import React, { Fragment, useEffect, useState } from "react";
import { TenantsInfoWrapper } from "../../Style";
import {
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentTitle,
    DashboardTopBarPerformanceItem,
    DashboardTopBarPerformanceItemContent,
    DashboardTopBarPerformanceItemTitle,
} from "../../../Dashboard/Style";
import { tenantStats } from "../../../../Admin/Stats";

interface TenantStatisticsInterface {
    tenant_id: string;
}

interface TenantStatisticsDataInterface {
    total_case_file: number;
    total_documents: number;
    total_final_documents: number;
    total_revenue: number;
    total_service_revenue: number;
    total_copyright_revenue: number;
}

export function TenantStatistics({ tenant_id }: TenantStatisticsInterface) {
    const [stats, setStats] = useState<TenantStatisticsDataInterface>({
        total_case_file: 0,
        total_documents: 0,
        total_final_documents: 0,
        total_revenue: 0,
        total_service_revenue: 0,
        total_copyright_revenue: 0,
    });

    useEffect(() => {
        tenantStats(tenant_id).then((r) => setStats(r));
    }, []);

    return (
        <Fragment>
            <TenantsInfoWrapper>
                <DashboardTopBarContainerContentHeader>
                    <DashboardTopBarContainerContentTitle>
                        Tenant statistics
                    </DashboardTopBarContainerContentTitle>
                </DashboardTopBarContainerContentHeader>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Total amount of case files:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        {stats.total_case_file}
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Total amount of created documents:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        {stats.total_documents}
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Total amount of finalized documents:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        {stats.total_final_documents}
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Total revenue:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        €{stats.total_revenue / 100}
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Total service revenue:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        €{stats.total_service_revenue / 100}
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Total copyright revenue:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        €{stats.total_copyright_revenue / 100}
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
            </TenantsInfoWrapper>
        </Fragment>
    );
}
