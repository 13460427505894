import { TableActionProps } from "../../../types/table";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { Fragment, useState } from "react";
import {
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "../../../components/ui/sheet";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/ui/table";
import { Checkbox } from "../../../components/ui/checkbox";
import { Switch } from "../../../components/ui/switch";
import { Button } from "../../../components/ui/button";
import Loading from "../../../components/global/loading";
import { CaseFileIndex } from "../../../types/case-files";
import { RocketIcon } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert";
import {
    useDuplicateCaseFileMutation,
    useGetDocumentsInCaseFileQuery,
} from "../../../api/tenant/case-file";
import { FetchDocumentsInCaseFileType } from "../../../types/overview";
import { toast } from "sonner";
import { DocumentExportIcon } from "../../../lib/utils";

function CaseFileDuplicate({ row, onClose }: TableActionProps<CaseFileIndex>) {
    const { data, isLoading } = useGetDocumentsInCaseFileQuery(row.id);

    return (
        <Loading
            loaded={!isLoading}
            child={
                <Fragment>
                    <CaseFileDuplicateComp data={data} row={row} onClose={onClose} />
                </Fragment>
            }
        />
    );
}

function CaseFileDuplicateComp({
    data,
    row,
    onClose,
}: {
    data?: FetchDocumentsInCaseFileType;
    row: CaseFileIndex;
    onClose: () => void;
}) {
    const schema = z.object({
        caseFileName: z.string().optional(),
        copyRiderTerms: z.boolean(),
        copyAddenda: z.boolean(),
        documents: z
            .array(
                z.object({
                    id: z.string(),
                    setAsDraft: z.boolean(),
                }),
            )
            .optional(),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            documents:
                data?.documents.map((document) => {
                    return {
                        id: document.type === "recap" ? document.id : "",
                        setAsDraft: false,
                    };
                }) ?? [],
            copyAddenda: false,
            copyRiderTerms: false,
        },
    });

    const [duplicateCaseFile] = useDuplicateCaseFileMutation();

    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        try {
            await duplicateCaseFile({
                ...values,
                caseFileId: row.id,
                is_supply_chain: row.is_supply_chain,
                documents: values.documents ?? [],
                supply_chain_id: row.supply_chain_id,
            });

            toast.success("Recap / CP duplicated");

            onClose();
        } catch (e) {
            toast.success("Something went wrong while duplicating Recap / CP");
        }

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <SheetHeader>
                        <SheetTitle className="text-slate-950">
                            Duplicate {row.document_name}
                        </SheetTitle>
                        <SheetDescription>Select what you want to duplicate</SheetDescription>
                    </SheetHeader>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)}>
                            <FormField
                                control={form.control}
                                name="caseFileName"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Document name</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder={row.document_name} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {row.supply_chain_id && (
                                <Alert className="mb-8 border-solid border-2 border-red-500">
                                    <RocketIcon className="h-4 w-4 fill-slate-950" />
                                    <AlertTitle className="text-slate-950">Heads up!</AlertTitle>
                                    <AlertDescription className="text-slate-950">
                                        This Recap/ C/P was shared with your organisation and will
                                        be duplicated as is.
                                    </AlertDescription>
                                </Alert>
                            )}

                            {(data?.documents?.length ?? 0) > 0 && (
                                <FormField
                                    control={form.control}
                                    name="documents"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                Choose which documents to duplicate
                                            </FormLabel>
                                            <FormControl>
                                                <Table>
                                                    <TableHeader>
                                                        <TableRow className="w-1/2">
                                                            <TableHead className="w-6"></TableHead>
                                                            <TableHead className="grid-rows-2">
                                                                Document name
                                                            </TableHead>
                                                            <TableHead className="w-1/6 text-center">
                                                                Copy
                                                            </TableHead>
                                                            <TableHead className="w-1/6 text-center">
                                                                Draft
                                                            </TableHead>
                                                        </TableRow>
                                                    </TableHeader>
                                                    <TableBody>
                                                        {data?.documents?.map((item, index) => (
                                                            <TableRow
                                                                key={index}
                                                                className="h-[38px]"
                                                            >
                                                                <TableCell>
                                                                    <DocumentExportIcon
                                                                        className={"h-4 -m-1.5"}
                                                                        item={item}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.document_name}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    className="pl-0"
                                                                >
                                                                    <Checkbox
                                                                        id={`copy-${item.id}`}
                                                                        disabled={
                                                                            item.type === "recap"
                                                                        }
                                                                        defaultChecked={form
                                                                            .getValues("documents")
                                                                            ?.map((x) => x.id)
                                                                            .includes(item.id)}
                                                                        onCheckedChange={() => {
                                                                            if (
                                                                                (
                                                                                    form.getValues(
                                                                                        "documents",
                                                                                    ) ?? []
                                                                                )
                                                                                    ?.map(
                                                                                        (x) => x.id,
                                                                                    )
                                                                                    ?.indexOf(
                                                                                        item.id,
                                                                                    ) > -1
                                                                            ) {
                                                                                form.setValue(
                                                                                    "documents",
                                                                                    (
                                                                                        field.value ??
                                                                                        []
                                                                                    ).filter(
                                                                                        (x) =>
                                                                                            x.id !==
                                                                                            item.id,
                                                                                    ),
                                                                                );
                                                                            } else {
                                                                                form.setValue(
                                                                                    "documents",
                                                                                    [
                                                                                        ...(form.getValues(
                                                                                            "documents",
                                                                                        ) ?? []),
                                                                                        {
                                                                                            ...item,
                                                                                            setAsDraft:
                                                                                                Boolean(
                                                                                                    item.finalized,
                                                                                                ),
                                                                                        },
                                                                                    ],
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    className="pl-0"
                                                                >
                                                                    {item.finalized &&
                                                                    (
                                                                        form.getValues(
                                                                            "documents",
                                                                        ) ?? []
                                                                    )
                                                                        ?.map((x) => x.id)
                                                                        ?.indexOf(item.id) > -1 ? (
                                                                        <Checkbox
                                                                            id={`draft-${item.id}`}
                                                                            defaultChecked={field.value
                                                                                ?.filter((x) =>
                                                                                    Boolean(
                                                                                        x.setAsDraft,
                                                                                    ),
                                                                                )
                                                                                ?.map((x) => x.id)
                                                                                .includes(item.id)}
                                                                            onCheckedChange={() => {
                                                                                const index = form
                                                                                    .getValues(
                                                                                        "documents",
                                                                                    )
                                                                                    ?.map(
                                                                                        (x) => x.id,
                                                                                    )
                                                                                    ?.indexOf(
                                                                                        item.id,
                                                                                    );
                                                                                const list =
                                                                                    form.getValues(
                                                                                        "documents",
                                                                                    );

                                                                                if (
                                                                                    list &&
                                                                                    index !==
                                                                                        undefined
                                                                                ) {
                                                                                    list[
                                                                                        index
                                                                                    ].setAsDraft =
                                                                                        !list[index]
                                                                                            .setAsDraft;
                                                                                    form.setValue(
                                                                                        "documents",
                                                                                        list,
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            )}
                            {data?.riders && (
                                <FormField
                                    control={form.control}
                                    name="copyRiderTerms"
                                    render={({ field }) => (
                                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm my-2">
                                            <div className="space-y-0.5">
                                                <FormLabel>Copy rider terms</FormLabel>
                                                <FormDescription>
                                                    Copying these won&apos;t affect the original
                                                    terms
                                                </FormDescription>
                                            </div>
                                            <FormControl>
                                                <Switch
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            )}
                            {data?.addenda && (
                                <FormField
                                    control={form.control}
                                    name="copyAddenda"
                                    render={({ field }) => (
                                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                                            <div className="space-y-0.5">
                                                <FormLabel>Copy addenda</FormLabel>
                                                <FormDescription>
                                                    Copying these won&apos;t affect the original
                                                    addenda
                                                </FormDescription>
                                            </div>
                                            <FormControl>
                                                <Switch
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            )}
                            {form.formState.isValid && (
                                <SheetFooter className="mt-4">
                                    <Button type="submit">Duplicate</Button>
                                </SheetFooter>
                            )}
                        </form>
                    </Form>
                </Fragment>
            }
        />
    );
}

export default CaseFileDuplicate;
