import { ColumnDef } from "@tanstack/react-table";
import { Invoice } from "../types/billing";
import React from "react";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import { format } from "date-fns";

const columns: ColumnDef<Invoice>[] = [
    {
        accessorKey: "number",
        header: "#",
        meta: {
            title: "Number",
        },
    },
    {
        accessorKey: "start_date",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Start (CEST)" type="date-range" />;
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Start date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("start_date") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "end_date",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="End date (CEST)" type="date-range" />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "End date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("end_date") as string;

            if (!value) {
                return <span>-</span>;
            }

            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "subtotal",
        header: "Subtotal",
        cell: ({ row }) => {
            const amount = parseFloat(row.getValue("subtotal")) / 100;
            const formatted = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            }).format(amount);

            return <div className="font-medium">{formatted}</div>;
        },
        meta: {
            title: "Subtotal",
        },
    },
    {
        accessorKey: "total",
        header: "Total",
        cell: ({ row }) => {
            const amount = parseFloat(row.getValue("total")) / 100;
            const formatted = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            }).format(amount);

            return <div className="font-medium">{formatted}</div>;
        },
        meta: {
            title: "Total",
        },
    },
    {
        accessorKey: "status",
        header: "Status",
        meta: {
            title: "Status",
        },
    },
];

export { columns };
