import React, { createContext, Fragment, useContext, useEffect, useReducer } from "react";
import { ProviderWithChildren } from "../../types";
import {
    ChatChannel,
    ChatIndex,
    ChatMessage,
    ChatPanelContext,
    ChatPanelState,
} from "../../types/chat";
import { useLazyGetChatChannelsQuery, useSendChatMessageMutation } from "../../api/tenant/chat";
import { useAuth } from "./AuthProvider";
import Echo from "laravel-echo";
import { toast } from "sonner";
import { Typography } from "../../components/ui/typography";
import { useCaseFiles } from "./CaseFilesProvider";
import { TenantUser } from "../../types/auth";

const ChatContext = createContext<ChatPanelContext>(null!);

function useChat() {
    return useContext(ChatContext);
}

function ChatProvider({ children }: ProviderWithChildren) {
    const [chatPanelState, setChatPanelState] = useReducer(
        (state: ChatPanelState, newState: Partial<ChatPanelState>) => ({
            ...state,
            ...newState,
        }),
        {
            channels: [],
            messages: [],
        },
    );

    const echo = window.Echo;
    const { user } = useAuth().state;
    const [getChatChannels, { data: channels }] = useLazyGetChatChannelsQuery();
    const [sendChatMessage] = useSendChatMessageMutation();

    const original_cf_id = useCaseFiles()?.original_cf_id ?? undefined;

    useEffect(() => {
        fetchChannels();

        return () => {
            setChatPanelState({
                activeChannel: undefined,
            });
        };
    }, []);

    useEffect(() => {
        if (channels) {
            setChatPanelState({
                channels: channels?.data,
            });
        }
    }, [channels]);

    useEffect(() => {
        if (user && echo && chatPanelState.activeChannel) {
            connectToSocket(user, echo);
        }
    }, [user, echo, chatPanelState.activeChannel]);

    useEffect(() => {
        connectToCustomSocket();
    }, [chatPanelState.custom_socket]);

    function connectToCustomSocket() {
        if (user && echo) {
            echo.leave(`${user.tenant_id}.messages`);
            echo.join(`${chatPanelState.custom_socket}.messages`).listen(
                ".newMessage",
                (message: ChatIndex) => {
                    handleNewMessage(message);
                },
            );
        }
    }

    function connectToSocket(user: TenantUser, echo: Echo) {
        if (chatPanelState.custom_socket) {
            echo.join(`${chatPanelState.custom_socket}.messages`).listen(
                ".newMessage",
                (message: ChatIndex) => {
                    setChatPanelState({
                        new_message: message,
                    });
                },
            );
        } else {
            echo.join(`${user.tenant_id}.messages`).listen(".newMessage", (message: ChatIndex) => {
                setChatPanelState({
                    new_message: message,
                });
            });
        }
    }

    useEffect(() => {
        if (chatPanelState.new_message) {
            handleNewMessage(chatPanelState.new_message);
        }
    }, [chatPanelState.new_message]);

    function handleNewMessage(message: ChatMessage) {
        if (chatPanelState.activeChannel?.channel_id === message.channel_id) {
            const messages = chatPanelState.messages.slice();
            messages.push(message);

            setChatPanelState({
                messages: messages,
                new_message: undefined,
            });

            if (user?.id !== message.user_id) {
                toast.info(
                    <Fragment>
                        <Typography
                            text={`${message.user_name} send ${message.message}`}
                            style="p"
                        />
                    </Fragment>,
                );
            }
        }
    }

    async function fetchChannels() {
        try {
            const data = await getChatChannels().unwrap();

            setChatPanelState({
                channels: data.data,
            });
        } catch (e) {
            toast.error("Something went wrong while fetching chat channels");
        }
    }

    function updateActiveChannel(channel?: ChatChannel) {
        if (chatPanelState.activeChannel?.channel_id !== channel?.channel_id) {
            setChatPanelState({
                activeChannel: channel,
                messages: channel?.messages,
            });
        }
    }

    function updateActiveMessages(messages: Array<ChatMessage>) {
        setChatPanelState({
            messages: messages,
        });
    }

    function shouldMessagesBeCombined(message: ChatIndex): boolean {
        const messages = chatPanelState.messages,
            index = messages.indexOf(message);

        if (index === -1) {
            return true;
        } else {
            if (messages[index - 1]) {
                return message.user_id !== messages[index - 1].user_id;
            }
            return true;
        }
    }

    async function sendMessage(message: string) {
        if (chatPanelState.activeChannel) {
            try {
                return await sendChatMessage({
                    message: message,
                    channel: chatPanelState.activeChannel.channel_id,
                    socket_channel: chatPanelState?.custom_socket,
                    sender_id: chatPanelState?.supply_chain_sender,
                    case_file_id: original_cf_id,
                }).unwrap();
            } catch (e) {
                toast.error("Something went wrong while sending chat message");
            }
        }
    }

    function setSupplyChainSocketConnection(custom_socket?: string, supply_chain_sender?: string) {
        setChatPanelState({
            custom_socket: custom_socket,
            supply_chain_sender: supply_chain_sender,
        });
    }

    const exposedValues: ChatPanelContext = {
        channels: chatPanelState.channels,
        updateActiveChannel: updateActiveChannel,
        updateActiveMessages: updateActiveMessages,
        shouldMessagesBeCombined: shouldMessagesBeCombined,
        setSupplyChainSocketConnection: setSupplyChainSocketConnection,
        sendMessage: sendMessage,
        messages: chatPanelState.messages,
        activeChannel: chatPanelState.activeChannel,
        setUpCustomSocket: (custom_socket) => setChatPanelState({ custom_socket: custom_socket }),
    };

    return <ChatContext.Provider value={exposedValues}>{children}</ChatContext.Provider>;
}

export { useChat, ChatProvider };
