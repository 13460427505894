import { useGetOrganisationMembersQuery } from "../../api/tenant/organisation";
import { DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import React, { Fragment, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Button } from "../ui/button";
import { useAuth } from "../../lib/providers/AuthProvider";
import {
    useCreateNewDirectChatChannelMutation,
    useGetChatChannelsQuery,
} from "../../api/tenant/chat";
import { toast } from "sonner";
import { Typography } from "../ui/typography";
import { TenantUser } from "../../types/auth";

function NewDirectChannel({ onClose }: { onClose: () => void }) {
    const { data: users } = useGetOrganisationMembersQuery();

    const { data: channels } = useGetChatChannelsQuery();

    const [newDirectChatChannel] = useCreateNewDirectChatChannelMutation();

    const [selectedUserId, setSelectedUserId] = useState<string>();

    const user = useAuth().state.user;

    function getAvailableUsers(): Array<TenantUser> | undefined {
        const directChannels = channels?.data.filter(
            (c) => c.additional_data.channel_type === "direct",
        );

        if (directChannels?.length) {
            const usersWithChannel: TenantUser[] = [];

            directChannels.map((dc) => {
                users?.data.map((u) => {
                    if (u.id !== user?.id) {
                        if (dc.permissions.members.includes(u.id)) {
                            usersWithChannel.push(u);
                        }
                    }
                });
            });

            return users?.data
                .filter((u) => !usersWithChannel.map((u) => u.id).includes(u.id))
                .filter((x) => x.id !== user?.id);
        } else {
            return users?.data.filter((x) => x.id !== user?.id);
        }
    }

    async function handleSubmit() {
        if (selectedUserId) {
            try {
                await newDirectChatChannel({
                    channel_name: users?.data.find((x) => x.id === selectedUserId)?.name ?? "",
                    channel_member_id: selectedUserId,
                });

                toast.message("Chat channel created");

                onClose();
            } catch (e) {
                toast.message("Failed to create chat channel");
            }
        }
    }

    return (
        <Fragment>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>New direct chat channel</DialogTitle>
                </DialogHeader>
                {(getAvailableUsers()?.length ?? 0) > 0 ? (
                    <Fragment>
                        <Select
                            defaultValue="default"
                            onValueChange={(e: string) => setSelectedUserId(e)}
                        >
                            <SelectTrigger>
                                <SelectValue defaultValue="default" />
                            </SelectTrigger>
                            <SelectContent>
                                {getAvailableUsers()?.map((user, index) => (
                                    <SelectItem value={user.id} key={index}>
                                        {user.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <DialogFooter className="mt-4">
                            <Button type="submit" onClick={handleSubmit}>
                                Create channel
                            </Button>
                        </DialogFooter>
                    </Fragment>
                ) : (
                    <Typography text="No users available for direct chat channel" style="p" />
                )}
            </DialogContent>
        </Fragment>
    );
}

export default NewDirectChannel;
