import { tenantApi } from "../tenantApi";
import { HouseForm } from "../../types/house-forms";
import { ColorSchemaInterface } from "../../types";

const houseFormsApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getHouseForms: build.query<{ data: Array<HouseForm> }, void>({
            query: () => ({
                url: "/house-forms",
            }),
            providesTags: (result: { data: Array<HouseForm> }) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "Houseforms", id }) as const)
                    : []),
                { type: "Houseforms" as const, id: "LIST" },
            ],
        }),
        deleteHouseForm: build.mutation<boolean, string>({
            query: (id) => ({
                url: `/house-forms/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Houseforms", id: "LIST" }],
        }),
        duplicateHouseForm: build.mutation<boolean, string>({
            query: (id) => ({
                url: `/house-forms/duplicate/${id}`,
                method: "POST",
            }),
            invalidatesTags: [{ type: "Houseforms", id: "LIST" }],
        }),
        editHouseForm: build.mutation<boolean, Pick<HouseForm, "id" | "name">>({
            query: ({ id, ...body }) => ({
                url: `/house-forms/edit/${id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [{ type: "Houseforms", id: "LIST" }],
        }),
        exportHouseForm: build.mutation<string, { id: string; schema: ColorSchemaInterface }>({
            query: ({ id, ...body }) => ({
                url: `/house-forms/export-file/${id}`,
                method: "POST",
                body: body,
                responseHandler: "text",
            }),
        }),
        getHouseForm: build.query<HouseForm, string>({
            query: (id) => ({
                url: `/house-forms/${id}`,
            }),
        }),
        saveHouseForm: build.mutation<boolean, Pick<HouseForm, "id" | "content" | "smart_fields">>({
            query: ({ id, ...body }) => ({
                url: `/house-forms/${id}`,
                method: "PUT",
                body: body,
            }),
        }),
        createHouseForm: build.mutation<HouseForm, Pick<HouseForm, "name" | "template_id">>({
            query: ({ ...body }) => ({
                url: `/house-forms`,
                method: "POST",
                body: body,
            }),
        }),
    }),
});

export const {
    useGetHouseFormsQuery,
    useDeleteHouseFormMutation,
    useDuplicateHouseFormMutation,
    useEditHouseFormMutation,
    useExportHouseFormMutation,
    useLazyGetHouseFormQuery,
    useSaveHouseFormMutation,
    useCreateHouseFormMutation,
} = houseFormsApi;
