import React, { useRef } from "react";
import { Document, Page } from "react-pdf";

const cMapUrl = "pdfjs-dist/cmaps/";

interface PDFPreviewProps {
    file: File;
}

const PDFPreview = ({ file }: PDFPreviewProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="w-full h-full flex flex-col">
            <div
                ref={containerRef}
                className="flex-grow flex justify-center items-center overflow-hidden"
            >
                <Document
                    file={URL.createObjectURL(file)}
                    options={{
                        cMapUrl,
                        cMapPacked: true,
                    }}
                    loading={<div>Loading PDF...</div>}
                >
                    <Page pageIndex={0} scale={0.7} />
                </Document>
            </div>
        </div>
    );
};

export default PDFPreview;
