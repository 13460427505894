import Command from ".";
import Memento from "../Memento";

function logMemoryUsageOfStringArray(arr: Array<{ command: Command; memento: Memento }>): string {
    // Calculate the total memory usage in bytes
    let totalBytes = 0;

    for (const str of arr) {
        totalBytes += str.memento.getState().length * 2; // Each character is approximately 2 bytes
    }

    // Adding overhead for array structure (approximately)
    totalBytes += arr.length * 8; // Each element reference in the array

    // Format the bytes into a human-readable string
    const formattedSize = formatBytes(totalBytes);

    return formattedSize;
}

function formatBytes(bytes: number): string {
    const units = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    let unitIndex = 0;
    let formattedBytes = bytes;

    while (formattedBytes >= 1000 && unitIndex < units.length - 1) {
        formattedBytes /= 1000;
        unitIndex++;
    }

    return `${formattedBytes.toFixed(2)} ${units[unitIndex]}`;
}

class CommandHistory {
    private stack: { command: Command; memento: Memento; timestamp: number }[] = [];
    private maxHistorySize = 50;

    push(command: Command): void {
        // Collect snapshot and timestamp
        const memento = command.previous;
        const timestamp = new Date().getTime();

        // Check if the last command can be combined with the new command
        // => if both operated on the same span within 5 seconds
        const lastItem = this.stack[this.stack.length - 1];
        if (
            lastItem &&
            command.id &&
            lastItem.command.id === command.id &&
            timestamp - lastItem.timestamp <= 5000
        ) {
            // update timestamp of now merged action
            const item = this.stack.pop();
            this.stack.push({ ...item!, timestamp });
            return;
        }

        // Remove the oldest element if the stack exceeds the max history size
        if (this.stack.length >= this.maxHistorySize) {
            this.stack.shift();
        }

        // Add new command
        this.stack.push({ command, memento, timestamp });
        // console.log(this.size());
    }

    undo(): void {
        const item = this.stack.pop();
        if (item) {
            // Restore snapshot
            item.command.restoreState(item.memento);
        }
    }

    size(): string {
        return logMemoryUsageOfStringArray(this.stack);
    }
}

export default CommandHistory;
