import React from "react";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { color } from "../../../Central/styles/mixins";

type ModalPosition =
    | "top left"
    | "top right"
    | "bottom right"
    | "bottom left"
    | "right center"
    | "left center"
    | "top center"
    | "bottom center"
    | "center center";

export type ModalSizes = "small" | "medium" | "large" | "larger" | "full-screen";

export type ModalHeights = "default" | "full-screen";

interface ModalsInterface {
    trigger?: JSX.Element;
    head: JSX.Element;
    information?: JSX.Element;
    body: JSX.Element;
    open: boolean;
    closeOnClick: boolean;
    position?: ModalPosition;
    onClose: () => void;
    size?: ModalSizes;
    height?: ModalHeights;
    onSubmit?: () => void;
    closeOnEscapeDisabled?: boolean;
}

const StyledPopup = styled(Popup)<{ inputSize: string; modalHeight: string }>`
    &-overlay {
        background-color: ${color("darker", 0.3)};
    }
    &-content {
        background-color: ${color("white")};
        width: 100%;
        max-width: ${(props) => props.inputSize || "800px"};
        height: ${(props) => props.modalHeight || "initial"};
        max-height: 100%;
        overflow: auto;
    }
`;

const HeadWrapper = styled.section`
    padding: 1em 1em 0;
    font-weight: 400;
    font-size: 1.5em;
    color: ${color("dark")};
`;

const InformationWrapper = styled.section`
    padding: 0em 4em;
    font-weight: 200;
    font-size: 0.75em;
    color: ${color("dark")};
`;

const BodyWrapper = styled.section`
    justify-content: center;
    height: calc(100% - 4.5em);
`;

export const ModalParagraphText = styled.p`
    color: ${(props) => props.theme.color.main.dark};
`;

export const ModalParagraphWaring = styled.p`
    color: ${(props) => props.theme.color.main.warning};
`;

/**
 * A method for determining the modal width based on provided prop
 * @param size
 */
export function determineCSSWidthOnSize(size: ModalSizes): string {
    switch (size) {
        case "small":
            return "664px";
        case "medium":
            return "864px";
        case "large":
            return "1064px";
        case "larger":
            return "1280px";
        case "full-screen":
            return "100%";
        default:
            return "864px";
    }
}

/**
 * A method for determining the modal height based on provided prop
 * @param height
 */
function determineCSSHeightOnSize(height: ModalHeights): string {
    switch (height) {
        case "default":
            return "initial";
        case "full-screen":
            return "100%";
        default:
            return "initial";
    }
}

/**
 * A component for rendering modals
 * @param trigger
 * @param head
 * @param information
 * @param body
 * @param open
 * @param closeOnClick
 * @param position
 * @param onClose
 * @param size
 * @param height
 * @param closeOnEscapeDisabled
 * @constructor
 */
export const Modals = ({
    trigger,
    head,
    information,
    body,
    open,
    closeOnClick,
    position,
    onClose,
    size,
    height,
    closeOnEscapeDisabled,
}: ModalsInterface) => {
    return (
        <StyledPopup
            trigger={trigger}
            modal
            closeOnDocumentClick={closeOnClick}
            open={open}
            position={position ?? "center center"}
            onClose={onClose}
            inputSize={determineCSSWidthOnSize(size ?? "medium")}
            modalHeight={determineCSSHeightOnSize(height ?? "default")}
            closeOnEscape={!closeOnEscapeDisabled}
        >
            <HeadWrapper>{head}</HeadWrapper>
            {information && <InformationWrapper>{information}</InformationWrapper>}
            <BodyWrapper>{body}</BodyWrapper>
        </StyledPopup>
    );
};
