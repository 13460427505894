import { RotDropDownProps } from "../../../types/case-files";
import React, { Fragment } from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import { MoreHorizontal, Pencil, ThumbsUp } from "lucide-react";

function RotDropDown({ rot_link, setSelectedAction }: RotDropDownProps) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-56">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    {!rot_link.approval_id && (
                        <Fragment>
                            <Button
                                variant="ghost"
                                className="w-full justify-start"
                                onClick={() => setSelectedAction("edit-permission")}
                            >
                                <DropdownMenuItem>
                                    <Pencil className="mr-2 h-4 w-4" />
                                    <span>Edit permissions</span>
                                </DropdownMenuItem>
                            </Button>
                            <Button
                                variant="ghost"
                                className="w-full justify-start"
                                onClick={() => setSelectedAction("request-approval")}
                            >
                                <DropdownMenuItem>
                                    <ThumbsUp className="mr-2 h-4 w-4" />
                                    <span>Request approval</span>
                                </DropdownMenuItem>
                            </Button>
                        </Fragment>
                    )}

                    {rot_link.approval_id && (
                        <Button
                            variant="ghost"
                            className="w-full justify-start"
                            onClick={() => setSelectedAction("remove-approval")}
                        >
                            <DropdownMenuItem>
                                <Pencil className="mr-2 h-4 w-4" />
                                <span>Withdraw approval</span>
                            </DropdownMenuItem>
                        </Button>
                    )}
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <Button
                        variant="destructive"
                        className="w-full justify-start"
                        onClick={() => setSelectedAction("remove-access")}
                    >
                        <DropdownMenuItem className="bg-red-500 focus:bg-red-500">
                            <Pencil className="mr-2 h-4 w-4 hover:fill-white" />
                            <span className="text-white">Withdraw permission</span>
                        </DropdownMenuItem>
                    </Button>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export default RotDropDown;
