import React, { Fragment } from "react";

import { MenuContainer, MenuIcon, MenuItem, MenuLink, MenuWrapper } from "../Style";
import { useNavigate } from "react-router";
import { adminPrefix } from "../../../../../config/central";

export const Menu = () => {
    // const activeItem = "dashboad";
    // const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     determineActivePage(location.pathname);
    // }, []);
    // //
    // function determineActivePage() {
    //     if (location === `/${adminPrefix}/dashboard`) {dispatch(setActive("dashboard"));}
    //     else if (location === `/${adminPrefix}/activity`) {dispatch(setActive("activity"));}
    //     else if (location === `/${adminPrefix}/templates`) {dispatch(setActive("templates"));}
    //     else if (location === `/${adminPrefix}/templates/new`) {dispatch(setActive("templates"));}
    //     else if (location === `/${adminPrefix}/riders`) {dispatch(setActive("riders"));}
    //     else if (location === `/${adminPrefix}/riders/new`) {dispatch(setActive("riders"));}
    //     else if (location === `/${adminPrefix}/invoices`) {dispatch(setActive("invoices"));}
    //     else if (location === `/${adminPrefix}/tenants`) {dispatch(setActive("tenants"));}
    //     else if (location === `/${adminPrefix}/backups`) {dispatch(setActive("backups"));}
    //     else if (location.includes("tenants")) {dispatch(setActive("tenants"));}
    //     else if (location === `/${adminPrefix}/recap-fields`) {dispatch(setActive("recap-fields"));}
    //     else if (location === `/${adminPrefix}/recap-clauses`) {dispatch(setActive("recap-clauses"));}
    //     else if (location.includes("recap-templates")) {dispatch(setActive("recap-templates"));}
    //     else if (location.includes("fleet-templates")) {dispatch(setActive("fleet-templates"));}
    // }

    interface MenuTitleProps {
        title: string;
    }
    const MenuTitle = ({ title }: MenuTitleProps) => {
        return <Fragment>{title}</Fragment>;
    };

    return (
        <MenuWrapper>
            <MenuContainer>
                <MenuLink to={`/${adminPrefix}/dashboard`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/dashboard`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M2.175 13.647s-.014.353.336.353l4.033-.005.006-3.263s-.057-.538.47-.538h1.675c.625 0 .587.538.587.538l-.008 3.253h3.949c.443 0 .423-.44.423-.44V7.528L8.082 2.64 2.175 7.528v6.119Z"></path>
                                <path d="M0 7.073s.5.912 1.595 0l6.537-5.461 6.127 5.427c1.266.901 1.741 0 1.741 0L8.132 0 0 7.073ZM14.11 1.599h-1.576l.007 1.888 1.569 1.315z"></path>
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Dashboard"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/activity`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/activity`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <rect width="3" height="12" x="5" y="4" />
                                <rect width="3" height="16" x="9" y="0" />
                                <rect width="3" height="8" x="13" y="8" />
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Recent activity"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/templates`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/templates`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M12.81 0H3.478v13.333H16V3.057L12.81 0ZM6.957 3.333h3.478c.384 0 .695.299.695.667 0 .368-.311.667-.695.667H6.957c-.384 0-.696-.299-.696-.667 0-.368.312-.667.696-.667ZM12.522 10H6.957c-.384 0-.696-.299-.696-.667 0-.368.312-.666.696-.666h5.565c.384 0 .695.298.695.666 0 .368-.311.667-.695.667Zm0-2.667H6.957c-.384 0-.696-.298-.696-.666 0-.368.312-.667.696-.667h5.565c.384 0 .695.299.695.667 0 .368-.311.666-.695.666Z"></path>
                                <path d="M2.087 2.667H0V16h11.826v-1.333H2.087z"></path>
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Templates"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/riders`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/riders`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7.148 2.258-1.576.281a.321.321 0 0 0-.261.214.327.327 0 0 0 .092.328l.13.132-1.03 1.044a.41.41 0 0 0 0 .575l.506.513a.397.397 0 0 0 .568 0L6.61 4.301l.13.132a.329.329 0 0 0 .237.107c.124 0 .265-.086.298-.278l.278-1.595a.362.362 0 0 0-.073-.295.347.347 0 0 0-.33-.114Zm4.072 9.224a.397.397 0 0 0-.569 0L9.62 12.526l-.13-.132a.329.329 0 0 0-.238-.107c-.124 0-.264.086-.298.278l-.277 1.595a.362.362 0 0 0 .072.295c.064.076.16.12.263.12a.39.39 0 0 0 .068-.006l1.576-.281a.321.321 0 0 0 .262-.214.327.327 0 0 0-.093-.328l-.13-.132 1.031-1.044a.407.407 0 0 0 .118-.287.407.407 0 0 0-.118-.288l-.506-.513ZM6.323 8.787 3.83 6.695c-.22-.183-.582-.32-.845-.32H.644a.661.661 0 0 0-.644.66l.004 8.41c0 .323.223.55.54.55L6.082 16a.64.64 0 0 0 .63-.647V9.624c0-.294-.167-.654-.388-.837Zm-2.694.219V7.27l2.206 1.907h-2.03c-.077 0-.176-.097-.176-.172Zm11.984-6.573L13.118.33c-.192-.16-.554-.33-.844-.33h-2.34c-.35 0-.644.307-.644.67l.004 8.415a.53.53 0 0 0 .54.553l5.536.007a.64.64 0 0 0 .63-.646V3.27c0-.293-.166-.654-.387-.837Zm-2.695.219V.916l2.206 1.887h-2.029c-.075 0-.177-.075-.177-.151Z"></path>
                        </MenuIcon>
                        <MenuTitle title={"Rider clauses"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/tenants`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/tenants`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M5.332 7.172h-.387A3.855 3.855 0 0 0 2.09 8.293l-.11.125v3.68h1.817v-2.09l.242-.274.113-.129a4.912 4.912 0 0 1 2.094-1.273c-.398-.3-.715-.7-.914-1.16ZM13.93 8.281a3.855 3.855 0 0 0-2.86-1.12c-.16 0-.324.007-.484.027-.203.43-.508.8-.887 1.09a4.874 4.874 0 0 1 2.223 1.331l.11.125.238.274v2.094h1.753V8.406ZM4.934 6.309h.136a2.87 2.87 0 0 1 1.383-2.797 1.82 1.82 0 0 0-2.207-.688 1.816 1.816 0 0 0 .688 3.5ZM10.86 5.973c.003.101.003.207 0 .308.081.012.167.02.257.02h.082a1.817 1.817 0 1 0-1.656-2.746 2.906 2.906 0 0 1 1.316 2.418ZM9.93 5.977a1.986 1.986 0 1 1-3.973 0 1.986 1.986 0 0 1 3.973 0Zm0 0" />
                                <path d="M8.05 9.023a4.306 4.306 0 0 0-3.16 1.2l-.113.125v2.812a.695.695 0 0 0 .711.684h5.106a.699.699 0 0 0 .715-.684v-2.805l-.11-.132a4.255 4.255 0 0 0-3.148-1.2Zm0 0" />
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Tenants"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/invoices`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/invoices`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M5.441 7.469H10.7v1.25H5.441Zm0 2.36H10.7v1.25H5.441Zm0-4.759H10.7v1.25H5.441Zm0 0" />
                                <path d="M11.34 1 9.64.281 8.079 1 6.41.281 4.84 1 2.46 0v16l2.38-1 1.57.691L8.078 15l1.563.691L11.34 15l2.2 1V0Zm.941 13.11-.922-.41-1.687.69-1.57-.718-1.68.687-1.55-.687-1.153.469V1.859l1.152.47 1.55-.688 1.68.687 1.57-.687 1.688.687.922-.406Zm0 0" />
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Invoices"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/backups`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/backuos`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <path d="m15.988 12.063-2.16-9.68a2.074 2.074 0 0 0-2.02-1.617H4.192a2.065 2.065 0 0 0-2.02 1.617l-2.16 9.68c-.015.058-.011.257-.011.386v.899c0 .52.21.996.555 1.336.34.34.812.55 1.332.55h12.226c.52 0 .992-.21 1.332-.55.344-.34.555-.817.555-1.336v-.899c0-.129.004-.328-.012-.386ZM1.887 14.155a.813.813 0 0 1-.57-.234.802.802 0 0 1-.239-.574v-.899c0-.226.09-.422.238-.57a.8.8 0 0 1 .57-.238h12.227a.8.8 0 0 1 .57.238.793.793 0 0 1 .239.57v.899c0 .226-.09.425-.238.574a.813.813 0 0 1-.57.234ZM3.574 2.06a.987.987 0 0 1 .617-.215h7.618a.987.987 0 0 1 .964.773l1.786 8a1.878 1.878 0 0 0-.446-.055H1.887c-.153 0-.301.02-.446.055l1.786-8c.05-.226.175-.418.347-.558Zm0 0" />
                            <path d="M13.512 12.898a.541.541 0 1 1-1.082.004.541.541 0 0 1 1.082-.004Zm0 0" />
                        </MenuIcon>
                        <MenuTitle title={"Backups"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/recap-fields`}>
                    <MenuItem className="" onClick={() => navigate(`/${adminPrefix}/recap-fields`)}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M15 14.68H1a.182.182 0 0 1-.18-.18v-2c0-.098.082-.18.18-.18h14c.098 0 .18.082.18.18v2c0 .098-.082.18-.18.18Zm-13.82-.36h13.64v-1.64H1.18ZM7 11.68H1v-.36h6Zm8-2H1a.182.182 0 0 1-.18-.18v-2c0-.098.082-.18.18-.18h14c.098 0 .18.082.18.18v2c0 .098-.082.18-.18.18ZM1.18 9.32h13.64V7.68H1.18ZM7 6.68H1v-.36h6Zm8-2H1a.182.182 0 0 1-.18-.18v-2c0-.098.082-.18.18-.18h14c.098 0 .18.082.18.18v2c0 .098-.082.18-.18.18ZM1.18 4.32h13.64V2.68H1.18ZM7 1.68H1v-.36h6Zm0 0" />
                            </g>
                        </MenuIcon>

                        <MenuTitle title={"Recap fields"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/recap-clauses`}>
                    <MenuItem
                        className=""
                        onClick={() => navigate(`/${adminPrefix}/recap-clauses`)}
                    >
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M8 14.328h8v-2H8Zm-8-3.336h16v-2H0Zm8-3.328h8v-2H8ZM0 2.328v2h16v-2Zm0 0" />
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Recap clauses"} />
                    </MenuItem>
                </MenuLink>
                <MenuLink to={`/${adminPrefix}/recap-templates`}>
                    <MenuItem
                        className=""
                        onClick={() => navigate(`/${adminPrefix}/recap-templates`)}
                    >
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path
                                    fillRule="evenodd"
                                    d="M1 1v14h14V1Zm12 2H3v2h10Zm0 4H7v6h6ZM5 7H3v6h2Zm0 0"
                                />
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Recap templates"} />
                    </MenuItem>
                </MenuLink>

                <MenuLink to={`/${adminPrefix}/fleet-templates`}>
                    <MenuItem
                        className=""
                        onClick={() => navigate(`/${adminPrefix}/fleet-templates`)}
                    >
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path
                                    fillRule="evenodd"
                                    d="M1 1v14h14V1Zm12 2H3v2h10Zm0 4H7v6h6ZM5 7H3v6h2Zm0 0"
                                />
                            </g>
                        </MenuIcon>
                        <MenuTitle title={"Fleet templates"} />
                    </MenuItem>
                </MenuLink>
            </MenuContainer>
        </MenuWrapper>
    );
};
