import { DataTableColumnHeaderProps } from "../../types/table";
import { cn } from "../../lib/utils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import * as React from "react";
import { Command, CommandInput } from "../ui/command";
import { Search } from "lucide-react";

function DataTableSearch<TData, TValue>({
    column,
    title,
    className,
}: Omit<DataTableColumnHeaderProps<TData, TValue>, "type">) {
    return (
        <div className={cn("flex items-center space-x-2", className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="ghost"
                        size="sm"
                        className="-ml-3 h-8 data-[state=open]:bg-accent"
                    >
                        <span>{title}</span>
                        <Search className="ml-2 h-3 w-3" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <Command>
                        <CommandInput
                            placeholder={title}
                            onValueChange={(e) => {
                                column.setFilterValue(e);
                            }}
                            autoFocus={true}
                            value={(column.getFilterValue() as string) ?? ""}
                        />
                    </Command>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

export default DataTableSearch;
