import { useEffect } from "react";
import { determineTenantName } from "./helpers/tenant";

export function usePageTitle(value?: string) {
    const title = value ? firstLetterUppercase(value) + " | " : "";

    useEffect(() => {
        document.title =
            title + `${firstLetterUppercase(determineTenantName() ?? "")} | Ocean Recap`;
    }, [value]);
}

function firstLetterUppercase(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
