import { Card, CardContent, CardHeader } from "../ui/card";
import React, { Fragment } from "react";
import { Typography } from "../ui/typography";
import { JSX } from "react/jsx-runtime";
import { ScrollArea } from "../ui/scroll-area";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { MoreHorizontal } from "lucide-react";
import { Align } from "@radix-ui/react-popper";
import { cn } from "../../lib/utils";
import VerticalDragAndDrop, {
    VerticalDragAndDropProps,
} from "../drag-and-drop/vertical-drag-and-drop";

type TableOfContentsProps<Generic> = {
    title: string;
    items: Array<Generic & { label: string; inactive?: boolean; id: string }>;
    listHeaderElement?: JSX.Element;
    mainListHeader?: string;
    listDraggable: boolean;
    onClick: (item: Generic) => void;
    itemActions?: ItemActions<Generic>;
    draggable?: DraggableTableOfContentItems<Generic>;
    options?: JSX.Element;
    disabled?: boolean;
};

type DraggableTableOfContentItems<Generic> = Pick<
    VerticalDragAndDropProps<Generic>,
    "onDragEnd" | "indexPropertyToUpdate"
>;

type ItemActions<Generic> = {
    actions: Array<ItemActionsItem<Generic>>;
    label: string;
    disabled: boolean;
    activeItem?: Generic;
    className?: string;
    align?: Align;
};

type ItemActionsItem<Generic> = {
    label: (item: Generic) => string;
    action: (e: React.MouseEvent<HTMLDivElement>, item: Generic) => void;
};

function TableOfContents<Generic>({
    title,
    listHeaderElement,
    options,
    mainListHeader,
    items,
    itemActions,
    draggable,
    onClick,
    disabled,
}: TableOfContentsProps<Generic>) {
    const scrollAreaHeightClass = listHeaderElement ? "h-[calc(100vh-332px)]" : "???";

    return (
        <Card className="h-full w-[320px] px-0">
            <CardHeader className="px-6 py-4 flex flex-row justify-between items-center space-y-0">
                <Typography text={title} style="h4" />
                {options && <div className="inline-flex">{options}</div>}
            </CardHeader>
            <CardContent className="px-0">
                {listHeaderElement ? listHeaderElement : null}
                {mainListHeader ? (
                    <Typography text={mainListHeader} style="medium" className="pl-6 mb-2" />
                ) : null}

                <ScrollArea className={`${scrollAreaHeightClass}`}>
                    {draggable ? (
                        <VerticalDragAndDrop<Generic>
                            onDragEnd={draggable.onDragEnd}
                            indexPropertyToUpdate={draggable.indexPropertyToUpdate}
                            items={items}
                            listElement={(
                                item: Generic & { label: string; inactive?: boolean },
                                index,
                            ) => (
                                <List<Generic>
                                    item={item}
                                    key={index}
                                    itemActions={itemActions}
                                    className={item.inactive ? "opacity-50" : "opacity-100"}
                                    onClick={onClick}
                                />
                            )}
                            disabled={disabled}
                        />
                    ) : (
                        <Fragment>
                            {items.map((item, index) => (
                                <List<Generic>
                                    item={item}
                                    key={index}
                                    itemActions={itemActions}
                                    className={item.inactive ? "opacity-50" : "opacity-100"}
                                    onClick={onClick}
                                />
                            ))}
                        </Fragment>
                    )}
                </ScrollArea>
            </CardContent>
        </Card>
    );
}

type ListProps<Generic> = {
    item: Generic & { label: string; active?: boolean };
    itemActions?: ItemActions<Generic>;
    className?: string;
    onClick: (item: Generic) => void;
};

function List<Generic>({ item, itemActions, className, onClick }: ListProps<Generic>) {
    return (
        <div
            data-active={item.active}
            className={cn(
                "inline-flex items-center justify-between rounded-md text-sm font-medium transition-colors h-9 px-6 py-2 w-full hover:bg-accent hover:text-accent-foreground cursor-pointer data-[active=true]:bg-accent data-[active=true]:text-accent-foreground",
                className,
            )}
            onClick={() => onClick(item)}
        >
            <Typography text={item.label} style="p" className="truncate max-w-[24ch]" />
            {itemActions && (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon" disabled={itemActions.disabled}>
                            <MoreHorizontal className="fill-neutral-800 h-[1.2rem] w-[1.2rem]" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className={itemActions.className}
                        align={itemActions.align ?? "start"}
                    >
                        <DropdownMenuLabel>{itemActions.label}</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            {itemActions.actions.map((value, index) => (
                                <DropdownMenuItem
                                    key={index}
                                    onClick={(e) => value.action(e, item)}
                                >
                                    <span>{value.label(item)}</span>
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </div>
    );
}

export default TableOfContents;
