import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTenant, Tenant } from "../../../Admin/Tenants";
import { AdminTrialInfoTenant } from "./PlanInfo";
import { TenantDetailContainer, TenantTopBarContainer } from "../Style";
import { TenantStatistics } from "./Statistics";
import { AdminUserInfoTenant } from "./Users";

export function AdminTenantDetailPage() {
    const [tenant, setTenant] = useState<Tenant>();
    const { tenantId } = useParams<{ tenantId: string }>();

    useEffect(() => {
        if (tenantId) {
            getData(tenantId);
        }
    }, [tenantId]);

    function getData(tenantId: string) {
        getTenant(tenantId).then((r: { data: any }) => {
            setTenant(r.data);
        });
    }

    function revalidate() {
        if (tenantId) {
            getData(tenantId);
        }
    }

    return (
        <Fragment>
            <TenantDetailContainer>
                <TenantTopBarContainer>
                    {tenant && (
                        <Fragment>
                            <AdminTrialInfoTenant tenant={tenant} onUpdate={revalidate} />
                            <TenantStatistics tenant_id={tenant.id} />
                            <AdminUserInfoTenant tenant={tenant} />
                        </Fragment>
                    )}
                </TenantTopBarContainer>
            </TenantDetailContainer>
        </Fragment>
    );
}
