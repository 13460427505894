import { TenantUser } from "../../../types/auth";
import {
    createSpanWithText,
    direction,
    nodeBound,
    setCursorAfterElement,
    setCursorBeforeElement,
    setCursorWithElement,
    shouldCombine,
    updateSpanWithText,
} from "./base";
import {
    checkAdditionSpan,
    checkDeletionSpan,
    containsFrMarkers,
    findSiblingLeafNodes,
    isLineNumber,
    isUserBR,
} from "./inspectDOM";

/**
 * Handles the creation and manipulation of text within an addition node context based on the specified
 * type of node boundary condition ('edge' or 'inside'). The function creates a new addition span,
 * splits an existing text node into multiple parts, or simply updates the existing node,
 * depending on where the character insertion takes place.
 *
 * @param {Node} anchor - The DOM node that serves as the reference point for where the addition will occur.
 *                        This is either the currently focused node or the node adjacent to the cursor.
 * @param {number} offset - The character position within the `anchor`'s text content at which the addition
 *                          or manipulation is supposed to happen.
 * @param {string} char - The character that is to be added or manipulated within the `anchor`.
 * @param {TenantUser} user - The user that made this change to the document.
 * @param {nodeBound} type - The type of node boundary condition which can be 'edge' or 'inside'.
 *                           'edge' implies the addition at the right boundary of the node,
 *                           'inside' requires a split of the node.
 */
export function handleAdditionNodeCreation(
    anchor: Node,
    offset: number,
    char: string,
    type: nodeBound,
    user: TenantUser,
): string | null {
    if (!anchor) {
        return null;
    }

    if (type === "right") {
        // Create new addition span containing the char that was typed
        return newAdditionNode(anchor, char, user);
    } else if (type === "inside") {
        // Now that we know that we are inside anchor and offset refers to the character offset
        // we should determine if we are at the right edge
        if (offset === anchor.textContent?.length) {
            return newAdditionNode(anchor, char, user);
        }

        // If we get to here, it means we are actually inside anchor, so well be splitting the node

        // If there exists no text, we cannot possibly continue
        if (!anchor.textContent) {
            return null;
        }

        // Text before and after the new char position
        const startText = anchor.textContent.slice(0, offset);
        const endText = anchor.textContent.slice(offset);

        // Addition span for the new character typed
        const newAddition = createSpanWithText({ "data-change-type": "addition" }, char, user.id);

        // Node instances used for updating the DOM
        let parentNode: Node | null = anchor.parentNode;
        let insertionAnchor: Node = anchor;
        let startNode: Node;
        let endNode: Node;

        // First we check if anchor is an addition
        const checkAddition = checkAdditionSpan(anchor);
        if (checkAddition) {
            // Make sure to get the actual element type parent and anchor node, since anchor might have been referring to
            // the text content of an addition up until here
            parentNode = checkAddition.parentNode;
            insertionAnchor = checkAddition;

            // If it is an addition, we need new addition spans in order to split the text
            startNode = createSpanWithText({ "data-change-type": "addition" }, startText, user.id);
            endNode = createSpanWithText({ "data-change-type": "addition" }, endText, user.id);
        } else {
            // If we are not inside an addition, we can use regular text nodes
            startNode = document.createTextNode(startText);
            endNode = document.createTextNode(endText);
        }

        if (parentNode) {
            // Insert start, new, end in order into the DOM
            parentNode.insertBefore(startNode, insertionAnchor);
            parentNode.insertBefore(newAddition, insertionAnchor);
            parentNode.insertBefore(endNode, insertionAnchor);
            parentNode.removeChild(insertionAnchor);

            // Set the cursor position after the new span
            setCursorAfterElement(newAddition);

            return (newAddition as HTMLSpanElement).getAttribute("data-identifier");
        }
    }

    return null;
}

/**
 * Creates a new addition node or updates an existing one based on the proximity of the last addition.
 * This function checks if the provided anchor node is an addition node and if it was modified recently
 * (within a time defined by `chainTime`). If so, it appends the character to this node and updates its timestamp.
 * Otherwise, it creates a new addition node after the anchor node.
 *
 * @param {Node} anchor - The node after which the addition or update will occur. This is where the cursor is
 *                        currently located.
 * @param {TenantUser} user - The user that made this change to the document.
 * @param {string} char - The character to be added to the addition node. This character is either
 *                        appended to an existing node or used to create a new node.
 */
export function newAdditionNode(anchor: Node, char: string, user: TenantUser): string | null {
    // If we are appending the node after an addition node
    const checkAddition = checkAdditionSpan(anchor);
    if (checkAddition && shouldCombine(checkAddition)) {
        const newText = checkAddition.textContent + char;
        updateSpanWithText(checkAddition, newText);
        setCursorAfterElement(checkAddition);
        return (checkAddition as HTMLSpanElement).getAttribute("data-identifier");
    } else {
        // In the other more general case, just create the new addition node with char as content

        // Create a new addition node
        const newAddition = createSpanWithText({ "data-change-type": "addition" }, char, user.id);

        let parentRef = anchor.parentNode;
        let placeRef = anchor.nextSibling;
        const checkAddition = checkAdditionSpan(anchor);
        if (checkAddition) {
            parentRef = checkAddition.parentNode;
            placeRef = checkAddition.nextSibling;
        }
        const checkDeletion = checkDeletionSpan(anchor);
        if (checkDeletion) {
            parentRef = checkDeletion.parentNode;
            placeRef = checkDeletion.nextSibling;
        }

        if (parentRef) {
            // Insert new addition after anchor
            parentRef.insertBefore(newAddition, placeRef);

            // Set the cursor position after the new span
            setCursorAfterElement(newAddition);

            return (newAddition as HTMLSpanElement).getAttribute("data-identifier");
        }

        return null;
    }
}

/**
 * Handles the creation of deletion nodes based on the specified direction and location relative to an anchor node.
 * This function is designed to manage how backspace ('bsp') and delete ('del') operations affect a node
 * at different positions ('left', 'inside', 'right') relative to an anchor node.
 *
 * @param {Node} anchor - The reference node relative to which deletions are to be handled.
 * @param {number} offset - The position offset within the anchor node where the deletion operation is applied.
 * @param {nodeBound} location - The position of the deletion relative to the anchor node. Possible values are 'left', 'inside', or 'right'.
 * @param {direction} direction - The deletion direction, which can either be 'backspace' ('bsp') or 'delete' ('del').
 * @param {TenantUser} user - The user that made this change to the document.
 * @returns {string | null} - the uuid of the span that was created or updated
 */
export function handleDeletionNodeCreation(
    anchor: Node,
    offset: number,
    location: nodeBound,
    direction: direction,
    user: TenantUser,
): string | null {
    if (!anchor) {
        return null;
    }

    // Handle backspaces
    if (direction === "backspace") {
        if (location === "left") {
            return backspaceLeftEdge(anchor, user);
        }

        if (location === "inside") {
            return backspaceInside(anchor, offset, user);
        }

        if (location === "right") {
            return backspaceRightEdge(anchor, user);
        }
    }

    // handle deletes
    if (direction === "delete") {
        if (location === "left") {
            return deleteLeftEdge(anchor, user);
        }

        if (location === "inside") {
            return deleteInside(anchor, offset, user);
        }

        if (location === "right") {
            return deleteRightEdge(anchor, user);
        }
    }

    return null;
}

/**
 * Handles the backspace operation when the cursor is at the left edge of an anchor node.
 * This function performs several checks and operations:
 * - If the left sibling node is empty or doesn't exist, no action is taken.
 * - If the left sibling is a deletion span, backspacing is disallowed.
 * - Removes the last character of the left sibling's text content and potentially combines it
 *   with the anchor node's content if the anchor is also a deletion span.
 * - If combining is not necessary or applicable, it creates a new deletion node with the last character of the left
 *   sibling and inserts it before the anchor node in the DOM.
 *
 * @param {Node} anchor - The anchor node adjacent to which the backspace operation is performed.
 * @param {TenantUser} user - The user that made this change to the document.
 * @returns {string | null} - the uuid of the span that was created or updated
 */
function backspaceLeftEdge(anchor: Node, user: TenantUser): string | null {
    const { left } = findSiblingLeafNodes(anchor);

    // Nothing to backspace
    if (!left) {
        return null;
    }

    // Allow for normal deletion of line breaks
    if (isUserBR(left)) {
        (left as Element).remove();
        return null;
    }

    // Disallow backspacing into deletion spans, line-numbers, empty text nodes
    if (checkDeletionSpan(left) || isLineNumber(left) || !left.textContent) {
        return null;
    }

    // Modify string
    const lastChar = left.textContent[left.textContent.length - 1]; // Save the last character from left node
    left.textContent = left.textContent.slice(0, -1); // Remove the last character from left node

    // Only do the char removal for addition
    const checkAdditionLeft = checkAdditionSpan(left);
    if (checkAdditionLeft) {
        // We may return, as the string has been modified already
        // and no further action is required
        return (checkAdditionLeft as HTMLSpanElement).getAttribute("data-identifier");
    }

    // - IF anchor is a deletion we might combine
    let checkDeletion = checkDeletionSpan(anchor);
    if (checkDeletion && shouldCombine(checkDeletion)) {
        const newText = lastChar + checkDeletion.textContent;
        updateSpanWithText(checkDeletion, newText);
        return (checkDeletion as HTMLSpanElement).getAttribute("data-identifier");
    }

    // - ELSE create deletion node
    const newDeletion = createSpanWithText({ "data-change-type": "deletion" }, lastChar, user.id);

    let parentRef = anchor.parentNode;
    let placeRef = anchor;
    const checkAddition = checkAdditionSpan(anchor);
    if (checkAddition) {
        parentRef = checkAddition.parentNode;
        placeRef = checkAddition;
    }
    checkDeletion = checkDeletionSpan(anchor);
    if (checkDeletion) {
        parentRef = checkDeletion.parentNode;
        placeRef = checkDeletion;
    }

    if (parentRef) {
        // Insert new deletion before anchor
        parentRef.insertBefore(newDeletion, placeRef);

        // Place cursor at start of new deletion
        setCursorWithElement(newDeletion, 0);

        return (newDeletion as HTMLSpanElement).getAttribute("data-identifier");
    }

    return null;
}

/**
 * Handles the backspace operation inside a node at a specific character offset.
 * The function checks the type of the node (whether it's a deletion or addition span) and applies the appropriate
 * backspace handling logic based on the node type:
 * - If the anchor node is a deletion span, no action is taken to modify its content.
 * - If the anchor node is an addition span, it delegates to `backspaceInsideAddition` to handle the backspace.
 * - For regular text nodes, it delegates to `backspaceInsideNeutral` to perform the backspace operation.
 *
 * @param {Node} anchor - The node within which the backspace operation is to be performed.
 * @param {number} offset - The character offset within the node where the backspace operation is to occur.
 * @param {TenantUser} user - The user that made this change to the document.
 */
function backspaceInside(anchor: Node, offset: number, user: TenantUser): string | null {
    if (!anchor) {
        return null;
    }

    if (checkDeletionSpan(anchor)) {
        return null;
    }

    // Handle backspace inside an addition node
    const checkAddition = checkAdditionSpan(anchor);
    if (checkAddition) {
        return backspaceInsideAddition(checkAddition, offset);
    }

    // Handle backspace inside a regular text section
    return backspaceInsideNeutral(anchor, offset, user);
}

/**
 * Handles the backspace operation when the cursor is at the right edge of an anchor node.
 * This function removes the last character of the anchor's text and checks for the possibility of combining this
 * character with a deletion span in the right sibling node. If no combination is possible, it creates a new deletion span:
 * - If the anchor node is a deletion span, the function exits without modifying the content.
 * - Removes the last character of the anchor's text content.
 * - Checks the right sibling node for a deletion span and attempts to combine them if appropriate.
 * - If no combination is necessary, creates a new deletion span with the removed character and inserts it after the anchor.
 * The cursor is then placed before this new deletion span to reflect the change.
 *
 * @param {Node} anchor - The node from which the last character is removed.
 * @param {TenantUser} user - The user that made this change to the document.
 */
function backspaceRightEdge(anchor: Node, user: TenantUser): string | null {
    if (!anchor) {
        return null;
    }

    if (isUserBR(anchor)) {
        (anchor as Element).remove();
        return null;
    }

    if (checkDeletionSpan(anchor) || !anchor.textContent) {
        return null;
    }

    let checkAddition = checkAdditionSpan(anchor);
    if (checkAddition && checkAddition.textContent) {
        // Convert to inside type, since that already handles clean up of nodes
        return backspaceInsideAddition(checkAddition, checkAddition.textContent.length);
    }

    // Remove char from text content
    const lastChar = anchor.textContent[anchor.textContent.length - 1]; // Save the last character from anchor node
    anchor.textContent = anchor.textContent.slice(0, -1); // Remove the last character from anchor node

    const { right } = findSiblingLeafNodes(anchor);

    if (right && right.textContent) {
        // If right is a deletion we might combine
        const checkDeletion = checkDeletionSpan(right);
        if (checkDeletion && shouldCombine(checkDeletion)) {
            const newText = lastChar + checkDeletion.textContent;
            updateSpanWithText(checkDeletion, newText);
            return (checkDeletion as HTMLSpanElement).getAttribute("data-identifier");
        }
    }

    // ELSE create deletion node
    const newDeletion = createSpanWithText({ "data-change-type": "deletion" }, lastChar, user.id);

    let parentRef = anchor.parentNode;
    let placeRef = anchor.nextSibling;
    checkAddition = checkAdditionSpan(anchor);
    if (checkAddition) {
        parentRef = checkAddition.parentNode;
        placeRef = checkAddition.nextSibling;
    }
    const checkDeletion = checkDeletionSpan(anchor);
    if (checkDeletion) {
        parentRef = checkDeletion.parentNode;
        placeRef = checkDeletion.nextSibling;
    }

    if (parentRef) {
        // Insert new deletion after anchor
        parentRef.insertBefore(newDeletion, placeRef);

        // Place cursor at start of new deletion
        setCursorBeforeElement(newDeletion);

        return (newDeletion as HTMLSpanElement).getAttribute("data-identifier");
    }

    return null;
}

/**
 * Handles the backspace operation within an addition span at a specified character offset.
 * This function modifies the text content of the addition span based on the cursor's position:
 * - If the node contains only one character, it removes the entire node from the DOM.
 * - For longer text, it deletes the character at the specified offset.
 *
 * @param {Node} anchor - The addition span node within which the backspace operation is performed.
 * @param {number} offset - The character offset within the node where the backspace operation is to occur.
 */
function backspaceInsideAddition(anchor: Node, offset: number): string | null {
    if (!anchor.textContent) {
        return null;
    }

    // If final char, remove the element
    if (anchor.textContent.length === 1) {
        anchor.parentNode?.removeChild(anchor); // TODO: what if parentNode is null?
        return null;
    }

    // delete character at offset
    anchor.textContent = anchor.textContent.slice(0, offset - 1) + anchor.textContent.slice(offset);

    // move cursor span
    setCursorWithElement(anchor.firstChild!, offset - 1); // TODO: need better text child node selection method

    return (anchor as HTMLSpanElement).getAttribute("data-identifier");
}

/**
 * Handles the backspace operation within a neutral text node at a specified character offset.
 * The function deletes a character at the given offset and wraps the deleted character in a new deletion span.
 * It adjusts the surrounding text and updates the DOM to reflect these changes:
 * - Extracts the text before and after the offset.
 * - Wraps the character at the offset into a new deletion span.
 * - Reconstructs the text nodes around the deletion span and replaces the original text node with these new nodes.
 * After modifying the DOM, it moves the cursor to a position before the new deletion span.
 *
 * @param {Node} anchor - The text node within which the backspace operation is performed.
 * @param {number} offset - The character offset within the text node where the backspace operation is to occur.
 * @param {TenantUser} user - The user that made this change to the document.
 */
function backspaceInsideNeutral(anchor: Node, offset: number, user: TenantUser): string | null {
    if (!anchor.textContent || anchor.textContent.length < offset + 1) {
        return null;
    }

    // Extract text contents based on carot osition (offset)
    const beforeText = anchor.textContent.slice(0, offset - 1);
    const charToWrap = anchor.textContent.charAt(offset - 1);
    const afterText = anchor.textContent.slice(offset);

    // Create necessary nodes
    const newDeletion = createSpanWithText({ "data-change-type": "deletion" }, charToWrap, user.id);
    const beforeTextNode = document.createTextNode(beforeText);
    const afterTextNode = document.createTextNode(afterText);

    const parent = anchor.parentNode;

    if (parent) {
        // Insert new nodes into DOM
        parent.insertBefore(beforeTextNode, anchor);
        parent.insertBefore(newDeletion, anchor);
        parent.insertBefore(afterTextNode, anchor);
        parent.removeChild(anchor);

        // Move cursor before new deletion node
        setCursorBeforeElement(newDeletion);

        return (newDeletion as HTMLSpanElement).getAttribute("data-identifier");
    }

    return null;
}

/**
 * Handles the delete operation at the left edge of an anchor node.
 * This function removes the first character of the anchor's text and checks for the possibility of merging this
 * character with a deletion span in the left sibling node. If no such combination is possible, it creates a new deletion span:
 * - If the anchor node is a deletion span, the function exits without modifying the content.
 * - Removes the first character of the anchor's text content.
 * - Checks the left sibling node for a deletion span and attempts to combine them if appropriate.
 * - If no combination is necessary, creates a new deletion span with the removed character and inserts it before the anchor.
 * The cursor is then positioned after the new deletion span to reflect the change.
 *
 * @param {Node} anchor - The node from which the first character is removed.
 * @param {TenantUser} user - The user that made this change to the document.
 */
function deleteLeftEdge(anchor: Node, user: TenantUser): string | null {
    if (!anchor || !anchor.textContent) {
        return null;
    }

    // Disallow deleting into deletion
    if (checkDeletionSpan(anchor)) {
        return null;
    }

    const firstChar = anchor.textContent.charAt(0); // Save the first character from anchor node
    anchor.textContent = anchor.textContent.slice(1); // Remove the first character from anchor node

    const checkAdditionAnchor = checkAdditionSpan(anchor);
    if (checkAdditionAnchor) {
        // Only do the char removal for addition
        return (checkAdditionAnchor as HTMLSpanElement).getAttribute("data-identifier");
    }

    const { left } = findSiblingLeafNodes(anchor);

    // Possibly combine char into deletion node if it exists
    if (left && left.textContent) {
        const checkDeletion = checkDeletionSpan(left);
        if (checkDeletion && shouldCombine(checkDeletion)) {
            const newText = left.textContent + firstChar;
            updateSpanWithText(checkDeletion, newText);
            return (checkDeletion as HTMLSpanElement).getAttribute("data-identifier");
        }
    }

    // If there was no deletion node to merge with, we create a new one
    const newDeletion = createSpanWithText({ "data-change-type": "deletion" }, firstChar, user.id);

    let parentRef = anchor.parentNode;
    let placeRef = anchor;
    const checkAddition = checkAdditionSpan(anchor);
    if (checkAddition) {
        parentRef = checkAddition.parentNode;
        placeRef = checkAddition;
    }
    const checkDeletion = checkDeletionSpan(anchor);
    if (checkDeletion) {
        parentRef = checkDeletion.parentNode;
        placeRef = checkDeletion;
    }

    if (parentRef) {
        // Insert new deletion after anchor
        parentRef.insertBefore(newDeletion, placeRef);

        // Place cursor at start of new deletion
        setCursorAfterElement(newDeletion);

        return (newDeletion as HTMLSpanElement).getAttribute("data-identifier");
    }

    return null;
}

/**
 * Handles the delete operation inside a node at a specific character offset.
 * This function identifies the type of the node (whether it's a deletion, addition, or neutral text span) and
 * applies the appropriate delete handling logic based on the node type:
 * - If the node is a deletion span, no action is taken.
 * - If the node is an addition span, it delegates to `deleteInsideAddition` to handle the delete.
 * - For neutral text nodes, it delegates to `deleteInsideNeutral` to perform the delete operation.
 *
 * @param {Node} anchor - The node within which the delete operation is to be performed.
 * @param {number} offset - The character offset within the node where the delete operation is to occur.
 * @param {TenantUser} user - The user that made this change to the document.
 */
function deleteInside(anchor: Node, offset: number, user: TenantUser): string | null {
    if (!anchor) {
        return null;
    }

    if (checkDeletionSpan(anchor)) {
        return null;
    }

    // Handle backspace inside an addition node
    const checkAddition = checkAdditionSpan(anchor);
    if (checkAddition) {
        return deleteInsideAddition(anchor, offset);
    }

    // Handle backspace inside a regular text section
    return deleteInsideNeutral(anchor, offset, user);
}

/**
 * Handles the delete operation at the right edge of an anchor node by targeting the first character of the right sibling node.
 * This function performs several actions based on the state of the sibling node:
 * - If the right sibling node is absent or its text content is empty, no action is taken.
 * - If the right sibling node is a deletion span, deleting into it is disallowed.
 * - Otherwise, the first character of the right sibling's text is removed. If the anchor node is a deletion span and should be combined with this character, it does so.
 * - If no combination is required, it creates a new deletion span with the removed character and inserts it appropriately in the DOM.
 * Finally, the cursor is positioned after the newly created deletion span.
 *
 * @param {Node} anchor - The anchor node adjacent to which the delete operation is performed.
 * @param {TenantUser} user - The user that made this change to the document.
 */
function deleteRightEdge(anchor: Node, user: TenantUser): string | null {
    const { right } = findSiblingLeafNodes(anchor);

    if (!right) {
        return null;
    }

    if (isUserBR(right)) {
        (right as Element).remove();
        return null;
    }

    // Disallow deleting into deletion
    if (checkDeletionSpan(right) || isLineNumber(right) || !right.textContent) {
        return null;
    }

    const firstChar = right.textContent.charAt(0); // Save the first character from right node
    right.textContent = right.textContent.slice(1); // Remove the first character from right node

    const checkAdditionRight = checkAdditionSpan(right);
    if (checkAdditionRight) {
        // Only do the char removal for addition
        return (checkAdditionRight as HTMLSpanElement).getAttribute("data-identifier");
    }

    if (anchor && anchor.textContent) {
        // If anchor is a deletion we might combine
        const checkDeletion = checkDeletionSpan(anchor);
        if (checkDeletion && shouldCombine(checkDeletion)) {
            const newText = checkDeletion.textContent + firstChar;
            updateSpanWithText(checkDeletion, newText);
            return (checkDeletion as HTMLSpanElement).getAttribute("data-identifier");
        }
    }

    // ELSE create deletion node
    const newDeletion = createSpanWithText({ "data-change-type": "deletion" }, firstChar, user.id);

    // TODO:  The following ought to be abstracted since we do it for any new insertion
    // insertion as a whole can be abstracted into a method

    // Necessary step for when anchor refers to the text node of an addition/deletion
    let parentRef = anchor.parentNode;
    let placeRef = anchor.nextSibling;
    const checkAddition = checkAdditionSpan(anchor);
    if (checkAddition) {
        parentRef = checkAddition.parentNode;
        placeRef = checkAddition.nextSibling;
    }
    const checkDeletion = checkDeletionSpan(anchor);
    if (checkDeletion) {
        parentRef = checkDeletion.parentNode;
        placeRef = checkDeletion.nextSibling;
    }

    if (parentRef) {
        // Insert new deletion after anchor
        parentRef.insertBefore(newDeletion, placeRef);

        // Place cursor at start of new deletion
        setCursorAfterElement(newDeletion);

        return (newDeletion as HTMLSpanElement).getAttribute("data-identifier");
    }

    return null;
}

/**
 * Handles the delete operation inside an addition span at a specified character offset.
 * This function modifies the text content of the addition span based on the cursor's position:
 * - If the node contains only one character and is thus the final character, it removes the entire node from the DOM.
 * - For longer text, it deletes the character at the specified offset.
 * After modifying the text, the function adjusts the cursor position to reflect the change.
 *
 * @param {Node} anchor - The addition span node within which the delete operation is performed.
 * @param {number} offset - The character offset within the node where the delete operation is to occur.
 */
function deleteInsideAddition(anchor: Node, offset: number): string | null {
    if (!anchor.textContent) {
        return null;
    }

    // If final char, remove the element
    if (anchor.textContent.length === 1) {
        anchor.parentNode?.removeChild(anchor); // TODO: what if parentNode is null?
        return null;
    }

    // delete character at offset
    anchor.textContent = anchor.textContent.slice(0, offset) + anchor.textContent.slice(offset + 1);

    // move cursor span
    setCursorWithElement(anchor.firstChild!, offset); // TODO: need better text child node selection method

    return (anchor as HTMLSpanElement).getAttribute("data-identifier");
}

/**
 * Handles the delete operation inside a neutral text node at a specified character offset.
 * This function deletes a character at the given offset and wraps the deleted character in a new deletion span.
 * It adjusts the surrounding text and updates the DOM to reflect these changes:
 * - Extracts the text before and after the character at the specified offset.
 * - Wraps the character at the offset into a new deletion span.
 * - Reconstructs the text nodes around the deletion span and replaces the original text node with these new nodes.
 * After modifying the DOM, the cursor is moved to a position before the newly created deletion span.
 *
 * @param {Node} anchor - The text node within which the delete operation is performed.
 * @param {number} offset - The character offset within the text node where the delete operation is to occur.
 * @param {TenantUser} user - The user that made this change to the document.
 */
function deleteInsideNeutral(anchor: Node, offset: number, user: TenantUser): string | null {
    if (!anchor.textContent || anchor.textContent.length < offset + 1) {
        return null;
    }

    // Extract text contents based on carot osition (offset)
    const beforeText = anchor.textContent.slice(0, offset);
    const charToWrap = anchor.textContent.charAt(offset);
    const afterText = anchor.textContent.slice(offset + 1);

    // Create necessary nodes
    const newDeletion = createSpanWithText({ "data-change-type": "deletion" }, charToWrap, user.id);
    const beforeTextNode = document.createTextNode(beforeText);
    const afterTextNode = document.createTextNode(afterText);

    const parent = anchor.parentNode;

    if (parent) {
        // Insert new nodes into DOM
        parent.insertBefore(beforeTextNode, anchor);
        parent.insertBefore(newDeletion, anchor);
        parent.insertBefore(afterTextNode, anchor);
        parent.removeChild(anchor);

        // Move cursor before new deletion node
        setCursorBeforeElement(afterTextNode);

        return (newDeletion as HTMLSpanElement).getAttribute("data-identifier");
    }

    return null;
}

export function handleLineBreak(anchor: Node, offset: number, location: nodeBound) {
    if (!anchor) {
        return;
    }

    const enterNode = document.createElement("br");
    enterNode.setAttribute("data-change-type", "addition");
    let parentNode: Node | null = anchor.parentNode;
    let insertionAnchor: Node = anchor;

    if (location === "inside") {
        // Node instances used for updating the DOM
        let startNode: Node;
        let endNode: Node;

        // Default to anchor's textContent
        const textContent = anchor.textContent;

        // split textcontent
        const startText = textContent?.slice(0, offset) ?? "";
        const endText = textContent?.slice(offset) ?? "";

        // fill new nodes
        const checkAddition = checkAdditionSpan(anchor);
        if (checkAddition) {
            parentNode = checkAddition.parentNode;
            insertionAnchor = checkAddition;
            const userID =
                (checkAddition as HTMLSpanElement).getAttribute("data-user") ?? "unknown";
            // If it is an addition, we need new addition spans in order to split the text
            startNode = createSpanWithText({ "data-change-type": "addition" }, startText, userID);
            endNode = createSpanWithText({ "data-change-type": "addition" }, endText, userID);
        } else {
            startNode = document.createTextNode(startText);
            endNode = document.createTextNode(endText);
        }

        if (parentNode) {
            if (checkDeletionSpan(parentNode)) {
                return;
            }
            // Insert start into the DOM
            parentNode.insertBefore(startNode, insertionAnchor);

            // Insert an extra br if cursor is at end of regular line in table formatted CP
            let enterNodeExtra;
            if (!checkAdditionSpan(anchor) && offset === anchor.textContent?.length) {
                enterNodeExtra = document.createElement("br");
                enterNodeExtra.setAttribute("data-change-type", "addition");
                parentNode.insertBefore(enterNodeExtra, insertionAnchor);
            }

            // Insert enter and end
            parentNode.insertBefore(enterNode, insertionAnchor);
            parentNode.insertBefore(endNode, insertionAnchor);
            parentNode.removeChild(insertionAnchor);

            // Set the cursor position after the new span
            setCursorAfterElement(enterNodeExtra ?? enterNode);
        }
    }

    if (location === "right") {
        // add br after anchor
        insertionAnchor = anchor.nextSibling as Node;

        const checkDeletion = checkDeletionSpan(anchor);
        if (checkDeletion) {
            insertionAnchor = checkDeletion.nextSibling as Node;
            parentNode = checkDeletion.parentNode;
            // return;
        }

        if (parentNode) {
            // Insert an extra br if cursor is at end of regular line in table formatted CP
            // checks if anchor char length is equal to offset OR if there are 2 fr-markers present it allows a discrepancy of 2
            if (
                !checkDeletion &&
                !checkAdditionSpan(anchor) &&
                (offset === anchor.textContent?.length ||
                    (offset + 2 === anchor.textContent?.length && containsFrMarkers(anchor)))
            ) {
                parentNode.insertBefore(document.createElement("br"), insertionAnchor);
            }
            parentNode.insertBefore(enterNode, insertionAnchor);
            setCursorAfterElement(enterNode);
        }
    }
}
