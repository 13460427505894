import React, { Fragment, useState } from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Button } from "../../components/ui/button";
import { Cloud, FileDown, PenTool, Plus } from "lucide-react";
import NewDocument from "./new-document";
import Upload from "./upload";
import { useCaseFiles } from "../../lib/providers/CaseFilesProvider";
import ProFormaCode from "./pro-forma-code";
import ImportQ88 from "./import-q88";
import { AddFileProps, DocumentComponentProps, DocumentTypes } from "../../types/case-files";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../components/ui/tooltip";

function DocumentComponent({ id, onClose, isOpen, documentType }: DocumentComponentProps) {
    if (!documentType) {
        return null;
    }
    switch (documentType) {
        case "document":
            return <NewDocument id={id} onClose={onClose} isOpen={isOpen} />;
        case "upload":
            return <Upload id={id} onClose={onClose} isOpen={isOpen} />;
        case "pro-forma":
            return <ProFormaCode id={id} onClose={onClose} isOpen={isOpen} />;
        case "import-q88":
            return <ImportQ88 id={id} onClose={onClose} isOpen={isOpen} />;
        default:
            return null;
    }
}

function AddFile({ id, TriggerElement }: AddFileProps) {
    const { permissions } = useCaseFiles();
    const [newDocumentType, setNewDocumentType] = useState<DocumentTypes>("none");
    const [sheetIsTriggered, setSheetIsTriggered] = useState<boolean>(false);

    function handleOpenSheet(documentType: DocumentTypes) {
        setSheetIsTriggered(true);
        setNewDocumentType(documentType);
    }

    function handleCloseSheet() {
        setSheetIsTriggered(false);
        setNewDocumentType("none");
    }

    return (
        <Fragment>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    {TriggerElement ? (
                        TriggerElement
                    ) : (
                        <div>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button>
                                        <Plus size="16" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent side="bottom">Add a document</TooltipContent>
                            </Tooltip>
                        </div>
                    )}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                    <DropdownMenuLabel>New document</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <Button
                        variant="ghost"
                        className="w-full justify-start"
                        onClick={() => handleOpenSheet("document")}
                        disabled={!permissions.create_documents}
                    >
                        <DropdownMenuItem>
                            <PenTool className="mr-2 h-4 w-4" />
                            <span>Standard form</span>
                        </DropdownMenuItem>
                    </Button>
                    <Button
                        variant="ghost"
                        className="w-full justify-start"
                        onClick={() => handleOpenSheet("upload")}
                        disabled={!permissions.upload_documents}
                    >
                        <DropdownMenuItem>
                            <Cloud className="mr-2 h-4 w-4" />
                            <span>Upload attachment</span>
                        </DropdownMenuItem>
                    </Button>
                    <Button
                        variant="ghost"
                        className="w-full justify-start"
                        disabled={!permissions.create_documents}
                        onClick={() => handleOpenSheet("import-q88")}
                    >
                        <DropdownMenuItem>
                            <img
                                className="mr-2 h-4 w-4 stroke-current"
                                alt="q88"
                                src="/images/q88.svg"
                            />
                            <span>Import Q88</span>
                        </DropdownMenuItem>
                    </Button>
                    <Button
                        variant="ghost"
                        className="w-full justify-start"
                        onClick={() => handleOpenSheet("pro-forma")}
                        disabled={!permissions.create_documents}
                    >
                        <DropdownMenuItem>
                            <FileDown className="mr-2 h-4 w-4" />
                            <span>Pro-forma</span>
                        </DropdownMenuItem>
                    </Button>
                </DropdownMenuContent>
            </DropdownMenu>
            <DocumentComponent
                id={id}
                onClose={handleCloseSheet}
                isOpen={sheetIsTriggered}
                documentType={newDocumentType}
            />
        </Fragment>
    );
}

export default AddFile;
