// eslint-disable-next-line @typescript-eslint/no-var-requires
const FroalaEditorMain = require("froala-editor");

export const zoomInSvg =
    "\n" +
    '<svg width="19px" height="24px" viewBox="0 0 19 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
    '    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
    '        <g id="Group" fill="#000000" fill-rule="nonzero">\n' +
    '            <path d="M16.7310254,10.7120005 C17.3192768,8.51677898 17.0173232,6.2238105 15.8809812,4.2555306 C14.3654955,1.63065823 11.5394115,0 8.50544516,0 C7.0199098,0 5.55125552,0.394663399 4.25808283,1.14124294 C2.28980293,2.27758492 0.881866099,4.1124498 0.293614788,6.3078075 C-0.294636522,8.50302907 0.00718091732,10.7959975 1.14365903,12.7642774 C2.6591447,15.3891498 5.48536484,17.019808 8.51933121,17.019808 C9.66424987,17.019808 10.7989582,16.7844258 11.851167,16.3350351 L16.2765976,24 L18.0450272,22.9789667 L13.6194605,15.3137295 C15.1408001,14.1764346 16.2319444,12.5750459 16.7310254,10.7120005 Z M11.7456603,14.1101355 C10.7626094,14.6776938 9.64682424,14.9777415 8.51933121,14.9777415 C6.21261281,14.9777415 4.06395037,13.7383432 2.91208868,11.7432442 C2.04843066,10.2473623 1.81903852,8.50466272 2.26611496,6.83643047 C2.7131914,5.1677898 3.78323428,3.77333061 5.27911612,2.90967259 C6.2623031,2.34211422 7.377816,2.04206657 8.50544516,2.04206657 C10.8121636,2.04206657 12.9606899,3.28146484 14.1125516,5.27656388 C14.9762096,6.77244572 15.2056017,8.51514533 14.7586614,10.1835137 C14.311585,11.8520182 13.2415421,13.2464774 11.7456603,14.1101355 Z M9.53342147,7.48880267 L11.9158325,7.48880267 L11.9158325,9.53086924 L9.53342147,9.53086924 L9.53342147,11.9132802 L7.4913549,11.9132802 L7.4913549,9.53086924 L5.1089439,9.53086924 L5.1089439,7.48880267 L7.4913549,7.48880267 L7.4913549,5.10639167 L9.53342147,5.10639167 L9.53342147,7.48880267 Z" id="Shape"></path>\n' +
    "        </g>\n" +
    "    </g>\n" +
    "</svg>";

export const zoomOutSvg =
    "\n" +
    '<svg width="19px" height="24px" viewBox="0 0 19 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
    '    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
    '        <g id="Group" fill="#000000" fill-rule="nonzero">\n' +
    '            <path d="M16.7310254,10.7120005 C17.3192767,8.51677898 17.0173231,6.2238105 15.8809811,4.2555306 C14.3654955,1.63065823 11.5394115,0 8.50544516,0 C7.01990979,0 5.55125552,0.394663399 4.25808283,1.14124294 C2.28980293,2.27758492 0.881866099,4.1124498 0.293614788,6.3078075 C-0.294636522,8.50302907 0.00718091713,10.7959975 1.14365903,12.7642774 C2.6591447,15.3891498 5.48536484,17.019808 8.51933121,17.019808 C9.66424987,17.019808 10.7989582,16.7844258 11.851167,16.3350351 L16.2765975,24 L18.0450272,22.9789667 L13.6194605,15.3137295 C15.1408001,14.1764346 16.2319443,12.5750459 16.7310254,10.7120005 Z M11.7456603,14.1101355 C10.7626094,14.6776938 9.64682423,14.9777415 8.51933121,14.9777415 C6.21261281,14.9777415 4.06395036,13.7383432 2.91208868,11.7432442 C2.04843066,10.2473623 1.81903851,8.50466272 2.26611495,6.83643047 C2.7131914,5.1677898 3.78323428,3.77333061 5.27911611,2.90967259 C6.2623031,2.34211422 7.377816,2.04206657 8.50544516,2.04206657 C10.8121636,2.04206657 12.9606899,3.28146484 14.1125515,5.27656388 C14.9762095,6.77244572 15.2056017,8.51514533 14.7586614,10.1835137 C14.3115849,11.8520182 13.2415421,13.2464774 11.7456603,14.1101355 Z M11.9158325,7.48880267 L11.9158325,9.53086924 L5.1089439,9.53086924 L5.1089439,7.48880267 L11.9158325,7.48880267 Z" id="Shape"></path>\n' +
    "        </g>\n" +
    "    </g>\n" +
    "</svg>";

class ZoomInEditor {
    private static instance: ZoomInEditor;
    private _wrapper: Element | undefined;
    private _zoomStep: 0 | 1 | 2;

    constructor() {
        this._wrapper = undefined;
        this.zoomStep = 0;
    }

    public static getInstance(): ZoomInEditor {
        if (!ZoomInEditor.instance) {
            ZoomInEditor.instance = new ZoomInEditor();
        }

        return ZoomInEditor.instance;
    }

    public init() {
        this.registerZoomInButton();
        this.registerZoomOutButton();
    }

    public registerReference(ref: any) {
        if (ref.current) {
            this.wrapper = ZoomInEditor.getWrapper(ref);
        }
    }

    private registerZoomInButton() {
        FroalaEditorMain.DefineIcon("zoomIn", {});
        FroalaEditorMain.RegisterCommand("zoomIn", {
            title: "Zoom in",
            icon: zoomInSvg,
            undo: true,
            focus: true,
            showOnMobile: true,
            refreshAfterCallback: true,
            callback: () => this.handleZoomIn(),
        });
    }

    private registerZoomOutButton() {
        FroalaEditorMain.DefineIcon("zoomOut", {});
        FroalaEditorMain.RegisterCommand("zoomOut", {
            title: "Zoom out",
            icon: zoomOutSvg,
            undo: true,
            focus: true,
            showOnMobile: true,
            refreshAfterCallback: true,
            callback: () => this.handleZoomOut(),
        });
    }

    private static getWrapper(ref: any): Element | undefined {
        let froalaWrapperElement: Element | undefined;
        let mainNode;
        if (ref.current.el) {
            mainNode = ref.current.el;
        } else {
            mainNode = ref.current;
        }

        for (let i = 0; i < mainNode.children.length; i++) {
            if (
                mainNode.children[i].classList.contains("fr-wrapper") ||
                mainNode.children[i].classList.contains("fr-wrapper-final") // it's a final document
            ) {
                froalaWrapperElement = mainNode.children[i];

                if (
                    froalaWrapperElement?.firstElementChild &&
                    froalaWrapperElement.firstElementChild.classList.contains("fr-view")
                ) {
                    froalaWrapperElement = froalaWrapperElement.firstElementChild;

                    break;
                }

                break;
            }
        }

        return froalaWrapperElement;
    }

    private incrementZoomStep() {
        if (this.zoomStep === 0) {
            return 1;
        } else if (this.zoomStep === 1) {
            return 2;
        } else {
            return 0;
        }
    }

    private hideSidePanel() {
        window.document
            .getElementById("editor-side-panel")
            ?.classList.remove("xl:w-[300px]", "xl:static", "xl:bottom-auto", "xl:z-auto");

        window.document
            .getElementById("editor-side-panel")
            ?.firstElementChild?.classList.remove("xl:ml-0");
    }

    private showSidePanel() {
        window.document
            .getElementById("editor-side-panel")
            ?.classList.add("xl:w-[300px]", "xl:static", "xl:bottom-auto", "xl:z-auto");

        window.document
            .getElementById("editor-side-panel")
            ?.firstElementChild?.classList.add("xl:ml-0");
    }

    public handleZoomIn() {
        if (this.wrapper) {
            if (this.incrementZoomStep() === 0) {
                return;
            } else {
                this.hideSidePanel();
            }

            const zoomStep = this.incrementZoomStep();
            const scale = 1 + zoomStep * 0.5;

            const maxWidth =
                zoomStep === 1
                    ? "calc(100vw - 360px)"
                    : zoomStep === 2
                      ? "calc(50vw - 120px)"
                      : "100%";

            this.wrapper.style.transition = "all 0.125s ease-in";
            this.wrapper.style.transform = `scale(${scale})`;
            this.wrapper.style.transformOrigin = "top center";
            this.wrapper.style.maxWidth = `${maxWidth}`;
            this.zoomStep = zoomStep;
        }
    }

    public handleZoomOut() {
        if (this.wrapper) {
            this.showSidePanel();

            this.zoomStep = 0;
            this.wrapper.style.transition = "all 0.125s ease-in";
            this.wrapper.style.maxWidth = `100%`;
            this.wrapper.style.transform = `scale(1)`;
        }
    }

    get wrapper(): any {
        return this._wrapper;
    }

    set wrapper(value: any) {
        this._wrapper = value;
    }

    get zoomStep(): 0 | 1 | 2 {
        return this._zoomStep;
    }

    set zoomStep(value: 0 | 1 | 2) {
        this._zoomStep = value;
    }
}

export default ZoomInEditor;
