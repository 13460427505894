import {
    NewDocumentProps,
    TemplateIndex,
    TemplatesType,
    TemplateTypes,
} from "../../types/case-files";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { Fragment, useEffect, useState } from "react";
import { useCreateDocumentMutation, useLazyGetTemplatesQuery } from "../../api/tenant/case-file";
import { toast } from "sonner";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "../../components/ui/sheet";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { Switch } from "../../components/ui/switch";
import Loading from "../../components/global/loading";
import { useNavigate } from "react-router";
import { useCaseFiles } from "../../lib/providers/CaseFilesProvider";
import SearchableList from "../../components/searchable-list/searchable-list";
import { Zap } from "lucide-react";

function NewDocument({ id, onClose, isOpen, isFirstCharterParty }: NewDocumentProps) {
    const schema = z.object({
        document_name: z.string().min(2, {
            message: "Document name must be at least 2 characters.",
        }),
        type: z.enum(["standard_form", "bill_of_lading", "sof", "nor", "loi", "house_form"]),
        template_id: z.string(),
        formatting: z.boolean(),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            document_name: "Standard form",
            type: "standard_form",
            template_id: undefined,
            formatting: true,
        },
    });
    const { watch } = form;
    const [templatesLoaded, setTemplatesLoaded] = useState<boolean>(false);
    const [createDocument] = useCreateDocumentMutation();
    const [getTemplates] = useLazyGetTemplatesQuery();
    const [templates, setTemplates] = useState<TemplatesType>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(true);
    const { case_file_id } = useCaseFiles();

    const navigate = useNavigate();

    useEffect(() => {
        handleFetchTemplates("standard_form");
    }, []);

    useEffect(() => {
        if (!open && !loading) {
            onClose();
        }
    }, [open]);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        try {
            const document_id = await createDocument({
                case_file_id: case_file_id,
                options: {
                    tc_formatting: values.type === "bill_of_lading" ? false : values.formatting,
                },
                ...values,
            }).unwrap();

            toast.success("successfully created " + values.document_name);

            onClose();

            if (isFirstCharterParty) {
                navigate(`/case-file/${id}/${document_id}`);
            }
        } catch (e) {
            toast.error("Something went wrong while creating document");
        }
        setLoading(false);
    }

    async function handleFetchTemplates(type: TemplateTypes) {
        const mappedTemplates = templates as TemplatesType;

        if (!Object.prototype.hasOwnProperty.call(mappedTemplates, type)) {
            setTemplatesLoaded(false);
            mappedTemplates[type] = await getTemplates(type).unwrap();
            setTemplates(mappedTemplates);
        }
        setTemplatesLoaded(true);
    }

    function getTemplatesList(type: TemplateTypes) {
        return templates[type] ?? [];
    }

    return (
        <Sheet open={isOpen} onOpenChange={(x) => setOpen(x)}>
            <SheetContent
                className="bg-white"
                onInteractOutside={onClose}
                onEscapeKeyDown={onClose}
            >
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <SheetHeader>
                                {isFirstCharterParty ? (
                                    <Fragment>
                                        <SheetTitle className="text-slate-950">
                                            New charter party
                                        </SheetTitle>
                                        <SheetDescription>
                                            Create your first charter party
                                        </SheetDescription>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <SheetTitle className="text-slate-950">
                                            New document
                                        </SheetTitle>
                                        <SheetDescription>Create a new document</SheetDescription>
                                    </Fragment>
                                )}
                            </SheetHeader>
                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(handleSubmit)}>
                                    <FormField
                                        control={form.control}
                                        name="document_name"
                                        render={({ field }) => (
                                            <FormItem className="py-2">
                                                <FormLabel>
                                                    Document name{" "}
                                                    <span className="text-sm text-muted-foreground">
                                                        *
                                                    </span>
                                                </FormLabel>
                                                <FormControl>
                                                    <Input {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="type"
                                        render={({ field }) => (
                                            <FormItem className="py-2">
                                                <FormLabel>Document type</FormLabel>
                                                <Select
                                                    onValueChange={(value: TemplateTypes) => {
                                                        field.onChange(value);
                                                        handleFetchTemplates(value);
                                                        form.resetField("template_id");
                                                    }}
                                                    defaultValue={field.value}
                                                >
                                                    <FormControl>
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select what type of document" />
                                                        </SelectTrigger>
                                                    </FormControl>

                                                    {isFirstCharterParty ? (
                                                        <SelectContent>
                                                            <SelectItem value="standard_form">
                                                                Standard form
                                                            </SelectItem>
                                                        </SelectContent>
                                                    ) : (
                                                        <SelectContent>
                                                            <SelectItem value="standard_form">
                                                                Standard form
                                                            </SelectItem>
                                                            <SelectItem value="bill_of_lading">
                                                                Bill of lading
                                                            </SelectItem>
                                                            <SelectItem value="sof">SOF</SelectItem>
                                                            <SelectItem value="nor">NOR</SelectItem>
                                                            <SelectItem value="loi">LOI</SelectItem>
                                                            <SelectItem value="house_form">
                                                                Templates
                                                            </SelectItem>
                                                        </SelectContent>
                                                    )}
                                                </Select>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    {watch("type") && templates && templatesLoaded && (
                                        <FormField
                                            control={form.control}
                                            name="template_id"
                                            render={({ field }) => (
                                                <FormItem className="py-2 flex flex-col">
                                                    <FormLabel>Template</FormLabel>
                                                    <SearchableList<TemplateIndex>
                                                        groups={[
                                                            {
                                                                heading: undefined,
                                                                options: getTemplatesList(
                                                                    watch("type"),
                                                                ),
                                                            },
                                                        ]}
                                                        selectedValues={
                                                            new Set([
                                                                getTemplatesList(
                                                                    watch("type"),
                                                                ).find(
                                                                    (template) =>
                                                                        template.id === field.value,
                                                                ),
                                                            ])
                                                        }
                                                        onOptionSelected={(option) => {
                                                            if (!option) {
                                                                field.onChange(undefined);
                                                            } else if (option.size > 0) {
                                                                const [value] = option;
                                                                if (value) {
                                                                    field.onChange(value.id);
                                                                    form.setValue(
                                                                        "document_name",
                                                                        value?.name,
                                                                    );
                                                                }
                                                            } else {
                                                                field.onChange(undefined);
                                                            }
                                                        }}
                                                        searchPlaceholder="Search templates"
                                                        className="border border-input border-solid"
                                                        commandItemSelectedClassName="bg-blue-400 hover:bg-blue-400"
                                                        labelFormatter={(value) => value.name}
                                                    >
                                                        {(props) => (
                                                            <Fragment>
                                                                <span
                                                                    className={`flex items-center ${
                                                                        props.isSelected
                                                                            ? "text-white"
                                                                            : "text-gray-950"
                                                                    }`}
                                                                >
                                                                    <Zap
                                                                        className={`h-4 w-4 min-w-[16px] stroke-transparent mr-4 ${
                                                                            props.isSelected
                                                                                ? "fill-white"
                                                                                : "fill-blue-400"
                                                                        }
                                                                            ${
                                                                                props.option.value
                                                                                    .is_smart
                                                                                    ? "opacity-100"
                                                                                    : "opacity-0"
                                                                            }`}
                                                                    />
                                                                    <span className="text-balance">
                                                                        {props.option.value.name}
                                                                    </span>
                                                                </span>
                                                            </Fragment>
                                                        )}
                                                    </SearchableList>
                                                    <FormDescription className="flex">
                                                        Is a template missing? &nbsp;
                                                        <a
                                                            href="https://tally.so/r/w2XEqb"
                                                            target="_blank"
                                                            tabIndex={-1}
                                                            className="text-primary underline-offset-4 underline cursor-pointer"
                                                            rel="noreferrer"
                                                        >
                                                            Please submit a request.
                                                        </a>
                                                    </FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}
                                    {["sof", "nor", "loi"].includes(watch("type")) && (
                                        <FormField
                                            control={form.control}
                                            name="formatting"
                                            render={({ field }) => (
                                                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm my-2">
                                                    <div className="space-y-0.5">
                                                        <FormLabel>
                                                            Highlight changes to the printed text:
                                                        </FormLabel>
                                                    </div>
                                                    <FormControl>
                                                        <Switch
                                                            checked={field.value}
                                                            onCheckedChange={field.onChange}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />
                                    )}

                                    <SheetFooter className="mt-4">
                                        <SheetClose asChild>
                                            <Button
                                                type="submit"
                                                disabled={!form.formState.isValid}
                                            >
                                                Create
                                            </Button>
                                        </SheetClose>
                                    </SheetFooter>
                                </form>
                            </Form>
                        </Fragment>
                    }
                />
            </SheetContent>
        </Sheet>
    );
}

export default NewDocument;
