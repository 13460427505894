interface TooltipProps {
    fieldName: string;
    target: HTMLElement;
    parentContainer: HTMLElement;
}

function Tooltip({ fieldName, target, parentContainer }: TooltipProps) {
    const tooltip = document.createElement("span");
    tooltip.className = "placeholder-tooltip";
    tooltip.innerText = fieldName;

    target.appendChild(tooltip);

    const parentRect = parentContainer.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();

    // Adjust horizontal position
    if (tooltipRect.left < parentRect.left) {
        tooltip.style.left = "0";
        tooltip.style.transform = "none";
    } else if (tooltipRect.right > parentRect.right) {
        tooltip.style.right = "0";
        tooltip.style.left = "auto";
        tooltip.style.transform = "none";
    } else {
        tooltip.style.left = "50%";
        tooltip.style.transform = "translateX(-50%)";
    }

    // Adjust vertical position
    if (tooltipRect.top < parentRect.top) {
        tooltip.style.top = "100%";
        tooltip.style.bottom = "auto";
        tooltip.style.marginTop = "5px";
    } else {
        tooltip.style.bottom = "100%";
        tooltip.style.top = "auto";
        tooltip.style.marginBottom = "5px";
    }

    return tooltip;
}

export default Tooltip;
