import { tenantApi } from "../tenantApi";
import { ChatChannel } from "../../types/chat";
import { DuplicateCaseFileRequestType, FetchDocumentsInCaseFileType } from "../../types/overview";
import {
    ApprovalsInterface,
    ApprovalStatusTypes,
    AuditTrail,
    CaseFileDocumentIndex,
    CaseFileIndex,
    CaseFileMetaData,
    CaseFileStatusType,
    CreateSupplyChainInvite,
    FetchCaseFiles,
    PermissionTypes,
    RemoveRotApproval,
    RotOrganisation,
    SupplyChainInfo,
    SupplyChainInvite,
    TemplateIndex,
    TemplateTypes,
} from "../../types/case-files";
import { ColorSchemaInterface, WithCaseFileId } from "../../types";
import { RtkExtraConfig } from "../../lib/utils";

export interface ShareRequestDocument {
    id: string;
    name: string;
    type: string;
    extension: string;
    index: number;
    include: boolean;
}

export interface ShareRequest {
    note?: string;
    inline: {
        recap: boolean;
        riders: boolean;
    };
    documents: Array<ShareRequestDocument>;
    emails: Array<string>;
    coverPage: boolean;
    tableOfContents: boolean;
    colorScheme: string;
    coverStyle: string;
    logos: {
        broker?: string;
        owner?: string;
        charterer?: string;
    };
}

export const caseFilesApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getCaseFiles: build.query<FetchCaseFiles, void>({
            query: () => ({ url: "/case-files" }),
            providesTags: (result: FetchCaseFiles = { data: [] }) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "CaseFiles", id }) as const)
                    : []),
                { type: "CaseFiles" as const, id: "LIST" },
            ],
        }),
        getDocumentsInCaseFile: build.query<FetchDocumentsInCaseFileType, string>({
            query: (id) => ({ url: `/case-files/fetch-files-in-case-file/${id}` }),
        }),
        getRotLinksInCaseFile: build.query<Array<SupplyChainInvite>, string>({
            query: (id) => ({
                url: `/${id}/case-file/rot-links`,
            }),
            providesTags: (result: Array<SupplyChainInvite>) => [
                ...(result
                    ? result.map(({ id }) => ({ type: "CaseFilesRotLinks", id }) as const)
                    : []),
                { type: "CaseFilesRotLinks" as const, id: "LIST" },
            ],
        }),
        getAvailableRotOrganisations: build.query<Array<RotOrganisation>, string>({
            query: (id) => ({ url: `/main/organisation-names/${id}` }),
            providesTags: (result: Array<RotOrganisation>) => [
                ...(result
                    ? result.map(({ id }) => ({ type: "AvailableCaseFilesRotLinks", id }) as const)
                    : []),
                { type: "AvailableCaseFilesRotLinks" as const, id: "LIST" },
            ],
        }),
        createCaseFile: build.mutation<string, Partial<CaseFileIndex>>({
            query: (body) => ({
                url: "/case-files/new",
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "CaseFiles", id: "LIST" }],
        }),
        updateCaseFile: build.mutation<
            CaseFileIndex,
            Partial<CaseFileIndex> & Pick<CaseFileIndex, "id">
        >({
            query: ({ id, ...body }) => ({
                url: `/case-files/edit/${id}`,
                method: "POST",
                body: {
                    ...body,
                    cp_date: body?.cp_date
                        ? String(new Date(body.cp_date).toDateString())
                        : undefined,
                },
            }),
            invalidatesTags: [{ type: "CaseFiles", id: "LIST" }],
        }),
        duplicateCaseFile: build.mutation<
            boolean,
            DuplicateCaseFileRequestType & { caseFileId: string }
        >({
            query: (body) => ({
                url: "/case-files/duplicate",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "CaseFiles", id: "LIST" }],
        }),
        deleteCaseFile: build.mutation<
            boolean,
            { is_supply_chain: boolean; id: string; supply_chain_id?: string }
        >({
            query: ({ id, ...body }) => ({
                url: `/case-files/delete/${id}`,
                method: "DELETE",
                body,
            }),
            invalidatesTags: [{ type: "CaseFiles", id: "LIST" }],
        }),
        createRotLink: build.mutation<unknown, CreateSupplyChainInvite>({
            query: (body) => ({
                url: "/supply-chain/create",
                method: "POST",
                body,
            }),
            invalidatesTags: [
                {
                    type: "CaseFilesRotLinks",
                    id: "LIST",
                },
                {
                    type: "AvailableCaseFilesRotLinks",
                    id: "LIST",
                },
                {
                    type: "CaseFiles",
                    id: "LIST",
                },
                {
                    type: "CaseFileMetaData",
                },
            ],
            extraOptions: RtkExtraConfig,
        }),
        removeRotLink: build.mutation<boolean, string>({
            query: (id) => ({
                url: `/supply-chain/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                {
                    type: "CaseFilesRotLinks",
                    id: "LIST",
                },
                {
                    type: "AvailableCaseFilesRotLinks",
                    id: "LIST",
                },
                {
                    type: "CaseFileMetaData",
                },
                {
                    type: "CaseFiles",
                    id: "LIST",
                },
            ],
        }),
        updateRotLinkPermission: build.mutation<
            unknown,
            { permission: PermissionTypes } & { id: string }
        >({
            query: ({ id, ...body }) => ({
                url: `/supply-chain/${id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [
                {
                    type: "CaseFilesRotLinks",
                    id: "LIST",
                },
            ],
        }),
        markRotLinkForApproval: build.mutation<boolean, string>({
            query: (id) => ({
                url: `/approval/${id}`,
                method: "POST",
            }),
            invalidatesTags: [
                "CaseFileMetaData",
                {
                    type: "CaseFilesRotLinks",
                    id: "LIST",
                },
                {
                    type: "Documents",
                    id: "LIST",
                },
                {
                    type: "Approvals",
                    id: "LIST",
                },
                {
                    type: "CaseFiles",
                    id: "LIST",
                },
            ],
        }),
        removeRotApproval: build.mutation<boolean, RemoveRotApproval>({
            query: (body) => ({
                url: "/approval",
                method: "DELETE",
                body: body,
            }),
            invalidatesTags: [
                {
                    type: "CaseFilesRotLinks",
                    id: "LIST",
                },
                {
                    type: "Approvals",
                    id: "LIST",
                },
                {
                    type: "CaseFileMetaData",
                },
            ],
        }),
        getAuditTrail: build.query<
            { data: Array<AuditTrail> },
            WithCaseFileId<{ infinite: boolean }>
        >({
            query: ({ case_file_id, infinite }) => ({
                url: `/${case_file_id}/case-file/audit-trail/${infinite ? "inf" : ""}`,
            }),
            providesTags: (result: { data: Array<AuditTrail> }) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "CaseFileAuditTrail", id }) as const)
                    : []),
                { type: "CaseFileAuditTrail" as const, id: "LIST" },
            ],
            keepUnusedDataFor: 300,
        }),
        getDocuments: build.query<Array<CaseFileDocumentIndex>, string>({
            query: (id) => ({
                url: `${id}/case-file/files`,
            }),
            providesTags: (result: Array<CaseFileDocumentIndex>) => [
                ...(result ? result.map(({ id }) => ({ type: "Documents", id }) as const) : []),
                { type: "Documents" as const, id: "LIST" },
            ],
        }),
        duplicateDocument: build.mutation<boolean, WithCaseFileId<{ id: string }>>({
            query: ({ id, case_file_id }) => ({
                url: `/${case_file_id}/case-file/duplicate/${id}`,
                method: "POST",
            }),
            invalidatesTags: [
                {
                    type: "Documents",
                    id: "LIST",
                },
            ],
        }),
        deleteDocument: build.mutation<boolean, WithCaseFileId<{ id: string }>>({
            query: ({ id, case_file_id }) => ({
                url: `${case_file_id}/case-file/delete-document/${id}`,
                method: "POST",
            }),
            invalidatesTags: [
                {
                    type: "Documents",
                    id: "LIST",
                },
                {
                    type: "CaseFileFileUploads",
                    id: "LIST",
                },
            ],
        }),
        editDocument: build.mutation<
            boolean,
            WithCaseFileId<{ id: string; document_name: string }>
        >({
            query: ({ id, case_file_id, ...body }) => ({
                url: `${case_file_id}/case-file/edit/${id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [
                {
                    type: "Documents",
                    id: "LIST",
                },
            ],
        }),
        exportDocument: build.mutation<
            string,
            WithCaseFileId<{ id: string; schema: ColorSchemaInterface }>
        >({
            query: ({ id, case_file_id, ...body }) => ({
                url: `${case_file_id}/case-file/export-file/${id}`,
                method: "POST",
                body: body,
                responseHandler: "text",
            }),
        }),
        finalizeDocument: build.mutation<
            boolean | string,
            WithCaseFileId<{
                document_id: string;
                hasSignatures?: boolean;
            }>
        >({
            query: ({ document_id, case_file_id, ...body }) => ({
                url: `/${case_file_id}/case-file/finalize/${document_id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [
                {
                    type: "Documents",
                    id: "LIST",
                },
            ],
        }),
        unFinalizeDocument: build.mutation<
            boolean | string,
            WithCaseFileId<{ document_id: string }>
        >({
            query: ({ document_id, case_file_id }) => ({
                url: `/${case_file_id}/case-file/un-finalize/${document_id}`,
                method: "POST",
            }),
            invalidatesTags: [
                {
                    type: "Documents",
                    id: "LIST",
                },
            ],
        }),
        createDocument: build.mutation<
            string,
            WithCaseFileId<{
                type: TemplateTypes;
                template_id: string;
                document_name: string;
                options: { tc_formatting: boolean };
            }>
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `/${case_file_id}/case-file/create-document`,
                method: "POST",
                body: body,
                responseHandler: "text",
            }),
            invalidatesTags: [
                {
                    type: "Documents",
                    id: "LIST",
                },
            ],
        }),
        getTemplates: build.query<Array<TemplateIndex>, TemplateTypes>({
            query: (type) => ({
                url: `/case-files/templates/${type}`,
            }),
            providesTags: (result: Array<TemplateIndex>) => [
                ...(result ? result.map(({ id }) => ({ type: "Templates", id }) as const) : []),
                { type: "Templates" as const, id: "LIST" },
            ],
        }),
        getSupplyChainInfo: build.query<SupplyChainInfo | undefined, string>({
            query: (case_file_id) => ({
                url: `/${case_file_id}/case-file/supply-chain-info`,
            }),
        }),
        getCaseFileChat: build.query<{ channel: ChatChannel }, string>({
            query: (id) => ({
                url: `/${id}/case-file/chat`,
            }),
        }),
        getCaseFileApprovals: build.query<Array<ApprovalsInterface>, string>({
            query: (id) => ({
                url: `/${id}/case-file/approvals`,
            }),
            providesTags: (result: Array<ApprovalsInterface>) => [
                ...(result ? result.map(({ id }) => ({ type: "Approvals", id }) as const) : []),
                { type: "Approvals" as const, id: "LIST" },
            ],
        }),
        updateApprovalStatus: build.mutation<
            boolean,
            WithCaseFileId<{ id: string; type: ApprovalStatusTypes; note?: string }>
        >({
            query: ({ id, case_file_id, ...body }) => ({
                url: `/approval/${case_file_id}/update/${id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [
                {
                    type: "Approvals",
                    id: "LIST",
                },
                {
                    type: "CaseFileMetaData",
                },
            ],
        }),
        markAsHouseForm: build.mutation<
            boolean,
            WithCaseFileId<{ id: string; name: string; tenant_id: string }>
        >({
            query: ({ id, case_file_id, ...body }) => ({
                url: `/${case_file_id}/case-file/add-as-template/${id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [{ type: "Houseforms", id: "LIST" }],
        }),
        updateCaseFileStatus: build.mutation<
            boolean,
            WithCaseFileId<{ status: CaseFileStatusType }>
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `/case-files/edit/status/${case_file_id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["CaseFiles", "CaseFileMetaData"],
        }),
        toggleFollowCaseFile: build.mutation<boolean, WithCaseFileId<{ user_id: string }>>({
            query: ({ case_file_id, ...body }) => ({
                url: `/case-files/toggle-follow/${case_file_id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["CaseFiles", "CaseFileMetaData"],
        }),
        getCaseFileMetaData: build.query<CaseFileMetaData, string>({
            query: (id) => ({
                url: `/${id}/case-file/single`,
            }),
            providesTags: ["CaseFileMetaData"],
        }),
        circulateCaseFile: build.mutation<
            { message: string },
            WithCaseFileId<{ data: ShareRequest }>
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `/${case_file_id}/case-file/circulate`,
                method: "POST",
                body: body.data,
            }),
        }),
        multiExport: build.mutation<{ fileName: string }, WithCaseFileId<{ data: ShareRequest }>>({
            query: ({ case_file_id, ...body }) => ({
                url: `/${case_file_id}/case-file/export-multiple`,
                method: "POST",
                body: body.data,
            }),
        }),
    }),
});

export const {
    useGetCaseFilesQuery,
    useCreateCaseFileMutation,
    useUpdateCaseFileMutation,
    useGetDocumentsInCaseFileQuery,
    useDuplicateCaseFileMutation,
    useDeleteCaseFileMutation,
    useGetAvailableRotOrganisationsQuery,
    useLazyGetRotLinksInCaseFileQuery,
    useCreateRotLinkMutation,
    useRemoveRotLinkMutation,
    useUpdateRotLinkPermissionMutation,
    useMarkRotLinkForApprovalMutation,
    useRemoveRotApprovalMutation,
    useLazyGetAuditTrailQuery,
    useDuplicateDocumentMutation,
    useLazyGetDocumentsQuery,
    useDeleteDocumentMutation,
    useEditDocumentMutation,
    useExportDocumentMutation,
    useFinalizeDocumentMutation,
    useUnFinalizeDocumentMutation,
    useCreateDocumentMutation,
    useLazyGetTemplatesQuery,
    useGetCaseFileChatQuery,
    useLazyGetSupplyChainInfoQuery,
    useLazyGetCaseFileApprovalsQuery,
    useUpdateApprovalStatusMutation,
    useMarkAsHouseFormMutation,
    useMultiExportMutation,
    useUpdateCaseFileStatusMutation,
    useToggleFollowCaseFileMutation,
    useCirculateCaseFileMutation,
    useLazyGetCaseFileMetaDataQuery,
} = caseFilesApi;
