import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { flexRow } from "../../../styles/mixins";
import { theme } from "../../../styles";
import { ComponentWithChildElement } from "../../../../types/auth";

const Wrapper = styled.section`
    ${flexRow};
    flex: 1;
    position: absolute;
    height: calc(100% - 2em - 5em);
`;
const TenantAppPage = styled.section`
    margin: 2em 2em 0 2em;
    position: relative;
    height: calc(100% - 2em);
    width: calc(100% - 4em);
`;
export const AdminBasePage = ({ children }: ComponentWithChildElement) => {
    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <TenantAppPage>{children}</TenantAppPage>
            </Wrapper>
        </ThemeProvider>
    );
};
