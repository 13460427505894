import React, { Fragment, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { useRiderTerms } from "../../../lib/providers/rider-terms-provider";
import Loading from "../../../components/global/loading";

function RidersDelete() {
    const { riderState, riderDispatch, removeTerm } = useRiderTerms();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);
        await removeTerm(riderState.activeRiderTerm!.id);
        setLoading(false);
    }

    function handleClose() {
        riderDispatch({
            type: "SET_SHOW_DELETE_MODAL",
            payload: false,
        });
    }

    return (
        <Dialog open={riderState.showDeleteModal} onOpenChange={handleClose}>
            <DialogContent>
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <DialogHeader>
                                <DialogTitle>
                                    Delete {riderState.activeRiderTerm?.title}
                                </DialogTitle>
                                <DialogDescription>Are you sure?</DialogDescription>
                            </DialogHeader>
                            <DialogFooter className="mt-4">
                                <Button type="submit" onClick={handleSubmit} variant="destructive">
                                    Delete
                                </Button>
                            </DialogFooter>
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

export default RidersDelete;
