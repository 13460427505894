import { RotActions } from "../../../types/case-files";
import {
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Alert, AlertDescription } from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import React, { Fragment, useEffect, useState } from "react";
import { useRemoveRotLinkMutation } from "../../../api/tenant/case-file";
import { toast } from "sonner";
import Loading from "../../../components/global/loading";

function RotDelete({ rot_link, onClose }: RotActions) {
    const [removeRotLink, result] = useRemoveRotLinkMutation();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (result.status === "fulfilled") {
            toast.success("Successfully removed ROT link");
        }
    }, [result]);

    async function handleSubmit() {
        setLoading(true);
        await removeRotLink(rot_link.id);
        onClose();

        setLoading(false);
    }

    return (
        <DialogContent>
            <Loading
                loaded={!loading}
                child={
                    <Fragment>
                        <DialogHeader>
                            <DialogTitle>Withdraw access</DialogTitle>
                        </DialogHeader>
                        <Alert className="mb-2">
                            <AlertDescription className="text-slate-950">
                                Are you sure you want to remove access to this Recap C/P? They will
                                keep a copy of the current edition of the Recap C/P but any future
                                changes you make will not be synchronized to their account.
                            </AlertDescription>
                        </Alert>
                        <DialogFooter className="mt-4">
                            <Button type="submit" onClick={handleSubmit}>
                                Remove
                            </Button>
                        </DialogFooter>
                    </Fragment>
                }
            />
        </DialogContent>
    );
}

export default RotDelete;
