import React, { Fragment } from "react";
import { ShipWheel } from "lucide-react";

type LoadingProps = {
    loaded: boolean;
    child: JSX.Element;
};

function Loading({ loaded, child }: LoadingProps) {
    return (
        <Fragment>
            {loaded ? (
                child
            ) : (
                <div className="flex h-full w-full min-h-64 items-center justify-center relative">
                    <ShipWheel className={"w-48 animate-spin absolute"} />
                    <div
                        className="inline-block  opacity-50 absolute h-8 w-8 animate-spin-reverse  rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default Loading;
