import { NotificationProps } from "../../types/notifications";
import { Button } from "../ui/button";
import { Typography } from "../ui/typography";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import React, { Fragment } from "react";
import { useNotifications } from "../../lib/providers/notifications-provider";
import { formatDistance } from "date-fns";

function MultiThreadNotification({ notification }: NotificationProps) {
    const { generateMessage } = useNotifications();

    return (
        <div className="space-y-1">
            <h4 className="text-sm font-medium leading-none flex items-center">
                {notification.status === "new" ? (
                    <span className="flex h-2 w-2 rounded-full bg-sky-500 mr-2" />
                ) : null}
                Multiple changes made to&nbsp;
                <Button variant="link" size="link">
                    {notification.document_name}
                </Button>
            </h4>
            <Typography
                text={`
                    ${formatDistance(new Date(notification.messages[0].updated_at), new Date(), { addSuffix: true })}
                     - 
                    ${notification.channel.channel_name}
                `}
                style="muted"
                className="ml-4"
            />
            <Accordion type="single" collapsible className="ml-4">
                <AccordionItem value="item-1">
                    <AccordionTrigger>Messages</AccordionTrigger>
                    <AccordionContent>
                        {notification.messages.map((message, index) => (
                            <Typography
                                text={
                                    <Fragment>
                                        <b>{message.user_name}</b>
                                        {generateMessage(message.action_type)}
                                        {formatDistance(new Date(message.updated_at), new Date(), {
                                            addSuffix: true,
                                        })}
                                    </Fragment>
                                }
                                style="muted"
                                key={index}
                            />
                        ))}
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default MultiThreadNotification;
