import React, { Fragment } from "react";
import { EditStatusDocumentProps } from "../../../types/case-files";
import { formatCaseFileStatus } from "../../../lib/utils";
import { Circle, CircleCheckBig } from "lucide-react";

function EditStatusDocument({ status, isSelected, onSubmit }: EditStatusDocumentProps) {
    return (
        <Fragment>
            {
                <div
                    onClick={() => {
                        if (!isSelected) {
                            return onSubmit(status);
                        }
                    }}
                    className={`flex flex-row-reverse font-medium items-center p-0.5 ${
                        isSelected ? "cursor-auto" : "cursor-pointer"
                    }`}
                >
                    <span className={isSelected ? "text-gray-500" : "text-gray-950"}>
                        {formatCaseFileStatus(status)}
                    </span>
                    {isSelected ? (
                        <CircleCheckBig className="mr-2 h-4 w-4" />
                    ) : (
                        <Circle className="mr-2 h-4 w-4" />
                    )}
                </div>
            }
        </Fragment>
    );
}

export default EditStatusDocument;
