import { useLocation } from "react-router";
import { Archive, LogOut, PencilLine, TextSelect } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { Button } from "../ui/button";
import React from "react";
import NavBarItem, { NavBarItemProps } from "./nav-bar-item";
import { useAuth } from "../../lib/providers/AuthProvider";
import { Pages } from "../../types";

function NavBar() {
    const activePage: Pages = determineActivePage(useLocation().pathname ?? "");
    const { signOut } = useAuth();

    function determineActivePage(location: string): Pages {
        if (location.includes("archive")) {
            return "archive";
        }

        if (location.includes("templates")) {
            return "templates";
        }

        if (location.includes("overview") || location.includes("case-file")) {
            return "home";
        }

        return "none";
    }

    const menuItems: Array<Omit<NavBarItemProps, "activePage">> = [
        {
            icon: (
                <PencilLine
                    className={`h-4 w-4 ${activePage === "home" ? "stroke-primary" : ""}`}
                />
            ),
            tooltip: "Charter parties",
            page: "home",
        },
        {
            icon: (
                <Archive
                    className={`h-4 w-4 ${activePage === "archive" ? "stroke-primary" : ""}`}
                />
            ),
            tooltip: "Charter party archive",
            page: "archive",
        },
        {
            icon: (
                <TextSelect
                    className={`h-4 w-4 ${activePage === "templates" ? "stroke-primary" : ""}`}
                />
            ),
            tooltip: "Templates",
            page: "templates",
        },
    ];

    return (
        <nav className="w-12 z-20 bottom-0 top-0 pt-8 fixed bg-foreground flex border-r flex-col justify-between">
            <div className="flex flex-col w-full mt-5">
                {menuItems.map((item, index) => (
                    <NavBarItem key={index} {...item} activePage={activePage} />
                ))}
            </div>
            <div>
                <div
                    className={`
                    flex justify-center items-center w-full h-10 mb-2
                    border-solid
                    border-primary
                    hover:border-r-2
                    `}
                >
                    <Tooltip delayDuration={100}>
                        <TooltipTrigger asChild>
                            <Button variant="ghost" className=" h-full w-full" onClick={signOut}>
                                <LogOut className={"h-4 w-4"} />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent align="center" side="right">
                            Log out
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
