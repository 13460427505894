import { ColumnDef } from "@tanstack/react-table";
import { RecentActivity } from "../types/case-files";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { format } from "date-fns";

const columns: ColumnDef<RecentActivity>[] = [
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Date (CEST)" type="date-range" />;
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "tenant",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Tenant" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Entry type",
        },
    },
    {
        accessorKey: "user",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="User name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "User name",
        },
    },
    {
        accessorKey: "message",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Message" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Message",
        },
    },
    {
        accessorKey: "template",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Template name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Template name",
        },
    },
    {
        accessorKey: "total_revenue",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Total revenue" type="sort" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Total revenue",
        },
        cell: ({ row }) => {
            const value = row.getValue("total_revenue") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
    {
        accessorKey: "copyright_revenue",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Copyright revenue" type="sort" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Copyright revenue",
        },
        cell: ({ row }) => {
            const value = row.getValue("copyright_revenue") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
    {
        accessorKey: "service_revenue",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Service revenue" type="sort" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Service revenue",
        },
        cell: ({ row }) => {
            const value = row.getValue("service_revenue") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
];

export { columns };
