import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { WithCaseFileId } from "../../types";

interface RemoveVesselPayloadInterface {
    recapId: string;
    vesselId: string;
}

interface RecapVesselPayloadInterface {
    recap_document_id: string;
    q_88_id?: string | null;
}

interface RecapVesselEntityInterface extends RemoveVesselPayloadInterface {
    id: string;
    created_at: string | null;
    deleted_at: string | null;
    updated_at: string | null;
    user_id: string | null;
}

interface RecapVesselsState {
    loading: "initial" | "idle" | "pending" | "complete" | "failed";
    entities: RecapVesselEntityInterface[];
    selected?: string;
    error?: string;
}

const initialState: RecapVesselsState = {
    entities: [],
    loading: "initial",
};

const addVessel = createAsyncThunk(
    "vessels/add",
    async (payload: WithCaseFileId<RecapVesselPayloadInterface>) => {
        const { recap_document_id, case_file_id, ...postData } = payload;
        const { data } = await axios.post(
            `/api/tenant/v1/recaps/${case_file_id}/document/${recap_document_id}/vessels`,
            postData,
        );
        return data;
    },
);

const fetchVessels = createAsyncThunk(
    "vessels/fetch",
    async (body: { recapId: string; case_file_id: string }) => {
        const { data } = await axios.get(
            `/api/tenant/v1/recaps/${body.case_file_id}/document/${body.recapId}/vessels`,
        );
        return data;
    },
);

const removeVessel = createAsyncThunk(
    "vessels/remove",
    async (payload: WithCaseFileId<RemoveVesselPayloadInterface>) => {
        await axios.delete(
            `/api/tenant/v1/recaps/${payload.case_file_id}/document/${payload.recapId}/vessels/${payload.vesselId}`,
        );
        return payload;
    },
);

const selectFirst = (state: RecapVesselsState) => {
    if (state.entities.length) {
        state.selected = state.entities[0].id;
    } else {
        state.selected = undefined;
    }
};

const RecapVesselSlice = createSlice({
    name: "recap-vessels",
    initialState,
    reducers: {
        setSelectedVessel: (state, action: PayloadAction<string | undefined>) => {
            state.selected = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVessels.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(
                fetchVessels.fulfilled,
                (state, action: PayloadAction<RecapVesselEntityInterface[]>) => {
                    state.entities.splice(0, state.entities.length, ...action.payload);
                    selectFirst(state);
                    state.loading = "complete";
                },
            )
            .addCase(
                addVessel.fulfilled,
                (state, action: PayloadAction<RecapVesselEntityInterface>) => {
                    if (action.payload) {
                        const isDeselected = state.entities.length === 0;
                        state.entities.push(action.payload);
                        if (isDeselected) {
                            selectFirst(state);
                        }
                    }
                },
            )
            .addCase(removeVessel.fulfilled, (state, action) => {
                const index = state.entities.findIndex(
                    (entity) => entity.id === action.payload.vesselId,
                );
                if (index !== -1) {
                    const isDeletedSelected = state.entities[index].id === state.selected;
                    state.entities.splice(index, 1);
                    if (isDeletedSelected) {
                        selectFirst(state);
                    }
                }
            });
    },
});

export const { setSelectedVessel } = RecapVesselSlice.actions;
export { RecapVesselEntityInterface, RecapVesselPayloadInterface };
export { addVessel, removeVessel, fetchVessels };
export default RecapVesselSlice.reducer;
