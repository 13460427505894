import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import React from "react";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { JSX } from "react/jsx-runtime";
import { CSS } from "@dnd-kit/utilities";

type VerticalDragAndDropProps<Generic> = {
    onDragEnd: (sortedList: Array<Generic & { id: string }>) => void;
    items: Array<Generic & { id: string }>;
    listElement: (item: Generic, index: number) => JSX.Element;
    indexPropertyToUpdate?: keyof Generic;
    disabled?: boolean;
};

function VerticalDragAndDrop<Generic>({
    onDragEnd,
    items,
    indexPropertyToUpdate,
    listElement,
    disabled,
}: VerticalDragAndDropProps<Generic>) {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10, // only start ordering after moving 10 px to prevent overwriting onclick event
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    function handleDragEnd(event: DragEndEvent) {
        if (!event) {
            return;
        }
        const { active, over } = event;
        if (!over) {
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = items.map((x) => x.id).indexOf(active.id as string);
            const newIndex = items.map((x) => x.id).indexOf(over.id as string);
            onDragEnd(
                arrayMove(items, oldIndex, newIndex).map((item, index) => {
                    if (!indexPropertyToUpdate) {
                        return item;
                    } else {
                        const map = new Map<string, unknown>(Object.entries(item));
                        map.set(indexPropertyToUpdate as string, index + 1);

                        return Object.fromEntries(map.entries());
                    }
                }) as Array<Generic & { id: string }>,
            );
        }
    }

    return (
        <DndContext
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
                disabled={disabled}
            >
                {items.map((item, index) => (
                    <ListItem item={item} index={index} key={index}>
                        {listElement(item, index)}
                    </ListItem>
                ))}
            </SortableContext>
        </DndContext>
    );
}

type ListItemProps<Generic> = {
    item: Generic & { id: string };
    index: number;
    children: React.ReactNode;
};

function ListItem<Generic>({ item, children }: ListItemProps<Generic>) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
            id: item.id,
            transition: {
                duration: 150, // milliseconds
                easing: "cubic-bezier(0.25, 1, 0.5, 1)",
            },
        }),
        style = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}

export default VerticalDragAndDrop;
export { VerticalDragAndDropProps };
