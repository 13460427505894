import React from "react";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { useParams } from "react-router-dom";
import { Typography } from "../../../components/ui/typography";
import { determineTenantName } from "../../../lib/helpers/tenant";

function SingingCompleted() {
    const { document_name } = useParams<{ document_name?: string }>();

    const largeConfettiProps: ConfettiProps = {
        force: 0.8,
        duration: 3000,
        particleCount: 300,
        width: 1600,
        colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
    };
    return (
        <Dialog open>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>You have signed {document_name}</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                    <ConfettiExplosion {...largeConfettiProps} />
                    <Typography
                        text={`${determineTenantName()} has been notified that you have signed the document.`}
                        style="medium"
                    />
                    <Typography
                        text="You will receive an e-mail to download the fully signed document once all signatures have been processed."
                        style="p"
                    />
                    <Typography text=" It is now safe to close this browser window." style="p" />
                </DialogDescription>
            </DialogContent>
        </Dialog>
    );
}

export default SingingCompleted;
