import React, { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { createTemplate } from "../../../Admin/Templates";
import { Form, Formik } from "formik";
import {
    Bar,
    CancelButton,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
    TextArea,
} from "../../../Form/FormField/style";
import FroalaEditor from "react-froala-wysiwyg";
import {
    defaultFroalaConfigAdmin,
    documentTypeOptions,
    newTemplateValidationSchema,
} from "../../../Config";
import {
    FormButtonGroupContainer,
    NewTemplateEditorContainer,
    NewTemplateSettingsContainer,
    NewTemplateWrapper,
} from "../Style";
import { CheckBox, CheckBoxLabel, CheckBoxWrapper, Selection } from "../../../Form/Documents/Style";
import { adminPrefix } from "../../../../config/central";
import { NewTemplateItem } from "../../../../types/Central";

export function AdminNewTemplatePage() {
    const froalaConfig = {
        ...defaultFroalaConfigAdmin,
        events: {
            initialized: function () {
                onEditorInitialized(this);
            },
        },
    };

    const editorRef = useRef<any>(null);

    const navigate = useNavigate();

    const onEditorInitialized = (editor: any) => {
        editorRef.current = editor;
    };

    const initialValues: NewTemplateItem = {
        template_type: "standard_form",
        name: "",
        content: "",
        footer: "",
        multiple_pages: false,
        landscape: false,
        stylesheet: "",
        file: undefined,
        service_fee: 0,
        copyright_fee: 0,
        is_smart: false,
    };

    function handleSubmit(values: NewTemplateItem) {
        createTemplate(values).then(() => {
            navigate(`/${adminPrefix}/templates`, { replace: true });
        });
    }

    return (
        <Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={newTemplateValidationSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <NewTemplateWrapper>
                            <NewTemplateSettingsContainer>
                                <InputGroup>
                                    <CheckBoxWrapper>
                                        <span>Smart form:</span>
                                        <CheckBox
                                            id="is_smart"
                                            type="checkbox"
                                            onClick={(e: any) =>
                                                setFieldValue(
                                                    "is_smart",
                                                    e.target.checked === true ? 1 : 0,
                                                )
                                            }
                                            defaultChecked={Boolean(initialValues.is_smart)}
                                        />
                                        <CheckBoxLabel htmlFor="is_smart" />
                                    </CheckBoxWrapper>
                                </InputGroup>
                                <InputGroup>
                                    <CheckBoxWrapper>
                                        <span>Landscape:</span>
                                        <CheckBox
                                            id="landscape"
                                            type="checkbox"
                                            onClick={(e: any) =>
                                                setFieldValue(
                                                    "landscape",
                                                    e.target.checked === true ? 1 : 0,
                                                )
                                            }
                                            defaultChecked={Boolean(initialValues.landscape)}
                                        />
                                        <CheckBoxLabel htmlFor="landscape" />
                                    </CheckBoxWrapper>
                                </InputGroup>
                                <InputGroup>
                                    <CheckBoxWrapper>
                                        <span>Multiple pages:</span>
                                        <CheckBox
                                            id="multiple_pages"
                                            type="checkbox"
                                            onClick={(e: any) =>
                                                setFieldValue(
                                                    "multiple_pages",
                                                    e.target.checked === true ? 1 : 0,
                                                )
                                            }
                                            defaultChecked={Boolean(initialValues.multiple_pages)}
                                        />
                                        <CheckBoxLabel htmlFor="multiple_pages" />
                                    </CheckBoxWrapper>
                                </InputGroup>
                                <InputGroup>
                                    <Selection
                                        onChange={(v: { label: string; value: string }) =>
                                            setFieldValue("template_type", v.value)
                                        }
                                        options={documentTypeOptions}
                                        defaultValue={documentTypeOptions.find(
                                            (x) => x.value === values.template_type,
                                        )}
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        autoFocus={false}
                                    />
                                    {errors.template_type && touched.template_type && (
                                        <StyledInlineErrorMessage>
                                            {errors.template_type}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        name="name"
                                        autoCorrect="off"
                                        autoComplete="name"
                                        valid={touched.name && !errors.name}
                                        error={touched.name && errors.name}
                                    />
                                    <Bar />
                                    <Label htmlFor="name">Name</Label>
                                    {errors.name && touched.name && (
                                        <StyledInlineErrorMessage>
                                            {errors.name}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        name="footer"
                                        autoCorrect="off"
                                        autoComplete="footer"
                                        valid={touched.footer && !errors.footer}
                                        error={touched.footer && errors.footer}
                                    />
                                    <Bar />
                                    <Label htmlFor="footer">Footer</Label>
                                    {errors.footer && touched.footer && (
                                        <StyledInlineErrorMessage>
                                            {errors.footer}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <TextArea
                                        name="stylesheet"
                                        autoCorrect="off"
                                        autoComplete="stylesheet"
                                        onChange={async (e) => {
                                            await setFieldValue("stylesheet", e.target.value);
                                        }}
                                    />
                                    <Bar />
                                    <Label htmlFor="name">Stylesheet</Label>
                                    {errors.stylesheet && touched.stylesheet && (
                                        <StyledInlineErrorMessage>
                                            {errors.stylesheet}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type="number"
                                        name="service_fee"
                                        autoCorrect="off"
                                        autoComplete="service_fee"
                                        valid={touched.service_fee && !errors.service_fee}
                                        error={touched.service_fee && errors.service_fee}
                                    />
                                    <Bar />
                                    <Label htmlFor="service_fee">Service fee</Label>
                                    {errors.service_fee && touched.service_fee && (
                                        <StyledInlineErrorMessage>
                                            {errors.service_fee}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>

                                <InputGroup>
                                    <Input
                                        type="number"
                                        name="copyright_fee"
                                        autoCorrect="off"
                                        autoComplete="copyright_fee"
                                        valid={touched.copyright_fee && !errors.copyright_fee}
                                        error={touched.copyright_fee && errors.copyright_fee}
                                    />
                                    <Bar />
                                    <Label htmlFor="copyright_fee">Copyright fee</Label>
                                    {errors.copyright_fee && touched.copyright_fee && (
                                        <StyledInlineErrorMessage>
                                            {errors.copyright_fee}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <FormButtonGroupContainer>
                                    <CancelButton
                                        type="button"
                                        onClick={() =>
                                            navigate(`/${adminPrefix}/templates`, { replace: true })
                                        }
                                    >
                                        Back to overview
                                    </CancelButton>
                                    <SubmitButton type="submit">Create</SubmitButton>
                                </FormButtonGroupContainer>
                            </NewTemplateSettingsContainer>
                            <NewTemplateEditorContainer>
                                {errors.content && touched.content && (
                                    <StyledInlineErrorMessage>
                                        {errors.content}
                                    </StyledInlineErrorMessage>
                                )}
                                <div className={values.multiple_pages ? "multiple-pages" : ""}>
                                    <style>{values.stylesheet}</style>
                                    <FroalaEditor
                                        config={froalaConfig}
                                        model={values.content}
                                        onModelChange={(val: object | undefined) => {
                                            setFieldValue("content", val);
                                        }}
                                    />
                                </div>
                            </NewTemplateEditorContainer>
                        </NewTemplateWrapper>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
}
