import { Textarea } from "../ui/textarea";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../ui/hover-card";
import { Button } from "../ui/button";
import { SendHorizonal } from "lucide-react";
import { Typography } from "../ui/typography";
import React, { Fragment, useRef } from "react";
import { DropdownMenuShortcut } from "../ui/dropdown-menu";
import { useChat } from "../../lib/providers/chat-provider";
import { useHotkeys } from "react-hotkeys-hook";

function ChatSendMessage() {
    const { sendMessage, activeChannel } = useChat();
    const messageRef = useRef<HTMLTextAreaElement | null>(null);

    useHotkeys("shift+enter", () => send());

    async function send() {
        if (messageRef?.current?.value) {
            await sendMessage(messageRef.current.value);
            messageRef.current.value = "";
        }
    }

    return (
        <div className="flex w-full items-center space-x-2">
            <div className="grid w-full gap-1.5">
                <Textarea
                    placeholder="Type your message here."
                    id="message"
                    className="h-[40px] h-min-[40px] resize-none"
                    ref={messageRef}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && e.shiftKey) {
                            send();
                        }
                    }}
                    disabled={activeChannel === undefined}
                />
            </div>
            <HoverCard>
                <HoverCardTrigger asChild>
                    <Button
                        type="submit"
                        size="icon"
                        onClick={send}
                        disabled={activeChannel === undefined}
                    >
                        <SendHorizonal className="h-4 w-4" />
                    </Button>
                </HoverCardTrigger>
                <HoverCardContent className="w-auto" align="end">
                    <div className="flex items-center pt-2">
                        <Typography
                            text={
                                <Fragment>
                                    Press shift + enter{" "}
                                    <DropdownMenuShortcut>⌘⏎</DropdownMenuShortcut> to quick send
                                </Fragment>
                            }
                            style="muted"
                        />
                    </div>
                </HoverCardContent>
            </HoverCard>
        </div>
    );
}

export default ChatSendMessage;
