import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import { Admin, useAdmin } from "../../../../lib/providers/admin-auth-provider";
import { Container, FormTitle, FormWrapper, Wrapper } from "../../Registration/Style";
import {
    Bar,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
} from "../../../Form/FormField/style";
import { adminPrefix } from "../../../../config/central";

interface FormValues {
    email: string;
    password: string;
}

export const AdminLoginPage = () => {
    const [error, setError] = useState<string | boolean>();

    const navigate = useNavigate(),
        auth = useAdmin(),
        initialValues: FormValues = {
            email: "",
            password: "",
        };

    function handleSubmit(values: FormValues, actions: FormikHelpers<FormValues>) {
        const user: Admin = {
            email: values.email,
            password: values.password,
        };

        auth.signIn(user, (value) => {
            if (value === true) {
                navigate(`/${adminPrefix}/activity`, { replace: true });
            } else {
                setError(value);
            }
        });

        actions.setSubmitting(false);
    }

    return (
        <Wrapper>
            <Container>
                <FormWrapper className="bg-white">
                    {/*<OceanRecapLogo />*/}
                    <FormTitle>Admin Login</FormTitle>
                    {error && <StyledInlineErrorMessage>{error}</StyledInlineErrorMessage>}

                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values, actions) => handleSubmit(values, actions)}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        name="email"
                                        autoCorrect="off"
                                        autoComplete="username-dont-fill"
                                        valid={touched.email && !errors.email}
                                        error={touched.email && errors.email}
                                        className=""
                                    />
                                    <Bar />
                                    <Label htmlFor="username">Email</Label>
                                    {errors.email && touched.email && (
                                        <StyledInlineErrorMessage>
                                            {errors.email}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type="password"
                                        name="password"
                                        autoCorrect="off"
                                        autoComplete="password"
                                        valid={touched.password && !errors.password}
                                        error={touched.password && errors.password}
                                        className=""
                                    />
                                    <Bar />
                                    <Label htmlFor="password">Password</Label>
                                    {errors.password && touched.password && (
                                        <StyledInlineErrorMessage>
                                            {errors.password}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <SubmitButton type="submit">Log in</SubmitButton>
                            </Form>
                        )}
                    </Formik>
                </FormWrapper>
            </Container>
        </Wrapper>
    );
};
