import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { Button } from "../../../components/ui/button";
import { Plus } from "lucide-react";
import React from "react";
import { useAddenda } from "../../../lib/providers/addenda-provider";

function AddendaTableOfContentsButtons() {
    const { permissions } = useCaseFiles();
    const { setActiveAddendum } = useAddenda();

    return (
        <Button
            variant="ghost"
            size="icon"
            disabled={!permissions.edit_documents}
            onClick={() => setActiveAddendum(undefined)}
        >
            <Plus size="16" className="fill-neutral-800 h-[1.2rem] w-[1.2rem]" />
        </Button>
    );
}

export default AddendaTableOfContentsButtons;
