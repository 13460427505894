import styled from "styled-components";
import { AppCard, borderThin } from "../../styles/mixins";

export const RecapTemplateContainer = styled.section`
    ${AppCard};
    padding: 1em;
`;

export const RecapOptionsDroppable = styled.div`
    border: ${borderThin("border")};
    opacity: 0.5;
    width: 320px;
`;

export const RecapOptionsDropZone = styled.div`
    border: ${borderThin("border")};
    opacity: 0.5;
    border-radius: 5px;
    width: calc(100% - 384px);
`;
