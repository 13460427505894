import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { cn } from "../../lib/utils";

function Logo({ className }: { className?: string }) {
    const imagePath =
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
            ? "/images/logo-compact.svg"
            : "/images/logo-compact.svg";

    return (
        <Link className={cn("d-inline-block", className)} to={"/"}>
            <Fragment>
                <img className="h-4" src={imagePath} alt="Ocean Recap" />
            </Fragment>
        </Link>
    );
}

export default Logo;
