import TypedFroalaEditor from "froala-editor-typings";
import Command from ".";
import { checkIfSelectionContainsDeletionSpan } from "../Utility/inspectDOM";
import React, { Fragment } from "react";
import { Button } from "../../../components/ui/button";
import { handleReinstating } from "../Utility/reinstate";
import { createPortal } from "react-dom";
import { Card } from "../../../components/ui/card";
import { createRoot } from "react-dom/client";
import Memento from "../Memento";
import { saveEditorSelectionAndAddEditorStateToMemento } from "../Utility/base";

class ReinstateCommand implements Command {
    private editor: TypedFroalaEditor.FroalaEditor;
    private location: DOMRect;
    private previousState: Memento;
    private spanId: string | null;
    private onConfirmCallback: () => void;

    constructor(
        editor: TypedFroalaEditor.FroalaEditor,
        location: DOMRect,
        onConfirmCallback: () => void,
    ) {
        this.editor = editor;
        this.location = location;
        this.previousState = this.saveState();
        this.onConfirmCallback = onConfirmCallback;
    }

    execute(): void {
        this.reinstateSelection();
    }

    undo(): void {
        this.restoreState(this.previousState);
    }

    saveState(): Memento {
        return saveEditorSelectionAndAddEditorStateToMemento(this.editor);
    }

    restoreState(memento: Memento): void {
        this.editor.html.set(memento.getState()); // Restore the HTML content
    }

    get previous(): Memento {
        return this.previousState;
    }

    get id(): string | null {
        return this.spanId;
    }

    private reinstateSelection(): void {
        const selection: Selection = this.editor.selection.get();

        // always remove the overlay panel, it'll be created when needed
        const existingPanel = document.getElementById("floatingPanel");
        existingPanel?.remove();

        // Remove any existing panel on registered click
        if (
            !selection.isCollapsed &&
            checkIfSelectionContainsDeletionSpan(selection) // check if panel should be shown if selection contains deletion
        ) {
            const panel = document.createElement("div");
            const root = createRoot(panel);

            root.render(
                <Fragment>
                    {createPortal(
                        <Card className="shadow-card">
                            <div className="flex justify-between p-2">
                                <Button
                                    size="sm"
                                    className="mr-4"
                                    onClick={() => {
                                        this.onConfirmCallback(); // Trigger the callback when the action is confirmed, which pushed to undo stack
                                        handleReinstating(selection);
                                        selection.removeAllRanges();
                                        panel.remove(); // Remove the panel after logging
                                    }}
                                >
                                    Reinstate
                                </Button>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => {
                                        selection.removeAllRanges();
                                        panel.remove(); // Remove the panel after logging
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Card>,
                        panel,
                    )}
                </Fragment>,
            );

            // Create a panel to hold the buttons
            panel.className = "absolute";

            panel.id = "floatingPanel";
            panel.style.left = `${this.location.right + 20}px`;
            panel.style.top = `${this.location.top - 50}px`;
            panel.style.zIndex = "997";

            // Add the panel to the body
            document.body.appendChild(panel);
        }
    }
}

export default ReinstateCommand;
