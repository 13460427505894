import { DataTableColumnHeaderProps } from "../../types/table";
import * as React from "react";
import { useEffect } from "react";
import { DateRange } from "react-day-picker";
import { cn } from "../../lib/utils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { Calendar as CalendarIcon } from "lucide-react";
import { Calendar } from "../ui/calendar";
import { Typography } from "../ui/typography";
import { format } from "date-fns";

function DataTableDateRangeFilter<TData, TValue>({
    column,
    title,
    className,
}: Omit<DataTableColumnHeaderProps<TData, TValue>, "type">) {
    const [date, setDate] = React.useState<DateRange | undefined>();

    useEffect(() => {
        if (date?.to && date?.from) {
            column.setFilterValue(date);
        }
    }, [date]);

    return (
        <div className={cn("flex items-center space-x-2", className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="ghost"
                        size="sm"
                        className="-ml-3 h-8 data-[state=open]:bg-accent"
                    >
                        <span>{title}</span>
                        <CalendarIcon className="ml-2 h-3 w-3" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={
                            new Date(
                                new Date().getFullYear(),
                                new Date().getMonth() - 1,
                                new Date().getDate(),
                            )
                        }
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                        components={{
                            CaptionLabel: (props) => (
                                <div className="text-sm font-medium">
                                    <Typography
                                        text={props.displayIndex === 0 ? "from" : "to"}
                                        style="muted"
                                        className="text-center"
                                    />
                                    {format(props.displayMonth, "MMMM yyyy")}
                                </div>
                            ),
                        }}
                    />
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

export default DataTableDateRangeFilter;
