import { useDeleteDocumentMutation } from "../../../api/tenant/case-file";
import { toast } from "sonner";
import React, { Fragment } from "react";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { UploadedFile } from "../../../types/uploads";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function DeleteDocument({ row, onClose }: TableActionProps<CaseFileDocumentIndex | UploadedFile>) {
    const [deleteDocument] = useDeleteDocumentMutation();
    const { case_file_id } = useCaseFiles();
    async function handleSubmit() {
        try {
            await deleteDocument({
                id: row.id,
                case_file_id: case_file_id,
            });

            onClose();
            toast.message(`Deleted ${row.document_name}`);
        } catch (e) {
            toast.error("Something went wrong while deleting document");
        }
    }

    return (
        <Fragment>
            <DialogHeader>
                <DialogTitle>Delete {row.document_name}</DialogTitle>
                <DialogDescription>Are you sure?</DialogDescription>
            </DialogHeader>
            <DialogFooter className="mt-4">
                <Button type="submit" onClick={handleSubmit} variant="destructive">
                    Delete
                </Button>
            </DialogFooter>
        </Fragment>
    );
}

export default DeleteDocument;
