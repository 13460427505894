import React from "react";
import { Typography } from "../../components/ui/typography";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { useLazyGetCsrfCookieQuery, useRegisterAccountMutation } from "../../api/cookieApi";
import { Switch } from "../../components/ui/switch";

function Register() {
    const schema = z
        .object({
            name: z.string().min(2, {
                message: "Name must be at least 2 characters.",
            }),
            email: z.string().email("Invalid email address"),
            username: z.string().min(2, {
                message: "Username must be at least 2 characters.",
            }),
            password: z.string().min(2, {
                message: "Password must be at least 2 characters.",
            }),
            domain: z
                .string()
                .max(32)
                .min(2, {
                    message: "Domain must be at least 3 characters.",
                })
                .regex(/^[a-zA-Z0-9_-]*$/, "Domain must not contain special characters"),
            eula: z.boolean(),
        })
        .refine((data) => data.eula, {
            message: "Agreement of Terms must be accepted to join.",
            path: ["eula"],
        });

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: "",
            email: "",
            username: "",
            password: "",
            domain: "",
            eula: false,
        },
    });

    const navigate = useNavigate();

    const [registerAccount] = useRegisterAccountMutation();
    const [getCsrfCookie] = useLazyGetCsrfCookieQuery();

    async function handleSubmit(values: z.infer<typeof schema>) {
        await getCsrfCookie();

        try {
            const registration = await registerAccount({
                domain: values.domain!,
                email: values.email!,
                name: values.name!,
                password: values.password!,
                username: values.username!,
            }).unwrap();

            if (registration) {
                navigate(`/${values.domain}`, { replace: true });
            } else {
                toast.error("Something went wrong");
            }
        } catch (e) {
            toast.error("Something went wrong");
        }
    }

    return (
        <div className="bg-login h-screen bg-cover bg-center bg-no-repeat">
            <div className="h-screen w-screen bg-zinc-900 bg-opacity-45 flex align items-center justify-center">
                <div className="w-[520px] bg-white p-8 flex flex-col">
                    <div className="mx-auto	mb-8 w-[245px]">
                        <img
                            src="/images/logo.png"
                            alt="Ocean Recap"
                            className="h-24 mt-8 mb-8 self-center"
                        />
                    </div>
                    <div className="flex align items-center justify-cente">
                        <Typography text={"Create a free account"} style="h3" />
                    </div>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)}>
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Name{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Email{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="username"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Username{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Password{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input type="password" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="domain"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Domain{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="eula"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <div className="flex items-center">
                                            <FormControl>
                                                <Switch
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                            <FormDescription className="ml-2">
                                                I agree with the{" "}
                                                <a
                                                    href="/pdf/EULA.pdf"
                                                    target="_blank"
                                                    className="py-2 underline"
                                                >
                                                    Agreement of Use
                                                </a>
                                            </FormDescription>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="flex flex-col">
                                <Button type="submit" className="mt-8">
                                    Register account
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Register;
