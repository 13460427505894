import styled from "styled-components";
import { AppCard, borderThin, color, flexColumn } from "../../styles/mixins";

export const RecapFieldWrapper = styled.section`
    display: flex;
    justify-content: space-between;
    height: 100%;
`;

export const RecapCategoryContainer = styled.section`
    ${AppCard};
    padding: 1em;
    flex-basis: calc(30% - 2em);
    margin-right: 2em;
    height: calc(100% - 2em);
    color: #ffffff;
`;

export const RecapCategoryTopBar = styled.section`
    display: flex;
    justify-content: space-between;
`;

export const RecapCategoryList = styled.section`
    ${flexColumn};
    margin-top: 1em;
    max-height: calc(100% - 6em);
    overflow-y: auto;
`;

export const RecapCategoryListItem = styled.section`
    display: flex;
    justify-content: space-between;
    border-bottom: ${borderThin("listItem", 0.1)};

    &:hover,
    &.active {
        background-color: ${color("black", 0.0275)};
        cursor: pointer;
    }
`;

export const RecapCategoryListItemWrapper = styled.section`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.75em 0.5em;
`;

export const RecapCategoryListItemContainer = styled.section`
    flex-basis: 90%;
`;

export const RecapCategoryListItemTitle = styled.p`
    color: ${color("darker", 0.85)};
    line-height: 1.5em;
    display: inline-flex;
    vertical-align: middle;
    font-size: 0.875rem;
    font-weight: 500;
`;

export const RecapCategoryListItemSubTitle = styled.p`
    color: ${color("darker", 0.75)};
    margin-bottom: 0.5em;
    font-size: 0.675rem;
    font-weight: 400;
    margin-top: 4px;
`;

export const RecapFieldContainer = styled.section`
    ${AppCard};
    padding: 1em;
    flex-basis: calc(70% - 2em);
    height: calc(100% - 2em);
`;

export const DragAndDropField = styled.div`
    display: flex;
    flex-basis: 100%;
`;
