import React, { Fragment, useEffect, useState } from "react";
import { IntercomProps, IntercomProvider, useIntercom } from "react-use-intercom";
import { useClickAway } from "@uidotdev/usehooks";
import { useAuth } from "./AuthProvider";

type SupportProviderProps = {
    children: React.ReactNode;
    loggedIn: boolean;
    appId: string | undefined;
};

function InnerIntercomWrapper({ children, loggedIn, appId }: SupportProviderProps) {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const { boot, update, shutdown, hide } = useIntercom(),
        { user } = useAuth().state,
        ref = useClickAway(() => {
            hide();
        }) as React.LegacyRef<HTMLDialogElement>;

    useEffect(() => {
        const hasUserInfo = loggedIn && user && user.tenant_id;

        if (!hasUserInfo) {
            shutdown();
            setIsInitialized(false);
        } else {
            updateSupportMessengerInfo({
                email: user.email,
                name: user.name,
                company: {
                    companyId: user.tenant_id!,
                    name: user.tenant_id,
                    plan: user.plan,
                },
                userHash: user.support_signature_id,
            });
        }
    }, [loggedIn]);

    function updateSupportMessengerInfo(profile: Partial<IntercomProps>) {
        if (!appId) {
            return;
        }

        if (!isInitialized) {
            setIsInitialized(true);
            boot(profile);
        } else {
            update(profile);
        }
    }

    return (
        <Fragment>
            <dialog ref={ref}></dialog>
            {children}
        </Fragment>
    );
}

export function SupportProvider({ children, loggedIn }: SupportProviderProps) {
    const [appId, setAppId] = useState<string>();

    useEffect(() => {
        setAppId(process.env.INTERCOM_APP_ID ?? "");
    }, []);

    return appId ? (
        <IntercomProvider appId={appId}>
            <InnerIntercomWrapper loggedIn={loggedIn} appId={appId}>
                {children}
            </InnerIntercomWrapper>
        </IntercomProvider>
    ) : (
        <Fragment>{children}</Fragment>
    );
}
