import styled from "styled-components";
import { borderThin } from "../../styles/mixins";

export const PasswordIcon = styled.svg<{ valid: boolean; error: boolean }>`
    padding-bottom: 17px;
    border-bottom: ${borderThin("primary")};

    ${(props) => (props.valid ? borderThin("secondary") + ";" : "")}
    ${(props) => (props.error ? borderThin("warning") + ";" : "")}

    &:hover {
        cursor: pointer;
    }
`;
