import { ColumnDef } from "@tanstack/react-table";
import { UploadedFile } from "../types/uploads";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { TableDropDownItems } from "../types/table";
import { Download, MoreHorizontal, Trash2 } from "lucide-react";
import { DropdownMenu, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import DataTableAdvancedDropDown from "../components/data-table/data-table-advanced-drop-down";
import AttachmentsPreview from "../containers/case-files/attachements/attachments-preview";
import DeleteDocument from "../containers/case-files/detail/delete-document";
import { format } from "date-fns";
import { useCaseFiles } from "../lib/providers/CaseFilesProvider";
import { CaseFilePermissionsInterface } from "../types/case-files";

const columns: ColumnDef<UploadedFile>[] = [
    {
        accessorKey: "document_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="File name" />;
        },
        sortingFn: "basic",
        meta: {
            title: "File name",
        },
    },
    {
        accessorKey: "user",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Uploaded by" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Uploaded by",
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Uploaded at (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
        meta: {
            title: "Uploaded at (CEST)",
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const { permissions } = useCaseFiles();

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DataTableAdvancedDropDown<UploadedFile>
                        row={row.original}
                        type="dropdown"
                        items={tableDropDown(permissions)}
                    />
                </DropdownMenu>
            );
        },
    },
];

function tableDropDown(
    permissions: CaseFilePermissionsInterface,
): TableDropDownItems<UploadedFile> {
    return {
        label: "Actions",
        groups: [
            {
                actions: [
                    {
                        label: "Download",
                        icon: <Download className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => (
                            <AttachmentsPreview row={row} onClose={onClose} />
                        ),
                        elementType: "dialog",
                        customSize: "max-w-[90vw] sm:max-w-[90vw]",
                    },
                ],
            },
            {
                actions: [
                    {
                        label: "Delete",
                        icon: <Trash2 className="mr-2 h-4 w-4 stroke-white" />,
                        dangerous: true,
                        element: (row, onClose) => <DeleteDocument row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: () => !permissions.edit_documents,
                    },
                ],
            },
        ],
    };
}

const FILE_SIZE = 10 * (1024 * 1024); // 10mb
const SUPPORTED_FORMATS = [
    "image/jpg", // .jpg
    "image/jpeg", // .jpeg
    "image/gif", // .gif
    "image/png", // .png
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/pdf", // .pdf
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "image/tiff", // .tif/ .tiff,
    "text/plain", // .txt
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/zip", // .zip
    "application/vnd.rar", // .rar
];

export { columns, FILE_SIZE, SUPPORTED_FORMATS, tableDropDown };
