import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import DocumentViewer from "./froala-read-only/document-viewer";
import ZoomInEditor from "../plugins/ZoomInEditor";
import { useAuth } from "../lib/providers/AuthProvider";
import { DataAutomation } from "../plugins/DataAutomation";
import { ChangeLogV2 } from "../plugins/ChangeLogV2";
import parse from "html-react-parser";
import { TrackChangesV3 } from "../plugins/TrackChangesV3";
import { TenantUser } from "../types/auth";
import { isFrMarker } from "../plugins/TrackChangesV3/Utility/inspectDOM";

type EditorProps<Generic> = {
    document: Generic;
    formatting: boolean;
    case_file_id: string;
    document_id: string;
    content: string;
    froalaConfig: Record<string, unknown>;
    isUserActive: boolean;
    froalaConfigFinal: Record<string, unknown>;
    froalaRef: MutableRefObject<any>;
    handleChangeMade: () => void;
    emitEditorObject: (editor: any) => void;
    handleClickInEditor: (e: any) => void;
    heightMax?: string;
    finalized?: boolean;
};

function Editor<Generic>(props: EditorProps<Generic>) {
    const {
            froalaConfig,
            finalized,
            content,
            isUserActive,
            formatting,
            case_file_id,
            document_id,
            froalaRef,
            handleChangeMade,
            emitEditorObject,
            handleClickInEditor,
            heightMax,
        } = props,
        [froalaConfigState, setFroalaConfigState] = useState<Record<string, unknown>>(),
        [editor, setEditor] = useState<any>(),
        user = useAuth().state.user,
        zoom = ZoomInEditor.getInstance(),
        dataAutomation = DataAutomation.getInstance(),
        changelog = ChangeLogV2.getInstance(),
        trackChanges = TrackChangesV3.getInstance();

    useEffect(() => {
        zoom.init();
        init();

        const targetNode = document.querySelectorAll(".fr-wrapper")[0] as HTMLElement;

        if (targetNode) {
            const config = {
                childList: true,
                subtree: true,
            };

            const callback = (mutationList: MutationRecord[]) => {
                for (const mutation of mutationList) {
                    if (mutation.type === "childList") {
                        // Do not allow froala markers to trigger autosave
                        if (
                            mutation.addedNodes.length === 0 ||
                            isFrMarker(mutation.addedNodes[0]) ||
                            !mutation.addedNodes[0].textContent ||
                            (mutation.addedNodes[0].nodeType === Node.ELEMENT_NODE &&
                                (mutation.addedNodes[0] as HTMLElement).getAttribute("id") ===
                                    "fr-sel-markers")
                        ) {
                            return;
                        }
                    }
                    handleChangeMade();
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);

            return () => {
                observer.disconnect();
            };
        }
    }, [editor]);

    const isMouseDown = useRef<boolean>(false);
    const wentOutOfBounds = useRef<boolean>(false);

    useEffect(() => {
        const handlePointerDown = () => {
            isMouseDown.current = true;
        };

        const handlePointerUp = () => {
            isMouseDown.current = false;
        };

        document.addEventListener("pointerdown", handlePointerDown);
        document.addEventListener("pointerup", handlePointerUp);

        return () => {
            document.removeEventListener("pointerdown", handlePointerDown);
            document.removeEventListener("pointerup", handlePointerUp);
        };
    }, []);

    useEffect(() => {
        if (editor) {
            const editorEl = editor.$oel[0];

            const handleMouseMove = (e: MouseEvent) => {
                // Mouse must be down
                if (!isMouseDown.current) {
                    return;
                }

                // Get element under the cursor
                const elementUnderMouse = document.elementFromPoint(e.clientX, e.clientY);

                // Element must be within Editor
                if (!editorEl.contains(elementUnderMouse)) {
                    return;
                }

                if (elementUnderMouse) {
                    if (isValidSelection(elementUnderMouse)) {
                        // Save selection for returning to user
                        saveSelection();

                        // If a user returns
                        if (wentOutOfBounds.current) {
                            wentOutOfBounds.current = false;
                            restoreSelection();
                        }
                    } else {
                        // Reset selection
                        wentOutOfBounds.current = true;
                        document.getSelection()?.removeAllRanges();
                    }
                }
            };

            editorEl.addEventListener("mousemove", handleMouseMove);

            const handleSelectionChange = (e: Event) => {
                // do not want to interfere with the mouse select
                if (isMouseDown.current) {
                    return;
                }
                const selection = document.getSelection();
                if (selection && selection.rangeCount > 0 && !selection.isCollapsed) {
                    trackChanges.mapEvents("select", e);
                }
            };

            // Capture arrow selection of text
            document.addEventListener("selectionchange", handleSelectionChange);

            // Cleanup function to remove the event listener
            return () => {
                editorEl.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("selectionchange", handleSelectionChange);
            };
        }
    }, [document_id, editor]);

    useEffect(() => {
        if (froalaConfigState && editor && user) {
            registerPluginCommands(editor, user);
        }
    }, [froalaConfigState, editor]);

    // Define a type for the selection details
    type SelectionDetail = {
        anchorPath: number[];
        anchorOffset: number;
        focusPath: number[];
        focusOffset: number;
    };

    const oldSelection = useRef<SelectionDetail>({
        anchorPath: [],
        anchorOffset: 0,
        focusPath: [],
        focusOffset: 0,
    });

    const saveSelection = () => {
        const selection = document.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            oldSelection.current = {
                anchorPath: getNodePath(range.startContainer),
                anchorOffset: range.startOffset,
                focusPath: getNodePath(range.endContainer),
                focusOffset: range.endOffset,
            };
        }
    };

    const restoreSelection = () => {
        const selection = document.getSelection();
        const { anchorPath, anchorOffset, focusPath, focusOffset } = oldSelection.current;
        const anchorNode = getNodeFromPath(anchorPath);
        const focusNode = getNodeFromPath(focusPath);

        if (selection && anchorNode && focusNode) {
            const range = new Range();
            range.setStart(anchorNode, anchorOffset);
            range.setEnd(focusNode, focusOffset);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    // Used to persist live element references
    const getNodePath = (node: Node): number[] => {
        const path: number[] = [];
        let current = node;
        while (current !== document.body) {
            const parent = current.parentNode;
            const index = Array.prototype.indexOf.call(parent!.childNodes, current);
            path.unshift(index);
            current = parent!;
        }
        return path;
    };

    // Retrieve node from fixed path
    const getNodeFromPath = (path: number[]): Node | null => {
        let node: Node | null = document.body;
        path.forEach((index) => {
            if (!node) {
                return null;
            }
            node = node.childNodes[index];
        });
        return node;
    };

    // is element within top-level tags
    const isValidSelection = (node: Node | null): boolean => {
        const editorView = document.querySelector(".fr-view");
        let current = node;
        // Traverse up from the focus node to check if it's a valid selection
        while (current && current !== editorView) {
            if (current.parentNode === editorView) {
                return true; // Direct child of .fr-view
            }
            current = current.parentNode;
        }
        return false; // Not a direct child or a descendant of a direct child
    };

    function registerPluginCommands(editor: unknown, user: TenantUser) {
        emitEditorObject(editor);
        zoom.registerReference(froalaRef);
        dataAutomation.init(user, formatting ?? true);
        dataAutomation.registerReference(froalaRef);
        changelog.registerReference(froalaRef);
        registerTrackChangesCommands(editor, user);
    }

    function registerTrackChangesCommands(editor: any, user: TenantUser) {
        trackChanges.initialize(editor, user, case_file_id ?? "", document_id ?? "", formatting);

        editor.events.on(
            "commands.before",
            (cmd: string) => {
                if (cmd === "undo") {
                    trackChanges.undo();
                    return false;
                }
            },
            true,
        );

        editor.events.on(
            "mouseup",
            (e: MouseEvent) => {
                trackChanges.mapEvents("select", e);
                return false;
            },
            true,
        );

        editor.events.on(
            "paste.before",
            (e: any) => {
                e.preventDefault();
                e.stopPropagation();
                const ua = navigator.userAgent.toLowerCase();
                const isAndroid = ua.indexOf("android") > -1 && ua.indexOf("mobile");
                if (isAndroid) {
                    return;
                }
                trackChanges.mapEvents("paste.before", e);
                return false;
            },
            true,
        );

        editor.events.on(
            "window.cut",
            (e: any) => {
                e.preventDefault();
                e.stopPropagation();
                const ua = navigator.userAgent.toLowerCase();
                const isAndroid = ua.indexOf("android") > -1 && ua.indexOf("mobile");
                if (isAndroid) {
                    return;
                }

                trackChanges.mapEvents("window.cut", e);
                return false;
            },
            true,
        );

        editor.events.on(
            "keydown",
            (event: any) => {
                const isKeys =
                    event.key === "ArrowLeft" ||
                    event.key === "ArrowRight" ||
                    event.key === "ArrowUp" ||
                    event.key === "ArrowDown";

                const ua = navigator.userAgent.toLowerCase();
                const isAndroid = ua.indexOf("android") > -1 && ua.indexOf("mobile");
                if (isAndroid && !isKeys) {
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                }

                // Do not catch cut, copy, paste
                if (
                    (event.ctrlKey || event.metaKey) &&
                    (event.key === "x" || event.key === "c" || event.key === "v")
                ) {
                    return;
                }

                if (isKeys) {
                    return;
                } else {
                    event.preventDefault();
                    event.stopPropagation();
                }

                try {
                    if ("IMG" !== editor.selection.ranges(0).startContainer.tagName) {
                        const panel = document.getElementById("floatingPanel");
                        if (panel) {
                            panel.remove();
                        }
                        // Force undo button to become active
                        if (!editor.undo.canDo()) {
                            editor.undo.saveStep();
                        }
                        trackChanges.mapEvents("keydown", event);
                    }
                } catch (e) {
                    /* eslint-disable */ console.log(e);
                }

                return false;
            },
            true,
        );

        editor.events.on(
            "drop",
            (dropEvent: any) => {
                // Stop event propagation.
                dropEvent.preventDefault();
                dropEvent.stopPropagation();

                return false;
            },
            true,
        );

        editor.events.on("click", (e: any) => handleClickInEditor(e));
    }

    function init() {
        setFroalaConfigState({
            ...froalaConfig,
            heightMax: heightMax ?? "calc(100vh - 265px)",
            events: {
                initialized: function () {
                    setEditor(this);
                },
            },
        });
    }

    return (
        <div className="h-full" suppressContentEditableWarning={true}>
            {froalaConfigState && !finalized && isUserActive ? (
                <FroalaEditor config={froalaConfigState} model={content} ref={froalaRef} />
            ) : (
                <DocumentViewer standardForm>{parse(content)}</DocumentViewer>
            )}
        </div>
    );
}

export default Editor;
