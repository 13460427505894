import { cn } from "../../lib/utils";
import * as React from "react";
import { DataTableColumnHeaderProps } from "../../types/table";
import DataTableDateRangeFilter from "./data-table-date-range-filter";
import DataTableSortFilter from "./data-table-sort-filter";
import DataTableBooleanFilter from "./data-table-boolean-filter";
import DataTableFilter from "./data-table-filter";
import DataTableSearch from "./data-table-search";

function DataTableColumnHeader<TData, TValue>({
    column,
    title,
    className,
    type = "sort",
    valueFormatter,
}: DataTableColumnHeaderProps<TData, TValue>) {
    if (!column.getCanSort() || !column.getCanFilter()) {
        return <div className={cn(className)}>{title}</div>;
    }

    switch (type) {
        case "search":
            return <DataTableSearch column={column} title={title} />;
        case "filter":
            return (
                <DataTableFilter
                    column={column}
                    title={title}
                    className={className}
                    valueFormatter={valueFormatter}
                />
            );
        case "date-range":
            return <DataTableDateRangeFilter column={column} title={title} className={className} />;
        case "boolean":
            return (
                <DataTableBooleanFilter
                    column={column}
                    title={title}
                    className={className}
                    valueFormatter={valueFormatter}
                />
            );
        case "sort":
        default:
            return <DataTableSortFilter column={column} title={title} className={className} />;
    }
}

export default DataTableColumnHeader;
