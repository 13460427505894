import axios from "axios";

interface NotificationDataResponse {
    data: NotificationThreadInterface[];
    links: LaravelPaginatedLinks;
    meta: LaravelPaginatedMeta;
}

export interface LaravelPaginatedLinks {
    first: string;
    last: string;
    next: string;
    prev: string;
}

export interface LaravelPaginatedMeta {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
    links: any; // annoying but type later
}

export interface NotificationMessageInterface {
    id: string;
    notification_thread_id: string;
    content: string;
    author_id: string;
    status: string;
    action_type: NotificationActionTypes;
    user_name: string;
    updated_at: number;
}

export interface NotificationChannelInterface {
    id: string;
    channel_id: string;
    channel_name: string;
    notifiable_type: string;
    notifiable_id: string;
    permissions: string[];
}

export type NotificationActionTypes =
    | "created"
    | "document-created"
    | "document-finalized"
    | "document-un-finalized"
    | "document-deleted"
    | "document-uploaded"
    | "document-saved"
    | "recap-created"
    | "recap-saved"
    | "recap-deleted"
    | "recap-finalized"
    | "recap-un-finalized"
    | "code-used"
    | "signing-complete"
    | "document-signed"
    | "signing-declined";

export type NotificationTypes = "case_file" | "pro_forma" | "sign_document";

interface NotificationThreadInterface {
    id: string;
    notification_channel_id: string;
    topic: string;
    topic_id: string;
    type: NotificationTypes;
    document_name: string;
    updated_at: number;
    status: string;
    channel: NotificationChannelInterface;
    messages: NotificationMessageInterface[];
}

export async function getNotifications(page: number): Promise<NotificationDataResponse> {
    return await axios.get(`/api/tenant/v1/notifications?page=${page}`).then((res) => {
        const { data, links, meta } = res.data;
        return { data, links, meta };
    });
}
