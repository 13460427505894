import styled from "styled-components";
import { Form } from "formik";
import { borderThin, borderThinEm, color, flexColumn } from "../../styles/mixins";
import { FormButtonGroup } from "../../Form/FormField/style";

export const DashboardWrapper = styled.section`
    ${flexColumn};
`;

export const DashboardContainer = styled.section``;

export const DashboardTopBarWrapper = styled.section`
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
`;

export const DashboardTopBarContainer = styled.section`
    background-color: ${color("white")};
    padding: 1.5em;
    border-radius: 1em;
    flex-basis: 30%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-self: stretch;
`;

export const DashboardTopBarContainerContent = styled.section`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
`;

export const DashboardTopBarContainerContentHeader = styled.section`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 3em;
    padding-bottom: 1em;
`;

export const DashboardTopBarContainerGraph = styled.section`
    height: 400px;
`;

export const DashboardTopBarContainerContentTitle = styled.h1`
    font-size: 1.25em;
    font-weight: 500;
    color: ${color("darker")};
`;

export const DashboardTopBarContainerContentSubTitle = styled.h1`
    font-size: 0.85em;
    font-weight: 500;
    color: ${color("darker", 0.75)};
`;

export const DashboardTopBarContainerContentRange = styled.section`
    border: ${borderThin("darker", 0.3)};
    padding: 0.25em;
`;

export const DashboardTopBarContainerContentRangeSelector = styled.select`
    background-color: ${color("transparent")};
    padding: 0.25em 1em 0.25em 0.25em;
    margin: 0;
    border: none;
    width: auto;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    ::after {
        grid-area: select;
        content: "";
        width: 0.8em;
        height: 0.5em;
        background-color: ${color("darker", 0.3)};
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        justify-self: end;
        border-right: ${borderThinEm("transparent")};
    }
    &:focus,
    &:focus-visible {
        border: none;
        outline: none;
    }
`;

export const PieChartContainer = styled.section`
    max-width: 300px;
    margin: 0 auto;
`;

export const DashboardBodyContainer = styled.section`
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    padding: 1em 0;
    height: 640px;
`;

export const DashBoardBodyContainerColumn = styled(DashboardBodyContainer)`
    flex-direction: column;
`;

export const DashboardBodyMainContainer = styled.section`
    background-color: ${color("white")};
    padding: 1.5em;
    border-radius: 1em;
    flex-basis: 63.5%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-self: stretch;
`;

export const DashboardBodyMainActivityContainer = styled(DashboardBodyMainContainer)`
    width: calc(100% - 3em);
    flex-basis: 100%;
`;

export const DashboardBodySideContainer = styled.section`
    background-color: ${color("white")};
    padding: 1.5em;
    border-radius: 1em;
    flex-basis: 30%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-self: stretch;
`;

export const DashboardTopBarPerformanceItem = styled.section`
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
    border-bottom: ${borderThin("darker", 0.1)};
    padding-right: 1em;
    &:not(:first-child) {
        padding-top: 1em;
    }
    &:last-child {
        border: none;
    }
`;

export const ChangeTemplateContainer = styled.section`
    display: flex;
    width: 100%;
    height: 100%;
`;

export const ChangeTemplateForm = styled(Form)`
    width: 100%;
`;

export const ChangeTemplateFormContainer = styled.section`
    display: flex;
    justify-content: space-between;
    height: 100%;
`;

export const ChangeTemplateFormCreationField = styled.section`
    flex-basis: 100%;
    padding-right: 10px;
`;

export const ChangeTemplateDragAndDropField = styled.div`
    display: flex;
    flex-basis: 100%;
`;

export const ChangeTemplateDragAndDropHighlightItem = styled.p`
    color: ${color("green")};
`;

export const OverviewFormButtonGroup = styled(FormButtonGroup)`
    width: 100%;
`;

export const DashboardTopBarPerformanceItemContent = styled.h3`
    font-size: 1em;
    font-weight: 600;
    color: ${color("dark")};
    opacity: 0.9;
`;

export const DashboardTopBarPerformanceItemTitle = styled.h3`
    font-size: 1em;
    font-weight: 500;
    color: ${color("dark")};
    opacity: 0.8;
`;
