import { useRiderTerms } from "../../../lib/providers/rider-terms-provider";
import React, { useEffect } from "react";
import DocumentViewer from "../../../components/froala-read-only/document-viewer";
import { scrollToOffsetInsideParent } from "../../../lib/utils";
import PreviewTitle from "../../../components/ui/preview-title";
import DOMPurify from "dompurify";

function RidersPreview() {
    const { riderState, getGlobalHeader } = useRiderTerms();

    // Scroll active term into view
    useEffect(() => {
        if (riderState.activeRiderTerm) {
            const termElement = document.getElementById(`term-${riderState.activeRiderTerm.id}`);
            const scrollableParent = document.getElementById("rider-preview");

            if (termElement && scrollableParent) {
                scrollToOffsetInsideParent(termElement, scrollableParent, 30);
            }
        }
    }, [riderState.activeRiderTerm]);

    return (
        <DocumentViewer zoom id="rider-preview">
            <h1 className="text-3xl text-primary mb-12 text-center">{getGlobalHeader()}</h1>
            {riderState.savedTerms.map((term, index) => (
                <div key={index} id={`term-${term.id}`}>
                    <PreviewTitle>
                        {!riderState.clauseNumbering
                            ? term.title
                            : `Clause ${term.number - 1 + riderState.clauseNumberStart}: ${term.title}`}
                    </PreviewTitle>
                    <div className={"text-xs mb-10 text-wrap py-2 [&_p]:my-[.5em]"}>
                        {term.is_visible ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(term.content),
                                }}
                            />
                        ) : (
                            "– Not applicable –"
                        )}
                    </div>
                </div>
            ))}
        </DocumentViewer>
    );
}

export default RidersPreview;
