import { tenantApi } from "../tenantApi";
import { CaseFileIndex } from "../../types/case-files";

const archiveApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getArchiveCaseFiles: build.query<
            { data: Array<CaseFileIndex & { deleted_at: Date }> },
            void
        >({
            query: () => ({
                url: "/case-files/archived",
            }),
            providesTags: ["CaseFileArchive"],
        }),
        restoreCaseFile: build.mutation<boolean, string>({
            query: (id) => ({
                url: `/case-files/restore/${id}`,
                method: "POST",
            }),
            invalidatesTags: [
                "CaseFileArchive",
                {
                    type: "CaseFiles",
                    id: "LIST",
                },
            ],
        }),
    }),
});

export const { useGetArchiveCaseFilesQuery, useRestoreCaseFileMutation } = archiveApi;
