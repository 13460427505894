import axios from "axios";
import {
    EditTemplateItem,
    FetchTemplatesResponse,
    NewTemplateItem,
    ShowTemplateResponse,
    TagsResponse,
    TemplateItem,
} from "../../../types/Central";

export async function fetchTemplates(
    page: number = 1,
    search: string = "",
): Promise<FetchTemplatesResponse> {
    return await axios
        .get(`/admin/api/v1/templates?page=${page}&search=${search}`)
        .then((res) => res.data);
}

export async function createTemplate(data: NewTemplateItem): Promise<ShowTemplateResponse> {
    const formData = new FormData();

    if (data.file !== undefined) {
        formData.append("thumbnail", data.file);
    }

    formData.append("description", data.description ?? "");
    formData.append("footer", data.footer ?? "");
    formData.append("template_type", data.template_type);
    formData.append("content", data.content);
    formData.append("name", data.name);
    formData.append("stylesheet", data.stylesheet);
    formData.append("service_fee", data.service_fee.toString());
    formData.append("copyright_fee", data.copyright_fee.toString());
    formData.append("is_smart", Number(data.is_smart).toString());
    formData.append("multiple_pages", Number(data.multiple_pages).toString());
    formData.append("landscape", Number(data.landscape).toString());

    return await axios.post("/admin/api/v1/templates", formData).then((res) => res.data);
}

export async function updateTemplate(
    data: EditTemplateItem,
    recapItems: Array<string>,
): Promise<ShowTemplateResponse> {
    return await axios
        .post("/admin/api/v1/templates/update", {
            data: data,
            recapItems: recapItems,
        })
        .then((res) => res.data);
}

export async function fetchRiderTemplates(): Promise<FetchTemplatesResponse> {
    return await axios.get("/admin/api/v1/templates/riders").then((res) => res.data);
}

export async function createRiderTemplate(data: NewTemplateItem): Promise<ShowTemplateResponse> {
    return await axios.post("/admin/api/v1/templates/riders", data).then((res) => res.data);
}

export async function updateRiderTemplate(data: TemplateItem): Promise<ShowTemplateResponse> {
    return await axios.put("/admin/api/v1/templates/riders", data).then((res) => res.data);
}

export async function fetchTags(): Promise<TagsResponse> {
    return await axios.get("/admin/api/v1/central-tags").then((res) => res.data);
}
