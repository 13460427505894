import React, { Fragment } from "react";
import {
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
} from "../ui/dropdown-menu";
import { ContextMenuSeparator } from "../ui/context-menu";
import { Button } from "../ui/button";
import { MenuDropDownProps } from "../../types/table";

function DataTableDropDown<Generic>({ items, setSelectedAction, row }: MenuDropDownProps<Generic>) {
    return (
        <DropdownMenuContent className="w-56" align="end">
            <DropdownMenuLabel>{items.label}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {items.groups.map((group, index) => (
                <Fragment key={index}>
                    <DropdownMenuGroup>
                        {group.actions
                            .filter((action) => (action?.hide ? !action.hide(row) : true))
                            .map((action, index) =>
                                action.children ? (
                                    <div className="w-full justify-start px-2" key={index}>
                                        <DropdownMenuSub>
                                            <DropdownMenuSubTrigger>
                                                {action.icon}
                                                <span className="font-medium">
                                                    {" "}
                                                    {action.label}{" "}
                                                </span>
                                            </DropdownMenuSubTrigger>
                                            <DropdownMenuPortal>
                                                <DropdownMenuSubContent>
                                                    {action.children.map((child, childindex) => (
                                                        <DropdownMenuItem
                                                            className="justify-between"
                                                            key={childindex}
                                                        >
                                                            {child(row)}
                                                        </DropdownMenuItem>
                                                    ))}
                                                </DropdownMenuSubContent>
                                            </DropdownMenuPortal>
                                        </DropdownMenuSub>
                                    </div>
                                ) : (
                                    <Button
                                        variant={action.dangerous ? "destructive" : "ghost"}
                                        size="sm"
                                        className="w-full justify-start"
                                        onClick={() => {
                                            setSelectedAction(action);
                                        }}
                                        key={index}
                                    >
                                        <DropdownMenuItem
                                            className={
                                                action.dangerous
                                                    ? "bg-red-500 focus:bg-red-500"
                                                    : ""
                                            }
                                        >
                                            {action.icon}
                                            <span className={action.dangerous ? "text-white" : ""}>
                                                {action.label}
                                            </span>
                                        </DropdownMenuItem>
                                    </Button>
                                ),
                            )}
                    </DropdownMenuGroup>
                    {items.groups.length > 1 &&
                    index < items.groups.length - 1 &&
                    items.groups[index + 1] &&
                    items.groups[index + 1].actions.filter((action) =>
                        action?.hide ? !action.hide(row) : true,
                    ).length > 0 ? (
                        <ContextMenuSeparator />
                    ) : null}
                </Fragment>
            ))}
        </DropdownMenuContent>
    );
}

export default DataTableDropDown;
