import { TableActionProps } from "../../types/table";
import { CaseFileIndex } from "../../types/case-files";
import React, { Fragment, useState } from "react";
import { DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { useRestoreCaseFileMutation } from "../../api/tenant/archive";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/global/loading";

function CaseFileRestore({ row, onClose }: TableActionProps<CaseFileIndex>) {
    const [restoreCaseFile] = useRestoreCaseFileMutation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);
        try {
            await restoreCaseFile(row.id);

            toast.success(`Successfully restored ${row.document_name}`, {
                action: {
                    label: "View",
                    onClick: () => navigate(`/case-file/${row.id}`),
                },
            });
        } catch (e) {
            toast.error("Error while restoring Recap/ C/p");
        }

        onClose();

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Restore {row.document_name}</DialogTitle>
                    </DialogHeader>
                    <DialogFooter className="mt-4">
                        <Button type="submit" onClick={handleSubmit}>
                            Restore
                        </Button>
                    </DialogFooter>
                </Fragment>
            }
        />
    );
}

export default CaseFileRestore;
