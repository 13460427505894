import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { RecapSingleFieldInterface } from "../Central/Pages/RecapTemplates/index.interface";

const columns: ColumnDef<RecapSingleFieldInterface>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Name",
        },
    },
    {
        accessorKey: "data_id",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Data Id" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Data Id",
        },
    },
    {
        accessorKey: "categoryName",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Category Name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Category",
        },
    },
    {
        accessorKey: "data_type",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Field Type" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Field Type",
        },
    },
];

export { columns };
