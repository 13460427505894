import React, { createContext, useContext, useEffect, useReducer } from "react";
import {
    NotificationActionTypes,
    NotificationPanelContext,
    NotificationPanelState,
} from "../../types/notifications";
import { ProviderWithChildren } from "../../types";
import { getNotifications } from "../../api/tenant/notifications";

const NotificationContext = createContext<NotificationPanelContext>(null!);

function useNotifications() {
    return useContext(NotificationContext);
}

function NotificationProvider({ children }: ProviderWithChildren) {
    const [notificationPanelState, setNotificationPanelState] = useReducer(
        (state: NotificationPanelState, newState: Partial<NotificationPanelState>) => ({
            ...state,
            ...newState,
        }),
        {
            notifications: [],
            loaded: false,
            loading: true,
            currentPage: 1,
            startFetch: false,
        },
    );

    useEffect(() => {
        if (
            notificationPanelState.startFetch &&
            notificationPanelState.notifications.length === 0
        ) {
            init();
        }
    }, [
        notificationPanelState.currentPage,
        notificationPanelState.loading,
        notificationPanelState.startFetch,
    ]);

    async function init() {
        // prevent new request when last page is reached
        if (!notificationPanelState.loading) {
            return;
        }

        try {
            const res = await getNotifications(notificationPanelState.currentPage);
            const notifications = res.data;
            // check is it's the last page
            const lastPage = res.meta.current_page >= res.meta.last_page;

            setNotificationPanelState({
                notifications: [...notificationPanelState.notifications, ...notifications],
                loading: lastPage ? false : notificationPanelState.loading,
                currentPage: !lastPage
                    ? notificationPanelState.currentPage + 1
                    : notificationPanelState.currentPage,
            });
        } catch (err) {
            setNotificationPanelState({
                loading: false,
            });
        }

        setNotificationPanelState({
            loaded: true,
        });
    }

    function startFetch() {
        setNotificationPanelState({
            startFetch: true,
        });
    }

    function generateMessage(action_type: NotificationActionTypes): string {
        switch (action_type) {
            case "document-created":
                return " created document ";
            case "document-edited":
                return " edited document ";
            case "document-saved":
                return " saved document ";
            case "document-finalized":
                return " finalized document ";
            case "document-un-finalized":
                return " unfinalized document ";
            case "document-uploaded":
                return " uploaded a document ";
            case "recap-created":
                return " made the recap ";
            case "recap-saved":
                return " saved the recap ";
            case "recap-deleted":
                return " deleted the recap ";
            case "created":
                return " created a case file";
            case "document-deleted":
                return " deleted a document ";
            default:
                return ` did ${action_type} to `;
        }
    }

    const exposedValues: NotificationPanelContext = {
        notifications: notificationPanelState.notifications,
        startFetch: startFetch,
        generateMessage: generateMessage,
    };

    return (
        <NotificationContext.Provider value={exposedValues}>
            {children}
        </NotificationContext.Provider>
    );
}

export { useNotifications, NotificationProvider };
