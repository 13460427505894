export const caseFileOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Case file overview",
        },
    },
    maintainAspectRatio: true,
};

export const revenueOptions = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: "Revenue overview",
        },
    },
    maintainAspectRatio: false,
};

export const accountOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Overview for accounts",
        },
    },
    maintainAspectRatio: true,
};
