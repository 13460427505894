import { useRemoveRotApprovalMutation } from "../../../api/tenant/case-file";
import { RotActions } from "../../../types/case-files";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import {
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Alert, AlertDescription } from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import React, { Fragment, useState } from "react";
import { toast } from "sonner";
import Loading from "../../../components/global/loading";

function RotRemoveApproval({ rot_link, onClose }: RotActions) {
    const { case_file_id, rot_links } = useCaseFiles();
    const amount_of_approvals = rot_links?.filter((x) => x.approval_id).length ?? 0;
    const [removeRotApproval] = useRemoveRotApprovalMutation();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);

        try {
            await removeRotApproval({
                approval_id: rot_link.approval_id ?? "",
                case_file_id: case_file_id,
                amount_of_approvals: amount_of_approvals,
                supply_chain_id: rot_link.id,
            });

            toast.success("Approval withdrawn successfully");

            onClose();
        } catch (e) {
            toast.error("Something went wrong while withdrawing approval");
        }

        setLoading(false);
    }

    return (
        <DialogContent>
            <Loading
                loaded={!loading}
                child={
                    <Fragment>
                        <DialogHeader>
                            <DialogTitle>Withdraw approval</DialogTitle>
                        </DialogHeader>
                        <Alert className="mb-2">
                            <AlertDescription className="text-slate-950">
                                If you proceed, this company will not be printed on PDF files as
                                having approved this Recap C/P.
                            </AlertDescription>
                        </Alert>
                        <DialogFooter className="mt-4">
                            <Button type="submit" onClick={handleSubmit}>
                                Remove
                            </Button>
                        </DialogFooter>
                    </Fragment>
                }
            />
        </DialogContent>
    );
}

export default RotRemoveApproval;
