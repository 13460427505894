import React, { forwardRef, HTMLAttributes } from "react";
import { cn } from "../../lib/utils";

const PreviewTitle = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(
                "text-[#38567B] uppercase text-[14px] font-bold underline m-0 pb-2",
                className,
            )}
            {...props}
        />
    ),
);
PreviewTitle.displayName = "Preview title";

export default PreviewTitle;
