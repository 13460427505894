import React, { Fragment, useState } from "react";
import DataTableContextMenuDropDown from "./data-table-context-menu-drop-down";
import DataTableDropDown from "./data-table-drop-down";
import { DataTableDropDownProps, TableDropDownAction } from "../../types/table";
import DataTableActionContext from "./data-table-action-context";

function DataTableAdvancedDropDown<Generic>({ row, type, items }: DataTableDropDownProps<Generic>) {
    const [selectedAction, setSelectedAction] = useState<TableDropDownAction<Generic>>();

    return (
        <Fragment>
            {type === "dropdown" && (
                <DataTableDropDown<Generic>
                    items={items}
                    setSelectedAction={(selectedAction) => setSelectedAction(selectedAction)}
                    row={row}
                />
            )}
            {type === "context" && (
                <DataTableContextMenuDropDown<Generic>
                    items={items}
                    setSelectedAction={(selectedAction) => setSelectedAction(selectedAction)}
                    row={row}
                />
            )}
            {selectedAction && (
                <DataTableActionContext<Generic>
                    action={selectedAction}
                    row={row}
                    onClose={() => setSelectedAction(undefined)}
                />
            )}
        </Fragment>
    );
}

export default DataTableAdvancedDropDown;
