import React, { Fragment, useContext, useState } from "react";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { useFinalizeDocumentMutation } from "../../../api/tenant/case-file";
import { Button } from "../../../components/ui/button";
import { toast } from "sonner";
import { GlobalDocumentInterface } from "../../../types/document-editor";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { TableActionProps } from "../../../types/table";
import { DocumentEditorContext } from "../../../lib/providers/DocumentEditorProvider";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import { useNavigate } from "react-router";
import Loading from "../../../components/global/loading";

function FinalizeDocument({
    row,
    onClose,
}: TableActionProps<(CaseFileDocumentIndex & { content?: string }) | GlobalDocumentInterface>) {
    const { case_file_id } = useCaseFiles();
    const [finalizeDocument] = useFinalizeDocumentMutation();
    const editorContext = useContext(DocumentEditorContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);
        try {
            const hasSignatures = row.content?.includes("data-signature-id") ?? false;

            await finalizeDocument({
                case_file_id: case_file_id,
                document_id: row.id,
                hasSignatures: hasSignatures,
            }).unwrap();

            if (editorContext) {
                if (hasSignatures) {
                    editorContext.setSigningStatus("allowed");
                }
                editorContext.finalizeDocument();
            }

            onClose();
            toast.message(`Finalized ${row.document_name}`);
        } catch (e) {
            toast.error(
                "Could not finalize document, please check if billing info is provided or the invoicing threshold is reached.",
                {
                    action: {
                        label: "Billing",
                        onClick: () => navigate("/billing"),
                    },
                },
            );
        }

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Finalize {row.document_name}</DialogTitle>
                        <DialogDescription>
                            Finalize this document to remove editing options and the &quot;WORKING
                            COPY&quot; watermark
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="mt-4">
                        <Button type="submit" onClick={handleSubmit}>
                            Finalize
                        </Button>
                    </DialogFooter>
                </Fragment>
            }
        />
    );
}

export default FinalizeDocument;
