import { TypedFunction } from "../types";
import { useEffect, useState } from "react";

export default function useInterval<CallBackArgs, CallBackReturn>(
    callback: TypedFunction<CallBackArgs, CallBackReturn>,
    args: CallBackArgs,
    delay: number,
) {
    const [secondsRemaining, setSecondsRemaining] = useState<number>(delay);

    useEffect(() => {
        const timer = setTimeout(() => {
            let timeLeft = secondsRemaining - 1;

            if (timeLeft === 0) {
                timeLeft = delay;
                callback.fn(args);
            }

            setSecondsRemaining(timeLeft);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [secondsRemaining]);

    return { secondsRemaining };
}
