import styled from "styled-components";
import { color, flexColumn } from "../../styles/mixins";

export const TenantDetailContainer = styled.section`
    ${flexColumn};
`;

export const TenantTopBarContainer = styled.section`
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
`;

export const TenantMainContainer = styled.section`
    background-color: ${color("white")};
    padding: 1.5em;
    border-radius: 1em;
    margin-top: 1.5em;
    ${flexColumn};
`;

export const TenantEditButton = styled.section`
    display: flex;
    justify-content: center;
    background-color: ${color("primary")};
    padding: 0.675em 1em 0.5em;
    color: ${color("white")};
    text-transform: uppercase;
    border-radius: 0.25em;
    text-align: center;
    margin-top: auto;
    &:hover {
        cursor: pointer;
    }
`;

export const HouseFormWrapper = styled.section`
    background-color: ${color("white")};
    padding: 1.5em;
    border-radius: 1em;
    flex-basis: 47.5%;
    display: inline-flex;
    flex-direction: row;
    align-self: stretch;
    flex-direction: column;
`;

export const InfoWrapper = styled.section`
    background-color: ${color("white")};
    padding: 1.5em;
    border-radius: 1em;
    flex-basis: 20%;
    display: inline-flex;
    flex-direction: row;
    align-self: stretch;
    flex-direction: column;
`;

export const TenantsInfoWrapper = styled(InfoWrapper)`
    flex-basis: 30%;
`;
