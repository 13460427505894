export function avatarInitials(name: string): string {
    const split = name.split(" ");
    if (split.length > 1) {
        return split[0].charAt(0) + split[1].charAt(0);
    } else {
        return split[0].charAt(0) + split[0].charAt(1);
    }
}

export function avatarColors(id: string, isCurrentUser: boolean): Record<string, string> {
    const parts = id.split("-");
    const color = parseInt(parts[0], 16) % 360;

    return {
        color: "hsl(" + color + ",50%,100%)",
        outlineOffset: "1px",
        outline: isCurrentUser ? "2px solid hsl(" + color + ",50%,80%)" : "none",
        background: "hsl(" + color + ",50%,62%)",
    };
}
