import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Card, CardHeader, CardTitle } from "../../../components/ui/card";
import { useLazyGetCsrfCookieQuery } from "../../../api/cookieApi";
import { Typography } from "../../../components/ui/typography";
import { Button } from "../../../components/ui/button";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useAuth } from "../../../lib/providers/AuthProvider";
import { determineTenantName } from "../../../lib/helpers/tenant";

type SigneeInfo = {
    name: string;
    client_user_id: number;
    email: string;
    signed: number;
    document_id: string;
};

export async function getSigneeInfo(
    userId: string,
    id: string,
    token?: string,
): Promise<SigneeInfo> {
    return await axios
        .get(`api/tenant/v1/docusign/signatures/${userId}/${id}`, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        })
        .then((r) => r.data);
}

export async function viewPdf(file_name: string, token?: string): Promise<Blob | string> {
    return await axios
        .get(`/api/tenant/v1/files/signed-pdf/${file_name}`, {
            responseType: "blob",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        })
        .then((res: { data: any }) => res.data);
}

export async function requestSigningDocument(
    email: string,
    userId: string,
    id: string,
    token: string,
) {
    return await axios
        .get(`/api/tenant/v1/docusign/signatures/${email}/${userId}/${id}`, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        })
        .then((r) => r.data);
}

export interface SigningEventInterface {
    document_name: string;
    tenant: string;
    signed_by: string;
    oceanrecap_user: string;
}

function Docusign() {
    const { token, userid, id, filename } = useParams<{
        token: string;
        userid: string;
        id: string;
        filename: string;
    }>();
    const [name, setName] = useState<string | undefined>();
    const [email, setEmail] = useState<string | undefined>();
    const [documentId, setDocumentId] = useState<string>();
    const [documents, setDocuments] = useState<{
        uri: string;
        fileType: string;
        fileName: string;
    } | null>(null);
    const [signableDocumentLink, setSignableDocumentLink] = useState<string>("");
    const [getCsrfCookie] = useLazyGetCsrfCookieQuery();
    const echo = window.Echo;
    const auth = useAuth();
    const tenant_id = determineTenantName();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            auth.signInByToken(token);
        }
    }, [token]);

    useEffect(() => {
        if (documentId && auth.state.socketConnected) {
            echo.join(`${tenant_id}.docusign.${documentId}`)
                .listen(".signing_completed", (e: SigningEventInterface) => {
                    if (e.signed_by === userid) {
                        auth.signOut();
                        navigate(`/signing-completed/${e.document_name}`);
                    }
                })
                .listen(".viewing_complete", (e: SigningEventInterface) => {
                    if (e.signed_by === userid) {
                        auth.signOut();
                        navigate(`/signing-completed/${e.document_name}`);
                    }
                })
                .listen(".cancel", (e: SigningEventInterface) => {
                    auth.signOut();
                    navigate(`/signing-cancelled/${e.document_name}`);
                })
                .listen(".decline", (e: SigningEventInterface) => {
                    if (e.signed_by === userid) {
                        auth.signOut();
                        navigate(`/signing-declined/${e.document_name}`);
                    }
                });
        }
    }, [documentId, auth.state.user, auth.state.socketConnected]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        if (userid && id && filename && token) {
            await getCsrfCookie();
            const signeeData = await getSigneeInfo(userid, id, token);
            setName(signeeData.name);
            setEmail(signeeData.email);
            setDocumentId(signeeData.document_id);

            const blob = await viewPdf(filename, token);

            setFileData({
                data: blob,
                filename: filename,
            });
        }
    }

    type DataType = {
        data: Blob | string;
        filename: string;
    };

    function setFileData(data: DataType) {
        const fileType = "pdf";

        setDocuments({
            uri: URL.createObjectURL(data.data as Blob),
            fileType: fileType,
            fileName: filename ?? "",
        });
    }

    async function handleSignDocument() {
        if (token && id && email && userid) {
            const docusignIframeUrl = await requestSigningDocument(email, userid, id, token);

            setSignableDocumentLink(docusignIframeUrl.url);
        }
    }

    return (
        <div className="bg-white">
            {!signableDocumentLink && (
                <Fragment>
                    <Card>
                        <CardHeader className="flex flex-row justify-between items-center">
                            <div>
                                <CardTitle>Review the contract</CardTitle>
                                <Typography text={`Name: ${name}`} style="p" />
                                <Typography text={`Email: ${email}`} style="p" />
                            </div>

                            <Button onClick={handleSignDocument}>Sign</Button>
                        </CardHeader>
                    </Card>
                    <div className="max-w-[1506px] m-auto w-[98%] flex justify-between">
                        <Fragment>
                            <div className="py-[4px] px-[9px] flex w-full">
                                {documents && (
                                    <DocViewer
                                        documents={[documents]}
                                        config={{
                                            header: {
                                                disableFileName: true,
                                                disableHeader: true,
                                            },
                                            pdfZoom: {
                                                defaultZoom: 1.0,
                                                zoomJump: 0.4,
                                            },
                                            pdfVerticalScrollByDefault: true,
                                        }}
                                        pluginRenderers={DocViewerRenderers}
                                        prefetchMethod="GET"
                                        style={{}}
                                    />
                                )}
                            </div>
                        </Fragment>
                    </div>
                </Fragment>
            )}
            {signableDocumentLink && (
                <div className="flex justify-center q-full h-[calc(100vh-5em)]">
                    <iframe className="h-full w-[95%] rounded" src={signableDocumentLink} />
                </div>
            )}
        </div>
    );
}

export default Docusign;
