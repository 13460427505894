import { useGetOrganisationMembersQuery } from "../../api/tenant/organisation";
import Loading from "../../components/global/loading";
import React, { Fragment, useState } from "react";
import { Typography } from "../../components/ui/typography";
import TeamTable from "./team-table";
import { DataTableToolbarButton } from "../../types/table";
import InviteTeamMember from "./invite-team-member";
import { usePageTitle } from "../../lib/usePageTitle";

function Team() {
    const { data: users, isLoading } = useGetOrganisationMembersQuery();

    usePageTitle("Team");

    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

    const buttons: Array<DataTableToolbarButton> = [
        {
            label: "Invite team member",
            action: () => setShowInviteModal(true),
        },
    ];

    return (
        <Loading
            loaded={!isLoading}
            child={
                <Fragment>
                    <InviteTeamMember
                        open={showInviteModal}
                        onClose={() => setShowInviteModal(false)}
                    />
                    <div className="flex flex-col justify-between space-y-2 bg-foreground p-4 border border-solid border-slate-200">
                        <div className="flex items-center justify-between w-full">
                            <Typography text="Team" style="h2" className="pb-0" />
                        </div>
                    </div>
                    <div className=" w-full flex items-stretch h-full">
                        <div className="bg-foreground p-4 rounded-sm">
                            <TeamTable data={users?.data ?? []} buttons={buttons} />
                        </div>
                    </div>
                </Fragment>
            }
        />
    );
}

export default Team;
