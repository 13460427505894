import React, { Fragment, useEffect, useState } from "react";
import { Backup, getBackups } from "../../Admin/Backups";
import { DashboardTopBarContainerContent } from "../Dashboard/Style";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import { columns } from "../../../config/backups";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";

export function BackupsPage() {
    const [data, setData] = useState<Array<Backup>>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    // const handleCreateBackup = () => {
    //     createBackup().then((response) => {
    //         revalidate();
    //     });
    // };

    // const handleClick = (row: Backup) => {
    //     navigate(`/${adminPrefix}/backups/${row.id}`);
    // };

    useEffect(() => {
        revalidate();
    }, []);

    const revalidate = () => {
        getBackups().then((response) => {
            setData(response.data);
        });
    };

    return (
        <Fragment>
            <DashboardTopBarContainerContent>
                <div className="flex flex-col h-full">
                    <DataTableToolbar
                        table={table}
                        title="Backups"
                        searchableColumns={["type", "started_at", "status"]}
                    />
                    <div className="flex py-4 h-full">
                        <DataTable columns={columns} table={table} onClick={() => void 0} />
                    </div>
                </div>
            </DashboardTopBarContainerContent>
        </Fragment>
    );
}
