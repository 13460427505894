import { TenantUser } from "../../../types/auth";
import { createSpanWithText, getFroalaSelectionDetails, moveToNextNode } from "./base";
import {
    checkAdditionSpan,
    checkDeletionSpan,
    countCharsPrevSiblings,
    findSiblingLeafNodes,
    isFrMarker,
    isLineNumber,
    isUserBR,
} from "./inspectDOM";

/**
 * Handles deletion operations within a selection in the Froala editor by wrapping text nodes
 * within deletion spans and removing text from addition spans as necessary.
 * It iterates through the nodes in the selection, applying specific modifications based on the node type and attributes.
 *
 * @param {Selection} selection - The current selection object within the Froala editor.
 * @param {TenantUser} user - The user that made this change to the document.
 * @returns {{ lastNode: Node | null, lastOffset: number }} An object containing the last span element that was created
 *                                                           during the operation and the offset within the last node,
 *                                                           or null if no valid operations were performed due to invalid selection details.
 */
export function handleSelectionDeletion(
    selection: Selection,
    user: TenantUser,
): { lastNode: Node | null; lastOffset: number } {
    let currentNode = null;
    let lastSpan = null; // To store the last span created

    const selectionDetails = getFroalaSelectionDetails(selection);
    if (!selectionDetails) {
        return { lastNode: null, lastOffset: 0 };
    }

    // eslint-disable-next-line prefer-const
    let { startNode, endNode, startOffset, endOffset, gca } = selectionDetails;

    if (startNode.nodeType === Node.ELEMENT_NODE) {
        const newStart = startNode.childNodes[startOffset];
        if (newStart.nodeName === "A" && !newStart.textContent) {
            startNode = newStart.nextSibling!;
        } else {
            startNode = newStart;
        }
        startOffset = 0;
    }

    if (endNode.nodeType === Node.ELEMENT_NODE) {
        const { left } = findSiblingLeafNodes(endNode.childNodes[endOffset]);
        if (left && left.textContent) {
            endNode = left;
            endOffset = left.textContent.length;
        }
    }

    let returnOffset = endOffset;

    // Setup a TreeWalker to iterate through the nodes within the user selection
    const treeWalker = document.createTreeWalker(
        gca,
        NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT, // Show both text nodes and elements
        {
            acceptNode: function (node) {
                if (
                    isFrMarker(node) // Skip Froala markers
                ) {
                    return NodeFilter.FILTER_SKIP;
                }
                return NodeFilter.FILTER_ACCEPT;
            },
        },
    );

    currentNode = startNode;
    treeWalker.currentNode = startNode;
    const nodesToWrap = [];
    const nodesToRemove = [];
    const lineBreaks = [];

    // Walk through the selected nodes and store the text nodes to wrap
    while (currentNode) {
        const isStartNode = currentNode === startNode;
        const isEndNode = currentNode === endNode;

        // Determine offsets for each node
        let realStartOffset;
        let realEndOffset;
        if (currentNode.textContent) {
            realStartOffset = isStartNode ? startOffset : 0;
            realEndOffset = isEndNode ? endOffset : currentNode.textContent.length;
        } else {
            realStartOffset = 0;
            realEndOffset = 0;
        }
        // Adjust for selection within a single node
        if (startNode === endNode) {
            realEndOffset = endOffset;
        }

        // To remove newlines
        if (isUserBR(currentNode)) {
            lineBreaks.push(currentNode);
        }

        // If we encounter a text element, store it for wrapping in a span
        if (currentNode.nodeType === Node.TEXT_NODE) {
            // Do not remove text nodes that are children of a deletion span or line number nodes
            const checkDeletion = checkDeletionSpan(currentNode);
            if (checkDeletion || isLineNumber(currentNode)) {
                // Skip to next loop iteration
                currentNode = moveToNextNode(treeWalker, isEndNode);
                continue;
            }

            // Remove any existing addition spans
            const checkAddition = checkAdditionSpan(currentNode);
            if (checkAddition) {
                const offsetError = countCharsPrevSiblings(checkAddition, currentNode);

                nodesToRemove.push({
                    node: checkAddition,
                    startOffset: realStartOffset + offsetError,
                    endOffset: realEndOffset + offsetError,
                });
                // Skip to next loop iteration
                currentNode = moveToNextNode(treeWalker, isEndNode);
                continue;
            }
            // Collect the text node to wrap
            nodesToWrap.push({
                node: currentNode,
                startOffset: realStartOffset,
                endOffset: realEndOffset,
            });
        }

        currentNode = moveToNextNode(treeWalker, isEndNode);
    }

    // Remove linebreaks
    for (const node of lineBreaks) {
        (node as Element).remove();
    }

    // Remove any existing addition spans
    for (const { node, startOffset, endOffset } of nodesToRemove) {
        const span = removeText(node, startOffset, endOffset);
        if (span) {
            lastSpan = span;
            returnOffset = startOffset;
        }

        // Should figure out what to return if the endNode is removed, otherwise we cannot set the cursor back
    }

    // For each text node, wrap it in a span
    for (const { node, startOffset, endOffset } of nodesToWrap) {
        const span = wrapNode(node, startOffset, endOffset, user);
        // Stores the last span created
        if (span) {
            lastSpan = span;
            returnOffset = startOffset;
        }
    }

    // Return lastSpan, or endNode if no spans were created
    return { lastNode: lastSpan || endNode, lastOffset: returnOffset };
}

/**
 * Wraps a specified portion of a text node within a new span element. This function is useful for
 * applying specific styles or attributes to a portion of text without affecting the entire text node.
 *
 * @param {Node} node - The text node to be partially wrapped. This must be a Node of type Text.
 * @param {number} startOffset - The starting index within the text node where wrapping begins.
 * @param {number} endOffset - The ending index within the text node where wrapping ends.
 * @param {TenantUser} user - The user that made this change to the document.
 * @returns {Node | null} The newly created span element that now contains the wrapped text,
 *                               or null if the operation fails due to invalid input or missing parent.
 */
function wrapNode(
    node: Node,
    startOffset: number,
    endOffset: number,
    user: TenantUser,
): Node | null {
    if (!(node instanceof Text) || !node.parentNode) {
        return null;
    }

    // Split the text node at the endOffset, then at the startOffset
    node.splitText(endOffset);
    const selectedText = node.splitText(startOffset);

    if (!selectedText.textContent) {
        return null;
    }

    // Wrap the selected text in a span
    const span = createSpanWithText(
        { "data-change-type": "deletion" },
        selectedText.textContent,
        user.id,
    );

    // Replace the selected text node with the span
    node.parentNode.replaceChild(span, selectedText);

    return span;
}

/**
 * Removes a specified range of text from a node. If the entire text of the node is selected,
 * the node itself is removed from the DOM. If only a part of the text is selected, it adjusts
 * the node's text content to exclude the specified range.
 *
 * @param {Node} node - The node from which text will be removed. This should ideally be an element node
 *                      that contains text, such as a text node within a span.
 * @param {number} startOffset - The starting index within the node's text content where removal begins.
 * @param {number} endOffset - The ending index within the node's text content where removal ends.
 * @returns {Node | null} The modified node with text removed, or null if the entire node was removed
 *                        or if the node does not meet the conditions for modification.
 */
function removeText(node: Node, startOffset: number, endOffset: number): Node | null {
    if (!node || !node.parentNode) {
        return null;
    }

    // We expect node to be a marker span
    if (node.nodeType === Node.ELEMENT_NODE && node.textContent) {
        // Remove nodes that are selected in whole
        if (startOffset === 0 && endOffset === node.textContent.length) {
            node.parentNode.removeChild(node);
            return null;
        } else {
            node.textContent =
                node.textContent.substring(0, startOffset) + node.textContent.substring(endOffset);
            return node;
        }
    }

    return node;
}
