import { RotActions } from "../../../types/case-files";
import {
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Alert, AlertDescription } from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import React, { Fragment, useState } from "react";
import { useMarkRotLinkForApprovalMutation } from "../../../api/tenant/case-file";
import { toast } from "sonner";
import Loading from "../../../components/global/loading";

function RotMarkForApproval({ rot_link, onClose }: RotActions) {
    const [markRotLinkForApproval] = useMarkRotLinkForApprovalMutation();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);
        try {
            await markRotLinkForApproval(rot_link.id).unwrap();
            toast.success("Approval request was submitted");
            onClose();
        } catch (e) {
            toast.error("Error");
        }

        setLoading(false);
    }

    return (
        <DialogContent>
            <Loading
                loaded={!loading}
                child={
                    <Fragment>
                        <DialogHeader>
                            <DialogTitle>Request approval</DialogTitle>
                        </DialogHeader>
                        <Alert className="mb-2">
                            <AlertDescription className="text-slate-950">
                                Commencing the approval process will lock this Recap C/P in
                                read-only mode.
                                <br />
                                <br />
                                You can add other approvers at any time. There are two outcomes to
                                the process:
                                <br />
                                <br />
                                <b>1. Fully approved</b>
                                <br />
                                The Recap C/P will be marked as Fully Approved and all PDF exports
                                will be marked as such.
                                <br />
                                <br />
                                <b>2. Approval rejected</b>
                                <br />
                                You will receive a notification stating why this Recap C/P was
                                declined. You can, at any time, re-start the approval process.
                                <br />
                                <br />
                                You can reset the approval process at any time to re-enable editing.
                            </AlertDescription>
                        </Alert>
                        <DialogFooter className="mt-4">
                            <Button type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </DialogFooter>
                    </Fragment>
                }
            />
        </DialogContent>
    );
}

export default RotMarkForApproval;
