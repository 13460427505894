import { DataTableColumnHeaderProps } from "../../types/table";
import { cn } from "../../lib/utils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { ListFilter } from "lucide-react";
import { CheckIcon } from "@radix-ui/react-icons";
import * as React from "react";
import SearchableList from "../searchable-list/searchable-list";

function DataTableFilter<TData, TValue>({
    column,
    title,
    className,
    valueFormatter,
}: Omit<DataTableColumnHeaderProps<TData, TValue>, "type">) {
    const facets = column?.getFacetedUniqueValues();
    const selectedValues = new Set(column?.getFilterValue() as TValue[]);

    return (
        <div className={cn("flex items-center space-x-2", className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="ghost"
                        size="sm"
                        className="-ml-3 h-8 data-[state=open]:bg-accent"
                    >
                        <span>{title}</span>
                        <ListFilter className="ml-2 h-3 w-3" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <SearchableList<TValue>
                        groups={[
                            {
                                heading: undefined,
                                options: Array.from(facets.keys()).filter((x) => x && x.length),
                            },
                        ]}
                        selectedValues={selectedValues}
                        onOptionSelected={(filterValues) => {
                            column?.setFilterValue(
                                filterValues && filterValues.size > 0
                                    ? Array.from(filterValues)
                                    : undefined,
                            );
                        }}
                        searchPlaceholder={title}
                        labelFormatter={valueFormatter}
                        commandItemClassName="max-w-60"
                        clearable
                        multiSelectable
                        autoFocusSearch
                    >
                        {(props) => (
                            <div className={`flex h-4 py-2 w-full items-center`}>
                                <div
                                    className={cn(
                                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border" +
                                            " border-primary",
                                        props.isSelected
                                            ? "bg-primary text-primary-foreground"
                                            : "opacity-50 [&_svg]:invisible",
                                    )}
                                >
                                    <CheckIcon className={cn("h-4 w-4")} />
                                </div>
                                <span className="text-gray-950 overflow-hidden text-ellipsis truncate max-w-40">
                                    {props.option.label}
                                </span>
                                {facets?.get(props.option.value) && (
                                    <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                                        {facets.get(props.option.value)}
                                    </span>
                                )}
                            </div>
                        )}
                    </SearchableList>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

export default DataTableFilter;
