import React, { Fragment, useEffect, useState } from "react";
import {
    DashboardTopBarContainerContent,
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentTitle,
} from "../Style";
import { activityStats } from "../../../Admin/Stats";
import { DataTableToolbar } from "../../../../components/data-table/data-table-toolbar";
import DataTable from "../../../../components/data-table/data-table";
import { columns } from "../../../../config/central-recent-activity";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { RecentActivity } from "../../../../types/case-files";
import saveAs from "file-saver";
import axios from "axios";

async function documentPDFblob(tenant: string, document_id: string): Promise<Blob> {
    return await axios
        .post(
            "/admin/api/v1/getdocumentpdfblob",
            {
                tenant: tenant,
                document_id: document_id,
            },
            {
                responseType: "blob",
            },
        )
        .then((r) => r.data);
}
export function AdminActivityPage() {
    const [activityData, setActivityData] = useState<Array<RecentActivity>>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data: activityData,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        activityStats().then((r: { data: React.SetStateAction<RecentActivity[]> }) =>
            setActivityData(r.data),
        );
    }

    function downloadFile(row: RecentActivity) {
        if (row.type === "file_exported") {
            const fileName = row.template + ".pdf";

            documentPDFblob(row.tenant, row.document_id).then((blob: string | Blob) =>
                saveAs(blob, fileName),
            );
        }
    }

    return (
        <Fragment>
            <DashboardTopBarContainerContent>
                <DashboardTopBarContainerContentHeader>
                    <DashboardTopBarContainerContentTitle>
                        Recent activity
                    </DashboardTopBarContainerContentTitle>
                </DashboardTopBarContainerContentHeader>
                <div className="flex flex-col h-full">
                    <DataTableToolbar
                        table={table}
                        title="Recent Activity"
                        searchableColumns={["user", "tenant", "message", "template"]}
                    />
                    <div className="flex py-4 h-full">
                        <DataTable columns={columns} table={table} onClick={downloadFile} />
                    </div>
                </div>
            </DashboardTopBarContainerContent>
        </Fragment>
    );
}
