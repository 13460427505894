import { Card } from "../../../components/ui/card";
import React from "react";

import { useAddenda } from "../../../lib/providers/addenda-provider";
import DocumentViewer from "../../../components/froala-read-only/document-viewer";
import parse from "html-react-parser";

function AddendaPreview() {
    const { activeAddendum } = useAddenda();

    return (
        <Card>
            <DocumentViewer zoom>
                <div style={{ fontFamily: "Lora" }}>{parse(activeAddendum?.content ?? "")}</div>
            </DocumentViewer>
        </Card>
    );
}

export default AddendaPreview;
