import React, { Fragment, useEffect, useRef, useState } from "react";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { useRiderTerms } from "../../../lib/providers/rider-terms-provider";
import RidersDelete from "./riders-delete";
import { useAuth } from "../../../lib/providers/AuthProvider";
import { toast } from "sonner";
import { NewRiderEvent, RiderTerm } from "../../../types/riders";
import {
    useGetRiderTermMutation,
    useGetRiderTermsQuery,
    useParseRiderSelectionMutation,
    useSetRiderNumberingMutation,
    useSetRiderStartNumberMutation,
    useToggleVisibilityRiderTermMutation,
    useUpdateRiderHeaderMutation,
} from "../../../api/tenant/riders";
import Loading from "../../../components/global/loading";
import { usePageTitle } from "../../../lib/usePageTitle";
import { Typography } from "../../../components/ui/typography";
import SearchableList from "../../../components/searchable-list/searchable-list";
import { Button } from "../../../components/ui/button";
import { LoaderCircle, MoreVertical, Plus, Save, ScanEye, Settings2 } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Switch } from "../../../components/ui/switch";
import { useDebounce } from "@uidotdev/usehooks";
import { Separator } from "../../../components/ui/separator";
import { Panel } from "../../../components/ui/panel";
import VerticalDragAndDrop from "../../../components/drag-and-drop/vertical-drag-and-drop";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import RiderTermEditor from "./rider-term-editor";
import RidersPreview from "./riders-preview";

function CaseFileRiders() {
    const { setActivePage, case_file_id, meta_data, permissions } = useCaseFiles();
    const {
        setRiderTerms,
        riderDispatch,
        riderState,
        resetView,
        persistState,
        createTerm,
        handleTermReorder,
    } = useRiderTerms();
    const riderTermsEditorRef = useRef<{
        resetForm: () => void;
    } | null>(null);
    const user = useAuth().state.user;
    const echo = window.Echo;
    const { data: terms, isLoading } = useGetRiderTermsQuery(case_file_id);
    const [getRiderTerm] = useGetRiderTermMutation();
    const [parseRiderSelection] = useParseRiderSelectionMutation();
    const [setRiderNumbering] = useSetRiderNumberingMutation();
    const [clauseNumber, setClauseNumber] = useState<number>();
    const debouncedClauseNumber = useDebounce(clauseNumber, 300);
    const [setRiderStartNumber] = useSetRiderStartNumberMutation();
    const [heading, setHeading] = useState<string>();
    const debounceHeading = useDebounce(heading, 300);
    const [updateRiderHeader] = useUpdateRiderHeaderMutation();
    const [toggleVisibilityRiderTerm] = useToggleVisibilityRiderTermMutation();
    const [showPreview, setShowPreview] = useState<boolean>(false);

    usePageTitle(`CP: ${meta_data?.reference} - Riders`);

    useEffect(() => {
        setActivePage("riders");
        if (window.innerWidth >= 1600) {
            setShowPreview(true);
        }
    }, []);

    useEffect(() => {
        if (!isLoading && terms) {
            setRiderTerms(terms);
        }
    }, [isLoading]);

    useEffect(() => {
        if (debouncedClauseNumber) {
            handleRiderNumberingSet(debouncedClauseNumber);
        }
    }, [debouncedClauseNumber]);

    useEffect(() => {
        if (debounceHeading) {
            updateHeader(debounceHeading);
        }
    }, [debounceHeading]);

    // Event listeners for GPT parsing
    useEffect(() => {
        if (user && case_file_id) {
            echo.join(`multi-clause-rider.${case_file_id}`)
                .listen(".textProcessingStarted", () => {
                    toast.info("Processing text.");
                })
                .listen(".textProcessingError", () => {
                    toast.error("Importing riders failed.");
                })
                .listen(".clauseSaved", async (rider: NewRiderEvent) => {
                    try {
                        const res = await getRiderTerm(rider.id).unwrap();

                        riderDispatch({
                            type: "ADD_TERM_GENERAL",
                            payload: res.data,
                        });

                        toast.success(`${rider.title} imported.`);
                    } catch (e) {
                        toast.error("Error while importing rider clause");
                    }
                });
        }
    }, [user]);

    async function handleRiderNumberingSet(number: number) {
        try {
            riderDispatch({
                type: "SET_CLAUSE_NUMBER_START",
                payload: await setRiderStartNumber({
                    offset: number,
                    case_file_id: case_file_id,
                }).unwrap(),
            });
        } catch (e) {
            toast.error("Error while updating clause number start");
        }
    }

    async function updateHeader(header: string) {
        try {
            riderDispatch({
                type: "SET_HEADER",
                payload: await updateRiderHeader({
                    case_file_id: case_file_id,
                    header: header,
                }).unwrap(),
            });
        } catch (e) {
            toast.error("Something went wrong while updating the heading");
        }
    }

    function handleSearchItemSelected(
        option: Set<Pick<RiderTerm, "id" | "title"> | undefined> | undefined,
    ) {
        const riderTermIndex = riderState.terms
            .map((term) => term.id)
            .indexOf(option?.values().next().value.id);

        if (riderTermIndex !== -1) {
            riderDispatch({
                type: "SET_ACTIVE_RIDER_TERM",
                payload: riderState.terms[riderTermIndex],
            });
        } else {
            if (!permissions.edit_documents) {
                return;
            }

            const clause = riderState.globalClauses.find(
                (clause) => clause.id === option?.values().next().value.id,
            );

            if (clause) {
                riderDispatch({
                    type: "SET_SHOW_MULTI_CLAUSE",
                    payload: false,
                });

                riderDispatch({
                    type: "SET_ACTIVE_RIDER_TERM",
                    payload: undefined,
                });

                riderDispatch({
                    type: "SET_RIDER_IN_CREATION",
                    payload: { title: clause.name, content: clause.content },
                });
            }
        }
    }

    async function handleSave(showToast?: boolean) {
        if (riderState.showMultiClause) {
            riderDispatch({ type: "SET_IS_WAITING_GPT", payload: true });

            try {
                await parseRiderSelection({
                    case_file_id: case_file_id,
                    term: riderState.riderInCreation.content,
                }).unwrap();

                resetView();
            } catch (e) {
                toast.error("Something went wrong while analysing ");
            }

            riderDispatch({ type: "SET_IS_WAITING_GPT", payload: false });
        } else if (!riderState.activeRiderTerm) {
            if (!riderState.showHeaderEditor && !riderState.activeRiderTerm) {
                // both fields should be filled out
                if (!riderState.riderInCreation.title || !riderState.riderInCreation.content) {
                    toast.error("Please fill out the title and content fields.");
                    return;
                }

                createTerm();
            }

            riderTermsEditorRef.current?.resetForm();
            resetView();
        } else {
            persistState(true, showToast);
        }
    }

    async function handleRiderNumberingClick() {
        try {
            const enabled = !riderState.clauseNumbering;

            await setRiderNumbering({
                case_file_id: case_file_id,
                state: enabled,
            });

            riderDispatch({ type: "SET_CLAUSE_NUMBERING", payload: enabled });
            riderDispatch({
                type: "SET_CLAUSE_NUMBER_START",
                payload: 1,
            });
        } catch (e) {
            toast.error("Error while enabling clause numbering");
        }
    }

    async function toggleRiderTermVisibility(e: React.MouseEvent<HTMLDivElement>, term: RiderTerm) {
        e.stopPropagation();

        try {
            const res = await toggleVisibilityRiderTerm({
                term_id: term.id,
                case_file_id: case_file_id,
            }).unwrap();

            const termIndex = riderState.terms.map((x) => x.id).indexOf(term.id);

            const termsList = [
                ...riderState.terms.slice(0, termIndex),
                res.data,
                ...riderState.terms.slice(termIndex + 1),
            ];

            const savedTermsList = [
                ...riderState.savedTerms.slice(0, termIndex),
                res.data,
                ...riderState.savedTerms.slice(termIndex + 1),
            ];

            riderDispatch({ type: "SET_TERMS", payload: termsList });
            riderDispatch({ type: "SET_SAVED_TERMS", payload: savedTermsList });
        } catch (e) {
            toast.error("Something went wrong while toggling visibility");
        }
    }

    function handleRemoveRiderTerm(e: React.MouseEvent<HTMLDivElement>, term: RiderTerm) {
        e.stopPropagation();

        riderDispatch({ type: "SET_ACTIVE_RIDER_TERM", payload: term });
        riderDispatch({ type: "SET_SHOW_DELETE_MODAL", payload: true });
    }

    return (
        <Fragment>
            <RidersDelete />
            <Loading
                loaded={!isLoading}
                child={
                    <div className="flex flex-col !scroll-smooth">
                        <div className="flex flex-col z-20">
                            <div className="flex pt-4 px-4 h-16 left-12 right-0 gap-4 fixed bg-background top-28 z-10">
                                <Typography className="flex-grow pb-0" text="Riders" style="h2" />
                                <SearchableList<Pick<RiderTerm, "id" | "title">>
                                    groups={[
                                        {
                                            heading: "Clauses",
                                            options: riderState.terms,
                                        },
                                        {
                                            heading: "Clause library",
                                            options: riderState.globalClauses.map((globalTerm) => ({
                                                id: globalTerm.id,
                                                title: globalTerm.name,
                                            })),
                                            className: "text-slate-500 hover:text-slate-950",
                                        },
                                    ]}
                                    onOptionSelected={handleSearchItemSelected}
                                    searchPlaceholder="Search terms"
                                    selectedValues={new Set(undefined)}
                                    labelFormatter={(term) => term.title}
                                    popover={{
                                        closeOnSelect: true,
                                    }}
                                    className="w-[200px] h-9 focus-within:outline border outline-2 outline-blue-500 text-sm"
                                    searchInputClassName="h-9"
                                >
                                    {(props) => <div>{props.option.value.title}</div>}
                                </SearchableList>
                                <Button
                                    onClick={() => setShowPreview(!showPreview)}
                                    variant={showPreview ? "default" : "outline"}
                                    title={`${showPreview ? "Hide" : "Show"} preview`}
                                >
                                    <ScanEye className="h-4 w-4" />
                                </Button>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            disabled={!permissions.edit_documents}
                                        >
                                            <Settings2 height={16} />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-88" align="end">
                                        <div className="grid gap-4">
                                            <div className="space-y-2">
                                                <h4 className="font-medium leading-none">
                                                    Options
                                                </h4>
                                                <p className="text-sm text-muted-foreground">
                                                    Update the rider settings for this Recap/CP
                                                </p>
                                            </div>
                                            <div className="grid gap-2">
                                                <div className="grid grid-cols-3 items-center gap-4">
                                                    <Label htmlFor="header-title">Heading</Label>
                                                    <Input
                                                        id="header-title"
                                                        defaultValue={riderState.header}
                                                        className="col-span-2 h-8"
                                                        type="text"
                                                        onChange={(event) =>
                                                            setHeading(event.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <Separator />
                                            <div className="grid gap-2">
                                                <div className="flex items-center space-x-2">
                                                    <Switch
                                                        id="term-numbering"
                                                        checked={riderState.clauseNumbering}
                                                        onCheckedChange={handleRiderNumberingClick}
                                                    />
                                                    <Label htmlFor="term-numbering">
                                                        {riderState.clauseNumbering
                                                            ? "Disable"
                                                            : "Enable"}{" "}
                                                        term numbering
                                                    </Label>
                                                </div>
                                                {riderState.clauseNumbering ? (
                                                    <div className="grid grid-cols-3 items-center gap-2">
                                                        <Label htmlFor="start-number">
                                                            Starting number
                                                        </Label>
                                                        <Input
                                                            id="start-number"
                                                            defaultValue={
                                                                riderState.clauseNumberStart ?? 0
                                                            }
                                                            className="col-span-2 h-8"
                                                            pattern="[0-9]*"
                                                            type="number"
                                                            min={1}
                                                            onChange={(event) =>
                                                                setClauseNumber(
                                                                    Number(event.target.value),
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </PopoverContent>
                                </Popover>
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() => resetView()}
                                    disabled={!permissions.edit_documents}
                                >
                                    <Plus height={16} className={"mr-2"} />
                                    New
                                </Button>
                                <Button
                                    type="button"
                                    variant="default"
                                    onClick={() => handleSave(true)}
                                    disabled={!permissions.edit_documents}
                                >
                                    {riderState.saving ? (
                                        <Save height={16} className={"mr-2"} />
                                    ) : (
                                        <LoaderCircle height={16} className={"mr-2 animate-spin"} />
                                    )}
                                    Save
                                </Button>
                            </div>
                        </div>
                        <div
                            className={`editor-grid ${showPreview ? "editor-grid-preview" : ""} mt-[3rem]`}
                        >
                            <Panel className="sticky top-44 mb-0 mt-[1.1rem] flex flex-col gap-4 shrink-0">
                                <Typography text="Table of contents" style="h4" />
                                <div className={"flex flex-col"}>
                                    <VerticalDragAndDrop
                                        onDragEnd={handleTermReorder}
                                        items={riderState.terms}
                                        indexPropertyToUpdate="number"
                                        listElement={(term, index) => (
                                            <div
                                                key={term.id}
                                                className={
                                                    "flex justify-between border-b last:border-b-0 break-words"
                                                }
                                            >
                                                <a
                                                    className={`py-2 truncate grow ${!term.is_visible ? "text-slate-500" : ""} `}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        riderDispatch({
                                                            type: "SET_ACTIVE_RIDER_TERM",
                                                            payload: term,
                                                        });
                                                    }}
                                                    href={`#section-${term.id}`}
                                                >
                                                    {riderState.clauseNumbering
                                                        ? `(${riderState.clauseNumberStart + index}) - `
                                                        : null}
                                                    {term.title}
                                                </a>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger asChild>
                                                        <Button
                                                            className={"ml-2 shrink-0"}
                                                            variant="ghost"
                                                            size="icon"
                                                        >
                                                            <MoreVertical className="fill-neutral-800 h-[1.2rem] w-[1.2rem]" />
                                                        </Button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <DropdownMenuLabel>
                                                            Actions
                                                        </DropdownMenuLabel>
                                                        <DropdownMenuSeparator />
                                                        <DropdownMenuGroup>
                                                            <DropdownMenuItem
                                                                onClick={(event) =>
                                                                    toggleRiderTermVisibility(
                                                                        event,
                                                                        term,
                                                                    )
                                                                }
                                                            >
                                                                {term.is_visible ? "Hide" : "Show"}
                                                            </DropdownMenuItem>
                                                            <DropdownMenuItem
                                                                onClick={(event) =>
                                                                    handleRemoveRiderTerm(
                                                                        event,
                                                                        term,
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </DropdownMenuItem>
                                                        </DropdownMenuGroup>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>
                                        )}
                                    />
                                    {!riderState.terms.length && (
                                        <span className="text-center">No terms yet</span>
                                    )}
                                </div>
                            </Panel>
                            <div
                                className={`mt-[1rem] z-10 sticky top-44 shrink-0 ${showPreview ? "hidden 2xl:block" : "block"} `}
                            >
                                <Typography
                                    text={
                                        !riderState.activeRiderTerm
                                            ? "New term"
                                            : `Edit ${riderState.activeRiderTerm.title}`
                                    }
                                    className="pb-1 z-10 sticky bg-background top-28"
                                    style="h3"
                                />
                                <RiderTermEditor ref={riderTermsEditorRef} />
                            </div>
                            <div
                                className={`sticky top-44 mt-[1rem] ${showPreview ? "flex flex-1" : "hidden"}`}
                                style={{ fontFamily: "Lora, serif" }}
                            >
                                <RidersPreview />
                            </div>
                        </div>
                    </div>
                }
            />
        </Fragment>
    );
}

export default CaseFileRiders;
