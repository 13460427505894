import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import { RtkExtraConfig } from "../lib/utils";
import { getCookie } from "./shared";

const baseQuery = fetchBaseQuery({
    baseUrl: "/admin/api/v1",
    prepareHeaders: (headers) => {
        const csrfToken = decodeURIComponent(getCookie("XSRF-TOKEN") ?? "");
        headers.append("X-XSRF-TOKEN", csrfToken);
    },
});

const baseQueryWithRetry = retry(baseQuery, RtkExtraConfig);

export const centralApi = createApi({
    reducerPath: "central_public",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["RecapTemplate"],
    endpoints: () => ({}),
});
