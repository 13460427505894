import { Dialog, DialogContent, DialogFooter, DialogTrigger } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import React, { useState } from "react";
import { useUpdateBillingInfoMutation } from "../../api/tenant/billing";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { BillingInfo } from "../../types/billing";
import Loading from "../../components/global/loading";

function UpdateBillingInfo({ billingInfo }: { billingInfo?: BillingInfo }) {
    const [updateBillingInfo] = useUpdateBillingInfoMutation();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const schema = z.object({
        company_name: z.string(),
        street: z.string(),
        housenumber: z.string(),
        zipcode: z.string(),
        city: z.string(),
        country: z.string(),
        vat_number: z.string(),
    });

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            company_name: billingInfo?.company_name ?? undefined,
            street: billingInfo?.street ?? undefined,
            housenumber: billingInfo?.housenumber ?? undefined,
            zipcode: billingInfo?.zipcode ?? undefined,
            city: billingInfo?.city ?? undefined,
            country: billingInfo?.country ?? undefined,
            vat_number: billingInfo?.vat_number ?? undefined,
        },
    });

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        await updateBillingInfo(values);
        setOpen(false);
        setLoading(false);
    }

    return (
        <Dialog open={open} onOpenChange={() => setOpen(!open)}>
            <DialogTrigger asChild>
                <Button>Update</Button>
            </DialogTrigger>
            <DialogContent>
                <Loading
                    loaded={!loading}
                    child={
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(handleSubmit)}>
                                <FormField
                                    control={form.control}
                                    name="company_name"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                Company name{" "}
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="street"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                Street{" "}
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="housenumber"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                House number{" "}
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="zipcode"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                Zipcode{" "}
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="city"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                City{" "}
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="country"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                Country{" "}
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="vat_number"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>
                                                VAT-No{" "}
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                {form.formState.isValid && (
                                    <DialogFooter className="mt-4">
                                        <Button type="submit">Update</Button>
                                    </DialogFooter>
                                )}
                            </form>
                        </Form>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

export default UpdateBillingInfo;
