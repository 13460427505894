import { tenantApi } from "../tenantApi";

export interface RecapFieldSavedValueInterface {
    value: string;
    field_id: string;
    user_id: string;
    id: string;
}

const api = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getRecapFieldSavedValues: build.query<RecapFieldSavedValueInterface[], string>({
            query: (id) => ({
                url: `recaps/field_saved_values/${id}`,
            }),
            transformResponse: (response: {
                data: RecapFieldSavedValueInterface[];
            }): RecapFieldSavedValueInterface[] => {
                return response.data;
            },
            providesTags: ["RecapFieldSavedValue"],
        }),
        updateRecapFieldSavedValue: build.mutation<
            RecapFieldSavedValueInterface,
            Partial<RecapFieldSavedValueInterface> & Pick<RecapFieldSavedValueInterface, "id">
        >({
            query: (body: { id: string; value: string }) => ({
                url: `recaps/field_saved_values/${body.id}`,
                method: "POST",
                body: {
                    value: body.value,
                },
            }),
            invalidatesTags: ["RecapFieldSavedValue"],
        }),
        deleteRecapFieldSavedValue: build.mutation<
            RecapFieldSavedValueInterface,
            Partial<RecapFieldSavedValueInterface> & Pick<RecapFieldSavedValueInterface, "id">
        >({
            query: (body: { id: string; value: string }) => ({
                url: `recaps/field_saved_values/${body.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["RecapFieldSavedValue"],
        }),
    }),
});

export const {
    useGetRecapFieldSavedValuesQuery,
    useLazyGetRecapFieldSavedValuesQuery,
    useUpdateRecapFieldSavedValueMutation,
    useDeleteRecapFieldSavedValueMutation,
} = api;
