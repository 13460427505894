import React, { useState } from "react";
import { HouseForm } from "../../types/house-forms";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns, tableDropDown } from "../../config/house-forms";
import { DataTableToolbar } from "../../components/data-table/data-table-toolbar";
import DataTable from "../../components/data-table/data-table";
import { Tables } from "../../config/app";
import { useNavigate } from "react-router";

function HouseFormsTable({ data }: { data: Array<HouseForm> }) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            meta: {
                tableId: Tables.HOUSE_FORMS,
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    const navigate = useNavigate();

    return (
        <div className="flex flex-col h-full">
            <DataTableToolbar table={table} searchableColumns={[]} />
            <div className="flex py-4 h-full">
                <DataTable
                    columns={columns}
                    table={table}
                    onClick={(row) => navigate(`/templates/${row.id}`)}
                    contextMenuItems={tableDropDown}
                />
            </div>
        </div>
    );
}

export default HouseFormsTable;
