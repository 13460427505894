import { checkAdditionSpan, checkDeletionSpan, isLineNumber } from "./inspectDOM";
import { getFroalaSelectionDetails, moveToNextNode } from "./base";

/**
 *
 * @param {Selection} selection - The current selection object within the Froala editor.
 * @returns {Node | null} The last span element that was created during the operation,
 *                        or null if no valid operations were performed due to invalid selection details.
 */
export function handleReinstating(selection: Selection): {
    lastNode: Node | null;
    startOffset: number;
} {
    let currentNode = null;
    let lastSpan = null; // To store the last span created

    const selectionDetails = getFroalaSelectionDetails(selection);
    if (!selectionDetails) {
        return { lastNode: null, startOffset: 0 };
    }

    const { startNode, endNode, startOffset, endOffset, gca } = selectionDetails;

    // Setup a TreeWalker to iterate through the nodes within the user selection
    const treeWalker = document.createTreeWalker(
        gca,
        NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT, // Show both text nodes and elements
        {
            acceptNode: function (node) {
                if (
                    node.parentNode instanceof Element &&
                    node.parentNode.classList.contains("fr-marker") // Skip Froala markers
                ) {
                    return NodeFilter.FILTER_SKIP;
                }
                return NodeFilter.FILTER_ACCEPT;
            },
        },
    );

    currentNode = startNode;
    treeWalker.currentNode = startNode;
    const nodesToReinstate = [];

    // Walk through the selected nodes and store the text nodes to wrap
    while (currentNode) {
        const isStartNode = currentNode === startNode;
        const isEndNode = currentNode === endNode;

        // Determine offsets for each node
        let realStartOffset;
        let realEndOffset;
        if (currentNode.textContent) {
            realStartOffset = isStartNode ? startOffset : 0;
            realEndOffset = isEndNode ? endOffset : currentNode.textContent.length;
        } else {
            realStartOffset = 0;
            realEndOffset = 0;
        }
        // Adjust for selection within a single node
        if (startNode === endNode) {
            realEndOffset = endOffset;
        }

        // If we encounter a text element, store it for wrapping in a span
        if (currentNode.nodeType === Node.TEXT_NODE) {
            // Do not remove text nodes that are children of an addition span or line number nodes
            const checkAddition = checkAdditionSpan(currentNode);
            if (checkAddition || isLineNumber(currentNode)) {
                // Skip to next loop iteration
                currentNode = moveToNextNode(treeWalker, isEndNode);
                continue;
            }

            // Store deletion spans for reinstatements
            const checkDeletion = checkDeletionSpan(currentNode);
            if (checkDeletion) {
                nodesToReinstate.push({
                    node: checkDeletion,
                    startOffset: realStartOffset,
                    endOffset: realEndOffset,
                });
                // Skip to next loop iteration
                currentNode = moveToNextNode(treeWalker, isEndNode);
                continue;
            }
        }

        currentNode = moveToNextNode(treeWalker, isEndNode);
    }

    // Reinstate the deletion spans
    for (const { node, startOffset, endOffset } of nodesToReinstate) {
        const span = reinstateNode(node, startOffset, endOffset);
        if (span) {
            lastSpan = span;
        }

        // TODO: what is lastSpan if the final span is reinstated?
    }

    // Return lastSpan, or endNode if no spans were created
    return { lastNode: lastSpan || endNode, startOffset: startOffset };
}

/**
 * @param {Node} node - The node from which text will be removed. This should ideally be an element node
 *                      that contains text, such as a text node within a span.
 * @param {number} startOffset - The starting index within the node's text content where removal begins.
 * @param {number} endOffset - The ending index within the node's text content where removal ends.
 * @returns {Node | null} The modified node with text removed, or null if the entire node was removed
 *                        or if the node does not meet the conditions for modification.
 */
function reinstateNode(node: Node, startOffset: number, endOffset: number): Node | null {
    if (!node || !node.parentNode || !node.textContent) {
        return null;
    }

    // Assuming the node is an Element and has text content
    const textContent = node.textContent;
    const parentNode = node.parentNode;

    // Create a fragment to hold the new nodes
    const fragment = document.createDocumentFragment();

    // Text before the selection
    if (startOffset > 0) {
        const beforeNode = node.cloneNode() as Element;
        beforeNode.textContent = textContent.substring(0, startOffset);
        fragment.appendChild(beforeNode);
    }

    // Text within the selection
    const selectedText = document.createTextNode(textContent.substring(startOffset, endOffset));
    fragment.appendChild(selectedText);

    // Text after the selection
    if (endOffset < textContent.length) {
        const afterNode = node.cloneNode() as Element;
        afterNode.textContent = textContent.substring(endOffset);
        fragment.appendChild(afterNode);
    }

    // Replace the original node with the fragment
    parentNode.replaceChild(fragment, node);

    return fragment;
}
