import React, { Fragment } from "react";
import {
    DocumentEditorProvider,
    useDocumentEditor,
} from "../../../lib/providers/DocumentEditorProvider";
import { useParams } from "react-router";
import Loading from "../../../components/global/loading";
import Collaborators from "./collaborators";
import Editor from "../../../components/editor";
import { GlobalDocumentInterface } from "../../../types/document-editor";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import SidePanel from "./side-panel";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import FinalizeDocument from "../detail/finalize-document";
import ExportDocument from "../detail/export-document";
import RequestSignatures from "../docusign/request-signatures";
import CancelSignatures from "../docusign/cancel-signatures";
import SigningStatus from "../docusign/signing-status";
import { usePageTitle } from "../../../lib/usePageTitle";
import DocumentActions from "./document-actions";
import { Typography } from "../../../components/ui/typography";
import { Badge } from "../../../components/ui/badge";

function DocumentEditor() {
    const { document_id } = useParams<{ id: string; document_id: string }>();

    return (
        <Loading
            loaded={document_id !== undefined}
            child={
                <Fragment>
                    {document_id && (
                        <DocumentEditorProvider document_id={document_id}>
                            <DocumentEditorComp />
                        </DocumentEditorProvider>
                    )}
                </Fragment>
            }
        />
    );
}

function DocumentEditorComp() {
    const {
            document,
            froalaConfig,
            froalaConfigFinal,
            isUserActive,
            document_id,
            froalaRef,
            handleChangeMade,
            setEditorObject,
            selectedAction,
            setSelectedAction,
            handleClickInEditor,
        } = useDocumentEditor(),
        { case_file_id, meta_data } = useCaseFiles();

    usePageTitle(
        document ? `CP: ${meta_data?.reference} - ${document.document_name}` : "Undefined",
    );

    return (
        <Fragment>
            <div className={"fixed left-12 bg-background h-16 z-10 top-28 right-0"}>
                <div className={"flex gap-4 pt-4 px-4 justify-end"}>
                    <div className={"flex flex-grow gap-4 items-center"}>
                        {document ? (
                            <Fragment>
                                <Typography
                                    className="pb-0"
                                    text={document.document_name ?? "Undefined"}
                                    style="h2"
                                />
                                <Badge variant={document.finalized ? "primary" : "pending"}>
                                    {document.finalized ? "Finalized" : "Draft"}
                                </Badge>
                            </Fragment>
                        ) : (
                            ""
                        )}
                    </div>
                    <Collaborators />
                    <DocumentActions />
                </div>
            </div>
            <div className="flex p-4 sticky top-40 mt-12 pb-0 gap-x-4 h-[calc(100vh-13rem)]">
                <div className={"flex flex-col grow self-stretch"}>
                    {document && (
                        <div className={document.template?.multiple_pages ? "multiple-pages" : ""}>
                            <style>{document.template?.stylesheet}</style>
                            <Editor<GlobalDocumentInterface>
                                document={document}
                                document_id={document_id}
                                content={document.content}
                                case_file_id={case_file_id}
                                formatting={document.formatting ?? true}
                                froalaConfig={froalaConfig}
                                isUserActive={isUserActive()}
                                froalaConfigFinal={froalaConfigFinal}
                                froalaRef={froalaRef}
                                handleChangeMade={() => handleChangeMade(true)}
                                emitEditorObject={(editor) => setEditorObject(editor)}
                                finalized={document.finalized}
                                handleClickInEditor={handleClickInEditor}
                            />
                        </div>
                    )}
                </div>
                <SidePanel />
            </div>
            {document && selectedAction && (
                <Dialog onOpenChange={() => setSelectedAction(undefined)} open={!!selectedAction}>
                    <DialogContent>
                        {selectedAction === "finalize" && (
                            <FinalizeDocument
                                row={document}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                        {selectedAction === "export" && (
                            <ExportDocument
                                row={document}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                        {selectedAction === "request-signatures" && (
                            <RequestSignatures
                                row={document}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                        {selectedAction === "cancel-signing" && (
                            <CancelSignatures
                                row={document}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                        {selectedAction === "signing-status" && (
                            <SigningStatus
                                row={document}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </Fragment>
    );
}

export default DocumentEditor;
