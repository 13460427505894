import styled from "styled-components";
import { borderThin, color, TitleText } from "../../styles/mixins";
import { Input } from "../../Form/FormField/style";

export const FolderListTitle = styled.h1`
    font-size: 1.25em;
    font-weight: 600;
    padding: 1em 0;
    color: ${color("dark")};
`;

export const FolderListItem = styled.li`
    margin: 0.75em 0 0.75em 1em;
`;

export const FolderListItemTitle = styled.p`
    border-bottom: ${borderThin("darker", 0.3)};
    padding-bottom: 0.5em;
`;

export const RecapCategoryTitle = styled.h1`
    ${TitleText};
    line-height: 2.5em;
`;

export const NewRiderTemplateName = styled(Input)`
    width: 400px;
`;
