import { ColumnDef } from "@tanstack/react-table";
import {
    CheckCheck,
    Copy,
    Eye,
    FileDown,
    MoreHorizontal,
    Pencil,
    Share2,
    TextSelect,
    Trash2,
    Zap,
} from "lucide-react";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import { Badge } from "../components/ui/badge";
import { format } from "date-fns";
import { useCaseFiles } from "../lib/providers/CaseFilesProvider";
import { DropdownMenu, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import DataTableAdvancedDropDown from "../components/data-table/data-table-advanced-drop-down";
import React from "react";
import { TableDropDownItems } from "../types/table";
import FinalizeDocument from "../containers/case-files/detail/finalize-document";
import UnFinalizeDocument from "../containers/case-files/detail/un-finalize-document";
import DuplicateDocument from "../containers/case-files/detail/duplicate-document";
import EditDocument from "../containers/case-files/detail/edit-document";
import ExportDocument from "../containers/case-files/detail/export-document";
import DeleteDocument from "../containers/case-files/detail/delete-document";
import { CaseFileDocumentIndex, CaseFilePermissionsInterface } from "../types/case-files";
import AttachmentsPreview from "../containers/case-files/attachements/attachments-preview";
import { formatDocumentType } from "../lib/utils";
import AddAsHouseForm from "../containers/case-files/detail/add-as-house-form";
import CreateProForma from "../containers/case-files/detail/create-pro-forma";
import { mapDocumentIndexToUploadedFile } from "../containers/case-files/detail/documents-table";

const columns: ColumnDef<CaseFileDocumentIndex>[] = [
    {
        accessorKey: "document_name",
        header: "Name",
        cell: ({ row }) => {
            return (
                <div className="flex items-center w-full overflow-hidden text-ellipsis">
                    {row.original.is_smart ? (
                        <Zap
                            className={"h-4 w-4 min-w-[16px] stroke-transparent fill-blue-400 mr-4"}
                        />
                    ) : null}
                    <span className="w-full min-w-0 overflow-hidden text-ellipsis">
                        {row.getValue("document_name")}
                    </span>
                </div>
            );
        },
        meta: {
            title: "Document name",
        },
    },
    {
        accessorKey: "template_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Template" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Template",
        },
    },
    {
        accessorKey: "template_type",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Type"
                    type="filter"
                    valueFormatter={formatDocumentType}
                />
            );
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Template type",
        },
        cell: ({ row }) => {
            return (
                <span>
                    {formatDocumentType(row.getValue("template_type"), row.original.file_type)}
                </span>
            );
        },
    },
    {
        accessorKey: "user",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Author" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "User",
        },
    },
    {
        accessorKey: "finalized",
        header: "Status",
        cell: ({ row }) => {
            const type = row.getValue("template_type") as string | undefined;

            if (!type || ["file_upload", "q88"].includes(type)) {
                return;
            }

            const finalized = row.getValue("finalized");

            return (
                <span>
                    <Badge variant={finalized ? "primary" : "pending"}>
                        {finalized ? "Finalized" : "Draft"}
                    </Badge>
                </span>
            );
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Status",
        },
    },
    {
        accessorKey: "updated_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Last modified (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        cell: ({ row }) => {
            return (
                <span>{format(new Date(row.getValue("updated_at")), "dd-MM-yyyy HH:mm:ss")}</span>
            );
        },
        meta: {
            title: "Last modified (CEST)",
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const { permissions } = useCaseFiles();

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DataTableAdvancedDropDown<CaseFileDocumentIndex>
                        row={row.original}
                        type="dropdown"
                        items={tableDropDown(permissions)}
                    />
                </DropdownMenu>
            );
        },
    },
];

function tableDropDown(
    permissions: CaseFilePermissionsInterface,
): TableDropDownItems<CaseFileDocumentIndex> {
    return {
        label: "Actions",
        groups: [
            {
                actions: [
                    {
                        label: "Finalize",
                        icon: <CheckCheck className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <FinalizeDocument row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) =>
                            Boolean(row.finalized) ||
                            !permissions.finalize_documents ||
                            row.template_type === "file_upload" ||
                            row.template_type === "q88",
                    },
                    {
                        label: "Un-finalize",
                        icon: <CheckCheck className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => (
                            <UnFinalizeDocument row={row} onClose={onClose} />
                        ),
                        elementType: "dialog",
                        hide: (row) =>
                            !row.finalized ||
                            !permissions.finalize_documents ||
                            row.template_type === "file_upload" ||
                            row.template_type === "q88",
                    },
                    {
                        label: "Duplicate",
                        icon: <Copy className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => (
                            <DuplicateDocument row={row} onClose={onClose} />
                        ),
                        elementType: "dialog",
                        hide: (row) =>
                            !permissions.duplicate_documents ||
                            row.template_type === "file_upload" ||
                            row.template_type === "q88" ||
                            row.template_type === "recap",
                    },
                    {
                        label: "Change name",
                        icon: <Pencil className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <EditDocument row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) =>
                            Boolean(row.finalized) ||
                            !permissions.edit_documents ||
                            row.template_type === "file_upload" ||
                            row.template_type === "q88",
                    },
                    {
                        label: "Export",
                        icon: <FileDown className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <ExportDocument row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) =>
                            true ||
                            row.template_type === "file_upload" ||
                            row.template_type === "q88",
                    },
                    {
                        label: "Preview",
                        icon: <Eye className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => {
                            return (
                                <AttachmentsPreview
                                    row={mapDocumentIndexToUploadedFile(row)}
                                    onClose={onClose}
                                />
                            );
                        },
                        elementType: "dialog",
                        hide: (row) => !["file_upload", "q88"].includes(row.template_type),
                        customSize: "max-w-[90vw] sm:max-w-[90vw]",
                    },
                ],
            },
            {
                actions: [
                    {
                        label: "Create pro-forma",
                        icon: <Share2 className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <CreateProForma row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) =>
                            ["recap", "file_upload", "q88"].includes(row.template_type) ||
                            !permissions.generate_pro_forma_code,
                    },
                    {
                        label: "Add as template",
                        icon: <TextSelect className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <AddAsHouseForm row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) =>
                            !["standard_form", "bill_of_lading"].includes(row.template_type),
                    },
                ],
            },
            {
                actions: [
                    {
                        label: "Delete",
                        icon: <Trash2 className="mr-2 h-4 w-4 stroke-white" />,
                        dangerous: true,
                        element: (row, onClose) => <DeleteDocument row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) =>
                            Boolean(row.finalized) ||
                            !permissions.edit_documents ||
                            row.template_type === "recap",
                    },
                ],
            },
        ],
    };
}

export { columns, tableDropDown };
