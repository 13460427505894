export function determineTenantName() {
    // check if tenant name is admin or register
    let name: string | undefined = window.location.pathname.split("/")[1];

    if (
        name === "admin" ||
        name === "submarine" ||
        name === "register" ||
        name === "verify-email" ||
        name === "dsredirect"
    ) {
        name = undefined;
    }

    return name;
}
