import { useParams } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import React from "react";

function SigningDeclined() {
    const { document_name } = useParams<{ document_name?: string }>();

    return (
        <Dialog open>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>You have declined to sign {document_name}</DialogTitle>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
}

export default SigningDeclined;
