import { tenantApi } from "../tenantApi";

export interface RecapTemplateInterface {
    id: string;
    data_count: number;
    author: string;
    created_at: Date;
    updated_at: Date;
    name: string;
    categories: Array<{
        id: string;
        name: string;
    }>;
}

const recapTemplates = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getRecapTemplates: build.query<RecapTemplateInterface[], void>({
            query: () => ({
                url: "/recaps/templates",
            }),
            transformResponse: (response: {
                data: RecapTemplateInterface[];
            }): RecapTemplateInterface[] => {
                return response.data;
            },
            providesTags: (result: Array<RecapTemplateInterface>) => [
                ...(result
                    ? result.map(({ id }) => ({ type: "RecapTemplates", id }) as const)
                    : []),
                { type: "RecapTemplates" as const, id: "LIST" },
            ],
        }),
    }),
});

export const { useLazyGetRecapTemplatesQuery } = recapTemplates;
