import React, { useState } from "react";
import { Typography } from "../../components/ui/typography";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateAccountMutation } from "../../api/tenant/organisation";
import { Switch } from "../../components/ui/switch";
import { usePageTitle } from "../../lib/usePageTitle";
import { determineTenantName } from "../../lib/helpers/tenant";

function CreateAccount() {
    const schema = z
        .object({
            username: z.string().min(2, {
                message: "Username must be at least 2 characters.",
            }),
            password: z.string().min(2, {
                message: "Password must be at least 2 characters.",
            }),
            confirm_password: z.string().min(2, {
                message: "Password must be at least 2 characters.",
            }),
            eula: z.boolean(),
        })
        .refine((data) => data.password === data.confirm_password, {
            message: "Passwords don't match",
            path: ["confirm_password"],
        })
        .refine((data) => data.eula, {
            message: "Agreement of Terms must be accepted to join.",
            path: ["eula"],
        });

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            username: "",
            password: "",
            confirm_password: "",
            eula: false,
        },
    });

    const [errorMessage, setErrorMessage] = useState<string>();

    const [createAccount] = useCreateAccountMutation();

    const navigate = useNavigate();

    const { id } = useParams<{ id: string }>();

    usePageTitle("Create Account");

    async function handleSubmit(values: z.infer<typeof schema>) {
        if (id) {
            try {
                await createAccount({
                    id: id,
                    ...values,
                }).unwrap();
                navigate(`/${determineTenantName()}/login`);
            } catch (e) {
                form.reset();
                setErrorMessage(e.data.message);
            }
        }
    }

    return (
        <div className="bg-login h-screen bg-cover bg-center bg-no-repeat">
            <div className="h-screen w-screen bg-zinc-900 bg-opacity-45 flex align items-center justify-center">
                <div className="w-[520px] bg-white p-8 flex flex-col">
                    <img
                        src="/images/logo.png"
                        alt="Ocean Recap"
                        className="h-24 mt-8 mb-8 self-center"
                    />
                    <div className="flex align items-center justify-cente">
                        <Typography text={"Create account"} style="h3" />
                    </div>
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(handleSubmit)}
                            className="space-t-8 space-b-4"
                        >
                            <FormField
                                control={form.control}
                                name="username"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Username</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Password</FormLabel>
                                        <FormControl>
                                            <Input type="password" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="confirm_password"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Confirm Password</FormLabel>
                                        <FormControl>
                                            <Input type="password" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="eula"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <div className="flex items-center">
                                            <FormControl>
                                                <Switch
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                            <FormDescription className="ml-2">
                                                I agree with the{" "}
                                                <a
                                                    href="/pdf/EULA.pdf"
                                                    target="_blank"
                                                    className="py-2 underline"
                                                >
                                                    Agreement of Use
                                                </a>
                                            </FormDescription>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {errorMessage && <Typography text={errorMessage} style="warning" />}

                            <div className="flex flex-col">
                                <Button type="submit" className="mt-8">
                                    Join
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default CreateAccount;
