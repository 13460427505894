import React, { Fragment } from "react";
import { Field } from "formik";

export function FilteredPropsInputField(props: any) {
    const filteredProps = {
        ...props,
        className: props?.className ?? "",
        id: props?.name ?? "",
        valid: props.valid ? 1 : 0,
        error: props.valid ? 1 : 0,
    };
    return <Field {...filteredProps} />;
}

/**
 * A component for rendering a styled date time picker
 * @constructor
 */
//TODO typehint
export function DatePickerField() {
    // const {setFieldValue} = useFormikContext();
    // // @ts-ignore
    // const [field] = useField(props);
    // const [open, setOpen] = useState(!props.toggle);

    return (
        <Fragment>
            {/*<DatePicker*/}
            {/*    {...field}*/}
            {/*    {...props}*/}
            {/*    selected={(field.value && new Date(field.value)) || null}*/}
            {/*    onChange={val => {*/}
            {/*        setFieldValue(field.name, val)*/}
            {/*    }}*/}
            {/*    dateFormat='dd/MM/yyyy'*/}
            {/*    open={open}*/}
            {/*    onClickOutside={() => {*/}
            {/*        if (props.toggle) setOpen(false)*/}
            {/*    }}*/}
            {/*    onInputClick={() => setOpen(true)}*/}
            {/*    onFocus={() => setOpen(true)}*/}
            {/*    autoComplete="off"*/}
            {/*    onSelect={() => {*/}
            {/*        if (props.hover) setOpen(false)*/}
            {/*        if (typeof props.onSelect !== 'undefined') props.onSelect()*/}
            {/*    }}*/}
            {/*    readOnly={true}*/}
            {/*    onBlur={props.onBlur}*/}
            {/*/>*/}
            {/*<DatePickerWrapperStyles {...props} />*/}
        </Fragment>
    );
}
