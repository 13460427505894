import React, { Fragment, useEffect, useRef, useState } from "react";
import { Typography } from "../../components/ui/typography";
import { Badge } from "../../components/ui/badge";
import { useLazyGetHouseFormQuery, useSaveHouseFormMutation } from "../../api/tenant/house-forms";
import { useParams } from "react-router";
import Loading from "../../components/global/loading";
import { formatDocumentType } from "../../lib/utils";
import { DocumentLoadType } from "../../types/case-files";
import Editor from "../../components/editor";
import { HouseForm } from "../../types/house-forms";
import { froalaConfigFinal, froalaConfigMain } from "../../lib/providers/DocumentEditorProvider";
import { SearchObject } from "../../components/global/search-object";
import { RecapSingleFieldInterface } from "../../types/document-editor";
import { toast } from "sonner";
import { Separator } from "../../components/ui/separator";
import { ScrollArea } from "../../components/ui/scroll-area";
import { SmartFieldTextArea } from "../case-files/document-editor/smart-fields";
import { Panel } from "../../components/ui/panel";
import { DataAutomation } from "../../plugins/DataAutomation";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../components/ui/tooltip";
import { Button } from "../../components/ui/button";
import useInterval from "../../lib/useInterval";
import { AUTO_SAVE_TIMER } from "../../config/document-editor";
import { Save } from "lucide-react";

export default function HouseFormEditor() {
    const { id } = useParams<{ id: string }>();
    const froalaRef = useRef<any>(null);
    const [getHouseForm, { isLoading, data: document }] = useLazyGetHouseFormQuery();
    const [smartFields, setSmartFields] = useState<Array<RecapSingleFieldInterface>>();
    const searchRef = useRef<HTMLInputElement | null>(null);
    const dataAutomation = DataAutomation.getInstance();
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [editor, setEditor] = useState<any>();
    const [saveHouseForm] = useSaveHouseFormMutation();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { secondsRemaining: autoSaveTimer } = useInterval<null, void>(
        {
            fn: () => handleSave(),
        },
        null,
        AUTO_SAVE_TIMER,
    );

    useEffect(() => {
        if (id) {
            init(id);
        }
    }, [id]);

    async function init(id: string) {
        try {
            const houseForm = await getHouseForm(id).unwrap();
            setSmartFields(houseForm.smart_fields ?? []);
        } catch (e) {
            toast.error("Something went wrong while fetching template");
        }
    }

    function handleSmartFieldChanged(value: string, field: RecapSingleFieldInterface) {
        updateSmartField({
            ...field,
            value: value,
            status: "done",
        });

        dataAutomation.updateRecapField(field, value ?? "");
    }

    function removeSmartField(field: RecapSingleFieldInterface, empty: boolean = false) {
        if (!empty) {
            updateSmartField({
                ...field,
                status: "todo",
            });
        } else {
            updateSmartField({
                ...field,
                value: undefined,
                status: "todo",
            });
        }

        dataAutomation.updateRecapField(field, "");
    }

    function updateSmartField(field: RecapSingleFieldInterface) {
        if (smartFields) {
            const index = smartFields.findIndex((x) => x.id === field.id);

            setSmartFields([
                ...smartFields.slice(0, index),
                field,
                ...smartFields.slice(index + 1),
            ]);
        }
    }

    async function handleSave(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (editor && id && !isSaving && (hasChanges || e)) {
            setIsSaving(true);

            try {
                editor.selection.save();
                const content = editor.html.get(true);
                await saveHouseForm({
                    id: id,
                    content: content,
                    smart_fields: smartFields ?? document?.smart_fields,
                }).unwrap();

                setHasChanges(false);
                if (e) {
                    toast.success("Saved the template");
                }
            } catch (e) {
                toast.error("Something went wrong while saving template");
            }
            setIsSaving(false);
        }
    }

    return (
        <Loading
            loaded={!isLoading}
            child={
                <Fragment>
                    {document && (
                        <Fragment>
                            <div className={"fixed left-12 bg-background h-16 z-10 top-12 right-0"}>
                                <div className={"flex gap-4 pt-4 px-4 justify-end"}>
                                    <div className={"flex flex-grow gap-4 items-center"}>
                                        <Fragment>
                                            <Typography
                                                className="pb-0"
                                                text={document.name ?? "Undefined"}
                                                style="h2"
                                            />

                                            <Badge variant="pending">
                                                {formatDocumentType(
                                                    document.template_type as DocumentLoadType,
                                                )}
                                            </Badge>
                                        </Fragment>
                                    </div>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="default"
                                                onClick={(e) => handleSave(e)}
                                            >
                                                <Save className="h-4 w-4 mr-2" /> Save
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent
                                            side={"bottom"}
                                            className={"z-30"}
                                            align="center"
                                        >
                                            Auto saving in {autoSaveTimer} seconds
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex p-4 sticky top-40 mt-12 pb-0 gap-x-4 h-[calc(100vh-7rem)]">
                                <div className={"flex flex-col grow self-stretch"}>
                                    {document && (
                                        <div>
                                            <Editor<HouseForm>
                                                document={document}
                                                document_id={document.id}
                                                content={document.content}
                                                case_file_id={""}
                                                formatting
                                                froalaConfig={froalaConfigMain}
                                                isUserActive
                                                froalaConfigFinal={froalaConfigFinal}
                                                froalaRef={froalaRef}
                                                handleChangeMade={() => setHasChanges(true)}
                                                emitEditorObject={(editor) => setEditor(editor)}
                                                finalized={false}
                                                handleClickInEditor={() => void 0}
                                                heightMax="calc(100vh - 185px)"
                                            />
                                        </div>
                                    )}
                                </div>
                                <Panel className="xl:w-[300px] grow-0 shrink-0 absolute bottom-0 w-[1px] overflow-hidden -z-10 xl:static xl:bottom-auto xl:z-auto">
                                    <div className="xl:bg-white ml-4 xl:ml-0 flex flex-1 overflow-y-auto h-full">
                                        <div className="flex flex-col w-full h-full">
                                            <div className="flex justify-between">
                                                <Typography text="Smart fields" style="h4" />
                                            </div>
                                            {smartFields && (
                                                <Fragment>
                                                    <SearchObject<RecapSingleFieldInterface>
                                                        type="text"
                                                        className="border-none shadow-none pl-0 mt-4"
                                                        items={smartFields}
                                                        ref={searchRef}
                                                        onResults={(items) => setSmartFields(items)}
                                                        keys={["name", "value", "status"]}
                                                    />
                                                    <Separator />
                                                    <ScrollArea className="max-h-[1200px] pr-4">
                                                        {smartFields.map((smart_field, index) => (
                                                            <SmartFieldTextArea
                                                                field={smart_field}
                                                                key={index}
                                                                disabled={false}
                                                                handleChange={
                                                                    handleSmartFieldChanged
                                                                }
                                                                removeValue={removeSmartField}
                                                            />
                                                        ))}
                                                    </ScrollArea>
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            }
        />
    );
}
