import React, { useState } from "react";
import { Import } from "lucide-react";
import { Typography, TypographyTypes } from "../../../../components/ui/typography";
import { WithCaseFileId } from "../../../../types";
import { RecapFieldResult, RecapInterface } from "../../../../types/recap";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { Separator } from "../../../../components/ui/separator";
import UploadAndParseContent from "./upload-parse-content";
import ImportParsedData from "./import-parsed-data";
import { useImportRecapPDFMutation, useParseRecapPDFMutation } from "../../../../api/tenant/recap";
import ParseDialogError from "./parse-dialog-error";

type ParseRecapFileProps = WithCaseFileId<{
    recap: RecapInterface;
    isUserActive: boolean;
    fetchData: (id: string) => void;
}>;

type dialogViewState = "upload" | "import" | "error";

function ParseRecapFile({ case_file_id, recap, isUserActive, fetchData }: ParseRecapFileProps) {
    const [result, setResult] = useState<RecapFieldResult[]>([]);
    const [unmatched, setUnmatched] = useState<{ [key: string]: string }>({});
    const [dialogContent, setDialogContent] = useState<dialogViewState>("upload");
    const [open, setOpen] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [parseRecapPDF, { isLoading: isParseLoading }] = useParseRecapPDFMutation();
    const [importRecapPDF] = useImportRecapPDFMutation();

    // Upload the PDF to the backend for the python script to parse it.
    const handleSubmit = () => {
        if (file && case_file_id) {
            parseRecapPDF({ file: file, case_file_id: case_file_id, id: recap.id })
                .unwrap()
                .then((payload) => {
                    handleParsed(payload.result, payload.unmatched);
                    setFile(null);
                    setError(null);
                })
                .catch(() => {
                    setDialogContent("error");
                    setError("Something went wrong while trying to parse the PDF.");
                });
        }
    };

    // Moves the user from the uploading to the selection screen.
    const handleParsed = (
        parsedResult: RecapFieldResult[],
        parsedUnmatched: { [key: string]: string },
    ) => {
        setResult(parsedResult);
        setUnmatched(parsedUnmatched);
        setDialogContent("import");
    };

    // Lets the user import the "ready for import" fields into the backend
    // and moves all unidentified fields into the "various" recap field.
    // This action overwrites all previous recap data.
    const handleImport = () => {
        importRecapPDF({
            case_file_id,
            id: recap.id,
            data: { result, unmatched },
        })
            .unwrap()
            .then(() => {
                fetchData(recap.id);
                handleDialogClose();
            })
            .catch(() => {
                setDialogContent("error");
                setError("Something went wrong while trying to import the fields.");
            });
    };

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        resetState();
        setOpen(false);
    };

    const resetState = () => {
        setResult([]);
        setUnmatched({});
        setDialogContent("upload");
        setFile(null);
        setError(null);
    };

    const handleFileChange = (selectedFile: File | null) => {
        setFile(selectedFile);
    };

    const handleErrorChange = (error: string | null) => {
        setError(error);
    };

    // Helper for generating dialog header description content and style
    const getTextAndStyle = (): { descriptionText: string; descriptionStyle: TypographyTypes } => {
        switch (dialogContent) {
            case "upload":
                return {
                    descriptionText: "This will parse the PDF and extract data fields.",
                    descriptionStyle: "small",
                };
            case "import":
                return {
                    descriptionText: "This will override all previous recap data!",
                    descriptionStyle: "warning",
                };
            case "error":
                return {
                    descriptionText: "An error occurred. Please try again.",
                    descriptionStyle: "warning",
                };
            default:
                return { descriptionText: "", descriptionStyle: "small" };
        }
    };
    const { descriptionText, descriptionStyle } = getTextAndStyle();

    return (
        <Dialog
            open={open}
            onOpenChange={(isOpen) => {
                if (!isOpen) {
                    resetState();
                }
                setOpen(isOpen);
            }}
        >
            <DialogTrigger asChild>
                <Button
                    variant="ghost"
                    className="p-0 h-auto"
                    disabled={!isUserActive}
                    onClick={handleDialogOpen}
                >
                    <Import className="mr-2 h-4 w-4" />
                    <span>Import data</span>
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {dialogContent === "upload"
                            ? "Upload and parse a recap PDF"
                            : dialogContent === "import"
                              ? "Import parsed data"
                              : dialogContent === "error"
                                ? "Oops something went wrong"
                                : ""}
                    </DialogTitle>
                    <DialogDescription>
                        <Typography text={descriptionText} style={descriptionStyle} />
                    </DialogDescription>
                </DialogHeader>

                {dialogContent === "upload" ? (
                    <UploadAndParseContent
                        file={file}
                        onFileChange={handleFileChange}
                        error={error}
                        onErrorChange={handleErrorChange}
                        isParseLoading={isParseLoading}
                    />
                ) : dialogContent === "import" ? (
                    <ImportParsedData result={result} unmatched={unmatched} />
                ) : dialogContent === "error" ? (
                    <ParseDialogError error={error} />
                ) : null}

                {file ? <Separator /> : null}

                <DialogFooter>
                    {dialogContent === "upload" ? (
                        <Button
                            onClick={handleSubmit}
                            disabled={!file || !!error || isParseLoading}
                        >
                            Parse
                        </Button>
                    ) : dialogContent === "import" ? (
                        <Button onClick={handleImport} disabled={result.length === 0}>
                            Import
                        </Button>
                    ) : dialogContent === "error" ? (
                        <Button onClick={resetState}>Back</Button>
                    ) : null}
                    <DialogClose asChild>
                        <Button variant="ghost" onClick={handleDialogClose}>
                            Close
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default ParseRecapFile;
