import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createRiderTemplate, fetchTags } from "../../Admin/Templates";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import FroalaEditor from "react-froala-wysiwyg";
import {
    NewTemplateEditorContainer,
    NewTemplateSettingsContainer,
    NewTemplateWrapper,
} from "../Templates/Style";
import {
    FolderListItem,
    FolderListItemTitle,
    FolderListTitle,
    NewRiderTemplateName,
} from "./Style";
import {
    FormatFoldersBasedOnTagsInterface,
    FormatFoldersBasedOnTagsItemsInterface,
} from "./index.interface";
import { OverviewFormButtonGroup } from "../Dashboard/Style";
import {
    Bar,
    CancelButton,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
} from "../../Form/FormField/style";
import { adminPrefix } from "../../../config/central";
import { FolderInterface, NewTemplateItem, TagDataInterface } from "../../../types/Central";

export function NewRiderTemplatePage() {
    const [tags, setTags] = useState<Array<TagDataInterface>>([]);
    const [selectedTags, setSelectedTags] = useState<Array<FolderInterface>>([]);

    useEffect(() => {
        fetchTags().then((r) => setTags(r.data));
    }, []);

    const froalaConfig = {
        key: process.env.FROALA_KEY,
        charCounterCount: false,
        documentReady: true,
        heightMin: 300,
        attribution: false,
        toolbarSticky: false,
        toolbarButtons: {
            moreText: {
                buttons: [
                    "bold",
                    "italic",
                    "underline",
                    "strikeThrough",
                    "subscript",
                    "superscript",
                    "fontFamily",
                    "fontSize",
                    "textColor",
                    "backgroundColor",
                    "inlineClass",
                    "inlineStyle",
                    "clearFormatting",
                ],
            },
            moreParagraph: {
                buttons: [
                    "alignLeft",
                    "alignCenter",
                    "formatOLSimple",
                    "alignRight",
                    "alignJustify",
                    "formatOL",
                    "formatUL",
                    "paragraphFormat",
                    "paragraphStyle",
                    "lineHeight",
                    "outdent",
                    "indent",
                    "quote",
                ],
            },
            moreRich: {
                buttons: [
                    "insertLink",
                    "insertImage",
                    "insertVideo",
                    "insertTable",
                    "emoticons",
                    "fontAwesome",
                    "specialCharacters",
                    "embedly",
                    "insertFile",
                    "insertHR",
                ],
            },
            moreMisc: {
                buttons: [
                    "undo",
                    "redo",
                    "fullscreen",
                    "print",
                    "getPDF",
                    "spellChecker",
                    "selectAll",
                    "html",
                    "help",
                ],
                align: "right",
                buttonsVisible: 2,
            },
        },
        quickInsertEnabled: false,
        events: {
            initialized: function () {
                onEditorInitialized(this);
            },
        },
    };

    const editorRef = useRef<any>(null);

    const navigate = useNavigate();

    const onEditorInitialized = (editor: any) => {
        editorRef.current = editor;
    };

    const initialValues: NewTemplateItem = {
        template_type: "rider",
        name: "",
        content: "",
        description: "",
        footer: "",
        stylesheet: "",
        multiple_pages: false,
        landscape: false,
        service_fee: 0,
        copyright_fee: 0,
        folders: [],
        is_smart: false,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        content: Yup.string().required("Please make sure the form has content before uploading"),
    });

    function handleSubmit(values: NewTemplateItem) {
        if (selectedTags.length === 0) {
            return;
        }

        createRiderTemplate({ ...values, folders: selectedTags }).then(() => {
            navigate(`/${adminPrefix}/riders`, { replace: true });
        });
    }

    function collectTags(tag: FolderInterface) {
        const previousSelected = [...selectedTags];
        const index = previousSelected.indexOf(tag);

        if (index > -1) {
            previousSelected.splice(index, 1);
        } else {
            previousSelected.push(tag);
        }

        setSelectedTags(previousSelected);
    }

    return (
        <Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <NewTemplateWrapper>
                            <NewTemplateSettingsContainer>
                                <InputGroup>
                                    <NewRiderTemplateName
                                        type="text"
                                        name="name"
                                        autoCorrect="off"
                                        autoComplete="name"
                                        valid={touched.name && !errors.name}
                                        error={touched.name && errors.name}
                                    />
                                    <Bar />
                                    <Label htmlFor="name">Name</Label>
                                    {errors.name && touched.name && (
                                        <StyledInlineErrorMessage>
                                            {errors.name}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        name="description"
                                        autoCorrect="off"
                                        autoComplete="name"
                                        valid={touched.description && !errors.description}
                                        error={touched.description && errors.description}
                                    />
                                    <Bar />
                                    <Label htmlFor="description">Description</Label>
                                    {errors.description && touched.description && (
                                        <StyledInlineErrorMessage>
                                            {errors.name}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <FormatFoldersBasedOnTags
                                        tags={tags}
                                        onClick={collectTags}
                                        active={values.folders}
                                    />
                                    {selectedTags.length === 0 && (
                                        <StyledInlineErrorMessage>
                                            Please select a folder for the rider clause
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <OverviewFormButtonGroup>
                                    <CancelButton
                                        type="button"
                                        onClick={() =>
                                            navigate(`/${adminPrefix}/riders`, { replace: true })
                                        }
                                    >
                                        Back to overview
                                    </CancelButton>
                                    <SubmitButton type="submit">Create</SubmitButton>
                                </OverviewFormButtonGroup>
                            </NewTemplateSettingsContainer>
                            <NewTemplateEditorContainer>
                                {errors.content && touched.content && (
                                    <StyledInlineErrorMessage>
                                        {errors.content}
                                    </StyledInlineErrorMessage>
                                )}
                                <FroalaEditor
                                    config={froalaConfig}
                                    model={values.content}
                                    onModelChange={(val: object | undefined) => {
                                        setFieldValue("content", val);
                                    }}
                                />
                            </NewTemplateEditorContainer>
                        </NewTemplateWrapper>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
}

export function FormatFoldersBasedOnTags({
    tags,
    onClick,
    active,
}: FormatFoldersBasedOnTagsInterface) {
    return (
        <section>
            <FolderListTitle>Folders:</FolderListTitle>
            <ul>
                {tags.map((x) => (
                    <FormatFoldersBasedOnTagsItems
                        key={x.id}
                        data={x}
                        onClick={onClick}
                        active={active}
                    />
                ))}
            </ul>
        </section>
    );
}

export function FormatFoldersBasedOnTagsItems({
    data,
    onClick,
    active,
}: FormatFoldersBasedOnTagsItemsInterface) {
    function isTagActive(tag: string): boolean {
        return (
            active?.some((el) => {
                return el.id === tag;
            }) ?? false
        );
    }

    return (
        <FolderListItem>
            <FolderListItemTitle>
                <label>
                    <input
                        type="checkbox"
                        name="folder"
                        value={data.id}
                        onClick={() => onClick(data)}
                        defaultChecked={isTagActive(data.id)}
                    />
                    {data.name}
                </label>
            </FolderListItemTitle>
            {data.children.length > 0 && (
                <ul>
                    {data.children.map((x: any) => (
                        <FormatFoldersBasedOnTagsItems
                            key={x.id}
                            data={x}
                            onClick={() => onClick(x)}
                            active={active}
                        />
                    ))}
                </ul>
            )}
        </FolderListItem>
    );
}
