import React, { useEffect, useRef } from "react";
import { useChat } from "../../lib/providers/chat-provider";
import { Typography } from "../ui/typography";
import ChatMessageHeader from "./chat-message-header";
import ChatMessage from "./chat-message";
import { Separator } from "../ui/separator";
import ChatSendMessage from "./chat-send-message";
import { ChatMessageListProps } from "../../types/chat";

function ChatMessageList({ withHeader = true }: ChatMessageListProps) {
    const { messages } = useChat(),
        scrollToBottomRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        scrollToBottomRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
        });
    }, [messages]);

    return (
        <div className="w-2/3 h-[calc(100vh-116px)] mr-6">
            {withHeader && <ChatMessageHeader />}
            <div className="flex flex-col flex-grow w-full rounded-lg overflow-hidden h-full">
                <div className="flex flex-col flex-grow h-0 p-4 overflow-auto">
                    {messages?.length === 0 ? (
                        <div>
                            <Typography
                                text="No messages yet"
                                style="muted"
                                className="flex justify-center"
                            />
                        </div>
                    ) : (
                        messages?.map((message) => (
                            <ChatMessage message={message} key={message.id} />
                        ))
                    )}
                    <div ref={scrollToBottomRef} />
                </div>
                <Separator className="my-4" />
                <ChatSendMessage />
            </div>
        </div>
    );
}

export default ChatMessageList;
