import { tenantApi } from "../tenantApi";
import { LoginSuccessFul } from "../../types";
import { TenantUser, User } from "../../types/auth";

const authenticationApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        signInUser: build.mutation<LoginSuccessFul, User>({
            query: (body) => ({
                url: "authentication/login-check",
                method: "POST",
                body: body,
            }),
        }),
        signOutUser: build.query<boolean, void>({
            query: () => ({
                url: "/authentication/log-out",
            }),
        }),
        fetchLoggedInUser: build.query<TenantUser | string, void>({
            query: () => ({
                url: "/authentication/get-logged-in-user",
            }),
        }),
        requestPasswordReset: build.mutation<boolean, { username: string; email: string }>({
            query: (body) => ({
                url: "/authentication/forgot-password",
                method: "POST",
                body: body,
            }),
        }),
        resetPassword: build.mutation<
            boolean | string,
            { username: string; password: string; token: string }
        >({
            query: (body) => ({
                url: "/authentication/reset-password",
                method: "POST",
                body: body,
            }),
        }),
    }),
});

export const {
    useSignInUserMutation,
    useLazySignOutUserQuery,
    useLazyFetchLoggedInUserQuery,
    useRequestPasswordResetMutation,
    useResetPasswordMutation,
} = authenticationApi;
