import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import { RtkExtraConfig } from "../lib/utils";
import { getCookie } from "./shared";
import { determineTenantName } from "../lib/helpers/tenant";

const baseQuery = fetchBaseQuery({
    baseUrl: determineTenantName() ? `/${determineTenantName()}/api/tenant/v1` : "",
    prepareHeaders: (headers) => {
        const csrfToken = decodeURIComponent(getCookie("XSRF-TOKEN") ?? "");
        headers.append("X-XSRF-TOKEN", csrfToken);
    },
    timeout: 90000,
});

const baseQueryWithRetry = retry(baseQuery, RtkExtraConfig);

export const tenantApi = createApi({
    reducerPath: "tenantApi",
    baseQuery: baseQueryWithRetry,
    tagTypes: [
        "CaseFiles",
        "CaseFilesRotLinks",
        "AvailableCaseFilesRotLinks",
        "CaseFileFileUploads",
        "Riders",
        "GlobalRiders",
        "CaseFileAuditTrail",
        "Documents",
        "Templates",
        "Invoices",
        "Addenda",
        "Houseforms",
        "BillingInfo",
        "RecapDocument",
        "CaseFileArchive",
        "ChatChannels",
        "OrganisationMembers",
        "RecapTemplates",
        "Approvals",
        "CaseFileMetaData",
        "RecapFieldSavedValue",
        "ColumnVisibility",
    ],
    endpoints: () => ({}),
});
