import { tenantApi } from "../tenantApi";
import { BillingInfo, Invoice } from "../../types/billing";

const billingApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getInvoices: build.query<{ data: Array<Invoice> }, void>({
            query: () => ({
                url: "/invoice",
            }),
            providesTags: (result: { data: Array<Invoice> }) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "Invoices", id }) as const)
                    : []),
                { type: "Invoices" as const, id: "LIST" },
            ],
        }),
        processInvoice: build.mutation<string, Invoice>({
            query: (invoice) => ({
                url: "/invoice/generate-pdf",
                method: "Post",
                body: {
                    invoice: invoice,
                },
                responseHandler: "text",
            }),
            invalidatesTags: [
                {
                    type: "Invoices",
                    id: "LIST",
                },
            ],
        }),
        getBillingInfo: build.query<{ data: BillingInfo }, void>({
            query: () => ({
                url: "/account/billing-info",
            }),
            providesTags: ["BillingInfo"],
        }),
        updateBillingInfo: build.mutation<void, BillingInfo>({
            query: (body) => ({
                url: "/account/billing-info",
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["BillingInfo"],
        }),
    }),
});

export const {
    useGetInvoicesQuery,
    useProcessInvoiceMutation,
    useLazyGetBillingInfoQuery,
    useUpdateBillingInfoMutation,
} = billingApi;
