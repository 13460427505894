import { DocumentsTableProps } from "../../../types/case-files";
import React, { useState } from "react";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns, tableDropDown } from "../../../config/overview";
import { useNavigate } from "react-router";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import { Tables } from "../../../config/app";

function OverviewTable({ data }: DocumentsTableProps) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        broker: false,
        charterer: false,
        owner: false,
    });
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            meta: {
                tableId: Tables.CASE_FILE_OVERVIEW,
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });
    const navigate = useNavigate();

    return (
        <div className="flex flex-col h-full">
            <DataTableToolbar
                table={table}
                searchableColumns={[
                    "document_name",
                    "load_port",
                    "discharge_port",
                    "vessel_name",
                    "owner",
                    "charterer",
                    "broker",
                ]}
            />
            <div className="flex py-4 h-full">
                <DataTable
                    columns={columns}
                    contextMenuItems={tableDropDown}
                    table={table}
                    onClick={(row) => navigate(`/case-file/${row.id}`)}
                />
            </div>
        </div>
    );
}

export default OverviewTable;
