import { tenantApi } from "../tenantApi";
import { RecapCategoryInterface } from "../../types/recap";

const recapCategories = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getRecapCategories: build.query<RecapCategoryInterface[], void>({
            query: () => ({
                url: "/recaps/categories/",
            }),
            transformResponse: (response: {
                data: RecapCategoryInterface[];
            }): RecapCategoryInterface[] => {
                return response.data;
            },
            providesTags: () => [],
        }),
    }),
});

export const { useGetRecapCategoriesQuery } = recapCategories;
