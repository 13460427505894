import React from "react";
import { Button } from "../../../components/ui/button";
import { CheckCheck, Eye, FilePen, Save, Trash2 } from "lucide-react";
import { useDocumentEditor } from "../../../lib/providers/DocumentEditorProvider";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../components/ui/tooltip";
import SyncData from "./sync-data";

function DocumentActions() {
    const { document, handleSave, isUserActive, autoSaveTimer, setSelectedAction, signingStatus } =
            useDocumentEditor(),
        { permissions } = useCaseFiles();

    return (
        <div>
            <div className={"flex gap-4 w-full justify-end"}>
                <SyncData />
                {!document?.finalized && permissions.finalize_documents && (
                    <Button
                        variant={"outline"}
                        onClick={async () => {
                            await handleSave();
                            setSelectedAction("finalize");
                        }}
                        disabled={!isUserActive()}
                    >
                        <CheckCheck className="mr-2 h-4 w-4" />
                        <span>Finalize</span>
                    </Button>
                )}
                {document?.finalized &&
                (signingStatus === "allowed" || signingStatus === "cancelled") &&
                permissions.sign_documents ? (
                    <Button
                        onClick={() => {
                            setSelectedAction("request-signatures");
                        }}
                    >
                        <FilePen className="mr-2 h-4 w-4" />
                        <span>Request signatures</span>
                    </Button>
                ) : null}
                {document?.finalized && signingStatus === "pending" ? (
                    <Button
                        onClick={() => {
                            setSelectedAction("signing-status");
                        }}
                    >
                        <Eye className="mr-2 h-4 w-4" />
                        <span>Signing status</span>
                    </Button>
                ) : null}
                {document?.finalized &&
                signingStatus === "pending" &&
                permissions.sign_documents ? (
                    <Button
                        onClick={() => {
                            setSelectedAction("cancel-signing");
                        }}
                    >
                        <Trash2 className="mr-2 h-4 w-4" />
                        <span>Cancel signing</span>
                    </Button>
                ) : null}
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="default" onClick={handleSave} disabled={!isUserActive()}>
                            <Save className="h-4 w-4 mr-2" /> Save
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side={"bottom"} className={"z-30"} align="center">
                        Auto saving in {autoSaveTimer} seconds
                    </TooltipContent>
                </Tooltip>
            </div>
        </div>
    );
}

export default DocumentActions;
