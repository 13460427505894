import axios from "axios";
import { ColorSchemaInterface } from "../../types";
import { DocusignSignatureStatus, SigneeDataInterface } from "../../types/docusign";
import { SignatureRoleInterface } from "../../types/document-editor";

export async function cancelSignature(document_id: string, case_file_id: string) {
    return await axios
        .delete(`/api/tenant/v1/docusign/${case_file_id}/cancel/${document_id}`, {
            headers: {
                Authorization: "Bearer ",
                Accept: "application/json",
            },
        })
        .then((r) => r.data);
}

export async function requestSignatures(
    id: string,
    type: string,
    signatures: Array<{
        name: string;
        email: string;
        note: string;
    }>,
    content: string,
    schema: ColorSchemaInterface,
    case_file_id: string,
    token?: string,
): Promise<SigneeDataInterface> {
    return await axios
        .post(
            `/api/tenant/v1/docusign/${case_file_id}/signatures/${id}/${type}`,
            {
                signatures: signatures,
                schema: schema,
                content: content,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                    Accept: "application/json",
                },
            },
        )
        .then((res) => res.data);
}

export async function getSignatureData(
    id: string,
    case_file_id: string,
): Promise<DocusignSignatureStatus> {
    return await axios
        .get(`/api/tenant/v1/docusign/${case_file_id}/signatures/${id}`)
        .then((res) => res.data);
}

export async function resendSignature(
    signee: SignatureRoleInterface,
    document_id: string,
    case_file_id: string,
) {
    return await axios
        .post(
            `/api/tenant/v1/docusign/${case_file_id}/reminder/${signee.id}/${signee.name}/${signee.email}/${document_id}`,
            {
                headers: {
                    Authorization: "Bearer ",
                    Accept: "application/json",
                },
            },
        )
        .then((r) => r.data);
}
