import React, { Fragment, useEffect, useState } from "react";
import { getRecapFieldCategories, getRecapFieldsIndex } from "../../Admin/Recap";
import { RecapCategoryInterface } from "./index.interface";
import {
    RecapCategoryContainer,
    RecapCategoryList,
    RecapCategoryListItem,
    RecapCategoryListItemContainer,
    RecapCategoryListItemSubTitle,
    RecapCategoryListItemTitle,
    RecapCategoryListItemWrapper,
    RecapCategoryTopBar,
    RecapFieldContainer,
    RecapFieldWrapper,
} from "./Style";
import { RecapFieldCategoryModal } from "./Modals/Category";
import { NewRecapFieldModal } from "./Modals/Field";
import { RecapCategoryTitle } from "../NewRiderTemplate/Style";
import { DashboardTopBarContainerContent } from "../Dashboard/Style";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import { columns } from "../../../config/recap-fields";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { Button } from "../../../components/ui/button";
import { DataTableToolbarButton } from "../../../types/table";
import { RecapSingleFieldInterface } from "../RecapTemplates/index.interface";

export function RecapFields() {
    const [data, setData] = useState<Array<RecapSingleFieldInterface>>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });
    const [categories, setCategories] = useState<Array<RecapCategoryInterface>>([]);
    const [activeField, setActiveField] = useState<RecapSingleFieldInterface>();
    const [activeCategory, setActiveCategory] = useState<RecapCategoryInterface>();
    const [showNewCategoryModal, setShowNewCategoryModal] = useState<boolean>(false);
    const [showNewFieldModal, setShowNewFieldModal] = useState<boolean>(false);

    const buttons: Array<DataTableToolbarButton> = [
        {
            label: "add recap field",
            action: () => setShowNewFieldModal(true),
        },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        getRecapFieldsIndex().then((r) => {
            setData(r.data);
        });

        getRecapFieldCategories().then((r) =>
            setCategories(r.data as unknown as RecapCategoryInterface[]),
        );
    }

    function closeModal() {
        setShowNewCategoryModal(false);
        setShowNewFieldModal(false);
        setActiveCategory(undefined);
        setActiveField(undefined);
        fetchData();
    }

    function editCategory(row: RecapCategoryInterface) {
        setActiveCategory(row);
        setShowNewCategoryModal(true);
    }

    function handleClick(row: RecapSingleFieldInterface) {
        setActiveField(row);
        setShowNewFieldModal(true);
    }

    return (
        <Fragment>
            <RecapFieldCategoryModal
                open={showNewCategoryModal}
                onClose={closeModal}
                row={activeCategory}
                type="field"
            />
            <NewRecapFieldModal
                open={showNewFieldModal}
                onClose={closeModal}
                row={activeField}
                categories={categories}
                type="field"
            />
            <RecapFieldWrapper>
                <RecapCategoryContainer>
                    <RecapCategoryTopBar>
                        <RecapCategoryTitle>Recap categories</RecapCategoryTitle>
                        <Button onClick={() => setShowNewCategoryModal(true)}>
                            <svg
                                className="MuiSvgIcon-root"
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                            >
                                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                            </svg>
                            <span>New</span>
                        </Button>
                    </RecapCategoryTopBar>
                    <RecapCategoryList>
                        {categories.map((c, i) => (
                            <RecapCategoryListItem key={i}>
                                <RecapCategoryListItemWrapper>
                                    <RecapCategoryListItemContainer>
                                        <RecapCategoryListItemTitle>
                                            {c.name}
                                        </RecapCategoryListItemTitle>
                                        <RecapCategoryListItemSubTitle>
                                            Contains {c.contains} &nbsp;fields
                                        </RecapCategoryListItemSubTitle>
                                    </RecapCategoryListItemContainer>
                                    <Button onClick={() => editCategory(c)}>
                                        <span>Edit</span>
                                    </Button>
                                </RecapCategoryListItemWrapper>
                            </RecapCategoryListItem>
                        ))}
                    </RecapCategoryList>
                </RecapCategoryContainer>
                <RecapFieldContainer>
                    <Fragment>
                        <DashboardTopBarContainerContent>
                            <div className="flex flex-col h-full">
                                <DataTableToolbar
                                    table={table}
                                    title="Recap fields"
                                    searchableColumns={[
                                        "name",
                                        "data_id",
                                        "categoryName",
                                        "data_type",
                                    ]}
                                    buttons={buttons}
                                />
                                <div className="flex py-4 h-full">
                                    <DataTable
                                        columns={columns}
                                        table={table}
                                        onClick={handleClick}
                                    />
                                </div>
                            </div>
                        </DashboardTopBarContainerContent>
                    </Fragment>
                </RecapFieldContainer>
            </RecapFieldWrapper>
        </Fragment>
    );
}
