import React, { useState } from "react";
import { Sheet, SheetContent } from "../ui/sheet";
import { Dialog, DialogContent } from "../ui/dialog";
import { ActionContextProps } from "../../types/table";

function DataTableActionContext<Generic>({ action, row, onClose }: ActionContextProps<Generic>) {
    const [open, setOpen] = useState<boolean>(true);

    function closeContext() {
        setOpen(false);
        onClose();
    }

    switch (action.elementType) {
        case "sheet":
            return (
                <Sheet open={open} onOpenChange={closeContext}>
                    <SheetContent className={`bg-white ${action.customSize}`}>
                        {action.element ? action.element(row, closeContext) : null}
                    </SheetContent>
                </Sheet>
            );
        default:
            return (
                <Dialog open={open} onOpenChange={closeContext}>
                    <DialogContent className={`bg-white ${action.customSize}`}>
                        {action.element ? action.element(row, closeContext) : null}
                    </DialogContent>
                </Dialog>
            );
    }
}

export default DataTableActionContext;
