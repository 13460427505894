import { Route, Routes } from "react-router-dom";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { AuthProvider } from "../lib/providers/AuthProvider";
import AdminAuthProvider, { useAdmin } from "../lib/providers/admin-auth-provider";
import AdminLogin from "../Central/Pages/Admin";
import { AdminBasePage } from "../Central/Pages/Admin/BasePage";
import { AdminDashboardPage } from "../Central/Pages/Dashboard";
import { AdminLayout } from "../layouts/admin-layout";
import { AdminActivityPage } from "../Central/Pages/Dashboard/Activity";
import Register from "../containers/authentication/register";
import { AdminTenantsPage } from "../Central/Pages/Tenants";
import { AdminInvoicesPage } from "../Central/Pages/Invoices";
import { AdminTenantDetailPage } from "../Central/Pages/Tenants/Detail";
import { RiderTemplatesPage } from "../Central/Pages/RiderTemplates";
import { AdminTemplatesPage } from "../Central/Pages/Templates";
import { AdminNewTemplatePage } from "../Central/Pages/Templates/NewTemplate";
import { BackupsPage } from "../Central/Pages/Backups";
import { RecapFields } from "../Central/Pages/RecapFields";
import { NewRiderTemplatePage } from "../Central/Pages/NewRiderTemplate";
import { RecapTemplates } from "../Central/Pages/RecapTemplates";
import { EditRecapTemplate } from "../Central/Pages/RecapTemplates/Edit";
import { ComponentWithChildElement } from "../types/auth";
import { adminPrefix } from "../config/central";

/**
 * Make sure user is logged in
 * @param children
 * @constructor
 */
function RequireAdminAuth({ children }: ComponentWithChildElement): ReactNode {
    const [firstAttempt, setFirstAttempt] = useState<boolean | undefined>(undefined);
    const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);

    const auth = useAdmin();

    useEffect(() => {
        if (auth.user) {
            setFirstAttempt(true);
        }
    }, [auth]);

    useEffect(() => {
        if (firstAttempt === true) {
            if (auth.user) {
                setLoggedIn(true);
            } else {
                setLoggedIn(false);
            }
        }
    }, [firstAttempt]);

    return <Fragment>{loggedIn === true && children}</Fragment>;
}

export const Main = () => {
    return (
        <Fragment>
            <AuthProvider>
                <Routes>
                    {/*<Route path={"/"} element={<SplashPage/>}/>*/}
                    <Route path="/register" element={<Register />} />
                    {/*<Route*/}
                    {/*    path="/dsredirect"*/}
                    {/*    element={*/}
                    {/*        <DocuSignRedirect />*/}
                    {/*    }*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path="/verify-email/:token"*/}
                    {/*    element={*/}
                    {/*        <VerifyEmail />*/}
                    {/*    }*/}
                    {/*/>*/}
                </Routes>
            </AuthProvider>
            <AdminAuthProvider>
                <Routes>
                    <Route path={`/${adminPrefix}`} element={<AdminLogin />} />
                    <Route
                        path={`/${adminPrefix}/dashboard`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <AdminDashboardPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/activity`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <AdminActivityPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/invoices`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <AdminInvoicesPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/templates`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <AdminTemplatesPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/templates/new`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <AdminNewTemplatePage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/riders`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <RiderTemplatesPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/riders/new`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <NewRiderTemplatePage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/tenants`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <AdminTenantsPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/tenants/:tenantId`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <AdminTenantDetailPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/recap-templates`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <RecapTemplates />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/recap-templates/:id`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <EditRecapTemplate />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    <Route
                        path={`/${adminPrefix}/recap-fields`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <RecapFields />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    {/*<Route*/}
                    {/*    path={`/${adminPrefix}/recap-clauses`}*/}
                    {/*    element={*/}
                    {/*        <RequireAdminAuth>*/}
                    {/*            <AdminBasePage>*/}
                    {/*                <RecapClauses />*/}
                    {/*            </AdminBasePage>*/}
                    {/*        </RequireAdminAuth>*/}
                    {/*    }*/}
                    {/*/>*/}
                    <Route
                        path={`/${adminPrefix}/backups`}
                        element={
                            <RequireAdminAuth>
                                <AdminLayout>
                                    <AdminBasePage>
                                        <BackupsPage />
                                    </AdminBasePage>
                                </AdminLayout>
                            </RequireAdminAuth>
                        }
                    />
                    {/*<Route*/}
                    {/*    path={`/${adminPrefix}/fleet-templates`}*/}
                    {/*    element={*/}
                    {/*        <RequireAdminAuth>*/}
                    {/*            <AdminBasePage>*/}
                    {/*                <FleetTemplates />*/}
                    {/*            </AdminBasePage>*/}
                    {/*        </RequireAdminAuth>*/}
                    {/*    }*/}
                    {/*/>*/}
                </Routes>
            </AdminAuthProvider>
        </Fragment>
    );
};
