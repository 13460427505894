import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import React, { Fragment, useState } from "react";
import { ProcessInvoiceProps } from "../../types/billing";
import { useInviteTeamMemberMutation } from "../../api/tenant/organisation";
import { toast } from "sonner";
import Loading from "../../components/global/loading";

function InviteTeamMember({ open, onClose }: Omit<ProcessInvoiceProps, "invoice">) {
    const schema = z.object({
        name: z.string().min(2, {
            message: "Username must be at least 2 characters.",
        }),
        email: z.string().email("This is not a valid email."),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: "",
            email: "",
        },
    });
    const [inviteTeamMember] = useInviteTeamMemberMutation();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        try {
            await inviteTeamMember(values).unwrap();

            toast.message("Invite email sent");

            onClose();
            form.reset();
        } catch (e) {
            toast.error("Something went wrong while inviting team member");
        }
        setLoading(false);
    }

    function handleClose() {
        form.reset();
        onClose();
    }

    return (
        <Dialog open={open} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[400px]">
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <DialogHeader>
                                <DialogTitle>Invite team member</DialogTitle>
                            </DialogHeader>
                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(handleSubmit)}>
                                    <FormField
                                        control={form.control}
                                        name="name"
                                        render={({ field }) => (
                                            <FormItem>
                                                <Label htmlFor="name">Name</Label>
                                                <FormControl>
                                                    <Input {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem className="mt-4">
                                                <Label htmlFor="name">E-mail</Label>
                                                <FormControl>
                                                    <Input {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <DialogFooter>
                                        <Button className="mt-4" type="submit">
                                            Invite
                                        </Button>
                                    </DialogFooter>
                                </form>
                            </Form>
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

export default InviteTeamMember;
