import { useUpdateCaseFileStatusMutation } from "../../../api/tenant/case-file";
import { toast } from "sonner";
import React, { Fragment, useState } from "react";
import { TableActionOverviewHoverProps } from "../../../types/table";
import { CaseFileIndex, CaseFileStatusType } from "../../../types/case-files";
import { formatCaseFileStatus } from "../../../lib/utils";
import { Circle, CircleCheckBig } from "lucide-react";

function CaseFileEditStatus({
    row,
    child,
}: TableActionOverviewHoverProps<CaseFileStatusType, CaseFileIndex>) {
    const [status, setStatus] = useState<Partial<CaseFileStatusType>>(row.status ?? "draft");
    const [updateStatus] = useUpdateCaseFileStatusMutation();

    async function handleSubmit(child: CaseFileStatusType) {
        await updateStatus({
            case_file_id: row.id,
            status: child,
        });

        setStatus(child);

        toast.message("Updated status");
    }

    function isSelected() {
        return child === status;
    }

    return (
        <Fragment>
            {
                <div
                    key={child}
                    onClick={() => {
                        if (!isSelected()) {
                            return handleSubmit(child);
                        }
                    }}
                    className={`flex flex-row-reverse font-medium items-center p-0.5 ${
                        isSelected() ? "cursor-auto" : "cursor-pointer"
                    }`}
                >
                    <span className="text-gray-950">{formatCaseFileStatus(child)}</span>
                    {isSelected() ? (
                        <CircleCheckBig className="mr-2 h-4 w-4" />
                    ) : (
                        <Circle className="mr-2 h-4 w-4" />
                    )}
                </div>
            }
        </Fragment>
    );
}

export default CaseFileEditStatus;
