import React, { useEffect } from "react";
import { Typography } from "../../components/ui/typography";
import SimpleList from "../../components/simple-list";
import { useLazyGetBillingInfoQuery } from "../../api/tenant/billing";
import Loading from "../../components/global/loading";
import UpdateBillingInfo from "./update-billing-info";

function BillingInfo() {
    const [getBillingInfo, { data: billingInfo, isLoading }] = useLazyGetBillingInfoQuery();

    useEffect(() => {
        getBillingInfo();
    }, []);

    return (
        <Loading
            loaded={!isLoading}
            child={
                <div className="flex flex-col h-full flex-1">
                    <div className="flex w-full justify-between mb-4">
                        <Typography text="Information" style="h3" className="mb-4" />
                        <div className="flex gap-x-4">
                            <UpdateBillingInfo billingInfo={billingInfo?.data} />
                        </div>
                    </div>
                    <SimpleList
                        items={[
                            {
                                title: "Company name",
                                content: billingInfo?.data.company_name ?? "-",
                            },
                            {
                                title: "Street",
                                content: billingInfo?.data.street ?? "-",
                            },
                            {
                                title: "Housenumber",
                                content: billingInfo?.data.housenumber ?? "-",
                            },
                            {
                                title: "Zipcode",
                                content: billingInfo?.data.zipcode ?? "-",
                            },
                            {
                                title: "City",
                                content: billingInfo?.data.city ?? "-",
                            },
                            {
                                title: "Country",
                                content: billingInfo?.data.country ?? "-",
                            },
                            {
                                title: "VAT-No",
                                content: billingInfo?.data.vat_number ?? "-",
                            },
                        ]}
                    />
                </div>
            }
        />
    );
}

export default BillingInfo;
