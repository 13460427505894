import {
    DialogClose,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../../components/ui/dialog";
import React, { Fragment } from "react";
import { Button } from "../../../../components/ui/button";

export default function DeleteRecapTemplate({ row, onDelete, onClose }: any) {
    function handleSubmit() {
        onDelete(row);
        onClose();
    }

    return (
        <Fragment>
            <DialogHeader>
                <DialogTitle className="text-xl">Remove recap template</DialogTitle>
                <DialogDescription>Do you want to remove this recap template?</DialogDescription>
            </DialogHeader>
            <DialogFooter className="mt-4">
                <DialogClose asChild>
                    <Button type="button" variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </DialogClose>
                <DialogClose asChild>
                    <Button type="submit" onClick={handleSubmit}>
                        Confirm
                    </Button>
                </DialogClose>
            </DialogFooter>
        </Fragment>
    );
}
