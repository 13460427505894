import React, { Fragment, useState } from "react";
import { DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { Button } from "../ui/button";
import { useGetOrganisationMembersQuery } from "../../api/tenant/organisation";
import { useAuth } from "../../lib/providers/AuthProvider";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "../ui/command";
import { toast } from "sonner";
import { FormItem } from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "@radix-ui/react-label";
import { useCreateNewGroupChatChannelMutation } from "../../api/tenant/chat";

function NewGroupChannel({ onClose }: { onClose: () => void }) {
    const { data: users } = useGetOrganisationMembersQuery();

    const [selectedUserIds, setSelectedUserIds] = useState<Array<string>>([]);

    const [channelName, setChannelName] = useState<string>("");

    const user = useAuth().state.user;

    const [createNewChannel] = useCreateNewGroupChatChannelMutation();

    async function handleSubmit() {
        try {
            await createNewChannel({
                channel_name: channelName,
                channel_members_ids: selectedUserIds,
            });

            toast.message("Chat channel created");

            onClose();
        } catch (e) {
            toast.message("Failed to create chat channel");
        }
    }

    return (
        <Fragment>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>New group chat channel</DialogTitle>
                </DialogHeader>
                <FormItem className="py-2">
                    <Label htmlFor="channel_name">Channel name</Label>
                    <Input
                        type="channel_name"
                        id="channel_name"
                        placeholder="Channel name"
                        onChange={(e) => setChannelName(e.target.value)}
                    />
                </FormItem>
                <Command className="border border-input border-solid">
                    <CommandInput placeholder="Search..." />
                    <CommandList className="rounded-md ">
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {users?.data
                                .filter((x) => x.id !== user?.id)
                                .map((user, index) => {
                                    const isSelected = selectedUserIds.includes(user.id);
                                    return (
                                        <CommandItem
                                            key={index}
                                            onSelect={() => {
                                                if (isSelected) {
                                                    setSelectedUserIds(
                                                        selectedUserIds.filter(
                                                            (x) => x !== user.id,
                                                        ),
                                                    );
                                                } else {
                                                    setSelectedUserIds([
                                                        ...selectedUserIds,
                                                        user.id,
                                                    ]);
                                                }
                                            }}
                                            className={`cursor-pointer ${
                                                isSelected
                                                    ? "bg-blue-400 color-white hover:bg-blue-400" +
                                                      " aria-selected:bg-blue-400" +
                                                      " aria-selected:text-white"
                                                    : ""
                                            }`}
                                        >
                                            <span
                                                className={`flex items-center ${
                                                    isSelected ? "text-white" : "text-gray-950"
                                                }`}
                                            >
                                                {user.name}
                                            </span>
                                        </CommandItem>
                                    );
                                })}
                        </CommandGroup>
                    </CommandList>
                </Command>
                <DialogFooter className="mt-4">
                    <Button type="submit" onClick={handleSubmit}>
                        Create channel
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Fragment>
    );
}

export default NewGroupChannel;
