import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { RegisterNewAccount } from "../types";
import { getCookie } from "./shared";
import { determineTenantName } from "../lib/helpers/tenant";

export const cookieApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "",
    }),
    reducerPath: "cookies",
    endpoints: (build) => ({
        getCsrfCookie: build.query<unknown, void>({
            query: () => "/sanctum/csrf-cookie",
            extraOptions: {},
        }),
        downloadBlob: build.query<Blob | string, string>({
            query: (downloadUrl) => ({
                url: `/${determineTenantName()}${downloadUrl}`,
                responseHandler: (response) =>
                    response.blob().then((blob) => URL.createObjectURL(blob)),
            }),
        }),
        registerAccount: build.mutation<boolean, RegisterNewAccount>({
            query: (body) => ({
                url: "/api/system/main/create-environment",
                method: "POST",
                body: body,
                headers: {
                    "X-XSRF-TOKEN": decodeURIComponent(getCookie("XSRF-TOKEN") ?? ""),
                },
            }),
        }),
    }),
});
export const { useLazyGetCsrfCookieQuery, useLazyDownloadBlobQuery, useRegisterAccountMutation } =
    cookieApi;
