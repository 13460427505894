import React, { Fragment, useState } from "react";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { GlobalDocumentInterface } from "../../../types/document-editor";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../../../components/ui/input";
import { Typography } from "../../../components/ui/typography";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import { useDocumentEditor } from "../../../lib/providers/DocumentEditorProvider";
import { requestSignatures } from "../../../api/tenant/docusign";
import { SignatureRoleInterface } from "../../../types/docusign";
import { getColors } from "../../../lib/utils";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function RequestSignatures({ row, onClose }: TableActionProps<GlobalDocumentInterface>) {
    // const [emails, setEmails] = useState<string[]>();
    const signatures = getSignatures(),
        defaultValues = signatures.map((x) => {
            return {
                id: x.id,
                email: "",
                name: "",
                note: "",
                data_id: x.data_id,
                role: x.role,
                inner_html: x.inner_html ?? "",
            };
        });
    const schema = z.object({
        signatures: z.array(
            z.object({
                email: z.string().email(),
                name: z.string(),
                id: z.number(),
                note: z.string(),
                data_id: z.string(),
                role: z.string(),
                inner_html: z.string(),
            }),
        ),
        colorSchema: z.enum(["traditional", "black", "default"]),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            signatures: defaultValues,
            colorSchema: "default",
        },
    });
    const {
        control,
        register,
        formState: { errors },
    } = form;
    const { fields } = useFieldArray({
        control,
        name: "signatures",
    });
    const { setSigningStatus } = useDocumentEditor();
    const { case_file_id } = useCaseFiles();

    function getSignatures(): Array<SignatureRoleInterface> {
        const signatureNodes = document.querySelectorAll("[data-signature-id^='unafix-signature']");

        if (!signatureNodes) {
            return [];
        }

        return Array.prototype.map.call(signatureNodes, (node: Element, index: number) => {
            return {
                id: index,
                data_id: node.getAttribute("data-signature-id"),
                role: node.getAttribute("data-signature-name"),
                inner_html: node.innerHTML ?? "",
                name: "",
                email: "",
            };
        });
    }

    const [errorMessage, setErrorMessage] = useState<string>();

    async function handleSubmit(values: z.infer<typeof schema>) {
        if (
            values.signatures.map((x) => x.email).length >
            new Set(values.signatures.map((x) => x.email)).size
        ) {
            setErrorMessage("Emails must be unique");

            return;
        }

        let newContent: string = row.content;

        values.signatures.forEach((x) => {
            newContent = newContent.replace(
                `<a data-signature-id="${x.data_id}" data-signature-name="${x.role}">${x.inner_html}</a>`,
                `<span style="color: white; font-size: 2.5em;">_</span><span style="color: white;">*|*${x.role}*|*</span>`,
            );
        });

        await requestSignatures(
            row.id,
            row.type,
            values.signatures,
            newContent,
            getColors(values.colorSchema),
            case_file_id,
        );

        setSigningStatus("pending");

        onClose();
    }

    return (
        <Fragment>
            <DialogHeader>
                <DialogTitle>Start the e-signing process</DialogTitle>
                <DialogDescription>
                    You can invite the signatories to this document to sign electronically using
                    AES/QES level E-Signatures.
                    <br />
                    Invited signatories can see, download and sign this document with no
                    registrating requirement.
                    <br />
                    <br />
                    By continuing, you agree to the Oceap Recap{" "}
                    <a className="underline" href="/pdf/EULA_Signatures.pdf" target="_blank">
                        terms of use
                    </a>{" "}
                    relating to the use of electronic signatures.
                </DialogDescription>
            </DialogHeader>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    {fields.map((field, index) => (
                        <Fragment key={index}>
                            <Typography
                                text={`Signatory ${index + 1} : ${signatures[index].role}`}
                                style="h4"
                            />
                            <FormItem className="py-2">
                                <FormLabel>Signatory name</FormLabel>
                                <FormControl>
                                    <Input
                                        {...register(`signatures.${index}.name` as const)}
                                        defaultValue={field.name}
                                        className="border p-2 border-gray-300"
                                    />
                                </FormControl>
                                <FormMessage>
                                    {errors?.signatures &&
                                        errors?.signatures[index] &&
                                        errors?.signatures[index]?.name?.message}
                                </FormMessage>
                            </FormItem>
                            <FormItem className="py-2">
                                <FormLabel>Signatory email</FormLabel>
                                <FormControl>
                                    <Input
                                        {...register(`signatures.${index}.email` as const)}
                                        defaultValue={field.email}
                                        className="border p-2 border-gray-300"
                                    />
                                </FormControl>
                                <FormMessage>
                                    {errors?.signatures &&
                                        errors?.signatures[index] &&
                                        errors?.signatures[index]?.email?.message}
                                </FormMessage>
                            </FormItem>
                        </Fragment>
                    ))}
                    <FormField
                        control={form.control}
                        name="colorSchema"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Color schema</FormLabel>
                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue defaultValue="default" />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem value="default">Ocean Recap scheme</SelectItem>
                                        <SelectItem value="traditional">
                                            Traditional scheme
                                        </SelectItem>
                                        <SelectItem value="black">Black scheme</SelectItem>
                                    </SelectContent>
                                </Select>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    {errorMessage && <Typography text={errorMessage} style="warning" />}
                    <DialogFooter className="mt-4">
                        <Button type="submit">Request Signatures</Button>
                    </DialogFooter>
                </form>
            </Form>
        </Fragment>
    );
}

export default RequestSignatures;
