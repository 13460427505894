import React from "react";
import {
    DashboardTopBarContainerContent,
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentRange,
    DashboardTopBarContainerContentRangeSelector,
    DashboardTopBarContainerContentTitle,
} from "../../Style";
import { Bar } from "react-chartjs-2";
import { DashboardSalesTrendsInterface } from "../../index.interface";
import { caseFileOptions } from "../../config";

export function DashboardSalesTrends({ data }: DashboardSalesTrendsInterface) {
    return (
        <DashboardTopBarContainerContent>
            <DashboardTopBarContainerContentHeader>
                <DashboardTopBarContainerContentTitle>
                    Case file Trends
                </DashboardTopBarContainerContentTitle>
                <DashboardTopBarContainerContentRange>
                    <DashboardTopBarContainerContentRangeSelector>
                        <option>Past 6 months</option>
                    </DashboardTopBarContainerContentRangeSelector>
                </DashboardTopBarContainerContentRange>
            </DashboardTopBarContainerContentHeader>
            {data.datasets && <Bar options={caseFileOptions} data={data} />}
        </DashboardTopBarContainerContent>
    );
}
