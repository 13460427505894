import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { determineTenantName } from "./lib/helpers/tenant";

const container = document.getElementById("app");

if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    const tenant: string | undefined = determineTenantName();
    root.render(
        // <React.StrictMode> // TODO enable this
        <Router basename={`/${tenant ?? ""}`}>
            <App />
        </Router>,
        // </React.StrictMode>
    );
}
