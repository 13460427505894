import React, {
    createContext,
    MutableRefObject,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { ProviderWithChildren } from "../../types";
import { useAuth } from "./AuthProvider";
import { useCaseFiles } from "./CaseFilesProvider";
import FroalaObject from "froala-editor";
import { toast } from "sonner";
import { ChangeLogV2 } from "../../plugins/ChangeLogV2";
import useInterval from "../useInterval";
import { fromUnixTime } from "date-fns";
import { AUTO_SAVE_TIMER } from "../../config/document-editor";
import { fetchSingleDocument, setActiveCollaborator } from "../../api/tenant/document-editor";
import {
    ActiveUsersInterface,
    DocumentSigningStatusType,
    GlobalDocumentInterface,
    RecapSingleFieldInterface,
    TrackChangesEntry,
} from "../../types/document-editor";
import { useStoreManualSaveMutation } from "../../api/tenant/documents";
import { TenantUser } from "../../types/auth";

type DocumentEditorContextType = {
    activeUsers: Array<ActiveUsersInterface>;
    newChanges: boolean;
    changeLog: Array<TrackChangesEntry>;
    smartFields: Array<RecapSingleFieldInterface>;
    activeCollaboratorId: string;
    document_id: string;
    isUserActive: () => boolean;
    handleGrantAccess: (value: string) => Promise<void>;
    froalaConfigFinal: Record<string, unknown>;
    document: GlobalDocumentInterface | undefined;
    froalaConfig: Record<string, unknown>;
    froalaRef: MutableRefObject<any>;
    handleChangeMade: (x: boolean) => void;
    setEditorObject: (editor: any) => void;
    editor: any;
    hasChanges: boolean;
    handleSave: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
    autoSaveTimer: number;
    activeSidePanel: SidePanelTypes;
    setActiveSidePanel: (x: SidePanelTypes) => void;
    selectedAction?: DocumentActions;
    setSelectedAction: (x?: DocumentActions) => void;
    signingStatus?: DocumentSigningStatusType;
    setSigningStatus: (x: DocumentSigningStatusType) => void;
    updateChangeLog: (x: Array<TrackChangesEntry>) => void;
    handleClickInEditor: (e: any) => void;
    updateSmartField: (field: RecapSingleFieldInterface) => void;
    updateSmartFieldList: (x: Array<RecapSingleFieldInterface>) => void;
    fetchData: () => void;
    finalizeDocument: () => void;
};

type SidePanelTypes = "change-log" | "smart-fields";

export const froalaConfigMain = {
    attribution: false,
    documentReady: true,
    charCounterCount: false,
    key: process.env.FROALA_KEY,
    quickInsertEnabled: false,
    tableInsertHelper: false,
    wordPasteModal: false,
    tableEditButtons: [],
    disableRightClick: true,
    keepFormatOnDelete: true,
    pasteDeniedTags: ["u"],
    pastePlain: true,
    tableResizer: false,
    toolbarSticky: true,
    toolbarButtons: {
        actions: {
            buttons: ["undo"],
        },
        zoom: {
            buttons: ["zoomIn", "zoomOut"],
            align: "right",
        },
    },
    enter: FroalaObject.ENTER_P,
    pluginsEnabled: [
        "fontFamily",
        "fontSize",
        "inlineStyle",
        "inlineClass",
        "lineHeight",
        "lists",
        "paragraphFormat",
        "paragraphStyle",
        "table",
        "wordPaste",
        "quickInsert",
        "align",
        "codeBeautifier",
        "codeView",
        "colors",
        "entities",
        "image",
        "link",
        "specialCharacters",
    ],
    linkEditButtons: [],
};

export const froalaConfigFinal = {
    attribution: false,
    documentReady: false,
    charCounterCount: false,
    key: process.env.FROALA_KEY,
    heightMax: "calc(100vh - 244px)",
};

export const DocumentEditorContext = createContext<DocumentEditorContextType>(null!);

function useDocumentEditor() {
    return useContext(DocumentEditorContext);
}

type DocumentActions =
    | "finalize"
    | "export"
    | "request-signatures"
    | "cancel-signing"
    | "signing-status";

export interface ClickInEditorInterface {
    el: Element;
    type: "smart-field" | "change";
    data_id?: string;
}

type DocumentEditorProviderProps = {
    document_id: string;
    fetchApiCall?: () => Promise<{ data: GlobalDocumentInterface }>;
    saveApiCall?: () => Promise<boolean>;
} & ProviderWithChildren;

function DocumentEditorProvider({
    children,
    document_id,
    fetchApiCall,
}: DocumentEditorProviderProps) {
    const echo = window.Echo,
        { meta_data, permissions, case_file_id } = useCaseFiles(),
        changeLogV2 = ChangeLogV2.getInstance(),
        user = useAuth().state.user;

    const [activeUsers, setActiveUsers] = useState<Array<ActiveUsersInterface>>([]);
    const [document, setDocument] = useState<GlobalDocumentInterface>();
    const [changeLog, setChangeLog] = useState<Array<TrackChangesEntry>>([]);
    const [smartFields, setSmartFields] = useState<Array<RecapSingleFieldInterface>>([]);
    const [socketConnected, setSocketConnected] = useState<boolean>(false);
    const [activeCollaboratorId, setActiveCollaboratorId] = useState<string>("");
    const [newChanges, setNewChanges] = useState<boolean>(false);
    const froalaRef = useRef<any>(null);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [editor, setEditor] = useState<any>();
    const [activeSidePanel, setActiveSidePanel] = useState<SidePanelTypes>("smart-fields");
    const [selectedAction, setSelectedAction] = useState<DocumentActions>();
    const [signingStatus, setSigningStatus] = useState<DocumentSigningStatusType>();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [saveDocument] = useStoreManualSaveMutation();

    const { secondsRemaining: autoSaveTimer } = useInterval<null, void>(
        {
            fn: () => handleSave(),
        },
        null,
        AUTO_SAVE_TIMER,
    );

    const exposedValues: DocumentEditorContextType = {
        activeUsers: activeUsers,
        newChanges: newChanges,
        changeLog: changeLog,
        smartFields: smartFields,
        activeCollaboratorId: activeCollaboratorId,
        document_id: document_id,
        isUserActive: isUserActive,
        handleGrantAccess: handleGrantAccess,
        froalaConfigFinal: froalaConfigFinal,
        document: document,
        froalaConfig: froalaConfigMain,
        froalaRef: froalaRef,
        handleChangeMade: (x) => setHasChanges(x),
        setEditorObject: (editor: any) => setEditor(editor),
        editor: editor,
        hasChanges: hasChanges,
        handleSave: handleSave,
        autoSaveTimer: autoSaveTimer,
        activeSidePanel: activeSidePanel,
        setActiveSidePanel: (x: SidePanelTypes) => setActiveSidePanel(x),
        selectedAction: selectedAction,
        setSelectedAction: (x) => setSelectedAction(x),
        signingStatus: signingStatus,
        setSigningStatus: (x) => setSigningStatus(x),
        updateChangeLog: (x) => setChangeLog(x),
        handleClickInEditor: handleClickInEditor,
        updateSmartField: updateSmartField,
        updateSmartFieldList: (x) => setSmartFields(x),
        fetchData: init,
        finalizeDocument: () =>
            document &&
            setDocument({
                ...document,
                finalized: true,
            }),
    };

    useEffect(() => {
        init();
        setSocketConnected(false);

        return () => {
            echo.leaveAllChannels();
        };
    }, [document_id]);

    useEffect(() => {
        determineActiveUser();
    }, [activeUsers]);

    async function init() {
        if (!fetchApiCall) {
            const res = await fetchSingleDocument(document_id, case_file_id);
            setDocument(res.data);
            setActiveSidePanel(res.data.is_smart ? "smart-fields" : "change-log");
            setChangeLog(res.data.change_log ?? []);
            setSmartFields(res.data.smart_fields ?? []);
            setSigningStatus(res.data.signing_progress);
        } else {
            const res = await fetchApiCall();
            setDocument(res.data);
            setActiveSidePanel(res.data.is_smart ? "smart-fields" : "change-log");
            setChangeLog(res.data.change_log ?? []);
            setSmartFields(res.data.smart_fields ?? []);
            setSigningStatus(res.data.signing_progress);
        }
    }

    const { setActivePage } = useCaseFiles();

    useEffect(() => {
        if (document) {
            if (user && !socketConnected) {
                setupSockets(user);
            }

            setActivePage(document.type === "standard_form" ? "document" : "other-documents");
        }

        if (document && user && !socketConnected) {
            setupSockets(user);
        }
    }, [document, user]);

    function updateSmartField(field: RecapSingleFieldInterface) {
        const index = smartFields.findIndex((x) => x.id === field.id),
            updatedList = [...smartFields.slice(0, index), field, ...smartFields.slice(index + 1)];

        setHasChanges(true);
        setSmartFields(updatedList);
    }

    function setupSockets(user: TenantUser) {
        let socketChannel = `${user.tenant_id}.documents.${document_id}`;

        if (meta_data?.additional_data.socket_channel) {
            socketChannel =
                meta_data?.additional_data.socket_channel + "--supply-chain-editor." + document_id;
        }

        echo.join(socketChannel)
            .here((users: Array<ActiveUsersInterface>) => {
                setActiveUsers(users);
            })
            .joining(userJoined)
            .leaving(userLeft)
            .listen(".access-granted", (e: { collaborator_id: string }) => {
                accessGranted(e.collaborator_id);
                setIsSaving(false);
            })
            .listen(".finalized", () => init())
            .listen(".changes-pushed", handleNewChanges);

        setSocketConnected(true);
    }

    function determineActiveUser() {
        if (activeUsers.length) {
            try {
                const prevActiveUserId = activeCollaboratorId;
                const activeUserId = activeUsers
                    .filter((x) => x.allowed_to_edit)
                    .sort((a, b) => a.user_joined - b.user_joined)[0]["id"];
                setActiveCollaboratorId(activeUserId);

                if (prevActiveUserId !== activeUserId && activeUserId === user?.id) {
                    init();
                } else {
                    setIsSaving(false);
                }
            } catch (e) {
                setActiveCollaboratorId("");
            }
        }
    }

    function userJoined(user: ActiveUsersInterface) {
        if (activeUsers.filter((u) => u.id === user.id).length) {
            setActiveUsers(
                activeUsers
                    .map((u) => {
                        if (u.id === user.id) {
                            return { ...u, user_joined: user.user_joined };
                        } else {
                            return u;
                        }
                    })
                    .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
            );
        } else {
            setActiveUsers((prev) =>
                [...prev, user].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
            );
        }
    }

    function userLeft(user: ActiveUsersInterface) {
        setActiveUsers((prev) =>
            prev
                .filter((u) => u.id !== user.id)
                .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
        );
    }

    async function handleGrantAccess(value: string): Promise<void> {
        if (document_id) {
            await setActiveCollaborator(
                document_id,
                value,
                "documents",
                undefined,
                meta_data?.additional_data.socket_channel,
            );
        }
    }

    async function accessGranted(collaborator_id: string) {
        await init();

        setActiveCollaboratorId(collaborator_id);
    }

    function isUserActive(): boolean {
        if (document?.finalized) {
            return false;
        }
        if (!permissions?.edit_documents) {
            return false;
        }
        if (activeUsers.length <= 1) {
            return true;
        } else {
            return activeCollaboratorId === user?.id;
        }
    }

    function handleNewChanges() {
        setNewChanges(true);
    }

    function handleClickInEditor(e: any) {
        try {
            const elementAmdType = getElementAndType(e);

            if (elementAmdType) {
                if (elementAmdType?.type === "smart-field" && document?.is_smart) {
                    setActiveSidePanel("smart-fields");

                    setTimeout(() => {
                        const node = window.document.querySelector(
                            `[data-id="textarea-id-${elementAmdType.data_id}"]`,
                        ) as HTMLTextAreaElement;

                        if (node) {
                            node.scrollIntoView({ behavior: "smooth", block: "center" });
                            node.focus();
                            node.selectionStart = node.value.length;
                            node.selectionEnd = node.value.length;
                        }
                    }, 250);
                }

                if (elementAmdType.type === "change") {
                    setActiveSidePanel("change-log");

                    setTimeout(() => {
                        let identifier = elementAmdType.data_id;

                        if (
                            "hasAttribute" in elementAmdType.el &&
                            elementAmdType.el.hasAttribute("data-tracking") &&
                            elementAmdType.el.firstElementChild &&
                            "hasAttribute" in elementAmdType.el.firstElementChild &&
                            elementAmdType.el.firstElementChild.hasAttribute("data-change-type") &&
                            elementAmdType.el.firstElementChild.getAttribute("data-change-type") ===
                                "deletion"
                        ) {
                            identifier =
                                elementAmdType.el.firstElementChild.getAttribute(
                                    "data-identifier",
                                ) ?? identifier;
                        }

                        const node = window.document?.querySelector(
                            `[id="change-log-id-${identifier}"]`,
                        ) as HTMLElement;

                        if (node) {
                            Array.from(
                                window.document.getElementsByClassName("cl-highlight"),
                            ).forEach((element) => element.classList.remove("dl-highlight"));

                            node.classList.add("cl-highlight");
                            setTimeout(() => node.classList.remove("cl-highlight"), 3000);

                            node.scrollIntoView({ behavior: "smooth", block: "center" });
                        }
                    }, 250);
                }
            }

            return void 0;
        } catch (e) {
            return void 0;
        }
    }

    function getElementAndType(e: any): ClickInEditorInterface | null {
        if (
            e.target.tagName === "A" ||
            (e.target.parentElement && e.target.parentElement.tagName === "A")
        ) {
            const target = e.target.tagName === "A" ? e.target : e.target.parentElement;

            if ("hasAttribute" in target && "getAttribute" in target) {
                return {
                    el: target,
                    data_id: target.getAttribute("data-recap-id"),
                    type: "smart-field",
                };
            }
            return null;
        }

        if (
            e.target.tagName === "SPAN" ||
            (e.target.parentElement && e.target.parentElement.tagName === "SPAN")
        ) {
            const target = e.target.tagName === "SPAN" ? e.target : e.target.parentElement;

            if ("hasAttribute" in target && "getAttribute" in target) {
                return {
                    el: target,
                    data_id: target.getAttribute("data-identifier"),
                    type: "change",
                };
            }
        }

        return null;
    }

    async function handleSave(e?: React.MouseEvent<HTMLButtonElement>) {
        if (editor && document_id && document && !isSaving && (hasChanges || e)) {
            setIsSaving(true);

            try {
                editor.selection.save();
                const content = editor.html.get(true);

                const changes = await changeLogV2.syncChanges(changeLog);

                if (content === "" || content === '<div class="fr-document"></div>') {
                    return;
                }

                await saveDocument({
                    id: document_id,
                    case_file_id: document.case_file_id,
                    content: content,
                    smart_fields: smartFields,
                    changes: changes,
                }).unwrap();

                setChangeLog(
                    [
                        ...changeLog,
                        ...changes.map((x) => {
                            return {
                                ...x,
                                timestamp: x.timestamp * 1000,
                            };
                        }),
                    ]
                        .sort((a, b) => b.timestamp - a.timestamp)
                        .map((x) => {
                            return {
                                ...x,
                                case_file_id: null,
                                created_at: x.created_at
                                    ? x.created_at
                                    : fromUnixTime(x.timestamp / 1000),
                                diff: x.value,
                                user: user?.name,
                            };
                        }),
                );

                setHasChanges(false);

                if (e) {
                    toast.success("Saved the document");
                }
            } catch (e) {
                toast.error("Save failed");
            }

            setIsSaving(false);
        }
    }

    return (
        <DocumentEditorContext.Provider value={exposedValues}>
            {children}
        </DocumentEditorContext.Provider>
    );
}

export { useDocumentEditor, DocumentEditorProvider };
