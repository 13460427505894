import { ChatChannelProps } from "../../types/chat";
import { useChat } from "../../lib/providers/chat-provider";
import React, { Fragment } from "react";
import { Typography } from "../ui/typography";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../ui/hover-card";
import { Button } from "../ui/button";
import { format, formatDistance } from "date-fns";
import { CalendarDays } from "lucide-react";

function ChatChannel({ channel }: ChatChannelProps) {
    const { activeChannel, updateActiveChannel } = useChat();

    return (
        <div
            className="w-full border-solid border-primary px-4 py-2 flex flex-col data-[active=true]:bg-muted/75 data-[active=true]:border-l-2 data-[active=false]:ml-[2px] cursor-pointer"
            data-active={channel.channel_id === activeChannel?.channel_id}
            onClick={() => updateActiveChannel(channel)}
        >
            <div className="flex w-full items-center justify-between">
                <Typography text={channel.channel_name} style="medium" />
                <span
                    className="flex h-2 w-2 rounded-full bg-sky-500 mr-2 text-xs text-white"
                    data-active={channel.channel_id === activeChannel?.id}
                />
            </div>
            {channel.last_message ? (
                <Fragment>
                    <HoverCard>
                        <HoverCardTrigger asChild>
                            <Button variant="none" className="p-0 h-4 justify-start">
                                <Typography
                                    text={formatDistance(
                                        new Date(channel.last_message.timestamp * 1000),
                                        new Date(),
                                        {
                                            addSuffix: true,
                                            includeSeconds: true,
                                        },
                                    )}
                                    style="muted"
                                    className="text-[12px]"
                                />
                            </Button>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-auto" align="start">
                            <div className="flex items-center pt-2">
                                <CalendarDays className="mr-2 h-4 w-4 opacity-70" />{" "}
                                <span className="text-xs text-muted-foreground">
                                    {format(
                                        new Date(channel.last_message.timestamp * 1000),
                                        "dd-MM-yyyy HH:mm:ss",
                                    )}
                                </span>
                            </div>
                        </HoverCardContent>
                    </HoverCard>
                    <Typography
                        text={channel.last_message.message}
                        style="p"
                        className="truncate max-w-[24ch] mt-2"
                    />
                </Fragment>
            ) : (
                <Typography
                    text="No messages yet"
                    style="p"
                    className="truncate max-w-[24ch] mt-2"
                />
            )}
        </div>
    );
}

export default ChatChannel;
