import React from "react";
import Chat from "../chat-panel/chat";
import Notifications from "../notifications-panel/notifications";
import AccountMenu from "../account-menu/account-menu";
import Logo from "../logo/logo";

function TopBar() {
    return (
        <header className="h-12 z-30 w-screen bg-foreground fixed items-stretch flex justify-end">
            <Logo className={"left-0 top-0 flex items-center justify-center w-12 h-12 fixed"} />
            <div className={"border-b ml-12 flex-grow flex items-center justify-end px-2"}>
                <div className="flex">
                    <Notifications />
                    <Chat />
                    <AccountMenu />
                </div>
            </div>
        </header>
    );
}

export default TopBar;
