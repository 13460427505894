import { getFroalaSelectionDetails, moveToNextNode } from "./base";
import * as Sentry from "@sentry/react";

/**
 * Retrieves the editing context based on a given anchor node and offset.
 * This context is essential for choosing the appropriate DOM modifications
 * during text editing operations.
 *
 * @param {Node} anchor - The anchor node from which the context is derived.
 * @param {number} offset - The offset within the anchor node used to determine
 *                          the precise location for DOM operations.
 * @returns {{ anchorNode: Node; charIndexed: boolean }} An object containing
 *          the anchor node and a boolean indicating whether the character
 *          index should be used directly.
 */
export function retrieveEditorContext(
    anchor: Node,
    offset: number,
): { anchorNode: Node; charIndexed: boolean } {
    let anchorReturn = anchor;

    // Determine if the anchor node is a text node
    if (anchorReturn.nodeType === Node.TEXT_NODE) {
        return {
            anchorNode: anchorReturn,
            charIndexed: true,
        };
    } else if (
        // Handle cases where the anchor has child nodes
        anchorReturn.childNodes &&
        anchorReturn.childNodes.length >= offset
    ) {
        anchorReturn = anchorReturn.childNodes[offset > 0 ? offset - 1 : 0];

        const isFaultyAnchorNode =
            isFrMarker(anchorReturn) ||
            (anchorReturn.nodeType === Node.TEXT_NODE && !anchorReturn.textContent);
        // Check if the new anchor node is a marker or empty text node
        if (isFaultyAnchorNode) {
            const switchNode = repairFroalaHell(anchorReturn);
            if (switchNode) {
                anchorReturn = switchNode;
            } else {
                Sentry.captureMessage("Failed to swtich from faulty anchor", "error");
            }
        }

        return {
            anchorNode: anchorReturn,
            charIndexed: false,
        };
    }

    return {
        anchorNode: anchorReturn,
        charIndexed: false,
    };
}

// Recursive helper to find first non fr-marker node while traversing left
export function repairFroalaHell(anchor: Node): Node | null {
    // Base case
    if (!anchor) {
        return null;
    }

    const newSelect = anchor.previousSibling;

    // Base case
    if (!newSelect) {
        return null;
    }

    const emptyTextNode = newSelect.nodeType === Node.TEXT_NODE && !newSelect.textContent;
    // If the selected sibling is of the desired type
    if (!isFrMarker(newSelect) && !emptyTextNode) {
        return newSelect;
    }

    return repairFroalaHell(newSelect);
}

/**
 * Finds the left and right sibling leaf nodes of a given anchor node in the DOM tree.
 * This function is particularly useful for identifying boundary nodes for text operations,
 * especially in complex DOM structures with nested elements and text nodes.
 *
 * @param {Node} anchor - The anchor node around which siblings are sought. This node
 *                        can be a text node, an element node, or any other node type.
 * @returns {{left: Node | null; right: Node | null}} An object containing the left and
 *          right sibling leaf nodes. Returns `null` for each side if no sibling exists.
 */
export function findSiblingLeafNodes(anchor: Node): {
    left: Node | null;
    right: Node | null;
} {
    let root, pivotText;
    // TODO: might there be some more efficient way of bounding the search based on ElementNextSibling?
    if (anchor.nodeType === Node.TEXT_NODE || anchor.nodeName === "A") {
        // since the a tags in our editor
        root = anchor.parentNode?.parentNode;
        pivotText = anchor;
    } else if (anchor.nodeType === Node.ELEMENT_NODE) {
        root = anchor.parentNode;
        // TODO: In the case that we do not receive a leaf node, we need to select a non fr-marker leaf as pivot
        for (let i = 0; i < anchor.childNodes.length; i++) {
            const child = anchor.childNodes[i];

            // Check if the child has the class "fr-marker"
            if (isFrMarker(child)) {
                continue;
            }

            // Assign non fr-marker as pivot
            pivotText = child;
        }
    } else {
        root = anchor;
        pivotText = anchor;
    }

    if (!root || !pivotText) {
        return { left: null, right: null };
    }

    // Create a TreeWalker to traverse the DOM
    const walker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, {
        acceptNode: (node) => {
            // Reject Froala markers
            if (isFrMarker(node)) {
                return NodeFilter.FILTER_REJECT;
            }
            // Accept leaf nodes (nodes without children)
            return node.childNodes.length === 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
        },
    });

    // Collect leaf nodes while preserving order
    const leafNodes: Node[] = [];
    while (walker.nextNode()) {
        // Do not select within same marked span as anchor
        if (checkAdditionSpan(anchor) || checkDeletionSpan(anchor)) {
            if (walker.currentNode.parentNode === anchor && walker.currentNode !== pivotText) {
                continue;
            }
        }

        const isEmptyTextNode =
            walker.currentNode.nodeType === Node.TEXT_NODE && !walker.currentNode.textContent;
        // Do not allow froala empty text node
        if (isEmptyTextNode) {
            continue;
        }
        leafNodes.push(walker.currentNode);
    }

    // Find the pivot node's position
    const pivotIndex = leafNodes.indexOf(pivotText);

    // Define left and right text node siblings
    let leftSibling: Node | null = null;
    let rightSibling: Node | null = null;

    // Check if left sibling exists and is a text node
    if (pivotIndex > 0) {
        const potentialLeftSibling = leafNodes[pivotIndex - 1];
        if (
            potentialLeftSibling.nodeType === Node.TEXT_NODE ||
            potentialLeftSibling.nodeName === "BR"
        ) {
            leftSibling = potentialLeftSibling;
        }
    }

    // Check if right sibling exists and is a text node
    if (pivotIndex >= 0 && pivotIndex < leafNodes.length - 1) {
        const potentialRightSibling = leafNodes[pivotIndex + 1];
        if (
            potentialRightSibling.nodeType === Node.TEXT_NODE ||
            potentialRightSibling.nodeName === "BR"
        ) {
            rightSibling = potentialRightSibling;
        }
    }

    return { left: leftSibling, right: rightSibling };
}

/**
 * Checks if a given node is part of an addition span or is an addition span itself.
 * It returns the addition span if the given node qualifies, otherwise, it returns null.
 *
 * @param {Node} node - The DOM node to check. This can be any node from which the
 *                      context or parentage needs to be assessed for addition marking.
 * @returns {Node | null} The addition span element if the node is part of or is an
 *                        addition span; otherwise, null.
 *
 */
export function checkAdditionSpan(node: Node): Node | null {
    if (!node) {
        return null;
    }

    // Case in which node refers to the text content of an addition span
    if (node.nodeType === Node.TEXT_NODE && node.parentNode) {
        if (node.parentNode.nodeType === Node.ELEMENT_NODE) {
            if ((node.parentNode as HTMLElement).getAttribute("data-change-type") === "addition") {
                return node.parentNode;
            }
        }
    }

    // Case in which node refers to an addition span
    if (node.nodeType === Node.ELEMENT_NODE) {
        if ((node as HTMLElement).getAttribute("data-change-type") === "addition") {
            return node;
        }
    }

    return null;
}

/**
 * Checks if a given node is part of a deletion span or is a deletion span itself.
 * This function returns the deletion span element if the node is within such a span
 * or is the span itself, otherwise returns null.
 *
 * @param {Node} node - The DOM node to check. This node can be any node in the document,
 *                      and the function will determine if it is part of a deletion span context.
 * @returns {Node | null} The deletion span element if the node is part of or is itself a
 *                        deletion span; otherwise, null.
 */
export function checkDeletionSpan(node: Node): Node | null {
    if (!node) {
        return null;
    }

    // Case in which node refers to the text content of an deletion span
    if (node.nodeType === Node.TEXT_NODE && node.parentNode) {
        if (node.parentNode.nodeType === Node.ELEMENT_NODE) {
            if ((node.parentNode as HTMLElement).getAttribute("data-change-type") === "deletion") {
                return node.parentNode;
            }
        }
    }

    // Case in which node refers to an deletion span
    if (node.nodeType === Node.ELEMENT_NODE) {
        if ((node as HTMLElement).getAttribute("data-change-type") === "deletion") {
            return node;
        }
    }

    return null;
}

/**
 * Determines whether a given DOM node is a Froala editor marker. Froala markers are used
 * within the Froala WYSIWYG editor to manage certain editing actions and mark specific points
 * in the DOM for operational purposes.
 *
 * @param {Node} node - The DOM node to check if it is classified as a Froala marker.
 * @returns {boolean} Returns `true` if the node is an element node and has the 'fr-marker'
 *                    class; otherwise, returns `false`.
 */
export function isFrMarker(node: Node): boolean {
    if (!node) {
        return false;
    }

    return (
        (node.nodeType === Node.ELEMENT_NODE &&
            (node as HTMLElement).classList.contains("fr-marker")) ||
        (node.parentNode?.nodeType === Node.ELEMENT_NODE &&
            (node.parentNode as HTMLElement)?.classList.contains("fr-marker"))
    );
}

// Recursively find if param `node` is part of a line number
export function isLineNumber(node: Node | null): boolean {
    if (!node) {
        return false;
    }

    if (
        node.nodeType === Node.ELEMENT_NODE &&
        (node as HTMLElement).hasAttribute("data-is-line-number")
    ) {
        return true;
    }

    return isLineNumber(node.parentNode);
}

export function countCharsBeforeMarker(span: Node): number {
    let count = 0; // Initialize character count
    let foundMarker = false; // Flag to track if the fr-marker was found

    function traverseNodes(node: Node): number {
        const children = node.childNodes; // Get all child nodes of the node
        let localCount = 0;

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (isFrMarker(child)) {
                foundMarker = true; // Set the flag to true when fr-marker is found
                break;
            } else if (child.nodeType === Node.TEXT_NODE && child.textContent) {
                localCount += child.textContent.length;
            } else {
                localCount += traverseNodes(child); // Recursively check for nested .fr-marker
                // If marker found in recursion, break the loop
                if (foundMarker) {
                    break;
                }
            }
        }

        return localCount;
    }

    count = traverseNodes(span);

    return foundMarker ? count : 0; // Return count only if fr-marker was found, otherwise 0
}

export function checkIfSelectionContainsDeletionSpan(selection: Selection): boolean {
    const selectionDetails = getFroalaSelectionDetails(selection);
    let currentNode: Node | null = null;

    if (!selectionDetails) {
        return false;
    }

    const { startNode, endNode, gca } = selectionDetails;

    // create a tree walker to traverse the gca for only element nodes
    // next filter filter all nodes that aren't deletion nodes
    const treeWalker = document.createTreeWalker(gca, NodeFilter.SHOW_TEXT, {
        acceptNode: function (node) {
            if (!isLineNumber(node) && !isFrMarker(node)) {
                return NodeFilter.FILTER_ACCEPT;
            }
            return NodeFilter.FILTER_SKIP;
        },
    });

    currentNode = startNode;
    treeWalker.currentNode = startNode;

    while (currentNode) {
        const isEndNode = currentNode === endNode;

        const checkDeletion = checkDeletionSpan(currentNode);
        if (checkDeletion) {
            // if the current node is a deletion we know the selection contains a deletion so we can
            // break the loop
            return true;
        }

        // continue the loop with the next node
        currentNode = moveToNextNode(treeWalker, isEndNode);
    }

    return false;
}

export function countCharsPrevSiblings(addition: Node, anchor: Node): number {
    if (
        !addition ||
        !anchor ||
        !anchor.textContent ||
        !checkAdditionSpan(addition) ||
        !addition.contains(anchor)
    ) {
        return 0;
    }

    let sum = 0;

    for (const child of addition.childNodes) {
        if (child === anchor) {
            break;
        }
        sum += child.textContent?.length ?? 0;
    }

    return sum;
}

// Returns true if a Node contains 2 Froala markers
export function containsFrMarkers(node: Node): boolean {
    if (!node) {
        return false;
    }

    let count = 0;

    for (const child of node.childNodes) {
        if (isFrMarker(child)) {
            count++;
        }
    }

    return count === 2;
}

export function isUserBR(node: Node): boolean {
    if (
        node.nodeName === "BR" &&
        (node as HTMLBRElement).getAttribute("data-change-type") === "addition"
    ) {
        return true;
    }
    return false;
}
