import styled from "styled-components";
import { FormButtonGroup, Input } from "../../Form/FormField/style";
import { ModalBody } from "../../../components/style-components/Modals/Finalize";

export const RiderTemplateContainer = styled(ModalBody)`
    padding: 0 3em;
    height: 100vh;
`;

export const RiderNameInputField = styled(Input)`
    width: 400px;
`;

export const BackToOverviewGroup = styled(FormButtonGroup)`
    width: 100%;
`;
