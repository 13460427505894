import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { RecapTemplateInterface } from "../Central/Pages/RecapTemplates/index.interface";
import { TableDropDownItems } from "../types/table";
import { Trash2 } from "lucide-react";
import DeleteRecapTemplate from "../Central/Pages/RecapTemplates/Modals/DeleteRecapTemplate";

const columns: ColumnDef<RecapTemplateInterface>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Name",
        },
    },
    {
        accessorKey: "data_count",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Amount of fields" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Amount of fields",
        },
    },
];

function tableDropDown(
    onDelete: (row: RecapTemplateInterface) => void,
): TableDropDownItems<RecapTemplateInterface> {
    return {
        label: "Actions",
        groups: [
            {
                actions: [
                    {
                        label: "Delete",
                        icon: <Trash2 className="mr-2 h-4 w-4 stroke-white" />,
                        dangerous: true,
                        element: (row, onClose) => (
                            <DeleteRecapTemplate row={row} onClose={onClose} onDelete={onDelete} />
                        ),
                        elementType: "dialog",
                    },
                ],
            },
        ],
    };
}

export { columns, tableDropDown };
