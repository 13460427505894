import { MenuDropDownProps } from "../../types/table";
import {
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuPortal,
    ContextMenuSeparator,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
} from "../ui/context-menu";
import { DropdownMenuLabel } from "../ui/dropdown-menu";
import React, { Fragment } from "react";
import { Button } from "../ui/button";

function DataTableContextMenuDropDown<Generic>({
    items,
    setSelectedAction,
    row,
}: MenuDropDownProps<Generic>) {
    return (
        <ContextMenuContent className="max-w-[200px]">
            <DropdownMenuLabel className="px-2 py-1.5 text-sm font-semibold">
                {items.label}
            </DropdownMenuLabel>
            <ContextMenuSeparator />
            {items.groups.map((group, index) => (
                <Fragment key={index}>
                    {group.actions
                        .filter((action) => (action?.hide ? !action.hide(row) : true))
                        .map((action, index) =>
                            action.children ? (
                                <div className="w-full justify-start px-2" key={index}>
                                    <ContextMenuSub>
                                        <ContextMenuSubTrigger>
                                            <div className="pl-1 flex">
                                                {action.icon}
                                                <span className="font-medium">
                                                    {" "}
                                                    {action.label}{" "}
                                                </span>
                                            </div>
                                        </ContextMenuSubTrigger>
                                        <ContextMenuPortal>
                                            <ContextMenuSubContent>
                                                {action.children.map((child, childindex) => (
                                                    <ContextMenuItem
                                                        className="justify-between pl-1.5"
                                                        key={childindex}
                                                    >
                                                        {child(row)}
                                                    </ContextMenuItem>
                                                ))}
                                            </ContextMenuSubContent>
                                        </ContextMenuPortal>
                                    </ContextMenuSub>
                                </div>
                            ) : (
                                <Button
                                    variant={action.dangerous ? "destructive" : "ghost"}
                                    size="sm"
                                    className="w-full justify-start"
                                    key={index}
                                    onClick={() => {
                                        setSelectedAction(action);
                                    }}
                                >
                                    <ContextMenuItem
                                        key={index}
                                        className={
                                            action.dangerous ? "bg-red-500 focus:bg-red-500" : ""
                                        }
                                    >
                                        {action.icon}
                                        <span className={action.dangerous ? "text-white" : ""}>
                                            {action.label}
                                        </span>
                                    </ContextMenuItem>
                                </Button>
                            ),
                        )}
                    {items.groups.length > 1 &&
                    index < items.groups.length - 1 &&
                    items.groups[index + 1] &&
                    items.groups[index + 1].actions.filter((action) =>
                        action?.hide ? !action.hide(row) : true,
                    ).length > 0 ? (
                        <ContextMenuSeparator />
                    ) : null}
                </Fragment>
            ))}
        </ContextMenuContent>
    );
}

export default DataTableContextMenuDropDown;
