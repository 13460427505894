import React, { useState } from "react";
import { Typography } from "../../components/ui/typography";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useResetPasswordMutation } from "../../api/tenant/authentication";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../../lib/usePageTitle";
import { determineTenantName } from "../../lib/helpers/tenant";

function ResetPassword() {
    const schema = z
        .object({
            username: z.string().min(2, {
                message: "Username must be at least 2 characters.",
            }),
            password: z.string().min(2, {
                message: "Password must be at least 2 characters.",
            }),
            confirm_password: z.string().min(2, {
                message: "Password must be at least 2 characters.",
            }),
        })
        .refine((data) => data.password === data.confirm_password, {
            message: "Passwords don't match",
            path: ["confirm_password"],
        });

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            username: "",
            password: "",
            confirm_password: "",
        },
    });

    const [errorMessage, setErrorMessage] = useState<string>();

    const [resetPassword] = useResetPasswordMutation();

    const { hash } = useParams<{ hash: string }>();

    const navigate = useNavigate();

    usePageTitle("Forgot Password");

    async function handleSubmit(values: z.infer<typeof schema>) {
        if (hash) {
            try {
                await resetPassword({
                    username: values.username,
                    password: values.password,
                    token: hash,
                }).unwrap();
                navigate(`/${determineTenantName()}/login`);
            } catch (e) {
                form.reset();
                setErrorMessage(e.data.message);
            }
        }
    }

    return (
        <div className="bg-login h-screen bg-cover bg-center bg-no-repeat">
            <div className="h-screen w-screen bg-zinc-900 bg-opacity-45 flex align items-center justify-center">
                <div className="w-[520px] bg-white p-8 flex flex-col">
                    <img
                        src="/images/logo.png"
                        alt="Ocean Recap"
                        className="h-24 mt-8 mb-8 self-center"
                    />
                    <div className="flex align items-center justify-cente">
                        <Typography text={"Reset password"} style="h3" />
                    </div>
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(handleSubmit)}
                            className="space-t-8 space-b-4"
                        >
                            <FormField
                                control={form.control}
                                name="username"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Username</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Password</FormLabel>
                                        <FormControl>
                                            <Input type="password" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="confirm_password"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Confirm Password</FormLabel>
                                        <FormControl>
                                            <Input type="password" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {errorMessage && <Typography text={errorMessage} style="warning" />}

                            <div className="flex flex-col">
                                <Button type="submit" className="mt-8">
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
