import { TenantUser } from "../../types/auth";
import { tenantApi } from "../tenantApi";

const organisationApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getOrganisationMembers: build.query<{ data: Array<TenantUser> }, void>({
            query: () => ({
                url: "/organisation/members",
            }),
            providesTags: (result: { data: Array<TenantUser> }) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "OrganisationMembers", id }) as const)
                    : []),
                { type: "OrganisationMembers" as const, id: "LIST" },
            ],
        }),
        inviteTeamMember: build.mutation<boolean, { name: string; email: string }>({
            query: (body) => ({
                url: "/organisation/members",
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["OrganisationMembers"],
        }),
        createAccount: build.mutation<
            boolean,
            { username: string; password: string; confirm_password: string; id: string }
        >({
            query: ({ id, ...body }) => ({
                url: `/organisation/create-account/${id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["OrganisationMembers"],
        }),
    }),
});

export const {
    useGetOrganisationMembersQuery,
    useInviteTeamMemberMutation,
    useCreateAccountMutation,
} = organisationApi;
