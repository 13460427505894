import {
    NewRecapClauseInterface,
    NewRecapFieldInterface,
    RecapClauseInterface,
    RecapModalInterface,
} from "../../index.interface";
import React, { Fragment, useState } from "react";
import { Form, Formik } from "formik";
import {
    Bar,
    CancelButton,
    FormButtonGroup,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
} from "../../../../Form/FormField/style";
// import {
//     CheckBox,
//     CheckBoxLabel,
//     CheckBoxWrapper,
//     InputGroupLabel,
//     Selection,
//     SelectionFilterContainer,
// } from "../../../../../Tenant/Pages/Documents/Style";
import {
    createRecapClause,
    createRecapField,
    updateRecapClause,
    updateRecapField,
} from "../../../../Admin/Recap";
import { Modals } from "../../../../Components/Modals";
import { ModalBody } from "../../../../../components/style-components/Modals/Finalize";
import {
    CheckBox,
    CheckBoxLabel,
    CheckBoxWrapper,
    InputGroupLabel,
    Selection,
    SelectionFilterContainer,
} from "../../../../Form/Documents/Style";
import { LoadingSpinner } from "../../../../Components/Loading";
import { RecapSingleFieldInterface } from "../../../RecapTemplates/index.interface";

export function NewRecapFieldModal({
    open,
    onClose,
    categories,
    row,
    type,
}: RecapModalInterface<RecapSingleFieldInterface | RecapClauseInterface>) {
    const [clicked, setClicked] = useState<boolean>(false);

    const initialValues: NewRecapFieldInterface | NewRecapClauseInterface = {
        name: row?.name ?? "",
        data_id: row?.data_id ?? "",
        category_id: row?.category_id ?? "",
        value: row?.value ?? "",
        is_fleet: Boolean(row?.is_fleet) ?? false,
        data_type: type === "field" ? row?.data_type ?? "text" : "clause",
    };

    function handleSubmit(values: NewRecapFieldInterface | RecapClauseInterface) {
        setClicked(true);
        if (type === "field") {
            if (row) {
                updateRecapField(row.id, values as NewRecapFieldInterface)
                    .then(() => setClicked(false))
                    .then(onClose);
            } else {
                createRecapField(values as NewRecapFieldInterface)
                    .then(() => setClicked(false))
                    .then(onClose);
            }
        }
        if (type === "clause") {
            if (row) {
                updateRecapClause(row.id, values as RecapClauseInterface)
                    .then(() => setClicked(false))
                    .then(onClose);
            } else {
                createRecapClause(values as RecapClauseInterface)
                    .then(() => setClicked(false))
                    .then(onClose);
            }
        }
    }

    function formatSelectOptions(): Array<{ label: string; value: string }> {
        return (
            categories?.map((x) => {
                return {
                    label: x.name,
                    value: x.id,
                };
            }) ?? []
        );
    }

    const recapFieldDataTypes = [
        {
            label: "text",
            value: "text",
        },
        {
            label: "date",
            value: "date",
        },
        {
            label: "number",
            value: "number",
        },
    ];

    return (
        <Modals
            head={
                <h1>
                    {!row ? "Add " : "Update "}a recap {type}
                </h1>
            }
            body={
                <ModalBody>
                    {!clicked && (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => handleSubmit(values)}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    <InputGroup>
                                        <InputGroupLabel>Select field category</InputGroupLabel>
                                        <SelectionFilterContainer>
                                            <Selection
                                                onChange={(v: { label: string; value: string }) =>
                                                    setFieldValue("category_id", v.value)
                                                }
                                                options={formatSelectOptions()}
                                                defaultValue={formatSelectOptions().find(
                                                    (x) => x.value === values.category_id,
                                                )}
                                                classNamePrefix="react-select"
                                                isSearchable={false}
                                                autoFocus={false}
                                            />
                                        </SelectionFilterContainer>
                                        {errors.category_id && touched.category_id && (
                                            <StyledInlineErrorMessage>
                                                {errors.category_id}
                                            </StyledInlineErrorMessage>
                                        )}
                                    </InputGroup>
                                    {type === "field" && (
                                        <InputGroup>
                                            <InputGroupLabel>
                                                Select field data type
                                            </InputGroupLabel>
                                            <SelectionFilterContainer>
                                                <Selection
                                                    onChange={(v: {
                                                        label: string;
                                                        value: string;
                                                    }) => setFieldValue("data_type", v.value)}
                                                    options={recapFieldDataTypes}
                                                    defaultValue={
                                                        recapFieldDataTypes.find(
                                                            (x) => x.value === values.data_type,
                                                        ) ?? recapFieldDataTypes[0]
                                                    }
                                                    classNamePrefix="react-select"
                                                    isSearchable={false}
                                                    autoFocus={false}
                                                />
                                            </SelectionFilterContainer>
                                            {errors.data_type && touched.data_type && (
                                                <StyledInlineErrorMessage>
                                                    {errors.data_type}
                                                </StyledInlineErrorMessage>
                                            )}
                                        </InputGroup>
                                    )}
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            name="name"
                                            autoCorrect="off"
                                            autoComplete="name"
                                            valid={touched.name && !errors.name}
                                            error={touched.name && errors.name}
                                        />
                                        <Bar />
                                        <Label htmlFor="name">Display name</Label>
                                        {errors.name && touched.name && (
                                            <StyledInlineErrorMessage>
                                                {errors.name}
                                            </StyledInlineErrorMessage>
                                        )}
                                    </InputGroup>
                                    {type === "clause" && (
                                        <InputGroup>
                                            <p>Clause value</p>
                                            <textarea
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        "value",
                                                        e.target.value.replace(/\n/g, "<br>"),
                                                    )
                                                }
                                                cols={60}
                                                rows={6}
                                                defaultValue={values.value ?? ""}
                                            />
                                        </InputGroup>
                                    )}
                                    {type === "field" && (
                                        <Fragment>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    name="data_id"
                                                    autoCorrect="off"
                                                    autoComplete="name"
                                                    valid={touched.data_id && !errors.data_id}
                                                    error={touched.data_id && errors.data_id}
                                                />
                                                <Bar />
                                                <Label htmlFor="data_id">Data Id</Label>
                                                {errors.data_id && touched.data_id && (
                                                    <StyledInlineErrorMessage>
                                                        {errors.data_id}
                                                    </StyledInlineErrorMessage>
                                                )}
                                            </InputGroup>
                                            <InputGroup>
                                                <CheckBoxWrapper>
                                                    <span>Fleet field:</span>
                                                    <CheckBox
                                                        id="fleet"
                                                        type="checkbox"
                                                        onClick={(e: any) =>
                                                            setFieldValue(
                                                                "is_fleet",
                                                                e.target.checked === true ? 1 : 0,
                                                            )
                                                        }
                                                        defaultChecked={Boolean(row?.is_fleet)}
                                                    />
                                                    <CheckBoxLabel htmlFor="fleet" />
                                                </CheckBoxWrapper>
                                            </InputGroup>
                                        </Fragment>
                                    )}
                                    <FormButtonGroup>
                                        <CancelButton type="button" onClick={onClose}>
                                            Cancel
                                        </CancelButton>
                                        <SubmitButton type="submit">
                                            {!row ? "Create" : "Update"}
                                        </SubmitButton>
                                    </FormButtonGroup>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {clicked && (
                        <Fragment>
                            <LoadingSpinner />
                        </Fragment>
                    )}
                </ModalBody>
            }
            open={open}
            closeOnClick={true}
            onClose={onClose}
            size="small"
        />
    );
}
