import React, { Fragment, useEffect, useState } from "react";
import { fetchRiderTemplates } from "../../Admin/Templates";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns } from "../../../config/riders";
import { DashboardTopBarContainerContent } from "../Dashboard/Style";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import { useNavigate } from "react-router";
import { DataTableToolbarButton } from "../../../types/table";
import { EditRiderTemplateModal } from "./Modals/EditRiderTemplateModal";
import { TemplateItem } from "../../../types/Central";

export function RiderTemplatesPage() {
    const [data, setData] = useState<Array<TemplateItem>>([]);
    const [selectEdit, setSelectEdit] = useState<TemplateItem | undefined>(undefined);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    const navigate = useNavigate();

    useEffect(() => {
        if (data.length === 0) {
            fetchData();
        }
    }, []);

    function fetchData() {
        fetchRiderTemplates().then((data: { data: React.SetStateAction<TemplateItem[]> }) => {
            setData(data.data);
        });
    }

    function handleOnClick(row: TemplateItem) {
        setShowEditModal(true);
        setSelectEdit(row);
    }

    function newEntry() {
        navigate("new");
    }

    const buttons: Array<DataTableToolbarButton> = [
        {
            label: "new rider template",
            action: () => newEntry(),
        },
    ];

    function handleClose() {
        setSelectEdit(undefined);
        setShowEditModal(false);
        fetchData();

        const active = document.getElementsByClassName("active-table-row")[0];
        if (active) {
            active.classList.remove("active-table-row");
        }
    }

    return (
        <Fragment>
            {selectEdit && (
                <EditRiderTemplateModal
                    open={showEditModal}
                    onClose={handleClose}
                    template={selectEdit}
                />
            )}
            <Fragment>
                <DashboardTopBarContainerContent>
                    <div className="flex flex-col h-full">
                        <DataTableToolbar
                            table={table}
                            title="Rider clauses"
                            searchableColumns={["name", "description", "active"]}
                            buttons={buttons}
                        />
                        <div className="flex py-4 h-full">
                            <DataTable columns={columns} table={table} onClick={handleOnClick} />
                        </div>
                    </div>
                </DashboardTopBarContainerContent>
            </Fragment>
        </Fragment>
    );
}
