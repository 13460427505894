import Loading from "../../components/global/loading";
import React, { Fragment, useState } from "react";
import { Typography } from "../../components/ui/typography";
import { useGetArchiveCaseFilesQuery } from "../../api/tenant/archive";
import CaseFileArchiveTable from "./case-file-archive-table";
import { CaseFileIndex } from "../../types/case-files";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import CaseFileRestore from "./case-file-restore";
import { usePageTitle } from "../../lib/usePageTitle";

function CaseFileArchive() {
    const { data: archive, isLoading } = useGetArchiveCaseFilesQuery();
    const [selectedRow, setSelectedRow] = useState<CaseFileIndex>();

    usePageTitle("Charter Party Archive");

    return (
        <Loading
            loaded={!isLoading}
            child={
                <Fragment>
                    {selectedRow && (
                        <Dialog
                            open={selectedRow !== undefined}
                            onOpenChange={() => setSelectedRow(undefined)}
                        >
                            <DialogContent>
                                <CaseFileRestore
                                    row={selectedRow}
                                    onClose={() => setSelectedRow(undefined)}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                    <div className="flex flex-col justify-between space-y-2 bg-foreground p-4 border border-solid border-slate-200">
                        <div className="flex items-center justify-between w-full">
                            <Typography text="Charter Party Archive" style="h2" className="pb-0" />
                        </div>
                    </div>
                    <div className=" w-full flex items-stretch h-full">
                        <div className="bg-foreground p-4 rounded-sm">
                            <CaseFileArchiveTable
                                data={archive?.data ?? []}
                                onClick={(row) => setSelectedRow(row)}
                            />
                        </div>
                    </div>
                </Fragment>
            }
        />
    );
}

export default CaseFileArchive;
