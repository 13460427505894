import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import React, { Fragment } from "react";
import { toast } from "sonner";
import { changeVisibilityTenant } from "../../../Admin/Tenants";
import { TenantAdminInterface } from "../../../../types/Central";

export interface HideActionInterface {
    row: TenantAdminInterface;
    onClose: () => void;
}

export function HideAction({ row, onClose }: HideActionInterface) {
    async function handleSubmit() {
        try {
            await changeVisibilityTenant(row.id, "hide");

            onClose();
            toast.message(`Hidden ${row.id}`);
        } catch (e) {
            toast.error("Something went wrong while hiding the tenant");
        }
    }

    return (
        <Fragment>
            <DialogHeader>
                <DialogTitle>Hide {row.id}</DialogTitle>
                <DialogDescription>Are you sure?</DialogDescription>
            </DialogHeader>
            <DialogFooter className="mt-4">
                <Button type="button" onClick={onClose} variant="default">
                    Cancel
                </Button>
                <Button type="submit" onClick={handleSubmit} variant="destructive">
                    Submit
                </Button>
            </DialogFooter>
        </Fragment>
    );
}
