import React, { Fragment, useState } from "react";
import { useGetInvoicesQuery } from "../../api/tenant/billing";
import Loading from "../../components/global/loading";
import { DataTableToolbarButton } from "../../types/table";
import { Typography } from "../../components/ui/typography";
import BillingTable from "./billing-table";
import ProcessInvoice from "./process-invoice";
import BillingInfo from "./billing-info";
import { usePageTitle } from "../../lib/usePageTitle";

function Billing() {
    const [showProcessInvoiceDialog, setShowProcessInvoiceDialog] = useState<boolean>(false);
    const { data: invoices, isLoading } = useGetInvoicesQuery();

    usePageTitle("Billing");

    const buttons: Array<DataTableToolbarButton> = [
        {
            label: "Process invoice",
            action: () => setShowProcessInvoiceDialog(true),
        },
    ];

    return (
        <Fragment>
            <Loading
                loaded={!isLoading}
                child={
                    <Fragment>
                        {invoices?.data.filter((x) => x.status === "open") && (
                            <ProcessInvoice
                                open={showProcessInvoiceDialog}
                                onClose={() => setShowProcessInvoiceDialog(false)}
                                invoice={invoices.data.filter((x) => x.status === "open")[0]}
                            />
                        )}
                        <div className="flex flex-col justify-between space-y-2 bg-foreground p-4 border border-solid border-slate-200 ">
                            <div className="flex items-center justify-between w-full">
                                <Typography text="Billing" style="h2" className="pb-0" />
                            </div>
                        </div>
                        <div className="w-full h-full flex">
                            <div className="w-1/4 pr-0 flex items-stretch h-full">
                                <div className="bg-foreground p-4 rounded-sm flex flex-1">
                                    <BillingInfo />
                                </div>
                            </div>
                            <div className="ml-4 w-3/4 flex items-stretch h-full">
                                <div className="bg-foreground p-4 rounded-sm">
                                    {invoices?.data && (
                                        <BillingTable data={invoices.data} buttons={buttons} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            />
        </Fragment>
    );
}

export default Billing;
