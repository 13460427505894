import TypedFroalaEditor from "froala-editor-typings";
import * as Sentry from "@sentry/react";
import Command from ".";
import { TenantUser } from "../../../types/auth";
import {
    checkDeletionSpan,
    containsFrMarkers,
    isLineNumber,
    retrieveEditorContext,
} from "../Utility/inspectDOM";
import { handleLineBreak } from "../Utility/modifyDOM";
import { handleSelectionDeletion } from "../Utility/selectionDeletion";
import {
    saveEditorSelectionAndAddEditorStateToMemento,
    setCursorAfterElement,
} from "../Utility/base";
import Memento from "../Memento";

class EnterCommand implements Command {
    private editor: TypedFroalaEditor.FroalaEditor;
    private event: KeyboardEvent;
    private user: TenantUser;
    private previousState: Memento;
    private spanId: string | null;

    constructor(editor: TypedFroalaEditor.FroalaEditor, event: KeyboardEvent, user: TenantUser) {
        this.editor = editor;
        this.event = event;
        this.user = user;
        this.previousState = this.saveState();
    }

    execute(): void {
        this.event.preventDefault();
        this.event.stopPropagation();
        this.insertLineBreak();
    }

    undo(): void {
        this.restoreState(this.previousState);
    }

    saveState(): Memento {
        return saveEditorSelectionAndAddEditorStateToMemento(this.editor);
    }

    restoreState(memento: Memento): void {
        this.editor.html.set(memento.getState()); // Restore the HTML content
    }

    get previous(): Memento {
        return this.previousState;
    }

    get id(): string | null {
        return this.spanId;
    }

    private insertLineBreak(): void {
        const selection: Selection = this.editor.selection.get();
        const enterNode = document.createElement("br");
        enterNode.setAttribute("data-change-type", "addition");

        if (selection.isCollapsed) {
            const anchor = selection.anchorNode;
            const anchorOffset = selection.anchorOffset;

            // Do nothing for safety
            if (!anchor) {
                return;
            }

            const { anchorNode, charIndexed } = retrieveEditorContext(anchor, anchorOffset);

            if (isLineNumber(anchorNode)) {
                return;
            }

            if (charIndexed) {
                const checkDeletion = checkDeletionSpan(anchorNode);
                if (checkDeletion) {
                    const cursorAtEndOfDeletion =
                        anchorOffset === checkDeletion.textContent?.length ||
                        (anchorOffset + 2 === checkDeletion.textContent?.length &&
                            containsFrMarkers(checkDeletion));
                    // If we are at the end of a deletion, allow typing
                    if (cursorAtEndOfDeletion) {
                        handleLineBreak(anchorNode, anchorOffset, "right");
                    }

                    // Disallow typing inside deletion span
                    return;
                }

                // Handles addition inside a node
                handleLineBreak(anchorNode, anchorOffset, "inside");
                return;
            } else {
                // In this case we are at a Node edge
                // Handles addition at an edge
                handleLineBreak(anchorNode, anchorOffset, "right");
                return;
            }
        } else {
            // Delete selection
            const { lastNode } = handleSelectionDeletion(selection, this.user!);

            // Place span and cursor
            if (lastNode) {
                const parentRef = lastNode.parentNode;
                const placeRef = lastNode.nextSibling;

                if (parentRef) {
                    // Insert new addition after anchor
                    parentRef.insertBefore(enterNode, placeRef);

                    // Set the cursor position after the new span
                    setCursorAfterElement(enterNode);
                }

                // newAdditionNode(lastNode, charTyped, this.user!);
            } else {
                // TODO: what if no last node?
                Sentry.captureMessage("Selection + Enter failed to return lastNode", "warning");
            }
        }
    }
}

export default EnterCommand;
