import { getUnixTime } from "date-fns";
import { RecapSingleFieldInterface, SmartFieldInterface } from "../../types/document-editor";
import { TenantUser } from "../../types/auth";

const randomNr = () => Math.floor(Math.random() * 999999);

export class DataAutomation {
    private static instance: DataAutomation;
    private _wrapper: Element | undefined;
    private _ref: any;
    private _user: TenantUser | undefined;
    private _formatting: boolean;

    public static getInstance(): DataAutomation {
        if (!DataAutomation.instance) {
            DataAutomation.instance = new DataAutomation();
        }

        return DataAutomation.instance;
    }

    public init(user: TenantUser, formatting: boolean) {
        this.user = user;
        this.formatting = formatting;
    }

    public registerReference(ref: any) {
        this.ref = ref;
    }

    public highlightNodeInEditor(node: Element) {
        const editorElement = this.getEditorElement();

        if (editorElement) {
            if (node.classList.contains("highlight")) {
                node.classList.remove("highlight");
            } else {
                DataAutomation.resetHighlightNodes(editorElement);

                node.classList.add("highlight");

                setTimeout(() => {
                    node.classList.remove("highlight");
                }, 3000);
            }
        }
    }

    // toggle highlight for the recap node inside the editor
    // if highlight is added, remove it after 3 seconds
    public highlight(item: SmartFieldInterface | RecapSingleFieldInterface) {
        const editorElement = this.getEditorElement();

        if (editorElement) {
            const recapElementList = this.getRecapElement(item);

            if (recapElementList) {
                if (recapElementList.length === 1) {
                    const recapElement = recapElementList[0] as Element;
                    if (recapElement) {
                        if (recapElement.classList.contains("recap-highlight")) {
                            recapElement.classList.remove("recap-highlight");
                        } else {
                            DataAutomation.resetHighlightNodes(editorElement);

                            recapElement.classList.add("recap-highlight");

                            setTimeout(() => {
                                recapElement.classList.remove("recap-highlight");
                            }, 3000);
                        }
                    }
                } else {
                    recapElementList.forEach((recapElement: Element) => {
                        if (recapElement) {
                            if (recapElement.classList.contains("recap-highlight")) {
                                recapElement.classList.remove("recap-highlight");
                            } else {
                                DataAutomation.resetHighlightNodes(editorElement, item.data_id);

                                recapElement.classList.add("recap-highlight");

                                setTimeout(() => {
                                    recapElement.classList.remove("recap-highlight");
                                }, 3000);
                            }
                        }
                    });
                }
            }
        }
    }

    // public async insertRecapValue(e: React.MouseEvent<SVGSVGElement>, item: SmartFieldInterface) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //
    //     const editorElement = this.getEditorElement();
    //
    //     if (editorElement) {
    //         DataAutomation.resetHighlightNodes(editorElement);
    //
    //         const recapElementList = this.getRecapElement(item);
    //
    //         if (recapElementList) {
    //             if (recapElementList.length === 1) {
    //                 const recapElement = recapElementList[0] as Element;
    //                 if (recapElement && this.user && item.value) {
    //                     this.insertIntoDom(recapElement, this.user, item.value);
    //                 }
    //             } else {
    //                 recapElementList.forEach((recapElement: Element) => {
    //                     if (recapElement && this.user && item.value) {
    //                         this.insertIntoDom(recapElement, this.user, item.value);
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //
    //     // give it some time to update the dom
    //     setTimeout(() => {
    //         return true;
    //     }, 200);
    // }

    // public async removeRecapValue(e: React.MouseEvent<SVGSVGElement>, item: SmartFieldInterface) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //
    //     const editorElement = this.getEditorElement();
    //
    //     if (editorElement) {
    //         DataAutomation.resetHighlightNodes(editorElement);
    //
    //         const recapElementList = this.getRecapElement(item);
    //
    //         if (recapElementList) {
    //             if (recapElementList.length === 1) {
    //                 const recapElement = recapElementList[0] as Element;
    //
    //                 if (recapElement && this.user && item.value) {
    //                     recapElement.removeAttribute("data-change-type");
    //                     recapElement.removeAttribute("data-identifier");
    //                     recapElement.removeAttribute("data-user");
    //                     recapElement.removeAttribute("data-timestamp");
    //                     recapElement.classList.remove("fr-highlight-change");
    //                     recapElement.innerHTML = "";
    //                 }
    //             } else {
    //                 recapElementList.forEach((recapElement: Element) => {
    //                     if (recapElement && this.user && item.value) {
    //                         recapElement.removeAttribute("data-change-type");
    //                         recapElement.removeAttribute("data-identifier");
    //                         recapElement.removeAttribute("data-user");
    //                         recapElement.removeAttribute("data-timestamp");
    //                         recapElement.classList.remove("fr-highlight-change");
    //                         recapElement.innerHTML = "";
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //
    //     // give it some time to update the dom
    //     setTimeout(() => {
    //         return true;
    //     }, 200);
    // }

    public insertAllRecapFields(items: Array<SmartFieldInterface>) {
        const editorElement = this.getEditorElement();

        if (editorElement) {
            DataAutomation.resetHighlightNodes(editorElement);

            items.map((item) => {
                const recapElementList = this.getRecapElement(item);

                if (recapElementList) {
                    if (recapElementList.length === 1) {
                        const recapElement = recapElementList[0] as Element;
                        if (recapElement && this.user && item.value) {
                            this.insertIntoDom(recapElement, this.user, item.value);
                        }
                    } else {
                        recapElementList.forEach((recapElement: Element) => {
                            if (recapElement && this.user && item.value) {
                                this.insertIntoDom(recapElement, this.user, item.value);
                            }
                        });
                    }
                }
            });
        }

        // give it some time to update the dom
        setTimeout(() => {
            return true;
        }, 200);
    }

    public updateRecapField(item: SmartFieldInterface | RecapSingleFieldInterface, value: string) {
        const editorElement = this.getEditorElement();
        if (editorElement) {
            DataAutomation.resetHighlightNodes(editorElement);

            const recapElementList = this.getRecapElement(item);
            if (recapElementList) {
                if (recapElementList.length === 1) {
                    const recapElement = recapElementList[0] as Element;

                    if (recapElement && this.user) {
                        this.insertIntoDom(recapElement, this.user, value);
                    }
                } else {
                    recapElementList.forEach((recapElement: Element) => {
                        if (recapElement && this.user) {
                            this.insertIntoDom(recapElement, this.user, value);
                        }
                    });
                }
            }
        }

        // give it some time to update the dom
        setTimeout(() => {
            return true;
        }, 50);
    }

    // public async resetHighLights() {
    //     const editorElement = this.getEditorElement();
    //
    //     if (editorElement) {
    //         DataAutomation.resetHighlightNodes(editorElement);
    //     }
    //
    //     // give it some time to update the dom
    //     setTimeout(() => {
    //         return true;
    //     }, 200);
    // }

    private static resetHighlightNodes(editorElement: Element, currentDataId?: string) {
        const highlightedNodes = editorElement.getElementsByClassName("highlight");

        for (let i = 0; i < highlightedNodes.length; i++) {
            if (highlightedNodes[i].getAttribute("data-recap-id") !== currentDataId) {
                highlightedNodes[i].classList.remove("highlight");
            }
        }
    }

    private insertIntoDom(element: Element, user: TenantUser, value: string) {
        if (value.length >= 1) {
            element.setAttribute("contenteditable", "false");
            element.setAttribute("data-change-type", "addition");
            element.setAttribute("data-identifier", String(randomNr()));
            element.setAttribute("data-user", user.id);
            element.setAttribute("data-timestamp", String(getUnixTime(new Date())));
            element.setAttribute("name", "smart-field");
            element.setAttribute("data-is-smart-field", "true");

            element.classList.remove("highlight");

            if (this.formatting) {
                element.classList.add("fr-highlight-change");
            }

            element.innerHTML = `<span ${this.formatting ? 'class="fr-highlight-change"' : ""}>${value
                .replaceAll("<br />", "\n")
                .replaceAll("<br>", "\n")
                .replaceAll("<br >", "\n")
                .replace(/<[^>]*>?/gm, "")
                .replace(/\n/g, "<br />")}</span>`;
        } else {
            element.removeAttribute("contenteditable");
            element.removeAttribute("data-change-type");
            element.removeAttribute("data-identifier");
            element.removeAttribute("data-user");
            element.removeAttribute("data-timestamp");
            element.removeAttribute("name");
            element.removeAttribute("data-is-smart-field");

            if (this.formatting) {
                element.classList.remove("fr-highlight-change");
            }

            element.innerHTML = "";
        }
    }

    private getEditorElement() {
        if (this.ref && this.ref.current && this.ref.current.el) {
            return this.ref.current.el;
        } else {
            return null;
        }
    }

    private getRecapElement(item: SmartFieldInterface): NodeList | null {
        try {
            return this.getEditorElement()?.querySelectorAll(
                `[data-recap-id="${item.data_id}"]`,
            ) as NodeList;
        } catch (e) {
            return null;
        }
    }

    get user(): TenantUser | undefined {
        return this._user;
    }

    set user(value: TenantUser | undefined) {
        this._user = value;
    }

    get ref(): any {
        return this._ref;
    }

    set ref(value: any) {
        this._ref = value;
    }

    get wrapper(): Element | undefined {
        return this._wrapper;
    }

    set wrapper(value: Element | undefined) {
        this._wrapper = value;
    }

    get formatting(): boolean {
        return this._formatting;
    }

    set formatting(value: boolean) {
        this._formatting = value;
    }
}
