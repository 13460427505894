import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import React from "react";
import { useParams } from "react-router-dom";

function SingingCancelled() {
    const { document_name } = useParams<{ document_name?: string }>();

    return (
        <Dialog open>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        The e-signing process for {document_name} was cancelled
                    </DialogTitle>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
}

export default SingingCancelled;
