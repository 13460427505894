interface CloseButtonProps {
    onClick: (event: MouseEvent) => void;
    target: HTMLElement;
}

function CloseButton({ target, onClick }: CloseButtonProps) {
    const button = document.createElement("button");
    button.className = "remove-placeholder";
    button.innerText = "×";

    // Add event listener instead of setting onclick directly
    button.addEventListener("click", onClick);

    target.appendChild(button);
    return button;
}

export default CloseButton;
