import { CaseFileIndex } from "../../types/case-files";
import React, { useState } from "react";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns, tableDropDown } from "../../config/archive";
import { DataTableToolbar } from "../../components/data-table/data-table-toolbar";
import DataTable from "../../components/data-table/data-table";
import { Tables } from "../../config/app";

function CaseFileArchiveTable({
    data,
    onClick,
}: {
    data: Array<CaseFileIndex & { deleted_at: Date }>;
    onClick: (row: CaseFileIndex) => void;
}) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            meta: {
                tableId: Tables.CASE_FILE_ARCHIVE,
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    return (
        <div className="flex flex-col h-full">
            <DataTableToolbar table={table} searchableColumns={[]} />
            <div className="flex py-4 h-full">
                <DataTable
                    columns={columns}
                    table={table}
                    onClick={onClick}
                    contextMenuItems={tableDropDown}
                />
            </div>
        </div>
    );
}

export default CaseFileArchiveTable;
