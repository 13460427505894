import { ColumnDef } from "@tanstack/react-table";
import { HouseForm } from "../types/house-forms";
import { Copy, MoreHorizontal, Pencil, Trash2, Zap } from "lucide-react";
import React from "react";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import { format } from "date-fns";
import { TableDropDownItems } from "../types/table";
import { DropdownMenu, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import DataTableAdvancedDropDown from "../components/data-table/data-table-advanced-drop-down";
import DeleteHouseForm from "../containers/house-forms/delete-house-form";
import DuplicateHouseForm from "../containers/house-forms/duplicate-house-form";
import EditHouseForm from "../containers/house-forms/edit-house-form";
import { formatDocumentType } from "../lib/utils";

const columns: ColumnDef<HouseForm>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Document name" type="filter" />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex items-center w-full overflow-hidden text-ellipsis">
                    {row.original.is_smart ? (
                        <Zap
                            className={"h-4 w-4 min-w-[16px] stroke-transparent fill-blue-400 mr-4"}
                        />
                    ) : null}

                    <span className={"w-full min-w-0 overflow-hidden text-ellipsis"}>
                        {row.getValue("name")}
                    </span>
                </div>
            );
        },
        meta: {
            title: "Document name",
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
    },
    {
        accessorKey: "template_type",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Template type" type="filter" />;
        },
        meta: {
            title: "Template type",
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        cell: ({ row }) => {
            return <span>{formatDocumentType(row.getValue("template_type"))}</span>;
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Created at (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Created at (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "updated_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Last modified (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Last modified (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("updated_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DataTableAdvancedDropDown<HouseForm>
                        row={row.original}
                        type="dropdown"
                        items={tableDropDown}
                    />
                </DropdownMenu>
            );
        },
    },
];

const tableDropDown: TableDropDownItems<HouseForm> = {
    label: "Actions",
    groups: [
        {
            actions: [
                {
                    label: "Edit",
                    icon: <Pencil className="mr-2 h-4 w-4" />,
                    element: (row, onClose) => <EditHouseForm row={row} onClose={onClose} />,
                    elementType: "dialog",
                },
                {
                    label: "Duplicate",
                    icon: <Copy className="mr-2 h-4 w-4" />,
                    element: (row, onClose) => <DuplicateHouseForm row={row} onClose={onClose} />,
                    elementType: "dialog",
                },
            ],
        },
        {
            actions: [
                {
                    label: "Archive",
                    icon: <Trash2 className="mr-2 h-4 w-4 stroke-white" />,
                    dangerous: true,
                    element: (row, onClose) => <DeleteHouseForm row={row} onClose={onClose} />,
                    elementType: "dialog",
                },
            ],
        },
    ],
};

export { columns, tableDropDown };
