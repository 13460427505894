import { SheetHeader } from "../ui/sheet";
import { Typography } from "../ui/typography";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { MoreHorizontal } from "lucide-react";
import { Separator } from "../ui/separator";
import React, { Fragment, useState } from "react";
import NewDirectChannel from "./new-direct-channel";
import { Dialog } from "../ui/dialog";
import NewGroupChannel from "./new-group-channel";

function ChatChannelHeader() {
    const [newChannelType, setNewChannelType] = useState<"direct" | "group">();

    return (
        <Fragment>
            <SheetHeader className="">
                <div className="flex items-center justify-between pb-2 w-full pr-4">
                    <Typography text="Chat" style="h2" className="p-6" />
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon">
                                <MoreHorizontal className="fill-neutral-800 h-[1.2rem] w-[1.2rem]" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-42" align="end">
                            <DropdownMenuLabel>New</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuGroup>
                                <DropdownMenuItem onClick={() => setNewChannelType("direct")}>
                                    <span>Direct chat</span>
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setNewChannelType("group")}>
                                    <span>Group</span>
                                </DropdownMenuItem>
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <Separator />
            </SheetHeader>
            <Dialog
                open={Boolean(newChannelType)}
                onOpenChange={() => setNewChannelType(undefined)}
            >
                {newChannelType === "direct" && (
                    <NewDirectChannel onClose={() => setNewChannelType(undefined)} />
                )}
                {newChannelType === "group" && (
                    <NewGroupChannel onClose={() => setNewChannelType(undefined)} />
                )}
            </Dialog>
        </Fragment>
    );
}

export default ChatChannelHeader;
