import { ColumnDef } from "@tanstack/react-table";
import { AuditTrail } from "../types/case-files";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { format } from "date-fns";

const columns: ColumnDef<AuditTrail>[] = [
    {
        accessorKey: "entry_type",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Entry type" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Entry type",
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Date (CEST)" type="date-range" />;
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "company_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Company name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Company name",
        },
    },
    {
        accessorKey: "user_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="User name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "User name",
        },
    },
    {
        accessorKey: "log_record",
        header: "Log record",
        meta: {
            title: "Log record",
        },
    },
];

export { columns };
