import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "../ui/command";
import { SearchInput } from "../ui/input";
import { cn } from "../../lib/utils";
import React, { Fragment, ReactNode } from "react";
import { PopoverAnchor } from "../ui/popover";

type SearchableBaseProps<Generic> = {
    children: (props: SearchableChildProps<Generic>) => JSX.Element;
    onOptionSelected: (values?: Set<Generic | undefined>) => void;
    searchPlaceholder: string;
    selectedValues: Set<Generic | undefined>;
    hideNoResults?: boolean;
    multiSelectable?: boolean;
    className?: string;
    commandItemClassName?: string;
    commandItemSelectedClassName?: string;
    searchInputClassName?: string;
    clearable?: boolean;
    clearableText?: string;
    labelFormatter?: (value: Generic) => string;
    autoFocusSearch?: boolean;
};

type SearchableChildProps<Generic> = {
    option: SearchableOption<Generic>;
    isSelected: boolean;
};

type SearchableListProps<Generic> = {
    groups: Array<SearchableGroup<Generic>>;
    popover?: SearchablePopOverOptions;
} & SearchableBaseProps<Generic>;

type SearchablePopOverOptions = {
    closeOnSelect: boolean;
};

type SearchableGroup<Generic> = {
    heading?: string;
    className?: string;
    options: Generic[];
};

type SearchableGroupOptions<Generic> = {
    options: SearchableOption<Generic>[];
} & Omit<SearchableGroup<Generic>, "options">;

type SearchableOption<Generic> = {
    label: string;
    value: Generic;
    index: number;
};

type SearchableProps<Generic> = {
    children: ReactNode;
    onSearchChange: (search: string) => void;
    clearSelection: () => void;
    searchSelected: () => void;
} & Pick<
    SearchableBaseProps<Generic>,
    | "searchPlaceholder"
    | "autoFocusSearch"
    | "className"
    | "searchInputClassName"
    | "clearable"
    | "clearableText"
    | "selectedValues"
    | "hideNoResults"
>;

function Searchable<Generic>({
    children,
    className,
    searchPlaceholder,
    autoFocusSearch,
    onSearchChange,
    searchInputClassName,
    clearable,
    clearableText,
    selectedValues,
    clearSelection,
    searchSelected,
    hideNoResults,
}: SearchableProps<Generic>) {
    return (
        <Command className={className} autoFocus>
            <SearchInput
                placeholder={searchPlaceholder}
                autoFocus={autoFocusSearch}
                onChange={(event) => onSearchChange(event.target.value)}
                className={cn("w-42", searchInputClassName)}
                onClick={searchSelected}
                onSelect={searchSelected}
            />
            <PopoverAnchor />
            <CommandList>
                {!!hideNoResults && <CommandEmpty className="py-2">No results found.</CommandEmpty>}
                {children}
            </CommandList>
            {clearable && selectedValues.size > 0 && (
                <Fragment>
                    <CommandSeparator />
                    <CommandGroup>
                        <CommandItem
                            onSelect={clearSelection}
                            className="justify-center text-center cursor-pointer"
                        >
                            <span className="text-slate-950">
                                {clearableText ?? "Clear filter"}
                            </span>
                        </CommandItem>
                    </CommandGroup>
                </Fragment>
            )}
        </Command>
    );
}

export default Searchable;
export {
    Searchable,
    SearchableProps,
    SearchableListProps,
    SearchableOption,
    SearchableChildProps,
    SearchableGroupOptions,
};
