import { tenantApi } from "../tenantApi";
import { ProFormaValidationDetails } from "../../types/pro-forma";
import { WithCaseFileId } from "../../types";

const proFormaApi = tenantApi.injectEndpoints({
    endpoints: (build) => {
        return {
            generateProForma: build.mutation<
                { code: string; id: string },
                WithCaseFileId<{ id: string; valid_until: string }>
            >({
                query: ({ id, case_file_id, ...body }) => ({
                    url: `${case_file_id}/case-file/pro-forma/${id}`,
                    method: "POST",
                    body: body,
                }),
            }),
            getProFormaValidation: build.query<ProFormaValidationDetails | string, string>({
                query: (code) => ({
                    url: `/pro-forma/validate/${code}`,
                }),
            }),
            createProFormaDocument: build.mutation<string, WithCaseFileId<{ code: string }>>({
                query: ({ code, case_file_id }) => ({
                    url: `${case_file_id}/case-file/pro-forma/${code}`,
                    responseHandler: "text",
                    method: "PUT",
                }),
                invalidatesTags: [
                    {
                        type: "Documents",
                        id: "LIST",
                    },
                ],
            }),
        };
    },
});

export const {
    useGenerateProFormaMutation,
    useLazyGetProFormaValidationQuery,
    useCreateProFormaDocumentMutation,
} = proFormaApi;
