import { TrackChangesEntry, TrackChangesEntryTypes } from "../../types/document-editor";
import { getUnixTime } from "date-fns";

export class ChangeLogV2 {
    private static _instance: ChangeLogV2;
    private _caseFileId: string | undefined;
    private _documentId: string | number | undefined;
    private _editor: any;
    private _reference: any;
    private _entries: Array<TrackChangesEntry> | undefined;
    private _formatting: boolean;
    private _userId: string;
    private _token: string | undefined;

    constructor(
        editor: any,
        entries?: Array<TrackChangesEntry>,
        caseFileId?: string,
        documentId?: string,
        userId?: string,
    ) {
        this._editor = editor;

        Object.assign(this, {
            editor: editor,
            caseFileId: caseFileId,
            documentId: documentId,
            userId: userId,
            reference: undefined,
            entries: entries,
        });
    }

    public static getInstance(): ChangeLogV2 {
        if (!ChangeLogV2.instance) {
            ChangeLogV2.instance = new ChangeLogV2(undefined);
        }

        return ChangeLogV2.instance;
    }

    public registerReference(ref: any) {
        this.reference = ref;
    }

    public init(
        editor: any,
        documentId: string,
        formatting_enabled: boolean,
        user_id: string,
        caseFileId: string,
        token?: string | undefined,
    ) {
        this.editor = editor;
        this.documentId = documentId;
        this.formatting = formatting_enabled;
        this.userId = user_id;
        this.caseFileId = caseFileId;
        this._token = token;
    }

    private static is_node_line_number(node: Node) {
        if (node.parentElement && "hasAttribute" in node.parentElement) {
            if (
                node.parentElement.tagName === "TD" &&
                node.parentElement.hasAttribute("data-is-line-number")
            ) {
                return true;
            }

            if (
                node.parentElement.parentElement &&
                node.parentElement.parentElement.tagName === "TD" &&
                node.parentElement.parentElement.hasAttribute("data-is-line-number")
            ) {
                return true;
            }
        }

        return false;
    }

    public async syncChanges(
        currentChanges: Array<TrackChangesEntry>,
    ): Promise<Array<TrackChangesEntry>> {
        let newChanges: Array<TrackChangesEntry> = [];
        if (this.reference && this.reference.current && this.reference.current.el) {
            const editorContainer = this.reference.current.el;
            const changesInEditor = editorContainer.querySelectorAll(
                "[data-change-type]",
            ) as NodeList;

            if (changesInEditor.length) {
                const convertedList: Array<TrackChangesEntry> = Array.prototype.map
                    .call(changesInEditor, (node: Node) => {
                        if (!ChangeLogV2.is_node_line_number(node)) {
                            const convertedNode = this.convertNodeListItemToChangeEntry(node);

                            if (convertedNode) {
                                return convertedNode;
                            }
                            return undefined;
                        }
                    })
                    .filter((x: any) => x);

                const filteredConvertedList: Array<TrackChangesEntry> = convertedList
                    .map((change) => {
                        const existingChange = currentChanges.find(
                            (x) => x.identifier === change.identifier,
                        );

                        if (!existingChange) {
                            return change;
                        } else {
                            let shouldAdd = false;

                            if (change.type === "addition") {
                                shouldAdd = existingChange.value.trim() !== change.value.trim();
                            } else if (change.type === "deletion") {
                                shouldAdd =
                                    existingChange.value.trim().length !==
                                    change.value.trim().length;
                            }

                            if (shouldAdd) {
                                return change;
                            }

                            return undefined;
                        }
                    })
                    .filter((x) => x !== undefined)
                    .filter((c) => c?.value != "") as Array<TrackChangesEntry>; // filter out empty changes

                if (filteredConvertedList) {
                    newChanges = filteredConvertedList;
                }
            }

            // always append reinstated changes to list if not in currentChanges
            newChanges = newChanges.concat(currentChanges.filter((x) => x.type === "reinstate"));
            // check if addition in currentChanges not in editor
        }

        newChanges = [...new Set(newChanges)].filter(
            (v, i, a) => a.findIndex((v2) => v2.identifier === v.identifier) === i,
        );

        newChanges = newChanges.filter((x) => x.value !== "");

        // filter out duplicate entries based on identifier
        this.entries = this.entries?.concat(
            newChanges.filter(
                (v, i, a) => a.findIndex((v2) => v2.identifier === v.identifier) === i,
            ),
        );

        return newChanges;
    }

    private static isChangeNodeAnOverWrite(nodeElement: Element): boolean {
        if (nodeElement.hasAttribute("data-tracking")) {
            if (
                nodeElement.hasAttribute("data-change-type") &&
                nodeElement.getAttribute("data-change-type") === "deletion" &&
                nodeElement.firstChild
            ) {
                if (
                    nodeElement.textContent?.replaceAll(String.fromCharCode(8203), "") !==
                    nodeElement.firstChild.textContent?.replaceAll(String.fromCharCode(8203), "")
                ) {
                    return true;
                }
            }

            return false;
        }

        return false;
    }

    private convertNodeListItemToChangeEntry(node: Node): TrackChangesEntry | undefined {
        const nodeElement = node as Element,
            overWrite: boolean = ChangeLogV2.isChangeNodeAnOverWrite(nodeElement);

        let identifier: number = Number(nodeElement.getAttribute("data-identifier")) ?? 0,
            type: TrackChangesEntryTypes =
                (nodeElement.getAttribute("data-change-type") as TrackChangesEntryTypes) ??
                "addition",
            user = nodeElement.getAttribute("data-user") ?? this.userId,
            value: string = node.textContent ?? "";

        if (!value.trim().length) {
            return undefined;
        }

        if (overWrite) {
            value =
                node.textContent?.replace(nodeElement.firstElementChild?.textContent ?? "", "") ??
                "";
            type = "addition";
        }

        if (
            nodeElement.hasAttribute("data-change-type") &&
            nodeElement.getAttribute("data-change-type") === "deletion" &&
            nodeElement.firstElementChild &&
            nodeElement.firstElementChild.hasAttribute("data-change-type") &&
            nodeElement.firstElementChild.getAttribute("data-change-type") === "deletion" &&
            nodeElement.firstElementChild.hasAttribute("data-identifier") &&
            !overWrite &&
            nodeElement.textContent?.replaceAll(String.fromCharCode(8203), "").length ===
                nodeElement.firstElementChild.textContent?.replaceAll(String.fromCharCode(8203), "")
                    .length
        ) {
            identifier = Number(nodeElement.firstElementChild.getAttribute("data-identifier")) ?? 0;
        }

        if (
            nodeElement.hasAttribute("data-pasted") &&
            nodeElement.hasAttribute("data-identifier")
        ) {
            value = node.textContent ?? "";
        }

        return {
            caseFileId: this.caseFileId ?? "",
            id: String(this._documentId) ?? "",
            identifier: identifier,
            stored: false,
            type: type,
            user: user,
            value: value,
            timestamp: Number(nodeElement.getAttribute("data-timestamp")),
            user_id: this.userId,
        };
    }

    public handleNodeHighlight(node: Element | null, type?: string) {
        setTimeout(() => {
            if (node) {
                if (this.formatting) {
                    // TODO fix highlighting
                    // case: plain addition
                    if (node.getAttribute("data-change-type") === "addition") {
                        ChangeLogV2.toggleNodeHighlight(node);
                    } else if (
                        node.firstElementChild &&
                        node.firstElementChild.hasAttribute("data-change-type") &&
                        node.firstElementChild.getAttribute("data-change-type") === "deletion" &&
                        node.textContent?.length !== node.firstElementChild.textContent?.length
                    ) {
                        if (type === "addition") {
                            ChangeLogV2.toggleNodeHighlight(node.lastElementChild ?? node);

                            if (!node.classList.contains("highlight")) {
                                node.firstElementChild.classList.add("hide-highlight");
                                node.classList.add("highlight");
                            } else {
                                node.firstElementChild.classList.remove("hide-highlight");
                                node.classList.remove("highlight");
                            }
                        } else {
                            ChangeLogV2.toggleNodeHighlight(node.firstElementChild);
                        }
                    } else {
                        ChangeLogV2.toggleNodeHighlight(node);
                    }
                } else {
                    if (node.getAttribute("data-change-type") === "addition") {
                        // case: plain addition
                        if (
                            node.textContent?.charCodeAt(0) === 8203 &&
                            node.textContent?.length === 1
                        ) {
                            if (node.parentElement) {
                                ChangeLogV2.toggleNodeHighlight(node.parentElement);
                                ChangeLogV2.toggleNodeHighlight(node);
                            } else {
                                ChangeLogV2.toggleNodeHighlight(node);
                            }
                        } else {
                            ChangeLogV2.toggleNodeHighlight(node);
                        }
                    }
                    if (node.getAttribute("data-change-type") === "deletion") {
                        if (node.firstElementChild) {
                            let original, current;
                            original = node.textContent;

                            if (
                                node.firstElementChild.getAttribute("data-change-type") ===
                                "deletion"
                            ) {
                                current = original?.replace(
                                    node.firstElementChild.textContent ?? "",
                                    "",
                                );

                                if (current && current !== original) {
                                    // case: override addition
                                    if (node.classList.contains("highlight")) {
                                        node.classList.remove("highlight");
                                        node.firstElementChild?.classList.remove("hide-highlight");

                                        return;
                                    } else {
                                        node.classList.add("highlight");
                                        node.firstElementChild?.classList.add("hide-highlight");

                                        return;
                                    }
                                } else {
                                    // case: plain deletion
                                    ChangeLogV2.toggleNodeHighlight(node);
                                }
                            }
                        } else {
                            // case: override deletion
                            if (node.classList.contains("hide-highlight")) {
                                node.classList.remove("hide-highlight");
                            } // prevent overlap

                            ChangeLogV2.toggleNodeHighlight(node);
                        }
                    }
                }
            }
        }, 200);
    }

    private static toggleNodeHighlight(node: Element) {
        if (
            node.classList.contains("highlight") ||
            (node.hasAttribute("data-highlight-showing") &&
                node.getAttribute("data-highlight-showing") === "true")
        ) {
            if (node?.parentElement?.classList.contains("tc-hidden")) {
                node?.parentElement?.classList.add("highlight");
            }

            node.classList.remove("highlight");
            node.setAttribute("data-highlight-showing", "false");

            return;
        } else {
            if (node?.parentElement?.classList.contains("highlight")) {
                node?.parentElement?.classList.add("highlight");
            }
            node.classList.add("highlight");
            node.setAttribute("data-highlight-showing", "true");

            return;
        }
    }

    public async reinstate(change: TrackChangesEntry): Promise<boolean> {
        try {
            const nodeWithIdentifier = document.querySelector(
                `[data-identifier="${change.identifier}"]`,
            );

            if (nodeWithIdentifier) {
                if (nodeWithIdentifier.parentElement) {
                    if (
                        nodeWithIdentifier.parentElement.outerHTML &&
                        nodeWithIdentifier.textContent
                    ) {
                        if (nodeWithIdentifier.parentElement.children.length < 2) {
                            if (
                                nodeWithIdentifier.parentElement.textContent?.length ===
                                nodeWithIdentifier.textContent.length
                            ) {
                                nodeWithIdentifier.parentElement.outerHTML =
                                    nodeWithIdentifier.innerHTML;
                            } else {
                                const addition = nodeWithIdentifier.parentElement.lastChild;

                                if (
                                    "hasAttribute" in nodeWithIdentifier &&
                                    nodeWithIdentifier.hasAttribute("data-tracking-deleted")
                                ) {
                                    nodeWithIdentifier.parentElement.outerHTML = `${nodeWithIdentifier.textContent}<span data-change-type="addition" data-identifier="${change.identifier}" data-user="${change.user_id}" data-timestamp="${getUnixTime(new Date())}" class="fr-highlight-change">${addition?.textContent}</span>`;
                                } else {
                                    nodeWithIdentifier.outerHTML = nodeWithIdentifier.innerHTML;
                                }
                            }
                        } else {
                            const deletion = nodeWithIdentifier.parentElement.firstElementChild;
                            nodeWithIdentifier.parentElement.outerHTML =
                                deletion?.innerHTML +
                                nodeWithIdentifier.parentElement.outerHTML.replace(
                                    deletion?.outerHTML ?? "",
                                    "",
                                );

                            deletion?.remove();
                        }
                    }
                }
            }

            return true;
        } catch (e) {
            return false;
        }
    }

    get token(): string | undefined {
        return this._token;
    }

    set token(value: string | undefined) {
        this._token = value;
    }

    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }

    get formatting(): boolean {
        return this._formatting;
    }

    set formatting(value: boolean) {
        this._formatting = value;
    }

    get entries(): Array<TrackChangesEntry> | undefined {
        return this._entries;
    }

    set entries(value: Array<TrackChangesEntry> | undefined) {
        this._entries = value;
    }

    get reference(): any {
        return this._reference;
    }

    set reference(value: any) {
        this._reference = value;
    }

    get editor(): any {
        return this._editor;
    }

    set editor(value: any) {
        this._editor = value;
    }

    get documentId(): string | number | undefined {
        return this._documentId;
    }

    set documentId(value: string | number | undefined) {
        this._documentId = value;
    }

    get caseFileId(): string | undefined {
        return this._caseFileId;
    }

    set caseFileId(value: string | undefined) {
        this._caseFileId = value;
    }

    static get instance(): ChangeLogV2 {
        return this._instance;
    }

    static set instance(value: ChangeLogV2) {
        this._instance = value;
    }
}
