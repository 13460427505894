import styled from "styled-components";

export const Wrapper = styled.section`
    height: 100vh;
    width: 100vw;
    background-image: url("/images/registration-background.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const Container = styled.section`
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FormWrapper = styled.section<{ inputSize?: string }>`
    width: 100%;
    max-width: ${(props) => props.inputSize || "600px"};
    height: auto;
    padding: 2em;
    margin: 0 auto;
`;

export const FormTitle = styled.h1`
    font-size: 2em;
    margin-bottom: 1em;
`;

export const FormSubTitle = styled.h3`
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 1em 0;
`;
