import { froalaConfigBasic, standardAddendum } from "../../../config/app";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardContent } from "../../../components/ui/card";
import React, { useEffect, useState } from "react";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import FroalaEditor from "react-froala-wysiwyg";
import { useDebounce } from "@uidotdev/usehooks";
import { useAddenda } from "../../../lib/providers/addenda-provider";
import { Addenda } from "../../../types/addenda";

function AddendaEditor() {
    const { permissions } = useCaseFiles();
    const [editor, setEditor] = useState<any>();

    const froalaConfig = {
        ...froalaConfigBasic,
        toolbarButtons: [
            "clearFormatting",
            "bold",
            "underline",
            "textColor",
            "backgroundColor",
            "fontSize",
            "|",
            "alignLeft",
            "alignCenter",
            "alignJustify",
            "formatOLSimple",
            "insertTable",
            "outdent",
            "indent",
        ],
        heightMin: "calc(100vh - 502px)",
        heightMax: "calc(100vh - 502px)",
        events: {
            initialized: function () {
                setEditor(this);
            },
        },
    };
    const [addendumInCreation, setAddendumInCreation] =
        useState<Pick<Addenda, "title" | "content">>(standardAddendum);

    const schema = z.object({
        content: z.string().min(2, "Please provide content"),
        title: z.string().min(2, "Please provide title"),
    });
    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: addendumInCreation ?? standardAddendum,
    });
    const { handleFormChange, activeAddendum, resetFormState } = useAddenda();
    const debouncedAddendum = useDebounce(addendumInCreation, 300);

    useEffect(() => {
        if (editor) {
            if (!permissions.add_addendum) {
                editor.edit.off();
            }
        }
    }, [editor]);

    useEffect(() => {
        if (resetFormState) {
            form.setValue("title", standardAddendum.title);
            form.setValue("content", standardAddendum.content);
        }
    }, [resetFormState]);

    useEffect(() => {
        if (debouncedAddendum) {
            handleFormChange(debouncedAddendum);
        }
    }, [debouncedAddendum]);

    useEffect(() => {
        if (activeAddendum) {
            form.setValue("title", activeAddendum.title);
            form.setValue("content", activeAddendum.content);

            setAddendumInCreation({
                content: activeAddendum.content,
                title: activeAddendum.title,
            });
        } else {
            form.setValue("title", standardAddendum.title);
            form.setValue("content", standardAddendum.content);
            setAddendumInCreation({
                content: standardAddendum.content,
                title: standardAddendum.title,
            });
        }
    }, [activeAddendum]);

    function updateForm(type: "title" | "content", value: string) {
        switch (type) {
            case "title":
                setAddendumInCreation((prev) => ({
                    ...prev,
                    title: value,
                }));
                break;
            case "content":
                setAddendumInCreation((prev) => ({
                    ...prev,
                    content: value,
                }));
                break;
        }
    }

    return (
        <Card className="flex-1">
            <CardContent>
                <Form {...form}>
                    <form>
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem className="py-2">
                                    <FormLabel>
                                        Title{" "}
                                        <span className="text-sm text-muted-foreground">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            onChange={(event) => {
                                                field.onChange(event);
                                                updateForm("title", event.target.value ?? "");
                                            }}
                                            disabled={!permissions.add_addendum}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="content"
                            render={() => (
                                <FormItem className="py-2">
                                    <FormControl>
                                        <FroalaEditor
                                            model={form.getValues("content")}
                                            config={froalaConfig}
                                            onModelChange={(value: string) => {
                                                form.setValue("content", value);
                                                setAddendumInCreation((prev) => {
                                                    return {
                                                        ...prev,
                                                        content: value,
                                                    };
                                                });
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </form>
                </Form>
            </CardContent>
        </Card>
    );
}

export default AddendaEditor;
