import React, { Fragment, useState } from "react";
import { DialogFooter, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert";
import { RocketIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { useMarkAsHouseFormMutation } from "../../../api/tenant/case-file";
import { toast } from "sonner";
import Loading from "../../../components/global/loading";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { useAuth } from "../../../lib/providers/AuthProvider";

function AddAsHouseForm({ row, onClose }: TableActionProps<CaseFileDocumentIndex>) {
    const user = useAuth().state.user;
    const schema = z.object({
        document_name: z.string().min(2, {
            message: "Document name must be at least 2 characters.",
        }),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            document_name: `${row.document_name} (template)`,
        },
    });
    const [markAsHouseForm] = useMarkAsHouseFormMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const { case_file_id } = useCaseFiles();

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);

        try {
            await markAsHouseForm({
                id: row.id,
                case_file_id: case_file_id,
                name: values.document_name ?? row.document_name,
                tenant_id: user?.tenant_id ?? "",
            }).unwrap;

            toast.message(`Successfully created a template from ${row.document_name}`);

            onClose();
        } catch (e) {
            toast.error("Something went wrong while creating template");
        }

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>
                            Create template from document: {row.document_name}
                        </DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)}>
                            <FormField
                                control={form.control}
                                name="document_name"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Document name{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <Alert className="mb-8">
                                <RocketIcon className="h-4 w-4 fill-slate-950" />
                                <AlertTitle className="text-slate-950">Heads up!</AlertTitle>
                                <AlertDescription className="text-slate-950">
                                    You are about to make a new house form based on the following
                                    {row.template_type === "bill_of_lading"
                                        ? " Bill of lading "
                                        : " Standard form "}
                                    document: {row.document_name}. All changes that were made in
                                    this document will be copied over to the house form. The
                                    document will remain unaffected.
                                </AlertDescription>
                            </Alert>

                            <DialogFooter>
                                <Button type="submit">Add as template</Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </Fragment>
            }
        />
    );
}

export default AddAsHouseForm;
