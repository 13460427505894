import styled, { createGlobalStyle, css } from "styled-components";
import { DatePickerField, FilteredPropsInputField } from "./index";
import { PasswordIcon } from "../Password";
import { borderThin, boxShadow, color, pxToRem } from "../../styles/mixins";

export const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker {
        border-color: ${color("darkGrey")} !important;
    }

    .react-datepicker__tab-loop {
        height: 100%;
        min-height: 240px;
        width: 100%;
        min-width: 240px;
        position: ${(props) => (props.hover ? "absolute" : "relative")};
    }

    .react-datepicker-popper {
        margin-left: calc(50% - 120px);
        background-color: ${(props) => props.theme.color.main.white}
    }

    .react-datepicker__triangle {
        transform: translate(123px, 0px) !important;

        &::before {
            border-bottom-color: ${color("darkGrey")} !important;
        }

        &::after {
            border-bottom-color: ${color("paleBlue")} !important;
        }
    }

    .react-datepicker__header {
        background-color: ${color("paleBlue")} !important;
    }

    .react-datepicker__current-month, react-datepicker__day-name, .react-datepicker__day {
        color: ${color("colorPickerData")} !important;
    }

    .react-datepicker__day--selected {
        color: ${color("white")} !important;
        background-color: ${color("primary")} !important;
    }
`;

export const InputGroup = styled.section`
    position: relative;
    margin: 1.5em 0 0.5em;

    & .react-datepicker-wrapper {
        width: 100%;
    }
`;

export const ClauseContentGroup = styled(InputGroup)`
    margin-top: 2em;
`;

export const RecapManagementContentGroup = styled(InputGroup)`
    width: 100%;
    margin: 0.5em 0 0 0;
`;

export const Label = styled.label`
    position: relative;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
    color: ${color("darker", 0.65)};

    &:hover {
        cursor: text;
    }
`;

export const DatePickLabel = styled(Label)<{ error: boolean; touched?: boolean }>`
    top: -14px;
    left: 0;
    font-size: 12px;
    color: ${(props) =>
        props.error ? color("pink") : props.touched ? color("secondary") : color("dark")};
`;

export const ItemLabel = styled(Label)`
    &:hover {
        cursor: text;
    }
`;

export const Bar = styled.span`
    position: relative;
    display: block;
    width: 100%;
    &:before {
        content: "";
        height: 2px;
        width: 0;
        bottom: 0px;
        position: absolute;
        background: ${color("primary")};
        transition: 300ms ease all;
        left: 0%;
    }
`;

export const PasswordFieldBar = styled(Bar)`
    width: calc(100% + 12px);
`;

export const Input = styled(FilteredPropsInputField)`
    background: none;
    color: ${color("dark")};
    font-size: 18px;
    padding: 10px 0 10px 5px;
    display: block;
    width: calc(100% - 5px);
    border: none;
    border-radius: 0;
    border-bottom: ${borderThin("primary")};

    &:focus {
        outline: none;
    }

    &:focus ~ ${Label}, &.focus ~ ${Label}, &[value]:not([value=""]) ~ ${Label} {
        top: -14px;
        font-size: 12px;
        color: ${color("primary")};
    }

    &:focus ~ ${Bar}:before, &.focus ~ ${Bar}:before, &[value]:not([value=""]) ~ ${Bar} {
        width: calc(100% + 5px);
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        background-color: ${color("white")};
        border-bottom: ${borderThin("primary")};
        box-shadow: ${boxShadow("spread")("white")} inset;
    }

    &:-webkit-autofill ~ ${Label} {
        top: -14px;
        font-size: 12px;
        color: ${color("primary")};
    }

    ${({ valid }) =>
        valid &&
        css`
            border-bottom: ${borderThin("secondary")};

            & ~ ${Label} {
                top: -14px;
                font-size: 12px;
                color: ${color("secondary")};
            }

            /* Autocomplete styles in Chrome*/
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border-bottom: ${borderThin("secondary")};
            }

            &:-webkit-autofill ~ ${Label} {
                color: ${color("secondary")};
            }

            ${PasswordIcon} {
                border-bottom: ${borderThin("secondary")};
            }
        `}

    ${({ error }) =>
        error &&
        css`
            border-bottom: ${borderThin("warning")};

            & ~ ${Label} {
                top: -14px;
                font-size: 12px;
                color: ${color("warning")};
            }

            /* Autocomplete styles in Chrome*/
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border: ${borderThin("warning", 1)};
            }
        `}
`;

export const DatePickerInput = styled(DatePickerField)`
    background: none;
    color: ${color("dark")};
    font-size: 18px;
    padding: 10px 0 10px 5px;
    display: block;
    width: calc(100% - 5px);
    border: none;
    border-radius: 0;
    border-bottom: ${borderThin("primary")};

    &:focus {
        outline: none;
    }

    &:focus ~ ${Label}, &.focus ~ ${Label}, &[value]:not([value=""]) ~ ${Label} {
        top: -14px;
        font-size: 12px;
        color: ${color("primary")};
    }

    &:focus ~ ${Bar}:before, &.focus ~ ${Bar}:before, &[value]:not([value=""]) ~ ${Bar} {
        width: calc(100% + 5px);
    }

    /* Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        background-color: ${color("white")};
        border-bottom: ${borderThin("primary")};
        box-shadow: ${boxShadow("spread")("white")} inset;
    }

    &:-webkit-autofill ~ ${Label} {
        top: -14px;
        font-size: 12px;
        color: ${color("primary")};
    }
`;

export const SmartFieldsLabel = styled(Label)``;

export const TextArea = styled.textarea`
    background: none;
    color: ${color("dark")};
    font-size: 18px;
    padding: 10px 0 10px 5px;
    display: block;
    width: calc(100% - 5px);
    border: none;
    border-radius: 0;
    border: ${borderThin("listItem", 0.5)};
    font-size: 16px;
    outline: none;
    font-family: "Barlow", sans-serif;
    resize: none;
    height: 58px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border-bottom: ${borderThin("primary")};
    }

    &:focus {
        border: ${borderThin("primary")};
    }

    & ~ ${Label} {
        max-width: 25ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        top: 16px;
        z-index: 996;
    }

    &:hover {
        & ~ ${Label}.expandable {
            max-width: 100%;
            font-size: 13px;
        }
    }

    &.valid {
        border: ${borderThin("secondary")} !important;

        &:focus {
            border: ${borderThin("primary")} !important;

            & ~ ${Label} {
                color: ${color("primary")};
            }
        }
    }

    &:focus ~ ${Label} {
        top: -8px;
        font-size: 16px;
        color: ${color("primary")};
        background-color: ${color("white")};
    }

    &.focus ~ ${Label} {
        top: -8px;
        font-size: 16px;
        background-color: ${color("white")};
    }

    &.valid ~ ${Label} {
        top: -8px;
        font-size: 16px;
        color: ${color("secondary")};
        background-color: ${color("white")};
    }
`;

export const NoteTextArea = styled(TextArea)`
    & ~ ${Label} {
        top: -8px;
        background-color: ${color("white")};
        max-width: 40ch;
    }

    &:focus,
    &.focus,
    &[value]:not([value=""]) {
        border-left: transparent;
        border-right: transparent;
        border-top: transparent;

        & ~ ${Label} {
            font-size: 12px;
        }
    }
`;

export const StyledInlineErrorMessage = styled.div`
    color: ${color("warning")};
    display: block;
    font-size: 12px;
    white-space: pre-line;
    margin-top: 4px;
`;

export const CheckboxLabel = styled.label`
    display: flex;
    vertical-align: middle;
    input {
        accent-color: ${color("primary")};
        height: 1em;
        width: 1em;
        margin: 0 1em 0 0;
    }
    span {
        line-height: 1em;
        font-size: 1em;
        color: ${color("dark")};
        a {
            color: ${color("dark")};
        }
    }
`;

export const FormButtonGroup = styled.section`
    width: inherit;
    display: flex;
    justify-content: space-between;

    button:first-child {
        padding-left: 0 !important;
    }

    button:last-child {
        padding-right: 0 !important;
    }
`;

export const AttachmentsFormButtonGroup = styled(FormButtonGroup)`
    flex: 5% 0;
    display: block;
`;

export const AttachmentSpinnerContainer = styled.section`
    margin-left: auto;
    margin-right: 0;
    width: 64px;
`;

export const DownloadButtonGroup = styled(FormButtonGroup)`
    box-shadow: ${boxShadow("buttonGroup")("buttonGroup")};
    background-color: ${color("white")};

    & > button {
        margin: 0.75em 0;
    }
`;

export const DefaultButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: ${color("black", 0.87)};
    padding: 6px 0;
    font-size: ${pxToRem(14)};
    min-width: 64px;
    box-sizing: border-box;
    font-family: "Barlow", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    margin-top: 1em;
`;

export const CancelButton = styled(DefaultButton)`
    color: ${color("warning")};
    padding: 0.5em 1em;
    margin-top: 1.5em;
`;

export const SubmitButton = styled(DefaultButton)`
    color: ${color("primary")};
    padding: 0.5em 1em;
    margin-top: 1.5em;
`;

export const RecapClauseSelectionInputGroup = styled(InputGroup)`
    width: 100%;
`;

export const RecapClauseSelection = styled.select`
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    min-width: 16px;
    user-select: none;
    padding: 1em 14px;
    border-radius: 0.5em;
    border-color: ${color("darker", 0.65)};
    background-color: ${color("transparent")};
    color: ${(props) => props.theme.color.main.dark};
    width: calc(50% - 4.5em);
    font-size: 1em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 20px) calc(1.1876em + 2px),
        calc(100% - 12px) calc(1.1876em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        8px 8px,
        8px 8px,
        1px 2.25em;
    background-repeat: no-repeat;
    width: 100%;
`;

export const ClauseCategorySelectionFilterContainer = styled.section`
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
`;

export const CautionaryClauseLabel = styled.section``;
