import { FormControl, FormItem, FormLabel } from "../../../components/ui/form";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import { CloudDownload, CloudUpload, EllipsisVertical, X } from "lucide-react";
import { Textarea } from "../../../components/ui/textarea";
import { Panel } from "../../../components/ui/panel";
import React, { useState } from "react";
import { RecapCategoryStructureInterface } from "../../../types/recap";
import { RecapVesselEntityInterface } from "../../../lib/stores/RecapVesselsSlice";
import { cn, scrollToCenterInsideParent } from "../../../lib/utils";
import {
    UseFormGetFieldState,
    UseFormRegister,
    UseFormSetValue,
} from "react-hook-form/dist/types/form";
import { Dialog, DialogTrigger } from "../../../components/ui/dialog";
import { toast } from "sonner";
import { RecapFieldValueDialog } from "./recap-field-value-dialog";
import { useUpdateRecapFieldSavedValueMutation } from "../../../api/tenant/recap-saved-values";

interface RecapCategoryInterface {
    className?: string;
    category: RecapCategoryStructureInterface;
    vessel?: RecapVesselEntityInterface;
    register: UseFormRegister<any>;
    removeField: (fieldId: string) => void;
    setValue: UseFormSetValue<any>;
    getFieldState: UseFormGetFieldState<any>;
    isUserActive: boolean;
    showPreview: boolean;
}

export function RecapCategory({
    className,
    category,
    vessel,
    removeField,
    register,
    getFieldState,
    setValue,
    isUserActive,
    showPreview,
}: RecapCategoryInterface) {
    const [updateValue] = useUpdateRecapFieldSavedValueMutation();
    const [openDialog, setOpenDialog]: [undefined | string, (value: string | undefined) => void] =
        useState();

    function handleScrollToFieldInPreview(fieldId: string) {
        const fieldIdSelector = vessel
            ? `recap-preview-vessel-${vessel.id}-field-${fieldId}`
            : `recap-preview-field-${fieldId}`;
        const node = window.document.getElementById(fieldIdSelector);
        const parent = window.document.getElementById(`recap-preview`);

        if (node && parent) {
            scrollToCenterInsideParent(node, parent);
        }
    }

    return (
        <Panel className={cn(className, "mb-8")}>
            <div
                className={`grid ${showPreview ? "grid-cols-1 2xl:grid-cols-2 3xl:grid-cols-3" : "grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4"} gap-4`}
                tabIndex={-1}
            >
                {Object.entries(category.fields).map((field) => (
                    <FormItem className={"group"} key={field[0]}>
                        <FormLabel className={"flex w-full gap-4 justify-between items-center"}>
                            <span>{field[1]}</span>
                            <Dialog
                                onOpenChange={(open) => {
                                    if (open) {
                                        setOpenDialog(field[0]);
                                    }
                                }}
                            >
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild tabIndex={-1}>
                                        <Button
                                            className={
                                                "opacity-0 transition-opacity duration-300 active:opacity-100 group-hover:opacity-100"
                                            }
                                            size={"icon"}
                                            variant={"ghost"}
                                            disabled={!isUserActive}
                                        >
                                            <EllipsisVertical className={"h-4 w-4"} />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align={"end"} className="w-56">
                                        <DropdownMenuGroup>
                                            <DropdownMenuItem onClick={() => removeField(field[0])}>
                                                <X className="mr-2 h-4 w-4" />
                                                <span>Remove field</span>
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuGroup>
                                            <DialogTrigger className={"w-full"}>
                                                <DropdownMenuItem>
                                                    <CloudDownload className="mr-2 h-4 w-4" />
                                                    <span>Read previous value</span>
                                                    <DropdownMenuShortcut>^L</DropdownMenuShortcut>
                                                </DropdownMenuItem>
                                            </DialogTrigger>
                                            <DropdownMenuItem
                                                onClick={async () => {
                                                    const element = document.getElementById(
                                                        "field-" +
                                                            field[0] +
                                                            (vessel ? "--" + vessel.id : ""),
                                                    ) as HTMLTextAreaElement;
                                                    if (!element) {
                                                        return;
                                                    }

                                                    await updateValue({
                                                        id: field[0],
                                                        value: element.value,
                                                    });

                                                    toast.message("Value saved", {
                                                        description:
                                                            'The value "' +
                                                            element.value +
                                                            '" has been saved .',
                                                    });
                                                }}
                                            >
                                                <CloudUpload className="mr-2 h-4 w-4" />
                                                <span>Store current value</span>
                                                <DropdownMenuShortcut>^S</DropdownMenuShortcut>
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                <RecapFieldValueDialog
                                    isOpen={openDialog === field[0]}
                                    fieldId={field[0]}
                                    setValue={setValue}
                                    vessel={vessel}
                                />
                            </Dialog>
                        </FormLabel>
                        <FormControl>
                            <Textarea
                                id={"field-" + field[0] + (vessel ? "--" + vessel.id : "")}
                                {...register(field[0] + (vessel ? "--" + vessel.id : ""))}
                                className={`${getFieldState(field[0] + (vessel ? "--" + vessel.id : "")).isDirty ? "border-primary" : ""}`}
                                rows={4}
                                name={field[0] + (vessel ? "--" + vessel.id : "")}
                                disabled={!isUserActive}
                                onSelectCapture={() => {
                                    handleScrollToFieldInPreview(field[0]);
                                }}
                            ></Textarea>
                        </FormControl>
                    </FormItem>
                ))}
            </div>
        </Panel>
    );
}
