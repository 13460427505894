import React from "react";
import { Container } from "../Registration/Style";
import { AdminLoginPage } from "./Login";
import { AdminPageStyle } from "./Login/Style";

const AdminLogin = () => {
    return (
        <AdminPageStyle>
            <Container>
                <AdminLoginPage />
            </Container>
        </AdminPageStyle>
    );
};

export default AdminLogin;
