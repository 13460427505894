import { FetchChatChannelsResponse, NewChatMessage } from "../../types/chat";
import { tenantApi } from "../tenantApi";

const chatApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getChatChannels: build.query<FetchChatChannelsResponse, void>({
            query: () => ({
                url: "chat/channels",
            }),
            providesTags: (result: FetchChatChannelsResponse) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "ChatChannels", id }) as const)
                    : []),
                { type: "ChatChannels" as const, id: "LIST" },
            ],
        }),
        sendChatMessage: build.mutation<boolean, NewChatMessage & { case_file_id?: string }>({
            query: ({ case_file_id, ...body }) => ({
                url: `/chat/message${case_file_id ? `/${case_file_id}` : ""}`,
                method: "POST",
                body: body,
            }),
        }),
        createNewDirectChatChannel: build.mutation<
            boolean,
            { channel_member_id: string; channel_name: string }
        >({
            query: (body) => ({
                url: "/chat/channels",
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["ChatChannels"],
        }),
        createNewGroupChatChannel: build.mutation<
            boolean,
            { channel_members_ids: Array<string>; channel_name: string }
        >({
            query: (body) => ({
                url: "/chat/channels",
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["ChatChannels"],
        }),
    }),
});

export const {
    useLazyGetChatChannelsQuery,
    useGetChatChannelsQuery,
    useSendChatMessageMutation,
    useCreateNewDirectChatChannelMutation,
    useCreateNewGroupChatChannelMutation,
} = chatApi;
