import React, { Fragment, useEffect, useState } from "react";
import { Avatar, AvatarFallback } from "../../../components/ui/avatar";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../../../components/ui/hover-card";
import { CalendarDays, Pencil } from "lucide-react";
import { formatDistance } from "date-fns";
import { useDocumentEditor } from "../../../lib/providers/DocumentEditorProvider";
import { Button } from "../../../components/ui/button";
import { avatarColors, avatarInitials } from "../../../lib/helpers/avatar";
import { useAuth } from "../../../lib/providers/AuthProvider";

function Collaborators() {
    const {
            activeUsers,
            isUserActive,
            handleGrantAccess,
            activeCollaboratorId,
            fetchData,
            handleChangeMade,
            newChanges,
            document,
        } = useDocumentEditor(),
        echo = window.Echo;

    const [switchingUser, setSwitchingUser] = useState<boolean>(false);

    const { state: userState } = useAuth();

    useEffect(() => {
        return () => {
            echo.leaveAllChannels();
        };
    }, []);

    useEffect(() => {
        if (!isUserActive) {
            setSwitchingUser(false);
        }
    }, [isUserActive]);

    if (document?.finalized) {
        return <Fragment />;
    }

    return (
        <div className="flex gap-x-2 justify-end items-center h-8">
            {!isUserActive() && newChanges && (
                <Button
                    variant="link"
                    onClick={() => {
                        fetchData();
                        handleChangeMade(false);
                    }}
                >
                    Changes were made, click to refresh
                </Button>
            )}
            {activeUsers?.map((member, index) => (
                <div key={index} className={"relative"}>
                    {activeCollaboratorId === member.id ? (
                        <div
                            title={member.name + " is currently editing."}
                            className={
                                "absolute rounded-full h-3 flex items-center justify-center w-3 z-10 text-sky-50 bg-sky-600 outline outline-2 outline-background -right-1 -bottom-1"
                            }
                        >
                            <Pencil width={6} />
                        </div>
                    ) : (
                        ""
                    )}
                    <Avatar
                        className={
                            "h-6 w-6 text-xs overflow-visible " +
                            (isUserActive() ? "cursor-pointer" : "")
                        }
                        key={index}
                    >
                        <HoverCard>
                            <HoverCardTrigger asChild>
                                <AvatarFallback
                                    style={avatarColors(
                                        member.id,
                                        userState.user?.id === member.id,
                                    )}
                                    className={"text-[10px] uppercase overflow-auto"}
                                >
                                    <div className={"w-full text-center overflow-hidden"}>
                                        {avatarInitials(member.name)}
                                    </div>
                                </AvatarFallback>
                            </HoverCardTrigger>
                            <HoverCardContent className="w-48" align="end">
                                <div className="space-y-1">
                                    <h4 className="text-sm font-semibold">{member.name}</h4>
                                    <div className="flex items-center pt-2">
                                        <CalendarDays className="mr-2 h-4 w-4 opacity-70" />{" "}
                                        <span className="text-xs text-muted-foreground">
                                            Joined{" "}
                                            {formatDistance(
                                                new Date(member.user_joined * 1000),
                                                new Date(),
                                                { addSuffix: true },
                                            )}
                                        </span>
                                    </div>
                                    {isUserActive() &&
                                        member.id !== activeCollaboratorId &&
                                        member.allowed_to_edit && (
                                            <Button
                                                size={"sm"}
                                                className={"w-full block !mt-3"}
                                                onClick={async () => {
                                                    setSwitchingUser(true);
                                                    await handleGrantAccess(member.id);
                                                }}
                                                disabled={switchingUser}
                                            >
                                                Make editor
                                            </Button>
                                        )}
                                </div>
                            </HoverCardContent>
                        </HoverCard>
                    </Avatar>
                </div>
            ))}
        </div>
    );
}

export default Collaborators;
