import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import React, { Fragment, useEffect, useState } from "react";
import { useDocumentEditor } from "../../../lib/providers/DocumentEditorProvider";
import { Switch } from "../../../components/ui/switch";
import {
    RecapSingleFieldInterface,
    SyncDocumentFieldInterface,
} from "../../../types/document-editor";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { fetchFieldValues } from "../../../lib/stores/RecapFieldValuesSlice";
import { fetchVessels, setSelectedVessel } from "../../../lib/stores/RecapVesselsSlice";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { ArrowRight, ClipboardPaste } from "lucide-react";
import Loading from "../../../components/global/loading";
import { VESSEL_CATEGORY_UUID, VESSEL_IMO_UUID, VESSEL_NAME_UUID } from "../recaps/recap-utils";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";

export default function SyncData() {
    const { documents, permissions, case_file_id } = useCaseFiles();
    const { smartFields, document_id, updateSmartFieldList, document } = useDocumentEditor();
    const recapIndex = documents.find((document) => document.template_type === "recap");
    const [syncFields, setSyncFields] = useState<Array<SyncDocumentFieldInterface> | undefined>();
    const [fieldsToSync, setFieldsToSync] = useState<string[]>([]);
    const recapFieldValues = useAppSelector((state) => state.recapFieldValues.entities);
    const valuesState = useAppSelector((state) => state.recapFieldValues.loading);
    const vessels = useAppSelector((state) => state.recapVessels.entities);
    const vesselsState = useAppSelector((state) => state.recapVessels.loading);
    const selectedVessel = useAppSelector((state) => state.recapVessels.selected);
    const vesselFieldValue = (vesselId: string, fieldId: string): string | undefined => {
        return recapFieldValues.find(
            (fieldValue) =>
                fieldValue.field_id === fieldId && fieldValue.recap_vessel_id === vesselId,
        )?.value;
    };

    const vesselName = (vesselId: string, vesselIndex: number) => {
        const value = vesselFieldValue(vesselId, VESSEL_NAME_UUID);
        return value ? value : "Unnamed vessel" + (vessels.length > 1 ? ` ${vesselIndex + 1}` : "");
    };

    const vesselImo = (vesselId: string) => {
        const value = vesselFieldValue(vesselId, VESSEL_IMO_UUID);
        return value ? value : "<UNKNOWN>";
    };

    useEffect(() => {
        (async () => {
            if (recapIndex?.id) {
                if (valuesState === "initial") {
                    await dispatch(
                        fetchFieldValues({
                            recapId: recapIndex.id,
                            case_file_id: case_file_id,
                        }),
                    );
                }
                if (vesselsState === "initial") {
                    await dispatch(
                        fetchVessels({
                            recapId: recapIndex.id,
                            case_file_id: case_file_id,
                        }),
                    );
                }
            }
        })();
    }, [recapIndex?.id]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (vesselsState === "complete" && valuesState === "complete") {
            getRecapFields();
        }
    }, [document_id, smartFields, vesselsState, valuesState, selectedVessel]);

    function getRecapFields() {
        if (recapIndex?.id) {
            try {
                const fields: SyncDocumentFieldInterface[] = [];

                smartFields.forEach((smartField) => {
                    const newValue = recapFieldValues?.find(
                        (recapField) =>
                            (smartField.category_id !== VESSEL_CATEGORY_UUID ||
                                recapField.recap_vessel_id === selectedVessel) &&
                            smartField.id === recapField.field_id,
                    )?.value;
                    if (newValue && newValue !== smartField.value) {
                        fields.push({
                            id: smartField.id,
                            value: smartField.value,
                            name: smartField.name,
                            newValue: newValue,
                        });
                    }
                });
                setSyncFields(fields);
                setFieldsToSync(fields.map((field: { id: string }) => field?.id));
            } catch (error) {
                setSyncFields([]);
                setFieldsToSync([]);
            }
        }
    }

    function handleSubmit() {
        const fields: RecapSingleFieldInterface[] = smartFields.map((smartField) => {
            if (fieldsToSync.filter((field) => field === smartField.id).length > 0) {
                const newValue = syncFields?.find(
                    (syncField) => syncField?.id === smartField.id,
                )?.newValue;
                return {
                    ...smartField,
                    value: newValue,
                };
            }
            return smartField;
        });
        updateSmartFieldList(fields);
    }

    function hasSelected(): boolean {
        if (!syncFields) {
            return false;
        }
        return syncFields.filter((field) => fieldsToSync.includes(field.id)).length > 0;
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button
                    variant={"outline"}
                    disabled={!permissions.edit_documents || document?.finalized}
                >
                    <ClipboardPaste className={"h-5 mr-2"} />
                    Sync Recap
                </Button>
            </DialogTrigger>
            <DialogContent>
                <Loading
                    loaded={syncFields !== undefined}
                    child={
                        <Fragment>
                            <DialogHeader>
                                <DialogTitle>Sync with Recap</DialogTitle>
                                <DialogDescription>
                                    Select fields from recap to sync to form
                                </DialogDescription>
                                {vessels.length ? (
                                    <Select
                                        onValueChange={(value) =>
                                            dispatch(setSelectedVessel(value))
                                        }
                                        value={selectedVessel}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a vessel" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {vessels.map((vessel, index) => (
                                                <SelectItem value={vessel.id} key={vessel.id}>
                                                    {vesselName(vessel.id, index)}{" "}
                                                    <span className={"ml-2 text-sm text-slate-500"}>
                                                        IMO: {vesselImo(vessel.id)}
                                                    </span>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                ) : (
                                    ""
                                )}
                            </DialogHeader>
                            {syncFields?.length === 0 ? (
                                <div>All fields are in sync with the recap</div>
                            ) : (
                                syncFields?.map((field) => (
                                    <div
                                        key={field.id}
                                        className={`text-slate-950 ${fieldsToSync.includes(field.id) ? "text-opacity-100" : "text-opacity-50"}`}
                                    >
                                        <div className="flex justify-between">
                                            <div className={"font-medium text-sm"}>
                                                {field.name}
                                            </div>
                                            <Switch
                                                value={field.id}
                                                defaultChecked={fieldsToSync.includes(field.id)}
                                                onCheckedChange={(checked) =>
                                                    checked
                                                        ? setFieldsToSync([
                                                              ...fieldsToSync,
                                                              field.id,
                                                          ])
                                                        : setFieldsToSync([
                                                              ...fieldsToSync.filter(
                                                                  (f) => f !== field.id,
                                                              ),
                                                          ])
                                                }
                                            />
                                        </div>
                                        <div
                                            className={`text-slate-950 ${fieldsToSync.includes(field.id) ? "opacity-100" : "opacity-50"}`}
                                        >
                                            <span
                                                className={`rounded-md inline-block py-0.5 px-2 ${field.value ? "text-red-500 bg-red-100 line-through" : "text-slate-700 bg-slate-200"} `}
                                            >
                                                {field.value ?? "<undefined>"}
                                            </span>
                                            <ArrowRight
                                                className={"inline mx-1 w-3 -mt-0.5 text-slate-500"}
                                            />
                                            <span
                                                className={`rounded-md inline-block py-0.5 px-2 ${field.newValue ? "text-green-700 bg-green-100" : "text-slate-700 bg-slate-200"}`}
                                            >
                                                {field.newValue ?? "<undefined>"}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            )}
                            <DialogFooter>
                                <DialogClose>
                                    <Button variant={"secondary"} type="button">
                                        Cancel
                                    </Button>
                                </DialogClose>
                                {!hasSelected() ? (
                                    <Button disabled>Sync</Button>
                                ) : (
                                    <DialogClose>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        >
                                            Sync
                                        </Button>
                                    </DialogClose>
                                )}
                            </DialogFooter>
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}
