import React from "react";

type ParseDialogErrorProps = {
    error: string | null;
};

function ParseDialogError({ error }: ParseDialogErrorProps) {
    if (!error) {
        return;
    }

    return <div>{error}</div>;
}

export default ParseDialogError;
