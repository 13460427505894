import { Outlet } from "react-router";
import TopBar from "../components/global/top-bar";
import React from "react";
import { ChatProvider } from "../lib/providers/chat-provider";
import { NotificationProvider } from "../lib/providers/notifications-provider";
import NavBar from "../components/nav-bar/nav-bar";

export function TenantBasePage() {
    return (
        <ChatProvider>
            <NotificationProvider>
                <div className="flex relative w-full flex-col">
                    <TopBar />
                    <div className="flex mt-12 flex-row h-full max-h-[calc(100%-40px)]">
                        <NavBar />
                        <div className="ml-12 w-full flex flex-col">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </NotificationProvider>
        </ChatProvider>
    );
}
