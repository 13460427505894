import { useExportDocumentMutation } from "../../../api/tenant/case-file";
import { GlobalDocumentInterface } from "../../../types/document-editor";
import React, { Fragment, useState } from "react";
import { DialogFooter, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import { saveAs } from "file-saver";
import { TableActionProps } from "../../../types/table";
import { useLazyDownloadBlobQuery } from "../../../api/cookieApi";
import { getColors } from "../../../lib/utils";
import { ColorSchemas } from "../../../types";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import Loading from "../../../components/global/loading";
import { toast } from "sonner";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function ExportDocument({
    row,
    onClose,
}: TableActionProps<CaseFileDocumentIndex | GlobalDocumentInterface>) {
    const [schema, setSchema] = useState<ColorSchemas>("default");
    const [exportDocument] = useExportDocumentMutation();
    const [downloadBlob] = useLazyDownloadBlobQuery();
    const [loading, setLoading] = useState<boolean>(false);

    const { case_file_id } = useCaseFiles();

    async function handleSubmit() {
        setLoading(true);
        try {
            const downloadUrl = await exportDocument({
                id: row.id,
                case_file_id: case_file_id,
                schema: getColors(schema),
            }).unwrap();

            const blob = await downloadBlob(`${downloadUrl}&caseFile=${case_file_id}`).unwrap();

            saveAs(blob, `${row.document_name}.pdf`);
            onClose();
        } catch (e) {
            toast.error("Something went wrong while exporting the file");
        } finally {
            setLoading(false);
        }
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Export {row.document_name}</DialogTitle>
                    </DialogHeader>
                    <Select
                        defaultValue="default"
                        onValueChange={(e: ColorSchemas) => setSchema(e)}
                    >
                        <SelectTrigger>
                            <SelectValue defaultValue="default" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="default">Ocean Recap scheme</SelectItem>
                            <SelectItem value="traditional">Traditional scheme</SelectItem>
                            <SelectItem value="black">Black scheme</SelectItem>
                        </SelectContent>
                    </Select>

                    <DialogFooter className="mt-4">
                        <Button type="submit" onClick={handleSubmit}>
                            Download
                        </Button>
                    </DialogFooter>
                </Fragment>
            }
        />
    );
}

export default ExportDocument;
