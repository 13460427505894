import { Addenda } from "./addenda";
import { TenantUser } from "./auth";
import { LucideProps } from "lucide-react";

type CaseFileIndex = {
    id: string;
    document_name: string;
    document_type: DocumentLoadType;
    document_type_formatted?: string;
    load_port?: string;
    discharge_port?: string;
    vessel_name?: string;
    cp_date: Date | string;
    collaborate_url?: string;
    revision: number;
    revisions: number;
    reference: number;
    finalized: boolean;
    finalize_date: Date;
    author: string;
    status: CaseFileStatusType;
    updated_at: Date;
    created_at: Date;
    deleted_at: Date;
    email?: string;
    collaborators: number;
    documents_count: number;
    new_messages: number;
    charterer: string;
    owner: string;
    broker: string;
    supply_chain_link_count: number;
    is_supply_chain: boolean;
    supply_chain_sender?: string;
    is_owner: boolean;
    supply_chain_id?: string;
    shared_by?: string;
    shared_with?: Array<string>;
    notify: boolean;
    is_following: boolean;
};

type DocumentExportIconProps = {
    item: { type: string };
} & LucideProps;

type DocumentsTableProps = {
    data: Array<CaseFileIndex>;
};

type CaseFileChatProps = {
    id: string;
};

type EditStatusDocumentProps = {
    status: CaseFileStatusType;
    isSelected: boolean;
    onSubmit: (status: CaseFileStatusType) => void;
};

type CaseFileDocumentsTableProps = {
    data: Array<CaseFileDocumentIndex>;
};

type CaseFileDocumentIndex = {
    id: string;
    is_smart: boolean;
    document_name: string;
    template_name: string;
    template_type: DocumentTemplateTypes;
    file_type: string | undefined;
    user: string;
    finalized: boolean;
    updated_at: Date;
};

type RotOrganisation = {
    name: string;
    id: string;
    users: Array<Pick<TenantUser, "id" | "name" | "email" | "tenant_id">>;
};

type SupplyChainInvite = {
    id: string;
    company_name: string;
    permission_set: PermissionTypes;
    created_at: Date;
    additional_data: {
        users_to_notify: Array<Pick<TenantUser, "id" | "name" | "email" | "tenant_id">>;
    };
    approval_status?: ApprovalStatusTypes;
    approval_id?: string;
    receiver_id: string;
};

type CreateSupplyChainInvite = {
    organisation: string;
    permissions: PermissionTypes;
    case_file_id: string;
    users_to_notify?: Array<Pick<TenantUser, "id" | "name" | "email" | "tenant_id">>;
    note?: string;
};

type RotDropDownActions =
    | "edit-permission"
    | "request-approval"
    | "remove-access"
    | "remove-approval";

type RotDropDownProps = {
    rot_link: SupplyChainInvite;
    setSelectedAction: (e?: RotDropDownActions) => void;
};

type RotActions = {
    rot_link: SupplyChainInvite;
    onClose: () => void;
};

type RemoveRotApproval = {
    approval_id: string;
    case_file_id: string;
    amount_of_approvals: number;
    supply_chain_id?: string;
};

type AuditTrail = {
    entry_type: string;
    created_at: Date;
    company_name: string;
    user_name: string;
    log_record: string;
    id: string;
};

type RecentActivity = {
    document_id: string;
    created_at: Date;
    tenant: string;
    user: string;
    message: string;
    template: string | null;
    total_revenue: number;
    copyright_revenue: number;
    service_revenue: number;
    type: RecentActivityTypes | null;
};

type NewDocumentProps = {
    id: string;
    onClose: () => void;
    isOpen?: boolean;
    isFirstCharterParty?: boolean;
};

type DocumentComponentProps = NewDocumentProps & { documentType: DocumentTypes };

type TemplatesType = {
    [key: string]: Array<TemplateIndex>;
};

type SupplyChainInfo = {
    sender_id: string;
    permission_set: PermissionTypes;
    hasApprovals: boolean;
    socket_channel: string;
};

type DocumentTypes = "document" | "upload" | "pro-forma" | "import-q88" | "none";
type RecentActivityTypes =
    | "file_exported"
    | "file_finalised"
    | "file_remove"
    | "file_add"
    | "file_unfinalised"
    | "standard_form"
    | "house_form"
    | "sign_invite"
    | "login";
type ApprovalStatusTypes = "pending" | "approved" | "denied";
type DocumentTemplateTypes =
    | "standard_form"
    | "bill_of_lading"
    | "sof"
    | "nor"
    | "loi"
    | "house_form"
    | "recap"
    | "file_upload"
    | "q88";
type PermissionTypes = "full_access" | "read_only_extended" | "read_only";
type CaseFileStatusType =
    | "draft"
    | "on_subs"
    | "clean_fixed"
    | "cancelled"
    | "fully_approved"
    | "under_approval"
    | "approval_rejected";
type DocumentLoadType =
    | "standard_form"
    | "riders"
    | "bill_of_lading"
    | "house_form"
    | "q88"
    | "other"
    | "recap"
    | "addenda"
    | "file_upload";

type TemplateIndex = {
    id: string;
    name: string;
    is_smart: boolean;
};

type TemplateTypes = "standard_form" | "bill_of_lading" | "sof" | "nor" | "loi" | "house_form";

type CaseFileStatusTypeArray = Array<Partial<CaseFileStatusType>>;

type FetchCaseFiles = {
    data: Array<CaseFileIndex>;
};

type ExportableListType = {
    id: string;
    name: string;
    type: DocumentLoadType;
    extension: string;
};

type CaseFileMetaData = {
    id: string;
    document_name: string;
    additional_data: {
        broker: string;
        owner: string;
        charterer: string;
        socket_channel: string;
    };
    supply_chain_id: string;
    supply_chain_id_encrypted: string;
    permission_set: PermissionTypes;
    original_cf_id: string;
    status: CaseFileStatusType;
    has_rider_terms: boolean;
    has_addenda: boolean;
    is_following: boolean;
    reference: number;
    vessel_name?: string;
    sender_id?: string;
};

type AddFileProps = {
    id: string;
    TriggerElement?: JSX.Element;
};

type CirculateCaseFileProps = {
    open: boolean;
    onClose: () => void;
};

type CirculateCaseFileRequest = {
    data: Array<ExportableListType>;
    emails: Array<string>;
    note: string | undefined;
    inline: boolean | undefined;
};

type CaseFileContextType = {
    documents: Array<CaseFileDocumentIndex>;
    setActivePage: (page: CaseFilePages) => void;
    activePage: CaseFilePages;
    case_file_id: string;
    rot_links: Array<SupplyChainInvite>;
    permissions: CaseFilePermissionsInterface;
    supply_chain_info?: SupplyChainInfo;
    is_owner: boolean;
    modalState: CaseFileModalState;
    dispatchModalState: (actions: CaseFileModalAction) => void;
    meta_data?: CaseFileMetaData;
    loaded: boolean;
    original_cf_id: string;
    approval: boolean;
    approvals?: Array<ApprovalsInterface>;
    update_meta_date: (data: CaseFileMetaData) => void;
};

type CaseFilesProviderState = {
    documents: Array<CaseFileDocumentIndex>;
    page: CaseFilePages;
    rot_links: Array<SupplyChainInvite>;
    is_owner: boolean;
    permissions?: CaseFilePermissionsInterface;
    loaded: boolean;
    supply_chain_info?: SupplyChainInfo;
    meta_data?: CaseFileMetaData;
    case_file_id: string;
    original_cf_id: string;
    approvals?: Array<ApprovalsInterface>;
    addenda: Array<Addenda>;
};

type ApprovalsInterface = {
    id: string;
    status: ApprovalStatusTypes;
    updated_at: Date;
    company_name: string;
    tenant_id: string;
};

type CaseFilesProviderProps = {
    case_file_id: string;
    children: React.ReactNode;
};

type CaseFilePages =
    | "overview"
    | "recap"
    | "document"
    | "riders"
    | "addenda"
    | "attachments"
    | "other-documents"
    | "audit-trail";

type CaseFilePermissionsInterface = {
    create_documents: boolean;
    edit_documents: boolean;
    delete_documents: boolean;
    duplicate_documents: boolean;
    finalize_documents: boolean;
    un_finalize_documents: boolean;
    restore_documents: boolean;
    sign_documents: boolean;
    accept_amendments: boolean;
    generate_pro_forma_code: boolean;
    add_as_house_form: boolean;
    upload_documents: boolean;
    add_addendum: boolean;
};

enum CaseFileActions {
    SHOW_NEW_DOCUMENT,
}

type CaseFileModalAction = {
    type: CaseFileActions;
    payload: boolean;
};

type CaseFileModalState = {
    showModal: boolean;
    modalType?: CaseFileActions;
};

export {
    CaseFileStatusType,
    CaseFileStatusTypeArray,
    DocumentLoadType,
    CaseFileIndex,
    DocumentsTableProps,
    AddFileProps,
    CaseFileChatProps,
    CaseFileDocumentsTableProps,
    DocumentExportIconProps,
    CaseFileDocumentIndex,
    RotOrganisation,
    SupplyChainInvite,
    PermissionTypes,
    CreateSupplyChainInvite,
    RotDropDownProps,
    RotActions,
    RemoveRotApproval,
    AuditTrail,
    NewDocumentProps,
    DocumentComponentProps,
    DocumentTypes,
    TemplatesType,
    DocumentTemplateTypes,
    SupplyChainInfo,
    ApprovalStatusTypes,
    TemplateIndex,
    TemplateTypes,
    FetchCaseFiles,
    ExportableListType,
    CaseFileMetaData,
    EditStatusDocumentProps,
    RecentActivity,
    CirculateCaseFileProps,
    CirculateCaseFileRequest,
    CaseFileContextType,
    CaseFilesProviderState,
    ApprovalsInterface,
    CaseFilesProviderProps,
    CaseFilePages,
    CaseFilePermissionsInterface,
    CaseFileActions,
    CaseFileModalAction,
    CaseFileModalState,
};
