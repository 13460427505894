import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { format, isValid } from "date-fns";
import { AdminInvoiceItem } from "../Central/Admin/Invoices";

const columns: ColumnDef<AdminInvoiceItem>[] = [
    {
        accessorKey: "id",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Id" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Id",
        },
    },
    {
        accessorKey: "number",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Number" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Number",
        },
    },
    {
        accessorKey: "end_date",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="End Date (CEST)" type="date-range" />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "End Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("end_date") as string;
            return <span>{isValid(value) ? format(new Date(value), "PPP") : ""}</span>;
        },
    },
    {
        accessorKey: "subtotal",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Subtotal" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Subtotal",
        },
        cell: ({ row }) => {
            const value = row.getValue("subtotal") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
    {
        accessorKey: "total",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Total" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Total",
        },
        cell: ({ row }) => {
            const value = row.getValue("total") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
    {
        accessorKey: "status",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Status" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Status",
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Date (CEST)" type="date-range" />;
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "updated_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="Update At (CEST)" type="date-range" />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("updated_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "subject",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Subject" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Subject",
        },
    },
    {
        accessorKey: "date_paid",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="Date Paid (CEST)" type="date-range" />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Date Paid (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("date_paid") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "run_date",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="Run Date (CEST)" type="date-range" />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Run Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("run_date") as string;
            return <span>{isValid(value) ? format(new Date(value), "PPP") : ""}</span>;
        },
    },
    {
        accessorKey: "start_date",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Start Date (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Start Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("start_date") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "tenant",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Tenant" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Tenant",
        },
    },
    {
        accessorKey: "title",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Title" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Title",
        },
    },
    {
        accessorKey: "due_date",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="Due Date (CEST)" type="date-range" />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Due Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("due_date") as string;
            return <span>{isValid(value) ? format(new Date(value), "PPP") : ""}</span>;
        },
    },
];

export { columns };
