import { Typography } from "../../../components/ui/typography";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
} from "../../../components/ui/context-menu";
import { Button } from "../../../components/ui/button";
import { addVessel, removeVessel, setSelectedVessel } from "../../../lib/stores/RecapVesselsSlice";
import { Plus } from "lucide-react";
import { RecapCategory } from "./recap-category";
import { Form } from "../../../components/ui/form";
import React, {
    ForwardedRef,
    forwardRef,
    RefAttributes,
    useCallback,
    useEffect,
    useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";
import { Schema, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRemoveRecapStructureMutation } from "../../../api/tenant/recap";
import { RecapInterface } from "../../../types/recap";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { VESSEL_CATEGORY_UUID, VESSEL_IMO_UUID, VESSEL_NAME_UUID } from "./recap-utils";
import { useGetUploadedFilesQuery } from "../../../api/tenant/attachments";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { ActiveUsersInterface } from "../../../types/document-editor";
import { UploadedFile } from "../../../types/uploads";
import debounce from "lodash/debounce";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import RecapClauses, { RecapClause } from "./recap-clauses";
import { updateFieldValue } from "../../../lib/stores/RecapFieldValuesSlice";
import { toast } from "sonner";
import { tenantApi } from "../../../api/tenantApi";
import { updateClauseValue } from "../../../lib/stores/RecapClauseValueSlice";

interface RecapFormInterface {
    recap: RecapInterface;
    setIsDirty: (isDirty: boolean) => void;
    handleQ88: (q88: UploadedFile, vesselId?: string) => void;
    schema: Schema;
    defaultValues: {
        [key: string]: string | Pick<RecapClause, "title" | "value">;
    };
    categorySlug: (categoryId: string) => string;
    categoryName: (categoryName: string) => string;
    activeUsers: Array<ActiveUsersInterface>;
    activeCollaboratorId: string;
    isUserActive: boolean;
    showPreview: boolean;
}

export const RecapForm = forwardRef(
    (
        props: RecapFormInterface,
        ref: ForwardedRef<{
            updateValues: (values: { [key: string]: string }) => void;
            submitForm: () => void;
        } | null> &
            RefAttributes<HTMLFormElement>,
    ) => {
        const {
            recap,
            setIsDirty,
            handleQ88,
            schema,
            defaultValues,
            categorySlug,
            categoryName,
            isUserActive,
            showPreview,
        } = props;
        const vessels = useAppSelector((state) => state.recapVessels.entities);
        const selectedVessel = useAppSelector((state) => state.recapVessels.selected);
        const recapFieldValues = useAppSelector((state) => state.recapFieldValues.entities);
        const [removeRecapFields] = useRemoveRecapStructureMutation();
        const dispatch = useAppDispatch();

        const { case_file_id } = useCaseFiles();
        const { data: uploads, isLoading: uploadsLoading } = useGetUploadedFilesQuery(case_file_id);
        const form = useForm<z.infer<typeof schema>>({
            resolver: zodResolver(schema),
            defaultValues: defaultValues,
        });

        useImperativeHandle(ref, () => ({
            updateValues: (fields) => {
                Object.entries(fields).forEach((field) => {
                    form.setValue(field[0], field[1], { shouldDirty: true });
                });
            },
            submitForm: async () => {
                await onSubmit({ values: form.getValues(), autoSave: false });
            },
        }));

        useEffect(() => {
            setIsDirty(form.formState.isDirty);
        }, [form.formState.isDirty]);

        const debouncedOnChange = useCallback(
            debounce(
                async () => {
                    await onSubmit({ values: form.getValues(), autoSave: true });
                },
                2000,
                {
                    leading: false,
                    trailing: true,
                },
            ),
            [],
        );

        useEffect(() => {
            (async () => {
                await debouncedOnChange();
            })();
        }, [form.watch()]);

        async function onSubmit(payload: { values: z.infer<typeof schema>; autoSave: boolean }) {
            const { values, autoSave } = payload;

            try {
                for (const value of Object.entries(values)) {
                    if (form.getFieldState(value[0]).isDirty) {
                        if (typeof value[1] === "string") {
                            if (value[0].includes("--")) {
                                const parts = value[0].split("--");
                                await dispatch(
                                    updateFieldValue({
                                        recap_document_id: recap.id,
                                        field_id: parts[0],
                                        recap_vessel_id: parts[1],
                                        value: value[1] as string,
                                        case_file_id: case_file_id,
                                    }),
                                );
                            } else {
                                await dispatch(
                                    updateFieldValue({
                                        recap_document_id: recap.id,
                                        field_id: value[0],
                                        value: value[1] as string,
                                        case_file_id: case_file_id,
                                    }),
                                );
                            }
                        } else {
                            const clause = value[1] as Pick<
                                RecapClause,
                                "title" | "value" | "visible"
                            >;

                            await dispatch(
                                updateClauseValue({
                                    recap_document_id: recap.id,
                                    id: value[0],
                                    case_file_id: case_file_id,
                                    title: clause.title,
                                    value: clause.value,
                                    visible: clause.visible,
                                }),
                            );
                        }
                    }
                }
                if (!autoSave) {
                    toast.success("Recap saved successfully.");
                }
                dispatch(tenantApi.util?.invalidateTags(["CaseFiles"]));
                form.reset(values);
            } catch (error) {
                toast.error("Saving failed, changes to Recap not saved.");
            }
        }

        async function deleteVessel(vesselId: string) {
            if (!recap) {
                return;
            }
            await dispatch(
                removeVessel({ case_file_id: case_file_id, recapId: recap.id, vesselId: vesselId }),
            );
        }

        async function removeField(fieldId: string) {
            if (!recap) {
                return;
            }

            await removeRecapFields({
                id: recap.id,
                fields: [fieldId],
                case_file_id: case_file_id,
            });
        }

        async function newVessel() {
            if (!recap) {
                return;
            }
            await dispatch(addVessel({ recap_document_id: recap.id, case_file_id: case_file_id }));
        }

        const vesselFieldValue = (vesselId: string, fieldId: string): string | undefined => {
            return recapFieldValues.find(
                (fieldValue) =>
                    fieldValue.field_id === fieldId && fieldValue.recap_vessel_id === vesselId,
            )?.value;
        };

        const vesselName = (vesselId: string, vesselIndex: number) => {
            const value = vesselFieldValue(vesselId, VESSEL_NAME_UUID);
            return value
                ? value
                : "Unnamed vessel" + (vessels.length > 1 ? ` ${vesselIndex + 1}` : "");
        };

        const vesselImo = (vesselId: string) => {
            const value = vesselFieldValue(vesselId, VESSEL_IMO_UUID);
            return value ? value : "<UNKNOWN>";
        };

        function focusVesselField(fieldId: string, vesselId?: string) {
            if (!vesselId) {
                return;
            }

            const field = document.getElementById("field-" + fieldId + "--" + vesselId);
            if (field) {
                // Dirty hack to force ui to be drawn
                setTimeout(() => {
                    if (vesselId !== selectedVessel) {
                        dispatch(setSelectedVessel(vesselId));
                    }
                    field.focus();
                }, 1);
            }
        }

        return (
            <Form {...form}>
                <form className={"relative pt-12"} onSubmit={form.handleSubmit(onSubmit)}>
                    {Object.entries(recap.structure)
                        .filter((structure) => structure[0] !== "recap-clauses")
                        .map((category, categoryIndex) => (
                            <div key={category[0]} tabIndex={-1}>
                                <div
                                    id={`section-${categorySlug(category[0])}`}
                                    className={"absolute -mt-44"}
                                    tabIndex={-1}
                                ></div>
                                {category[0] === VESSEL_CATEGORY_UUID ? (
                                    <div
                                        className={`sticky flex top-44 ${vessels.length > 0 ? "border-b" : ""} bg-background overflow-hidden w-full z-30 pb-4 gap-2`}
                                        tabIndex={-1}
                                    >
                                        {vessels.length === 0 ? (
                                            <Typography
                                                text="No vessels added yet"
                                                className="pb-1"
                                                style="h3"
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <ScrollArea type={"always"} className="grow -mb-3">
                                            <div className="flex flex-grow gap-2 pb-3">
                                                {vessels.map((vessel, vesselIndex) => (
                                                    <ContextMenu key={vessel.id}>
                                                        <ContextMenuTrigger>
                                                            <Button
                                                                className={
                                                                    "py-2 text-left h-auto flex-col items-start"
                                                                }
                                                                onClick={() => {
                                                                    dispatch(
                                                                        setSelectedVessel(
                                                                            vessel.id,
                                                                        ),
                                                                    );
                                                                }}
                                                                type={"button"}
                                                                variant={
                                                                    selectedVessel === vessel.id
                                                                        ? "default"
                                                                        : "ghost"
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        "uppercase max-w-48 truncate"
                                                                    }
                                                                >
                                                                    {vesselName(
                                                                        vessel.id,
                                                                        vesselIndex,
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className={"text-xs opacity-75"}
                                                                >
                                                                    IMO: {vesselImo(vessel.id)}
                                                                </div>
                                                            </Button>
                                                        </ContextMenuTrigger>
                                                        <ContextMenuContent
                                                            onCloseAutoFocus={(event) =>
                                                                event.preventDefault()
                                                            }
                                                        >
                                                            <ContextMenuItem
                                                                onClick={() => {
                                                                    focusVesselField(
                                                                        VESSEL_NAME_UUID,
                                                                        vessel.id,
                                                                    );
                                                                }}
                                                                disabled={!isUserActive}
                                                            >
                                                                Rename
                                                            </ContextMenuItem>
                                                            <ContextMenuItem
                                                                onClick={() => {
                                                                    focusVesselField(
                                                                        VESSEL_IMO_UUID,
                                                                        vessel.id,
                                                                    );
                                                                }}
                                                                disabled={!isUserActive}
                                                            >
                                                                Set IMO
                                                            </ContextMenuItem>
                                                            {!uploadsLoading &&
                                                            uploads?.filter((x) => x.imo).length ? (
                                                                <ContextMenuSub>
                                                                    <ContextMenuSubTrigger
                                                                        disabled={!isUserActive}
                                                                        className={
                                                                            !isUserActive
                                                                                ? "opacity-50"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        Insert Q88
                                                                    </ContextMenuSubTrigger>
                                                                    <ContextMenuSubContent>
                                                                        {uploads
                                                                            ?.filter((x) => x.imo)
                                                                            .map((q88, index) => (
                                                                                <ContextMenuItem
                                                                                    key={index}
                                                                                    onClick={() =>
                                                                                        handleQ88(
                                                                                            q88,
                                                                                            vessel.id,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        q88.document_name
                                                                                    }
                                                                                </ContextMenuItem>
                                                                            ))}
                                                                    </ContextMenuSubContent>
                                                                </ContextMenuSub>
                                                            ) : null}

                                                            <ContextMenuSeparator />
                                                            <ContextMenuItem
                                                                onClick={() =>
                                                                    deleteVessel(vessel.id)
                                                                }
                                                                disabled={!isUserActive}
                                                            >
                                                                Delete vessel
                                                            </ContextMenuItem>
                                                        </ContextMenuContent>
                                                    </ContextMenu>
                                                ))}
                                            </div>
                                            <ScrollBar orientation="horizontal" />
                                        </ScrollArea>
                                        <Button
                                            onClick={() => newVessel()}
                                            variant="link"
                                            type={"button"}
                                            disabled={!isUserActive}
                                        >
                                            <Plus className="w-4 h4" />
                                        </Button>
                                    </div>
                                ) : (
                                    <Typography
                                        text={categoryName(category[0])}
                                        className="pb-1 border-b z-10 sticky bg-background top-44"
                                        style="h3"
                                    />
                                )}
                                {category[0] === VESSEL_CATEGORY_UUID ? (
                                    <div className={"relative w-full"} tabIndex={-1}>
                                        {vessels.map((vessel) => (
                                            <RecapCategory
                                                getFieldState={form.getFieldState}
                                                className={`${selectedVessel === vessel.id ? "opacity-100 top-0 relative z-10" : "opacity-0 top-0 absolute z-0"} ${categoryIndex === 0 ? "pt-8" : ""} w-full rounded-t-none border-t-0`}
                                                key={vessel.id}
                                                category={category[1]}
                                                setValue={form.setValue}
                                                register={form.register}
                                                vessel={vessel}
                                                removeField={removeField}
                                                isUserActive={isUserActive}
                                                showPreview={showPreview}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <RecapCategory
                                        setValue={form.setValue}
                                        register={form.register}
                                        getFieldState={form.getFieldState}
                                        removeField={removeField}
                                        category={category[1]}
                                        className={`rounded-t-none ${categoryIndex === 0 ? "pt-8" : ""} border-t-0`}
                                        isUserActive={isUserActive}
                                        showPreview={showPreview}
                                    />
                                )}
                            </div>
                        ))}
                    <RecapClauses
                        recap={recap}
                        register={form.register}
                        setValue={form.setValue}
                        getValue={form.getValues}
                        isUserActive={isUserActive}
                    />
                </form>
            </Form>
        );
    },
);

RecapForm.displayName = "RecapForm";
