import React, { Fragment, useState } from "react";
import {
    NewRecapCategoryInterface,
    RecapCategoryInterface,
    RecapModalInterface,
} from "../../index.interface";
import { Form, Formik } from "formik";
import {
    createRecapClauseCategory,
    createRecapFieldCategory,
    updateRecapClauseCategory,
    updateRecapFieldCategory,
} from "../../../../Admin/Recap";
import { Modals } from "../../../../../components/style-components/Modals";
import { ModalBody } from "../../../../../components/style-components/Modals/Finalize";
import {
    Bar,
    CancelButton,
    FormButtonGroup,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
} from "../../../../Form/FormField/style";
import { LoadingSpinner } from "../../../../Components/Loading";

export function RecapFieldCategoryModal({
    open,
    onClose,
    row,
    type,
}: RecapModalInterface<RecapCategoryInterface>) {
    const [clicked, setClicked] = useState<boolean>(false);

    const initialValues: NewRecapCategoryInterface = {
        name: row?.name ?? "",
    };

    function handleSubmit(values: NewRecapCategoryInterface) {
        setClicked(true);
        if (type === "field") {
            if (row) {
                updateRecapFieldCategory(values, row.id)
                    .then(() => setClicked(false))
                    .then(onClose);
            } else {
                createRecapFieldCategory(values)
                    .then(() => setClicked(false))
                    .then(onClose);
            }
        }
        if (type === "clause") {
            if (row) {
                updateRecapClauseCategory(values, row.id)
                    .then(() => setClicked(false))
                    .then(onClose);
            } else {
                createRecapClauseCategory(values)
                    .then(() => setClicked(false))
                    .then(onClose);
            }
        }
    }

    return (
        <Modals
            head={
                <h1>
                    {!row ? "Add" : "Update"} a new recap {type} category
                </h1>
            }
            body={
                <ModalBody>
                    {!clicked && (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => handleSubmit(values)}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            name="name"
                                            autoCorrect="off"
                                            autoComplete="name"
                                            valid={touched.name && !errors.name}
                                            error={touched.name && errors.name}
                                        />
                                        <Bar />
                                        <Label htmlFor="name">Category</Label>
                                        {errors.name && touched.name && (
                                            <StyledInlineErrorMessage>
                                                {errors.name}
                                            </StyledInlineErrorMessage>
                                        )}
                                    </InputGroup>
                                    <FormButtonGroup>
                                        <CancelButton type="button" onClick={onClose}>
                                            Cancel
                                        </CancelButton>
                                        <SubmitButton type="submit">
                                            {!row ? "Create" : "Update"}
                                        </SubmitButton>
                                    </FormButtonGroup>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {clicked && (
                        <Fragment>
                            <LoadingSpinner />
                        </Fragment>
                    )}
                </ModalBody>
            }
            open={open}
            closeOnClick={true}
            onClose={onClose}
            size="small"
        />
    );
}
