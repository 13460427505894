import {
    DashboardTopBarContainerContent,
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentRange,
    DashboardTopBarContainerContentRangeSelector,
    DashboardTopBarContainerContentTitle,
} from "../../Style";
import { Bar } from "react-chartjs-2";
import React from "react";
import { DashboardAccountTrendsInterface } from "../../index.interface";
import { accountOptions } from "../../config";

export function DashboardAccountTrends({ data }: DashboardAccountTrendsInterface) {
    return (
        <DashboardTopBarContainerContent>
            <DashboardTopBarContainerContentHeader>
                <DashboardTopBarContainerContentTitle>
                    Account Trends
                </DashboardTopBarContainerContentTitle>
                <DashboardTopBarContainerContentRange>
                    <DashboardTopBarContainerContentRangeSelector>
                        <option>Past 6 months</option>
                    </DashboardTopBarContainerContentRangeSelector>
                </DashboardTopBarContainerContentRange>
            </DashboardTopBarContainerContentHeader>
            {data.datasets && <Bar options={accountOptions} data={data} />}
        </DashboardTopBarContainerContent>
    );
}
