import { TableActionProps } from "../../../types/table";
import { UploadedFile } from "../../../types/uploads";
import React, { Fragment } from "react";
import { SheetTitle } from "../../../components/ui/sheet";
import { useDownloadFileQuery } from "../../../api/tenant/attachments";
import Loading from "../../../components/global/loading";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button } from "../../../components/ui/button";
import { saveAs } from "file-saver";
import { DialogHeader } from "../../../components/ui/dialog";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

export function getFileExtension(document_name: string) {
    const split = document_name.split(".");
    if (split.length > 1) {
        return document_name.split(".").reverse()[0] ?? "pdf";
    } else {
        return "pdf";
    }
}

export function canPreview(file: UploadedFile): boolean {
    const excludedRenders = ["odt", "doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    return DocViewerRenderers.flatMap((render) =>
        excludedRenders.includes(render.fileTypes[0]) ? [] : render.fileTypes,
    ).includes(file.type);
}

function AttachmentsPreview({ row }: TableActionProps<UploadedFile>) {
    const { case_file_id } = useCaseFiles();
    const { data, isLoading } = useDownloadFileQuery({
        id: row.id,
        case_file_id: case_file_id,
    });
    function download() {
        let fileName = row.file_name;
        if (row.type === "q88" && !fileName.endsWith(".pdf")) {
            fileName = row.file_name + ".pdf";
        }

        saveAs(data as Blob, fileName);
        // TODO add audit trail entry
    }

    return (
        <Fragment>
            <DialogHeader>
                <SheetTitle className="text-slate-950">Preview {row.document_name}</SheetTitle>
            </DialogHeader>
            <Button className="mt-2" onClick={download}>
                Download
            </Button>
            <div>
                <Loading
                    loaded={!isLoading}
                    child={
                        <Fragment>
                            {data && canPreview(row) && (
                                <DocViewer
                                    documents={[
                                        {
                                            uri: data as string,
                                            fileType: getFileExtension(row.file_name),
                                        },
                                    ]}
                                    config={{
                                        header: {
                                            disableFileName: true,
                                            disableHeader: true,
                                        },
                                        pdfZoom: {
                                            defaultZoom: 1.0,
                                            zoomJump: 0.4,
                                        },
                                        pdfVerticalScrollByDefault: true,
                                    }}
                                    pluginRenderers={DocViewerRenderers}
                                    prefetchMethod="GET"
                                    style={{}}
                                />
                            )}
                        </Fragment>
                    }
                />
            </div>
        </Fragment>
    );
}

export default AttachmentsPreview;
