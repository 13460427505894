import React, { createContext, useContext, useEffect, useState } from "react";
import { Addenda, AddendaContextType, AddendaModalState } from "../../types/addenda";
import { ProviderWithChildren } from "../../types";
import { toast } from "sonner";
import { useGetCaseFileAddendaQuery, useSaveAddendumMutation } from "../../api/tenant/addenda";
import { useCaseFiles } from "./CaseFilesProvider";

const AddendaContext = createContext<AddendaContextType>(null!);

function useAddenda() {
    return useContext(AddendaContext);
}

function AddendaProvider({ children }: ProviderWithChildren) {
    const { case_file_id, meta_data, update_meta_date } = useCaseFiles();
    const [addendumInCreation, setAddendumInCreation] =
        useState<Pick<Addenda, "title" | "content">>();
    const [addendaList, setAddendaList] = useState<Array<Addenda>>();
    const [activeAddendum, setActiveAddendum] = useState<Addenda>();
    const [modalState, setModalState] = useState<AddendaModalState>();
    const [resetFormState, setResetFormState] = useState<boolean>(false);

    const { data: addenda } = useGetCaseFileAddendaQuery(case_file_id);
    const exposedValues: AddendaContextType = {
        handleFormChange: handleFormChange,
        addendumInCreation: addendumInCreation,
        addenda: addendaList,
        handleSave: handleSave,
        activeAddendum: activeAddendum,
        setActiveAddendum: (addendum) => setActiveAddendum(addendum),
        setModalState: (state) => setModalState(state),
        modalState: modalState,
        resetFormState: resetFormState,
    };
    const [saveAddendum] = useSaveAddendumMutation();

    useEffect(() => {
        if (addenda?.data) {
            setAddendaList(addenda.data);
        }
    }, [addenda]);

    useEffect(() => {
        if (addendaList && meta_data) {
            update_meta_date({
                ...meta_data,
                has_addenda: addendaList.length > 0,
            });
        }
    }, [addendaList]);

    async function handleSave() {
        if (addendumInCreation?.title && addendumInCreation?.content) {
            await saveAddendum({
                case_file_id: case_file_id,
                ...addendumInCreation,
                id: activeAddendum?.id,
            }).unwrap();

            toast.message(`Successfully ${activeAddendum ? "updated" : "created"} addendum`);

            setAddendumInCreation(addendumInCreation);
            setResetFormState(true);
        } else {
            toast.error("Please fill out the title and content fields.");
            return;
        }
    }

    function handleFormChange(form: Pick<Addenda, "title" | "content">) {
        setAddendumInCreation(form);
    }

    return <AddendaContext.Provider value={exposedValues}>{children}</AddendaContext.Provider>;
}

export { useAddenda, AddendaProvider };
