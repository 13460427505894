import { SheetHeader } from "../ui/sheet";
import { Typography } from "../ui/typography";
import { Separator } from "../ui/separator";
import React, { Fragment } from "react";

function ChatMessageHeader() {
    return (
        <Fragment>
            <SheetHeader className="">
                <div className="flex items-center justify-between pb-2 w-full pr-4">
                    <Typography text="Messages" style="h2" className="p-6" />
                </div>
                <Separator />
            </SheetHeader>
            <Separator />
        </Fragment>
    );
}

export default ChatMessageHeader;
