import axios from "axios";

export interface RecapStructureItemInterface {
    id: string;
    name: string;
    data_id: string;
    data_type: RecapFieldDataTypes;
    category_id: string;
    is_fleet: boolean;
}

export type RecapFieldDataTypes = "text" | "date" | "number" | "clause";

export async function fetchRecapStructure(): Promise<{ data: RecapStructureItemInterface[] }> {
    const { data } = await axios.get("/api/tenant/v1/recaps/structure");
    return data;
}
