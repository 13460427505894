import { cn } from "../../lib/utils";
import React, { Fragment, useState } from "react";
import { Button } from "./button";

export type TypographyTypes =
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "p"
    | "blockquote"
    | "code"
    | "lead"
    | "large"
    | "small"
    | "muted"
    | "warning"
    | "medium";

type TypographyProps = {
    text: string | JSX.Element;
    style: TypographyTypes;
    className?: string;
};

export function Typography({ text, style, className }: TypographyProps): JSX.Element {
    switch (style) {
        case "h1":
            return (
                <h1
                    className={cn(
                        "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
                        className,
                    )}
                >
                    {text}
                </h1>
            );
        case "h2":
            return (
                <h2
                    className={cn(
                        "scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0",
                        className,
                    )}
                >
                    {text}
                </h2>
            );
        case "h3":
            return (
                <h3 className={cn("scroll-m-20 text-2xl font-semibold tracking-tight", className)}>
                    {text}
                </h3>
            );
        case "h4":
            return (
                <h4 className={cn("scroll-m-20 text-xl font-semibold tracking-tight", className)}>
                    {text}
                </h4>
            );
        case "p":
        default:
            return <p className={cn("leading-7 ", className)}>{text}</p>;
        case "blockquote":
            return (
                <blockquote className={cn("mt-6 border-l-2 pl-6 italic", className)}>
                    {text}
                </blockquote>
            );
        case "code":
            return (
                <code
                    className={cn(
                        "relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
                        className,
                    )}
                >
                    {text}
                </code>
            );
        case "lead":
            return <p className={cn("text-xl text-muted-foreground", className)}>{text}</p>;
        case "large":
            return <div className={cn("text-lg font-semibold", className)}>{text}</div>;
        case "medium":
            return <div className={cn("text-md font-semibold", className)}>{text}</div>;
        case "small":
            return (
                <small className={cn("text-sm font-medium leading-none", className)}>{text}</small>
            );
        case "muted":
            return <p className={cn("text-sm text-muted-foreground", className)}>{text}</p>;
        case "warning":
            return (
                <small className={cn("text-sm font-medium leading-none text-red-700", className)}>
                    {text}
                </small>
            );
    }
}

type ShowMoreProps = {
    value: string;
    base: number;
    style: TypographyTypes;
    className?: string;
};

export function ShowMore({ value, base, style, className }: ShowMoreProps) {
    const [expand, setExpand] = useState<boolean>(false);

    return (
        <Fragment>
            {value.length >= base ? (
                <Typography
                    text={
                        <Fragment>
                            {!expand ? value.slice(0, base) : value}
                            &nbsp;
                            <Button
                                variant="link"
                                className="p-0"
                                onClick={() => setExpand(!expand)}
                            >
                                {!expand ? "show more" : "show less"}{" "}
                            </Button>
                        </Fragment>
                    }
                    style={style}
                    className={className}
                />
            ) : (
                <Typography text={value} style={style} className={className} />
            )}
        </Fragment>
    );
}

// purely for reference
export function TypographyTable() {
    return (
        <div className="my-6 w-full overflow-y-auto">
            <table className="w-full">
                <thead>
                    <tr className="m-0 border-t p-0 even:bg-muted">
                        <th className="border px-4 py-2 text-left font-bold [&[align=center]]:text-center [&[align=right]]:text-right">
                            King&apos;s Treasury
                        </th>
                        <th className="border px-4 py-2 text-left font-bold [&[align=center]]:text-center [&[align=right]]:text-right">
                            People&apos;s happiness
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="m-0 border-t p-0 even:bg-muted">
                        <td className="border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right">
                            Empty
                        </td>
                        <td className="border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right">
                            Overflowing
                        </td>
                    </tr>
                    <tr className="m-0 border-t p-0 even:bg-muted">
                        <td className="border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right">
                            Modest
                        </td>
                        <td className="border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right">
                            Satisfied
                        </td>
                    </tr>
                    <tr className="m-0 border-t p-0 even:bg-muted">
                        <td className="border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right">
                            Full
                        </td>
                        <td className="border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right">
                            Ecstatic
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export function TypographyList() {
    return (
        <ul className="my-6 ml-6 list-disc [&>li]:mt-2">
            <li>1st level of puns: 5 gold coins</li>
            <li>2nd level of jokes: 10 gold coins</li>
            <li>3rd level of one-liners : 20 gold coins</li>
        </ul>
    );
}
