import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { tenantApi } from "./api/tenantApi";
import { cookieApi } from "./api/cookieApi";
import { centralApi } from "./api/centralPublicApi";
import RecapVesselsSlice from "./lib/stores/RecapVesselsSlice";
import RecapFieldValuesSlice from "./lib/stores/RecapFieldValuesSlice";
import RecapClauseValueSlice from "./lib/stores/RecapClauseValueSlice";
import { determineTenantName } from "./lib/helpers/tenant";

// Create the root reducer
const rootReducer = combineReducers({
    [tenantApi.reducerPath]: tenantApi.reducer,
    [centralApi.reducerPath]: centralApi.reducer,
    [cookieApi.reducerPath]: cookieApi.reducer,
    recapVessels: RecapVesselsSlice,
    recapFieldValues: RecapFieldValuesSlice,
    recapClauseValues: RecapClauseValueSlice,
});

// Export the RootState type
export type RootState = ReturnType<typeof rootReducer>;

type MiddlewareActionType = {
    meta: Record<string, unknown>;
    payload: Record<string, unknown> & { originalStatus?: number };
    type: string;
};

const authMiddleware =
    () => (next: (arg0: MiddlewareActionType) => unknown) => (action: MiddlewareActionType) => {
        if (
            action?.type?.startsWith(tenantApi.reducerPath) &&
            action?.type?.endsWith("rejected") &&
            action?.payload
        ) {
            if (action.payload?.originalStatus === 401) {
                window.location.href = `/${determineTenantName()}/login`;
            }
        }

        return next(action);
    };

// Define the setupStore function
export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(tenantApi.middleware)
                .concat(cookieApi.middleware)
                .concat(centralApi.middleware)
                .concat(authMiddleware),
    });
};

// Export the store
export const store = setupStore();

// Export types
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
