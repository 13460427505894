import { useRemoveAddendaMutation } from "../../../api/tenant/addenda";
import { useAddenda } from "../../../lib/providers/addenda-provider";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import React from "react";
import { Button } from "../../../components/ui/button";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function RemoveAddendum() {
    const { case_file_id } = useCaseFiles();
    const { activeAddendum, modalState, setModalState } = useAddenda();
    const [removeAddendum] = useRemoveAddendaMutation();

    function handleSubmit() {
        if (activeAddendum) {
            removeAddendum({
                id: activeAddendum.id,
                case_file_id: case_file_id,
            });
            setModalState(undefined);
        }
    }

    return (
        <Dialog
            open={modalState?.show && modalState?.type === "remove"}
            onOpenChange={() => setModalState(undefined)}
        >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Remove addendum</DialogTitle>
                </DialogHeader>
                <DialogFooter className="mt-4">
                    <Button type="submit" onClick={handleSubmit}>
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default RemoveAddendum;
