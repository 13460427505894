import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from "../../../components/ui/sheet";
import React, { Fragment, useEffect, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { cn } from "../../../lib/utils";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../../../components/ui/calendar";
import { useCreateCaseFileMutation } from "../../../api/tenant/case-file";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import {
    RecapTemplateInterface,
    useLazyGetRecapTemplatesQuery,
} from "../../../api/tenant/recap-templates";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import Loading from "../../../components/global/loading";

function CaseFileCreate({ showSheet }: { showSheet: (x: boolean) => void }) {
    const schema = z.object({
        document_name: z.string().optional(),
        cp_date: z.date().optional(),
        recap_template_id: z.string().min(1),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            document_name: "",
            cp_date: undefined,
            recap_template_id: "",
        },
    });
    const [createCaseFile] = useCreateCaseFileMutation();
    const navigate = useNavigate();
    const [getData] = useLazyGetRecapTemplatesQuery();
    const [recapTemplates, setRecapTemplates]: [
        RecapTemplateInterface[] | undefined,
        (value: RecapTemplateInterface[] | undefined) => void,
    ] = useState();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const { data } = await getData();
            setRecapTemplates(data);
        })();
        return () => {
            showSheet(false);
        };
    }, []);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);

        try {
            const newCaseFileId = await createCaseFile({
                ...values,
                cp_date: values.cp_date ? format(values.cp_date, "PPP") : undefined,
            }).unwrap();

            showSheet(false);

            toast.success("Recap / C/P created", {
                action: {
                    label: "View",
                    onClick: () => navigate(`/case-file/${newCaseFileId}`),
                },
            });
        } catch (e) {
            toast.error("Something went wrong while creating Recap / C/P");
        }

        setLoading(false);
    }

    return (
        <SheetContent
            onInteractOutside={() => showSheet(false)}
            onEscapeKeyDown={() => showSheet(false)}
        >
            <Loading
                loaded={!loading}
                child={
                    <Fragment>
                        <SheetHeader>
                            <SheetTitle className="text-slate-950">New charter party</SheetTitle>
                        </SheetHeader>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(handleSubmit)}>
                                <FormField
                                    control={form.control}
                                    name="recap_template_id"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel className="pb-1">
                                                Recap template
                                                <span className="text-sm text-muted-foreground">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <Select
                                                value={field.value}
                                                name={field.name}
                                                onValueChange={field.onChange}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue
                                                        onBlur={field.onBlur}
                                                        ref={field.ref}
                                                    />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {recapTemplates !== undefined
                                                        ? recapTemplates.map((template) => (
                                                              <SelectItem
                                                                  key={template.id}
                                                                  value={template.id}
                                                              >
                                                                  {template.name}
                                                              </SelectItem>
                                                          ))
                                                        : ""}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="document_name"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>Name</FormLabel>
                                            <FormControl>
                                                <Input {...field} placeholder="" />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="cp_date"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel className="pb-1">
                                                Charter party date
                                            </FormLabel>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <FormControl>
                                                        <Button
                                                            variant={"outline"}
                                                            className={cn(
                                                                "pl-3 text-left font-normal flex h-9 w-full",
                                                                !field.value &&
                                                                    "text-muted-foreground",
                                                            )}
                                                        >
                                                            {field.value ? (
                                                                format(field.value, "PPP")
                                                            ) : (
                                                                <span>Pick a date</span>
                                                            )}
                                                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                        </Button>
                                                    </FormControl>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar
                                                        mode="single"
                                                        selected={field.value}
                                                        onSelect={field.onChange}
                                                        initialFocus
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <SheetFooter className="mt-4">
                                    <Button disabled={!form.formState.isValid} type="submit">
                                        Create
                                    </Button>
                                </SheetFooter>
                            </form>
                        </Form>
                    </Fragment>
                }
            />
        </SheetContent>
    );
}

export default CaseFileCreate;
