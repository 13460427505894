import { useDuplicateDocumentMutation } from "../../../api/tenant/case-file";
import { toast } from "sonner";
import React, { Fragment, useState } from "react";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import Loading from "../../../components/global/loading";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function DuplicateDocument({ row, onClose }: TableActionProps<CaseFileDocumentIndex>) {
    const [duplicateFile] = useDuplicateDocumentMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const { case_file_id } = useCaseFiles();

    async function handleSubmit() {
        setLoading(true);
        try {
            await duplicateFile({
                id: row.id,
                case_file_id: case_file_id,
            }).unwrap();

            onClose();
            toast.message(`Duplicated ${row.document_name}`);
        } catch (e) {
            toast.error(`Something went wrong while duplicating ${row.document_name}`);
        }
        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Duplicate {row.document_name}</DialogTitle>
                        <DialogDescription>
                            Create an identical copy of this document
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="mt-4">
                        <Button type="submit" onClick={handleSubmit}>
                            Duplicate
                        </Button>
                    </DialogFooter>
                </Fragment>
            }
        />
    );
}

export default DuplicateDocument;
