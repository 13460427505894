import { DataTableColumnHeaderProps } from "../../types/table";
import { cn } from "../../lib/utils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { ListFilter } from "lucide-react";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "../ui/command";
import { ScrollArea } from "../ui/scroll-area";
import { CheckIcon } from "@radix-ui/react-icons";
import * as React from "react";

function DataTableBooleanFilter<TData, TValue>({
    column,
    title,
    className,
    valueFormatter,
}: Omit<DataTableColumnHeaderProps<TData, TValue>, "type">) {
    const facets: Map<boolean, number> = column?.getFacetedUniqueValues(),
        selectedValues = new Set(column?.getFilterValue() as boolean[]),
        options = Array.from(facets.keys())
            .filter((x) => typeof x === "boolean")
            .map((x) => {
                return {
                    label: valueFormatter ? valueFormatter(x as TValue) : String(x),
                    value: x,
                };
            });

    return (
        <div className={cn("flex items-center space-x-2", className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="ghost"
                        size="sm"
                        className="-ml-3 h-8 data-[state=open]:bg-accent"
                    >
                        <span>{title}</span>
                        <ListFilter className="ml-2 h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    {options.length ? (
                        <Command>
                            <CommandInput placeholder={title} />
                            <CommandList>
                                <CommandEmpty className="py-2">No results found.</CommandEmpty>
                            </CommandList>
                            <CommandGroup>
                                <ScrollArea className={options.length >= 10 ? "h-80" : ""}>
                                    {options.map((option) => {
                                        const isSelected = selectedValues.has(option.value);
                                        return (
                                            <CommandItem
                                                key={String(option.value)}
                                                onSelect={() => {
                                                    if (isSelected) {
                                                        selectedValues.delete(option.value);
                                                    } else {
                                                        selectedValues.add(option.value);
                                                    }
                                                    const filterValues = Array.from(selectedValues);
                                                    column?.setFilterValue(
                                                        filterValues.length
                                                            ? filterValues
                                                            : undefined,
                                                    );
                                                }}
                                                value={String(option.value)}
                                            >
                                                <div
                                                    className={cn(
                                                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                        isSelected
                                                            ? "bg-primary text-primary-foreground"
                                                            : "opacity-50 [&_svg]:invisible",
                                                    )}
                                                >
                                                    <CheckIcon className={cn("h-4 w-4")} />
                                                </div>
                                                <span className="text-gray-950">
                                                    {option.label}
                                                </span>
                                                {facets?.get(option.value) && (
                                                    <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                                                        {facets.get(option.value)}
                                                    </span>
                                                )}
                                            </CommandItem>
                                        );
                                    })}
                                </ScrollArea>
                            </CommandGroup>
                            {selectedValues.size > 0 && (
                                <>
                                    <CommandSeparator />
                                    <CommandGroup>
                                        <CommandItem
                                            onSelect={() => column?.setFilterValue(undefined)}
                                            className="justify-center text-center"
                                        >
                                            <span className="text-slate-950">Clear filter</span>
                                        </CommandItem>
                                    </CommandGroup>
                                </>
                            )}
                        </Command>
                    ) : (
                        <div className="text-center text-sm py-2 w-[200px]">Nothing to filter</div>
                    )}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

export default DataTableBooleanFilter;
