import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RecapClause } from "../../containers/case-files/recaps/recap-clauses";
import { WithCaseFileId } from "../../types";

type RecapClauseValueSliceState = {
    entities: RecapClause[];
    loading: "initial" | "idle" | "pending" | "complete" | "failed";
};

const initialState: RecapClauseValueSliceState = {
    entities: [],
    loading: "initial",
};

const fetchClauseValues = createAsyncThunk(
    "clause_values/fetch",
    async (body: Pick<RecapClauseValueEntityInterface, "recap_document_id" | "case_file_id">) => {
        const { data } = await axios.get(
            `/api/tenant/v1/recaps/${body.case_file_id}/document/${body.recap_document_id}/clause`,
            {},
        );

        return data.data;
    },
);

const createRecapClauseValue = createAsyncThunk(
    "clause_values/create",
    async (body: Omit<RecapClauseValueEntityInterface, "id">) => {
        const { data } = await axios.post(
            `/api/tenant/v1/recaps/${body.case_file_id}/document/${body.recap_document_id}/clause`,
            {
                ...body,
            },
        );

        return data.data;
    },
);

const updateClauseValue = createAsyncThunk(
    "clause_values/update",
    async (body: RecapClauseValueEntityInterface) => {
        const { data } = await axios.put(
            `/api/tenant/v1/recaps/${body.case_file_id}/document/${body.recap_document_id}/clause/${body.id}`,
            {
                ...body,
            },
        );

        return data.data;
    },
);

type DeleteRecapClauseValue = WithCaseFileId<
    {
        recap_document_id: string;
    } & Pick<RecapClause, "id">
>;

const deleteClauseValue = createAsyncThunk(
    "clause_values/delete",
    async (body: DeleteRecapClauseValue) => {
        await axios.delete(
            `/api/tenant/v1/recaps/${body.case_file_id}/document/${body.recap_document_id}/clause/${body.id}`,
        );

        return body;
    },
);

type RecapClauseValueEntityInterface = {
    recap_document_id: string;
    case_file_id: string;
} & RecapClause;

const RecapClauseValueSlice = createSlice({
    name: "recap-clause-values",
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<RecapClause>) => {
            const index = state.entities.findIndex((entity) => entity.id === action.payload.id);
            if (index !== -1) {
                state.entities.splice(index, 1, action.payload);
            } else {
                state.entities.push(action.payload);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClauseValues.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(fetchClauseValues.fulfilled, (state, action: PayloadAction<RecapClause[]>) => {
                state.entities = action.payload;
                state.loading = "complete";
            })
            .addCase(
                updateClauseValue.fulfilled,
                (state, action: PayloadAction<RecapClauseValueEntityInterface>) => {
                    const index = state.entities.findIndex(
                        (entity) => entity.id === action.payload.id,
                    );

                    if (index !== -1) {
                        state.entities.splice(index, 1, action.payload);
                    } else {
                        state.entities.push(action.payload);
                    }
                },
            )
            .addCase(
                createRecapClauseValue.fulfilled,
                (state, action: PayloadAction<RecapClauseValueEntityInterface>) => {
                    state.entities.push(action.payload);
                },
            )
            .addCase(
                deleteClauseValue.fulfilled,
                (state, action: PayloadAction<DeleteRecapClauseValue>) => {
                    const index = state.entities.findIndex(
                        (entity) => entity.id === action.payload.id,
                    );
                    if (index !== -1) {
                        state.entities.splice(index, 1);
                    }
                },
            );
    },
});

const { setValue } = RecapClauseValueSlice.actions;
export {
    fetchClauseValues,
    updateClauseValue,
    createRecapClauseValue,
    deleteClauseValue,
    setValue,
};
export default RecapClauseValueSlice.reducer;
