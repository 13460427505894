import React, {
    ForwardedRef,
    RefAttributes,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { useRiderTerms } from "../../../lib/providers/rider-terms-provider";
import { Input } from "../../../components/ui/input";
import { useDebounce } from "@uidotdev/usehooks";
import Froalaeditor from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert";
import "./riders-custom-commands";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { froalaConfigBasic } from "../../../config/app";
import { Panel } from "../../../components/ui/panel";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "../../../components/placeholder/tooltip";
import CloseButton from "../../../components/placeholder/close-button";

type Placeholders = {
    [key: string]: string;
};

const RiderTermEditor = React.forwardRef(
    (
        _props: unknown,
        ref: ForwardedRef<{
            resetForm: () => void;
        } | null> &
            RefAttributes<unknown>,
    ) => {
        const { riderState, riderDispatch, addSnippedClause } = useRiderTerms(),
            handleSnip = (selectedElements: ChildNode[]) => {
                addSnippedClause(selectedElements);
            },
            schema = z.object({
                content: z.string().min(2, "Please provide content"),
                title: z.string().min(2, "Please provide title"),
            }),
            isInitialMount = useRef(true),
            form = useForm({
                resolver: zodResolver(schema),
                defaultValues: {
                    content:
                        riderState.activeRiderTerm?.content ?? riderState.riderInCreation.content,
                    title: riderState.activeRiderTerm?.title ?? riderState.riderInCreation.title,
                },
            }),
            [title, setTitle] = useState<string>(),
            [content, setContent] = useState<string>(),
            debouncedTitle = useDebounce(title, 300),
            debouncedContent = useDebounce(content, 300),
            { permissions } = useCaseFiles(),
            froalaConfig = {
                ...froalaConfigBasic,
                toolbarButtons: [
                    "clearFormatting",
                    "bold",
                    "underline",
                    "textColor",
                    "backgroundColor",
                    "fontSize",
                    "|",
                    "alignLeft",
                    "alignCenter",
                    "alignJustify",
                    "formatOLSimple",
                    "insertTable",
                    "outdent",
                    "indent",
                    "|",
                    "newMark",
                    "deleteMark",
                    "revertMark",
                    "clauseCut",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "placeholder",
                ],
                heightMin: "calc(100vh - 514px)",
                heightMax: "calc(100vh - 512px)",
                pasteDeniedAttrs: ["class", "id"],
                pasteAllowedStyleProps: ["list-style-type"],
                events: {
                    initialized: function () {
                        riderDispatch({ type: "SET_EDITOR", payload: this });
                    },
                },
                clauseCutCallback: handleSnip,
            };
        const [currentCloseId, setCurrentCloseId] = useState<string>();

        useEffect(() => {
            isInitialMount.current = false;
        }, []);

        // Handles UX for recap placeholders
        useEffect(() => {
            const handleMouseOver = (event: MouseEvent) => {
                const target = event.target as HTMLElement;
                if (
                    target.nodeType === Node.ELEMENT_NODE &&
                    target.hasAttribute("data-id") &&
                    target.tagName === "SPAN"
                ) {
                    const ID = target.getAttribute("data-id");
                    const fieldID = target.getAttribute("data-field-id");
                    const recapEntry = riderState.recapStructure.find(
                        (entry) => entry.id === fieldID,
                    );
                    const fieldName = recapEntry ? recapEntry.name : "loading..";

                    renderPlaceholderHoverables(target, fieldName, ID!);
                }
            };

            // Attach event listener
            document.addEventListener("mouseover", handleMouseOver);

            // Cleanup event listener on component unmount
            return () => {
                document.removeEventListener("mouseover", handleMouseOver);
            };
        }, [currentCloseId, riderState.recapStructure]);

        // Setup placeholder command
        useEffect(() => {
            // Register the Froala command after the component mounts and data is fetched
            Froalaeditor.RegisterCommand("placeholder", {
                title: "Insert Placeholder",
                type: "dropdown",
                icon: "placeholder",
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                options: {},

                callback: function (cmd: string, val: string) {
                    const [value, id] = val.split("^"); // TODO: is this char safe to use?

                    // Determine if the value contains newlines
                    const containsNewlines = value.includes("\n");

                    // Create the span element
                    const placeholder = document.createElement("span");
                    placeholder.className =
                        "bg-blue-100 bg-opacity-60 text-blue-800 px-0.5 relative rounded cursor-pointer whitespace-pre-line placeholder";
                    if (containsNewlines) {
                        placeholder.className += " inline-block align-top";
                    }
                    placeholder.setAttribute("data-field-id", id);
                    placeholder.setAttribute("data-id", uuidv4());
                    placeholder.setAttribute("contenteditable", "false");
                    placeholder.setAttribute("suppressContentEditableWarning", "true");
                    placeholder.textContent = value; // Set the text content to treat value as plain text

                    // Insert the span element's outer HTML
                    this.html.insert(placeholder.outerHTML);
                },

                refreshOnShow: function ($btn, $dropdown) {
                    // Built dropdown object
                    const entries = riderState.recapFields.reduce(
                        (acc, field) => {
                            const recapEntry = riderState.recapStructure.find(
                                (entry) => entry.id === field.field_id,
                            );
                            const fieldName = recapEntry ? recapEntry.name : "Unknown Field";
                            acc.push([`${field.value}^${field.field_id}`, fieldName]);
                            return acc;
                        },
                        [] as [string, string][],
                    );

                    // Sort the entries alphabetically by fieldName
                    entries.sort((a, b) => a[1].localeCompare(b[1]));

                    // Convert the sorted entries back to an object
                    const placeholders = entries.reduce((acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    }, {} as Placeholders);

                    const targetNode = $dropdown.get(0);

                    // Options for the observer (which mutations to observe)
                    const config = { attributes: true };
                    const callback = (mutationList: MutationRecord[]) => {
                        for (const mutation of mutationList) {
                            if (
                                mutation.type === "attributes" &&
                                mutation.attributeName === "aria-hidden"
                            ) {
                                if ($dropdown.find(".fr-dropdown-search").length) {
                                    if ($dropdown.find(".fr-dropdown-search input").is(":focus")) {
                                        return;
                                    }

                                    if ($dropdown.attr("aria-hidden") === "true") {
                                        $dropdown
                                            .find(".fr-dropdown-search")
                                            .css("display", "none");
                                    } else {
                                        $dropdown
                                            .find(".fr-dropdown-search")
                                            .css("display", "flex");
                                        $dropdown.find(".fr-dropdown-search input").val("");
                                    }
                                }
                            }
                        }
                    };

                    // Create an observer instance linked to the callback function
                    const observer = new MutationObserver(callback);

                    // Start observing the target node for configured mutations
                    observer.observe(targetNode, config);

                    if (!$dropdown.find(".fr-dropdown-search").length) {
                        $dropdown.prepend(
                            '<div class="fr-dropdown-search w-full relative" style="display: flex"><input class="border z-20 grow py-1 px-4 text-sm rounded m-1 mb-0" onclick="this.focus()" onblur="blurValue(\'.fr-dropdown-search\')" oninput="filterValue(\'.fr-dropdown-search\')" placeholder="Search recap value" value=""></div>',
                        );
                    }

                    // Find the dropdown list container
                    const list = $dropdown.find(".fr-dropdown-list");
                    let listItems = "";

                    // Build the list items
                    for (const [key, value] of Object.entries(placeholders)) {
                        listItems += `<li><a class="fr-command" data-cmd="placeholder" data-param1="${key}" title="${value}">${value}</a></li>`;
                    }

                    // Append the built list items to the dropdown
                    list.empty();
                    list.append(listItems);

                    if (!this.selection.inEditor()) {
                        // Move cursor position to end.
                        this.selection.setAtEnd(this.$el.get(0));
                        this.selection.restore();
                    }
                },
            });

            // auto sync upon changes to recap fields or structure
            updateSpanContent();
        }, [riderState.recapFields, riderState.recapStructure]);

        useEffect(() => {
            form.resetField("content");
        }, [riderState.showMultiClause]);

        useEffect(() => {
            if (riderState.activeRiderTerm) {
                form.setValue("title", riderState.activeRiderTerm.title);
                form.setValue("content", riderState.activeRiderTerm.content);
            } else {
                form.setValue("title", riderState.riderInCreation.title);
                form.setValue("content", riderState.riderInCreation.content);
            }
        }, [riderState.activeRiderTerm, riderState.riderInCreation]);

        useEffect(() => {
            if (riderState.editor) {
                if (!permissions.edit_documents) {
                    riderState.editor.edit.off();
                }

                riderState.editor.events.on("commands.before", (cmd: string) => {
                    if (cmd === "placeholder") {
                        riderState.editor.selection.save();
                        riderDispatch({ type: "SET_SHOW_RECAP_PLACEHOLDER_MODAL", payload: true });
                    }
                });

                riderState.editor.events.on(
                    "paste.afterCleanup",
                    (paste_content: string) => {
                        return `<div style='text-align: justify;'>${paste_content}</div>`;
                    },
                    true,
                );

                riderState.editor.events.on(
                    "paste.after",
                    () => {
                        // Existing logic
                        const pasteNode = document.getElementById("isPasted");
                        if (pasteNode) {
                            pasteNode.removeAttribute("id");
                        }

                        // logic for replacing single-item ordered lists
                        const editorInstance = riderState.editor;
                        const htmlContent = editorInstance.html.get();
                        const tempDiv = document.createElement("div");
                        tempDiv.innerHTML = htmlContent;

                        tempDiv.querySelectorAll("ol").forEach((ol) => {
                            if (ol.children.length === 1) {
                                const div = document.createElement("div");
                                div.innerHTML = ol.children[0].innerHTML;
                                ol.parentNode?.replaceChild(div, ol);
                            }
                        });

                        if (tempDiv.innerHTML !== htmlContent) {
                            editorInstance.html.set(tempDiv.innerHTML);
                        }
                    },
                    true,
                );
            }
        }, [riderState.editor]);

        useEffect(() => {
            if (debouncedTitle) {
                handleFormChange("title", debouncedTitle);
            }
        }, [debouncedTitle]);

        useEffect(() => {
            if (debouncedContent) {
                handleFormChange("content", debouncedContent);
            }
        }, [debouncedContent]);

        function handleFormChange(field: "title" | "content", value: string) {
            if (riderState.activeRiderTerm === undefined) {
                riderDispatch({
                    type: "UPDATE_RIDER_IN_CREATION",
                    payload: { field, value },
                });
            } else {
                const currentValue =
                    field === "title"
                        ? riderState.activeRiderTerm?.title
                        : riderState.activeRiderTerm?.content;

                if (value !== currentValue) {
                    // Dispatch only if the value has changed
                    const id = riderState.activeRiderTerm!.id;
                    riderDispatch({
                        type: "UPDATE_ACTIVE_RIDER_TERM",
                        payload: { id, field, value },
                    });
                }
            }
        }

        useImperativeHandle(ref, () => ({
            resetForm: () => {
                form.resetField("title");
                form.resetField("content");
            },
        }));

        // Will sync recap values to placeholders in riders
        const updateSpanContent = () => {
            const newTerms = riderState.savedTerms.map((term) => {
                // Create a temporary element to parse the term's HTML content
                const tempElement = document.createElement("div");
                tempElement.innerHTML = term.content;

                // Remove elements with class .placeholder-tooltip and .remove-placeholder
                const tooltipElements = tempElement.querySelectorAll(".placeholder-tooltip");
                tooltipElements.forEach((tooltipElement) => {
                    tooltipElement.remove();
                });

                const removePlaceholderElements =
                    tempElement.querySelectorAll(".remove-placeholder");
                removePlaceholderElements.forEach((removePlaceholderElement) => {
                    removePlaceholderElement.remove();
                });

                // Find all spans with a data-field-id attribute within the term
                const spans = tempElement.querySelectorAll("span[data-field-id]");

                spans.forEach((span: HTMLElement) => {
                    const fieldId = span.getAttribute("data-field-id");
                    if (fieldId) {
                        const recapField = riderState.recapFields.find(
                            (field) => field.field_id === fieldId,
                        );
                        if (recapField) {
                            span.textContent = recapField.value;
                            if (recapField.value.includes("\n")) {
                                span.classList.add("inline-block", "align-top");
                            } else {
                                span.classList.remove("inline-block", "align-top");
                            }
                        }
                    }
                });

                // Return the modified term object with updated content
                return {
                    ...term,
                    content: tempElement.innerHTML,
                };
            });

            // Dispatch the updated terms
            riderDispatch({ type: "SET_TERMS", payload: newTerms });
        };

        const renderPlaceholderHoverables = (
            target: HTMLElement,
            fieldName: string,
            ID: string,
        ) => {
            const isDescendantOfFrWrapper = !!target.closest(".fr-wrapper");
            const isDescendantOfRiderPreview = !!target.closest("#rider-preview");

            if (
                !target.querySelector(".remove-placeholder") &&
                (isDescendantOfFrWrapper || isDescendantOfRiderPreview)
            ) {
                let parentContainer: HTMLElement | null = null;
                if (isDescendantOfFrWrapper) {
                    parentContainer = document.querySelector(".fr-wrapper") as HTMLElement;
                } else if (isDescendantOfRiderPreview) {
                    parentContainer = document.querySelector("#rider-preview") as HTMLElement;
                }

                let tooltipElement: HTMLElement | null = null;
                let closeButtonElement: HTMLElement | null = null;

                if (parentContainer) {
                    tooltipElement = Tooltip({ fieldName, target, parentContainer });
                }

                if (isDescendantOfFrWrapper) {
                    const handleClick = (closeEvent: MouseEvent) => {
                        closeEvent.stopPropagation();
                        target.remove();
                        const editor = document.getElementsByClassName("fr-view")[0];
                        if (editor) {
                            handleFormChange("content", editor.innerHTML);
                        }
                    };

                    closeButtonElement = CloseButton({ onClick: handleClick, target });
                    setCurrentCloseId(ID);
                }

                const handleMouseLeave = () => {
                    if (tooltipElement) {
                        tooltipElement.remove();
                    }
                    if (closeButtonElement) {
                        closeButtonElement.remove();
                    }
                    target.removeEventListener("mouseleave", handleMouseLeave);
                };

                target.addEventListener("mouseleave", handleMouseLeave);
            }
        };

        return (
            <Panel className="flex-1">
                <Form {...form}>
                    <form>
                        {!riderState.showMultiClause && (
                            <FormField
                                control={form.control}
                                name="title"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Title{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                onChange={(event) => {
                                                    setTitle(event.target.value);
                                                    field.onChange(event);
                                                }}
                                                disabled={!permissions.edit_documents}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        )}

                        {riderState.showMultiClause && (
                            <Alert className="my-4">
                                <AlertTitle className="text-slate-950">Experimental</AlertTitle>
                                <AlertDescription className="text-slate-950">
                                    You can copy up to 75 rider clauses at once in this text field
                                    and we will attempt to convert them into an integrated Rider
                                    set.
                                </AlertDescription>
                            </Alert>
                        )}

                        <FormField
                            control={form.control}
                            name="content"
                            render={() => (
                                <FormItem className="py-2 [&_p]:my-[.5em]">
                                    <FormControl>
                                        <FroalaEditor
                                            model={form.getValues("content")}
                                            config={froalaConfig}
                                            onModelChange={(value: string) => {
                                                form.setValue("content", value);
                                                setContent(value);
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </form>
                </Form>
            </Panel>
        );
    },
);

RiderTermEditor.displayName = "RiderTermEditor";

export default RiderTermEditor;
