export interface ThemeInterface {
    color: {
        main: {
            [key: string]: string;
        };
    };
    rgbacolor: {
        main: {
            [key: string]: {
                r: number;
                g: number;
                b: number;
                a?: number;
            };
        };
    };
    boxShadow: {
        [key: string]: {
            px1: number;
            px2: number;
            px3: number;
            px4: number;
        };
    };
    mediaSize: {
        [key: string]: number;
    };
    borderSize: {
        px: {
            [key: string]: number;
        };
        em: {
            [key: string]: number;
        };
    };
    margin: {
        [component: string]: {
            [item: string]: {
                [key: string]: number;
            };
        };
    };
}

export const theme: ThemeInterface = {
    color: {
        main: {
            primary: "#3767D6",
            secondary: "#74C3A2",
            grey: "#CCCCCC",
            darkGrey: "#707070",
            dark: "#343434",
            darker: "#212121",
            black: "#000000",
            white: "#FFFFFF",
            preview: "#333333",
            previewBg: "#EBEBEB",
            darkerPreviewBg: "#BEBEBE",
            trial: "#EFEFEF",
            border: "#242424",
            pink: "#FF7675",
            paleBlue: "#F6F8FE",
            logoBlue: "#002C59",
            logoRed: "#A11E21",
            warning: "#F44336",
            buttonGroup: "#00000033",
            buttonSpan: "#ffffff",
            colorPickerData: "#343434",
            previewTopBarBorder: "#CCCCCC",
            previewBodyBackground: "#EFEFEF",
            highCard: "#FFFFFF",

            // one-off colors often times used in arrays
            blurple: "#5F39D8",
            navy: "#313C57",
            cobaltBlue: "#2A4FA3",
            desireRed: "#EB3B5A",
            princeton: "#FA8231",
            saffron: "#F7B731",
            crayolaGreen: "#20BF6B",
            tiffanyBlue: "#0FB9B1",
            seaGreen: "#34A853",
            cyanAzureBlue: "#3E8EBF",
            watermelonRed: "#B8363E",
            richBlack: "#030104",

            // existing css colors (used the existing hex values of named colors)
            red: "#FF0000",
            green: "#008000",
            transparent: "transparent",
            currentColor: "currentColor",

            // admin graph colors
            caseFiles: "#0B379C",
            caseFilesOpened: "#144CCD",
            tenantsCreated: "#5982DF",
            usersJoined: "#83A2E9",
            copyrightRevenue: "#63db5d",
            serviceRevenue: "#4d7ba2",
            totalRevenue: "#ae1c21",
            totalRevenueBorder: "#7B5BE1",
            body: "rgb(246, 248, 254)",

            // table
            tableHeadersText: "rgba(54,54,54,0.65)",
            tableBodyText: "rgba(52, 52, 52, .5)",
            forceWhite: "#ffffff",

            q88Main: "#072f6c",
            q88Secondary: "#0875d0",
        },
    },
    rgbacolor: {
        main: {
            message: { r: 116, g: 195, b: 162 },
            white: { r: 255, g: 255, b: 255 },
            lightGrey: { r: 228, g: 230, b: 235 },
            grey: { r: 224, g: 224, b: 224 },
            dark: { r: 54, g: 54, b: 54 },
            darker: { r: 52, g: 52, b: 52 },
            black: { r: 0, g: 0, b: 0 },
            listItem: { r: 74, g: 101, b: 132 },
            tableRow: { r: 55, g: 103, b: 214 },
            button: { r: 45, g: 136, b: 255 },
            warning: { r: 191, g: 49, b: 12 },
            background: { r: 246, g: 248, b: 254 },
            primary: { r: 55, g: 103, b: 214 },
            recapField: { r: 239, g: 239, b: 239 },
        },
    },
    boxShadow: {
        basic: { px1: 1, px2: 1, px3: 7, px4: 1 },
        thick: { px1: 1, px2: 3, px3: 3, px4: 1 },
        blurry: { px1: 8, px2: 6, px3: 32, px4: 0 },
        spread: { px1: 0, px2: 0, px3: 0, px4: 1000 },
        transHead: { px1: 0, px2: 2, px3: 4, px4: -1 },
        transMiddle: { px1: 0, px2: 4, px3: 5, px4: 0 },
        transTail: { px1: 0, px2: 1, px3: 10, px4: 0 },
        buttonGroup: { px1: 0, px2: -2, px3: 3, px4: 0 },
    },
    mediaSize: {
        xl: 2560,
        lg: 1440,
        md: 761,
        sm: 425,
    },
    borderSize: {
        px: {
            thin: 1,
            normal: 2,
            thick: 3,
        },
        em: {
            thin: 0.5,
            normal: 1,
        },
    },
    margin: {
        textarea: {
            label: {
                normal: -6,
                smartfield: -8,
            },
        },
    },
};

export const darkTheme: ThemeInterface = {
    color: {
        main: {
            primary: "#A5D7E8",
            secondary: "#74C3A2",
            grey: "#CCCCCC",
            darkGrey: "rgba(255, 255, 255, .8)",
            dark: "rgba(255, 255, 255, .8)",
            darker: "#ffffff",
            black: "#000000",
            white: "#363636",
            preview: "#333333",
            previewBg: "#EBEBEB",
            darkerPreviewBg: "#606060",
            trial: "#EFEFEF",
            border: "#242424",
            pink: "#FF7675",
            paleBlue: "#F6F8FE",
            logoBlue: "#002C59",
            logoRed: "#A11E21",
            warning: "#E94560",
            buttonGroup: "#00000033",
            buttonSpan: "#363636",
            colorPickerData: "#343434",
            previewTopBarBorder: "rgba(52, 52, 52, .8)",
            previewBodyBackground: "#212121",
            highCard: "#484848",
            // one-off colors often times used in arrays
            blurple: "#5F39D8",
            navy: "#313C57",
            cobaltBlue: "#2A4FA3",
            desireRed: "#FFFFFF",
            princeton: "#FFFFFF",
            saffron: "#FFFFFF",
            crayolaGreen: "#FFFFFF",
            tiffanyBlue: "#FFFFFF",
            seaGreen: "#34A853",
            cyanAzureBlue: "#3E8EBF",
            watermelonRed: "#B8363E",
            richBlack: "#030104",

            // existing css colors (used the existing hex values of named colors)
            red: "#FF0000",
            green: "#008000",
            transparent: "transparent",
            currentColor: "currentColor",

            // admin graph colors
            caseFiles: "#aa5252",
            caseFilesOpened: "#4d98c6",
            tenantsCreated: "#5982DF",
            usersJoined: "#072365",
            copyrightRevenue: "#63db5d",
            serviceRevenue: "#32dae6",
            totalRevenue: "#d0161c",
            totalRevenueBorder: "#d0161c",
            body: "#212121",

            // table
            tableHeadersText: "#ffffff",
            tableBodyText: "rgba(255, 255, 255, .8)",
            forceWhite: "#ffffff",

            q88Main: "#fff",
            q88Secondary: "#fff",
        },
    },
    rgbacolor: {
        main: {
            message: { r: 116, g: 195, b: 162 },
            white: { r: 54, g: 54, b: 54 },
            lightGrey: { r: 228, g: 230, b: 235 },
            grey: { r: 224, g: 224, b: 224 },
            dark: { r: 255, g: 255, b: 255 },
            darker: { r: 255, g: 255, b: 255 },
            black: { r: 0, g: 0, b: 0 },
            listItem: { r: 210, g: 210, b: 210 },
            tableRow: { r: 55, g: 103, b: 214 },
            button: { r: 45, g: 136, b: 255 },
            warning: { r: 191, g: 49, b: 12 },
            background: { r: 246, g: 248, b: 254 },
            primary: { r: 165, g: 215, b: 232 },
            recapField: { r: 70, g: 70, b: 70 },
        },
    },
    boxShadow: {
        basic: { px1: 0, px2: 0, px3: 0, px4: 0 },
        thick: { px1: 0, px2: 0, px3: 0, px4: 0 },
        blurry: { px1: 0, px2: 0, px3: 0, px4: 0 },
        spread: { px1: 0, px2: 0, px3: 0, px4: 0 },
        transHead: { px1: 0, px2: 0, px3: 0, px4: 0 },
        transMiddle: { px1: 0, px2: 0, px3: 0, px4: 0 },
        transTail: { px1: 0, px2: 0, px3: 0, px4: 0 },
        buttonGroup: { px1: 0, px2: 0, px3: 0, px4: 0 },
    },
    mediaSize: {
        xl: 2560,
        lg: 1440,
        md: 761,
        sm: 425,
    },
    borderSize: {
        px: {
            thin: 1,
            normal: 2,
            thick: 3,
        },
        em: {
            thin: 0.5,
            normal: 1,
        },
    },
    margin: {
        textarea: {
            label: {
                normal: -6,
                smartfield: -8,
            },
        },
    },
};
