import axios from "axios";

export type BackupStatus = "Failed" | "Completed" | "In Progress";

export type BackupType = "Automatic" | "Manual";

export interface Backup {
    id: string;
    status: BackupStatus;
    started_at: Date;
    completed_at: Date | undefined;
    files: Array<string>;
    type: BackupType;
}

export interface ShowBackupResponse {
    data: Backup;
}

export interface IndexBackupResponse {
    data: Array<Backup>;
}

export async function getBackup(id: string): Promise<ShowBackupResponse> {
    return await axios
        .get(`/admin/api/v1/backups/${id}`, {
            // headers: mainHeaders,
        })
        .then((res) => res.data);
}

export async function getBackups(): Promise<IndexBackupResponse> {
    return await axios
        .get("/admin/api/v1/backups", {
            // headers: mainHeaders,
        })
        .then((res) => res.data);
}

export async function createBackup(): Promise<any> {
    return await axios
        .post("/admin/api/v1/backups", {
            // headers: mainHeaders,
        })
        .then((res) => res.data);
}

export async function deleteBackup(id: string): Promise<any> {
    return await axios
        .delete(`/admin/api/v1/backups/${id}`, {
            // headers: mainHeaders,
        })
        .then((res) => res.data);
}

export async function downloadBackups(id: string, token?: string): Promise<Blob | string> {
    return await axios
        .get(`/admin/api/v1/backups/download/${id}`, {
            responseType: "blob",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        })
        .then((res: { data: any }) => res.data);
}

// const encode = (str: string):string => Buffer.from(str, "binary").toString("base64");
