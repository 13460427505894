import axios from "axios";

export type InvoiceStatus = "open" | "closed" | "pending" | "paid" | "failed" | "ignored";

export interface AdminInvoiceItem {
    id: string;
    number: number;
    end_date: Date;
    subtotal: number;
    total: number;
    status: InvoiceStatus;
    created_at: Date;
    updated_at: Date;
    subject: string;
    date_paid?: Date;
    run_date?: Date;
    start_date: Date;
    tenant: string;
    title: string;
    due_date?: Date;
}

export interface AdminInvoicesResponse {
    data: Array<AdminInvoiceItem>;
}

export async function adminGetInvoices(): Promise<AdminInvoicesResponse> {
    return await axios
        .get("/admin/api/v1/invoice", {
            // headers: mainHeaders,
        })
        .then((res) => res.data);
}
