import TypedFroalaEditor from "froala-editor-typings";
import Command from ".";
import { TenantUser } from "../../../types/auth";
import Memento from "../Memento";
import { saveEditorSelectionAndAddEditorStateToMemento } from "../Utility/base";

class SnapshotCommand implements Command {
    private editor: TypedFroalaEditor.FroalaEditor;
    private event: KeyboardEvent;
    private user: TenantUser;
    private previousState: Memento;
    private spanId: string | null;

    constructor(editor: TypedFroalaEditor.FroalaEditor, event: KeyboardEvent, user: TenantUser) {
        this.editor = editor;
        this.event = event;
        this.user = user;
        this.previousState = this.saveState();
    }

    execute(): void {}

    undo(): void {
        this.restoreState(this.previousState);
    }

    saveState(): Memento {
        return saveEditorSelectionAndAddEditorStateToMemento(this.editor);
    }

    restoreState(memento: Memento): void {
        this.editor.html.set(memento.getState()); // Restore the HTML content
    }

    get previous(): Memento {
        return this.previousState;
    }

    get id(): string | null {
        return this.spanId;
    }
}

export default SnapshotCommand;
