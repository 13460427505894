import * as Yup from "yup";

export const defaultFroalaConfigAdmin = {
    key: process.env.FROALA_KEY,
    charCounterCount: false,
    documentReady: true,
    heightMin: 300,
    attribution: false,
    toolbarSticky: false,
    toolbarButtons: {
        moreText: {
            buttons: [
                "bold",
                "italic",
                "underline",
                "strikeThrough",
                "subscript",
                "superscript",
                "fontFamily",
                "fontSize",
                "textColor",
                "backgroundColor",
                "inlineClass",
                "inlineStyle",
                "clearFormatting",
            ],
        },
        moreParagraph: {
            buttons: [
                "alignLeft",
                "alignCenter",
                "formatOLSimple",
                "alignRight",
                "alignJustify",
                "formatOL",
                "formatUL",
                "paragraphFormat",
                "paragraphStyle",
                "lineHeight",
                "outdent",
                "indent",
                "quote",
            ],
        },
        moreRich: {
            buttons: [
                "insertLink",
                "insertImage",
                "insertVideo",
                "insertTable",
                "emoticons",
                "fontAwesome",
                "specialCharacters",
                "embedly",
                "insertFile",
                "insertHR",
            ],
        },
        moreMisc: {
            buttons: [
                "undo",
                "redo",
                "fullscreen",
                "print",
                "getPDF",
                "spellChecker",
                "selectAll",
                "html",
                "help",
            ],
            align: "right",
            buttonsVisible: 2,
        },
    },
    quickInsertEnabled: false,
};

export const documentTypeOptions = [
    { value: "bill_of_lading", label: "Bill of Lading" },
    { value: "standard_form", label: "Standard Form" },
    { value: "fixture", label: "Fixture" },
    { value: "sof", label: "SOF" },
    { value: "nor", label: "NOR" },
    { value: "loi", label: "LOI" },
];

export const newTemplateValidationSchema = Yup.object().shape({
    template_type: Yup.string().required().label("Template type"),
    name: Yup.string().required(),
    content: Yup.string().required("Please make sure the form has content before uploading"),
    footer: Yup.string().required(),
    thumbnail: Yup.mixed()
        .test("fileSize", "Picture must be under 8MB", (value: { size: number }) => {
            if (value) {
                const sum = value.size / 1000000;
                return sum < 8;
            }

            return true;
        })
        .label("Thumbnail"),
    service_fee: Yup.number().required(),
    copyright_fee: Yup.number().required(),
});
