import { tenantApi } from "../tenantApi";
import {
    GlobalDocumentInterface,
    SmartFieldInterface,
    TrackChangesEntry,
} from "../../types/document-editor";

const documentsApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        storeManualSave: build.mutation<
            { data: GlobalDocumentInterface },
            {
                case_file_id: string;
                id: string;
                content: string;
                smart_fields?: Array<SmartFieldInterface>;
                changes?: Array<TrackChangesEntry>;
            }
        >({
            query: ({ id, case_file_id, ...body }) => ({
                url: `/${case_file_id}/case-file/save-single-file/${id}`,
                method: "POST",
                body: body,
            }),
        }),
    }),
});

export const { useStoreManualSaveMutation } = documentsApi;
