import {
    DashboardTopBarContainerContent,
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentRange,
    DashboardTopBarContainerContentRangeSelector,
    DashboardTopBarContainerContentTitle,
    DashboardTopBarContainerGraph,
} from "../../Style";
import { Line } from "react-chartjs-2";
import React from "react";
import { revenueOptions } from "../../config";
import { DashboardRevenueTrendsInterface } from "../../index.interface";

export function DashboardRevenueTrends({ data }: DashboardRevenueTrendsInterface) {
    return (
        <DashboardTopBarContainerContent>
            <DashboardTopBarContainerContentHeader>
                <DashboardTopBarContainerContentTitle>
                    Revenue Trends
                </DashboardTopBarContainerContentTitle>
                <DashboardTopBarContainerContentRange>
                    <DashboardTopBarContainerContentRangeSelector>
                        <option>Past 6 months</option>
                    </DashboardTopBarContainerContentRangeSelector>
                </DashboardTopBarContainerContentRange>
            </DashboardTopBarContainerContentHeader>
            <DashboardTopBarContainerGraph>
                {data.datasets && <Line options={revenueOptions} data={data} />}
            </DashboardTopBarContainerGraph>
        </DashboardTopBarContainerContent>
    );
}
