import React, { Fragment, useEffect, useState } from "react";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns } from "../../../config/invoices";
import { adminGetInvoices, AdminInvoiceItem } from "../../Admin/Invoices";
import { DashboardTopBarContainerContent } from "../Dashboard/Style";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";

export function AdminInvoicesPage() {
    const [data, setData] = useState<Array<AdminInvoiceItem>>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        adminGetInvoices().then((data: { data: React.SetStateAction<AdminInvoiceItem[]> }) => {
            setData(data.data);
        });
    }

    return (
        <Fragment>
            <DashboardTopBarContainerContent>
                <div className="flex flex-col h-full">
                    <DataTableToolbar
                        table={table}
                        title="Invoices"
                        searchableColumns={[
                            "id",
                            "number",
                            "end_date",
                            "status",
                            "subject",
                            "tenant",
                            "title",
                        ]}
                    />
                    <div className="flex py-4 h-full">
                        <DataTable columns={columns} table={table} onClick={() => void 0} />
                    </div>
                </div>
            </DashboardTopBarContainerContent>
        </Fragment>
    );
}
