import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import React from "react";

type NavBarItemProps = {
    activePage: string;
    page: string;
    icon: JSX.Element;
    tooltip: string;
};

function NavBarItem({ activePage, icon, tooltip, page }: NavBarItemProps) {
    return (
        <div
            className={`
                    flex justify-center items-center px-2 py-1.5 mb-2
                    border-solid
                    border-primary
                    border-x-2
                    border-l-transparent
                    border-y-transparent
                    hover:border-r-primary
                    ${activePage.includes(page) ? "border-r-primary" : "border-r-transparent"}
                    `}
        >
            <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                    <Link to={`/${page}`}>
                        <Button variant="ghost" className="p-2 h-auto">
                            {icon}
                        </Button>
                    </Link>
                </TooltipTrigger>
                <TooltipContent align="center" side="right">
                    {tooltip}
                </TooltipContent>
            </Tooltip>
        </div>
    );
}

export { NavBarItemProps };

export default NavBarItem;
