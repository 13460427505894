import {
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
import {
    RecapFieldSavedValueInterface,
    useDeleteRecapFieldSavedValueMutation,
    useLazyGetRecapFieldSavedValuesQuery,
} from "../../../api/tenant/recap-saved-values";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { RecapVesselEntityInterface } from "../../../lib/stores/RecapVesselsSlice";

interface RecapFieldValueDialogInterface {
    isOpen: boolean;
    fieldId: string;
    vessel?: RecapVesselEntityInterface;
    setValue: UseFormSetValue<Record<string, string>>;
}

export function RecapFieldValueDialog({
    isOpen,
    fieldId,
    vessel,
    setValue,
}: RecapFieldValueDialogInterface) {
    const [getValues] = useLazyGetRecapFieldSavedValuesQuery();
    const [fieldValues, setFieldValues]: [
        RecapFieldSavedValueInterface[] | undefined,
        (values: RecapFieldSavedValueInterface[] | undefined) => void,
    ] = useState();

    useEffect(() => {
        if (isOpen) {
            (async () => {
                const { data } = await getValues(fieldId);
                setFieldValues(data);
            })();
        }
    }, [isOpen]);

    const [deleteValue] = useDeleteRecapFieldSavedValueMutation();

    return (
        <DialogContent
            onOpenAutoFocus={(event) => {
                event.preventDefault();
            }}
        >
            <DialogHeader>
                <DialogTitle>Select a previously saved value</DialogTitle>
            </DialogHeader>
            <div className={"border-t max-h-96 overflow-y-auto"}>
                {fieldValues &&
                    fieldValues.map((fieldValue, index) => (
                        <div key={index} className={"group gap-2 border-b py-1 flex w-full"}>
                            <DialogClose asChild>
                                <Button
                                    onClick={() => {
                                        const elementId =
                                            fieldId + (vessel ? "--" + vessel.id : "");
                                        setValue(elementId, fieldValue.value, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    variant={"ghost"}
                                    type={"submit"}
                                    className="w-full justify-start whitespace-normal overflow-auto h-auto text-left"
                                >
                                    {fieldValue.value}
                                </Button>
                            </DialogClose>
                            <div
                                className={
                                    "pl-1 pr-1 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                                }
                            >
                                <Button
                                    onClick={async () => {
                                        await deleteValue({ id: fieldValue.id });
                                    }}
                                    tabIndex={0}
                                    type="submit"
                                    variant={"destructive"}
                                    className={""}
                                >
                                    <Trash className={"w-4 h-4"} />
                                </Button>
                            </div>
                        </div>
                    ))}
            </div>
        </DialogContent>
    );
}
