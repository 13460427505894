import React, { Fragment } from "react";
import { DashboardTopBarContainer } from "../../Style";
import { DashboardSalesTrends } from "../Sales";
import { DashboardOverview } from "../Overview";
import { DashboardTopBarInterface } from "../../index.interface";

export function DashboardTopBar({ caseFileData, stats }: DashboardTopBarInterface) {
    return (
        <Fragment>
            <DashboardTopBarContainer>
                <DashboardOverview data={stats} />
            </DashboardTopBarContainer>
            <DashboardTopBarContainer>
                <DashboardSalesTrends data={caseFileData} />
            </DashboardTopBarContainer>
            <DashboardTopBarContainer>
                {/*<DashboardDocumentTypeTrends data={caseFileTypeData}/>*/}
            </DashboardTopBarContainer>
        </Fragment>
    );
}
