import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { TenantUser } from "../Central/Admin/Tenants";
import { format } from "date-fns";

const columns: ColumnDef<TenantUser>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Name",
        },
    },
    {
        accessorKey: "email",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Email" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Email",
        },
    },
    {
        accessorKey: "approval_status",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Status" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Status",
        },
    },
    {
        accessorKey: "draft",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Drafts" type="sort" />;
        },
        meta: {
            title: "Drafts",
        },
    },
    {
        accessorKey: "final",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Finalised" type="sort" />;
        },
        meta: {
            title: "Finalised",
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Date (CEST)" type="date-range" />;
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{value ? format(new Date(value), "PPP") : "-"}</span>;
        },
    },
    {
        accessorKey: "last_logged_in",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Login Date (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Login Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("last_logged_in") as string;
            return <span>{value ? format(new Date(value), "PPP") : "-"}</span>;
        },
    },
];

export { columns };
