import { CaseFileChatProps } from "../../../types/case-files";
import { useChat } from "../../../lib/providers/chat-provider";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import React, { useEffect, useRef } from "react";
import { useGetCaseFileChatQuery } from "../../../api/tenant/case-file";
import { Typography } from "../../../components/ui/typography";
import ChatMessage from "../../../components/chat-panel/chat-message";
import { Separator } from "../../../components/ui/separator";
import ChatSendMessage from "../../../components/chat-panel/chat-send-message";

function Chat({ id }: CaseFileChatProps) {
    const { setSupplyChainSocketConnection, messages, updateActiveChannel, setUpCustomSocket } =
        useChat();
    const { permissions, meta_data } = useCaseFiles();
    const scrollToBottomRef = useRef<HTMLDivElement | null>(null);
    const { data: chat, isLoading: chatLoading } = useGetCaseFileChatQuery(id);

    useEffect(() => {
        return () => {
            updateActiveChannel(undefined);
        };
    }, []);

    useEffect(() => {
        scrollToBottomRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
        });
    }, [messages]);

    useEffect(() => {
        if (permissions && !chatLoading) {
            init();
        }
    }, [id, permissions, chatLoading]);

    useEffect(() => {
        if (meta_data?.additional_data.socket_channel) {
            if (meta_data?.supply_chain_id_encrypted) {
                setSupplyChainSocketConnection(
                    meta_data.additional_data.socket_channel,
                    meta_data?.supply_chain_id_encrypted,
                );
            } else {
                setUpCustomSocket(meta_data.additional_data.socket_channel);
            }
        }
    }, [meta_data]);

    function init() {
        if (chat?.channel) {
            updateActiveChannel(chat.channel);
        }
    }

    return (
        <div className="flex flex-col flex-grow w-full rounded-lg h-full">
            <div className="flex flex-col flex-grow h-0 p-4 overflow-auto">
                {messages?.length === 0 ? (
                    <div>
                        <Typography
                            text="No messages yet"
                            style="muted"
                            className="flex justify-center"
                        />
                    </div>
                ) : (
                    messages?.map((message) => <ChatMessage message={message} key={message.id} />)
                )}
                <div ref={scrollToBottomRef} />
            </div>
            <Separator className="my-4" />
            <ChatSendMessage />
        </div>
    );
}

export default Chat;
