import { ChatMessageProps } from "../../types/chat";
import React, { Fragment } from "react";
import { useAuth } from "../../lib/providers/AuthProvider";
import { useChat } from "../../lib/providers/chat-provider";
import { Typography } from "../ui/typography";
import parse from "html-react-parser";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../ui/hover-card";
import { Button } from "../ui/button";
import { format, formatDistance } from "date-fns";
import { CalendarDays } from "lucide-react";

function ChatMessage({ message }: ChatMessageProps) {
    const { user } = useAuth().state,
        { shouldMessagesBeCombined } = useChat();

    return (
        <div
            className={`flex w-full mt-2 space-x-3 ${message.user_id !== user?.id ? "justify-end" : ""}`}
        >
            <div>
                {shouldMessagesBeCombined(message) && (
                    <div className={`flex ${message.user_id !== user?.id ? "justify-end" : ""}`}>
                        <Typography
                            text={message.user_name}
                            style="p"
                            className="first-letter:uppercase"
                        />
                    </div>
                )}
                <div
                    className={`inline-flex flex-col justify-end p-2 rounded-r-lg rounded-bl-lg max-w-[320px] text-white ${message.user_id === user?.id ? "bg-emerald-400" : "bg-sky-400"}`}
                >
                    <Typography
                        text={
                            <Fragment>{parse(message.message.replace(/\n/g, "<br />"))}</Fragment>
                        }
                        style="p"
                        className="text-sm"
                    />
                    <HoverCard>
                        <HoverCardTrigger asChild>
                            <Button variant="none" className="p-0 h-4 justify-end">
                                <Typography
                                    text={formatDistance(
                                        new Date(message.timestamp * 1000),
                                        new Date(),
                                        { addSuffix: true },
                                    )}
                                    style="muted"
                                    className="text-[12px] text-white"
                                />
                            </Button>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-auto" align="end">
                            <div className="flex items-center pt-2">
                                <CalendarDays className="mr-2 h-4 w-4 opacity-70" />{" "}
                                <span className="text-xs text-muted-foreground">
                                    {format(
                                        new Date(message.timestamp * 1000),
                                        "dd-MM-yyyy HH:mm:ss",
                                    )}
                                </span>
                            </div>
                        </HoverCardContent>
                    </HoverCard>
                </div>
            </div>
        </div>
    );
}

export default ChatMessage;
