import React from "react";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import { Button } from "../ui/button";
import { MessageSquareMore } from "lucide-react";
import ChatChannelsList from "./chat-channels-list";
import ChatMessageList from "./chat-message-list";

function Chat() {
    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button variant="ghost" size="icon">
                    <MessageSquareMore className="stroke-neutral-800 h-[1.2rem] w-[1.2rem]" />
                </Button>
            </SheetTrigger>
            <SheetContent className="sm:max-w-[800px] flex p-0">
                <ChatChannelsList />
                <ChatMessageList />
            </SheetContent>
        </Sheet>
    );
}

export default Chat;
