import { Outlet } from "react-router";
import { useAuth } from "../lib/providers/AuthProvider";
import Loading from "../components/global/loading";
import { SupportProvider } from "../lib/providers/SupportProvider";
import React from "react";
import Login from "../containers/authentication/login";

function TenantLayout() {
    const loggedIn = useAuth().state.loggedIn;
    const loaded = useAuth().state.loaded;

    return (
        <div>
            <Loading
                loaded={loaded}
                child={
                    <SupportProvider loggedIn={loggedIn} appId={process.env.INTERCOM_APP_ID ?? ""}>
                        {!loggedIn ? <Login /> : <Outlet />}
                    </SupportProvider>
                }
            />
        </div>
    );
}

export default TenantLayout;
