import { useAddenda } from "../../../lib/providers/addenda-provider";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import React, { Fragment, useState } from "react";
import { useExportAddendaMutation } from "../../../api/tenant/addenda";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { useLazyDownloadBlobQuery } from "../../../api/cookieApi";
import { saveAs } from "file-saver";
import Loading from "../../../components/global/loading";

function AddendaExport() {
    const { meta_data, original_cf_id } = useCaseFiles();
    const { activeAddendum, modalState, setModalState, addenda } = useAddenda();
    const [exportAddenda] = useExportAddendaMutation();
    const [downloadBlob] = useLazyDownloadBlobQuery();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        if (addenda === undefined) {
            return;
        }
        setLoading(true);
        let addendaToExport = addenda;
        if (activeAddendum && modalState?.type === "export-single") {
            addendaToExport = [activeAddendum];
        }

        const downloadUrl = await exportAddenda({
            case_file_id: original_cf_id,
            addenda: addendaToExport,
        }).unwrap();

        const blob = await downloadBlob(`${downloadUrl}&caseFile=${original_cf_id}`).unwrap();

        saveAs(blob, `${meta_data?.document_name ?? "case-file"}-addenda.pdf`);

        setModalState(undefined);

        setLoading(false);
    }

    return (
        <Dialog
            open={modalState?.show && modalState?.type !== "remove"}
            onOpenChange={() => setModalState(undefined)}
        >
            <DialogContent>
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <DialogHeader>
                                <DialogTitle>
                                    {modalState?.type === "export-all"
                                        ? "Export all addenda"
                                        : "Export addendum"}
                                </DialogTitle>
                            </DialogHeader>
                            <DialogFooter className="mt-4">
                                <Button type="submit" onClick={handleSubmit}>
                                    Export
                                </Button>
                            </DialogFooter>
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

export default AddendaExport;
