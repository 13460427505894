import React, { Fragment } from "react";
import { Typography } from "../components/ui/typography";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";

function SettingLayout() {
    return (
        <Fragment>
            <div className="flex flex-col justify-between space-y-2 bg-foreground p-4 border border-solid border-slate-200">
                <div className="flex items-center justify-between w-full">
                    <Typography text="Settings" style="h2" className="pb-0" />
                </div>
            </div>
            <div className="w-full h-full flex">
                <div className="w-1/4 pr-0 flex items-stretch h-full justify-items-start">
                    <div className="bg-foreground p-4 rounded-sm flex h-full w-full">
                        <div className="grid gap-3">
                            <ul className="grid gap-3">
                                <li>
                                    <Link to="/settings">Account</Link>
                                </li>
                                <li>
                                    <Link to="/team">Team</Link>
                                </li>
                                <li>
                                    <Link to="/team">Billing</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="ml-4 w-3/4 flex items-stretch h-full">
                    <div className="bg-foreground p-4 rounded-sm">
                        <Outlet />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SettingLayout;
