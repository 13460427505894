import React, { Fragment, useState } from "react";
import { TableActionProps } from "../../types/table";
import { HouseForm } from "../../types/house-forms";
import { DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { useDuplicateHouseFormMutation } from "../../api/tenant/house-forms";
import Loading from "../../components/global/loading";

function DuplicateHouseForm({ row, onClose }: TableActionProps<HouseForm>) {
    const [duplicateHouseForm] = useDuplicateHouseFormMutation();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);
        await duplicateHouseForm(row.id);
        onClose();
        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Duplicate {row.name}</DialogTitle>
                    </DialogHeader>
                    <DialogFooter>
                        <Button type="submit" onClick={handleSubmit}>
                            Duplicate
                        </Button>
                    </DialogFooter>
                </Fragment>
            }
        />
    );
}

export default DuplicateHouseForm;
