import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import React, { useEffect } from "react";
import { Button } from "../ui/button";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { DataTableViewOptionsProps } from "../../types/table";
import { Column, VisibilityState } from "@tanstack/react-table";
import { useToggleTableColumnVisibilityMutation } from "../../api/tenant/settings";
import { useAuth } from "../../lib/providers/AuthProvider";
import { toast } from "sonner";

export function DataTableViewOptions<TData>({ table }: DataTableViewOptionsProps<TData>) {
    const [toggleTableColumnVisibility] = useToggleTableColumnVisibilityMutation();
    const column_visibility = useAuth()?.state.column_visibility;

    useEffect(() => {
        handleColumnVisibility();
    }, [column_visibility]);

    function handleColumnVisibility() {
        const storedColumnVisibility = column_visibility?.find(
            (x) => x.table_id === table.options.meta?.tableId,
        )?.columns as unknown as VisibilityState;

        table.setColumnVisibility({
            ...table.getState().columnVisibility,
            ...storedColumnVisibility,
        });
    }

    async function handleToggleColumn(column: Column<TData>) {
        try {
            if (table.options.meta?.tableId) {
                await toggleTableColumnVisibility({
                    table: table.options.meta.tableId,
                    column: column.id,
                    visible: !column.getIsVisible(),
                }).unwrap();
            }

            column.toggleVisibility(!column.getIsVisible());
        } catch (e) {
            toast.error("Something went wrong while storing table preferences");
        }
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="ml-auto hidden h-8 lg:flex">
                    <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                    Edit columns
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-auto">
                <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {table
                    .getAllColumns()
                    .filter(
                        (column) => typeof column.accessorFn !== "undefined" && column.getCanHide(),
                    )
                    .map((column) => {
                        return (
                            <DropdownMenuCheckboxItem
                                key={column.id}
                                className="capitalize"
                                checked={column.getIsVisible()}
                                onCheckedChange={() => handleToggleColumn(column)}
                            >
                                {column.columnDef.meta?.title}
                            </DropdownMenuCheckboxItem>
                        );
                    })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
