import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import React, { Fragment } from "react";
import { Button } from "../ui/button";
import { Bell } from "lucide-react";
import { useNotifications } from "../../lib/providers/notifications-provider";
import NotificationsHeader from "./notifications-header";
import MultiThreadNotification from "./multi-thread-notification";
import SingleThreadNotification from "./single-thread-notification";

function Notifications() {
    const { startFetch, notifications } = useNotifications();
    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button variant="ghost" size="icon" onClick={startFetch}>
                    <Bell className="stroke-neutral-800 h-[1.2rem] w-[1.2rem]" />
                </Button>
            </SheetTrigger>
            <SheetContent className="sm:max-w-[400px]">
                <NotificationsHeader />
                <div className="overflow-y-auto h-full pb-8">
                    {notifications.map((notification) => (
                        <Fragment key={notification.id}>
                            {notification.messages.length > 1 ? (
                                <MultiThreadNotification notification={notification} />
                            ) : (
                                <SingleThreadNotification notification={notification} />
                            )}
                        </Fragment>
                    ))}
                </div>
            </SheetContent>
        </Sheet>
    );
}

export default Notifications;
