import axios from "axios";
import {
    NewRecapTemplateInterface,
    RecapSingleFieldInterface,
    RecapTemplateInterface,
} from "../../Pages/RecapTemplates/index.interface";
import {
    NewRecapCategoryInterface,
    NewRecapClauseInterface,
} from "../../Pages/RecapFields/index.interface";
import { NewRecapFieldInterface } from "../../../types/document-editor";
import { RecapCategoryInterface, RecapInterface } from "../../../types/recap";

export async function getRecapFieldsIndex(): Promise<{ data: Array<RecapSingleFieldInterface> }> {
    return await axios.get("/admin/api/v1/recap/field/index").then((res) => res.data);
}

export async function getRecapFieldCategories(): Promise<{ data: Array<RecapCategoryInterface> }> {
    return await axios.get("/admin/api/v1/recap/categories/field").then((res) => res.data);
}

export async function createRecapFieldCategory(data: NewRecapCategoryInterface): Promise<boolean> {
    return await axios.put("/admin/api/v1/recap/categories/field", data).then((res) => res.data);
}

export async function createRecapClauseCategory(data: NewRecapCategoryInterface): Promise<boolean> {
    return await axios.put("/admin/api/v1/recap/categories/clause", data).then((res) => res.data);
}

export async function updateRecapFieldCategory(
    data: NewRecapCategoryInterface,
    id: string,
): Promise<boolean> {
    return await axios
        .post(`/admin/api/v1/recap/categories/field/${id}`, data)
        .then((res) => res.data);
}

export async function updateRecapClauseCategory(
    data: NewRecapCategoryInterface,
    id: string,
): Promise<boolean> {
    return await axios
        .post(`/admin/api/v1/recap/categories/clause/${id}`, data)
        .then((res) => res.data);
}

export async function createRecapField(data: NewRecapFieldInterface): Promise<boolean> {
    return await axios.post("/admin/api/v1/recap/field", data).then((res) => res.data);
}

export async function createRecapClause(data: NewRecapClauseInterface): Promise<boolean> {
    return await axios.post("/admin/api/v1/recap/clause", data).then((res) => res.data);
}

export async function updateRecapField(id: string, data: NewRecapFieldInterface): Promise<boolean> {
    return await axios.put(`/admin/api/v1/recap/field/${id}`, data).then((res) => res.data);
}

export async function updateRecapClause(
    id: string,
    data: NewRecapClauseInterface,
): Promise<boolean> {
    return await axios.put(`/admin/api/v1/recap/clause/${id}`, data).then((res) => res.data);
}
export async function getRecapTemplates(): Promise<{ data: Array<RecapTemplateInterface> }> {
    return await axios.get("/admin/api/v1/recap/templates").then((res) => res.data);
}

export async function getRecapTemplate(id: string): Promise<RecapInterface> {
    return await axios.get(`/admin/api/v1/recap/templates/${id}`).then((res) => res.data);
}

export async function createRecapTemplates(
    data: NewRecapTemplateInterface,
): Promise<{ data: RecapTemplateInterface }> {
    return await axios.post("/admin/api/v1/recap/templates", data).then((res) => res.data);
}

export async function updateRecapTemplate(
    id: string,
    data: NewRecapTemplateInterface,
): Promise<{ data: RecapTemplateInterface }> {
    return await axios.put(`/admin/api/v1/recap/templates/${id}`, data).then((res) => res.data);
}

export async function deleteRecapTemplate(id: string): Promise<{ data: RecapTemplateInterface }> {
    return await axios.delete(`/admin/api/v1/recap/templates/${id}`).then((res) => res.data);
}
