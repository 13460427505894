import { CaseFileDocumentIndex, CaseFileDocumentsTableProps } from "../../../types/case-files";
import React, { Fragment, useState } from "react";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns, tableDropDown } from "../../../config/case-file";
import { useNavigate } from "react-router";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import AddFile from "../add-file";
import { formatCaseFileStatus, formatCaseFileStatusColor } from "../../../lib/utils";
import { Typography } from "../../../components/ui/typography";
import { Badge } from "../../../components/ui/badge";
import { Tables } from "../../../config/app";
import AttachmentsPreview, {
    canPreview,
    getFileExtension,
} from "../attachements/attachments-preview";
import { UploadedFile } from "../../../types/uploads";
import { Dialog, DialogContent } from "../../../components/ui/dialog";

export function mapDocumentIndexToUploadedFile(document: CaseFileDocumentIndex): UploadedFile {
    return {
        id: document.id,
        file_name: document.document_name,
        file_type: document.file_type,
        document_name: document.document_name,
        user: document.user,
        created_at: document.updated_at,
        type: getFileExtension(document.document_name),
    };
}

function DocumentsTable({ data }: CaseFileDocumentsTableProps) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            meta: {
                tableId: Tables.CASE_FILE_DETAIL,
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        }),
        navigate = useNavigate(),
        { permissions, case_file_id, meta_data } = useCaseFiles();

    const [selectedDocument, setSelectedDocument] = useState<CaseFileDocumentIndex>();

    function handleClick(row: CaseFileDocumentIndex) {
        if (row.template_type === "recap") {
            navigate("recap");
        }

        if (
            row.template_type === "standard_form" ||
            row.template_type === "bill_of_lading" ||
            row.template_type === "sof" ||
            row.template_type === "nor" ||
            row.template_type === "loi" ||
            row.template_type === "house_form"
        ) {
            navigate(row.id);
        }

        if (["q88", "file_upload"].includes(row.template_type)) {
            setSelectedDocument(row);
        }
    }

    return (
        <div className="flex flex-col h-full">
            {selectedDocument && (
                <Dialog
                    open={true}
                    onOpenChange={(open) => (!open ? setSelectedDocument(undefined) : void 0)}
                >
                    <DialogContent
                        className={
                            canPreview(mapDocumentIndexToUploadedFile(selectedDocument))
                                ? "max-w-[90vw] sm:max-w-[90vw]"
                                : ""
                        }
                    >
                        <AttachmentsPreview
                            row={mapDocumentIndexToUploadedFile(selectedDocument)}
                            onClose={() => void 0}
                        />
                    </DialogContent>
                </Dialog>
            )}

            <DataTableToolbar
                table={table}
                title={
                    <div className="flex items-center mb-4">
                        {meta_data && (
                            <Fragment>
                                <Typography
                                    text={
                                        <Fragment>
                                            <span>CP-ID: {meta_data.reference}</span>
                                            {meta_data?.vessel_name && (
                                                <span>&nbsp;| {meta_data.vessel_name}</span>
                                            )}
                                        </Fragment>
                                    }
                                    style="h3"
                                />
                                <Badge
                                    className="mx-4"
                                    variant={formatCaseFileStatusColor(meta_data.status)}
                                >
                                    {formatCaseFileStatus(meta_data.status)}
                                </Badge>
                                {!permissions.edit_documents && (
                                    <Badge variant="failed">Editting disabled</Badge>
                                )}
                            </Fragment>
                        )}
                    </div>
                }
                searchableColumns={["document_name", "user", "template_name", "template_type"]}
            />
            <div className="flex py-4 h-full overflow-auto">
                <DataTable
                    columns={columns}
                    contextMenuItems={tableDropDown(permissions)}
                    table={table}
                    onClick={handleClick}
                    renderOnEmpty={<AddFile id={case_file_id} />}
                    dataGroups={[
                        {
                            title: "Documents",
                            filter: (row) => {
                                return !["q88", "file_upload"].includes(row.original.template_type);
                            },
                        },
                        {
                            title: "Attachments",
                            filter: (row) =>
                                ["q88", "file_upload"].includes(row.original.template_type),
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default DocumentsTable;
