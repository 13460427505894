import React, { useState } from "react";
import {
    LogoutMenuWrapper,
    MenuContainer,
    MenuIcon,
    MenuItem,
    MenuLink,
    TopBarActionsContainer,
} from "../Style";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../components/ui/button";
import { useAdmin } from "../../../../../lib/providers/admin-auth-provider";
import { adminPrefix } from "../../../../../config/central";

export const Actions = () => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [posX, setPosX] = useState<number>(0);
    const [posY, setPosY] = useState<number>(0);
    //
    // const activeItem = useSelector((state: RootState) => state.menu.active);

    const auth = useAdmin();
    const navigate = useNavigate();

    function handleActionsSelected(e: React.MouseEvent<HTMLLIElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (!showDropDown) {
            setShowDropDown(true);
            setPosY(48);
            setPosX(window.innerWidth - 56 - 240);
        } else {
            setShowDropDown(false);
        }
    }

    function handleLogOut() {
        setShowDropDown(false);
        auth.signOut(() => {
            navigate(`/${adminPrefix}`);
        });
    }

    return (
        <TopBarActionsContainer>
            <MenuContainer>
                <MenuLink to={"/"}>
                    <MenuItem className={""} onClick={handleActionsSelected}>
                        <MenuIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M14.4 0H4.8a1.6 1.6 0 0 0-1.6 1.6v9.6a1.6 1.6 0 0 0 1.6 1.6h9.6a1.6 1.6 0 0 0 1.6-1.6V1.6A1.6 1.6 0 0 0 14.4 0ZM9.6 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm4 8.4h-8v-.2c0-1.48 1.803-3 4-3s4 1.52 4 3v.2Z"></path>
                                <path d="M1.6 4.8H0v9.6c0 .882.718 1.6 1.6 1.6h9.6v-1.6H1.6V4.8Z"></path>
                            </g>
                        </MenuIcon>
                    </MenuItem>
                </MenuLink>

                {showDropDown && (
                    <LogoutMenuWrapper
                        posX={posX}
                        posY={posY}
                        onBlur={() => setShowDropDown(false)}
                        className="p-0"
                    >
                        <Button onClick={handleLogOut} className="w-full">
                            Log out
                        </Button>
                    </LogoutMenuWrapper>
                )}
            </MenuContainer>
        </TopBarActionsContainer>
    );
};
