import React, { Fragment, useState } from "react";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { SupplyChainInvite } from "../../../types/case-files";
import CreateRotLink from "./create-rot-link";
import { Card } from "../../../components/ui/card";
import { Badge } from "../../../components/ui/badge";
import { Typography } from "../../../components/ui/typography";
import RotDropDown from "./rot-drop-down";
import { formatApprovalStatus, formatPermissionSet } from "../../../lib/utils";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuGroup,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuTrigger,
} from "../../../components/ui/context-menu";
import RotEditPermissions from "./rot-edit-permissions";
import RotMarkForApproval from "./rot-mark-for-approval";
import RotDelete from "./rot-delete";
import RotRemoveApproval from "./rot-remove-approval";
import { Dialog } from "../../../components/ui/dialog";
import { DropdownMenuLabel } from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import { Megaphone, Pencil, ThumbsUp } from "lucide-react";

function Permissions() {
    const { rot_links, is_owner } = useCaseFiles();
    const [selectedAction, setSelectedAction] = useState<
        "edit-permission" | "request-approval" | "remove-access" | "remove-approval"
    >();
    const [selectedRow, setSelectedRow] = useState<SupplyChainInvite>();

    function formatUsersList(link: SupplyChainInvite): string | undefined {
        if (link.additional_data.users_to_notify) {
            let res = "";

            link.additional_data.users_to_notify.map((user, index) => {
                res = res.concat(user.name);

                if (index < link.additional_data.users_to_notify.length - 1) {
                    res = res.concat(", ");
                }
            });

            return res;
        }

        return undefined;
    }

    return (
        <Fragment>
            <Card className="shadow-none border-none">
                <div className="flex">
                    <div className="flex items-center justify-between w-full">
                        <div>
                            <Typography text="Permissions" style="h3" className="mb-4" />
                        </div>
                        {is_owner && <CreateRotLink />}
                    </div>
                </div>
                <div className="mt-4">
                    {rot_links.length ? (
                        rot_links.map((rot_link, index) => (
                            <ContextMenu key={index}>
                                <ContextMenuTrigger disabled={!is_owner}>
                                    <div
                                        className="flex gap-4 items-center"
                                        onClick={() => setSelectedRow(rot_link)}
                                    >
                                        <div className={"text-sm flex-grow"}>
                                            <div className={"font-medium"}>
                                                {rot_link.company_name}
                                            </div>
                                            <div
                                                className={"text-slate-500 flex gap-2 items-start"}
                                                title={"These persons are notified."}
                                            >
                                                <Megaphone className={"w-4 h-5"} />{" "}
                                                {formatUsersList(rot_link) ?? ""}
                                            </div>
                                        </div>
                                        {!rot_link.approval_id && (
                                            <Badge variant="success" className="w-auto">
                                                {formatPermissionSet(rot_link.permission_set)}
                                            </Badge>
                                        )}
                                        {rot_link.approval_status && (
                                            <Badge
                                                variant={
                                                    rot_link.approval_status === "pending"
                                                        ? "primary"
                                                        : rot_link.approval_status === "approved"
                                                          ? "success"
                                                          : "failed"
                                                }
                                                className="w-auto"
                                            >
                                                {formatApprovalStatus(rot_link.approval_status)}
                                            </Badge>
                                        )}
                                        {is_owner && (
                                            <div
                                                className="pr-4"
                                                onClick={() => setSelectedRow(rot_link)}
                                            >
                                                <RotDropDown
                                                    rot_link={rot_link}
                                                    setSelectedAction={(e) => setSelectedAction(e)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </ContextMenuTrigger>
                                <ContextMenuContent className="w-56">
                                    <DropdownMenuLabel className="px-2 py-1.5 text-sm font-semibold">
                                        Actions
                                    </DropdownMenuLabel>
                                    <ContextMenuGroup>
                                        <ContextMenuSeparator />
                                        {!rot_link.approval_id && (
                                            <Fragment>
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    className="w-full justify-start"
                                                    onClick={() => {
                                                        setSelectedAction("edit-permission");
                                                        setSelectedRow(rot_link);
                                                    }}
                                                >
                                                    <ContextMenuItem>
                                                        <Pencil className="mr-2 h-4 w-4" />
                                                        <span>Edit permissions</span>
                                                    </ContextMenuItem>
                                                </Button>
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    className="w-full justify-start"
                                                    onClick={() => {
                                                        setSelectedAction("request-approval");
                                                        setSelectedRow(rot_link);
                                                    }}
                                                >
                                                    <ContextMenuItem>
                                                        <ThumbsUp className="mr-2 h-4 w-4" />
                                                        <span>Request approval</span>
                                                    </ContextMenuItem>
                                                </Button>
                                            </Fragment>
                                        )}
                                        {rot_link.approval_id && (
                                            <Button
                                                variant="ghost"
                                                className="w-full justify-start"
                                                onClick={() => {
                                                    setSelectedAction("remove-approval");
                                                    setSelectedRow(rot_link);
                                                }}
                                            >
                                                <ContextMenuItem>
                                                    <Pencil className="mr-2 h-4 w-4" />
                                                    <span>Withdraw approval</span>
                                                </ContextMenuItem>
                                            </Button>
                                        )}
                                    </ContextMenuGroup>
                                    <ContextMenuSeparator />
                                    <ContextMenuGroup>
                                        <Button
                                            variant="destructive"
                                            className="w-full justify-start"
                                            onClick={() => {
                                                setSelectedAction("remove-access");
                                                setSelectedRow(rot_link);
                                            }}
                                        >
                                            <ContextMenuItem className="bg-red-500 focus:bg-red-500">
                                                <Pencil className="mr-2 h-4 w-4 hover:fill-white" />
                                                <span className="text-white">
                                                    Withdraw permission
                                                </span>
                                            </ContextMenuItem>
                                        </Button>
                                    </ContextMenuGroup>
                                </ContextMenuContent>
                            </ContextMenu>
                        ))
                    ) : (
                        <div>
                            <Typography
                                text="No invites yet"
                                style="muted"
                                className="flex justify-center"
                            />
                        </div>
                    )}
                </div>
            </Card>
            <Dialog onOpenChange={() => setSelectedAction(undefined)} open={!!selectedAction}>
                {selectedRow && (
                    <Fragment>
                        {selectedAction === "edit-permission" && (
                            <RotEditPermissions
                                rot_link={selectedRow}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                        {selectedAction === "request-approval" && (
                            <RotMarkForApproval
                                rot_link={selectedRow}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                        {selectedAction === "remove-access" && (
                            <RotDelete
                                rot_link={selectedRow}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                        {selectedAction === "remove-approval" && (
                            <RotRemoveApproval
                                rot_link={selectedRow}
                                onClose={() => setSelectedAction(undefined)}
                            />
                        )}
                    </Fragment>
                )}
            </Dialog>
        </Fragment>
    );
}

export default Permissions;
