import React, { Fragment } from "react";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { GlobalDocumentInterface } from "../../../types/document-editor";
import { useDocumentEditor } from "../../../lib/providers/DocumentEditorProvider";
import { cancelSignature } from "../../../api/tenant/docusign";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function CancelSignatures({ row, onClose }: TableActionProps<GlobalDocumentInterface>) {
    const { setSigningStatus } = useDocumentEditor();
    const { case_file_id } = useCaseFiles();
    async function handleSubmit() {
        await cancelSignature(row.id, case_file_id);
        setSigningStatus("cancelled");
        onClose();
    }

    return (
        <Fragment>
            <DialogHeader>
                <DialogTitle>Cancel E-Signing process</DialogTitle>
                <DialogDescription>
                    This will cancel the current signing process and void all placed signatures
                </DialogDescription>
            </DialogHeader>
            <DialogFooter className="mt-4">
                <Button type="submit" variant="destructive" onClick={handleSubmit}>
                    Yes I&apos;m sure
                </Button>
            </DialogFooter>
        </Fragment>
    );
}

export default CancelSignatures;
