import TableOfContents from "../../../components/table-of-contents/table-of-contents";
import { Addenda } from "../../../types/addenda";
import React from "react";
import { useAddenda } from "../../../lib/providers/addenda-provider";
import AddendaTableOfContentsButtons from "./addenda-table-of-contents-buttons";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function AddendaTableOfContents() {
    const { permissions } = useCaseFiles();
    const { addenda, setActiveAddendum, setModalState, activeAddendum } = useAddenda();

    function formatAddendaForTableOfContents(
        addenda: Array<Addenda>,
    ): Array<Addenda & { label: string; inactive?: boolean; active?: boolean }> {
        return addenda.map((addendum, index) => {
            return {
                ...addendum,
                label: `(${index + 1}) - ${addendum.title}`,
                inactive: !addendum.is_visible,
                active: addendum.id === activeAddendum?.id,
            };
        });
    }

    function removeAddendum(e: React.MouseEvent<HTMLDivElement>, addendum: Addenda) {
        e.stopPropagation();

        setActiveAddendum(addendum);
        setModalState({
            show: true,
            type: "remove",
        });
    }

    // function exportAddendum(e: React.MouseEvent<HTMLDivElement>, addendum: Addenda) {
    //     e.stopPropagation();
    //
    //     setActiveAddendum(addendum);
    //     setModalState({
    //         show: true,
    //         type: "export-single",
    //     });
    // }

    return (
        <TableOfContents
            title="Table of contents"
            items={addenda ? formatAddendaForTableOfContents(addenda) : []}
            listDraggable={false}
            onClick={(addenda) => setActiveAddendum(addenda)}
            options={<AddendaTableOfContentsButtons />}
            itemActions={{
                label: "Actions",
                disabled: !permissions.edit_documents,
                className: "w-42",
                align: "end",
                actions: [
                    // {
                    //     label: () => "Export",
                    //     action: exportAddendum,
                    // },
                    {
                        label: () => "Remove",
                        action: removeAddendum,
                    },
                ],
            }}
        />
    );
}

export default AddendaTableOfContents;
