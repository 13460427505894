import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { WithCaseFileId } from "../../types";

interface RecapFieldValuePayloadInterface {
    recap_document_id: string;
    field_id: string;
    recap_vessel_id?: string | null;
    value: string;
}

interface RecapFieldValueIdentifierInterface {
    recap_document_id: string;
    field_id: string;
    recap_vessel_id?: string | null;
}

interface RecapFieldValueEntityInterface extends RecapFieldValuePayloadInterface {
    id: string;
    created_at: string | null;
    deleted_at: string | null;
    updated_at: string | null;
    user_id: string | null;
}

interface RecapFieldValuesState {
    loading: "initial" | "idle" | "pending" | "complete" | "failed";
    entities: RecapFieldValueEntityInterface[];
    selected?: string;
    error?: string;
}

const initialState: RecapFieldValuesState = {
    entities: [],
    loading: "initial",
};

const fetchFieldValues = createAsyncThunk(
    "field_values/fetch",
    async (body: { recapId: string; case_file_id: string }) => {
        const { data } = await axios.get(
            `/api/tenant/v1/recaps/${body.case_file_id}/document/${body.recapId}/field_values`,
            {},
        );

        return data.data;
    },
);

const updateFieldValue = createAsyncThunk(
    "field_value/update",
    async (payload: WithCaseFileId<RecapFieldValuePayloadInterface>) => {
        const { recap_document_id, field_id, recap_vessel_id, case_file_id, ...postData } = payload;
        if (recap_vessel_id) {
            const { data } = await axios.post(
                `/api/tenant/v1/recaps/${case_file_id}/document/${recap_document_id}/field_values/${field_id}/${recap_vessel_id}`,
                postData,
            );
            return data.data;
        } else {
            const { data } = await axios.post(
                `/api/tenant/v1/recaps/${case_file_id}/document/${recap_document_id}/field_values/${field_id}`,
                postData,
            );
            return data.data;
        }
    },
);

const deleteFieldValue = createAsyncThunk(
    "field_values/delete",
    async (payload: RecapFieldValueIdentifierInterface) => {
        const { recap_document_id, field_id, recap_vessel_id } = payload;

        if (!recap_vessel_id) {
            throw Error("Cannot delete vessel field. No recap_vessel_id defined.");
        }
        await axios.delete(
            `/api/tenant/v1/recaps/document/${recap_document_id}/field_values/${field_id}/${recap_vessel_id}`,
        );

        return payload;
    },
);

const deleteFieldValues = createAsyncThunk(
    "field_values/delete_all",
    async (payload: RecapFieldValueIdentifierInterface) => {
        const { recap_document_id, field_id } = payload;
        await axios.delete(
            `/api/tenant/v1/recaps/document/${recap_document_id}/field_values/${field_id}`,
        );

        return payload;
    },
);

const RecapFieldValuesSlice = createSlice({
    name: "recap-field-values",
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<RecapFieldValueEntityInterface>) => {
            const index = state.entities.findIndex((entity) => entity.id === action.payload.id);
            if (index !== -1) {
                state.entities.splice(index, 1, action.payload);
            } else {
                state.entities.push(action.payload);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFieldValues.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(
                fetchFieldValues.fulfilled,
                (state, action: PayloadAction<RecapFieldValueEntityInterface[]>) => {
                    state.entities = action.payload;
                    state.loading = "complete";
                },
            )
            .addCase(
                updateFieldValue.fulfilled,
                (state, action: PayloadAction<RecapFieldValueEntityInterface>) => {
                    const index = state.entities.findIndex(
                        (entity) => entity.id === action.payload.id,
                    );
                    if (index !== -1) {
                        state.entities.splice(index, 1, action.payload);
                    } else {
                        state.entities.push(action.payload);
                    }
                },
            )
            .addCase(updateFieldValue.rejected, () => {
                throw new Error("Recap field value could not be saved");
            })
            .addCase(
                deleteFieldValue.fulfilled,
                (state, action: PayloadAction<RecapFieldValueIdentifierInterface>) => {
                    const index = state.entities.findIndex(
                        (entity) =>
                            entity.field_id === action.payload.field_id &&
                            entity.recap_vessel_id === action.payload.recap_vessel_id,
                    );
                    if (index !== -1) {
                        state.entities.splice(index, 1);
                    }
                },
            )
            .addCase(
                deleteFieldValues.fulfilled,
                (state, action: PayloadAction<RecapFieldValueIdentifierInterface>) => {
                    const index = state.entities.findIndex(
                        (entity) => entity.field_id === action.payload.field_id,
                    );
                    if (index !== -1) {
                        state.entities.splice(index, 1);
                    }
                },
            );
    },
});

const { setValue } = RecapFieldValuesSlice.actions;
export { RecapFieldValueEntityInterface, RecapFieldValuePayloadInterface };
export { fetchFieldValues, updateFieldValue, deleteFieldValues, deleteFieldValue, setValue };
export default RecapFieldValuesSlice.reducer;
