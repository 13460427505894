import * as React from "react";
import { cn } from "../../lib/utils";

const Panel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div
            ref={ref}
            className={cn("rounded-sm p-4 border bg-foreground shadow-sm", className)}
            {...props}
        />
    ),
);
Panel.displayName = "Panel";

export { Panel };
