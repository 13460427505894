import { Logo } from "./Logo";
import { Menu } from "./Menu";
import { Actions } from "./Actions";
import React from "react";
import styled from "styled-components";
import { boxShadowDiv, color } from "../../../styles/mixins";

const TopBarWrapper = styled.section`
    width: 250px;
    display: flex;
    box-shadow: ${boxShadowDiv("transHead")("black", 20)},
        ${boxShadowDiv("transMiddle")("black", 14)}, ${boxShadowDiv("transTail")("black", 12)};
`;

const TopBarContainer = styled.header`
    background-color: ${color("white")};
    padding: 1.5rem 2rem;
`;

const TopBarInnerWrapper = styled.section`
    display: flex;
    flex-direction: column;
`;

export const AdminTopBar = () => {
    return (
        <TopBarWrapper>
            <TopBarContainer>
                <TopBarInnerWrapper>
                    <Logo />
                    <Menu />
                    <Actions />
                </TopBarInnerWrapper>
            </TopBarContainer>
        </TopBarWrapper>
    );
};
