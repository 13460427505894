import { Cross2Icon } from "@radix-ui/react-icons";
import React, { Fragment } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { DataTableViewOptions } from "./data-table-view-options";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { Typography } from "../ui/typography";
import { DataTableToolbarProps } from "../../types/table";

export function DataTableToolbar<TData>({
    table,
    filters,
    title,
    buttons,
}: DataTableToolbarProps<TData>) {
    const isFiltered = table.getState().columnFilters.length > 0;

    function handleFiltering(event: React.ChangeEvent<HTMLInputElement>) {
        table.setGlobalFilter(event.target.value.toLowerCase());
    }

    function FormatTitle() {
        if (title) {
            if (typeof title === "string") {
                return <Typography text={title} style="h3" className="mb-4" />;
            } else {
                return title;
            }
        }

        return null;
    }

    return (
        <Fragment>
            {(title || buttons) && (
                <div className="flex w-full justify-between">
                    <FormatTitle />
                    {buttons && (
                        <div className="flex gap-x-4">
                            {buttons.map((button, index) => (
                                <Button
                                    onClick={button.action}
                                    key={index}
                                    disabled={button.disabled}
                                >
                                    {button.label}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            )}
            <div className="flex items-center justify-between w-full">
                <div className="flex flex-1 items-center space-x-2">
                    <Input
                        placeholder="Search"
                        onChange={handleFiltering}
                        className="h-8 w-[150px] lg:w-[250px]"
                    />
                    {filters?.items.map((filter, index) => (
                        <Fragment key={index}>
                            {table.getColumn("status") && (
                                <DataTableFacetedFilter
                                    column={table.getColumn(filter.column)}
                                    title={filter.label}
                                    options={filter.options}
                                    icon={filter.icon}
                                />
                            )}
                        </Fragment>
                    ))}
                    {isFiltered && (
                        <Fragment>
                            <Button
                                variant="ghost"
                                onClick={() => table.resetColumnFilters()}
                                className="h-8 px-2 lg:px-3"
                            >
                                Reset
                                <Cross2Icon className="ml-2 h-4 w-4" />
                            </Button>
                        </Fragment>
                    )}
                </div>
                <DataTableViewOptions table={table} />
            </div>
        </Fragment>
    );
}
