import {
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentTitle,
    DashboardTopBarPerformanceItem,
    DashboardTopBarPerformanceItemContent,
    DashboardTopBarPerformanceItemTitle,
} from "../../../Dashboard/Style";
import React, { Fragment, useState } from "react";
import { AdminTrialInfoTenantInterface } from "../../index.interface";
import { InfoWrapper } from "../../Style";
import { updateTenant } from "../../../../Admin/Tenants";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { Input } from "../../../../../components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../../../components/ui/dialog";
import { Button } from "../../../../../components/ui/button";
import { Calendar } from "../../../../../components/ui/calendar";
import { format, startOfToday } from "date-fns";
import { TenantDetailPlan } from "../../../../../types/Central";

type ModalTypeEnum = "invoice" | "trial";

export function AdminTrialInfoTenant({ tenant, onUpdate }: AdminTrialInfoTenantInterface) {
    const [rot, setRot] = useState<boolean>(tenant.rot_enabled);
    const [invoiceThreshold, setInvoiceThreshold] = useState<number>(tenant.invoice_threshold);
    const [modalState, setModalState] = useState<boolean>(false);
    const [trialPeriodEnd, setTrialPeriodEnd] = useState<Date | null>(tenant.trial_ends_at ?? null);
    const [modalType, setModalType] = useState<ModalTypeEnum>();
    const currencyFormatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
    });

    function handleRot(checked: boolean) {
        const values: TenantDetailPlan = {
            tenant_id: tenant.id,
            trial_ends_at: tenant.trial_ends_at,
            invoice_threshold: tenant.invoice_threshold,
            rotEnabled: checked,
        };
        updateTenant(values).then(() => {
            onUpdate();
            setRot(checked);
        });
    }

    function handleSubmit() {
        const values: TenantDetailPlan = {
            tenant_id: tenant.id,
            trial_ends_at:
                modalType === "trial"
                    ? new Date(
                          trialPeriodEnd!.getFullYear(),
                          trialPeriodEnd!.getMonth(),
                          trialPeriodEnd!.getDate() + 1,
                      )
                    : tenant.trial_ends_at,
            invoice_threshold:
                modalType === "invoice" ? invoiceThreshold : tenant.invoice_threshold,
            rotEnabled: tenant.rot_enabled,
        };
        updateTenant(values).then(() => {
            onUpdate();
            setModalState(false);
        });
    }

    function handleInvoiceChange(event: React.ChangeEvent) {
        setInvoiceThreshold(Number((event.target as HTMLInputElement).value));
    }

    function handleModal(modal: ModalTypeEnum) {
        setModalType(modal);
        setModalState(!modalState);
    }

    function getCalendarStartingDate(): Date {
        return tenant.trial_ends_at && tenant.trial_ends_at > startOfToday()
            ? tenant.trial_ends_at
            : startOfToday();
    }

    return (
        <Fragment>
            <InfoWrapper>
                <DashboardTopBarContainerContentHeader>
                    <DashboardTopBarContainerContentTitle>
                        Subscription info
                    </DashboardTopBarContainerContentTitle>
                </DashboardTopBarContainerContentHeader>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Current plan:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        {tenant.plan}
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Trial period ends:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        <button onClick={() => handleModal("trial")}>
                            {tenant?.trial_ends_at
                                ? new Date(tenant.trial_ends_at).toLocaleDateString()
                                : "-"}
                        </button>
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        Invoice threshold:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        <button onClick={() => handleModal("invoice")}>
                            {currencyFormatter.format(tenant.invoice_threshold / 100)}
                        </button>
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItem>
                    <DashboardTopBarPerformanceItemTitle>
                        ROT enable:
                    </DashboardTopBarPerformanceItemTitle>
                    <DashboardTopBarPerformanceItemContent>
                        <div className="flex items-center space-x-2">
                            <Checkbox id="terms" defaultChecked={rot} onCheckedChange={handleRot} />
                        </div>
                    </DashboardTopBarPerformanceItemContent>
                </DashboardTopBarPerformanceItem>

                <Dialog open={modalState} onOpenChange={() => setModalState(!modalState)}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>
                                {modalType === "invoice"
                                    ? "Invoice Threshold"
                                    : "Select the end of trial"}
                            </DialogTitle>
                        </DialogHeader>
                        {modalType === "invoice" ? (
                            <Input
                                defaultValue={tenant.invoice_threshold}
                                onChange={handleInvoiceChange}
                            />
                        ) : (
                            <Calendar
                                initialFocus
                                mode="single"
                                defaultMonth={getCalendarStartingDate()}
                                fromMonth={startOfToday()}
                                selected={trialPeriodEnd ?? startOfToday()}
                                onSelect={(date: Date) => setTrialPeriodEnd(date)}
                                disabled={[{ from: new Date(1, 1, 1970), to: startOfToday() }]}
                                components={{
                                    CaptionLabel: (props) => (
                                        <div className="text-sm font-medium">
                                            {format(props.displayMonth, "MMMM yyyy")}
                                        </div>
                                    ),
                                }}
                            />
                        )}
                        <DialogFooter className="mt-4 mr-2">
                            <Button
                                id="invoice_threshold_button"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Change
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </InfoWrapper>
        </Fragment>
    );
}
