import { tenantApi } from "../tenantApi";
import { EnabledColumnsType, Tables } from "../../config/app";

const settingsApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        toggleTableColumnVisibility: build.mutation<
            boolean,
            { table: Tables; column: string; visible: boolean }
        >({
            query: (body) => ({
                url: "/settings/column-visibility",
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["ColumnVisibility"],
        }),
        getColumnVisibility: build.query<EnabledColumnsType, void>({
            query: () => ({
                url: "/settings/column-visibility",
            }),
            providesTags: ["ColumnVisibility"],
        }),
    }),
});

export const { useToggleTableColumnVisibilityMutation, useLazyGetColumnVisibilityQuery } =
    settingsApi;
