import { tenantApi } from "../tenantApi";
import { Addenda } from "../../types/addenda";
import { WithCaseFileId } from "../../types";

const addendaApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getCaseFileAddenda: build.query<{ data: Array<Addenda> }, string>({
            query: (case_file_id) => ({
                url: `/${case_file_id}/case-file/addenda`,
            }),
            providesTags: (result: { data: Array<Addenda> }) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "Addenda", id }) as const)
                    : []),
                { type: "Addenda" as const, id: "LIST" },
            ],
        }),
        saveAddendum: build.mutation<
            Addenda,
            WithCaseFileId<Pick<Addenda, "title" | "content"> & { id?: string }>
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `${case_file_id}/case-file/addenda/create`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [{ type: "Addenda", id: "LIST" }],
        }),
        removeAddenda: build.mutation<boolean, WithCaseFileId<{ id: string }>>({
            query: ({ id, case_file_id }) => ({
                url: `/${case_file_id}/case-file/addenda/remove/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Addenda", id: "LIST" }],
        }),
        exportAddenda: build.mutation<string, WithCaseFileId<{ addenda: Array<Addenda> }>>({
            query: ({ case_file_id, ...body }) => ({
                url: `/${case_file_id}/case-file/addenda/export`,
                method: "POST",
                body: {
                    ...body,
                },
                responseHandler: "text",
            }),
        }),
    }),
});

export const {
    useGetCaseFileAddendaQuery,
    useSaveAddendumMutation,
    useRemoveAddendaMutation,
    useExportAddendaMutation,
} = addendaApi;
