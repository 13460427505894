import React, { Fragment, useEffect, useState } from "react";
import { RocketIcon } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert";
import { toast } from "sonner";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { useDeleteCaseFileMutation } from "../../../api/tenant/case-file";
import { CaseFileIndex } from "../../../types/case-files";
import Loading from "../../../components/global/loading";

function CaseFileDelete({ row, onClose }: TableActionProps<CaseFileIndex>) {
    const preventDeleteIfSupplyChain = row.supply_chain_link_count > 0;
    const [deleteCaseFile] = useDeleteCaseFileMutation();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            onClose();
        };
    }, []);

    async function handleSubmit() {
        setLoading(true);

        try {
            await deleteCaseFile({
                id: row.id,
                is_supply_chain: row.is_supply_chain,
                supply_chain_id: row.supply_chain_id,
            });

            onClose();

            toast.message("Moved the recap / CP to archive");
        } catch (e) {
            toast.error("something went wrong while moving the recap / CP to archive");
        }

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Archive {row.document_name}</DialogTitle>
                        <DialogDescription>
                            The recap / cp will be moved to your archive where it can be restored
                        </DialogDescription>
                    </DialogHeader>
                    {preventDeleteIfSupplyChain ? (
                        <Alert className="mb-8 border-solid border-2 border-red-500">
                            <RocketIcon className="h-4 w-4 fill-slate-950" />
                            <AlertTitle className="text-slate-950">Heads up!</AlertTitle>
                            <AlertDescription className="text-slate-950">
                                We&apos;re unable to archive this recap / CP since it has active
                                Releasing of Terms links. Please remove these before archiving this
                                recap / CP.
                            </AlertDescription>
                        </Alert>
                    ) : (
                        <Fragment>
                            <Alert className="mb-8">
                                <RocketIcon className="h-4 w-4 fill-slate-950" />
                                <AlertTitle className="text-slate-950">Heads up!</AlertTitle>
                                <AlertDescription className="text-slate-950">
                                    The recap / CP will be moved to your archive where it can be
                                    restored at any time
                                </AlertDescription>
                            </Alert>
                            <DialogFooter>
                                <Button type="submit" onClick={handleSubmit} variant="destructive">
                                    Move to archive
                                </Button>
                            </DialogFooter>
                        </Fragment>
                    )}
                </Fragment>
            }
        />
    );
}

export default CaseFileDelete;
