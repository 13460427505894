import { tenantApi } from "../tenantApi";
import { RiderDBResource, RiderTerm } from "../../types/riders";
import { WithCaseFileId } from "../../types";

export const ridersApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getRiderTerms: build.query<Array<RiderTerm>, string>({
            query: (id) => ({
                url: `/${id}/case-file/rider-terms`,
            }),
            providesTags: (result: Array<RiderTerm>) => [
                ...(result ? result.map(({ id }) => ({ type: "Riders", id }) as const) : []),
                { type: "Riders" as const, id: "LIST" },
            ],
        }),
        getGlobalRiderClauses: build.query<{ data: Array<RiderDBResource> }, void>({
            query: () => ({
                url: "/rider-terms/get-global-clauses",
            }),
            providesTags: (result: { data: Array<RiderDBResource> }) => [
                ...(result?.data
                    ? result.data.map(({ id }) => ({ type: "Riders", id }) as const)
                    : []),
                { type: "GlobalRiders" as const, id: "LIST" },
            ],
        }),
        setRiderStartNumber: build.mutation<number, WithCaseFileId<Pick<RiderTerm, "offset">>>({
            query: ({ case_file_id, offset }) => ({
                url: `/rider-terms/set-start/${case_file_id}`,
                method: "PUT",
                body: {
                    start: offset,
                },
            }),
        }),
        createRiderTerm: build.mutation<
            { data: RiderTerm },
            WithCaseFileId<Pick<RiderTerm, "title" | "content">>
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `/rider-terms/${case_file_id}/create`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: [{ type: "Riders", id: "LIST" }],
        }),
        updateMultipleRiderTerms: build.mutation<
            { data: Array<RiderTerm> },
            WithCaseFileId<{ terms: Array<RiderTerm> }>
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `/rider-terms/${case_file_id}/update-multiple`,
                method: "PUT",
                body: {
                    terms: body.terms,
                },
            }),
            invalidatesTags: [{ type: "Riders", id: "LIST" }],
        }),
        updateRiderHeader: build.mutation<string, WithCaseFileId<{ header: string }>>({
            query: ({ case_file_id, header }) => ({
                url: `/rider-terms/update-header/${case_file_id}`,
                method: "PUT",
                body: {
                    header: header,
                },
                responseHandler: "text",
            }),
        }),
        deleteRiderTerm: build.mutation<{ data: boolean }, WithCaseFileId<{ term_id: string }>>({
            query: ({ case_file_id, term_id }) => ({
                url: `/rider-terms/${case_file_id}/remove/${term_id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Riders", id: "LIST" }],
        }),
        setRiderNumbering: build.mutation<boolean, WithCaseFileId<{ state: boolean }>>({
            query: ({ case_file_id, ...body }) => ({
                url: `/rider-terms/numbering-state/${case_file_id}`,
                method: "PUT",
                body: body,
            }),
        }),
        toggleVisibilityRiderTerm: build.mutation<
            { data: RiderTerm },
            WithCaseFileId<{ term_id: string }>
        >({
            query: ({ case_file_id, term_id }) => ({
                url: `/rider-terms/${case_file_id}/visibility/${term_id}`,
                method: "PUT",
            }),
        }),
        parseRiderSelection: build.mutation<
            { data: Array<RiderTerm> },
            WithCaseFileId<{ term: string }>
        >({
            query: ({ case_file_id, term }) => ({
                url: `/rider-terms/parse-text/${case_file_id}`,
                method: "POST",
                body: {
                    content: term,
                },
            }),
        }),
        getRiderTerm: build.mutation<{ data: RiderTerm }, string>({
            query: (id) => ({
                url: "rider-terms/single/",
                method: "POST",
                body: { id: id },
            }),
            invalidatesTags: [{ type: "Riders", id: "LIST" }],
        }),
        exportRiderTerms: build.query<string, string>({
            query: (case_file_id) => ({
                url: `/rider-terms/export/${case_file_id}`,
                responseHandler: "text",
            }),
        }),
    }),
});

export const {
    useGetRiderTermsQuery,
    useSetRiderStartNumberMutation,
    useCreateRiderTermMutation,
    useUpdateMultipleRiderTermsMutation,
    useUpdateRiderHeaderMutation,
    useGetGlobalRiderClausesQuery,
    useDeleteRiderTermMutation,
    useSetRiderNumberingMutation,
    useToggleVisibilityRiderTermMutation,
    useParseRiderSelectionMutation,
    useGetRiderTermMutation,
    useLazyExportRiderTermsQuery,
} = ridersApi;
