import React, { useEffect, useState } from "react";
import { useGetUploadedFilesQuery } from "../../../api/tenant/attachments";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import Loading from "../../../components/global/loading";
import AttachmentsTable from "./attachments-table";
import AttachmentsPreview from "./attachments-preview";
import { UploadedFile } from "../../../types/uploads";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { DataTableToolbarButton } from "../../../types/table";
import Upload from "../upload";
import { usePageTitle } from "../../../lib/usePageTitle";

function Attachments() {
    const { case_file_id, setActivePage, meta_data, permissions } = useCaseFiles();
    const { data, isLoading } = useGetUploadedFilesQuery(case_file_id);
    const [showPreview, setShowPreview] = useState<boolean>();
    const [selectedRow, setSelectedRow] = useState<UploadedFile>();
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

    usePageTitle(`CP: ${meta_data?.reference} - Attachments`);

    useEffect(() => {
        setActivePage("attachments");
    }, []);

    function handleClick(row: UploadedFile) {
        setSelectedRow(row);
        setShowPreview(true);
    }

    const buttons: Array<DataTableToolbarButton> = [
        {
            label: "Upload",
            action: () => setShowUploadModal(true),
            disabled: !permissions.upload_documents,
        },
    ];

    return (
        <Loading
            loaded={!isLoading}
            child={
                <div className="p-4 w-full flex items-stretch h-full">
                    {showUploadModal && (
                        <Upload
                            isOpen={showUploadModal}
                            id={case_file_id}
                            onClose={() => setShowUploadModal(false)}
                        />
                    )}
                    {selectedRow && (
                        <Dialog open={showPreview} onOpenChange={() => setShowPreview(false)}>
                            <DialogContent className="max-w-[90vw] sm:max-w-[90vw]">
                                <AttachmentsPreview
                                    row={selectedRow}
                                    onClose={() => setShowPreview(false)}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                    <div className="bg-foreground p-4 rounded-sm">
                        <AttachmentsTable
                            data={data ?? []}
                            onClick={handleClick}
                            buttons={buttons}
                        />
                    </div>
                </div>
            }
        />
    );
}

export default Attachments;
