import React, { Fragment, useEffect } from "react";
import AddendaTableOfContents from "./addenda-table-of-contents";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import AddendaEditor from "./addenda-editor";
import AddendaPreview from "./addenda-preview";
import AddendaHeader from "./addenda-header";
import { AddendaProvider } from "../../../lib/providers/addenda-provider";
import RemoveAddendum from "./remove-addendum";
import AddendaExport from "./addenda-export";
import { usePageTitle } from "../../../lib/usePageTitle";

function Addenda() {
    return (
        <AddendaProvider>
            <AddendaComp />
        </AddendaProvider>
    );
}

function AddendaComp() {
    const { setActivePage, meta_data } = useCaseFiles();

    usePageTitle(`CP: ${meta_data?.reference} - Addenda`);

    useEffect(() => {
        setActivePage("addenda");
    }, []);

    return (
        <Fragment>
            <RemoveAddendum />
            <AddendaExport />
            <div className="flex h-full w-full">
                <div className="p-4 w-full flex h-full gap-x-4">
                    <AddendaTableOfContents />
                    <div className="flex flex-1 flex-col h-full gap-4">
                        <AddendaHeader />
                        <AddendaEditor />
                    </div>
                    <div className="max-[1840px]:hidden">
                        <AddendaPreview />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Addenda;

// pkcs12 -inkey privkey.pem -in cert.pem -export -out unafix-next_8_.pfx
