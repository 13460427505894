import { toast } from "sonner";
import React, { Fragment, useState } from "react";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import { useUnFinalizeDocumentMutation } from "../../../api/tenant/case-file";
import Loading from "../../../components/global/loading";

function UnFinalizeDocument({ row, onClose }: TableActionProps<CaseFileDocumentIndex>) {
    const [unFinalizeDocument] = useUnFinalizeDocumentMutation();
    const { case_file_id } = useCaseFiles();
    const [loading, setLoading] = useState<boolean>(false);
    async function handleSubmit() {
        setLoading(true);
        if (case_file_id) {
            await unFinalizeDocument({
                case_file_id: case_file_id,
                document_id: row.id,
            });

            onClose();
            toast.message(`Un-finalized ${row.document_name}`);
        }

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <Fragment>
                        <DialogHeader>
                            <DialogTitle>Un-finalize {row.document_name}</DialogTitle>
                            <DialogDescription>
                                Finalize this document to re-enable editing options
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className="mt-4">
                            <Button type="submit" onClick={handleSubmit}>
                                Un-finalize
                            </Button>
                        </DialogFooter>
                    </Fragment>
                </Fragment>
            }
        />
    );
}

export default UnFinalizeDocument;
