import { CommandGroup, CommandItem } from "../ui/command";
import React, { Fragment, useEffect, useState } from "react";
import {
    Searchable,
    SearchableGroupOptions,
    SearchableListProps,
    SearchableOption,
} from "./searchable";
import { useHotkeys } from "react-hotkeys-hook";
import { cn } from "../../lib/utils";
import { Popover, PopoverContent } from "../ui/popover";

function SearchableList<Generic>({
    children,
    groups,
    selectedValues,
    onOptionSelected,
    searchPlaceholder,
    className,
    commandItemClassName,
    commandItemSelectedClassName,
    searchInputClassName,
    clearable,
    multiSelectable,
    clearableText,
    labelFormatter,
    autoFocusSearch,
    popover,
    hideNoResults,
}: SearchableListProps<Generic>) {
    /**
     * Since we use the label to search the options array it should always be a string,
     * Perhaps value formatter should be required
     */
    if (groups && typeof groups[0].options[0] === "object" && !labelFormatter) {
        throw Error("Please use the labelFormatter to generate a label of type string");
    }

    const [options, setOptions] = useState<SearchableOption<Generic>[]>(
        getFilterOptions().flatMap((groups) => groups.options),
    );
    const [active, setActive] = useState<SearchableOption<Generic>>();

    const [groupList, setGroupList] =
        useState<SearchableGroupOptions<Generic>[]>(getFilterOptions());

    const [showPopOver, setShowPopOver] = useState<boolean>(false);

    // make sure we reset the options when values is updated
    useEffect(() => {
        if (groups.flatMap((a) => a.options).length !== options.length) {
            setGroupList(getFilterOptions());
            setOptions(getFilterOptions().flatMap((groups) => groups.options));
        }
    }, [groups]);

    function getFilterOptions(search?: string): SearchableGroupOptions<Generic>[] {
        // -1 since we update it before it's usage in the map below
        let index = -1;

        return groups.map((group) => ({
            ...group,
            options: group.options
                .map((x) => {
                    index++;
                    return {
                        label: labelFormatter ? labelFormatter(x) : (x as string),
                        value: x as unknown as Generic,
                        index: index,
                    } as SearchableOption<Generic>;
                })
                .filter((option) => {
                    return String(option.label)
                        .toLowerCase()
                        .includes(search?.toLowerCase() ?? "");
                }),
        }));
    }

    const ref = useHotkeys<HTMLDivElement>("enter", handleEnter, { enableOnFormTags: true });

    useHotkeys("arrowDown", handleArrowDown, { enableOnFormTags: true });
    useHotkeys("arrowUp", handleArrowUp, { enableOnFormTags: true });
    // useHotkeys("enter", handleEnter, { enableOnFormTags: true });

    function handleArrowDown() {
        let newActive = undefined;

        const filteredOptions = groupList.flatMap((group) => group.options);

        if (!active) {
            newActive = options[filteredOptions[0].index];
        } else {
            newActive =
                filteredOptions[
                    filteredOptions.findIndex((item) => item.index === active.index) + 1
                ] ?? options[filteredOptions[0].index];
        }

        if (newActive) {
            activeItemUpdated(newActive);
        }
    }

    function handleArrowUp() {
        let newActive = undefined;

        const filteredOptions = groupList.flatMap((group) => group.options);

        if (!active) {
            newActive = filteredOptions[filteredOptions.length - 1];
        } else {
            newActive =
                filteredOptions[
                    filteredOptions.findIndex((item) => item.index === active.index) - 1
                ] ?? filteredOptions[filteredOptions.length - 1];
        }

        if (newActive) {
            activeItemUpdated(newActive);
        }
    }

    function activeItemUpdated(newActive: SearchableOption<Generic>) {
        setActive(newActive);
        // const node = document.querySelector(`option-${newActive?.index}`);
        const node = document.querySelector(`[data-option="option-${newActive?.index}"]`);

        if (node) {
            node.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }

    function handleEnter() {
        if (active) {
            handleSelectOption(active);
            setActive(active);
        }
    }

    function handleSelectOption(option: SearchableOption<Generic>) {
        if (selectedValues.has(option.value) && multiSelectable) {
            selectedValues.delete(option.value);
        } else {
            if (!multiSelectable) {
                selectedValues.clear();
            }
            selectedValues.add(option.value);
        }
        setActive(option);
        onOptionSelected(selectedValues);
        if (popover && popover.closeOnSelect) {
            setShowPopOver(false);
        }
    }

    function GroupLists() {
        return (
            <Fragment>
                {groupList.map((group, index) => (
                    <Fragment key={index}>
                        <CommandGroup heading={group.heading}>
                            {group.options.map((option, mapIndex) => {
                                const isSelected = selectedValues.has(option.value);
                                const isActive = active?.value === option.value;

                                return (
                                    <CommandItem
                                        key={mapIndex}
                                        onSelect={() => handleSelectOption(option)}
                                        className={cn(
                                            "cursor-pointer hover:bg-gray-100",
                                            `${commandItemClassName} 
                                                 ${group.className}
                                                 ${isActive ? "bg-gray-100 text-slate-950" : ""}
                                                 ${isSelected ? commandItemSelectedClassName : ""}
                                                `,
                                        )}
                                        data-option={`option-${option.index}`}
                                    >
                                        {children({ option, isSelected })}
                                    </CommandItem>
                                );
                            })}
                        </CommandGroup>
                    </Fragment>
                ))}
            </Fragment>
        );
    }

    return (
        <Popover
            open={!popover ? true : showPopOver}
            onOpenChange={(open) => {
                setShowPopOver(open);
                setActive(undefined);
            }}
        >
            <div ref={ref}>
                <Searchable
                    className={className}
                    onSearchChange={(search) => {
                        setGroupList(getFilterOptions(search));
                        setActive(undefined);
                    }}
                    searchPlaceholder={searchPlaceholder}
                    autoFocusSearch={autoFocusSearch}
                    searchInputClassName={searchInputClassName}
                    clearable={clearable}
                    clearableText={clearableText}
                    clearSelection={() => {
                        onOptionSelected(undefined);
                        if (popover && popover.closeOnSelect) {
                            setShowPopOver(false);
                        }
                    }}
                    selectedValues={selectedValues}
                    searchSelected={() => setShowPopOver(true)}
                    hideNoResults={hideNoResults}
                >
                    {popover ? (
                        <PopoverContent
                            className="max-h-[300px] overflow-y-auto"
                            onOpenAutoFocus={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <GroupLists />
                        </PopoverContent>
                    ) : (
                        <GroupLists />
                    )}
                </Searchable>
            </div>
        </Popover>
    );
}

export default SearchableList;
