import styled, { css } from "styled-components";
import { boxShadow, color } from "../../styles/mixins";
import Select from "react-select";

export const SelectionFilterContainer = styled.section`
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
`;

const ReactSelectStyles = css`
    background-color: ${(props) => props.theme.color.main.white};
    width: 100%;
    border-radius: 0.5em;
    border-color: ${color("darker", 0.65)};
    color: ${(props) => props.theme.color.main.darker};
    height: 38px;

    input[type="text"] {
        color: ${(props) => props.theme.color.main.darker} !important;
    }

    & .react-select__control,
    & .react-select__control--is-focused {
        background-color: ${(props) => props.theme.color.main.white};
        color: ${(props) => props.theme.color.main.darker};
        box-shadow: none;
        border-color: ${(props) => props.theme.color.main.darker};
    }

    & .react-select__control:hover {
        border-color: ${(props) => props.theme.color.main.primary};
    }

    & .react-select__single-value {
        color: ${(props) => props.theme.color.main.darker};
    }

    & .react-select__menu {
        z-index: 998;
        margin-top: 0;
        background-color: ${(props) => props.theme.color.main.white};
        border: 1px solid ${(props) => props.theme.color.main.darker};

        & .react-select__menu-list {
            & .react-select__option:hover {
                cursor: pointer;
                font-weight: 600;
            }

            & .react-select__option--is-selected {
                background-color: ${(props) => props.theme.color.main.primary} !important;
                color: ${(props) => props.theme.color.main.buttonSpan} !important;
            }

            & .react-select__option--is-focused {
                background-color: ${color("primary", 0.25)};
            }
        }
    }
`;

export const Selection = styled(Select)`
    ${ReactSelectStyles};
`;

export const CheckBoxWrapper = styled.div`
    position: relative;
`;

export const CheckBoxLabel = styled.label`
    position: absolute;
    width: 42px;
    height: 26px;
    border-radius: 15px;
    background: ${color("darkerPreviewBg")};
    cursor: pointer;
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin: 3px;
        background: ${color("white")};
        box-shadow: ${boxShadow("thick")("black", 0.2)};
        transition: 0.2s;
    }
`;

export const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 42px;
    height: 26px;
    &:checked + ${CheckBoxLabel} {
        background: ${color("primary")};
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
        }
    }
`;

export const InputGroupLabel = styled.p`
    font-weight: 500;
    color: ${color("darker", 0.85)};
    margin-bottom: 0.5em;
`;
