import React, { Fragment } from "react";
import { RecapFieldResult } from "../../../../types/recap";
import { Typography } from "../../../../components/ui/typography";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import ParsedFieldBox from "./parsed-field-box";
import { Separator } from "../../../../components/ui/separator";

type ImportParsedDataDialogProps = {
    result: RecapFieldResult[];
    unmatched: {
        [key: string]: string;
    };
};

function ImportParsedData({ result, unmatched }: ImportParsedDataDialogProps) {
    return (
        <Fragment>
            {Object.keys(result).length > 0 && (
                <Fragment>
                    <Typography text="Ready for import" style="h3" />
                    <ScrollArea className="w-full max-h-80 p-4 mb-3 border border-gray-300 rounded">
                        {result.map((field) => (
                            <ParsedFieldBox
                                key={field.field_id}
                                label={field.name}
                                content={field.value}
                            />
                        ))}
                    </ScrollArea>
                </Fragment>
            )}
            <Separator />
            {Object.keys(unmatched).length > 0 && (
                <Fragment>
                    <Typography text="Unidentified fields" style="h3" />
                    <ScrollArea className="w-full max-h-80 p-4 border border-gray-300 rounded">
                        {Object.entries(unmatched).map(([key, value]) => (
                            <ParsedFieldBox key={key} label={key} content={value} />
                        ))}
                    </ScrollArea>
                </Fragment>
            )}
        </Fragment>
    );
}

export default ImportParsedData;
