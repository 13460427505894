import { TableActionProps } from "../../../types/table";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import React, { Fragment, useState } from "react";
import { DialogFooter, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { Alert, AlertDescription, AlertTitle } from "../../../components/ui/alert";
import { CalendarIcon, Plus, RocketIcon } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { cn } from "../../../lib/utils";
import { format } from "date-fns";
import { Calendar } from "../../../components/ui/calendar";
import { useGenerateProFormaMutation } from "../../../api/tenant/pro-forma";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../components/ui/tooltip";
import { Typography } from "../../../components/ui/typography";
import { toast } from "sonner";
import Loading from "../../../components/global/loading";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function CreateProForma({ row }: TableActionProps<CaseFileDocumentIndex>) {
    const defaultData = new Date(new Date().setDate(new Date().getDate() + 3));
    const [generateProForma] = useGenerateProFormaMutation();
    const [code, setCode] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { case_file_id } = useCaseFiles();

    const schema = z.object({
        expiration: z
            .date()
            .refine(
                (data) => data.getDay() - 1 <= defaultData.getDay(),
                "Expiration date can not be before today",
            ),
    });

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            expiration: defaultData,
        },
    });

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);

        const { code } = await generateProForma({
            id: row.id,
            case_file_id: case_file_id,
            valid_until: format(values.expiration, "PPP"),
        }).unwrap();

        setCode(code);

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Create pro-forma for: {row.document_name}</DialogTitle>
                    </DialogHeader>
                    {code ? (
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    variant="outline"
                                    onClick={() => {
                                        navigator.clipboard.writeText(code);
                                        toast.success("Copied to clipboard");
                                    }}
                                >
                                    <Plus size="16" className="mr-2" />
                                    Copy to clipboard
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <Typography text="Click to copy" style="p" />
                            </TooltipContent>
                        </Tooltip>
                    ) : (
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(handleSubmit)}>
                                <FormField
                                    control={form.control}
                                    name="expiration"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel className="pb-1">Expiration date</FormLabel>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <FormControl>
                                                        <Button
                                                            variant={"outline"}
                                                            className={cn(
                                                                "pl-3 text-left font-normal flex h-9 w-full",
                                                                !field.value &&
                                                                    "text-muted-foreground",
                                                            )}
                                                        >
                                                            {field.value ? (
                                                                format(field.value, "dd/MM/yyyy")
                                                            ) : (
                                                                <span>Pick a cp date</span>
                                                            )}
                                                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                        </Button>
                                                    </FormControl>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar
                                                        mode="single"
                                                        selected={
                                                            new Date(field.value ?? new Date())
                                                        }
                                                        onSelect={(e) => {
                                                            if (
                                                                (e?.getDate() ?? 0) <
                                                                defaultData.getDate()
                                                            ) {
                                                                form.setError("expiration", {
                                                                    type: "error",
                                                                    message:
                                                                        "Expiration date can not be set before three days from today",
                                                                });
                                                            } else {
                                                                form.clearErrors("expiration");
                                                                form.setValue(
                                                                    "expiration",
                                                                    e ?? defaultData,
                                                                );
                                                            }
                                                        }}
                                                        initialFocus
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Alert className="mb-8">
                                    <RocketIcon className="h-4 w-4 fill-slate-950" />
                                    <AlertTitle className="text-slate-950">Heads up!</AlertTitle>
                                    <AlertDescription className="text-slate-950">
                                        You are about to create a pro-forma code for{" "}
                                        {row.document_name}. With this code, you or any other Ocean
                                        Recap user can create a new charter party that is identical
                                        to {row.document_name}.
                                        <br />
                                        <br />
                                        The original charter party will remain unaffected by this.
                                    </AlertDescription>
                                </Alert>

                                <DialogFooter>
                                    <Button type="submit">Create share code</Button>
                                </DialogFooter>
                            </form>
                        </Form>
                    )}
                </Fragment>
            }
        />
    );
}

export default CreateProForma;
