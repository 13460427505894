import React, { useState } from "react";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { CaseFileAttachmentsTableProps, UploadedFile } from "../../../types/uploads";
import { columns, tableDropDown } from "../../../config/attachments";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { DataTableToolbarButton } from "../../../types/table";
import { Tables } from "../../../config/app";

function AttachmentsTable({
    data,
    onClick,
    buttons,
}: CaseFileAttachmentsTableProps & {
    onClick: (row: UploadedFile) => void;
    buttons: Array<DataTableToolbarButton>;
}) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            meta: {
                tableId: Tables.CASE_FILE_ATTACHMENTS,
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        }),
        { permissions } = useCaseFiles();

    return (
        <div className="flex flex-col h-full">
            <DataTableToolbar
                table={table}
                title="Attachments"
                searchableColumns={["file_name", "user"]}
                buttons={buttons}
            />
            <div className="flex py-4 h-full">
                <DataTable
                    columns={columns}
                    contextMenuItems={tableDropDown(permissions)}
                    table={table}
                    onClick={onClick}
                />
            </div>
        </div>
    );
}

export default AttachmentsTable;
