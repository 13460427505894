import React, { Fragment, useState } from "react";
import { Typography } from "../../../components/ui/typography";
import { Link } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { Sheet, SheetTrigger } from "../../../components/ui/sheet";
import CaseFileCreate from "./create-case-file";
import { useGetCaseFilesQuery } from "../../../api/tenant/case-file";
import OverviewTable from "./overview-table";
import Loading from "../../../components/global/loading";
import { usePageTitle } from "../../../lib/usePageTitle";

function Overview() {
    const { data: documents, isLoading } = useGetCaseFilesQuery(),
        [showNewCaseFileSheet, setShowNewCaseFIleSheet] = useState<boolean>(false);

    usePageTitle("Charter Parties");

    return (
        <Loading
            loaded={!isLoading}
            child={
                <Fragment>
                    <div className="flex flex-col justify-between space-y-2 bg-foreground p-4 border border-solid border-slate-200">
                        <div className="flex items-center justify-between w-full">
                            <Typography text="Charter parties" style="h2" className="pb-0" />
                            <div>
                                <Link to="/archive">
                                    <Button variant="outline" className="mr-4">
                                        Archive
                                    </Button>
                                </Link>
                                <Sheet
                                    open={showNewCaseFileSheet}
                                    onOpenChange={(x) => setShowNewCaseFIleSheet(x)}
                                >
                                    <SheetTrigger
                                        asChild
                                        onClick={() => setShowNewCaseFIleSheet(true)}
                                    >
                                        <Button>New Charter Party</Button>
                                    </SheetTrigger>
                                    <CaseFileCreate showSheet={(x) => setShowNewCaseFIleSheet(x)} />
                                </Sheet>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex items-stretch h-full">
                        <div className="bg-foreground p-4 rounded-sm">
                            <OverviewTable data={documents?.data ?? []} />
                        </div>
                    </div>
                </Fragment>
            }
        />
    );
}

export default Overview;
