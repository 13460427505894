import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FormatFoldersBasedOnTags } from "../../../NewRiderTemplate";
import FroalaEditor from "react-froala-wysiwyg";
import { Modals } from "../../../../Components/Modals";
import { BackToOverviewGroup, RiderNameInputField, RiderTemplateContainer } from "../../Style";
import {
    NewTemplateEditorContainer,
    NewTemplateSettingsContainer,
    NewTemplateSettingsWrapper,
} from "../../../Templates/Style";
import {
    Bar,
    CancelButton,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
} from "../../../../Form/FormField/style";
import { CheckBox, CheckBoxLabel, CheckBoxWrapper } from "../../../../Form/Documents/Style";
import { fetchTags, updateRiderTemplate } from "../../../../Admin/Templates";
import {
    TagDataInterface,
    FolderInterface,
    TemplateItem,
    EditTemplateModalInterface,
    EditTemplateFormInterface,
} from "../../../../../types/Central";

export function EditRiderTemplateModal({ open, onClose, template }: EditTemplateModalInterface) {
    return (
        <Modals
            head={<h1>Edit: {template.name}</h1>}
            body={<EditRiderTemplate template={template} onClose={onClose} />}
            open={open}
            closeOnClick={false}
            onClose={onClose}
            size="full-screen"
            closeOnEscapeDisabled={true}
        />
    );
}

function EditRiderTemplate({ template, onClose }: EditTemplateFormInterface) {
    const [tags, setTags] = useState<Array<TagDataInterface>>([]);
    const [selectedTags, setSelectedTags] = useState<Array<FolderInterface>>([]);

    useEffect(() => {
        fetchTags().then((r) => {
            setTags(r.data);
            setSelectedTags((template.folders as Array<FolderInterface>) ?? []);
        });
    }, []);

    const froalaConfig = {
        key: process.env.FROALA_KEY,
        charCounterCount: false,
        documentReady: true,
        heightMin: 300,
        attribution: false,
        toolbarSticky: false,
        toolbarButtons: {
            moreText: {
                buttons: [
                    "bold",
                    "italic",
                    "underline",
                    "strikeThrough",
                    "subscript",
                    "superscript",
                    "fontFamily",
                    "fontSize",
                    "textColor",
                    "backgroundColor",
                    "inlineClass",
                    "inlineStyle",
                    "clearFormatting",
                ],
            },

            moreParagraph: {
                buttons: [
                    "alignLeft",
                    "alignCenter",
                    "formatOLSimple",
                    "alignRight",
                    "alignJustify",
                    "formatOL",
                    "formatUL",
                    "paragraphFormat",
                    "paragraphStyle",
                    "lineHeight",
                    "outdent",
                    "indent",
                    "quote",
                ],
            },

            moreRich: {
                buttons: [
                    "insertLink",
                    "insertImage",
                    "insertVideo",
                    "insertTable",
                    "emoticons",
                    "fontAwesome",
                    "specialCharacters",
                    "embedly",
                    "insertFile",
                    "insertHR",
                ],
            },

            moreMisc: {
                buttons: [
                    "undo",
                    "redo",
                    "fullscreen",
                    "print",
                    "getPDF",
                    "spellChecker",
                    "selectAll",
                    "html",
                    "help",
                ],

                align: "right",

                buttonsVisible: 2,
            },
        },
        quickInsertEnabled: false,
        events: {
            initialized: function () {
                onEditorInitialized(this);
            },
        },
    };

    const editorRef = useRef<any>(null);

    const onEditorInitialized = (editor: any) => {
        editorRef.current = editor;
    };

    const initialValues: TemplateItem = {
        template_type: "rider_clause",
        name: template.name,
        content: template.content,
        description: template.description,
        footer: "",
        service_fee: 0,
        copyright_fee: 0,
        stylesheet: "",
        multiple_pages: false,
        landscape: false,
        folders: template.folders,
        id: template.id,
        created_at: template.created_at,
        updated_at: template.updated_at,
        active: template.active,
        is_smart: false,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        content: Yup.string().required(),
    });

    function handleSubmit(values: TemplateItem) {
        if (selectedTags.length === 0) {
            return;
        }

        updateRiderTemplate({ ...values, folders: selectedTags }).then(() => {
            onClose();
        });
    }

    function collectTags(tag: FolderInterface) {
        let previousSelected = [...selectedTags];

        if (
            previousSelected.some((el) => {
                return el.id === tag.id;
            })
        ) {
            previousSelected = previousSelected.filter((el) => el.id !== tag.id);
        } else {
            previousSelected.push(tag);
        }

        setSelectedTags(previousSelected);
    }

    return (
        <RiderTemplateContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <NewTemplateSettingsWrapper>
                            <NewTemplateSettingsContainer>
                                <InputGroup>
                                    <CheckBoxWrapper>
                                        <span>Template active:</span>
                                        <CheckBox
                                            id="active"
                                            type="checkbox"
                                            onClick={(e: any) =>
                                                setFieldValue(
                                                    "active",
                                                    e.target.checked === true ? 1 : 0,
                                                )
                                            }
                                            defaultChecked={Boolean(template.active)}
                                        />
                                        <CheckBoxLabel htmlFor="active" />
                                    </CheckBoxWrapper>
                                </InputGroup>
                                <InputGroup>
                                    <RiderNameInputField
                                        type="text"
                                        name="name"
                                        autoCorrect="off"
                                        autoComplete="name"
                                        valid={touched.name && !errors.name}
                                        error={touched.name && errors.name}
                                    />
                                    <Bar />
                                    <Label htmlFor="name">Name</Label>
                                    {errors.name && touched.name && (
                                        <StyledInlineErrorMessage>
                                            {errors.name}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        name="description"
                                        autoCorrect="off"
                                        autoComplete="name"
                                        valid={touched.description && !errors.description}
                                        error={touched.description && errors.description}
                                    />
                                    <Bar />
                                    <Label htmlFor="description">Description</Label>
                                    {errors.description && touched.description && (
                                        <StyledInlineErrorMessage>
                                            {errors.name}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <InputGroup>
                                    <FormatFoldersBasedOnTags
                                        tags={tags}
                                        onClick={collectTags}
                                        active={initialValues.folders}
                                    />
                                </InputGroup>
                                <BackToOverviewGroup>
                                    <CancelButton type="button" onClick={onClose}>
                                        Back to overview
                                    </CancelButton>
                                    <SubmitButton type="submit">Update</SubmitButton>
                                </BackToOverviewGroup>
                            </NewTemplateSettingsContainer>
                            <NewTemplateEditorContainer>
                                {errors.content && touched.content && (
                                    <StyledInlineErrorMessage>
                                        {errors.content}
                                    </StyledInlineErrorMessage>
                                )}
                                <FroalaEditor
                                    config={froalaConfig}
                                    model={values.content}
                                    onModelChange={(val: object | undefined) => {
                                        setFieldValue("content", val);
                                    }}
                                />
                            </NewTemplateEditorContainer>
                        </NewTemplateSettingsWrapper>
                    </Form>
                )}
            </Formik>
        </RiderTemplateContainer>
    );
}
