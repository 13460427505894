import { RotActions } from "../../../types/case-files";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import { Alert, AlertDescription } from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import React, { Fragment, useState } from "react";
import { useUpdateRotLinkPermissionMutation } from "../../../api/tenant/case-file";
import Loading from "../../../components/global/loading";

function RotEditPermissions({ rot_link, onClose }: RotActions) {
    const schema = z.object({
        permission: z.enum(["full_access", "read_only_extended", "read_only"]),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            permission: rot_link.permission_set,
        },
    });
    const { watch } = form;
    const [updateRotLinkPermission] = useUpdateRotLinkPermissionMutation();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        await updateRotLinkPermission({
            id: rot_link.id,
            ...values,
        });

        onClose();

        setLoading(false);
    }

    return (
        <DialogContent>
            <Loading
                loaded={!loading}
                child={
                    <Fragment>
                        <DialogHeader>
                            <DialogTitle>Edit permissions</DialogTitle>
                        </DialogHeader>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(handleSubmit)}>
                                <FormField
                                    control={form.control}
                                    name="permission"
                                    render={({ field }) => (
                                        <FormItem className="py-2">
                                            <FormLabel>Permission</FormLabel>
                                            <Select
                                                onValueChange={field.onChange}
                                                defaultValue={field.value}
                                            >
                                                <FormControl className="bg-white">
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select" />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    <SelectItem value="full_access">
                                                        Unrestricted access
                                                    </SelectItem>
                                                    <SelectItem value="read_only_extended">
                                                        Read only plus
                                                    </SelectItem>
                                                    <SelectItem value="read_only">
                                                        Read only
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <Alert className="mb-8">
                                    <AlertDescription className="text-slate-950">
                                        {watch("permission") === "read_only"
                                            ? "The viewer can see this Recap / Charter Party, including its riders, addenda and uploads but not change anything. Viewer can participate in chat messaging."
                                            : watch("permission") === "read_only_extended"
                                              ? "The viewer can see this Recap / Charter Party, including its riders, addenda and uploads but not change anything. Viewer can participate in chat messaging, can upload files and participate in certain approval processes."
                                              : "The viewer will have full access to this Recap / Charter Party. The viewer can add, edit and remove a recap, standardized forms etc."}
                                    </AlertDescription>
                                </Alert>

                                <DialogFooter className="mt-4">
                                    <Button
                                        type="submit"
                                        disabled={
                                            !form.formState.isValid ||
                                            form.getValues("permission") === rot_link.permission_set
                                        }
                                    >
                                        Update
                                    </Button>
                                </DialogFooter>
                            </form>
                        </Form>
                    </Fragment>
                }
            />
        </DialogContent>
    );
}

export default RotEditPermissions;
