import React, { Fragment, useRef } from "react";
import { UploadIcon } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import Loading from "../../../../components/global/loading";
import { Typography } from "../../../../components/ui/typography";
import PDFPreview from "../../../../components/pdf-preview/pdf-preview";
import { Input } from "../../../../components/ui/input";
import { FILE_SIZE } from "../../../../config/attachments";

type UploadAndParseContentProps = {
    file: File | null;
    onFileChange: (file: File | null) => void;
    error: string | null;
    onErrorChange: (error: string | null) => void;
    isParseLoading: boolean;
};

function UploadAndParseContent({
    file,
    onFileChange,
    error,
    onErrorChange,
    isParseLoading,
}: UploadAndParseContentProps) {
    const uploadRef = useRef<HTMLInputElement | null>(null);

    function isFileValid(file: File): boolean {
        if (file.size > FILE_SIZE) {
            onErrorChange("File too big");
            return false;
        }

        if (file.type !== "application/pdf") {
            onErrorChange("Unsupported format");
            return false;
        }

        onErrorChange(null);
        return true;
    }

    function removeFile() {
        onFileChange(null);
        onErrorChange(null);
    }

    return (
        <Fragment>
            <Loading
                loaded={!isParseLoading}
                child={
                    <Fragment>
                        <div
                            className="flex items-center justify-center w-full relative"
                            id="dropzone"
                        >
                            {file ? (
                                <div className="flex flex-col">
                                    <div className="p-4 bg-gray-300 rounded">
                                        <PDFPreview file={file} />
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <Input
                                        id="file"
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => {
                                            const selectedFile = e.currentTarget.files?.[0] ?? null;
                                            if (selectedFile && isFileValid(selectedFile)) {
                                                onFileChange(selectedFile);
                                            } else {
                                                onFileChange(null);
                                            }
                                        }}
                                        className="w-full h-full absolute inset-0 z-50 opacity-0"
                                        ref={uploadRef}
                                    />
                                    <label
                                        htmlFor="file"
                                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                    >
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <UploadIcon className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                <span className="font-semibold">
                                                    Click to upload
                                                </span>{" "}
                                                or drag and drop
                                            </p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                                {" "}
                                                (MAX. 10mb)
                                            </p>
                                        </div>
                                    </label>
                                </Fragment>
                            )}
                        </div>
                        {file && (
                            <div className="w-full max-h-80">
                                <Typography
                                    text={`File to parse:`}
                                    style="muted"
                                    className="mb-1"
                                />
                                <div className="flex justify-between w-full items-center my-2">
                                    <div>
                                        <Typography text={file.name} style="p" />
                                        {error && <Typography text={error} style="warning" />}
                                    </div>
                                    <Button size="sm" variant="destructive" onClick={removeFile}>
                                        remove
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Fragment>
                }
            />
        </Fragment>
    );
}

export default UploadAndParseContent;
