import React, { useEffect, useReducer } from "react";
import {
    useLazyGetCaseFileApprovalsQuery,
    useLazyGetCaseFileMetaDataQuery,
    useLazyGetDocumentsQuery,
    useLazyGetRotLinksInCaseFileQuery,
    useLazyGetSupplyChainInfoQuery,
} from "../../api/tenant/case-file";
import permissionsSets from "../../../../permissions-sets.json";
import {
    CaseFileActions,
    CaseFileContextType,
    CaseFileModalAction,
    CaseFileModalState,
    CaseFilePages,
    CaseFilePermissionsInterface,
    CaseFilesProviderProps,
    CaseFilesProviderState,
    PermissionTypes,
} from "../../types/case-files";
import Loading from "../../components/global/loading";
import { toast } from "sonner";
import { tenantApi } from "../../api/tenantApi";
import { useDispatch } from "react-redux";

const CaseFileContext = React.createContext<CaseFileContextType>(null!);

function modalReducer(state: CaseFileModalState, action: CaseFileModalAction): CaseFileModalState {
    const { type, payload } = action;

    switch (type) {
        case CaseFileActions.SHOW_NEW_DOCUMENT:
            return {
                ...state,
                modalType: action.type,
                showModal: payload,
            };
    }
}

export function CaseFilesProvider({ children, case_file_id }: CaseFilesProviderProps) {
    const [state, setState] = useReducer(
            (state: CaseFilesProviderState, newState: Partial<CaseFilesProviderState>) => ({
                ...state,
                ...newState,
            }),
            {
                documents: [],
                page: "overview",
                rot_links: [],
                is_owner: true,
                loaded: false,
                case_file_id: case_file_id,
                original_cf_id: case_file_id,
                addenda: [],
            },
        ),
        [modalState, dispatchModalState] = useReducer(modalReducer, {
            showModal: false,
            modalType: undefined,
        });

    const [getRotLinks, { data: rotLinks }] = useLazyGetRotLinksInCaseFileQuery();
    const [getDocuments, { data: documents }] = useLazyGetDocumentsQuery();
    const [getSupplyChainInfo, { data: supplyChainInfo }] = useLazyGetSupplyChainInfoQuery();
    const [getApprovals, { data: approvals }] = useLazyGetCaseFileApprovalsQuery();
    const [getMetaData, { data: case_file_meta_data }] = useLazyGetCaseFileMetaDataQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setState({
            documents: documents,
            rot_links: rotLinks,
            supply_chain_info: supplyChainInfo,
            approvals: approvals,
            meta_data: case_file_meta_data,
        });

        if (
            case_file_meta_data?.status === "under_approval" ||
            case_file_meta_data?.status === "approval_rejected"
        ) {
            setState({
                permissions: getPermissionSet(case_file_meta_data.status),
            });
        }
    }, [rotLinks, documents, supplyChainInfo, approvals, case_file_meta_data]);

    async function init() {
        try {
            const case_file_meta_data = await getMetaData(case_file_id).unwrap();

            let permissions = undefined,
                is_owner = true;

            let caseFileIdUsedToFetch = state.case_file_id;
            let supply_chain_info = undefined;

            // reset case files cache when user is following case file
            // since the notification bell needs to be removed
            if (case_file_meta_data.is_following) {
                dispatch(tenantApi.util?.invalidateTags(["CaseFiles"]));
            }

            if (
                case_file_meta_data.permission_set &&
                case_file_meta_data.supply_chain_id_encrypted
            ) {
                permissions = getPermissionSet(case_file_meta_data.permission_set);
                is_owner = false;

                caseFileIdUsedToFetch = case_file_meta_data.original_cf_id;

                setState({
                    original_cf_id: case_file_id,
                });
            } else {
                setState({
                    original_cf_id: case_file_id,
                });
                try {
                    supply_chain_info = await getSupplyChainInfo(case_file_id).unwrap();
                } catch {
                    // Dummy
                }
            }

            if (
                case_file_meta_data.status === "under_approval" ||
                case_file_meta_data.status === "approval_rejected"
            ) {
                permissions = getPermissionSet(case_file_meta_data.status);
            }

            if (case_file_meta_data.status === "fully_approved") {
                if (is_owner) {
                    permissions = permissionsSets.fully_approved;
                } else if (case_file_meta_data.permission_set === "full_access") {
                    permissions = permissionsSets.fully_approved;
                } else {
                    permissions = permissionsSets.read_only;
                }
            }

            const rot_links = await getRotLinks(caseFileIdUsedToFetch).unwrap();
            const documents = await getDocuments(caseFileIdUsedToFetch).unwrap();
            const approvals = await getApprovals(caseFileIdUsedToFetch).unwrap();

            setState({
                documents: documents,
                rot_links: rot_links,
                permissions: permissions,
                is_owner: is_owner,
                loaded: true,
                supply_chain_info: supply_chain_info,
                meta_data: case_file_meta_data,
                case_file_id: caseFileIdUsedToFetch,
                approvals: approvals,
            });
        } catch (e) {
            toast.error("Something went wrong while fetching the document");
        }
    }

    function getPermissionSet(
        permission_set: PermissionTypes | "fully_approved" | "under_approval" | "approval_rejected",
    ): CaseFilePermissionsInterface {
        switch (permission_set) {
            case "full_access":
                return permissionsSets.full_access;
            case "read_only_extended":
                return permissionsSets.read_only_extended;
            case "fully_approved":
                return state.is_owner ? permissionsSets.fully_approved : permissionsSets.read_only;
            default:
                return permissionsSets.read_only;
        }
    }

    function setActivePage(page: CaseFilePages) {
        setState({ page: page });
    }

    const value: CaseFileContextType = {
        documents: state.documents,
        setActivePage: setActivePage,
        activePage: state.page,
        case_file_id: state.case_file_id,
        rot_links: state.rot_links,
        permissions: state.permissions ?? permissionsSets.full_access,
        supply_chain_info: state.supply_chain_info,
        meta_data: state.meta_data,
        is_owner: state.is_owner,
        modalState: modalState,
        dispatchModalState: dispatchModalState,
        loaded: state.loaded,
        original_cf_id: state.original_cf_id,
        approval:
            (state?.meta_data?.status === "fully_approved" ||
                state?.meta_data?.status === "under_approval" ||
                state.meta_data?.status === "approval_rejected") ??
            false,
        approvals: state.approvals,
        update_meta_date: (data) => setState({ meta_data: data }),
    };

    return (
        <Loading
            loaded={state.loaded}
            child={<CaseFileContext.Provider value={value}>{children}</CaseFileContext.Provider>}
        />
    );
}

export function useCaseFiles() {
    return React.useContext(CaseFileContext);
}
