import React, { Fragment, useEffect, useState } from "react";
import { Typography } from "../../../components/ui/typography";
import { Eye, EyeOff, Plus, Trash2 } from "lucide-react";
import { Panel } from "../../../components/ui/panel";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import FroalaEditor from "react-froala-wysiwyg";
import { froalaConfigBasic } from "../../../config/app";
import { Button } from "../../../components/ui/button";
import { RecapInterface } from "../../../types/recap";
import { toast } from "sonner";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../../components/ui/accordion";
import { createPortal } from "react-dom";
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { FormControl, FormItem, FormLabel } from "../../../components/ui/form";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import {
    createRecapClauseValue,
    deleteClauseValue,
    updateClauseValue,
} from "../../../lib/stores/RecapClauseValueSlice";
import { tenantApi } from "../../../api/tenantApi";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../components/ui/tooltip";
import VerticalDragAndDrop from "../../../components/drag-and-drop/vertical-drag-and-drop";
import { useUpdateClauseOrderStructureMutation } from "../../../api/tenant/recap";
import { scrollToOffsetInsideParent } from "../../../lib/utils";

type RecapClausesProps = {
    recap: RecapInterface;
    register: UseFormRegister<any>;
    setValue: UseFormSetValue<any>;
    getValue: UseFormGetValues<any>;
    isUserActive: boolean;
};

type RecapClause = {
    id: string;
    title: string;
    value: string;
    visible: boolean;
};

function RecapClauses({ recap, register, setValue, getValue, isUserActive }: RecapClausesProps) {
    const [content, setContent] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const recapClauseValues = useAppSelector((state) => state.recapClauseValues.entities);
    const [activeClause, setActiveClause] = useState<RecapClause>();
    const dispatch = useAppDispatch();
    const { case_file_id } = useCaseFiles();
    const [updateClauseOrderStructure] = useUpdateClauseOrderStructureMutation();
    const froalaConfig = {
        ...froalaConfigBasic,
        toolbarButtons: [
            "clearFormatting",
            "bold",
            "underline",
            "textColor",
            "backgroundColor",
            "fontSize",
            "|",
            "alignLeft",
            "alignCenter",
            "alignJustify",
            "formatOLSimple",
            "insertTable",
            "outdent",
            "indent",
            "|",
            "newMark",
            "deleteMark",
            "revertMark",
            "clauseCut",
            "|",
            "undo",
            "redo",
        ],
        heightMin: "200px",
        heightMax: "400px",
        placeholder: "new term",
        pasteDeniedAttrs: ["class", "id"],
        pasteAllowedStyleProps: ["list-style-type"],
        clauseCutCallback: handleClauseSnipping,
        events: {
            initialized: function () {
                setEditor(this);
            },
        },
    };
    const [editor, setEditor] = useState<any>();

    useEffect(() => {
        if (editor) {
            if (!isUserActive) {
                editor.edit.off();
            }
        }
    }, [editor]);

    async function handleSubmit() {
        await storeClause({
            title: title,
            value: content,
        });

        setContent("");
        setTitle("");
    }

    async function storeClause(clause: Pick<RecapClause, "title" | "value">) {
        try {
            const newClause = await dispatch(
                createRecapClauseValue({
                    recap_document_id: recap.id,
                    case_file_id: case_file_id,
                    title: clause.title,
                    value: clause.value,
                    visible: true,
                }),
            );

            setValue(`${newClause.payload.id}.title`, newClause.payload.title);
            setValue(`${newClause.payload.id}.value`, newClause.payload.value);

            dispatch(tenantApi.util?.invalidateTags(["RecapDocument"]));
        } catch (e) {
            toast.error("Something went wrong while cleaning up the recap");
        }
    }

    function getRecapClause(clauseId: string): RecapClause | undefined {
        return recapClauseValues.find((x) => x.id === clauseId);
    }

    function handleDelete(id: string) {
        dispatch(
            deleteClauseValue({
                id: id,
                recap_document_id: recap.id,
                case_file_id: case_file_id,
            }),
        );
        dispatch(tenantApi.util?.invalidateTags(["RecapDocument"]));

        setActiveClause(undefined);
    }

    async function handleVisibilityChange(id: string) {
        const clause = recapClauseValues.find((x) => x.id === id);

        if (!clause) {
            return;
        }

        await dispatch(
            updateClauseValue({
                recap_document_id: recap.id,
                id: id,
                case_file_id: case_file_id,
                title: clause.title,
                value: clause.value,
                visible: !clause.visible,
            }),
        );

        if (activeClause) {
            setActiveClause({
                ...activeClause,
                visible: !activeClause.visible,
            });
        }
    }

    function getRecapClauseList(): Array<RecapClause> {
        const clauseStructure = Object.values(recap.structure["recap-clauses"]?.fields ?? []) ?? [];

        return Object.values(recapClauseValues).sort(
            (a, b) => clauseStructure.indexOf(a.id) - clauseStructure.indexOf(b.id),
        );
    }

    async function handleReOrderClauseList(list: RecapClause[]) {
        await updateClauseOrderStructure({
            id: recap.id,
            case_file_id: case_file_id,
            recapClauseOrder: list.map((clause) => clause.id),
        });
    }

    async function handleClauseSnipping(contents: ChildNode[]) {
        // TODO move this to a generic function

        // gather all clause contents
        let htmlContent = "";
        contents.slice(1).forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as Element;
                htmlContent += element.outerHTML;
            }
        });

        const newClause: Pick<RecapClause, "title" | "value"> = {
            title: contents[0].textContent!,
            value: htmlContent ?? "",
        };

        await storeClause(newClause);
    }

    function handleScrollToClauseInPreview(clauseId: string) {
        const node = window.document.getElementById(`recap-preview-clause-${clauseId}`);
        const parent = window.document.getElementById(`recap-preview`);

        if (node && parent) {
            scrollToOffsetInsideParent(node, parent, 30);
        }
    }

    return (
        <Fragment>
            <div
                className="flex justify-between items-center sticky bg-background top-28"
                id="section-recap-clauses"
            >
                <Typography text={"Additional terms"} className="pb-1 border-b z-10 " style="h3" />
                <Dialog>
                    <DialogTrigger asChild disabled={!isUserActive}>
                        <Button variant="link" disabled={!isUserActive}>
                            <Plus className="w-4 h4" />
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="min-w-[700px]">
                        <DialogHeader>
                            <DialogTitle>New additional term</DialogTitle>
                            <DialogDescription>Add a term to the recap</DialogDescription>
                        </DialogHeader>
                        <FormItem>
                            <FormLabel>
                                <span>Title</span>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type="text"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </FormControl>
                        </FormItem>
                        <FormItem className="mt-4 [&_p]:my-[.5em]">
                            <FormControl>
                                <FroalaEditor
                                    model={content}
                                    config={froalaConfig}
                                    onModelChange={setContent}
                                />
                            </FormControl>
                        </FormItem>
                        <DialogFooter>
                            <Button type={"button"} onClick={handleSubmit} variant="ghost">
                                Save and new
                            </Button>
                            <DialogClose asChild>
                                <Button type={"button"} onClick={handleSubmit}>
                                    Save
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
            <Panel className="mb-8">
                {recapClauseValues.length === 0 ? (
                    <Typography text={"No additional terms yet"} style="h4" />
                ) : (
                    <Fragment>
                        <Accordion
                            type="single"
                            collapsible
                            className="w-full"
                            onValueChange={(clauseId) => {
                                if (clauseId.length) {
                                    const clause = getRecapClause(clauseId);
                                    setActiveClause(clause);
                                    handleScrollToClauseInPreview(clauseId);
                                } else {
                                    setActiveClause(undefined);
                                    setContent("");
                                    setTitle("");
                                }
                            }}
                        >
                            <VerticalDragAndDrop
                                onDragEnd={handleReOrderClauseList}
                                items={getRecapClauseList()}
                                listElement={(clause, index) => (
                                    <AccordionItem value={clause.id} key={index}>
                                        <AccordionTrigger
                                            className="text-left items-center gap-x-3"
                                            tabIndex={-1}
                                        >
                                            <div
                                                tabIndex={-1}
                                                className={`flex justify-between w-full ${!clause.visible ? "opacity-50" : ""}`}
                                            >
                                                {index + 1} - {clause.title}
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent id={`recap-term-${clause.id}`} />
                                    </AccordionItem>
                                )}
                                disabled={activeClause !== undefined || !isUserActive}
                            />
                        </Accordion>

                        {activeClause !== undefined &&
                            document.getElementById(`recap-term-${activeClause.id}`) &&
                            createPortal(
                                <div>
                                    <FormItem>
                                        <FormLabel>
                                            <span>Title</span>
                                        </FormLabel>
                                        <div className="flex justify-between w-full gap-x-2">
                                            <FormControl>
                                                <Input
                                                    {...register(activeClause.id + ".title")}
                                                    name={`${activeClause.id}.title`}
                                                    disabled={!isUserActive}
                                                />
                                            </FormControl>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant="outline"
                                                        size="icon"
                                                        className="!mt-0 "
                                                        onClick={() => {
                                                            handleVisibilityChange(activeClause.id);
                                                        }}
                                                        type="button"
                                                        disabled={!isUserActive}
                                                    >
                                                        {activeClause.visible ? (
                                                            <EyeOff className="h-4 w-4" />
                                                        ) : (
                                                            <Eye className="h-4 w-4" />
                                                        )}
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent side="bottom">
                                                    {activeClause.visible
                                                        ? "Mark as not-applicable"
                                                        : "Mark as applicable"}
                                                </TooltipContent>
                                            </Tooltip>
                                            <Dialog>
                                                <Tooltip>
                                                    <TooltipTrigger
                                                        asChild
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <DialogTrigger asChild>
                                                            <Button
                                                                variant="outline"
                                                                size="icon"
                                                                className="!mt-0 hover:bg-red-500 hover:stroke-white group "
                                                                type="button"
                                                                disabled={!isUserActive}
                                                            >
                                                                <Trash2 className="h-4 w-4 group-hover:stroke-white" />
                                                            </Button>
                                                        </DialogTrigger>
                                                    </TooltipTrigger>
                                                    <TooltipContent
                                                        className="z-50"
                                                        side="bottom"
                                                        align="end"
                                                    >
                                                        Remove term
                                                    </TooltipContent>
                                                </Tooltip>
                                                <DialogContent>
                                                    <DialogHeader>
                                                        <DialogTitle>Remove term</DialogTitle>
                                                    </DialogHeader>
                                                    <DialogFooter className="mt-4">
                                                        <DialogClose asChild>
                                                            <Button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleDelete(activeClause.id)
                                                                }
                                                            >
                                                                Remove
                                                            </Button>
                                                        </DialogClose>
                                                    </DialogFooter>
                                                </DialogContent>
                                            </Dialog>
                                        </div>
                                    </FormItem>
                                    <FormItem className="mt-4 [&_p]:my-[.5em]">
                                        <FormControl>
                                            <FroalaEditor
                                                {...register(activeClause.id + ".value")}
                                                config={froalaConfig}
                                                model={getValue(`${activeClause.id}.value`)}
                                                onModelChange={(value: string) => {
                                                    setValue(`${activeClause.id}.value`, value, {
                                                        shouldDirty: true,
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                    </FormItem>
                                </div>,
                                document.getElementById(`recap-term-${activeClause.id}`) ??
                                    document.body,
                            )}
                    </Fragment>
                )}
            </Panel>
        </Fragment>
    );
}

export default RecapClauses;
export { RecapClause };
