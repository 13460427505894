import React, { Fragment } from "react";
import { Outlet, useParams } from "react-router";
import { CaseFilesProvider, useCaseFiles } from "../lib/providers/CaseFilesProvider";
import Tabs from "../containers/case-files/tabs";
import Actions from "../containers/case-files/actions";
import Loading from "../components/global/loading";

export function CaseFileLayout() {
    const { case_file_id } = useParams<{ case_file_id: string; document_id: string }>();

    return (
        <div className="h-full w-full">
            {case_file_id && (
                <CaseFilesProvider case_file_id={case_file_id}>
                    <CaseFileComp />
                </CaseFilesProvider>
            )}
        </div>
    );
}

function CaseFileComp() {
    const { loaded } = useCaseFiles();

    return (
        <Loading
            loaded={loaded}
            child={
                <Fragment>
                    <div className="flex fixed left-12 right-0 z-30 top-12 flex-col justify-between space-y-2 bg-foreground p-4 border-b border-solid border-slate-200 h-auto">
                        <div className="flex items-center justify-between w-full">
                            <Tabs />
                            <Actions />
                        </div>
                    </div>
                    <div className="h-[calc(100vh-48px)] flex items-stretch">
                        <div className={"pt-16 w-full"}>
                            <Outlet />
                        </div>
                    </div>
                </Fragment>
            }
        />
    );
}
