import React, { Fragment } from "react";
import { DataTableProps } from "../../types/table";
import { DataTablePagination } from "./data-table-pagination";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { flexRender, Row } from "@tanstack/react-table";
import { Dialog } from "../ui/dialog";
import { ContextMenu, ContextMenuTrigger } from "../ui/context-menu";
import DataTableAdvancedDropDown from "./data-table-advanced-drop-down";
import { Button } from "../ui/button";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../ui/hover-card";
import { cn } from "../../lib/utils";

type DataGroup<TData> = {
    title?: string;
    filter: (row: Row<TData>) => boolean;
};

function DataTable<TData, TValue>({
    columns,
    contextMenuItems,
    table,
    onClick,
    renderOnEmpty,
    dataGroups,
}: DataTableProps<TData, TValue> & { dataGroups?: DataGroup<TData>[] }) {
    let definedGroups = dataGroups;
    if (!definedGroups) {
        definedGroups = [
            {
                filter: () => true,
            },
        ];
    }

    return (
        <div className="flex flex-col flex-1 justify-between">
            <div className="rounded-md">
                <Table>
                    {definedGroups?.map((dataGroup, index) => (
                        <Fragment key={index}>
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Fragment key={headerGroup.id}>
                                        {dataGroup.title ? (
                                            <TableRow className={"hover:bg-transparent"}>
                                                <TableHead
                                                    className={
                                                        "text-base font-bold uppercase text-slate-500"
                                                    }
                                                    colSpan={headerGroup.headers.length}
                                                >
                                                    {dataGroup.title}
                                                </TableHead>
                                            </TableRow>
                                        ) : (
                                            ""
                                        )}
                                        <TableRow>
                                            {headerGroup.headers.map((header) => {
                                                return (
                                                    <TableHead
                                                        key={header.id}
                                                        className={cn(
                                                            header.column.columnDef.meta
                                                                ?.headerClassName,
                                                            header.id === "actions" ? "w-12" : "",
                                                        )}
                                                    >
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                  header.column.columnDef.header,
                                                                  header.getContext(),
                                                              )}
                                                    </TableHead>
                                                );
                                            })}
                                        </TableRow>
                                    </Fragment>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.filter(dataGroup.filter).length ? (
                                    table
                                        .getRowModel()
                                        .rows.filter(dataGroup.filter)
                                        .map((row) => (
                                            <Dialog key={row.id}>
                                                <ContextMenu>
                                                    <ContextMenuTrigger asChild>
                                                        <TableRow
                                                            data-state={
                                                                row.getIsSelected() && "selected"
                                                            }
                                                            className="cursor-pointer"
                                                        >
                                                            {row.getVisibleCells().map((cell) => (
                                                                <TableCell
                                                                    key={cell.id}
                                                                    onClick={() => {
                                                                        if (
                                                                            cell.column.id !==
                                                                            "actions"
                                                                        ) {
                                                                            onClick(row.original);
                                                                        }
                                                                    }}
                                                                >
                                                                    {cell.column.id !==
                                                                    "actions" ? (
                                                                        <HoverCard>
                                                                            <HoverCardTrigger
                                                                                asChild
                                                                            >
                                                                                <Button
                                                                                    variant="none"
                                                                                    className={
                                                                                        "max-w-full h-full p-0 font-normal truncate block"
                                                                                    }
                                                                                >
                                                                                    {flexRender(
                                                                                        cell.column
                                                                                            .columnDef
                                                                                            .cell,
                                                                                        cell.getContext(),
                                                                                    )}
                                                                                </Button>
                                                                            </HoverCardTrigger>
                                                                            <HoverCardContent
                                                                                className="w-max max-w-96"
                                                                                align="end"
                                                                            >
                                                                                <div className="flex items-center justify-center pt-2 w-full whitespace-break-spaces">
                                                                                    {flexRender(
                                                                                        cell.column
                                                                                            .columnDef
                                                                                            .cell,
                                                                                        cell.getContext(),
                                                                                    )}
                                                                                </div>
                                                                            </HoverCardContent>
                                                                        </HoverCard>
                                                                    ) : (
                                                                        flexRender(
                                                                            cell.column.columnDef
                                                                                .cell,
                                                                            cell.getContext(),
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </ContextMenuTrigger>
                                                    {contextMenuItems && (
                                                        <DataTableAdvancedDropDown<TData>
                                                            items={contextMenuItems}
                                                            row={row.original}
                                                            type="context"
                                                        />
                                                    )}
                                                </ContextMenu>
                                            </Dialog>
                                        ))
                                ) : (
                                    <TableRow>
                                        {renderOnEmpty ? (
                                            <TableCell colSpan={columns.length} className="h-24">
                                                <div className="flex flex-1 justify-center">
                                                    {renderOnEmpty}
                                                </div>
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                colSpan={columns.length}
                                                className="h-24 text-center"
                                            >
                                                No results.
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Fragment>
                    ))}
                </Table>
            </div>
            <DataTablePagination table={table} />
        </div>
    );
}

export default DataTable;
