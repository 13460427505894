import React, { ReactNode } from "react";
import { AdminTopBar } from "../Central/Pages/Admin/TopBar";

export interface ComponentWithChildElement {
    children: ReactNode | React.JSX.Element;
}

export function AdminLayout({ children }: ComponentWithChildElement): React.JSX.Element {
    return (
        <div className="h-screen flex w-screen bg-white">
            <AdminTopBar />
            <div>{children}</div>
        </div>
    );
}
