import styled from "styled-components";
import { borderThin, color } from "../../../styles/mixins";

export const AdminPageStyle = styled.section`
    height: 100%;
    width: 100%;
    background-color: ${color("black")};
`;

export const AdminPageContentStyle = styled.section`
    border: ${borderThin("red")};
    border-radius: 4px;
    background-color: ${color("white")};
    padding: 2em;
`;
