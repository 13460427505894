import React, { Fragment, useRef, useState } from "react";
import { useUploadFilesMutation } from "../../api/tenant/attachments";
import { useCaseFiles } from "../../lib/providers/CaseFilesProvider";
import { toast } from "sonner";
import { FILE_SIZE, SUPPORTED_FORMATS } from "../../config/attachments";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../components/ui/dialog";
import { NewDocumentProps } from "../../types/case-files";
import Loading from "../../components/global/loading";
import { Input } from "../../components/ui/input";
import { Upload as UploadIcon } from "lucide-react";
import { Label } from "../../components/ui/label";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Typography } from "../../components/ui/typography";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";

function Upload({ onClose, isOpen }: NewDocumentProps) {
    const [files, setFiles] = useState<Array<File>>([]);
    const [error, setError] = useState<boolean>(false);
    const uploadRef = useRef<HTMLInputElement | null>(null);
    const [uploadFiles] = useUploadFilesMutation();
    const { case_file_id } = useCaseFiles();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);
        try {
            const formData = new FormData();

            if (files) {
                for (let i = 0; i < files.length; i++) {
                    formData.append("files[]", files[i]);
                }
            }

            await uploadFiles({
                formData: formData,
                case_file_id: case_file_id,
            }).unwrap();

            toast.success("successfully uploaded files");

            onClose();
        } catch (e) {
            toast.error("Something went wrong while uploading files");
        }

        setLoading(false);
    }

    function isFileValid(file: File): boolean {
        if (file.size >= FILE_SIZE && SUPPORTED_FORMATS.includes(file.type)) {
            if (!error) {
                setError(true);
            }
            return false;
        }

        return true;
    }

    function removeFile(file: File) {
        const index = Array.from(files ?? []).indexOf(file);
        setFiles(Array.from(files ?? []).filter((_, itemIndex) => itemIndex !== index));
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <DialogHeader>
                                <DialogTitle>Upload file</DialogTitle>
                                <DialogDescription>
                                    The following extensions are allowed: .jpg, .jpeg, .gif, .png,
                                    .doc, .docx, .pdf, .ppt, .pptx, .tif, .tiff, .txt,. xls, .xlsx,
                                    .zip, .rar
                                </DialogDescription>
                            </DialogHeader>
                            <div
                                className="flex items-center justify-center w-full relative"
                                id="dropzone"
                            >
                                <Input
                                    id="files"
                                    type="file"
                                    multiple
                                    accept=".jpg, .jpeg, .gif, .png, .doc, .docx, .pdf, .ppt, .pptx, .tif, .tiff, .txt,. xls, .xlsx, .zip, .rar"
                                    onChange={(e) =>
                                        setFiles((prevState) => [
                                            ...prevState,
                                            ...Array.from(e.currentTarget.files ?? []),
                                        ])
                                    }
                                    className="w-full h-full absolute inset-0 z-50 opacity-0"
                                    ref={uploadRef}
                                />
                                <label
                                    htmlFor="files"
                                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <UploadIcon className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">Click to upload</span>{" "}
                                            or drag and drop
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            {" "}
                                            (MAX. 10mb)
                                        </p>
                                    </div>
                                </label>
                            </div>
                            <div className="w-full items-center">
                                <Label htmlFor="files">Select file(s)</Label>
                            </div>
                            {files && (
                                <ScrollArea className="w-full max-h-80 pr-4">
                                    <Typography
                                        text={`${files.length} files ready to be uploaded to Recap C/P`}
                                        style="muted"
                                        className="mb-6"
                                    />
                                    {Array.from(files).map((file, index) => (
                                        <Fragment key={index}>
                                            <div className="flex justify-between w-full items-center my-2">
                                                <div>
                                                    <Typography text={file.name} style="p" />
                                                    {!isFileValid(file) && (
                                                        <Typography
                                                            text="File to big or unsupported format"
                                                            style="warning"
                                                        />
                                                    )}
                                                </div>
                                                <Button size="sm" onClick={() => removeFile(file)}>
                                                    remove
                                                </Button>
                                            </div>
                                            {index < files.length - 1 && <Separator />}
                                        </Fragment>
                                    ))}
                                </ScrollArea>
                            )}
                            <DialogFooter className="mt-4">
                                <Button type="submit" disabled={error} onClick={handleSubmit}>
                                    Upload
                                </Button>
                            </DialogFooter>
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

export default Upload;
