import TypedFroalaEditor from "froala-editor-typings";
import * as Sentry from "@sentry/react";
import Command from ".";
import { TenantUser } from "../../../types/auth";
import { checkAdditionSpan, isLineNumber, retrieveEditorContext } from "../Utility/inspectDOM";
import { handleDeletionNodeCreation } from "../Utility/modifyDOM";
import { handleSelectionDeletion } from "../Utility/selectionDeletion";
import {
    saveEditorSelectionAndAddEditorStateToMemento,
    setCursorAfterElement,
    setCursorWithElement,
} from "../Utility/base";
import Memento from "../Memento";

class DeleteCommand implements Command {
    private editor: TypedFroalaEditor.FroalaEditor;
    private event: KeyboardEvent;
    private user: TenantUser;
    private previousState: Memento;
    private spanId: string | null;

    constructor(editor: TypedFroalaEditor.FroalaEditor, event: KeyboardEvent, user: TenantUser) {
        this.editor = editor;
        this.event = event;
        this.user = user;
        this.previousState = this.saveState();
        this.spanId = null;
    }

    execute(): void {
        this.event.preventDefault();
        this.event.stopPropagation();
        this.insertDeleteDeletion();
    }

    undo(): void {
        this.restoreState(this.previousState);
    }

    saveState(): Memento {
        return saveEditorSelectionAndAddEditorStateToMemento(this.editor);
    }

    restoreState(memento: Memento): void {
        this.editor.html.set(memento.getState()); // Restore the HTML content
    }

    get previous(): Memento {
        return this.previousState;
    }

    get id(): string | null {
        return this.spanId;
    }

    private insertDeleteDeletion(): void {
        const selection: Selection = this.editor.selection.get();

        // A collapsed selection is synonomous to a regular cursor
        if (selection.isCollapsed) {
            const anchor = selection.anchorNode;
            const anchorOffset = selection.anchorOffset;

            // Do nothing for safety
            if (!anchor) {
                return;
            }

            // Extract context details from Selection object
            const { anchorNode, charIndexed } = retrieveEditorContext(anchor, anchorOffset);

            if (isLineNumber(anchorNode)) {
                return;
            }

            if (charIndexed) {
                if (anchorOffset === 0) {
                    // left side
                    this.spanId = handleDeletionNodeCreation(
                        anchorNode,
                        anchorOffset,
                        "left",
                        "delete",
                        this.user!,
                    );
                } else if (anchorOffset === anchorNode.textContent?.length) {
                    // right side
                    this.spanId = handleDeletionNodeCreation(
                        anchorNode,
                        anchorOffset,
                        "right",
                        "delete",
                        this.user!,
                    );
                } else {
                    // inside
                    this.spanId = handleDeletionNodeCreation(
                        anchorNode,
                        anchorOffset,
                        "inside",
                        "delete",
                        this.user!,
                    );
                }
            } else {
                // at the end
                this.spanId = handleDeletionNodeCreation(
                    anchorNode,
                    anchorOffset,
                    "right",
                    "delete",
                    this.user!,
                );
            }
        } else {
            const { lastNode, lastOffset } = handleSelectionDeletion(selection, this.user!);
            if (lastNode) {
                const checkAddition = checkAdditionSpan(lastNode);
                if (checkAddition) {
                    setCursorWithElement(checkAddition, lastOffset - 1);
                } else {
                    setCursorAfterElement(lastNode);
                }
            } else {
                // TODO: what if no last node?
                Sentry.captureMessage("Selection + Delete failed to return lastNode", "warning");
            }
        }
    }
}

export default DeleteCommand;
