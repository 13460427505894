import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { format } from "date-fns";
import { TemplateItem } from "../types/Central";

export interface CentralTagInterface {
    name: string;
}

const columns: ColumnDef<TemplateItem>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Name",
        },
    },
    {
        accessorKey: "description",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Description" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Description",
        },
    },
    {
        accessorKey: "folders",
        header: "Folders",
        meta: {
            title: "Folders",
        },
        cell: ({ row }) => {
            return (
                Object.values(row.getValue("folders") as object)
                    .map((tag) => tag.name)
                    .join(", ") ?? ""
            );
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Created At (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Created At (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "updated_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Updated At (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Updated AT (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("updated_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "active",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Active" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Active",
        },
    },
];

export { columns };
