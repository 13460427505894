import React from "react";
import { createRecapTemplates } from "../../../Admin/Recap";
import { NewRecapTemplateInterface } from "../index.interface";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
    ChangeTemplateContainer,
    ChangeTemplateForm,
    ChangeTemplateFormContainer,
    ChangeTemplateFormCreationField,
} from "../../Dashboard/Style";
import { Formik } from "formik";
import {
    Bar,
    CancelButton,
    FormButtonGroup,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
} from "../../../Form/FormField/style";
import { adminPrefix } from "../../../../config/central";

export interface NewRecapTemplatesInterface {
    onClose: () => void;
}

export function NewRecapTemplates({ onClose }: NewRecapTemplatesInterface) {
    const initialValues: NewRecapTemplateInterface = {
            name: "",
            data: [],
        },
        validationSchema = Yup.object().shape({
            name: Yup.string().required(),
            data: Yup.array().required(),
        }),
        navigate = useNavigate();

    function handleSubmit(values: NewRecapTemplateInterface) {
        createRecapTemplates(values).then((r) =>
            navigate(`/${adminPrefix}/recap-templates/${r.data.id}`),
        );
    }

    return (
        <ChangeTemplateContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({ errors, touched, resetForm }) => (
                    <ChangeTemplateForm>
                        <ChangeTemplateFormContainer>
                            <ChangeTemplateFormCreationField>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        name="name"
                                        autoCorrect="off"
                                        autoComplete="name"
                                        valid={touched.name && !errors.name}
                                        error={touched.name && errors.name}
                                    />
                                    <Bar />
                                    <Label htmlFor="name">Name</Label>
                                    {errors.name && touched.name && (
                                        <StyledInlineErrorMessage>
                                            {errors.name}
                                        </StyledInlineErrorMessage>
                                    )}
                                </InputGroup>
                                <FormButtonGroup>
                                    <CancelButton
                                        type="button"
                                        onClick={() => {
                                            resetForm();
                                            onClose();
                                        }}
                                    >
                                        Cancel
                                    </CancelButton>
                                    <SubmitButton type="submit">Create</SubmitButton>
                                </FormButtonGroup>
                            </ChangeTemplateFormCreationField>
                        </ChangeTemplateFormContainer>
                    </ChangeTemplateForm>
                )}
            </Formik>
        </ChangeTemplateContainer>
    );
}
