import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import React, { Fragment, useEffect, useState } from "react";
import { useCaseFiles } from "../../lib/providers/CaseFilesProvider";
import {
    CaseFileDocumentIndex,
    CaseFileMetaData,
    DocumentLoadType,
    ExportableListType,
} from "../../types/case-files";
import { getFileExtension } from "./attachements/attachments-preview";
import {
    ShareRequest,
    ShareRequestDocument,
    useCirculateCaseFileMutation,
    useMultiExportMutation,
} from "../../api/tenant/case-file";
import { useLazyDownloadBlobQuery } from "../../api/cookieApi";
import { saveAs } from "file-saver";
import { toast } from "sonner";
import Loading from "../../components/global/loading";
import {
    ChevronLeft,
    ChevronRight,
    Download,
    GripVertical,
    Plus,
    Send,
    Trash2,
    Upload,
} from "lucide-react";
import { DocumentExportIcon, formatDocumentType } from "../../lib/utils";
import VerticalDragAndDrop from "../../components/drag-and-drop/vertical-drag-and-drop";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { Label } from "../../components/ui/label";
import { Switch } from "../../components/ui/switch";
import { Checkbox } from "../../components/ui/checkbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../components/ui/tooltip";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../lib/providers/AuthProvider";
import { determineTenantName } from "../../lib/helpers/tenant";

type MultiExportableListType = {
    index: number;
    include: boolean;
} & ExportableListType;

const schema = z.object({
    note: z.string().optional(),
    inline: z.object({
        recap: z.boolean(),
        riders: z.boolean(),
    }),
    documents: z
        .array(
            z.object({
                id: z.string(),
                name: z.string(),
                type: z.string(),
                extension: z.string(),
                index: z.number(),
                include: z.boolean(),
            }),
        )
        .min(1, "Include at least 1 document"),
    emails: z
        .array(
            z.object({
                email: z.string().email(),
            }),
        )
        .min(1, "Enter at least one email"),
    coverPage: z.boolean(),
    tableOfContents: z.boolean(),
    colorScheme: z.string(),
    finalWatermark: z.string().optional(),
    coverStyle: z.string(),
    logos: z.object({
        broker: z.string().optional(),
        owner: z.string().optional(),
        charterer: z.string().optional(),
    }),
});

function getExportableList(
    documents: Array<CaseFileDocumentIndex>,
    hasAddenda?: boolean,
    hasRiders?: boolean,
): Array<MultiExportableListType> {
    const exportableList: Array<MultiExportableListType> = documents.map((document) => {
        return {
            id: document.id,
            name: document.document_name,
            type: document.template_type as DocumentLoadType,
            extension:
                document.template_type === "file_upload"
                    ? getFileExtension(document.document_name)
                    : "none",
            index:
                document.template_type === "recap"
                    ? 0
                    : document.template_type === "standard_form"
                      ? 1
                      : 5,
            include: true,
        };
    });

    if (hasAddenda) {
        exportableList.push({
            id: "addenda",
            type: "addenda",
            name: "Addenda",
            extension: "none",
            index: 4,
            include: true,
        });
    }

    if (hasRiders) {
        exportableList.push({
            id: "rider-terms",
            type: "riders",
            name: "Rider terms",
            extension: "none",
            index: 3,
            include: true,
        });
    }

    return exportableList.sort((a, b) => a.index - b.index);
}

function getDocumentName(item: MultiExportableListType, meta_data?: CaseFileMetaData): string {
    return (item?.name !== "" ? item?.name : meta_data?.document_name) ?? "Unnamed";
}

function friendlyStatus(status: string): string {
    switch (status) {
        case "waiting":
            return "Preparing export";
        case "start":
            return "Inspecting pages";
        case "running":
            return "Generating pages";
        case "ready":
            return "Preparing downloadable files...";
        case "failed":
            return "Something went wrong";

        default:
            return status;
    }
}

const dataGroups = [
    {
        title: "Documents",
        filter: (row: MultiExportableListType) => {
            return !["q88", "file_upload"].includes(row.type);
        },
    },
    {
        title: "Attachments",
        filter: (row: MultiExportableListType) => ["q88", "file_upload"].includes(row.type),
    },
];

type StatusMessage = {
    caseFileId: string;
    fileName: string;
};

function MultiExport() {
    const coverStyles = [
        {
            label: "Ocean Recap (Color / White)",
            value: "ocean-recap-color-white",
        },
        {
            label: "Ocean Recap (White / Blue)",
            value: "ocean-recap-white-blue",
        },
        {
            label: "Ocean Recap (Grayscale / White)",
            value: "ocean-recap-grayscale-white",
        },
    ];

    const colorSchemes = [
        {
            label: "Bright red and green",
            value: "ocean-recap",
        },
        {
            label: "Subtle red and blue",
            value: "traditional",
        },
        {
            label: "Printer optimized, black text only",
            value: "black",
        },
    ];

    const finalWatermarks = [
        {
            label: "- none -",
            value: " ",
        },
        {
            label: "ORIGINAL",
            value: "original",
        },
    ];

    const { documents, meta_data, case_file_id, rot_links } = useCaseFiles();
    const [exportableLists, setExportableLists] = useState<Array<Array<MultiExportableListType>>>(
        [],
    );
    const [multiExport, { isLoading }] = useMultiExportMutation();
    const [circulateCaseFile, { isLoading: isCirculateLoading }] = useCirculateCaseFileMutation();
    const [downloadBlob] = useLazyDownloadBlobQuery();
    const [open, setOpen] = useState<boolean>(false);
    const [download, setDownload] = useState<{ fileName: string; status: string } | undefined>();
    const [usePreview, setUsePreview] = useState(false);

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            emails: getInitialEmails(),
            documents: [],
            note: "",
            inline: {
                recap: true,
                riders: false,
            },
            tableOfContents: true,
            coverPage: true,
            finalWatermark: " ",
            coverStyle: coverStyles[0].value,
            colorScheme: colorSchemes[0].value,
            logos: {
                broker: undefined,
                owner: undefined,
                charterer: undefined,
            },
        },
        mode: "onSubmit",
    });
    const {
        control,
        register,
        formState: { errors },
        watch,
    } = form;
    const {
        fields: emailFields,
        remove,
        append,
    } = useFieldArray({
        control,
        name: "emails",
    });

    const steps = ["documents", "styling", "recipients"];
    const [currentStep, setCurrentStep] = useState<string>(steps[0]);
    const logos = ["broker", "owner", "charterer"];

    const echo = window.Echo;
    const { user } = useAuth().state;

    if (user && echo) {
        echo.join(`${meta_data?.sender_id ?? user.tenant_id}.exports`)
            .stopListening(".start")
            .listen(".start", (message: StatusMessage) => {
                if (download?.fileName === message.fileName) {
                    setDownload({ fileName: download.fileName, status: "start" });
                }
            })
            .stopListening(".running")
            .listen(".running", (message: StatusMessage) => {
                if (download?.fileName === message.fileName) {
                    setDownload({ fileName: download.fileName, status: "running" });
                }
            })
            .stopListening(".failed")
            .listen(".failed", (message: StatusMessage) => {
                if (download?.fileName === message.fileName) {
                    setDownload({ fileName: download.fileName, status: "failed" });
                }
            })
            .stopListening(".ready")
            .listen(".ready", (message: StatusMessage) => {
                if (download?.fileName === message.fileName) {
                    if (["ready", "download"].includes(download.status)) {
                        return;
                    }
                    setDownload({ fileName: download.fileName, status: "ready" });

                    setTimeout(() => {
                        setTimeout(async () => {
                            if (usePreview) {
                                openDownload(download.fileName);
                            } else {
                                await saveDownload(download.fileName);
                            }
                        }, 500);

                        setDownload({ fileName: download.fileName, status: "download" });
                    }, 500);
                }
            });
    }

    useEffect(() => {
        form.setValue("emails", getInitialEmails());
    }, [rot_links]);

    useEffect(() => {
        if (!open) {
            setCurrentStep(steps[0]);
            setDownload(undefined);
            form.reset();
        }
    }, [open]);

    function nextStep() {
        if (stepIndex() + 1 > steps.length - 1) {
            return;
        }
        setCurrentStep(steps[stepIndex() + 1]);
    }

    function stepIndex() {
        return steps.indexOf(currentStep);
    }

    function prevStep() {
        if (stepIndex() === 0) {
            return;
        }
        setCurrentStep(steps[stepIndex() - 1]);
    }

    function getInitialEmails(): Array<{ email: string }> {
        if (rot_links?.length) {
            const usersToNotifyEmails = rot_links
                .flatMap((x) => x.additional_data.users_to_notify)
                .map((x) => ({ email: x.email }));

            return usersToNotifyEmails.length ? usersToNotifyEmails : [{ email: "" }];
        } else {
            return [{ email: "" }];
        }
    }

    function toggleExportable(listIndex: number, index: number) {
        const tempList = exportableLists[listIndex];
        if (!tempList[index]) {
            return;
        }
        tempList[index].include = !tempList[index].include;

        if (tempList[index].type === "recap") {
            form.setValue("inline.recap", !form.getValues("inline.recap"));
        }

        updateExportableLists(listIndex, [...tempList]);
    }

    function updateExportableLists(
        listIndex: number,
        exportableList: Array<MultiExportableListType>,
    ) {
        const tempExportableLists = [...exportableLists];
        tempExportableLists[listIndex] = [...exportableList];
        setExportableLists(tempExportableLists);
    }

    function mergeDocumentsToForm() {
        const data: Array<ShareRequestDocument> = [];
        exportableLists.forEach((list) =>
            list.forEach((document) => {
                data.push({
                    id: document.id,
                    name: document.name,
                    include: document.include,
                    index: document.index,
                    type: document.type,
                    extension: document.extension,
                });
            }),
        );
        form.setValue("documents", data);
    }

    function loadExportableLists() {
        const list = getExportableList(
            documents,
            meta_data?.has_addenda,
            meta_data?.has_rider_terms,
        );
        const lists: Array<Array<MultiExportableListType>> = [];

        dataGroups.forEach((dataGroup, index) => {
            lists[index] = list.filter(dataGroup.filter);
        });
        setExportableLists(lists);
    }

    useEffect(() => {
        loadExportableLists();
    }, [documents, meta_data, open]);

    useEffect(() => {
        mergeDocumentsToForm();
    }, [exportableLists]);

    useEffect(() => {
        if (!open) {
            setCurrentStep(steps[0]);
            loadExportableLists();
        }
    }, [open]);

    async function handleExport(event: React.MouseEvent<HTMLElement>) {
        try {
            const formData = form.getValues();
            const data: ShareRequest = {
                ...formData,
                emails: formData.emails.map((row) => row.email),
            };

            const { fileName } = await multiExport({
                case_file_id: case_file_id,
                data,
            }).unwrap();

            if (event.shiftKey) {
                setUsePreview(true);
            } else {
                setUsePreview(false);
            }
            setDownload({
                fileName,
                status: "waiting",
            });
        } catch (e) {
            toast.error("Something went wrong while exporting this Recap C/P");
        }
    }

    async function saveDownload(fileName: string) {
        const url = "/api/tenant/v1/documents/download?file=" + fileName;
        const blob = await downloadBlob(`${url}&caseFile=${case_file_id}`).unwrap();
        const extension = fileName.split(".").pop();

        saveAs(
            blob,
            `${meta_data?.document_name ?? ""} - OR ID ${meta_data?.reference}.${extension}`,
        );
    }

    function openDownload(fileName: string) {
        const url =
            "/" + determineTenantName() + "/api/tenant/v1/documents/download/file/" + fileName;
        window.open(`${url}?caseFile=${case_file_id}`, "_blank");
    }

    async function handleSend() {
        const formData = form.getValues();

        const data: ShareRequest = {
            ...formData,
            emails: formData.emails.map((row) => row.email),
        };
        try {
            await circulateCaseFile({
                data,
                case_file_id: case_file_id,
            }).unwrap();

            toast.message("Successfully circulated this Recap C/P");
            form.reset();
            setOpen(false);
        } catch (e) {
            toast.error("Something went wrong while circulating this Recap C/P");
        }
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button variant="outline" className="mr-4" onClick={() => setOpen(true)}>
                        <Send className={"w-4"} />
                    </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom">Circulate</TooltipContent>
            </Tooltip>
            <DialogContent
                className={"overflow-hidden"}
                onOpenAutoFocus={(e) => e.preventDefault()}
            >
                {download ? (
                    download.status === "download" ? (
                        <div>
                            <div className={"text-lg mb-4"}>Downloading file</div>
                            <p className={"mb-6"}>
                                Currently downloading:
                                <br /> &quot;<em>{download.fileName}</em>&quot;
                            </p>
                            <div className={"p-4 flex justify-center gap-2"}>
                                <Button
                                    variant={"ghost"}
                                    onClick={async (event) => {
                                        if (event.shiftKey) {
                                            openDownload(download.fileName);
                                        } else {
                                            await saveDownload(download.fileName);
                                        }
                                    }}
                                >
                                    Download again
                                </Button>
                                <Button variant={"default"} onClick={() => setDownload(undefined)}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className={"text-lg mb-4"}>Generating file</div>
                            <div className={"p-4 text-center"}>
                                <div className={"rounded-xl bg-slate-200 mb-2"}>
                                    <div
                                        className={
                                            "transition-all duration-100 rounded-xl h-2 " +
                                            (download.status === "waiting"
                                                ? "w-[20px] bg-primary"
                                                : "") +
                                            (download.status === "running"
                                                ? "w-2/3 bg-primary"
                                                : "") +
                                            (download.status === "failed"
                                                ? "w-full bg-destructive"
                                                : "") +
                                            (download.status === "ready"
                                                ? "w-full bg-primary"
                                                : "") +
                                            (download.status === "start" ? "w-1/3 bg-primary" : "")
                                        }
                                    ></div>
                                </div>
                                {friendlyStatus(download.status)}
                            </div>
                        </div>
                    )
                ) : (
                    <Loading
                        loaded={!isLoading && !isCirculateLoading && !download}
                        child={
                            <Fragment>
                                <DialogHeader>
                                    <DialogTitle>Circulate</DialogTitle>
                                </DialogHeader>
                                <div>
                                    <Form {...form}>
                                        <form>
                                            <Tabs
                                                value={currentStep}
                                                onValueChange={(value) => setCurrentStep(value)}
                                            >
                                                <TabsList
                                                    className={
                                                        "mb-6 h-15 py-3 pb-10 bg-slate-200 bg-opacity-50 items-start justify-evenly w-full"
                                                    }
                                                >
                                                    <TabsTrigger
                                                        className={
                                                            "flex relative flex-col gap-2 data-[state=active]:font-medium data-[state=active]:shadow-none data-[state=active]:bg-transparent group"
                                                        }
                                                        value={"documents"}
                                                    >
                                                        <div
                                                            className={
                                                                "bg-slate-50 text-slate-700 group-data-[state=active]:bg-slate-500 text-center leading-5 text-xs rounded-full w-5 h-5 group-data-[state=active]:text-slate-50"
                                                            }
                                                        >
                                                            1
                                                        </div>
                                                        <div className={"absolute top-8"}>
                                                            Documents
                                                        </div>
                                                    </TabsTrigger>
                                                    <div
                                                        className={"bg-slate-300 w-10 h-[1px] mt-3"}
                                                    ></div>
                                                    <TabsTrigger
                                                        className={
                                                            "flex relative flex-col gap-2 data-[state=active]:font-medium data-[state=active]:shadow-none data-[state=active]:bg-transparent group"
                                                        }
                                                        value={"styling"}
                                                    >
                                                        <div
                                                            className={
                                                                "bg-slate-50 text-slate-700 group-data-[state=active]:bg-slate-500 text-center leading-5 text-xs rounded-full w-5 h-5 group-data-[state=active]:text-slate-50"
                                                            }
                                                        >
                                                            2
                                                        </div>
                                                        <div className={"absolute top-8"}>
                                                            Styling
                                                        </div>
                                                    </TabsTrigger>
                                                    <div
                                                        className={"bg-slate-300 w-10 h-[1px] mt-3"}
                                                    ></div>
                                                    <TabsTrigger
                                                        className={
                                                            "flex relative flex-col gap-2 data-[state=active]:font-medium data-[state=active]:shadow-none data-[state=active]:bg-transparent group"
                                                        }
                                                        value={"recipients"}
                                                    >
                                                        <div
                                                            className={
                                                                "bg-slate-50 text-slate-700 group-data-[state=active]:bg-slate-500 text-center leading-5 text-xs rounded-full w-5 h-5 group-data-[state=active]:text-slate-50"
                                                            }
                                                        >
                                                            3
                                                        </div>
                                                        <div className={"absolute top-8"}>
                                                            Recipients
                                                        </div>
                                                    </TabsTrigger>
                                                </TabsList>
                                                <div
                                                    className={
                                                        "overflow-y-auto overflow-x-hidden px-[2px] -mx-[2px] h-[calc(80vh-16rem)]"
                                                    }
                                                >
                                                    <TabsContent value="documents">
                                                        <div className={"flex flex-col gap-2"}>
                                                            <DialogDescription className={"mb-4"}>
                                                                Select which documents to export,
                                                                you can drag and drop to order the
                                                                documents
                                                            </DialogDescription>
                                                            {exportableLists &&
                                                                dataGroups.map(
                                                                    (dataGroup, groupIndex) => (
                                                                        <Fragment
                                                                            key={
                                                                                "data-group-" +
                                                                                groupIndex
                                                                            }
                                                                        >
                                                                            {exportableLists[
                                                                                groupIndex
                                                                            ] &&
                                                                            exportableLists[
                                                                                groupIndex
                                                                            ].length ? (
                                                                                <div>
                                                                                    {
                                                                                        dataGroup.title
                                                                                    }
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}

                                                                            <div
                                                                                className={
                                                                                    "border-t flex flex-col"
                                                                                }
                                                                            >
                                                                                {exportableLists[
                                                                                    groupIndex
                                                                                ] && (
                                                                                    <VerticalDragAndDrop
                                                                                        onDragEnd={(
                                                                                            list,
                                                                                        ) =>
                                                                                            updateExportableLists(
                                                                                                groupIndex,
                                                                                                list,
                                                                                            )
                                                                                        }
                                                                                        items={
                                                                                            exportableLists[
                                                                                                groupIndex
                                                                                            ]
                                                                                        }
                                                                                        listElement={(
                                                                                            item,
                                                                                            index,
                                                                                        ) => {
                                                                                            const selected =
                                                                                                exportableLists[
                                                                                                    groupIndex
                                                                                                ].find(
                                                                                                    (
                                                                                                        x,
                                                                                                    ) =>
                                                                                                        x.id ===
                                                                                                        item.id,
                                                                                                )
                                                                                                    ?.include ??
                                                                                                false;
                                                                                            return (
                                                                                                <div
                                                                                                    className={
                                                                                                        "flex px-4 border-b bg-white items-center justify-between outline-none" +
                                                                                                        (index <
                                                                                                        exportableLists[
                                                                                                            groupIndex
                                                                                                        ]
                                                                                                            .length -
                                                                                                            1
                                                                                                            ? " border-b"
                                                                                                            : "")
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        className="flex py-3 gap-4 flex-grow items-center"
                                                                                                        onClick={() => {
                                                                                                            toggleExportable(
                                                                                                                groupIndex,
                                                                                                                index,
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <GripVertical className="shrink-0 w-4 opacity-50 cursor-move" />
                                                                                                        <div
                                                                                                            className={`flex items-center bg-slate-500 shrink-0 w-8 h-8 justify-center rounded-full text-white ${!selected ? "bg-opacity-25" : ""}`}
                                                                                                        >
                                                                                                            <DocumentExportIcon
                                                                                                                item={
                                                                                                                    item
                                                                                                                }
                                                                                                                size={
                                                                                                                    14
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="grid gap-1">
                                                                                                            <p className="text-sm font-medium leading-none truncate text-ellipsis pr-4">
                                                                                                                {getDocumentName(
                                                                                                                    item,
                                                                                                                    meta_data,
                                                                                                                )}
                                                                                                            </p>
                                                                                                            <p className="text-sm text-muted-foreground">
                                                                                                                {formatDocumentType(
                                                                                                                    item.type,
                                                                                                                )}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <Switch
                                                                                                        onClick={() => {
                                                                                                            toggleExportable(
                                                                                                                groupIndex,
                                                                                                                index,
                                                                                                            );
                                                                                                        }}
                                                                                                        checked={
                                                                                                            selected
                                                                                                        }
                                                                                                        className="opacity-100"
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </Fragment>
                                                                    ),
                                                                )}
                                                        </div>
                                                    </TabsContent>
                                                    <TabsContent value={"styling"}>
                                                        <div className={"flex flex-col gap-4"}>
                                                            <DialogDescription className={"mb-4"}>
                                                                Configure the look and feel of the
                                                                exported document bundle
                                                            </DialogDescription>
                                                            <div className="flex space-y-2 flex-col">
                                                                <Label>C/P Amendment colors</Label>
                                                                <FormField
                                                                    name="colorScheme"
                                                                    control={form.control}
                                                                    render={({ field }) => (
                                                                        <FormItem>
                                                                            <Select
                                                                                onValueChange={
                                                                                    field.onChange
                                                                                }
                                                                                defaultValue={
                                                                                    field.value
                                                                                }
                                                                            >
                                                                                <FormControl>
                                                                                    <SelectTrigger>
                                                                                        <SelectValue />
                                                                                    </SelectTrigger>
                                                                                </FormControl>
                                                                                <SelectContent>
                                                                                    {colorSchemes.map(
                                                                                        (
                                                                                            colorScheme,
                                                                                        ) => (
                                                                                            <SelectItem
                                                                                                key={
                                                                                                    colorScheme.value
                                                                                                }
                                                                                                value={
                                                                                                    colorScheme.value
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    colorScheme.label
                                                                                                }
                                                                                            </SelectItem>
                                                                                        ),
                                                                                    )}
                                                                                </SelectContent>
                                                                            </Select>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="flex space-y-2 flex-col">
                                                                <Label>Final watermark</Label>
                                                                <FormField
                                                                    name="finalWatermark"
                                                                    control={form.control}
                                                                    render={({ field }) => (
                                                                        <FormItem>
                                                                            <Select
                                                                                onValueChange={
                                                                                    field.onChange
                                                                                }
                                                                                defaultValue={
                                                                                    field.value
                                                                                }
                                                                            >
                                                                                <FormControl>
                                                                                    <SelectTrigger>
                                                                                        <SelectValue />
                                                                                    </SelectTrigger>
                                                                                </FormControl>
                                                                                <SelectContent>
                                                                                    {finalWatermarks.map(
                                                                                        (
                                                                                            finalWatermark,
                                                                                        ) => (
                                                                                            <SelectItem
                                                                                                key={
                                                                                                    finalWatermark.value
                                                                                                }
                                                                                                value={
                                                                                                    finalWatermark.value
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    finalWatermark.label
                                                                                                }
                                                                                            </SelectItem>
                                                                                        ),
                                                                                    )}
                                                                                </SelectContent>
                                                                            </Select>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    "mb-2 flex gap-2 flex-col"
                                                                }
                                                            >
                                                                <Label>Options</Label>
                                                                <FormField
                                                                    name="coverPage"
                                                                    control={form.control}
                                                                    render={({ field }) => (
                                                                        <FormItem
                                                                            className={
                                                                                "flex space-y-0 gap-2"
                                                                            }
                                                                        >
                                                                            <FormControl>
                                                                                <Checkbox
                                                                                    checked={
                                                                                        field.value
                                                                                    }
                                                                                    onCheckedChange={
                                                                                        field.onChange
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                            <FormLabel
                                                                                className={
                                                                                    "font-normal"
                                                                                }
                                                                            >
                                                                                Cover page
                                                                            </FormLabel>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                                <FormField
                                                                    name="tableOfContents"
                                                                    control={form.control}
                                                                    render={({ field }) => (
                                                                        <FormItem
                                                                            className={
                                                                                "flex space-y-0 gap-2"
                                                                            }
                                                                        >
                                                                            <FormControl>
                                                                                <Checkbox
                                                                                    checked={
                                                                                        field.value
                                                                                    }
                                                                                    onCheckedChange={
                                                                                        field.onChange
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                            <FormLabel
                                                                                className={
                                                                                    "font-normal"
                                                                                }
                                                                            >
                                                                                Table of contents
                                                                            </FormLabel>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="flex space-y-2 flex-col">
                                                                <Label>Cover style</Label>
                                                                <FormField
                                                                    name="coverStyle"
                                                                    control={form.control}
                                                                    render={({ field }) => (
                                                                        <FormItem>
                                                                            <Select
                                                                                onValueChange={
                                                                                    field.onChange
                                                                                }
                                                                                defaultValue={
                                                                                    field.value
                                                                                }
                                                                            >
                                                                                <FormControl>
                                                                                    <SelectTrigger>
                                                                                        <SelectValue />
                                                                                    </SelectTrigger>
                                                                                </FormControl>
                                                                                <SelectContent>
                                                                                    {coverStyles.map(
                                                                                        (
                                                                                            coverStyle,
                                                                                        ) => (
                                                                                            <SelectItem
                                                                                                key={
                                                                                                    coverStyle.value
                                                                                                }
                                                                                                value={
                                                                                                    coverStyle.value
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    coverStyle.label
                                                                                                }
                                                                                            </SelectItem>
                                                                                        ),
                                                                                    )}
                                                                                </SelectContent>
                                                                            </Select>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    "flex flex-col hidden gap-2 mb-2"
                                                                }
                                                            >
                                                                <Label>Company logos</Label>
                                                                <div
                                                                    className={
                                                                        "flex justify-around gap-4"
                                                                    }
                                                                >
                                                                    {logos.map((value, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                "flex flex-col gap-4"
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    "bg-slate-200 flex justify-center items-center w-32 h-32 rounded-xl text-slate-400 text-xs uppercase"
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        "flex gap-2 flex-col items-center"
                                                                                    }
                                                                                >
                                                                                    <Upload
                                                                                        className={
                                                                                            "h-8"
                                                                                        }
                                                                                    />
                                                                                    <span>
                                                                                        Logo {value}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {/*<FormField*/}
                                                                            {/*    name={`logos.${value}`}*/}
                                                                            {/*    control={form.control}*/}
                                                                            {/*    render={({ field }) => (*/}
                                                                            {/*        <CompanySelect*/}
                                                                            {/*            setValue={*/}
                                                                            {/*                form.setValue*/}
                                                                            {/*            }*/}
                                                                            {/*            field={{*/}
                                                                            {/*                name: field.name,*/}
                                                                            {/*                value: field.value,*/}
                                                                            {/*            }}*/}
                                                                            {/*        />*/}
                                                                            {/*    )}*/}
                                                                            {/*/>*/}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabsContent>
                                                    <TabsContent value={"recipients"}>
                                                        <DialogDescription className={"mb-4"}>
                                                            E-mail your recap C/P to users with
                                                            permission or to non-platform users.
                                                        </DialogDescription>

                                                        <div className={"flex flex-col gap-2"}>
                                                            <div className="w-full mr-4 flex flex-col justify-between">
                                                                <div className="flex flex-col">
                                                                    <FormLabel>
                                                                        Circulate to the following
                                                                        e-mail address(es):
                                                                    </FormLabel>
                                                                    <div className="mb-4 flex flex-col">
                                                                        {emailFields.map(
                                                                            (emailField, index) => {
                                                                                const errorForField =
                                                                                    errors
                                                                                        ?.emails?.[
                                                                                        index
                                                                                    ]?.email;
                                                                                return (
                                                                                    <Fragment
                                                                                        key={index}
                                                                                    >
                                                                                        <FormItem className="py-2 flex gap-2">
                                                                                            <FormControl>
                                                                                                <Input
                                                                                                    {...register(
                                                                                                        `emails.${index}.email` as const,
                                                                                                    )}
                                                                                                    defaultValue={
                                                                                                        emailField.email
                                                                                                    }
                                                                                                    className="border p-2 border-gray-300"
                                                                                                    placeholder="Email"
                                                                                                    type="email"
                                                                                                />
                                                                                            </FormControl>
                                                                                            <Button
                                                                                                variant="outline"
                                                                                                className="w-12 !mt-0 hover:text-red-500"
                                                                                                onClick={() =>
                                                                                                    remove(
                                                                                                        index,
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    emailFields.length <=
                                                                                                    1
                                                                                                }
                                                                                                type="button"
                                                                                            >
                                                                                                <Trash2 className="h-4 w-4" />
                                                                                            </Button>
                                                                                            <Button
                                                                                                variant="outline"
                                                                                                className="w-12 !mt-0"
                                                                                                onClick={() =>
                                                                                                    append(
                                                                                                        {
                                                                                                            email: "",
                                                                                                        },
                                                                                                    )
                                                                                                }
                                                                                                type="button"
                                                                                            >
                                                                                                <Plus className="h-4 w-4" />
                                                                                            </Button>
                                                                                        </FormItem>
                                                                                        {errorForField && (
                                                                                            <FormMessage>
                                                                                                {errorForField?.message ?? (
                                                                                                    <>
                                                                                                        &nbsp;
                                                                                                    </>
                                                                                                )}
                                                                                            </FormMessage>
                                                                                        )}
                                                                                    </Fragment>
                                                                                );
                                                                            },
                                                                        )}
                                                                        {errors.emails && (
                                                                            <FormMessage>
                                                                                {
                                                                                    errors.emails
                                                                                        .message
                                                                                }
                                                                            </FormMessage>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <FormField
                                                                    name="inline.recap"
                                                                    control={form.control}
                                                                    render={({ field }) => (
                                                                        <FormItem
                                                                            className={
                                                                                "flex space-y-0 mb-3 gap-2"
                                                                            }
                                                                        >
                                                                            <FormControl>
                                                                                <Checkbox
                                                                                    checked={
                                                                                        field.value
                                                                                    }
                                                                                    onCheckedChange={
                                                                                        field.onChange
                                                                                    }
                                                                                    disabled={
                                                                                        !watch(
                                                                                            "documents",
                                                                                        ).find(
                                                                                            (
                                                                                                document,
                                                                                            ) =>
                                                                                                document.type ===
                                                                                                "recap",
                                                                                        )
                                                                                            ?.include ??
                                                                                        true
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                            <FormLabel
                                                                                className={
                                                                                    "font-normal"
                                                                                }
                                                                            >
                                                                                Include recap in the
                                                                                body of the e-mail
                                                                            </FormLabel>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                                <FormField
                                                                    name="inline.riders"
                                                                    control={form.control}
                                                                    render={({ field }) => (
                                                                        <FormItem
                                                                            className={
                                                                                "flex space-y-0 mb-3 gap-2"
                                                                            }
                                                                        >
                                                                            <FormControl>
                                                                                <Checkbox
                                                                                    checked={
                                                                                        field.value
                                                                                    }
                                                                                    onCheckedChange={
                                                                                        field.onChange
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                            <FormLabel
                                                                                className={
                                                                                    "font-normal"
                                                                                }
                                                                            >
                                                                                Include riders in
                                                                                the body of the
                                                                                e-mail
                                                                            </FormLabel>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                                <FormField
                                                                    control={form.control}
                                                                    name="note"
                                                                    render={({ field }) => (
                                                                        <FormItem>
                                                                            <FormLabel>
                                                                                Note
                                                                            </FormLabel>
                                                                            <FormControl>
                                                                                <Textarea
                                                                                    placeholder="Add an optional note"
                                                                                    className="resize-none"
                                                                                    {...field}
                                                                                    rows={4}
                                                                                />
                                                                            </FormControl>
                                                                            <FormMessage />
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </TabsContent>
                                                </div>
                                            </Tabs>
                                        </form>
                                    </Form>
                                </div>
                                <DialogFooter className="mt-4 h-10 w-full">
                                    <Button
                                        className={"mr-auto"}
                                        variant={"ghost"}
                                        onClick={prevStep}
                                        disabled={currentStep === "documents"}
                                    >
                                        <ChevronLeft className={"w-4 h-4 mr-2"} />
                                        Previous
                                    </Button>
                                    {currentStep === "styling" ? (
                                        <Button type="submit" onClick={handleExport}>
                                            <Download className={"w-4 h-4 mr-2"} />
                                            Download
                                        </Button>
                                    ) : (
                                        ""
                                    )}

                                    {currentStep === "recipients" ? (
                                        <Button type="submit" onClick={handleSend}>
                                            <Send className={"w-4 h-4 mr-2"} />
                                            Send now
                                        </Button>
                                    ) : (
                                        <Button variant={"ghost"} onClick={nextStep}>
                                            {currentStep === "styling" ? "Send via e-mail" : "Next"}
                                            <ChevronRight className={"w-4 h-4 ml-2"} />
                                        </Button>
                                    )}
                                </DialogFooter>
                            </Fragment>
                        }
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}

export default MultiExport;
export { getExportableList, MultiExportableListType, getDocumentName };
