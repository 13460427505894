import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import { format } from "date-fns";
import { Badge } from "../components/ui/badge";
import React, { Fragment } from "react";
import { DropdownMenu, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import { Copy, MoreHorizontal, Pencil, SquarePen, Trash2 } from "lucide-react";
import DataTableAdvancedDropDown from "../components/data-table/data-table-advanced-drop-down";
import CaseFileDelete from "../containers/case-files/overview/case-file-delete";
import { TableDropDownItems } from "../types/table";
import CaseFileEditDetails from "../containers/case-files/overview/case-file-edit-details";
import CaseFileDuplicate from "../containers/case-files/overview/case-file-duplicate";
import { CaseFileIndex, CaseFileStatusType } from "../types/case-files";
import { formatCaseFileStatus, formatCaseFileStatusColor } from "../lib/utils";
import CaseFileEditStatus from "../containers/case-files/overview/case-file-edit-status";

const columns: ColumnDef<CaseFileIndex>[] = [
    {
        accessorKey: "notify",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="" />;
        },
        enableSorting: false,
        meta: {
            title: "Notify",
            headerClassName: "w-6",
        },
        cell: ({ row }) => {
            const following = row.original.is_following ?? false;
            const notification = row.original.notify ?? false;

            if (following && notification) {
                return (
                    <div
                        className={
                            "bg-sky-400 shrink-0 text-sky-50 w-1.5 h-1.5 rounded-full flex items-center justify-center"
                        }
                    ></div>
                );
            } else {
                return <Fragment />;
            }
        },
    },
    {
        accessorKey: "reference",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="CP-ID" type="search" />;
        },
        sortingFn: "basic",
        meta: {
            title: "CP-ID",
        },
        filterFn: (row, id, value) => String(row.getValue(id)).includes(value),
    },
    {
        accessorKey: "cp_date",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="CP date (CEST)" type="date-range" />
            );
        },
        cell: ({ row }) => {
            const value = row.getValue("cp_date") as string;
            if (!value) {
                return <span>-</span>;
            }

            return <span>{format(new Date(value), "PPP")}</span>;
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "C/P date",
        },
    },
    {
        accessorKey: "status",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Status"
                    type="filter"
                    valueFormatter={formatCaseFileStatus}
                />
            );
        },
        cell: ({ row }) => {
            const value: CaseFileStatusType = row.getValue("status");
            const variant = formatCaseFileStatusColor(value) ?? "primary";

            return (
                <span>
                    <Badge variant={variant}>{formatCaseFileStatus(value)}</Badge>
                </span>
            );
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Status",
        },
    },
    {
        accessorKey: "document_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Document name" type="search" />;
        },
        cell: ({ row }) => {
            const shared_by: string | undefined = row.original.shared_by;
            const shared_with: Array<string> | undefined = row.original.shared_with;

            return (
                <div className="flex flex-col w-full items-start text-start">
                    <div className="mb-0 w-full text-ellipsis overflow-hidden min-h-4">
                        {row.getValue("document_name")}
                    </div>
                    {shared_by && (
                        <div className="text-xs text-muted-foreground w-full text-ellipsis overflow-hidden">
                            {`Shared by: ${shared_by}`}
                        </div>
                    )}

                    {shared_with?.length ? (
                        <Fragment>
                            <div className="text-xs text-muted-foreground w-full text-ellipsis overflow-hidden">
                                Shared with:
                            </div>
                            <div className="text-xs text-muted-foreground w-full text-ellipsis overflow-hidden">
                                {shared_with?.map((company, index) => (
                                    <span key={index}>
                                        {index ? <span>, </span> : null}
                                        {company}
                                    </span>
                                ))}
                            </div>
                        </Fragment>
                    ) : null}
                </div>
            );
        },
        filterFn: "includesString",
        meta: {
            title: "Document name",
        },
    },
    {
        accessorKey: "load_port",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Load port" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Load port",
        },
    },
    {
        accessorKey: "discharge_port",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Discharge port" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Discharge port",
        },
    },
    {
        accessorKey: "vessel_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Vessel name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Vessel name",
        },
    },
    {
        accessorKey: "charterer",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Charterer" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Charterer",
        },
    },
    {
        accessorKey: "owner",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Owner" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Owner",
        },
    },
    {
        accessorKey: "broker",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Broker" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Broker",
        },
    },
    {
        accessorKey: "author",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Author" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Author",
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Created at (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Created at (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "updated_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Last modified (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Last modified (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("updated_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DataTableAdvancedDropDown<CaseFileIndex>
                        row={row.original}
                        type="dropdown"
                        items={tableDropDown}
                    />
                </DropdownMenu>
            );
        },
    },
];

const tableDropDown: TableDropDownItems<CaseFileIndex> = {
    label: "Actions",
    groups: [
        {
            actions: [
                {
                    label: "Edit",
                    icon: <Pencil className="mr-2 h-4 w-4" />,
                    element: (row, onClose) => <CaseFileEditDetails row={row} onClose={onClose} />,
                    elementType: "dialog",
                    hide: (row) => Boolean(row.supply_chain_id),
                },
                {
                    label: "Duplicate",
                    icon: <Copy className="mr-2 h-4 w-4" />,
                    element: (row, onClose) => <CaseFileDuplicate row={row} onClose={onClose} />,
                    elementType: "sheet",
                },
                {
                    label: "Change status",
                    icon: <SquarePen className="mr-2 h-4 w-4" />,
                    children: [
                        (row) => <CaseFileEditStatus row={row} child={"draft"} />,
                        (row) => <CaseFileEditStatus row={row} child={"on_subs"} />,
                        (row) => <CaseFileEditStatus row={row} child={"cancelled"} />,
                        (row) => <CaseFileEditStatus row={row} child={"clean_fixed"} />,
                    ],
                    hide: (row) => Boolean(row.supply_chain_id),
                },
            ],
        },
        {
            actions: [
                {
                    label: "Archive",
                    icon: <Trash2 className="mr-2 h-4 w-4 stroke-white" />,
                    dangerous: true,
                    element: (row, onClose) => <CaseFileDelete row={row} onClose={onClose} />,
                    elementType: "dialog",
                },
            ],
        },
    ],
};

export { columns, tableDropDown };
