import React, { Fragment, useEffect, useState } from "react";
import { RecapTemplateInterface } from "./index.interface";
import { deleteRecapTemplate, getRecapTemplates } from "../../Admin/Recap";
import { useNavigate } from "react-router-dom";
import { RecapTemplateContainer } from "./Style";
import { NewRecapTemplates } from "./New";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns, tableDropDown } from "../../../config/recap-templates";
import { Modals } from "../../Components/Modals";
import { ModalBody } from "../../../components/style-components/Modals/Finalize";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import { DataTableToolbarButton } from "../../../types/table";

export function RecapTemplates() {
    const [data, setData] = useState<Array<RecapTemplateInterface>>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });
    const [newRecapModal, setNewRecapModal] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        getRecapTemplates().then((r: { data: React.SetStateAction<RecapTemplateInterface[]> }) =>
            setData(r.data),
        );
    }

    function handleDelete(row: RecapTemplateInterface) {
        deleteRecapTemplate(row.id).then(() => fetchData());
    }

    const buttons: Array<DataTableToolbarButton> = [
        {
            label: "new",
            action: () => setNewRecapModal(true),
        },
    ];
    return (
        <Fragment>
            <Modals
                head={<h1>New recap template</h1>}
                body={
                    <ModalBody>
                        <NewRecapTemplates onClose={() => setNewRecapModal(false)} />
                    </ModalBody>
                }
                open={newRecapModal}
                closeOnClick={true}
                onClose={() => setNewRecapModal(false)}
                size="small"
            />
            <RecapTemplateContainer>
                <div className="flex flex-col h-full">
                    <DataTableToolbar
                        table={table}
                        title="Recap Templates"
                        searchableColumns={["name", "data_count"]}
                        buttons={buttons}
                    />
                    <div className="flex py-4 h-full">
                        <DataTable
                            columns={columns}
                            table={table}
                            onClick={(row) => navigate(`${row.id}`)}
                            contextMenuItems={tableDropDown(handleDelete)}
                        />
                    </div>
                </div>
            </RecapTemplateContainer>
        </Fragment>
    );
}
