import React, { Fragment } from "react";
import styled from "styled-components";
import { colorRaw } from "../../../../../unafix-frontend/src/Central/styles/mixins";

const LoadingSvgWrapper = styled.svg`
    margin: 0 auto;
    background: transparent;
    display: block;
    shape-rendering: auto;
`;

const LoadingContainer = styled.section`
    display: flex;
    height: inherit;
    width: inherit;
    align-items: center;
    justify-content: center;
`;

export interface LoadingWrapperInterface {
    loaded: boolean;
    child: JSX.Element;
}

/**
 * A component for showing a loading spinner if provided is loaded
 * @param loaded
 * @param child
 * @constructor
 */
export function LoadingWrapper({ loaded, child }: LoadingWrapperInterface) {
    return (
        <Fragment>
            {loaded ? (
                child
            ) : (
                <LoadingContainer>
                    <LoadingSpinner />
                </LoadingContainer>
            )}
        </Fragment>
    );
}

export function LoadingWrapperSmaller({ loaded, child }: LoadingWrapperInterface) {
    return (
        <Fragment>
            {loaded ? (
                child
            ) : (
                <LoadingContainer>
                    <SmallLoadingSpinner />
                </LoadingContainer>
            )}
        </Fragment>
    );
}

/**
 * A component for showing a loading spinner
 * @constructor
 */
export function LoadingSpinner() {
    return (
        <Fragment>
            <LoadingSvgWrapper
                xmlns="http://www.w3.org/2000/svg"
                width="64px"
                height="64px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="32"
                    strokeWidth="8"
                    stroke={colorRaw("primary")()}
                    strokeDasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    strokeLinecap="round"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="1s"
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"
                    ></animateTransform>
                </circle>
            </LoadingSvgWrapper>
        </Fragment>
    );
}

export function SmallLoadingSpinner() {
    return (
        <Fragment>
            <LoadingSvgWrapper
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="32"
                    strokeWidth="8"
                    stroke={colorRaw("primary")()}
                    strokeDasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    strokeLinecap="round"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="1s"
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"
                    ></animateTransform>
                </circle>
            </LoadingSvgWrapper>
        </Fragment>
    );
}
