import { Card, CardHeader } from "../../../components/ui/card";
import React from "react";
import { Typography } from "../../../components/ui/typography";
import { Button } from "../../../components/ui/button";
import { useAddenda } from "../../../lib/providers/addenda-provider";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function AddendaHeader() {
    const { permissions } = useCaseFiles();
    const { handleSave, activeAddendum } = useAddenda();

    return (
        <Card>
            <CardHeader className="flex justify-between w-full flex-row items-center py-4 space-y-0">
                <Typography
                    text={activeAddendum ? `Edit ${activeAddendum.title}` : "New addendum"}
                    style="h4"
                />
                <div className="inline-flex gap-x-4 items-center">
                    <Button onClick={handleSave} disabled={!permissions.add_addendum}>
                        {activeAddendum ? "Update" : "Create"}
                    </Button>
                </div>
            </CardHeader>
        </Card>
    );
}

export default AddendaHeader;
