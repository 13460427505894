import {
    RiderContextExports,
    RiderDBResource,
    RiderManagementState,
    RiderProviderProps,
    RiderReducerAction,
    RiderTerm,
} from "../../types/riders";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { toast } from "sonner";
import { useCaseFiles } from "./CaseFilesProvider";
import {
    useCreateRiderTermMutation,
    useDeleteRiderTermMutation,
    useGetGlobalRiderClausesQuery,
    useUpdateMultipleRiderTermsMutation,
} from "../../api/tenant/riders";
import { useAppDispatch } from "../hooks";
import { fetchFieldValues } from "../stores/RecapFieldValuesSlice";
import { fetchRecapStructure } from "../../api/central/recap-structure";

const defaultHeader = "Additional Clauses";

function riderReducer(
    state: RiderManagementState,
    action: RiderReducerAction,
): RiderManagementState {
    switch (action.type) {
        case "SET_TERMS":
            return { ...state, terms: action.payload };
        case "SET_SAVED_TERMS":
            return { ...state, savedTerms: action.payload };
        case "ADD_TERM":
            return { ...state, terms: [...state.terms, action.payload] };
        case "ADD_TERM_GENERAL":
            return {
                ...state,
                savedTerms: [...state.savedTerms, action.payload],
                terms: [...state.terms, action.payload],
            };
        case "SET_CHANGES":
            return { ...state, changes: action.payload };
        case "SET_SAVING":
            return { ...state, saving: action.payload };
        case "SET_GLOBAL_CLAUSES":
            return { ...state, globalClauses: action.payload };
        case "SET_HEADER":
            return { ...state, header: action.payload };
        case "SET_EDITOR":
            return { ...state, editor: action.payload };
        case "SET_SHOW_DROPDOWN":
            return { ...state, showDropdown: action.payload };
        case "SET_INITIAL_VALUES":
            return { ...state, initialValues: action.payload };
        case "SET_RIDER_IN_CREATION":
            return {
                ...state,
                initialValues: action.payload,
                riderInCreation: action.payload,
            };
        case "UPDATE_RIDER_IN_CREATION":
            return {
                ...state,
                riderInCreation: {
                    ...state.riderInCreation,
                    [action.payload.field]: action.payload.value,
                },
            };
        case "CLEAR_RIDER_IN_CREATION":
            return {
                ...state,
                riderInCreation: {
                    title: "",
                    content: "",
                },
            };
        case "SET_SHOW_DELETE_MODAL":
            return { ...state, showDeleteModal: action.payload };
        case "SET_SHOW_EXPORT_MODAL":
            return { ...state, showExportModal: action.payload };
        case "SET_SHOW_MULTI_CLAUSE":
            return { ...state, showMultiClause: action.payload };
        case "SET_SHOW_SEARCH_MODAL":
            return { ...state, showSearchModal: action.payload };
        case "SET_SHOW_RECAP_PLACEHOLDER_MODAL":
            return { ...state, showRecapPlaceholderModal: action.payload };
        case "SET_SHOW_HEADER_EDITOR":
            return { ...state, showHeaderEditor: action.payload };
        case "SET_IS_WAITING_GPT":
            return { ...state, isWaitingGPT: action.payload };
        case "SET_CLAUSE_NUMBERING": {
            const termsNumbering = state.terms.map((term) => {
                return { ...term, numbering: action.payload };
            });
            const savedTermsNumbering = state.savedTerms.map((term) => {
                return { ...term, numbering: action.payload };
            });
            return {
                ...state,
                clauseNumbering: action.payload,
                terms: termsNumbering,
                savedTerms: savedTermsNumbering,
            };
        }
        case "SET_CLAUSE_NUMBER_START": {
            const termsOffset = state.terms.map((term) => {
                return { ...term, offset: action.payload };
            });
            const savedTermsOffset = state.savedTerms.map((term) => {
                return { ...term, offset: action.payload };
            });
            return {
                ...state,
                clauseNumberStart: action.payload,
                terms: termsOffset,
                savedTerms: savedTermsOffset,
            };
        }
        case "SET_NEW_RIDER_TERM":
            return { ...state, newRiderTerm: action.payload };
        case "SET_ACTIVE_RIDER_TERM":
            return {
                ...state,
                activeRiderTerm: action.payload,
                initialValues: {
                    title: action.payload ? action.payload.title : "",
                    content: action.payload ? action.payload.content : "",
                },
            };
        case "UPDATE_ACTIVE_RIDER_TERM":
            if (state.activeRiderTerm) {
                const updatedTerms = state.terms.map((term) =>
                    term.id === action.payload.id
                        ? {
                              ...term,
                              [action.payload.field]: action.payload.value,
                          }
                        : term,
                );

                return {
                    ...state,
                    terms: updatedTerms,
                };
            }
            return state;
        case "SET_WAS_DELETED_RIDER_TERM":
            return { ...state, wasDeletedRiderTerm: action.payload };
        case "SET_RECAP_STRUCTURE":
            return { ...state, recapStructure: action.payload };
        case "SET_RECAP_FIELDS":
            return { ...state, recapFields: action.payload };
        default:
            return state;
    }
}

const RiderContext = createContext<RiderContextExports | undefined>(undefined);
export const useRiderTerms = () => {
    const context = useContext(RiderContext);
    if (context === undefined) {
        throw new Error("useRiderTerms must be used within a RiderProvider");
    }
    return context;
};

export const RiderProvider: React.FC<RiderProviderProps> = ({ children, initialTerms = [] }) => {
    const { documents, case_file_id, meta_data, update_meta_date } = useCaseFiles(),
        [riderState, riderDispatch] = useReducer(riderReducer, {
            terms: initialTerms,
            savedTerms: initialTerms,
            changes: 0,
            saving: "idle",
            globalClauses: [],
            header: initialTerms.length > 0 ? initialTerms[0].header : defaultHeader,
            activeRiderTerm: undefined,
            editor: undefined,
            initialValues: {
                title: "",
                content: "",
            },
            riderInCreation: {
                title: "",
                content: "",
            },
            showDropdown: false,
            showDeleteModal: false,
            showExportModal: false,
            showMultiClause: false,
            showSearchModal: false,
            showRecapPlaceholderModal: false,
            showHeaderEditor: false,
            isWaitingGPT: false,
            clauseNumbering: initialTerms.length > 0 ? initialTerms[0].numbering : false,
            clauseNumberStart: initialTerms.length > 0 ? initialTerms[0].offset : 1,
            recapStructure: [],
            recapFields: [],
        }),
        [createRiderTerm] = useCreateRiderTermMutation(),
        [updateMultipleRiderTerms] = useUpdateMultipleRiderTermsMutation(),
        { data: globalRiderClauses } = useGetGlobalRiderClausesQuery(),
        [deleteRiderTerm] = useDeleteRiderTermMutation();

    const recapIndex = documents.find((document) => document.template_type === "recap");
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (recapIndex?.id) {
            (async () => {
                const recapValues = await dispatch(
                    fetchFieldValues({
                        recapId: recapIndex.id,
                        case_file_id: case_file_id,
                    }),
                );
                const { data: recapStructure } = await fetchRecapStructure();
                riderDispatch({ type: "SET_RECAP_FIELDS", payload: recapValues.payload });
                riderDispatch({ type: "SET_RECAP_STRUCTURE", payload: recapStructure });
            })();
        }
    }, [recapIndex]);

    // On mount
    useEffect(() => {
        init();
    }, []);

    function init() {
        // If there are rider terms
        if (riderState.terms.length > 0) {
            if (!riderState.header) {
                riderDispatch({
                    type: "SET_HEADER",
                    payload: riderState.terms[0].header,
                });
            }
            // Sync numbering toggle
            riderDispatch({
                type: "SET_CLAUSE_NUMBERING",
                payload: riderState.terms[0].numbering,
            });
            // Sync numbering start
            riderDispatch({
                type: "SET_CLAUSE_NUMBER_START",
                payload: riderState.terms[0].offset,
            });
        }
    }

    useEffect(() => {
        if (globalRiderClauses) {
            setGlobalClauses(globalRiderClauses.data);
        }
    }, [globalRiderClauses]);

    function setGlobalClauses(clauses: Array<RiderDBResource>) {
        riderDispatch({ type: "SET_GLOBAL_CLAUSES", payload: clauses });
    }

    // Keep terms in sync with redux store
    useEffect(() => {
        if (meta_data) {
            update_meta_date({
                ...meta_data,
                has_rider_terms: riderState.terms.length > 0,
            });
        }
    }, [riderState.terms]);

    // Update changes count, which triggers the saving process
    useEffect(() => {
        const changes = getUnsavedChangesCount();
        riderDispatch({
            type: "SET_CHANGES",
            payload: changes,
        });
    }, [riderState.terms]);

    // Initiate saving process when changes are detected
    useEffect(() => {
        persistState();
    }, [riderState.changes]);

    // Keep terms sorted by number
    useEffect(() => {
        const sortTerms = () => {
            const sortedTerms = [...riderState.terms].sort((a, b) => a.number - b.number);
            const sortedSavedTerms = [...riderState.savedTerms].sort((a, b) => a.number - b.number);

            // Check if the sorted terms are different from the current terms
            const termsAreDifferent = sortedTerms.some(
                (term, index) => term !== riderState.terms[index],
            );
            const savedTermsAreDifferent = sortedSavedTerms.some(
                (term, index) => term !== riderState.savedTerms[index],
            );

            if (termsAreDifferent) {
                riderDispatch({ type: "SET_TERMS", payload: sortedTerms });
            }
            if (savedTermsAreDifferent) {
                riderDispatch({
                    type: "SET_SAVED_TERMS",
                    payload: sortedSavedTerms,
                });
            }
        };

        sortTerms();
    }, [riderState.terms, riderState.savedTerms]);

    // Create a new term
    const createTerm = async () => {
        if (riderState.activeRiderTerm === undefined && !riderState.showHeaderEditor) {
            if (
                riderState.riderInCreation.title !== "" &&
                riderState.riderInCreation.content !== ""
            ) {
                try {
                    const res = await createRiderTerm({
                        case_file_id: case_file_id,
                        ...riderState.riderInCreation,
                    }).unwrap();

                    const newTerms = [...riderState.terms, { ...res.data, is_visible: true }];
                    const newSavedTerms = [
                        ...riderState.savedTerms,
                        { ...res.data, is_visible: true },
                    ];

                    riderDispatch({ type: "SET_TERMS", payload: newTerms });
                    riderDispatch({
                        type: "SET_SAVED_TERMS",
                        payload: newSavedTerms,
                    });
                    riderDispatch({ type: "CLEAR_RIDER_IN_CREATION" });
                    riderDispatch({
                        type: "SET_ACTIVE_RIDER_TERM",
                        payload: undefined,
                    });

                    toast.message("Added rider term");
                } catch (e) {
                    toast.error("something went wrong while creating rider term");
                }
            }
        }
    };

    const persistState = async (override: boolean = false, showToast?: boolean) => {
        if (riderState.saving !== "idle") {
            return;
        }
        if (override || riderState.changes > 0) {
            riderDispatch({ type: "SET_SAVING", payload: "pending" });
            // Save terms

            try {
                const savedTerms = await updateMultipleRiderTerms({
                    terms: riderState.terms,
                    case_file_id: case_file_id,
                }).unwrap();

                riderDispatch({ type: "SET_TERMS", payload: savedTerms.data });
                riderDispatch({
                    type: "SET_SAVED_TERMS",
                    payload: savedTerms.data,
                });
                riderDispatch({
                    type: "SET_SAVING",
                    payload: "resolved",
                });
                resetSavingStatus();

                if (showToast) {
                    toast.message("Saved rider terms");
                }
            } catch (e) {
                riderDispatch({
                    type: "SET_SAVING",
                    payload: "rejected",
                });
                resetSavingStatus();
            }
        }
    };

    // Reset saving status after a short delay
    const resetSavingStatus = () => {
        setTimeout(() => {
            riderDispatch({ type: "SET_SAVING", payload: "idle" });
        }, 500);
    };

    // Update a single term in terms in state
    const updateSingleTerm = (term: RiderTerm) => {
        // update riderState.terms and riderState.savedTerms using the term.id to find the term to update
        const updatedTerms = riderState.terms.map((t) => (t.id === term.id ? term : t));
        const updatedSavedTerms = riderState.terms.map((t) => (t.id === term.id ? term : t));
        riderDispatch({ type: "SET_TERMS", payload: updatedTerms });
        riderDispatch({ type: "SET_SAVED_TERMS", payload: updatedSavedTerms });
    };

    // Handle "Snip" command from the editor
    const addSnippedClause = async (contents: ChildNode[]) => {
        // gather all clause contents
        let htmlContent = "";
        contents.slice(1).forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as Element;
                htmlContent += element.outerHTML;
            }
        });

        const newClause: Pick<RiderTerm, "title" | "content"> = {
            title: contents[0].textContent!,
            content: htmlContent,
        };

        try {
            const res = await createRiderTerm({
                case_file_id: case_file_id,
                ...newClause,
            }).unwrap();

            riderDispatch({
                type: "ADD_TERM_GENERAL",
                payload: { ...res.data, is_visible: true },
            });
        } catch (e) {
            toast.error("something went wrong while creating rider term");
        }
    };

    function reorderTermsAfterDelete(terms: RiderTerm[], term_id: string): RiderTerm[] {
        const filteredTerms: RiderTerm[] = [];

        // Filter and map into the new array while assigning new numbers
        for (let i = 0, newNumber = 1; i < terms.length; i++) {
            if (terms[i].id !== term_id) {
                filteredTerms.push({
                    ...terms[i],
                    number: newNumber++,
                });
            }
        }

        return filteredTerms;
    }

    // Handles "Delete" button click
    const removeTerm = async (term_id: string) => {
        await deleteRiderTerm({
            case_file_id: case_file_id,
            term_id: term_id,
        });

        const termsList = reorderTermsAfterDelete(riderState.terms, term_id);
        const savedTermsList = reorderTermsAfterDelete(riderState.savedTerms, term_id);

        riderDispatch({ type: "SET_TERMS", payload: termsList });
        riderDispatch({ type: "SET_SAVED_TERMS", payload: savedTermsList });
        riderDispatch({
            type: "SET_ACTIVE_RIDER_TERM",
            payload: undefined,
        });
        riderDispatch({ type: "SET_SHOW_DELETE_MODAL", payload: false });

        toast.message("Successfully removed rider term");
    };

    // For clause dragging reordering
    const handleTermReorder = async (updatedTerms: Array<RiderTerm>) => {
        try {
            const res = await updateMultipleRiderTerms({
                terms: updatedTerms,
                case_file_id: case_file_id,
            }).unwrap();

            riderDispatch({ type: "SET_TERMS", payload: res.data });
            riderDispatch({ type: "SET_SAVED_TERMS", payload: res.data });
        } catch (e) {
            toast.error("An error occurred while reordering the rider terms");
        }
    };

    // Get the number of unsaved changes (difference between terms and savedTerms)
    const getUnsavedChangesCount = () => {
        const currentContentMap: { [key: string]: string } = {};
        const currentTitleMap: { [key: string]: string } = {};
        let count = 0;

        // Create maps for content and title from the current state
        riderState.terms.forEach((item) => {
            currentContentMap[item.id] = item.content;
            currentTitleMap[item.id] = item.title;
        });

        // Iterate over saved terms and compare
        riderState.savedTerms.forEach((item) => {
            const isContentChanged =
                item.id in currentContentMap && currentContentMap[item.id] !== item.content;
            const isTitleChanged =
                item.id in currentTitleMap && currentTitleMap[item.id] !== item.title;

            if (isContentChanged || isTitleChanged) {
                count++;
            }
        });

        // Check for header changes
        if (riderState.header !== getGlobalHeader()) {
            count++;
        }

        return count;
    };

    const getGlobalHeader = () => {
        if (riderState.header) {
            return riderState.header;
        } else {
            return riderState.savedTerms.length > 0
                ? riderState.savedTerms[0].header
                : defaultHeader;
        }
    };

    const resetView = () => {
        riderDispatch({ type: "SET_SHOW_MULTI_CLAUSE", payload: false });
        riderDispatch({ type: "SET_SHOW_HEADER_EDITOR", payload: false });
        riderDispatch({ type: "SET_SHOW_EXPORT_MODAL", payload: false });
        riderDispatch({ type: "SET_SHOW_DELETE_MODAL", payload: false });
        riderDispatch({ type: "SET_SHOW_SEARCH_MODAL", payload: false });
        riderDispatch({ type: "SET_SHOW_RECAP_PLACEHOLDER_MODAL", payload: false });
        riderDispatch({ type: "SET_ACTIVE_RIDER_TERM", payload: undefined });
    };

    function setRiderTerms(terms: Array<RiderTerm>) {
        riderDispatch({ type: "SET_TERMS", payload: terms });
        riderDispatch({ type: "SET_SAVED_TERMS", payload: terms });
        riderDispatch({
            type: "SET_HEADER",
            payload: terms && terms.length ? terms[0].header : defaultHeader,
        });
        riderDispatch({
            type: "SET_CLAUSE_NUMBERING",
            payload: terms && terms.length ? terms[0].numbering : false,
        });
        riderDispatch({
            type: "SET_CLAUSE_NUMBER_START",
            payload: terms && terms.length ? terms[0].offset : 0,
        });
    }

    return (
        <RiderContext.Provider
            value={{
                riderState,
                riderDispatch,
                createTerm,
                persistState,
                updateSingleTerm,
                addSnippedClause,
                removeTerm,
                handleTermReorder,
                resetView,
                getGlobalHeader,
                setRiderTerms,
            }}
        >
            {children}
        </RiderContext.Provider>
    );
};
