import { TableActionProps } from "../../../types/table";
import { GlobalDocumentInterface, SignatureRoleInterface } from "../../../types/document-editor";
import React, { Fragment, useEffect, useState } from "react";
import { DialogDescription, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import { Typography } from "../../../components/ui/typography";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { getSignatureData, resendSignature } from "../../../api/tenant/docusign";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function SigningStatus({ row }: TableActionProps<GlobalDocumentInterface>) {
    const [fullySigned, setFullySigned] = useState<boolean>(false);
    const [deleted, setDeleted] = useState<boolean>(false);
    const [signees, setSignees] = useState<Array<SignatureRoleInterface>>();
    const { case_file_id } = useCaseFiles();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        const status = await getSignatureData(row.id, case_file_id);

        setFullySigned(status.signed);
        setSignees(status.signees);
        setDeleted(status.deleted);
    }

    return (
        <Fragment>
            <DialogHeader>
                <DialogTitle>Signing status</DialogTitle>
                {deleted && (
                    <DialogDescription>
                        The e-signing process for this document is cancelled
                    </DialogDescription>
                )}
                {fullySigned && (
                    <DialogDescription>
                        The e-signing process for this document is completed
                    </DialogDescription>
                )}
                {!fullySigned && !deleted && signees && (
                    <Fragment>
                        {signees.map((signee, index) => (
                            <div
                                className="py-4 flex items-center flex-1 justify-between"
                                key={index}
                            >
                                <div className="items-center flex">
                                    <div>
                                        <Typography
                                            text={
                                                <Fragment>
                                                    <b>Name: </b> {signee.name}
                                                </Fragment>
                                            }
                                            style="p"
                                        />
                                        <Typography
                                            text={
                                                <Fragment>
                                                    <b>Email: </b> {signee.email}
                                                </Fragment>
                                            }
                                            style="p"
                                        />
                                    </div>
                                    <Badge
                                        variant={signee.signed ? "success" : "primary"}
                                        className="h-6 ml-8"
                                    >
                                        {signee.signed ? "Signed" : "Pending"}
                                    </Badge>
                                </div>
                                <Button
                                    onClick={() => resendSignature(signee, row.id, case_file_id)}
                                >
                                    Re-send
                                </Button>
                            </div>
                        ))}
                    </Fragment>
                )}
            </DialogHeader>
        </Fragment>
    );
}

export default SigningStatus;
