import Froalaeditor from "froala-editor";

Froalaeditor.DefineIcon("addition", {
    PATH: "M31 21H13a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2Z M12 16a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2H13a1 1 0 0 0-1 1Z M27 27H13a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2Z M15.89 9a1 1 0 0 0-1-1H10V3.21a1 1 0 0 0-2 0V8H2.89a1 1 0 0 0 0 2H8v5.21a1 1 0 0 0 2 0V10h4.89a1 1 0 0 0 1-1Z",
});
Froalaeditor.DefineIcon("deletion", {
    PATH: "m15.072 3.997 5.679 5.837-3.697 3.696-3.978 3.97h7.928V19H7.935l-3.933-3.933 11.07-11.07ZM10.952 17.5l4.51-4.5-3.635-3.637-5.704 5.704L8.556 17.5h2.396Z",
});
Froalaeditor.DefineIcon("revert", {
    PATH: "m8.87 10.505 1.95 1.945-1.06 1.061L6 9.755l3.76-3.757 1.06 1.06-1.95 1.947H18V18h-6.047v-1.5H16.5v-5.995H8.87Z",
});
Froalaeditor.DefineIcon("snip", {
    PATH: "M4.5 10A2.5 2.5 0 0 0 2 12.5 2.5 2.5 0 0 0 4.5 15 2.5 2.5 0 0 0 7 12.5 2.5 2.5 0 0 0 4.5 10zm0 1A1.5 1.5 0 0 1 6 12.5 1.5 1.5 0 0 1 4.5 14 1.5 1.5 0 0 1 3 12.5 1.5 1.5 0 0 1 4.5 11zM12 1 5 11h1v1l6-9V1zM8 7.5a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5zm3.5 2.5a2.5 2.5 0 0 1 2.5 2.5 2.5 2.5 0 0 1-2.5 2.5A2.5 2.5 0 0 1 9 12.5a2.5 2.5 0 0 1 2.5-2.5zm0 1a1.5 1.5 0 0 0-1.5 1.5 1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5zM4 1v2l6 9v-1h1L4 1zm4 6.5a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5zM0 5h4v1H0zm12 0h4v1h-4z",
});
Froalaeditor.DefineIcon("placeholder", {
    PATH: "M14 7H16C18.7614 7 21 9.23858 21 12C21 14.7614 18.7614 17 16 17H14M10 7H8C5.23858 7 3 9.23858 3 12C3 14.7614 5.23858 17 8 17H10M8 12H16",
});

// Register 'Make Green' command
Froalaeditor.RegisterCommand("newMark", {
    title: "Mark Added",
    icon: "addition",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        this.format.applyStyle("color", "#34a853");
        this.format.applyStyle("text-decoration", "underline");

        const selection = this.selection.get() as Selection;
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            // Check if the selection is not collapsed
            if (!range.collapsed) {
                // Move the cursor to the end of the selection
                range.collapse(false);

                // Remove and add the range
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }

        this.commands.clearFormatting();
    },
});

// Register 'Make Red and Strikethrough' command
Froalaeditor.RegisterCommand("deleteMark", {
    title: "Mark Removed",
    icon: "deletion",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        this.format.applyStyle("color", "#FF0000");
        this.format.applyStyle("text-decoration", "line-through");

        const selection = this.selection.get() as Selection;
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            // Check if the selection is not collapsed
            if (!range.collapsed) {
                // Move the cursor to the end of the selection
                range.collapse(false);

                // Remove and add the range
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }

        this.commands.clearFormatting();
    },
});

// Register 'Make Green' command
Froalaeditor.RegisterCommand("revertMark", {
    title: "Normalise",
    icon: "revert",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        this.format.applyStyle("color", "black");
        this.format.applyStyle("text-decoration", "none");

        const selection = this.selection.get() as Selection;
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            // Check if the selection is not collapsed
            if (!range.collapsed) {
                // Move the cursor to the end of the selection
                range.collapse(false);

                // Remove and add the range
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }

        this.commands.clearFormatting();
    },
});

Froalaeditor.RegisterCommand("clauseCut", {
    title: "Snip",
    icon: "snip",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        // Get the current selection
        const selection = this.selection.get() as Selection;
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            // Check if the selection is not collapsed (i.e., it's a range of text)
            if (!range.collapsed) {
                // Get all the selected elements
                const selectedElements: ChildNode[] = [];
                const documentFragment = range.cloneContents();
                const childNodes = documentFragment.childNodes;

                childNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        selectedElements.push(node);
                    }
                });

                // need at least a title and some content
                if (
                    selectedElements.length >= 2 &&
                    selectedElements[0].textContent !== "" &&
                    this.opts.clauseCutCallback
                ) {
                    this.opts.clauseCutCallback(selectedElements);
                    // remove the selected elements from the editor
                    range.deleteContents();
                    range.collapse(false);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    this.commands.clearFormatting();
                } else {
                    // If the selection is not valid, block the command
                    return;
                }
            }
        }
    },
});

Froalaeditor.RegisterShortcut(65, "newMark", null, "A", true);
Froalaeditor.RegisterShortcut(83, "deleteMark", null, "S", true);
Froalaeditor.RegisterShortcut(68, "revertMark", null, "D", true);
// sex number, niceee
Froalaeditor.RegisterShortcut(69, "clauseCut", null, "E", true);
