import React from "react";
import ChatChannelHeader from "./chat-channel-header";
import { ScrollArea } from "../ui/scroll-area";
import { useChat } from "../../lib/providers/chat-provider";
import ChatChannel from "./chat-channel";

function ChatChannelsList() {
    const { channels } = useChat();

    return (
        <div className="w-1/3 h-full border-solid border-r-[1px] border-border">
            <ChatChannelHeader />
            <div>
                <ScrollArea className="h-[calc(100vh-152px)]">
                    {channels.map((channel) => (
                        <ChatChannel channel={channel} key={channel.channel_id} />
                    ))}
                </ScrollArea>
            </div>
        </div>
    );
}

export default ChatChannelsList;
