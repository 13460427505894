import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchTenants } from "../../Admin/Tenants";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns, tableDropDown } from "../../../config/tenant";
import {
    DashboardTopBarContainerContent,
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentTitle,
} from "../Dashboard/Style";
import { DataTableToolbar } from "../../../components/data-table/data-table-toolbar";
import DataTable from "../../../components/data-table/data-table";
import { adminPrefix } from "../../../config/central";
import { TenantAdminInterface } from "../../../types/Central";

export function AdminTenantsPage() {
    const [data, setData] = useState<Array<TenantAdminInterface>>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        fetchTenants().then((result) => {
            setData(result.data);
        });
    }

    function handleClick(row: TenantAdminInterface) {
        navigate(`/${adminPrefix}/tenants/${row.id}`);
    }

    return (
        <Fragment>
            <DashboardTopBarContainerContent>
                <DashboardTopBarContainerContentHeader>
                    <DashboardTopBarContainerContentTitle>
                        Tenants
                    </DashboardTopBarContainerContentTitle>
                </DashboardTopBarContainerContentHeader>
                <div className="flex flex-col h-full">
                    <DataTableToolbar
                        table={table}
                        title="Tenants"
                        searchableColumns={["id", "created_at", "approval_status", "hidden"]}
                    />
                    <div className="flex py-4 h-full">
                        <DataTable
                            columns={columns}
                            table={table}
                            onClick={handleClick}
                            contextMenuItems={tableDropDown()}
                        />
                    </div>
                </div>
            </DashboardTopBarContainerContent>
        </Fragment>
    );
}
