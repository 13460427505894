import { tenantApi } from "../tenantApi";
import { ParseRecapPayload, RecapInterface, RecapStructurePatchInterface } from "../../types/recap";
import { WithCaseFileId } from "../../types";
import { RecapClause } from "../../containers/case-files/recaps/recap-clauses";

const recapApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getRecap: build.query<RecapInterface, WithCaseFileId<{ id: string }>>({
            query: ({ case_file_id, id }) => ({
                url: `recaps/${case_file_id}/document/${id}`,
            }),
            transformResponse: (response: { data: RecapInterface }): RecapInterface => {
                return response.data;
            },
            providesTags: ["RecapDocument"],
        }),
        addRecapStructure: build.mutation<
            RecapStructurePatchInterface,
            WithCaseFileId<
                Partial<RecapStructurePatchInterface> & Pick<RecapStructurePatchInterface, "id">
            >
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `recaps/${case_file_id}/document/${body.id}/structure/add`,
                method: "POST",
                body: {
                    fields: body.fields,
                },
            }),
            invalidatesTags: ["RecapDocument"],
        }),
        removeRecapStructure: build.mutation<
            RecapStructurePatchInterface,
            WithCaseFileId<
                Partial<RecapStructurePatchInterface> & Pick<RecapStructurePatchInterface, "id">
            >
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `recaps/${case_file_id}/document/${body.id}/structure/remove`,
                method: "POST",
                body: {
                    fields: body.fields,
                },
            }),
            invalidatesTags: ["RecapDocument"],
        }),
        updateClauseOrderStructure: build.mutation<
            RecapStructurePatchInterface,
            WithCaseFileId<
                Pick<RecapStructurePatchInterface, "id"> & { recapClauseOrder: string[] }
            >
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `recaps/${case_file_id}/document/${body.id}/structure/update-clause-order`,
                method: "POST",
                body: {
                    recapClauseOrder: body.recapClauseOrder,
                },
            }),
            invalidatesTags: ["RecapDocument"],
        }),
        uploadQ88Forms: build.mutation<boolean, WithCaseFileId<{ formData: FormData }>>({
            query: ({ case_file_id, formData }) => ({
                url: `${case_file_id}/case-file/import-q88`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["CaseFileFileUploads", "Documents"],
        }),
        addRecapClause: build.mutation<
            RecapClause,
            WithCaseFileId<
                Pick<RecapClause, "title" | "value"> & Pick<RecapStructurePatchInterface, "id">
            >
        >({
            query: ({ case_file_id, ...body }) => ({
                url: `recaps/${case_file_id}/document/${body.id}/clause`,
                method: "POST",
                body: {
                    clauses: body,
                },
            }),
            invalidatesTags: ["RecapDocument"],
        }),
        parseRecapPDF: build.mutation<
            ParseRecapPayload,
            WithCaseFileId<{ file: File; id: string }>
        >({
            query: ({ ...body }) => {
                const formData = new FormData();
                formData.append("file", body.file);

                return {
                    url: `recaps/${body.case_file_id}/document/${body.id}/parse-pdf`,
                    method: "POST",
                    body: formData,
                };
            },
            invalidatesTags: ["RecapDocument"],
        }),
        importRecapPDF: build.mutation<
            void,
            WithCaseFileId<{
                id: string;
                data: ParseRecapPayload;
            }>
        >({
            query: ({ ...body }) => ({
                url: `recaps/${body.case_file_id}/document/${body.id}/import-pdf`,
                method: "POST",
                body: body.data,
            }),
            invalidatesTags: ["RecapDocument"],
        }),
    }),
});

export const {
    useLazyGetRecapQuery,
    useAddRecapStructureMutation,
    useRemoveRecapStructureMutation,
    useUploadQ88FormsMutation,
    useAddRecapClauseMutation,
    useUpdateClauseOrderStructureMutation,
    useParseRecapPDFMutation,
    useImportRecapPDFMutation,
} = recapApi;
