import React, { useEffect, useRef, useState } from "react";
import { Typography } from "../../../../components/ui/typography";

interface ParsedFieldBoxProps {
    label: string;
    content: string;
}

const ParsedFieldBox = ({ label, content }: ParsedFieldBoxProps) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            setIsOverflowing(contentRef.current.scrollHeight > contentRef.current.clientHeight);
        }
    }, [content]);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="border rounded p-2 mb-2 relative" onClick={toggleCollapse}>
            <div className="flex justify-between items-center">
                <Typography text={label} style="p" className="italic" />
                <div className="flex-shrink-0 ml-2 flex items-center space-x-2">
                    {isOverflowing && (
                        <Typography
                            text={isCollapsed ? "Show more" : "Show less"}
                            style="small"
                            className="text-xs select-none cursor-pointer"
                        />
                    )}
                </div>
            </div>
            <div
                ref={contentRef}
                className={`mt-2 text-blue-600 ${isCollapsed ? "line-clamp-1" : "line-clamp-none"}`}
                style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: isCollapsed ? 1 : "unset",
                    WebkitBoxOrient: "vertical",
                    maxWidth: "410px",
                }}
            >
                <Typography text={content} style="p" className="whitespace-pre-wrap" />
            </div>
        </div>
    );
};

export default ParsedFieldBox;
