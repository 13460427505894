import React, { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { getRecapFieldsIndex, getRecapTemplate, updateRecapTemplate } from "../../../Admin/Recap";
import { ChangeTemplateContainer, ChangeTemplateFormContainer } from "../../Dashboard/Style";
import {
    RecapCategoryBlocksInterface,
    RecapInterface,
    RecapSingleFieldInterface,
    RecapTabStateInterface,
} from "../index.interface";
import AddRecapField from "../Modals/AddRecapField";
import { Button } from "../../../../components/ui/button";
import DeleteRecapField from "../Modals/DeleteRecapField";
import { Card, CardContent, CardTitle } from "../../../../components/ui/card";
import { toast } from "sonner";

export function EditRecapTemplate() {
    const [state, setState] = useReducer(
        (state: RecapTabStateInterface, newState: Partial<RecapTabStateInterface>) => ({
            ...state,
            ...newState,
        }),
        {
            fieldsOrderedByCategory: [],
            currentFields: [],
            textAreaValue: "",
            activeCategoryId: "",
            availableFields: [],
            fieldCategories: [],
            categories: [],
            structure: [],
            availableFieldsToAdd: [],
            newChanges: false,
            boundEcho: false,
            hasChanges: false,
            loaded: true,
        },
    );

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            getRecapTemplate(id).then((recap: RecapInterface) => {
                const categories = recap.categories;

                getRecapFieldsIndex().then((r) => {
                    const data = r.data.filter(
                        (x) =>
                            recap?.data
                                .map((field: RecapSingleFieldInterface) => field.id)
                                .indexOf(x.id) === -1,
                    );

                    const fieldsOrderedByCategory: Array<RecapCategoryBlocksInterface> =
                        categories.map((category) => {
                            return {
                                ...category,
                                data: recap.data.filter(
                                    (field: RecapSingleFieldInterface) =>
                                        field.category_id === category.category_id,
                                ),
                            };
                        });
                    setState({
                        fieldsOrderedByCategory: fieldsOrderedByCategory,
                        activeCategoryId: categories[0].category_id,
                        recap: recap,
                        fieldCategories: recap.categories,
                        availableFields: data,
                    });
                });
            });
        }
    }, [id]);

    async function handleSave() {
        if (state.recap) {
            const values = {
                name: state.recap.name,
                data: state.fieldsOrderedByCategory
                    .map((category) => category.data)
                    .flatMap((data) => data)
                    .map((field) => ({
                        ...field,
                        categoryName:
                            state.fieldCategories.find(
                                (category) => category.category_id === field.category_id,
                            )?.category_name ?? "",
                    })),
            };

            await updateRecapTemplate(state.recap.id, values);

            toast.message("<p>Successful saved</p>");
        }
    }

    function handleAddFields(fields: RecapSingleFieldInterface[], category_id: string) {
        const field_ids = fields.map((field: { id: string }): any => field.id);
        const category_index = state.fieldsOrderedByCategory.findIndex(
            (category) => category.category_id === category_id,
        );
        const categories = state.fieldsOrderedByCategory;
        categories[category_index]["data"].push(...fields);
        setState({
            availableFields: state.availableFields.filter((field) => !field_ids.includes(field.id)),
            fieldsOrderedByCategory: [...categories],
        });
    }

    function handleRemoveField(field: RecapSingleFieldInterface) {
        const category_index = state.fieldsOrderedByCategory.findIndex(
            (category) => category.category_id === field.category_id,
        );
        const categories = state.fieldsOrderedByCategory;
        categories[category_index].data = categories[category_index].data.filter(
            (currField: { id: string }) => currField.id !== field.id,
        );

        const availableFields = state.availableFields;
        availableFields.push(field);

        setState({
            availableFields: [...availableFields],
            fieldsOrderedByCategory: [...categories],
        });
    }

    return (
        <ChangeTemplateContainer>
            <ChangeTemplateFormContainer>
                <div className="flex justify-start flex-1 gap-5">
                    <div className="w-[320px]">
                        <h2 className="text-4xl mb-4">Categories</h2>
                        <Card className="p-4">
                            <CardContent className="text-xl list-none">
                                {state.fieldCategories.map((category) => (
                                    <li key={category.category_id}>{category.category_name}</li>
                                ))}
                            </CardContent>
                        </Card>
                    </div>

                    <div className="w-[700px]">
                        <div>
                            <div className="text-4xl mb-4 flex justify-between items-center">
                                <span>Fields</span>
                                <Button onClick={handleSave}>Save</Button>
                            </div>
                            <div>
                                {state.fieldsOrderedByCategory.map((category) => (
                                    <Card key={category.category_id}>
                                        <CardTitle
                                            className="text-xl flex justify-between p-4"
                                            onClick={() => {
                                                setState({
                                                    activeCategoryId: category.category_id,
                                                });
                                            }}
                                        >
                                            {category.category_name}
                                            <AddRecapField
                                                fields={
                                                    state.availableFields.filter(
                                                        (field) =>
                                                            field.category_id ===
                                                            category.category_id,
                                                    ) ?? { fields: [] }
                                                }
                                                onSubmit={(fields: any) =>
                                                    handleAddFields(fields, category.category_id)
                                                }
                                            />
                                        </CardTitle>
                                        <CardContent className="overflow-auto">
                                            {category.data.map((field) => (
                                                <li
                                                    className="items-center flex my-2 justify-between list-none"
                                                    key={field.id}
                                                >
                                                    {field.name}{" "}
                                                    <DeleteRecapField
                                                        onSubmit={() => handleRemoveField(field)}
                                                    />
                                                </li>
                                            ))}
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </ChangeTemplateFormContainer>
        </ChangeTemplateContainer>
    );
}
