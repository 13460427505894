import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
    ApprovalStatusTypes,
    CaseFileStatusType,
    DocumentExportIconProps,
    DocumentLoadType,
    PermissionTypes,
} from "../types/case-files";
import { ColorSchemaInterface, ColorSchemas } from "../types";
import { RetryOptions } from "@reduxjs/toolkit/dist/query/retry";
import { Cloud, FilePen, Files, NotebookTabs, PenTool, ReceiptText } from "lucide-react";
import React from "react";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const RtkExtraConfig: RetryOptions = {
    retryCondition: (error, args, { attempt }) =>
        attempt <= 5 && [502, 503, 504].includes(parseInt(error.status.toString())),
};

export function searchInObjectKeys<Generic>(
    items: Array<Generic>,
    query?: string,
    keys?: Array<keyof Generic>,
) {
    if (!query) {
        return items;
    }

    if (!keys) {
        return items.filter((o) =>
            Object.keys(o as object).some((k: string) =>
                String(o[k as keyof Generic])
                    .toLowerCase()
                    .includes(query.toLowerCase()),
            ),
        );
    }

    return items.filter((o) =>
        keys.some((k) => String(o[k]).toLowerCase().includes(query.toLowerCase())),
    );
}

export function formatDocumentType(type: DocumentLoadType, fileType?: string): string {
    switch (type) {
        case "bill_of_lading":
            return "Bill of lading";
        case "house_form":
            return "House form";
        case "riders":
            return "Riders";
        case "addenda":
            return "Addenda";
        case "recap":
            return "Recap";
        case "standard_form":
            return "Standard form";
        case "file_upload":
            return fileType ?? "Uploaded file";
        case "q88":
            return "Q88";
        case "other":
        default:
            return "Other";
    }
}

export function formatPermissionSet(set: PermissionTypes): string {
    switch (set) {
        case "full_access":
            return "Unrestricted access";
        case "read_only_extended":
            return "Read only plus";
        case "read_only":
            return "Read only";
    }
}

export function formatApprovalStatus(status: ApprovalStatusTypes): string {
    switch (status) {
        case "approved":
            return "Approved";
        case "denied":
            return "Rejected";
        case "pending":
            return "Pending";
    }
}

export function formatCaseFileStatus(status: CaseFileStatusType): string {
    switch (status) {
        case "cancelled":
            return "Cancelled";
        case "clean_fixed":
            return "Clean fixed";
        case "draft":
            return "Draft";
        case "fully_approved":
            return "Fully approved";
        case "on_subs":
            return "On subs";
        case "under_approval":
            return "Under approval";
        case "approval_rejected":
            return "Approval rejected";
    }
}

export function formatCaseFileStatusColor(status: CaseFileStatusType) {
    switch (status) {
        case "draft":
            return "pending";
        case "cancelled":
        case "approval_rejected":
            return "failed";
        case "clean_fixed":
        case "fully_approved":
            return "success";
        case "on_subs":
        case "under_approval":
            return "primary";
    }
}

export function getColors(schema: ColorSchemas): ColorSchemaInterface {
    switch (schema) {
        case "traditional":
            return {
                addition: "#3E8EBF",
                deletion: "#B8363E",
            };
        case "black":
            return {
                addition: "#000000",
                deletion: "#000000",
            };
        default:
            return {
                addition: "#34A853",
                deletion: "#FF0000",
            };
    }
}

export function DocumentExportIcon({ item, ...props }: DocumentExportIconProps): JSX.Element {
    switch (item.type) {
        case "recap":
            return <FilePen {...props} />;
        case "riders":
            return <NotebookTabs {...props} />;
        case "addenda":
            return <ReceiptText {...props} />;
        case "standard_form":
            return <PenTool {...props} />;
        case "q88":
            return (
                <svg
                    className={"fill-white w-5"}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 2"
                    viewBox="0 0 208.72 200.11"
                >
                    <path
                        d="M173.67 140.03c-13.56 22.94-38.83 38.36-67.78 38.36-43.26 0-78.33-34.45-78.33-76.94s35.07-76.94 78.33-76.94c29.38 0 54.99 15.89 68.38 39.41-11.58-15.35-30.05-25.29-50.86-25.29-35.06 0-63.48 28.19-63.48 62.96s28.42 62.95 63.48 62.95c20.46 0 38.66-9.6 50.26-24.51Z"
                        className="cls-1"
                    />
                    <path
                        d="M181.23 101.45c0 31.68-25.68 57.36-57.36 57.36-2.17 0-4.31-.12-6.41-.36 28.66-3.19 50.94-27.49 50.94-57s-22.28-53.81-50.94-57c2.1-.24 4.24-.36 6.41-.36 31.68 0 57.36 25.68 57.36 57.36Z"
                        className="cls-2"
                    />
                    <path
                        d="M162.35 101.45c0 26.7-21.65 48.34-48.35 48.34-17.09 0-32.11-8.87-40.7-22.25 7.24 8.51 18.03 13.91 30.08 13.91 21.81 0 39.49-17.68 39.49-39.49s-17.68-39.49-39.49-39.49c-13.43 0-25.29 6.7-32.41 16.94 8.01-15.62 24.28-26.3 43.03-26.3 26.7 0 48.35 21.64 48.35 48.34Z"
                        className="cls-1"
                    />
                    <path
                        d="M107.63 136.57c-1.81.28-3.67.43-5.56.43-19.69 0-35.65-15.96-35.65-35.65s15.96-35.64 35.65-35.64c2.18 0 4.32.2 6.39.58-18.56.98-33.31 16.35-33.31 35.16s14.31 33.72 32.48 35.12Z"
                        className="cls-2"
                    />
                </svg>
            );
        case "file_upload":
        case "other":
            return <Cloud {...props} />;
        default:
            return <Files {...props} />;
    }
}

export function scrollToCenterInsideParent(focusNode: HTMLElement, focusParent: HTMLElement) {
    const elementRect = focusNode.getBoundingClientRect();
    const parentRect = focusParent.getBoundingClientRect();
    const offset = parentRect.height / 2;

    // Calculate the position to scroll within the scrollable parent
    const scrollToPosition = elementRect.top - parentRect.top + focusParent.scrollTop - offset;
    const offsetTop = scrollToPosition >= 0 ? scrollToPosition : 0;

    // Scroll the scrollable parent element
    focusParent.scrollTo({
        top: offsetTop,
        behavior: "smooth",
    });
}

export function scrollToOffsetInsideParent(
    focusNode: HTMLElement,
    focusParent: HTMLElement,
    offset: number,
) {
    const elementRect = focusNode.getBoundingClientRect();
    const parentRect = focusParent.getBoundingClientRect();

    // Calculate the position to scroll within the scrollable parent
    const scrollToPosition = elementRect.top - parentRect.top + focusParent.scrollTop - offset;

    const offsetTop = scrollToPosition >= 0 ? scrollToPosition : 0;

    // Scroll the scrollable parent element
    focusParent.scrollTo({
        top: offsetTop,
        behavior: "smooth",
    });
}
