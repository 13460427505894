import { Input } from "../ui/input";
import React, { ForwardedRef, useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { searchInObjectKeys } from "../../lib/utils";

type SearchObjectProps<Generic> = {
    type: "text";
    onResults: (items: Array<Generic>) => void;
    items: Array<Generic>;
    keys?: Array<keyof Generic>;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
};

function SearchObjectComp<Generic>(
    props: SearchObjectProps<Generic>,
    ref: ForwardedRef<HTMLInputElement | null>,
) {
    const { type, onResults, items, keys, className, placeholder } = props,
        [result, setResult] = useState<Array<Generic>>(items),
        [search, setSearch] = useState<string>(),
        debouncedSearch = useDebounce(search, 300);

    useEffect(() => {
        setResult(searchInObjectKeys<Generic>(items, debouncedSearch, keys));
    }, [debouncedSearch]);

    useEffect(() => {
        onResults(result);
    }, [result]);

    return (
        <Input
            type={type}
            placeholder={placeholder ?? "search..."}
            className={className}
            onChange={(e) => setSearch(e.target.value)}
            ref={ref}
            disabled={props.disabled}
        />
    );
}

export const SearchObject = React.forwardRef(SearchObjectComp) as <Generic>(
    props: SearchObjectProps<Generic> & { ref?: React.ForwardedRef<HTMLInputElement | null> },
) => ReturnType<typeof SearchObjectComp>;
