import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { format } from "date-fns";
import { TableDropDownItems } from "../types/table";
import { Edit } from "lucide-react";
import { AcceptAction } from "../Central/Pages/Tenants/Modals/AcceptAction";
import { DenyAction } from "../Central/Pages/Tenants/Modals/DenyAction";
import { ShowAction } from "../Central/Pages/Tenants/Modals/ShowAction";
import { HideAction } from "../Central/Pages/Tenants/Modals/HideAction";
import { TenantAdminInterface } from "../types/Central";

const columns: ColumnDef<TenantAdminInterface>[] = [
    {
        accessorKey: "id",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Id" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Id",
        },
    },
    {
        accessorKey: "created_at",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Date (CEST)" type="date-range" />;
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Date (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("created_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        accessorKey: "approval_status",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Approval Status" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Approval Status",
        },
    },
    {
        accessorKey: "email_verified",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Email Verified" type="boolean" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Email Verified",
        },
    },
    {
        accessorKey: "total_case_file",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Total Case Files" type="sort" />;
        },
        meta: {
            title: "Total Case Files",
        },
    },
    {
        accessorKey: "total_documents",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Total Documents" type="sort" />;
        },
        meta: {
            title: "Total Documents",
        },
    },
    {
        accessorKey: "total_final_documents",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="Total Final Documents" type="sort" />
            );
        },
        meta: {
            title: "Total Final Documents",
        },
    },
    {
        accessorKey: "total_revenue",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Total revenue" type="sort" />;
        },
        meta: {
            title: "Total revenue",
        },
        cell: ({ row }) => {
            const value = row.getValue("total_revenue") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
    {
        accessorKey: "total_service_revenue",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader column={column} title="Total Service revenue" type="sort" />
            );
        },
        meta: {
            title: "Total Service revenue",
        },
        cell: ({ row }) => {
            const value = row.getValue("total_service_revenue") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
    {
        accessorKey: "total_copyright_revenue",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Total Copyright revenue"
                    type="sort"
                />
            );
        },
        meta: {
            title: "Total Copyright revenue",
        },
        cell: ({ row }) => {
            const value = row.getValue("total_copyright_revenue") as number;
            const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
            });
            return <span>{formatter.format(value / 100)}</span>;
        },
    },
    {
        accessorKey: "hidden",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Hidden" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Hidden",
        },
    },
];

function tableDropDown(): TableDropDownItems<TenantAdminInterface> {
    return {
        label: "Actions",
        groups: [
            {
                actions: [
                    {
                        label: "Accept",
                        icon: <Edit className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <AcceptAction row={row} onClose={onClose} />,
                        elementType: "dialog",
                    },
                    {
                        label: "Deny",
                        icon: <Edit className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <DenyAction row={row} onClose={onClose} />,
                        elementType: "dialog",
                    },
                    {
                        label: "Show",
                        icon: <Edit className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <ShowAction row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) => !row.hidden,
                    },
                    {
                        label: "Hide",
                        icon: <Edit className="mr-2 h-4 w-4" />,
                        element: (row, onClose) => <HideAction row={row} onClose={onClose} />,
                        elementType: "dialog",
                        hide: (row) => row.hidden,
                    },
                ],
            },
        ],
    };
}

export { columns, tableDropDown };
