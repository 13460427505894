import { Dialog, DialogContent, DialogFooter, DialogTitle } from "../../components/ui/dialog";
import React, { Fragment, useState } from "react";
import { ProcessInvoiceProps } from "../../types/billing";
import { Button } from "../../components/ui/button";
import { useProcessInvoiceMutation } from "../../api/tenant/billing";
import { saveAs } from "file-saver";
import { useLazyDownloadBlobQuery } from "../../api/cookieApi";
import Loading from "../../components/global/loading";

function ProcessInvoice({ open, onClose, invoice }: ProcessInvoiceProps) {
    const [processInvoice] = useProcessInvoiceMutation();
    const [downloadBlob] = useLazyDownloadBlobQuery();
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit() {
        setLoading(true);
        const downloadUrl = await processInvoice(invoice).unwrap();
        const blob = await downloadBlob(downloadUrl).unwrap();
        const fileName = "Ocean Recap invoice" + invoice.number + ".pdf";

        saveAs(blob, fileName);
        onClose();
        setLoading(false);
    }

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <DialogTitle>Process invoice and download pdf</DialogTitle>
                            <DialogFooter className="mt-4">
                                <Button type="submit" onClick={handleSubmit}>
                                    Process
                                </Button>
                            </DialogFooter>
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}
export default ProcessInvoice;
