import { Button } from "../../components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import React, { Fragment, useRef, useState } from "react";
import { useCaseFiles } from "../../lib/providers/CaseFilesProvider";
import AddFile from "./add-file";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { Typography } from "../../components/ui/typography";
import { Badge } from "../../components/ui/badge";
import {
    useRemoveRotApprovalMutation,
    useToggleFollowCaseFileMutation,
    useUpdateApprovalStatusMutation,
    useUpdateCaseFileStatusMutation,
} from "../../api/tenant/case-file";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog";
import { RocketIcon, Settings } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { useAuth } from "../../lib/providers/AuthProvider";
import { ApprovalStatusTypes, CaseFileStatusType } from "../../types/case-files";
import MultiExport from "./multi-export";
import { Textarea } from "../../components/ui/textarea";
import { Label } from "../../components/ui/label";
import EditStatusDocument from "./detail/edit-status-document";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../components/ui/tooltip";

function Actions() {
    const {
        case_file_id,
        is_owner,
        approval,
        approvals,
        original_cf_id,
        meta_data,
        permissions,
        update_meta_date,
    } = useCaseFiles();
    const [updateStatus] = useUpdateCaseFileStatusMutation();
    const [removeRotApproval] = useRemoveRotApprovalMutation();
    const [updateApproval] = useUpdateApprovalStatusMutation();
    const user = useAuth().state.user;
    const [toggleFollow] = useToggleFollowCaseFileMutation();
    const rejectionNoteRef = useRef<HTMLTextAreaElement | null>(null);
    const statusTypes: Array<CaseFileStatusType> = ["draft", "cancelled", "clean_fixed", "on_subs"];
    const [status, setStatus] = useState<CaseFileStatusType>(meta_data?.status ?? "draft");

    function voidApprovals() {
        removeRotApproval({
            approval_id: "",
            case_file_id: original_cf_id,
            amount_of_approvals: 0,
            supply_chain_id: "",
        });
    }

    function updateApprovalStatus(id: string, status: ApprovalStatusTypes) {
        updateApproval({
            id: id,
            type: status,
            note: rejectionNoteRef.current?.value ?? undefined,
            case_file_id: case_file_id,
        });
    }

    async function handleSubmit(value: CaseFileStatusType) {
        await updateStatus({
            case_file_id: case_file_id,
            status: value,
        });
        setStatus(value);

        if (meta_data) {
            update_meta_date({ ...meta_data, status: value });
        }
    }

    return (
        <div className="flex items-center">
            {approval && (
                <Popover>
                    <PopoverTrigger asChild>
                        <Button className="mr-4">Approvals</Button>
                    </PopoverTrigger>
                    <PopoverContent align="end">
                        {approvals?.map((approval, index) => (
                            <div
                                className="py-4 flex items-center flex-1 justify-between"
                                key={index}
                            >
                                <div>
                                    <Typography
                                        text={
                                            <Fragment>
                                                <b>Name: </b> {approval.company_name}
                                            </Fragment>
                                        }
                                        style="p"
                                    />
                                </div>
                                <Badge
                                    variant={
                                        approval.status === "approved"
                                            ? "success"
                                            : approval.status === "pending"
                                              ? "primary"
                                              : "failed"
                                    }
                                    className="h-6 ml-8"
                                >
                                    {approval.status}
                                </Badge>
                            </div>
                        ))}
                        {!is_owner &&
                            approvals?.find((x) => x.tenant_id === user?.tenant_id)?.status ===
                                "pending" && (
                                <div className="w-full flex justify-between">
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button>Approve</Button>
                                        </DialogTrigger>
                                        <DialogContent>
                                            <DialogTitle>Approve this Recap/CP</DialogTitle>
                                            <DialogFooter>
                                                <DialogClose>
                                                    <Button
                                                        type="submit"
                                                        onClick={() =>
                                                            updateApprovalStatus(
                                                                approvals?.find(
                                                                    (x) =>
                                                                        x.tenant_id ===
                                                                        user?.tenant_id,
                                                                )?.id ?? "",
                                                                "approved",
                                                            )
                                                        }
                                                    >
                                                        Approve
                                                    </Button>
                                                </DialogClose>
                                            </DialogFooter>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button variant="destructive">Reject</Button>
                                        </DialogTrigger>
                                        <DialogContent>
                                            <DialogTitle>Reject this Recap/CP</DialogTitle>
                                            <DialogDescription>
                                                You are about to withold your approval for this
                                                Recap C/P. Please state why you are withholding your
                                                approval below.
                                            </DialogDescription>

                                            <div className="grid w-full gap-1.5">
                                                <Label htmlFor="note">
                                                    Please state the reason for rejecting
                                                </Label>
                                                <Textarea
                                                    placeholder="Please state the reason for rejecting"
                                                    id="note"
                                                    ref={rejectionNoteRef}
                                                />
                                            </div>
                                            <DialogFooter>
                                                <DialogClose>
                                                    <Button
                                                        type="submit"
                                                        onClick={() =>
                                                            updateApprovalStatus(
                                                                approvals?.find(
                                                                    (x) =>
                                                                        x.tenant_id ===
                                                                        user?.tenant_id,
                                                                )?.id ?? "",
                                                                "denied",
                                                            )
                                                        }
                                                    >
                                                        Reject
                                                    </Button>
                                                </DialogClose>
                                            </DialogFooter>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            )}
                        {is_owner && (
                            <Dialog>
                                <DialogTrigger asChild>
                                    <Button variant="destructive" className="w-full">
                                        Void approval process
                                    </Button>
                                </DialogTrigger>
                                <DialogContent>
                                    <Alert className="mb-8 border-solid border-2 border-red-500">
                                        <RocketIcon className="h-4 w-4 fill-slate-950" />
                                        <AlertTitle className="text-slate-950">
                                            Heads up!
                                        </AlertTitle>
                                        <AlertDescription className="text-slate-950">
                                            Are you sure you want to reset the approval process for
                                            this case file?
                                        </AlertDescription>
                                    </Alert>
                                    <DialogFooter>
                                        <DialogClose>
                                            <Button
                                                type="submit"
                                                onClick={voidApprovals}
                                                variant="destructive"
                                            >
                                                Void
                                            </Button>
                                        </DialogClose>
                                    </DialogFooter>
                                </DialogContent>
                            </Dialog>
                        )}
                    </PopoverContent>
                </Popover>
            )}
            <MultiExport />
            <DropdownMenu>
                <DropdownMenuTrigger asChild disabled={!permissions.edit_documents}>
                    <div>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="mr-4"
                                    disabled={!permissions.edit_documents}
                                >
                                    <Settings className={"w-4"} />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent side="bottom">Settings</TooltipContent>
                        </Tooltip>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger>
                            <span className="font-medium">Edit status</span>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                                {statusTypes.map((statusValue, index) => (
                                    <DropdownMenuItem className="justify-between" key={index}>
                                        <EditStatusDocument
                                            status={statusValue}
                                            isSelected={statusValue === status}
                                            onSubmit={handleSubmit}
                                        />
                                    </DropdownMenuItem>
                                ))}
                            </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                    </DropdownMenuSub>
                    <DropdownMenuItem
                        onSelect={() => {
                            toggleFollow({ case_file_id: case_file_id, user_id: user?.id ?? "" });
                            if (meta_data) {
                                update_meta_date({
                                    ...meta_data,
                                    is_following: !meta_data.is_following,
                                });
                            }
                        }}
                        disabled={!is_owner}
                    >
                        {meta_data?.is_following ? "Un-follow" : "Follow"}
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <AddFile id={case_file_id} />
        </div>
    );
}

export default Actions;
