import React, { useLayoutEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";

export interface Admin {
    email: string;
    password: string;
}

export async function adminLogin(user: Admin): Promise<boolean | string> {
    return await axios
        .post(
            "/admin/api/v1/login",
            {
                email: user.email,
                password: user.password,
            },
            {
                method: "POST",
                headers: {
                    // perhaps do some funky verification stuff
                },
            },
        )
        .then((res) => {
            return Boolean(res.data);
        })
        .catch((e) => e.response.data.message);
}

export async function fetchAdmin(): Promise<any> {
    return await axios.get("/admin/api/v1/user").then((res) => {
        return res;
    });
}

export async function adminLogout(): Promise<any> {
    return await axios.get("/admin/api/v1/logout").then((res) => {
        return res;
    });
}

export interface AdminAuthContextType {
    user: any;
    signIn: (user: Admin, callback: (value: boolean | string) => void) => void;
    signOut: (callback: VoidFunction) => void;
    //impersonate?: (user: User, callback: VoidFunction) => void;
}

const AdminAuthContext = React.createContext<AdminAuthContextType>(null!);

export function useAdmin() {
    return React.useContext(AdminAuthContext);
}

const apiAdminAuthProvider = {
    isAuthenticated: false,
    signIn(admin: Admin, callback: (value: string | boolean) => void) {
        adminLogin(admin)
            .then((response) => {
                if (typeof response === "boolean") {
                    apiAdminAuthProvider.isAuthenticated = response;
                }
                setTimeout(() => callback(response), 100);
            })
            .catch((e) => {
                callback(e.response);
            });
    },
    signOut(callback: VoidFunction) {
        apiAdminAuthProvider.isAuthenticated = false;
        setTimeout(callback, 100);
    },
};

function AdminAuthProvider({ children }: { children: React.ReactNode }) {
    const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
    const [user, setUser] = useState<Admin | undefined>(undefined);
    const location = useLocation();

    const reload = () => {
        fetchAdmin()
            .then((response) => {
                setUser(response.data);
            })
            .then(() => setFirstAttemptFinished(true));
    };

    useLayoutEffect(() => {
        if (!firstAttemptFinished && location.pathname !== "/submarine") {
            reload();
        }
    }, [firstAttemptFinished]);

    const signIn = (user: Admin, callback: (value: boolean | string) => void) => {
        return apiAdminAuthProvider.signIn(user, (value) => {
            fetchAdmin()
                .then((response) => {
                    setUser(response.data);
                    callback(value);
                })
                .catch(() => {
                    callback(value);
                });
        });
    };

    const signOut = (callback: VoidFunction) => {
        return apiAdminAuthProvider.signOut(() => {
            setUser(undefined);
            adminLogout().then(() => {
                callback();
            });
        });
    };

    const value = { user, signIn, signOut };

    return <AdminAuthContext.Provider value={value}>{children}</AdminAuthContext.Provider>;
}

export default AdminAuthProvider;
