import { useNavigate, useParams } from "react-router";
import NewDocument from "./new-document";
import { Button } from "../../components/ui/button";
import {
    FilePen,
    Files,
    FileStack,
    Footprints,
    NotebookTabs,
    PenTool,
    ReceiptText,
} from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { useCaseFiles } from "../../lib/providers/CaseFilesProvider";

function Tabs() {
    const { activePage, documents, original_cf_id, case_file_id, permissions } = useCaseFiles(),
        [newDocumentModal, setNewDocumentModal] = useState<boolean>(false),
        navigate = useNavigate(),
        charters = documents.filter((x) => x.template_type === "standard_form"),
        otherDocuments = documents.filter(
            (x) =>
                x.template_type === "bill_of_lading" ||
                x.template_type === "sof" ||
                x.template_type === "nor" ||
                x.template_type === "loi",
        ),
        { document_id } = useParams<{ document_id: string }>();

    const no_active_charters = charters.filter((document) => document.id !== document_id);
    const active_document = charters.find((document) => document.id === document_id);

    let documents_page_title;

    if (activePage === "document" && active_document) {
        documents_page_title = active_document.document_name;
    } else if (charters.length === 1) {
        documents_page_title = charters[0].document_name;
    } else {
        documents_page_title = "Standard forms";
    }

    return (
        <div className="flex gap-1">
            <Button
                variant="ghost"
                data-active={activePage === "overview"}
                className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                onClick={() => navigate(`/case-file/${original_cf_id}`)}
            >
                <Files size="16" className="mr-2" /> Documents
            </Button>
            <Button
                variant="ghost"
                data-active={activePage === "recap"}
                className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                onClick={() => navigate(`/case-file/${original_cf_id}/recap`)}
            >
                <FilePen size="16" className="mr-2" /> Recap
            </Button>
            {charters.length === 1 ? (
                <Button
                    variant="ghost"
                    data-active={activePage === "document"}
                    className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                    onClick={() => navigate(`/case-file/${original_cf_id}/${charters[0].id}`)}
                >
                    <PenTool size="16" className="mr-2" />
                    <span className="truncate max-w-32">{documents_page_title}</span>
                </Button>
            ) : charters.length > 1 ? (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="ghost"
                            data-active={activePage === "document"}
                            className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                        >
                            <PenTool size="16" className="mr-2" />
                            {documents_page_title}
                            <ChevronDownIcon className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-1" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-w-80">
                        {no_active_charters.map((charter, index) => (
                            <DropdownMenuItem
                                key={index}
                                onClick={() =>
                                    navigate(`/case-file/${original_cf_id}/${charter.id}`)
                                }
                                truncate
                            >
                                {charter.document_name}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            ) : (
                <Button
                    variant="ghost"
                    disabled={!permissions.create_documents}
                    onClick={() => setNewDocumentModal(true)}
                    className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                    data-active={activePage === "document"}
                >
                    <PenTool size="16" className="mr-2" />
                    Create a standard form
                </Button>
            )}
            {newDocumentModal && (
                <NewDocument
                    id={case_file_id}
                    onClose={() => setNewDocumentModal(false)}
                    isFirstCharterParty={true}
                    isOpen={newDocumentModal}
                />
            )}
            <Button
                variant="ghost"
                data-active={activePage === "riders"}
                className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                onClick={() => navigate(`/case-file/${original_cf_id}/riders`)}
            >
                <NotebookTabs size="16" className="mr-2" /> Riders
            </Button>
            <Button
                variant="ghost"
                data-active={activePage === "addenda"}
                className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                onClick={() => navigate(`/case-file/${original_cf_id}/addenda`)}
            >
                <ReceiptText size="16" className="mr-2" /> Addenda
            </Button>
            <Button
                variant="ghost"
                data-active={activePage === "audit-trail"}
                className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                onClick={() => navigate(`/case-file/${original_cf_id}/audit-trail`)}
            >
                <Footprints size="16" className="mr-2" /> Audit trail
            </Button>
            {otherDocuments.length === 1 ? (
                <Button
                    variant="ghost"
                    data-active={activePage === "other-documents"}
                    className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                    onClick={() => navigate(`/case-file/${original_cf_id}/${otherDocuments[0].id}`)}
                >
                    <FileStack size="16" className="mr-2" />
                    <span className="truncate max-w-32">{otherDocuments[0].document_name}</span>
                </Button>
            ) : otherDocuments.length > 1 ? (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="ghost"
                            data-active={activePage === "other-documents"}
                            className="data-[active=true]:bg-accent data-[active=true]:text-accent-foreground"
                        >
                            <FileStack size="16" className="mr-2" />
                            Other documents
                            <ChevronDownIcon className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-1" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-w-80">
                        {otherDocuments.map((doc, index) => (
                            <DropdownMenuItem
                                key={index}
                                onClick={() => navigate(`/case-file/${original_cf_id}/${doc.id}`)}
                                truncate
                            >
                                {doc.document_name}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            ) : null}
        </div>
    );
}

export default Tabs;
