import { TableActionProps } from "../../../types/table";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateCaseFileMutation } from "../../../api/tenant/case-file";
import { useNavigate } from "react-router";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "sonner";
import {
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { Button } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../../../components/ui/calendar";
import { CaseFileIndex } from "../../../types/case-files";
import Loading from "../../../components/global/loading";
import { Textarea } from "../../../components/ui/textarea";

function CaseFileEditDetails({ row, onClose }: TableActionProps<CaseFileIndex>) {
    const schema = z.object({
        document_name: z.string().optional(),
        vessel_name: z.string().optional(),
        load_port: z.string().optional(),
        discharge_port: z.string().optional(),
        charterer: z.string().optional(),
        broker: z.string().optional(),
        owner: z.string().optional(),
        cp_date: z.date().optional(),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            document_name: row.document_name ?? "",
            load_port: row.load_port ?? "",
            discharge_port: row.discharge_port ?? "",
            charterer: row.charterer ?? "",
            broker: row.broker ?? "",
            owner: row.owner ?? "",
            vessel_name: row.vessel_name ?? "",
            cp_date: row.cp_date ? new Date(row.cp_date) : undefined,
        },
    });
    const [updateCaseFile] = useUpdateCaseFileMutation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            onClose();
        };
    }, []);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        try {
            await updateCaseFile({
                id: row.id,
                cp_date: String(values?.cp_date),
                ...values,
            });

            toast.success("Recap / CP updated", {
                action: {
                    label: "View",
                    onClick: () => navigate(`/case-file/${row.id}`),
                },
            });

            onClose();
        } catch (e) {
            toast.error("Something went wrong while updating Recap / CP");
        }

        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Edit {row.document_name}</DialogTitle>
                        <DialogDescription>
                            Make changes to this recap/cp. Click save when you&apos;re done.
                        </DialogDescription>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)}>
                            <FormField
                                control={form.control}
                                name="document_name"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Document name</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="cp_date"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">CP date</FormLabel>
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <FormControl>
                                                    <Button
                                                        variant={"outline"}
                                                        className={cn(
                                                            "pl-3 text-left font-normal flex h-9 w-full",
                                                            !field.value && "text-muted-foreground",
                                                        )}
                                                    >
                                                        {field.value ? (
                                                            format(field.value, "dd/MM/yyyy")
                                                        ) : (
                                                            <span>Pick a cp date</span>
                                                        )}
                                                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                    </Button>
                                                </FormControl>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0">
                                                <Calendar
                                                    mode="single"
                                                    selected={new Date(field.value ?? new Date())}
                                                    onSelect={(e) => {
                                                        form.setValue("cp_date", e);
                                                    }}
                                                    defaultMonth={
                                                        row?.cp_date
                                                            ? new Date(row.cp_date)
                                                            : new Date()
                                                    }
                                                    initialFocus
                                                />
                                            </PopoverContent>
                                        </Popover>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="vessel_name"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Vessel name</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="load_port"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Load port</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="discharge_port"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Discharge port</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="charterer"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Charterer</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="broker"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Broker</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="owner"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>Owner</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            {form.formState.isValid && (
                                <DialogFooter className="mt-4">
                                    <Button type="submit">Update</Button>
                                </DialogFooter>
                            )}
                        </form>
                    </Form>
                </Fragment>
            }
        />
    );
}

export default CaseFileEditDetails;
