import { NewDocumentProps } from "../../types/case-files";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../components/ui/dialog";
import React, { Fragment, useEffect, useState } from "react";
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "../../components/ui/input-otp";
import { REGEXP_ONLY_CHARS } from "input-otp";
import {
    useCreateProFormaDocumentMutation,
    useLazyGetProFormaValidationQuery,
} from "../../api/tenant/pro-forma";
import { ProFormaValidationDetails } from "../../types/pro-forma";
import { toast } from "sonner";
import { format } from "date-fns";
import { Button } from "../../components/ui/button";
import { useCaseFiles } from "../../lib/providers/CaseFilesProvider";
import SimpleList from "../../components/simple-list";
import Loading from "../../components/global/loading";

function ProFormaCode({ onClose, isOpen }: NewDocumentProps) {
    const [value, setValue] = useState<string>();
    const [validateProForma, { isLoading }] = useLazyGetProFormaValidationQuery();
    const [proFormaInfo, setProFormaInfo] = useState<ProFormaValidationDetails>();
    const [createProFormaDocument] = useCreateProFormaDocumentMutation();
    const { case_file_id } = useCaseFiles();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        window.document.addEventListener("paste", handlePaste);

        return () => {
            window.document.removeEventListener("paste", handlePaste);
        };
    }, []);

    function handlePaste(e: ClipboardEvent) {
        const paste = e.clipboardData?.getData("text") ?? "";

        setValue(paste.slice(0, 8));
    }

    useEffect(() => {
        if (value?.length === 8 && !isLoading && !proFormaInfo) {
            validateInput(value);
        }
    }, [value]);

    async function validateInput(code: string) {
        setLoading(true);
        try {
            const validation = await validateProForma(code).unwrap();

            if (typeof validation === "string") {
                onClose();
                toast.error("Error while validating share code");
            } else {
                setProFormaInfo(validation as ProFormaValidationDetails);
            }
        } catch (e) {
            onClose();
            toast.error("Error while validating share code");
        }

        setLoading(false);
    }

    function importProForma(code: string) {
        setLoading(true);
        try {
            createProFormaDocument({
                code: code,
                case_file_id: case_file_id,
            });

            toast.message("Successfully created pro forma");

            onClose();
        } catch (e) {
            toast.message("Something went wrong while creating pro forma");
        }

        setLoading(false);
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <DialogHeader>
                                <DialogTitle>Import a document using pro forma code</DialogTitle>
                            </DialogHeader>
                            {(value?.length ?? 0) === 8 && !isLoading && proFormaInfo ? (
                                <Fragment>
                                    <SimpleList
                                        title="Details"
                                        items={[
                                            {
                                                title: "Document name",
                                                content: proFormaInfo.document_name,
                                            },
                                            {
                                                title: "Document type",
                                                content: proFormaInfo.document_type,
                                            },
                                            {
                                                title: "Document template",
                                                content: proFormaInfo.template_name,
                                            },
                                            {
                                                title: "Imported from",
                                                content: proFormaInfo.imported_from,
                                            },
                                            {
                                                title: "Valid until",
                                                content: format(proFormaInfo.valid_until, "PPP"),
                                            },
                                        ]}
                                    />
                                    <DialogFooter>
                                        <Button
                                            type="submit"
                                            onClick={() => importProForma(value ?? "")}
                                        >
                                            Import
                                        </Button>
                                    </DialogFooter>
                                </Fragment>
                            ) : (
                                <InputOTP
                                    maxLength={8}
                                    value={value}
                                    onChange={(value) => setValue(value)}
                                    pattern={REGEXP_ONLY_CHARS}
                                    containerClassName="justify-between"
                                >
                                    <InputOTPGroup>
                                        <InputOTPSlot index={0} />
                                        <InputOTPSlot index={1} />
                                    </InputOTPGroup>
                                    <InputOTPSeparator />
                                    <InputOTPGroup>
                                        <InputOTPSlot index={2} />
                                        <InputOTPSlot index={3} />
                                    </InputOTPGroup>
                                    <InputOTPSeparator />
                                    <InputOTPGroup>
                                        <InputOTPSlot index={4} />
                                        <InputOTPSlot index={5} />
                                    </InputOTPGroup>
                                    <InputOTPSeparator />
                                    <InputOTPGroup>
                                        <InputOTPSlot index={6} />
                                        <InputOTPSlot index={7} />
                                    </InputOTPGroup>
                                </InputOTP>
                            )}
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

export default ProFormaCode;
