import React, { Fragment, useRef } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FroalaEditor from "react-froala-wysiwyg";
import { updateTemplate } from "../../../../Admin/Templates";
import {
    FormButtonGroupContainer,
    NewTemplateEditorContainer,
    NewTemplateSettingsContainer,
    TemplateContainer,
    TemplateWrapperContainer,
} from "../../Style";
import { Modals } from "../../../../../components/style-components/Modals";
import {
    Bar,
    CancelButton,
    Input,
    InputGroup,
    Label,
    StyledInlineErrorMessage,
    SubmitButton,
    TextArea,
} from "../../../../Form/FormField/style";
import {
    CheckBox,
    CheckBoxLabel,
    CheckBoxWrapper,
    Selection,
} from "../../../../Form/Documents/Style";
import { ColorSchemas } from "../../../../../types";
import {
    EditTemplateFormInterface,
    EditTemplateItem,
    EditTemplateModalInterface,
} from "../../../../../types/Central";

export function EditTemplateModal({ open, onClose, template }: EditTemplateModalInterface) {
    return (
        <Modals
            head={<h1>Edit: {template.name}</h1>}
            body={<EditTemplateForm template={template} onClose={onClose} />}
            open={open}
            closeOnClick={false}
            onClose={onClose}
            size="full-screen"
            closeOnEscapeDisabled={true}
        />
    );
}

function EditTemplateForm({ template, onClose }: EditTemplateFormInterface) {
    const froalaConfig = {
        key: process.env.FROALA_KEY,
        charCounterCount: false,
        documentReady: true,
        heightMin: 300,
        attribution: false,
        toolbarSticky: false,
        toolbarButtons: {
            moreText: {
                buttons: [
                    "bold",
                    "italic",
                    "underline",
                    "strikeThrough",
                    "subscript",
                    "superscript",
                    "fontFamily",
                    "fontSize",
                    "textColor",
                    "backgroundColor",
                    "inlineClass",
                    "inlineStyle",
                    "clearFormatting",
                ],
            },

            moreParagraph: {
                buttons: [
                    "alignLeft",
                    "alignCenter",
                    "formatOLSimple",
                    "alignRight",
                    "alignJustify",
                    "formatOL",
                    "formatUL",
                    "paragraphFormat",
                    "paragraphStyle",
                    "lineHeight",
                    "outdent",
                    "indent",
                    "quote",
                ],
            },

            moreRich: {
                buttons: [
                    "insertLink",
                    "insertImage",
                    "insertVideo",
                    "insertTable",
                    "emoticons",
                    "fontAwesome",
                    "specialCharacters",
                    "embedly",
                    "insertFile",
                    "insertHR",
                ],
            },

            moreMisc: {
                buttons: [
                    "undo",
                    "redo",
                    "fullscreen",
                    "print",
                    "getPDF",
                    "spellChecker",
                    "selectAll",
                    "html",
                    "help",
                ],

                align: "right",

                buttonsVisible: 2,
            },
        },
        quickInsertEnabled: false,
        events: {
            initialized: function () {
                onEditorInitialized(this);
            },
        },
    };

    const editorRef = useRef<any>(null);

    const onEditorInitialized = (editor: any) => {
        editorRef.current = editor;
    };

    const documentTypeOptions = [
        { value: "bill_of_lading", label: "Bill of Lading" },
        { value: "standard_form", label: "Standard Form" },
        { value: "fixture", label: "Fixture" },
        { value: "sof", label: "SOF" },
        { value: "nor", label: "NOR" },
        { value: "loi", label: "LOI" },
    ];

    const initialValues: EditTemplateItem = {
        id: template.id,
        active: template.active,
        created_at: template.created_at,
        updated_at: template.updated_at,
        template_type: template.template_type,
        name: template.name,
        content: template.content,
        footer: template.footer ?? "",
        stylesheet: template.stylesheet,
        thumbnail: template.thumbnail,
        service_fee: template.service_fee,
        copyright_fee: template.copyright_fee,
        multiple_pages: template.multiple_pages ?? false,
        landscape: template.landscape ?? false,
        file: undefined,
        is_smart: template.is_smart ?? false,
    };

    const validationSchema = Yup.object().shape({
        template_type: Yup.string().required().label("Template type"),
        name: Yup.string().required(),
        content: Yup.string().required(),
        stylesheet: Yup.string().optional().nullable(),
        footer: Yup.string().optional(),
        thumbnail: Yup.string().optional(),
        service_fee: Yup.number().required().min(0),
        copyright_fee: Yup.number().required().min(0),
    });

    function handleSubmit(values: EditTemplateItem) {
        updateTemplate(
            values,
            values.content
                .match(/data-recap-id="((?:\\.|[^"\\])*)" /gm)
                ?.map((m) => m.replace('data-recap-id="', "").replace('"', "")) ?? [],
        ).then(() => {
            onClose();
        });
    }

    return (
        <Fragment>
            <TemplateContainer>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ values, errors, touched, setFieldValue }) => {
                        return (
                            <Form>
                                <TemplateWrapperContainer>
                                    <NewTemplateSettingsContainer>
                                        <InputGroup>
                                            <CheckBoxWrapper>
                                                <span>Smart form:</span>
                                                <CheckBox
                                                    id="smart"
                                                    type="checkbox"
                                                    onClick={(e: any) =>
                                                        setFieldValue(
                                                            "is_smart",
                                                            e.target.checked === true ? 1 : 0,
                                                        )
                                                    }
                                                    defaultChecked={Boolean(template.is_smart)}
                                                />
                                                <CheckBoxLabel htmlFor="smart" />
                                            </CheckBoxWrapper>
                                        </InputGroup>
                                        <InputGroup>
                                            <CheckBoxWrapper>
                                                <span>Landscape:</span>
                                                <CheckBox
                                                    id="landscape"
                                                    type="checkbox"
                                                    onClick={(e: any) =>
                                                        setFieldValue(
                                                            "landscape",
                                                            e.target.checked === true ? 1 : 0,
                                                        )
                                                    }
                                                    defaultChecked={Boolean(template.landscape)}
                                                />
                                                <CheckBoxLabel htmlFor="landscape" />
                                            </CheckBoxWrapper>
                                        </InputGroup>
                                        <InputGroup>
                                            <CheckBoxWrapper>
                                                <span>Multiple pages:</span>
                                                <CheckBox
                                                    id="multiple_pages"
                                                    type="checkbox"
                                                    onClick={(e: any) =>
                                                        setFieldValue(
                                                            "multiple_pages",
                                                            e.target.checked === true ? 1 : 0,
                                                        )
                                                    }
                                                    defaultChecked={Boolean(
                                                        template.multiple_pages,
                                                    )}
                                                />
                                                <CheckBoxLabel htmlFor="multiple_pages" />
                                            </CheckBoxWrapper>
                                        </InputGroup>
                                        <InputGroup>
                                            <CheckBoxWrapper>
                                                <span>Template active:</span>
                                                <CheckBox
                                                    id="active"
                                                    type="checkbox"
                                                    onClick={(e: any) =>
                                                        setFieldValue(
                                                            "active",
                                                            e.target.checked === true ? 1 : 0,
                                                        )
                                                    }
                                                    defaultChecked={Boolean(template.active)}
                                                />
                                                <CheckBoxLabel htmlFor="active" />
                                            </CheckBoxWrapper>
                                        </InputGroup>
                                        <InputGroup>
                                            <Selection
                                                onChange={(v: { label: string; value: string }) =>
                                                    setFieldValue(
                                                        "template_type",
                                                        v.value as ColorSchemas,
                                                    )
                                                }
                                                options={documentTypeOptions}
                                                defaultValue={documentTypeOptions.find(
                                                    (x) => x.value === values.template_type,
                                                )}
                                                classNamePrefix="react-select"
                                                isSearchable={false}
                                                autoFocus={false}
                                            />

                                            {errors.template_type && touched.template_type && (
                                                <StyledInlineErrorMessage>
                                                    {errors.template_type}
                                                </StyledInlineErrorMessage>
                                            )}
                                        </InputGroup>
                                        <InputGroup>
                                            <Input
                                                type="text"
                                                name="name"
                                                autoCorrect="off"
                                                autoComplete="name"
                                                valid={touched.name && !errors.name}
                                                error={touched.name && errors.name}
                                            />
                                            <Bar />
                                            <Label htmlFor="name">Name</Label>
                                            {errors.name && touched.name && (
                                                <StyledInlineErrorMessage>
                                                    {errors.name}
                                                </StyledInlineErrorMessage>
                                            )}
                                        </InputGroup>
                                        <InputGroup>
                                            <Input
                                                type="text"
                                                name="footer"
                                                autoCorrect="off"
                                                autoComplete="footer"
                                                valid={touched.footer && !errors.footer}
                                                error={touched.footer && errors.footer}
                                            />
                                            <Bar />
                                            <Label htmlFor="footer">Footer</Label>
                                            {errors.footer && touched.footer && (
                                                <StyledInlineErrorMessage>
                                                    {errors.footer}
                                                </StyledInlineErrorMessage>
                                            )}
                                        </InputGroup>
                                        <InputGroup>
                                            <TextArea
                                                name="stylesheet"
                                                autoCorrect="off"
                                                autoComplete="stylesheet"
                                                defaultValue={initialValues.stylesheet ?? ""}
                                                onChange={async (e) => {
                                                    await setFieldValue(
                                                        "stylesheet",
                                                        e.target.value,
                                                    );
                                                }}
                                            />
                                            <Bar />
                                            <Label htmlFor="name">Stylesheet</Label>
                                            {errors.stylesheet && touched.stylesheet && (
                                                <StyledInlineErrorMessage>
                                                    {errors.stylesheet}
                                                </StyledInlineErrorMessage>
                                            )}
                                        </InputGroup>
                                        <InputGroup>
                                            <Input
                                                type="number"
                                                name="service_fee"
                                                autoCorrect="off"
                                                autoComplete="service_fee"
                                                valid={touched.service_fee && !errors.service_fee}
                                                error={touched.service_fee && errors.service_fee}
                                            />
                                            <Bar />
                                            <Label htmlFor="service_fee">Service fee</Label>
                                            {errors.service_fee && touched.service_fee && (
                                                <StyledInlineErrorMessage>
                                                    {errors.service_fee}
                                                </StyledInlineErrorMessage>
                                            )}
                                        </InputGroup>
                                        <InputGroup>
                                            <Input
                                                type="number"
                                                name="copyright_fee"
                                                autoCorrect="off"
                                                autoComplete="copyright_fee"
                                                valid={
                                                    touched.copyright_fee && !errors.copyright_fee
                                                }
                                                error={
                                                    touched.copyright_fee && errors.copyright_fee
                                                }
                                            />
                                            <Bar />
                                            <Label htmlFor="copyright_fee">Copyright fee</Label>
                                            {errors.copyright_fee && touched.copyright_fee && (
                                                <StyledInlineErrorMessage>
                                                    {errors.copyright_fee}
                                                </StyledInlineErrorMessage>
                                            )}
                                        </InputGroup>
                                        <FormButtonGroupContainer>
                                            <CancelButton type="button" onClick={() => onClose()}>
                                                Back to overview
                                            </CancelButton>
                                            <SubmitButton type="submit">Update</SubmitButton>
                                        </FormButtonGroupContainer>
                                    </NewTemplateSettingsContainer>
                                    <NewTemplateEditorContainer>
                                        {errors.content && touched.content && (
                                            <StyledInlineErrorMessage>
                                                {errors.content}
                                            </StyledInlineErrorMessage>
                                        )}
                                        <div
                                            className={
                                                values.multiple_pages ? "multiple-pages" : ""
                                            }
                                        >
                                            <style>{values.stylesheet}</style>
                                            <FroalaEditor
                                                config={froalaConfig}
                                                model={values.content}
                                                onModelChange={(val: object | undefined) => {
                                                    setFieldValue("content", val);
                                                }}
                                            />
                                        </div>
                                    </NewTemplateEditorContainer>
                                </TemplateWrapperContainer>
                            </Form>
                        );
                    }}
                </Formik>
            </TemplateContainer>
        </Fragment>
    );
}
