import styled from "styled-components";
import { Link } from "react-router-dom";
import { AppCard, boxShadowDiv, color } from "../../../styles/mixins";

export const MenuWrapper = styled.section``;

export const MenuContainer = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const MenuLink = styled(Link)`
    text-decoration: none;
`;

export const MenuItem = styled.li`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: ${color("darker", 0.75)};
    font-weight: 500;
    &:hover,
    &.active {
        color: ${color("primary")};
        svg {
            fill: ${color("primary")};
        }
    }
`;

export const MenuIcon = styled.svg`
    margin-right: 1em;
    fill: ${color("darker", 0.75)};
    &.active {
        fill: ${color("primary")};
    }
`;

export const TopBarActionsContainer = styled.section`
    display: inline-flex;
    justify-content: flex-end;
    flex-basis: 10%;
`;

export const ActionMenuWrapper = styled.ul<{ posX?: number; posY?: number }>`
    position: absolute;
    z-index: 999999;
    left: ${(props) => props.posX || 0}px;
    width: auto;
    ${AppCard};
    padding: 1em 0 0;
`;

export const LogoutMenuWrapper = styled(ActionMenuWrapper)<{ posY: number }>`
    top: ${(props) => props.posY}px;
    width: 240px;
    box-shadow: ${boxShadowDiv("transHead")("black", 20)},
        ${boxShadowDiv("transMiddle")("black", 14)}, ${boxShadowDiv("transTail")("black", 12)};
    z-index: 999;
`;
