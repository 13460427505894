import { Typography } from "../../../components/ui/typography";
import { Button } from "../../../components/ui/button";
import { SearchObject } from "../../../components/global/search-object";
import { Separator } from "../../../components/ui/separator";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { RecapSingleFieldInterface } from "../../../types/document-editor";
import { useDocumentEditor } from "../../../lib/providers/DocumentEditorProvider";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { Label } from "../../../components/ui/label";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { MoreHorizontal, Pencil, Trash2 } from "lucide-react";
import { Textarea } from "../../../components/ui/textarea";
import { useDebounce } from "@uidotdev/usehooks";
import { DataAutomation } from "../../../plugins/DataAutomation";

function SmartFields() {
    const { smartFields, isUserActive, handleChangeMade, updateSmartFieldList } =
        useDocumentEditor();
    const [filtered, setFiltered] = useState<Array<RecapSingleFieldInterface>>(smartFields);
    const searchRef = useRef<HTMLInputElement | null>(null);
    const dataAutomation = DataAutomation.getInstance();
    const [filterType, setFilterType] = useState<"all" | "todo" | "done">("all");

    useEffect(() => {
        setFiltered(smartFields);
        setFilterType("all");
    }, [smartFields]);

    function handleInsertAll() {
        dataAutomation.insertAllRecapFields(smartFields);

        updateSmartFieldList(
            smartFields.map((x) => {
                return {
                    ...x,
                    status: "done",
                };
            }),
        );

        handleChangeMade(true);
    }

    function handleFilter(type: "all" | "todo" | "done") {
        switch (type) {
            case "done":
                setFilterType("done");
                setFiltered(smartFields.filter((x) => x.status === "done"));
                break;
            case "todo":
                setFilterType("todo");
                setFiltered(smartFields.filter((x) => x?.status === "todo" || !x.status));
                break;
            case "all":
            default:
                setFilterType("all");
                setFiltered(smartFields);
                break;
        }
    }

    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex justify-between">
                <Typography text="Smart fields" style="h4" />
                <Button variant="ghost" onClick={handleInsertAll} disabled={!isUserActive()}>
                    Insert all
                </Button>
            </div>
            <SearchObject<RecapSingleFieldInterface>
                type="text"
                className="border-none shadow-none pl-0 mt-4"
                items={smartFields}
                ref={searchRef}
                onResults={(items) => setFiltered(items)}
                keys={["name", "value", "status"]}
            />
            <Separator />
            <Select onValueChange={handleFilter} value={filterType}>
                <SelectTrigger className="my-2">
                    <SelectValue placeholder="filter smart fields"></SelectValue>
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">Show all</SelectItem>
                    <SelectItem value="todo">Show todo</SelectItem>
                    <SelectItem value="done">Show done</SelectItem>
                </SelectContent>
            </Select>
            <ScrollArea className="max-h-[1200px] pr-4">
                {filtered.map((smart_field, index) => (
                    <SmartFieldItem field={smart_field} key={index} />
                ))}
            </ScrollArea>
        </div>
    );
}

type SmartFieldItemProps = {
    field: RecapSingleFieldInterface;
};

export function SmartFieldItem({ field }: SmartFieldItemProps) {
    const { isUserActive, updateSmartField, handleChangeMade } = useDocumentEditor();
    const [value, setValue] = useState<string>(field.value ?? "");
    const debouncedValue = useDebounce(value, 300);
    const dataAutomation = DataAutomation.getInstance();

    useEffect(() => {
        if (value !== field.value) {
            setValue(field?.value ?? "");
        }
    }, [field]);

    useEffect(() => {
        if (debouncedValue?.length ?? 0) {
            handleChange(debouncedValue ?? "");
        }
    }, [debouncedValue]);

    function handleChange(value: string) {
        updateSmartField({
            ...field,
            value: value,
            status: "done",
        });

        dataAutomation.updateRecapField(field, value ?? "");
        handleChangeMade(true);
    }

    function removeValue(field: RecapSingleFieldInterface, empty: boolean = false) {
        if (!empty) {
            updateSmartField({
                ...field,
                status: "todo",
            });
        } else {
            updateSmartField({
                ...field,
                value: undefined,
                status: "todo",
            });
        }

        dataAutomation.updateRecapField(field, "");
    }

    return (
        <SmartFieldTextArea
            disabled={!isUserActive()}
            handleChange={handleChange}
            removeValue={removeValue}
            field={field}
        />
    );
}

type SmartFieldTextAreaProps = {
    disabled: boolean;
    handleChange: (value: string, field: RecapSingleFieldInterface) => void;
    removeValue: (field: RecapSingleFieldInterface, empty?: boolean) => void;
} & SmartFieldItemProps;

export function SmartFieldTextArea({
    field,
    disabled,
    handleChange,
    removeValue,
}: SmartFieldTextAreaProps) {
    const [value, setValue] = useState<string>(field.value ?? "");
    const debouncedValue = useDebounce(value, 300);

    useEffect(() => {
        if (debouncedValue?.length ?? 0) {
            handleChange(debouncedValue ?? "", field);
        }
    }, [debouncedValue]);

    return (
        <Fragment>
            <div className="w-full py-4">
                <Label htmlFor={field.data_id} className="flex justify-between w-full pb-2">
                    {field.name}
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild tabIndex={-1}>
                            <Button variant="ghost" className="h-8 w-8 p-0" disabled={disabled}>
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="w-28">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuGroup>
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start p-2"
                                    onClick={() => handleChange(field.value ?? "", field)}
                                >
                                    <DropdownMenuItem>
                                        <Pencil className="mr-2 h-4 w-4" />
                                        <span>Insert</span>
                                    </DropdownMenuItem>
                                </Button>
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start p-2"
                                    onClick={() => removeValue(field)}
                                >
                                    <DropdownMenuItem>
                                        <Trash2 className="mr-2 h-4 w-4" />
                                        <span>Remove</span>
                                    </DropdownMenuItem>
                                </Button>
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </Label>
                <Textarea
                    onChange={(e) => {
                        if (e.currentTarget.value.length) {
                            setValue(e.currentTarget.value ?? "");
                        } else {
                            removeValue(field, true);
                            setValue("");
                        }
                    }}
                    id={field.data_id}
                    className="resize-none"
                    value={value ?? ""}
                    disabled={disabled}
                    rows={4}
                    data-id={`textarea-id-${field.data_id}`}
                />
            </div>
            <Separator />
        </Fragment>
    );
}

export default SmartFields;
