import TypedFroalaEditor from "froala-editor-typings";
import * as Sentry from "@sentry/react";
import Command from ".";
import { TenantUser } from "../../../types/auth";
import { checkDeletionSpan, isLineNumber, retrieveEditorContext } from "../Utility/inspectDOM";
import { handleAdditionNodeCreation, newAdditionNode } from "../Utility/modifyDOM";
import { handleSelectionDeletion } from "../Utility/selectionDeletion";
import Memento from "../Memento";
import { saveEditorSelectionAndAddEditorStateToMemento } from "../Utility/base";

class WriteCommand implements Command {
    private editor: TypedFroalaEditor.FroalaEditor;
    private event: KeyboardEvent;
    private readonly user: TenantUser;
    private readonly previousState: Memento;
    private spanId: string | null;

    constructor(editor: TypedFroalaEditor.FroalaEditor, event: KeyboardEvent, user: TenantUser) {
        this.editor = editor;
        this.event = event;
        this.user = user;
        this.previousState = this.saveState();
    }

    execute(): void {
        this.event.preventDefault();
        this.event.stopPropagation();
        this.insertAddition();
    }

    undo(): void {
        this.restoreState(this.previousState);
    }

    saveState(): Memento {
        return saveEditorSelectionAndAddEditorStateToMemento(this.editor);
    }

    restoreState(memento: Memento): void {
        this.editor.html.set(memento.getState()); // Restore the HTML content
    }

    get previous(): Memento {
        return this.previousState;
    }

    get id(): string | null {
        return this.spanId;
    }

    private insertAddition(): void {
        const spaceChar = "\u00A0";

        // Collect the key the user pressed
        let charTyped;
        charTyped = this.event.key;

        // Ignore non-character and modifier keys
        const isSpecialChar =
            charTyped.length > 1 || this.event.ctrlKey || this.event.metaKey || this.event.altKey;
        if (isSpecialChar) {
            return;
        }
        // Replace a space with unicode space
        if (this.event.code === "Space") {
            charTyped = spaceChar;
        }

        const selection: Selection = this.editor.selection.get();

        if (selection.isCollapsed) {
            const anchor = selection.anchorNode;
            const anchorOffset = selection.anchorOffset;

            // Do nothing for safety
            if (!anchor) {
                return;
            }

            const { anchorNode, charIndexed } = retrieveEditorContext(anchor, anchorOffset);

            if (isLineNumber(anchorNode)) {
                return;
            }

            if (charIndexed) {
                const checkDeletion = checkDeletionSpan(anchorNode);
                if (checkDeletion) {
                    // If we are at the end of a deletion, allow typing
                    if (anchorOffset === checkDeletion.textContent?.length) {
                        this.spanId = handleAdditionNodeCreation(
                            checkDeletion,
                            anchorOffset,
                            charTyped,
                            "right",
                            this.user!,
                        );
                    }

                    // Disallow typing inside deletion span
                    return;
                }

                // Handles addition inside a node
                this.spanId = handleAdditionNodeCreation(
                    anchorNode,
                    anchorOffset,
                    charTyped,
                    "inside",
                    this.user!,
                );
                return;
            } else {
                // In this case we are at a Node edge

                // Handles addition at an edge
                this.spanId = handleAdditionNodeCreation(
                    anchorNode,
                    anchorOffset,
                    charTyped,
                    "right",
                    this.user!,
                );

                return;
            }
        } else {
            // Delete selection
            const { lastNode } = handleSelectionDeletion(selection, this.user!);

            // Place span and cursor
            if (lastNode) {
                this.spanId = newAdditionNode(lastNode, charTyped, this.user!);
            } else {
                // TODO: what if no last node?
                Sentry.captureMessage("Selection + Addition failed to return lastNode", "warning");
            }
        }
    }
}

export default WriteCommand;
