import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "../../../components/ui/command";
import { Textarea } from "../../../components/ui/textarea";
import { Alert, AlertDescription } from "../../../components/ui/alert";
import React, { Fragment, useEffect, useState } from "react";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    useCreateRotLinkMutation,
    useGetAvailableRotOrganisationsQuery,
} from "../../../api/tenant/case-file";
import Loading from "../../../components/global/loading";
import { PermissionTypes } from "../../../types/case-files";
import useCountDown from "react-countdown-hook";
import { SECOND_TO_MD } from "../../../lib/constants";
import { Send } from "lucide-react";

const initialTime = 3 * 1000;

function CreateRotLink() {
    const { case_file_id } = useCaseFiles();
    const schema = z.object({
        organisation: z.string(),
        permissions: z.enum(["full_access", "read_only_extended", "read_only"]),
        users_to_notify: z
            .array(
                z.object({
                    id: z.string(),
                    name: z.string(),
                    email: z.string(),
                    tenant_id: z.string(),
                }),
            )
            .optional(),
        note: z.string().optional(),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            permissions: "read_only",
        },
    });
    const { watch } = form;
    const { data: availableLinks, isLoading } = useGetAvailableRotOrganisationsQuery(case_file_id);
    const [createRotLink] = useCreateRotLinkMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [timeLeft, { start, reset }] = useCountDown(initialTime, SECOND_TO_MD);

    useEffect(() => {
        reset();
    }, [confirm]);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);

        try {
            await createRotLink({
                ...values,
                case_file_id: case_file_id,
            });

            toast.success("You have successfully released these terms");
            form.reset();
        } catch (e) {
            toast.error("Something went wrong while releasing the terms");
        }
        setLoading(false);
    }

    function getPermissionText(permission: PermissionTypes) {
        switch (permission) {
            case "read_only_extended": {
                return (
                    "The viewer can see this Recap / Charter Party, including its riders, addenda and uploads but not change" +
                    " anything. Viewer can participate in chat messaging, can upload files and participate in certain approval processes."
                );
            }
            case "full_access":
                return (
                    "The viewer will have full access to this Recap / Charter Party. The viewer can add, edit and remove a recap," +
                    " standardized forms etc."
                );
            default:
                return "The viewer can see this Recap / Charter Party, including its riders, addenda and uploads but not change anything. Viewer can participate in chat messaging.";
        }
    }

    function resetFieldsExceptOrganisation() {
        form.resetField("permissions");
        form.resetField("users_to_notify");
        form.resetField("note");
    }

    return (
        <Dialog onOpenChange={() => setConfirm(false)}>
            <DialogTrigger asChild>
                <Button>Invite</Button>
            </DialogTrigger>
            <DialogContent>
                {!confirm && (
                    <Loading
                        loaded={!loading}
                        child={
                            <Fragment>
                                <DialogHeader>
                                    <DialogTitle>
                                        Release this Recap C/P to another company on Ocean Recap
                                    </DialogTitle>
                                    <DialogDescription>
                                        You can authorize another Ocean Recap user to gain access to
                                        this Recap C/P from their own overview. Changes you make
                                        will instantly be visible to them until you revoke their
                                        access rights.
                                    </DialogDescription>
                                </DialogHeader>
                                <Loading
                                    loaded={!isLoading}
                                    child={
                                        <Form {...form}>
                                            <form onSubmit={form.handleSubmit(handleSubmit)}>
                                                <FormField
                                                    control={form.control}
                                                    name="organisation"
                                                    render={({ field }) => (
                                                        <FormItem className="py-2">
                                                            <FormLabel>Share with</FormLabel>
                                                            <Select
                                                                onValueChange={(value) => {
                                                                    field.onChange(value);
                                                                    resetFieldsExceptOrganisation();
                                                                }}
                                                                defaultValue={field.value}
                                                            >
                                                                <FormControl className="bg-white">
                                                                    <SelectTrigger>
                                                                        <SelectValue placeholder="Select organisation" />
                                                                    </SelectTrigger>
                                                                </FormControl>
                                                                <SelectContent>
                                                                    {availableLinks?.map(
                                                                        (organisation, index) => (
                                                                            <SelectItem
                                                                                value={
                                                                                    organisation.id
                                                                                }
                                                                                key={index}
                                                                            >
                                                                                {organisation.name}
                                                                            </SelectItem>
                                                                        ),
                                                                    )}
                                                                </SelectContent>
                                                            </Select>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                                {watch("organisation") && (
                                                    <FormField
                                                        control={form.control}
                                                        name="users_to_notify"
                                                        render={({ field }) => (
                                                            <FormItem className="py-2">
                                                                <FormLabel>
                                                                    Select which users should be
                                                                    notified
                                                                </FormLabel>
                                                                <Command className="border border-input border-solid">
                                                                    <CommandInput placeholder="Search..." />
                                                                    <CommandList className="rounded-md ">
                                                                        <CommandEmpty>
                                                                            No results found.
                                                                        </CommandEmpty>
                                                                        <CommandGroup>
                                                                            {availableLinks
                                                                                ?.find(
                                                                                    (x) =>
                                                                                        x.id ===
                                                                                        watch(
                                                                                            "organisation",
                                                                                        ),
                                                                                )
                                                                                ?.users.map(
                                                                                    (
                                                                                        user,
                                                                                        index,
                                                                                    ) => {
                                                                                        const isSelected =
                                                                                            form
                                                                                                .getValues(
                                                                                                    "users_to_notify",
                                                                                                )
                                                                                                ?.map(
                                                                                                    (
                                                                                                        x,
                                                                                                    ) =>
                                                                                                        x.id,
                                                                                                )
                                                                                                .includes(
                                                                                                    user.id,
                                                                                                );

                                                                                        return (
                                                                                            <CommandItem
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                onSelect={() => {
                                                                                                    if (
                                                                                                        isSelected
                                                                                                    ) {
                                                                                                        field.onChange(
                                                                                                            (
                                                                                                                form.getValues(
                                                                                                                    "users_to_notify",
                                                                                                                ) ??
                                                                                                                []
                                                                                                            ).filter(
                                                                                                                (
                                                                                                                    x,
                                                                                                                ) =>
                                                                                                                    x.id !==
                                                                                                                    user.id,
                                                                                                            ),
                                                                                                        );
                                                                                                    } else if (
                                                                                                        form.getValues(
                                                                                                            "users_to_notify",
                                                                                                        )
                                                                                                            ?.length
                                                                                                    ) {
                                                                                                        field.onChange(
                                                                                                            [
                                                                                                                ...(form.getValues(
                                                                                                                    "users_to_notify",
                                                                                                                ) ??
                                                                                                                    []),
                                                                                                                user,
                                                                                                            ],
                                                                                                        );
                                                                                                    } else {
                                                                                                        field.onChange(
                                                                                                            [
                                                                                                                user,
                                                                                                            ],
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                className={`cursor-pointer ${
                                                                                                    isSelected
                                                                                                        ? "bg-primary color-white hover:bg-blue-400" +
                                                                                                          " aria-selected:bg-primary" +
                                                                                                          " aria-selected:text-white"
                                                                                                        : ""
                                                                                                }`}
                                                                                            >
                                                                                                <span
                                                                                                    className={`flex items-center ${
                                                                                                        isSelected
                                                                                                            ? "text-white"
                                                                                                            : "text-gray-950"
                                                                                                    }`}
                                                                                                >
                                                                                                    {
                                                                                                        user.name
                                                                                                    }
                                                                                                </span>
                                                                                            </CommandItem>
                                                                                        );
                                                                                    },
                                                                                )}
                                                                        </CommandGroup>
                                                                    </CommandList>
                                                                </Command>
                                                                <FormMessage />
                                                            </FormItem>
                                                        )}
                                                    />
                                                )}
                                                <FormField
                                                    control={form.control}
                                                    name="permissions"
                                                    render={({ field }) => (
                                                        <FormItem className="py-2">
                                                            <FormLabel>Permission</FormLabel>
                                                            <Select
                                                                onValueChange={field.onChange}
                                                                defaultValue={field.value}
                                                            >
                                                                <FormControl className="bg-white">
                                                                    <SelectTrigger>
                                                                        <SelectValue placeholder="Select organisation" />
                                                                    </SelectTrigger>
                                                                </FormControl>
                                                                <SelectContent>
                                                                    <SelectItem value="full_access">
                                                                        Unrestricted access
                                                                    </SelectItem>
                                                                    <SelectItem value="read_only_extended">
                                                                        Read only plus
                                                                    </SelectItem>
                                                                    <SelectItem value="read_only">
                                                                        Read only
                                                                    </SelectItem>
                                                                </SelectContent>
                                                            </Select>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />

                                                <Alert className="my-4">
                                                    <AlertDescription className="text-slate-950">
                                                        {getPermissionText(watch("permissions"))}
                                                    </AlertDescription>
                                                </Alert>

                                                {watch("users_to_notify") && (
                                                    <FormField
                                                        control={form.control}
                                                        name="note"
                                                        render={({ field }) => (
                                                            <FormItem>
                                                                <FormLabel>Add a note</FormLabel>
                                                                <FormControl>
                                                                    <Textarea
                                                                        className="resize-none"
                                                                        {...field}
                                                                    />
                                                                </FormControl>
                                                                <FormMessage />
                                                            </FormItem>
                                                        )}
                                                    />
                                                )}
                                                <DialogFooter className="mt-4">
                                                    <Button
                                                        type="button"
                                                        disabled={!form.formState.isValid}
                                                        onClick={() => {
                                                            setConfirm(true);
                                                            start();
                                                        }}
                                                    >
                                                        Invite
                                                    </Button>
                                                </DialogFooter>
                                            </form>
                                        </Form>
                                    }
                                />
                            </Fragment>
                        }
                    />
                )}
                {confirm && (
                    <Fragment>
                        <DialogHeader>
                            <DialogTitle>You are about to release these terms</DialogTitle>
                        </DialogHeader>
                        <div className="grid gap-4">
                            <div className="space-y-2">
                                <h4 className="font-medium leading-none">Organisation: </h4>
                                <p className="text-sm text-muted-foreground">
                                    {
                                        availableLinks?.find(
                                            (org) => org.id === watch("organisation"),
                                        )?.name
                                    }
                                </p>
                            </div>
                            <div className="space-y-2">
                                <h4 className="font-medium leading-none">Permissions: </h4>
                                <p className="text-sm text-muted-foreground">
                                    {getPermissionText(watch("permissions"))}
                                </p>
                            </div>
                            {form.getValues("users_to_notify") && (
                                <div className="space-y-2">
                                    <h4 className="font-medium leading-none">
                                        The following users will notified:{" "}
                                    </h4>
                                    {form.getValues("users_to_notify")?.map((user) => (
                                        <p key={user.id} className="text-sm text-muted-foreground">
                                            - {user.name}
                                        </p>
                                    ))}
                                </div>
                            )}
                            {form.getValues("note") && (
                                <div className="space-y-2">
                                    <h4 className="font-medium leading-none">Note: </h4>
                                    <p className="text-sm text-muted-foreground">
                                        {form.getValues("note")}
                                    </p>
                                </div>
                            )}
                        </div>

                        <DialogFooter className="mt-4 gap-4">
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={() => {
                                    form.reset();
                                    setConfirm(false);
                                    setLoading(false);
                                }}
                            >
                                Back
                            </Button>
                            <DialogClose asChild>
                                <Button
                                    type="submit"
                                    disabled={timeLeft !== 0}
                                    onClick={() => handleSubmit(form.getValues())}
                                >
                                    <Send height={16} className={"mr-2"} />
                                    {timeLeft > 0 ? `(${timeLeft / 1000})` : "Invite"}
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </Fragment>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default CreateRotLink;
