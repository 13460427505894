import { ColumnDef } from "@tanstack/react-table";
import { CaseFileIndex } from "../types/case-files";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { TableDropDownItems } from "../types/table";
import { ArchiveRestore, MoreHorizontal } from "lucide-react";
import { format } from "date-fns";
import { DropdownMenu, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import DataTableAdvancedDropDown from "../components/data-table/data-table-advanced-drop-down";
import CaseFileRestore from "../containers/archive/case-file-restore";

const columns: ColumnDef<CaseFileIndex & { deleted_at: Date }>[] = [
    {
        accessorKey: "reference",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="CP-ID" />;
        },
        sortingFn: "basic",
        meta: {
            title: "CP-ID",
        },
    },
    {
        accessorKey: "document_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Document name" type="filter" />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex items-center w-full overflow-hidden text-ellipsis space-x-2">
                    <span className="w-full min-w-0 overflow-hidden text-ellipsis">
                        {row.getValue("document_name")}
                    </span>
                </div>
            );
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Document name",
        },
    },
    {
        accessorKey: "load_port",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Load port" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Load port",
        },
    },
    {
        accessorKey: "discharge_port",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Discharge port" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Discharge port",
        },
    },
    {
        accessorKey: "vessel_name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Vessel name" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Vessel name",
        },
    },
    {
        accessorKey: "charterer",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Charterer" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Charterer",
        },
    },
    {
        accessorKey: "owner",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Owner" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Owner",
        },
    },
    {
        accessorKey: "broker",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Broker" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Broker",
        },
    },
    {
        accessorKey: "author",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Author" type="filter" />;
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        meta: {
            title: "Author",
        },
    },
    {
        accessorKey: "deleted_at",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title="Deleted at (CEST)"
                    type="date-range"
                />
            );
        },
        filterFn: (row, id, value) =>
            new Date(row.getValue(id)) > new Date(value.from) &&
            new Date(row.getValue(id)) < new Date(value.to),
        meta: {
            title: "Deleted at (CEST)",
        },
        cell: ({ row }) => {
            const value = row.getValue("deleted_at") as string;
            return <span>{format(new Date(value), "PPP")}</span>;
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DataTableAdvancedDropDown<CaseFileIndex>
                        row={row.original}
                        type="dropdown"
                        items={tableDropDown}
                    />
                </DropdownMenu>
            );
        },
    },
];

const tableDropDown: TableDropDownItems<CaseFileIndex & { deleted_at: Date }> = {
    label: "Actions",
    groups: [
        {
            actions: [
                {
                    label: "Restore",
                    icon: <ArchiveRestore className="mr-2 h-4 w-4" />,
                    element: (row, onClose) => <CaseFileRestore row={row} onClose={onClose} />,
                    elementType: "dialog",
                },
            ],
        },
    ],
};

export { columns, tableDropDown };
