import React, { useEffect, useState } from "react";
import { useLazyGetAuditTrailQuery } from "../../../api/tenant/case-file";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { DataTableToolbarButton } from "../../../types/table";
import Loading from "../../../components/global/loading";
import AuditTrailTable from "./audit-trail-table";
import { usePageTitle } from "../../../lib/usePageTitle";

function AuditTrail() {
    const { case_file_id, setActivePage, meta_data } = useCaseFiles(),
        [getData, { data, isLoading }] = useLazyGetAuditTrailQuery(),
        [showAll, setShowAll] = useState<boolean>(false);

    usePageTitle(`CP: ${meta_data?.reference} - Audit Trail`);

    useEffect(() => {
        setActivePage("audit-trail");
        getData({
            case_file_id: case_file_id,
            infinite: showAll,
        });
    }, []);

    function fetchData(showAll: boolean) {
        getData({
            case_file_id: case_file_id,
            infinite: showAll,
        });
        setShowAll(showAll);
    }

    const buttons: Array<DataTableToolbarButton> = [
        {
            label: !showAll ? "Show all" : "Show latest",
            action: () => fetchData(!showAll),
        },
    ];

    return (
        <Loading
            loaded={!isLoading}
            child={
                <div className="p-4 w-full flex items-stretch h-full">
                    <div className="bg-foreground p-4 rounded-sm">
                        <AuditTrailTable data={data?.data ?? []} buttons={buttons} />
                    </div>
                </div>
            }
        />
    );
}

export default AuditTrail;
