import React, { Fragment, useEffect } from "react";
import TenantLayout from "./layouts/tenant-layout";
import axios, { AxiosError, isAxiosError } from "axios";
import { AuthProvider } from "./lib/providers/AuthProvider";
import { TenantBasePage } from "./layouts/tenant-base-page";
import { Navigate, Route, Routes, useLocation } from "react-router";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { TooltipProvider } from "./components/ui/tooltip";
import { Provider as StoreProvider } from "react-redux";
import { store } from "./store";
import { CaseFileLayout } from "./layouts/case-file-layout";
import { RecapEditor } from "./containers/case-files/recaps/recap-editor";
import { useNetworkState } from "@uidotdev/usehooks";
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle,
} from "./components/ui/alert-dialog";
import Overview from "./containers/case-files/overview/overview";
import CaseFile from "./containers/case-files/detail/detail";
import CaseFileRiders from "./containers/case-files/riders/case-file-riders";
import { RiderProvider } from "./lib/providers/rider-terms-provider";
import Attachments from "./containers/case-files/attachements/attachments";
import AuditTrail from "./containers/case-files/audit-trail/audit-trail";
import "@tanstack/react-table";
import { RowData } from "@tanstack/react-table";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";

import { Toaster } from "./components/ui/sonner";
import { Main } from "./router";
import DocumentEditor from "./containers/case-files/document-editor/document-editor";
import Docusign from "./containers/case-files/docusign/docusign";
import SingingCompleted from "./containers/case-files/docusign/singing-completed";
import SingingCancelled from "./containers/case-files/docusign/singing-cancelled";
import SigningDeclined from "./containers/case-files/docusign/signing-declined";
import Billing from "./containers/billing/billing";
import Addenda from "./containers/case-files/addenda/addenda";
import CaseFileArchive from "./containers/archive/case-file-archive";
import ResetPassword from "./containers/authentication/reset-password";
import Team from "./containers/team/team";
import CreateAccount from "./containers/authentication/create-account";
import SettingLayout from "./layouts/setting";
import HouseForms from "./containers/house-forms/house-forms";
import { Tables } from "./config/app";

import * as Sentry from "@sentry/react";
import { determineTenantName } from "./lib/helpers/tenant";
import { Event, EventHint } from "@sentry/browser";
import HouseFormEditor from "./containers/house-forms/house-form-editor";

function handleSentryBeforeSend(event: Event, hint: EventHint) {
    addAxiosContextRecursive(event, hint?.originalException);

    return event;
}

function addAxiosContextRecursive(event: Event, error: unknown) {
    if (isAxiosError(error)) {
        addAxiosContext(event, error);
    } else if (error instanceof Error && error.cause) {
        addAxiosContextRecursive(event, error.cause);
    }
}

function addAxiosContext(event: Event, error: AxiosError) {
    if (error.response) {
        const contexts = { ...event.contexts };
        contexts.Axios = { Response: error.response };
        event.contexts = contexts;
    }
}

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    beforeSend: handleSentryBeforeSend,
});

declare global {
    interface Window {
        Echo: Echo;
        Pusher: Pusher;
    }
}

declare module "@tanstack/react-table" {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ColumnMeta<TData extends RowData, TValue> {
        title: string;
        headerClassName?: string;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface TableMeta<TData extends RowData> {
        tableId: Tables;
    }
}

window.Pusher = require("pusher-js/with-encryption");

function App() {
    const tenant: string | undefined = determineTenantName(),
        network = useNetworkState();
    const location = useLocation();

    useEffect(() => {
        // @ts-expect-error -- gtag is global
        if (typeof gtag !== "undefined") {
            // @ts-expect-error -- gtag is global
            gtag("event", "page_view");
        }
    }, [location]);

    useEffect(() => {
        if (tenant) {
            axios.defaults.baseURL = `/${tenant}`;
        } else {
            axios.defaults.baseURL = "";
        }
    }, []);

    return (
        <div className="App">
            {network.online ? (
                <Fragment>
                    {tenant ? (
                        <StoreProvider store={store}>
                            <TooltipProvider>
                                <AuthProvider>
                                    <Routes>
                                        <Route
                                            path="/sign/:token/:userid/:id/:filename"
                                            element={<Docusign />}
                                        />
                                        <Route
                                            path="/signing-completed/:document_name"
                                            element={<SingingCompleted />}
                                        />
                                        <Route
                                            path="/signing-cancelled/:document_name"
                                            element={<SingingCancelled />}
                                        />
                                        <Route
                                            path="/signing-declined/:document_name"
                                            element={<SigningDeclined />}
                                        />
                                        <Route element={<TenantLayout />}>
                                            <Route element={<TenantBasePage />}>
                                                <Route path="/overview" element={<Overview />} />
                                                <Route element={<CaseFileLayout />}>
                                                    <Route
                                                        path="/case-file/:case_file_id"
                                                        element={<CaseFile />}
                                                    />
                                                    <Route
                                                        path="/case-file/:case_file_id/recap"
                                                        element={<RecapEditor />}
                                                    />
                                                    <Route
                                                        path="/case-file/:case_file_id/riders"
                                                        element={
                                                            <RiderProvider>
                                                                <CaseFileRiders />
                                                            </RiderProvider>
                                                        }
                                                    />
                                                    <Route
                                                        path="/case-file/:case_file_id/addenda"
                                                        element={<Addenda />}
                                                    />
                                                    <Route
                                                        path="/case-file/:case_file_id/attachments"
                                                        element={<Attachments />}
                                                    />
                                                    <Route
                                                        path="/case-file/:case_file_id/audit-trail"
                                                        element={<AuditTrail />}
                                                    />
                                                    <Route
                                                        path="/case-file/:case_file_id/:document_id"
                                                        element={<DocumentEditor />}
                                                    />
                                                </Route>
                                                <Route
                                                    path="/archive"
                                                    element={<CaseFileArchive />}
                                                />
                                                <Route path="/templates" element={<HouseForms />} />
                                                <Route
                                                    path="/templates/:id"
                                                    element={<HouseFormEditor />}
                                                />

                                                <Route element={<SettingLayout />}></Route>
                                                <Route path="/billing" element={<Billing />} />
                                                <Route path="/team" element={<Team />} />
                                                <Route
                                                    path="*"
                                                    element={<Navigate to={"/overview"} />}
                                                />
                                            </Route>
                                        </Route>
                                        <Route
                                            path="/forgot-password/:hash"
                                            element={<ResetPassword />}
                                        />
                                        <Route
                                            path="/create-account/:id"
                                            element={<CreateAccount />}
                                        />
                                    </Routes>
                                </AuthProvider>
                            </TooltipProvider>
                        </StoreProvider>
                    ) : (
                        <StoreProvider store={store}>
                            <Main />
                        </StoreProvider>
                    )}
                </Fragment>
            ) : (
                <AlertDialog open>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Internet connection lost</AlertDialogTitle>
                            <AlertDialogDescription></AlertDialogDescription>
                        </AlertDialogHeader>
                    </AlertDialogContent>
                </AlertDialog>
            )}
            <Toaster
                position="top-right"
                className={"border-0 &:where([data-close-button]):hover:bg-transparent mr-6"}
                closeButton
                toastOptions={{
                    classNames: {
                        closeButton: "left-[calc(100%-1.5em)] top-[1em] !bg-black !hover:bg-black",
                    },
                }}
            />
        </div>
    );
}

export default App;
