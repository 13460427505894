import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import React, { Fragment, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRequestPasswordResetMutation } from "../../api/tenant/authentication";
import { toast } from "sonner";
import { Typography } from "../../components/ui/typography";
import Loading from "../../components/global/loading";

function ForgotPassword() {
    const schema = z.object({
        username: z.string().min(2, {
            message: "Username must be at least 2 characters.",
        }),
        email: z.string().email("This is not a valid email."),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            username: "",
            email: "",
        },
    });

    const [requestPasswordReset] = useRequestPasswordResetMutation();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        try {
            await requestPasswordReset(values).unwrap();

            form.reset();

            setOpen(false);

            toast.message("Password reset mail will be sent shortly");
        } catch (e) {
            setErrorMessage("No user found, please check if the username and email are correct");
        }

        setLoading(false);
    }

    function handleOpenChange(open: boolean) {
        setOpen(open);
        form.reset();
    }

    return (
        <Dialog open={open} onOpenChange={handleOpenChange}>
            <DialogTrigger asChild>
                <Button variant="ghost" className="mt-1 mx-auto w-[200px]">
                    Forgot your password?
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[400px]">
                <Loading
                    loaded={!loading}
                    child={
                        <Fragment>
                            <DialogHeader>
                                <DialogTitle>Request password reset</DialogTitle>
                                <DialogDescription>
                                    Your password will be reset by email
                                </DialogDescription>
                            </DialogHeader>
                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(handleSubmit)}>
                                    <FormField
                                        control={form.control}
                                        name="username"
                                        render={({ field }) => (
                                            <FormItem>
                                                <Label htmlFor="name">Username</Label>
                                                <FormControl>
                                                    <Input {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem className="mt-4">
                                                <Label htmlFor="name">E-mail</Label>
                                                <FormControl>
                                                    <Input {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    {errorMessage && (
                                        <Typography text={errorMessage} style="warning" />
                                    )}
                                    <DialogFooter>
                                        <Button className="mt-4" type="submit">
                                            Request reset
                                        </Button>
                                    </DialogFooter>
                                </form>
                            </Form>
                        </Fragment>
                    }
                />
            </DialogContent>
        </Dialog>
    );
}

export default ForgotPassword;
