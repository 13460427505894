import { v4 as uuidv4 } from "uuid";
import Memento from "../Memento";

export const chainTime = 5000;

export type direction = "backspace" | "delete";
export type nodeBound = "left" | "inside" | "right";

/**
 * Creates an HTML span element with specified attributes and text content. This utility function is designed
 * to facilitate the dynamic creation of span elements with customizable properties, making it suitable
 * for various text manipulation tasks in the DOM where spans are frequently used to highlight, delete,
 * or otherwise modify segments of text.
 *
 * @param {Record<string, string>} attributes - An object where each key-value pair corresponds to an attribute
 *                                              name and its value for the span element.
 * @param {string} textContent - The text content to be placed inside the newly created span element.
 * @param {TenantUser} user - The user that made this change to the document
 * @returns {Node} - The newly created span element with the specified attributes and text content.
 */
export function createSpanWithText(
    attributes: Record<string, string>,
    textContent: string,
    userId: string,
): Node {
    const span = document.createElement("span");
    span.setAttribute("data-timestamp", new Date().getTime().toString());
    span.setAttribute("data-identifier", uuidv4());
    span.setAttribute("data-user", userId);

    // Loop through attributes and set them on the span
    Object.entries(attributes).forEach(([key, value]) => {
        span.setAttribute(key, value);

        // Check if the current attribute key is 'data-change-type'
        if (key === "data-change-type") {
            if (value === "deletion") {
                span.classList.add("fr-tracking-deleted");
            }
            if (value === "addition") {
                span.classList.add("fr-highlight-change");
            }
        }
    });

    span.textContent = textContent;

    return span;
}

/**
 * Updates the textContent of a given span node.
 * Automatically updates the timestamp that is associated with the node.
 *
 * @param {Node} span - A Node that needs to be an HTMLSpanElement
 * @param {string} textContent - The new text to insert into the span
 */
export function updateSpanWithText(span: Node, textContent: string) {
    if (!(span instanceof HTMLSpanElement)) {
        return;
    }
    span.textContent = textContent;
    (span as HTMLSpanElement).setAttribute("data-timestamp", new Date().getTime().toString());
}

/**
 * Helper method that checks if the timestamp of a node is within the globally defined chainTime,
 * thereby determining if we should chain content onto the node.
 *
 * @param node - The node to be tested regarding it's timestamp.
 * @returns Returns true if `node` was created/updated less than `chainTime` ago, false otherwise.
 */
export function shouldCombine(node: Node): boolean {
    const firsTimestamp = (node as HTMLElement).getAttribute("data-timestamp") ?? "";

    return Math.abs(parseInt(firsTimestamp) - new Date().getTime()) <= chainTime;
}

/**
 * Sets the cursor (caret) position immediately after the specified HTML element.
 * This utility is essential when modifying the DOM structure and ensuring the user's cursor remains in an expected position.
 *
 * @param {Node} element - The HTML element after which the cursor should be positioned.
 */
export function setCursorAfterElement(element: Node) {
    const range = document.createRange();
    const selection = window.getSelection();
    if (!selection) {
        return;
    }

    range.setStartAfter(element);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
}

export function setCursorBeforeElement(element: Node) {
    const range = document.createRange();
    const selection = window.getSelection();
    if (!selection) {
        return;
    }

    range.setStartBefore(element);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
}

/**
 * Sets the cursor (caret) position just after the specified element or at a specific offset within the element's first child.
 * This function is particularly useful when manipulating the DOM and needing to restore or set the cursor position accordingly.
 *
 * @param {Node} element - The HTML element near which the cursor should be positioned.
 * @param {number} [offset=0] - The offset within the element's first child where the cursor should be positioned.
 *                              Defaults to 0, which places the cursor at the beginning of the element or its first child.
 */
export function setCursorWithElement(element: Node, offset: number = 0) {
    const range = document.createRange();
    const selection = window.getSelection();
    if (!selection) {
        return;
    }

    let anchor;

    if (element.nodeType === Node.ELEMENT_NODE && element.firstChild) {
        anchor = element.firstChild;
    } else {
        anchor = element;
    }

    range.setStart(anchor, offset);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
}

/**
 * Retrieves and returns the details of the current text selection from a Froala editor, provided the selection is not collapsed.
 * This function is particularly useful for understanding the scope and context of the user's current selection within the editor.
 *
 * @param {Selection} selection - The Selection object from the browser's Document object, representing the current selection within the Froala editor.
 * @returns {{startNode: Node, startOffset: number, endNode: Node, endOffset: number, gca: Node} | null} An object containing the start and end nodes
 *          and offsets of the selection, along with the greatest common ancestor (gca) node of the selection. Returns null if no range is selected
 *          or if the selection is collapsed.
 */
export function getFroalaSelectionDetails(selection: Selection): {
    startNode: Node;
    startOffset: number;
    endNode: Node;
    endOffset: number;
    gca: Node;
} | null {
    if (selection.rangeCount === 0 || selection.isCollapsed) {
        return null;
    }

    const range = selection.getRangeAt(0);

    return {
        startNode: range.startContainer,
        startOffset: range.startOffset,
        endNode: range.endContainer,
        endOffset: range.endOffset,
        gca: range.commonAncestorContainer,
    };
}

export function moveToNextNode(treeWalker: TreeWalker, isEndNode: boolean): Node | null {
    if (isEndNode) {
        return null;
    }
    return treeWalker.nextNode();
}

// Omdat deze functie de selectie bewerkt, wordt ook de selectionchange listener continu aangeroepen wanneer er een selectie actief is.
// Niet verschrikkelijk, maar niet optimaal, dus later kijken wat daaran gedaan kan worden.
export function saveEditorSelectionAndAddEditorStateToMemento(editor: any): Memento {
    // call selection save to make sure we remember the last known caret position
    editor.selection.save();
    // Get the current HTML content, including the markers which we saved in the line above
    const state = editor.html.get(true);
    // call selection restore to restore the caret to the last known position
    editor.selection.restore();

    return new Memento(state);
}
