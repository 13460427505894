import { ColumnDef } from "@tanstack/react-table";
import DataTableColumnHeader from "../components/data-table/data-table-column-header";
import React from "react";
import { TenantUser } from "../types/auth";

const columns: ColumnDef<TenantUser>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Name" type="filter" />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex w-full items-start text-start">
                    <span className={"mb-0 w-full text-ellipsis overflow-hidden min-h-4"}>
                        {row.getValue("name")}
                    </span>
                </div>
            );
        },
        meta: {
            title: "Name",
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
    },
    {
        accessorKey: "username",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Username" type="filter" />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex w-full items-start text-start">
                    <span className={"mb-0 w-full text-ellipsis overflow-hidden min-h-4"}>
                        {row.getValue("username")}
                    </span>
                </div>
            );
        },
        meta: {
            title: "Username",
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
    },
    {
        accessorKey: "email",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title="Recovery email" type="filter" />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex w-full items-start text-start">
                    <span className={"mb-0 w-full text-ellipsis overflow-hidden min-h-4"}>
                        {row.getValue("email")}
                    </span>
                </div>
            );
        },
        meta: {
            title: "Recovery email",
        },
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
    },
];

export { columns };
