import { Addenda } from "../types/addenda";

const froalaConfigBasic = {
    key: process.env.FROALA_KEY,
    charCounterCount: false,
    documentReady: false,
    pasteDeniedAttrs: ["class", "id", "style"],
    wordDeniedAttrs: ["class", "id", "style"],
    attribution: false,
    toolbarSticky: true,
    quickInsertEnabled: false,
    wordPasteModal: false,
    pastePlain: true,
};

const standardAddendum: Pick<Addenda, "title" | "content"> = {
    title: "",
    content:
        '<p style="font-size: 12pt; text-align: right; font-weight: 400;">Addendum no.: </p>\n' +
        '<p style="font-size: 12pt; text-align: right; font-weight: 400;">Addendum date: </p>\n' +
        "<p><br></p>\n" +
        '<p style="font-size: 18pt; text-align: center; font-weight: 600;">ADDENDUM TO CHARTER-PARTY OF M/V “[FILL IN]" DATED [CHARTER PARTY DATE] at [CHARTER PARTY PLACE]</p>\n' +
        "<p><br></p>\n" +
        '<hr style="width: 35%;">\n' +
        "<p><br></p>\n" +
        "<p>It is mutually agreed between the Owners, <strong>[Owners name]</strong>, and the Charterers, <strong>[Charterers name]</strong> that:</p><p><br></p>\n" +
        "<p>--- Replace this line with the content of the Addendum ---</p><p><br></p>\n" +
        "<p>This Addendum shall have no effect on all other terms, conditions and exceptions of the Charter Party (including its other addenda, if any) as referenced above.</p><p><br></p><p><br></p>\n" +
        '<table style="width: 100%;">\n' +
        "    <tbody>\n" +
        "        <tr>\n" +
        '            <td style="width: 50.0000%; vertical-align: top; border-style: solid; border-color: black; border-width: 1px 1px 0px 1px;">&nbsp;Signature (Owners)</td>\n' +
        '            <td style="width: 50.0000%; vertical-align: top; border-style: solid; border-color: black; border-width: 1px 1px 0px 1px;">&nbsp;Signature (Charterers)</td>\n' +
        "        </tr>\n" +
        "        <tr>\n" +
        '            <td style="width: 50%; height: 75px; border-style: solid; border-color: black; border-width: 0px 1px 1px 1px;"><br></td>\n' +
        '            <td style="width: 50.0000%; height: 75px; border-style: solid; border-color: black; border-width: 0px 1px 1px 1px;"><br></td>\n' +
        "        </tr>\n" +
        "    </tbody>\n" +
        "</table>\n" +
        "<p><br></p>",
};

enum Tables {
    CASE_FILE_OVERVIEW = "case_file_overview",
    CASE_FILE_DETAIL = "case_file_detail",
    CASE_FILE_ATTACHMENTS = "case_file_attachments",
    CASE_FILE_AUDIT_TRAIL = "case_file_audit_trail",
    CASE_FILE_ARCHIVE = "case_file_archive",
    HOUSE_FORMS = "house_forms",
    INVOICES = "invoices",
    TEAM = "team",
}

type EnabledColumnsType = Array<{
    table_id: Tables;
    columns: Array<{ [key: string]: boolean }>;
}>;

export { froalaConfigBasic, standardAddendum, Tables, EnabledColumnsType };
