// import React, {Fragment, useState} from "react";
// import {DocumentModalInterface, GlobalDocumentInterface} from "../../../../../Documents/Overview/index.interface";
import styled from "styled-components";
// import {GlobalDocumentInterface} from "../../../../types/document-editor";
// import {
//     CancelButton,
//     FormButtonGroup,
//     StyledInlineErrorMessage,
//     SubmitButton,
// } from "../../../../../../../../../../unafix-frontend/src/Central/Form/FormField/style";
// import {SingleFileInterface} from "../../../../../Documents/index.interface";
// import {LoadingSpinner} from "../../../../../../../Components/Loading";
// import {ModalParagraphText} from "../../../../../../../Components/Modals";
// import {finalizeFile} from "../../../../../../Documents/Post";

export const ModalBody = styled.section`
    padding: 1.5em 1.5em 0.5em;
    max-height: calc(100vh - 180px);
    overflow: auto;
`;

// export const FinalizeFileModal = ({
//     row,
//     handleSubmit,
//     handleClose,
//     token,
// }: DocumentModalInterface<GlobalDocumentInterface | SingleFileInterface>) => {
//     const [error, setError] = useState<string | boolean>();
//     const [clicked, setClicked] = useState<boolean>(false);
//
//     function finalize() {
//         let error = false;
//
//         const hasSignatures = row.content?.includes("data-signature-id") ?? false;
//
//         setClicked(true);
//         finalizeFile(row.case_file_id, row.id, token, hasSignatures)
//             .then((r) => {
//                 if (typeof r === "boolean" && r) {
//                     handleSubmit();
//                 } else {
//                     setClicked(false);
//                     setError(r);
//
//                     error = true;
//
//                     return;
//                 }
//             })
//             .then(() => setClicked(false))
//             .finally(() => {
//                 if (!error) {
//                     handleClose();
//                 }
//             });
//     }
//
//     return (
//         <ModalBody>
//             {
//                 !clicked &&
//                 <Fragment>
//                     {
//                         error &&
//                         <StyledInlineErrorMessage>
//                             {
//                                 error
//                             }
//
// };                        </StyledInlineErrorMessage>
// }
// {
//     !error &&
//     <Fragment>
//         <ModalParagraphText>
//             On to the next voyage!
//         </ModalParagraphText>
//     </Fragment>
// }
//
// <FormButtonGroup>
//     <CancelButton type='button' onClick={handleClose}>Cancel</CancelButton>
//     {
//         !error &&
//         <SubmitButton type="submit" onClick={finalize}>Finalize</SubmitButton>
//     }
// </FormButtonGroup>
// </Fragment>
// }
// {
//     clicked &&
//     <Fragment>
//         <LoadingSpinner/>
//     </Fragment>
// }
// </ModalBody>
// );
