import React from "react";
import {
    DashboardTopBarContainerContent,
    DashboardTopBarContainerContentHeader,
    DashboardTopBarContainerContentSubTitle,
    DashboardTopBarContainerContentTitle,
    DashboardTopBarPerformanceItem,
    DashboardTopBarPerformanceItemContent,
    DashboardTopBarPerformanceItemTitle,
} from "../../Style";
import { DashboardOverviewInterface } from "../../index.interface";

export function DashboardOverview({ data }: DashboardOverviewInterface) {
    return (
        <DashboardTopBarContainerContent>
            <DashboardTopBarContainerContentHeader>
                <DashboardTopBarContainerContentTitle>
                    Performance overview
                </DashboardTopBarContainerContentTitle>
                <DashboardTopBarContainerContentSubTitle>
                    {data && data.performance && data.performance.period && (
                        <span>
                            Period: {data.performance.period[0]} - {data.performance.period[1]}
                        </span>
                    )}
                </DashboardTopBarContainerContentSubTitle>
            </DashboardTopBarContainerContentHeader>
            <DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItemTitle>
                    Total accounts created:
                </DashboardTopBarPerformanceItemTitle>
                <DashboardTopBarPerformanceItemContent>
                    {data?.performance?.tenants_created}
                </DashboardTopBarPerformanceItemContent>
            </DashboardTopBarPerformanceItem>
            <DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItemTitle>
                    Total users joined:
                </DashboardTopBarPerformanceItemTitle>
                <DashboardTopBarPerformanceItemContent>
                    {data?.performance?.users_joined}
                </DashboardTopBarPerformanceItemContent>
            </DashboardTopBarPerformanceItem>
            <DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItemTitle>
                    Total case files finalized:
                </DashboardTopBarPerformanceItemTitle>
                <DashboardTopBarPerformanceItemContent>
                    {data?.performance?.case_file_finalized_count}
                </DashboardTopBarPerformanceItemContent>
            </DashboardTopBarPerformanceItem>
            <DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItemTitle>
                    Total case files opened:
                </DashboardTopBarPerformanceItemTitle>
                <DashboardTopBarPerformanceItemContent>
                    {data?.performance?.case_file_total}
                </DashboardTopBarPerformanceItemContent>
            </DashboardTopBarPerformanceItem>
            <DashboardTopBarPerformanceItem>
                <DashboardTopBarPerformanceItemTitle>
                    Total revenue:
                </DashboardTopBarPerformanceItemTitle>
                <DashboardTopBarPerformanceItemContent>
                    €{data?.performance?.revenue / 100}
                </DashboardTopBarPerformanceItemContent>
            </DashboardTopBarPerformanceItem>
        </DashboardTopBarContainerContent>
    );
}
