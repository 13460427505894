import axios from "axios";
import { GlobalDocumentInterface } from "../../types/document-editor";

export async function fetchSingleDocument(
    id: string,
    case_file_id: string,
): Promise<{ data: GlobalDocumentInterface }> {
    return await axios
        .get(`/api/tenant/v1/${case_file_id}/case-file/fetch-single-file/${id}`, {
            headers: {
                Accept: "application/json",
            },
        })
        .then((res) => res.data);
}

export async function setActiveCollaborator(
    id: string,
    collaboratorId: string,
    scope: string = "documents",
    token?: string,
    socket_channel?: string,
) {
    return await axios
        .post(
            `/api/tenant/v1/documents/grant-access/${id}`,
            {
                collaboratorId: collaboratorId,
                scope: scope,
                socket_channel: socket_channel,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                    Accept: "application/json",
                },
            },
        )
        .then((r) => Boolean(r.data));
}
