import TypedFroalaEditor from "froala-editor-typings";
import { ChangeLogV2 } from "../ChangeLogV2";
import { TenantUser } from "../../types/auth";
import WriteCommand from "./Command/WriteCommand";
import CommandHistory from "./Command/CommandHistory";
import BackspaceCommand from "./Command/BackspaceCommand";
import DeleteCommand from "./Command/DeleteCommand";
import EnterCommand from "./Command/EnterCommand";
import PasteCommand from "./Command/PasteCommand";
import ReinstateCommand from "./Command/ReinstateCommand";
import SnapshotCommand from "./Command/SnapshotCommand";

type TrackChangesEventTypes =
    | "keydown"
    | "keyup"
    | "paste.before"
    | "paste.after"
    | "window.cut"
    | "commands.before"
    | "commands.after"
    | "select";

export class TrackChangesV3 {
    private static instance: TrackChangesV3;
    private _trackChangesEnabled: boolean;
    private _user: TenantUser | undefined;
    private _editor: any | undefined;
    private _caseFileId: TenantUser | string | undefined;
    private changeLogV2: ChangeLogV2;
    private commandHistory: CommandHistory;

    constructor(
        editor: TypedFroalaEditor.FroalaEditor | undefined,
        user: TenantUser | undefined,
        caseFileId: string | undefined,
    ) {
        this.editor = editor;
        this.user = user;
        this.caseFileId = caseFileId;
        this.changeLogV2 = ChangeLogV2.getInstance();
    }

    /**
     * Make the class instance publicly available
     */
    static getInstance() {
        if (!TrackChangesV3.instance) {
            TrackChangesV3.instance = new TrackChangesV3(undefined, undefined, undefined);
        }

        return TrackChangesV3.instance;
    }

    /**
     * Initialize the plugin
     * @param editor
     * @param user
     * @param caseFileId
     * @param documentId
     * @param formatting_enabled
     * @param token
     */
    initialize(
        editor: TypedFroalaEditor.FroalaEditor,
        user: TenantUser,
        caseFileId: string | undefined,
        documentId: string,
        formatting_enabled: boolean,
        token?: string,
    ) {
        this.editor = editor;
        this.user = user;
        this.caseFileId = caseFileId;
        this.trackChangesEnabled = formatting_enabled;
        this.commandHistory = new CommandHistory();

        if (caseFileId || documentId) {
            this.changeLogV2 = ChangeLogV2.getInstance();

            this.changeLogV2.init(
                editor,
                documentId,
                formatting_enabled,
                user.id,
                caseFileId ?? "",
                token,
            );
        }

        // Cleanup reinstate panel on scroll
        const editorElement = document.querySelectorAll(".fr-wrapper")[0] as HTMLElement;
        editorElement.addEventListener("scroll", () => {
            const panel = document.getElementById("floatingPanel");
            if (panel) {
                panel.remove(); // Remove the panel when scrolling occurs
            }
        });

        // Cleanup reinstate panel on outside click
        window.addEventListener("mousedown", (event) => {
            const panel = document.getElementById("floatingPanel");
            // Check if event.target is a Node and not just any EventTarget
            if (panel && event.target instanceof Node) {
                if (!panel.contains(event.target)) {
                    panel.remove(); // Remove the panel only if the click was outside the panel
                }
            }
        });
    }

    /**
     * Map the events to the corresponding methods
     * @param type
     * @param e
     */
    mapEvents(type: TrackChangesEventTypes, event: any): void {
        if (!this.user) {
            return;
        }

        switch (type) {
            case "select": {
                const selection = document.getSelection();
                if (selection && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    const rect = range.getBoundingClientRect();
                    const snapshot = new SnapshotCommand(this.editor, event, this.user!);
                    const reinstateCommand = new ReinstateCommand(
                        this.editor,
                        rect,
                        // This is only pushed when the reinstate happens
                        () => {
                            this.commandHistory.push(snapshot);
                        },
                    );
                    reinstateCommand.execute();
                }
                break;
            }
            case "keydown":
                this.handleKeyDownEvent(event);
                break;
            case "paste.before": {
                const pasteCommand = new PasteCommand(this.editor, event, this.user);
                pasteCommand.execute();
                this.commandHistory.push(pasteCommand);
                break;
            }
            case "window.cut": {
                const selection = document.getSelection();
                if (!selection) {
                    return;
                }
                // Store selection in clipboard
                navigator.clipboard.writeText(selection.toString());
                // Call backspace command to simulate the deletion part of a cut action
                const backspaceCommand = new BackspaceCommand(this.editor, event, this.user!);
                this.commandHistory.push(backspaceCommand);
                backspaceCommand.execute();
                break;
            }
        }
    }

    undo(): void {
        this.commandHistory.undo();
    }

    /**
     * Handle the keydown events, i.e. deletion or addition
     * @param event
     * @private
     */
    private handleKeyDownEvent(event: KeyboardEvent) {
        // Keyboard boolean states
        const isCtrlKey = event.ctrlKey || event.metaKey;
        const isShiftKey = event.key === "Shift";

        if (isCtrlKey && event.key === "z") {
            this.undo();
            return;
        }

        if (!isCtrlKey && !isShiftKey) {
            switch (event.key) {
                case "Backspace": {
                    const backspaceCommand = new BackspaceCommand(this.editor, event, this.user!);
                    backspaceCommand.execute();
                    this.commandHistory.push(backspaceCommand);
                    break;
                }
                case "Delete": {
                    const deleteCommand = new DeleteCommand(this.editor, event, this.user!);
                    deleteCommand.execute();
                    this.commandHistory.push(deleteCommand);
                    break;
                }
                case "Enter": {
                    const enterCommand = new EnterCommand(this.editor, event, this.user!);
                    enterCommand.execute();
                    this.commandHistory.push(enterCommand);
                    break;
                }
                default: {
                    const writeCommand = new WriteCommand(this.editor, event, this.user!);
                    writeCommand.execute();
                    this.commandHistory.push(writeCommand);
                    break;
                }
            }

            return false;
        }
    }

    get trackChangesEnabled(): boolean {
        return this._trackChangesEnabled;
    }

    set trackChangesEnabled(value: boolean) {
        this._trackChangesEnabled = value;
    }

    get editor(): any {
        return this._editor;
    }

    set editor(value: any) {
        this._editor = value;
    }

    get user(): TenantUser | undefined {
        return this._user;
    }

    set user(value: TenantUser | undefined) {
        this._user = value;
    }

    get caseFileId(): TenantUser | string | undefined {
        return this._caseFileId;
    }

    set caseFileId(value: TenantUser | string | undefined) {
        this._caseFileId = value;
    }
}
