import React, { Fragment, useEffect, useState } from "react";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../../components/ui/sheet";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { TemplateIndex, TemplatesType, TemplateTypes } from "../../types/case-files";
import { useLazyGetTemplatesQuery } from "../../api/tenant/case-file";
import SearchableList from "../../components/searchable-list/searchable-list";
import { Zap } from "lucide-react";
import { toast } from "sonner";
import { useCreateHouseFormMutation } from "../../api/tenant/house-forms";
import { useNavigate } from "react-router";

export default function CreateHouseForm() {
    const [templates, setTemplates] = useState<TemplatesType>({});
    const [getTemplates] = useLazyGetTemplatesQuery();
    const [templatesLoaded, setTemplatesLoaded] = useState<boolean>(false);
    const [createHouseForm] = useCreateHouseFormMutation();

    const schema = z.object({
        document_name: z.string().min(2, {
            message: "Document name must be at least 2 characters.",
        }),
        type: z.enum(["standard_form", "bill_of_lading"]),
        template_id: z.string(),
    });

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            document_name: "new template",
            type: "standard_form",
            template_id: undefined,
        },
    });

    const navigate = useNavigate();

    useEffect(() => {
        handleFetchTemplates("standard_form");
    }, []);

    async function handleSubmit(values: z.infer<typeof schema>) {
        try {
            const houseForm = await createHouseForm({
                name: values.document_name,
                template_id: values.template_id,
            }).unwrap();

            toast.success("successfully created " + values.document_name);

            navigate(`/templates/${houseForm.id}`);
        } catch (e) {
            toast.error("Something went wrong while creating template");
        }
    }

    async function handleFetchTemplates(type: TemplateTypes) {
        const mappedTemplates = templates as TemplatesType;

        if (!Object.prototype.hasOwnProperty.call(mappedTemplates, type)) {
            setTemplatesLoaded(false);
            mappedTemplates[type] = await getTemplates(type).unwrap();
            setTemplates(mappedTemplates);
        }
        setTemplatesLoaded(true);
    }

    function getTemplatesList(type: TemplateTypes) {
        return templates[type] ?? [];
    }

    const { watch } = form;

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button>New template</Button>
            </SheetTrigger>
            <SheetContent className="bg-white">
                <SheetHeader>
                    <SheetTitle className="text-slate-950">New template</SheetTitle>
                </SheetHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <FormField
                            control={form.control}
                            name="document_name"
                            render={({ field }) => (
                                <FormItem className="py-2">
                                    <FormLabel>
                                        Template name{" "}
                                        <span className="text-sm text-muted-foreground">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="type"
                            render={({ field }) => (
                                <FormItem className="py-2">
                                    <FormLabel>Document type</FormLabel>
                                    <Select
                                        onValueChange={async (value: TemplateTypes) => {
                                            field.onChange(value);
                                            await handleFetchTemplates(value);
                                            form.resetField("template_id");
                                        }}
                                        defaultValue={field.value}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select what type of document" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectItem value="standard_form">
                                                Standard form
                                            </SelectItem>
                                            <SelectItem value="bill_of_lading">
                                                Bill of lading
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {watch("type") && templates && templatesLoaded && (
                            <FormField
                                control={form.control}
                                name="template_id"
                                render={({ field }) => (
                                    <FormItem className="py-2 flex flex-col">
                                        <FormLabel>Template</FormLabel>
                                        <SearchableList<TemplateIndex>
                                            groups={[
                                                {
                                                    heading: undefined,
                                                    options: getTemplatesList(watch("type")),
                                                },
                                            ]}
                                            selectedValues={
                                                new Set([
                                                    getTemplatesList(watch("type")).find(
                                                        (template) => template.id === field.value,
                                                    ),
                                                ])
                                            }
                                            onOptionSelected={(option) => {
                                                if (!option) {
                                                    field.onChange(undefined);
                                                } else if (option.size > 0) {
                                                    const [value] = option;
                                                    if (value) {
                                                        field.onChange(value.id);
                                                        form.setValue("document_name", value?.name);
                                                    }
                                                } else {
                                                    field.onChange(undefined);
                                                }
                                            }}
                                            searchPlaceholder="Search templates"
                                            className="border border-input border-solid"
                                            commandItemSelectedClassName="bg-blue-400 hover:bg-blue-400"
                                            labelFormatter={(value) => value.name}
                                        >
                                            {(props) => (
                                                <Fragment>
                                                    <span
                                                        className={`flex items-center ${
                                                            props.isSelected
                                                                ? "text-white"
                                                                : "text-gray-950"
                                                        }`}
                                                    >
                                                        <Zap
                                                            className={`h-4 w-4 min-w-[16px] stroke-transparent mr-4 ${
                                                                props.isSelected
                                                                    ? "fill-white"
                                                                    : "fill-blue-400"
                                                            }
                                                                            ${
                                                                                props.option.value
                                                                                    .is_smart
                                                                                    ? "opacity-100"
                                                                                    : "opacity-0"
                                                                            }`}
                                                        />
                                                        <span className="text-balance">
                                                            {props.option.value.name}
                                                        </span>
                                                    </span>
                                                </Fragment>
                                            )}
                                        </SearchableList>
                                        <FormDescription className="flex">
                                            Is a template missing? &nbsp;
                                            <a
                                                href="https://tally.so/r/w2XEqb"
                                                target="_blank"
                                                tabIndex={-1}
                                                className="text-primary underline-offset-4 underline cursor-pointer"
                                                rel="noreferrer"
                                            >
                                                Please submit a request.
                                            </a>
                                        </FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        )}
                        <SheetFooter className="mt-4">
                            <SheetClose asChild>
                                <Button type="submit" disabled={!form.formState.isValid}>
                                    Create
                                </Button>
                            </SheetClose>
                        </SheetFooter>
                    </form>
                </Form>
            </SheetContent>
        </Sheet>
    );
}
