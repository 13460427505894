import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import React, { Fragment, useEffect } from "react";
import DocumentsTable from "./documents-table";
import Loading from "../../../components/global/loading";
import { Typography } from "../../../components/ui/typography";
import Chat from "./chat";
import Permissions from "./permissions";
import { useDispatch } from "react-redux";
import { tenantApi } from "../../../api/tenantApi";
import { usePageTitle } from "../../../lib/usePageTitle";

function Detail() {
    const { documents, setActivePage, case_file_id, permissions, meta_data } = useCaseFiles();
    const dispatch = useDispatch();

    useEffect(() => {
        setActivePage("overview");

        return () => {
            dispatch(tenantApi.util?.invalidateTags(["CaseFileMetaData"]));
        };
    }, []);

    usePageTitle(`CP: ${meta_data?.reference}`);

    return (
        <Loading
            loaded={permissions !== undefined}
            child={
                <Fragment>
                    <div className="p-4 w-full flex items-stretch h-full">
                        <div className="bg-foreground p-4 rounded-sm w-2/3">
                            <DocumentsTable data={documents} />
                        </div>
                        <div className="flex flex-col w-1/3 gap-4">
                            <div className="flex flex-col justify-center ml-4 bg-foreground p-4 h-1/2 rounded-sm">
                                <Typography text="Chat" style="h3" className="mb-4" />
                                <Chat id={case_file_id} />
                            </div>
                            <div className="flex flex-col ml-4 bg-foreground p-4 h-1/2 rounded-sm">
                                <div className="bg-foreground rounded-sm">
                                    <Permissions />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        />
    );
}

export default Detail;
