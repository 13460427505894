import React, { Fragment } from "react";
import {
    DashboardBodyContainer,
    DashboardBodyMainContainer,
    DashboardBodySideContainer,
} from "../../Style";
import { DashboardRevenueTrends } from "../Revenue";
import { DashboardAccountTrends } from "../Accounts";
import { DashboardBodyInterface } from "../../index.interface";

export function DashboardBody({ accountsData, revenueData }: DashboardBodyInterface) {
    return (
        <Fragment>
            <DashboardBodyContainer>
                <DashboardBodyMainContainer>
                    <DashboardRevenueTrends data={revenueData} />
                </DashboardBodyMainContainer>
                <DashboardBodySideContainer>
                    <DashboardAccountTrends data={accountsData} />
                </DashboardBodySideContainer>
            </DashboardBodyContainer>
        </Fragment>
    );
}
