import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { ScrollArea } from "../../../components/ui/scroll-area";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/ui/table";
import { Switch } from "../../../components/ui/switch";
import React, { useState } from "react";
import { useRemoveRecapStructureMutation } from "../../../api/tenant/recap";
import { toast } from "sonner";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { RecapFieldInterface } from "../../../types/recap";
import { Eraser } from "lucide-react";

type CleanEmptyFieldsProps = {
    emptyFields: Array<RecapFieldInterface>;
    recapId: string;
    isUserActive: boolean;
};

function CleanEmptyFields({ emptyFields, recapId, isUserActive }: CleanEmptyFieldsProps) {
    const [fieldsToRemove, setFieldsToRemove] = useState<Array<string>>(
        emptyFields.map((x) => x.fieldId),
    );
    const [removeRecapFields] = useRemoveRecapStructureMutation();
    const { case_file_id } = useCaseFiles();

    function resetCheckedFields() {
        setFieldsToRemove(emptyFields.map((x) => x.fieldId));
    }

    async function handleSubmit() {
        try {
            await removeRecapFields({
                id: recapId,
                fields: fieldsToRemove,
                case_file_id: case_file_id,
            }).unwrap();

            toast.message("Cleaned up the recap");
        } catch (e) {
            toast.error("Something went wrong while cleaning up the recap");
        }
    }

    return (
        <Dialog onOpenChange={resetCheckedFields}>
            <DialogTrigger asChild>
                <Button variant="ghost" className="p-0 h-auto" disabled={!isUserActive}>
                    <Eraser className="mr-2 h-4 w-4" />
                    <span>Clean up</span>
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Clean up empty fields</DialogTitle>
                    <DialogDescription>
                        This will remove all selected fields from the current recap
                    </DialogDescription>
                </DialogHeader>
                <ScrollArea className="h-80">
                    <Table>
                        <TableHeader className="top-0 sticky bg-background z-50">
                            <TableHead>Field</TableHead>
                            <TableHead className="w-16 text-right">Clear</TableHead>
                        </TableHeader>
                        <TableBody>
                            {emptyFields.map((field, index) => (
                                <TableRow key={index}>
                                    <TableCell>{field.fieldName}</TableCell>
                                    <TableCell className="float-right">
                                        <Switch
                                            checked={fieldsToRemove.includes(field.fieldId)}
                                            onCheckedChange={() => {
                                                if (fieldsToRemove.includes(field.fieldId)) {
                                                    setFieldsToRemove(
                                                        fieldsToRemove.filter(
                                                            (x) => x !== field.fieldId,
                                                        ),
                                                    );
                                                } else {
                                                    setFieldsToRemove([
                                                        ...fieldsToRemove,
                                                        field.fieldId,
                                                    ]);
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </ScrollArea>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button onClick={handleSubmit}>Clean up fields</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default CleanEmptyFields;
