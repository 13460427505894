import { NotificationProps } from "../../types/notifications";
import { Typography } from "../ui/typography";
import React, { Fragment } from "react";
import { useNotifications } from "../../lib/providers/notifications-provider";
import { formatDistance } from "date-fns";
import { Separator } from "../ui/separator";

function SingleThreadNotification({ notification }: NotificationProps) {
    const { generateMessage } = useNotifications();
    return (
        <div className="space-y-1 pt-4">
            <Typography
                text={
                    <Fragment>
                        {notification.status === "new" ? (
                            <span className="flex h-2 w-2 rounded-full bg-sky-500 mr-2" />
                        ) : null}
                        <div>
                            <b>{notification.messages[0].user_name}</b>
                            {generateMessage(notification.messages[0].action_type)}
                            {formatDistance(
                                new Date(notification.messages[0].updated_at),
                                new Date(),
                                { addSuffix: true },
                            )}
                        </div>
                    </Fragment>
                }
                style="p"
                className="text-sm font-medium leading-none flex items-center"
            />
            <Typography
                text={`
                    ${formatDistance(new Date(notification.messages[0].updated_at), new Date(), { addSuffix: true })}
                     - 
                    ${notification.channel.channel_name}
                `}
                style="muted"
                className="ml-4"
            />
            <Separator />
        </div>
    );
}

export default SingleThreadNotification;
