import { tenantApi } from "../tenantApi";
import { UploadedFile } from "../../types/uploads";
import { WithCaseFileId } from "../../types";

export const attachmentsApi = tenantApi.injectEndpoints({
    endpoints: (build) => ({
        getUploadedFiles: build.query<Array<UploadedFile>, string>({
            query: (id) => ({
                url: `/${id}/case-file/uploads`,
            }),
            providesTags: (result: Array<UploadedFile>) => [
                ...(result
                    ? result.map(({ id }) => ({ type: "CaseFileFileUploads", id }) as const)
                    : []),
                { type: "CaseFileFileUploads" as const, id: "LIST" },
            ],
        }),
        uploadFiles: build.mutation<boolean, WithCaseFileId<{ formData: FormData }>>({
            query: ({ case_file_id, formData }) => ({
                url: `/${case_file_id}/case-file/upload`,
                method: "POST",
                body: formData,
                formData: true,
            }),
            invalidatesTags: [
                {
                    type: "CaseFileFileUploads",
                    id: "LIST",
                },
                {
                    type: "Documents",
                    id: "LIST",
                },
            ],
        }),
        downloadFile: build.query<Blob | string, WithCaseFileId<{ id: string }>>({
            query: ({ id, case_file_id }) => ({
                url: `/${case_file_id}/case-file/download-file/${id}`,
                responseHandler: (response) =>
                    response.blob().then((blob) => URL.createObjectURL(blob)),
            }),
        }),
    }),
});

export const { useUploadFilesMutation, useGetUploadedFilesQuery, useDownloadFileQuery } =
    attachmentsApi;
