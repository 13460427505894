import {
    BookText,
    CalendarClock,
    Coins,
    Container,
    FilePenLine,
    Fuel,
    Route,
    Scale,
    Ship,
    Text,
    Users,
} from "lucide-react";
import React from "react";

export function RecapCategoryIcon({
    className,
    categoryId,
}: {
    className?: string;
    categoryId: string;
}) {
    switch (categoryId) {
        case "28ea9e91-8329-4630-938a-1f57f8ad352e":
            return <Ship className={className} />;
        case "cc226e38-f7fa-46f4-a148-81feacd85322":
            return <Users className={className} />;
        case "61c85e20-9cdd-4436-bd2c-8701d1315efd":
            return <FilePenLine className={className} />;
        case "5b317d3f-a6c4-459b-9e1e-aa42d6ef7419":
            return <Container className={className} />;
        case "3671d12e-1122-4e54-9a27-bafc74a89092":
            return <Route className={className} />;
        case "7d49560b-da3c-4b5f-88e7-bd1600cc0b02":
            return <Scale className={className} />;
        case "037032ad-a0f6-42bf-86a3-8aa02ebc18a0":
            return <Text className={className} />;
        case "e82a2e5d-c9b5-450a-9bf0-1bc257d5bb39":
            return <Coins className={className} />;
        case "e1185120-77fa-4769-b8f0-5c5bfbfc5d64":
            return <Fuel className={className} />;
        case "9b540251-8fae-4c32-a027-7ccc4b2b0f4c":
            return <CalendarClock className={className} />;
        default:
            return <BookText className={className} />;
    }
}
