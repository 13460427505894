import React, { Fragment, useState } from "react";
import { AdminUserInfoTenantInterface } from "../../index.interface";
import {
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { DataTableToolbar } from "../../../../../components/data-table/data-table-toolbar";
import DataTable from "../../../../../components/data-table/data-table";
import { columns } from "../../../../../config/users";

export function AdminUserInfoTenant({ tenant }: AdminUserInfoTenantInterface) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
            owner: false,
        }),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        data = tenant.users,
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    return (
        <Fragment>
            <section className="p-6 rounded-2xl basis-1/2 inline-flex self-stretch flex-col">
                <div className="inline-flex justify-between w-full items-center h-12 pb-4">
                    <h2 className="font-medium text-xl">Authors</h2>
                </div>
                <div className="flex items-center pb-4 w-full justify-between">
                    <DataTableToolbar table={table} searchableColumns={["name", "email"]} />
                </div>
                <DataTable
                    columns={columns}
                    table={table}
                    onClick={() => {
                        void 0;
                    }}
                />
            </section>
        </Fragment>
    );
}
