import styled from "styled-components";
import { borderThin } from "../../styles/mixins";
import { ModalBody } from "../../../components/style-components/Modals/Finalize";
import { FormButtonGroup } from "../../Form/FormField/style";
export const NewTemplateWrapper = styled.section`
    display: flex;
    height: 100%;
`;

export const NewTemplateSettingsWrapper = styled(NewTemplateWrapper)`
    height: calc(100vh - 200px);
`;

export const NewTemplateSettingsContainer = styled.section`
    display: inline-flex;
    width: 25%;
    height: calc(100vh - 115px);
    border-right: ${borderThin("darker", 0.1)};
    flex-direction: column;
    padding: 0 2em 0 0;
`;

export const NewTemplateEditorContainer = styled.section`
    display: inline-flex;
    padding: 0 0 0 2em;
    flex-direction: column;
`;

export const TemplateContainer = styled(ModalBody)`
    padding: 0 3em;
    height: 100vh;
`;

export const FormButtonGroupContainer = styled(FormButtonGroup)`
    width: 100%;
`;

export const TemplateWrapperContainer = styled(NewTemplateWrapper)`
    height: calc(100vh - 200px);
`;
