import axios from "axios";
import { BillingInfo } from "../../../types/billing";
import { TenantAdminInterface, TenantDetailPlan } from "../../../types/Central";

export type ThemeTypes = "light" | "dark";

export async function fetchTenants(): Promise<GetTenantIndexResponse> {
    return await axios.get("/admin/api/v1/tenants").then((res) => res.data);
}

export async function getTenant(tenantId: string): Promise<ShowTenantResponse> {
    return await axios.get(`/admin/api/v1/tenants/${tenantId}`).then((res) => {
        return {
            data: res.data,
        };
    });
}

export async function updateTenant(data: TenantDetailPlan): Promise<Tenant> {
    return await axios.post("/admin/api/v1/tenants", data).then((res) => res.data);
}

export async function vetTenant(
    tenant: TenantAdminInterface,
    approve: boolean,
): Promise<ShowTenantResponse> {
    return await axios
        .post("/admin/api/v1/tenants/vet", {
            tenantId: tenant.id,
            accept: approve,
        })
        .then((res) => res.data);
}

export async function changeVisibilityTenant(id: string, action: string) {
    return await axios.post("/admin/api/v1/hidden", {
        id: id,
        action: action,
    });
}

type PlanType = "trial" | "paid";

export interface TenantAccountUser {
    approval_status: string;
    email: string;
    email_verified_at?: Date;
    id: string;
    invoice_threshold: number;
    is_admin: number;
    last_logged_in?: Date;
    name: string;
    plan: PlanType;
    profile_picture: any;
    tenant_created_at: Date;
    tenant_id: string;
    trial_ends_at?: Date;
    username: string;
}

export interface TenantUser {
    id: string;
    username: string;
    name: string;
    email: string;
    email_verified_at?: Date;
    approval_status: string;
    last_logged_in?: Date;
    created_at: Date;
    updated_at: Date;
    is_admin: number;
    plan: PlanType;
    tenant_created_at: Date;
    draft?: number;
    final?: number;
    tenant_id?: string;
    theme: ThemeTypes;
}

export interface Tenant {
    id: string;
    created_at: Date;
    updated_at: Date;
    users: Array<TenantUser>;
    billing_info?: Array<BillingInfo>;
    casefile_all_count?: number;
    casefile_final_count?: number;
    invoice_threshold: number;
    plan: string;
    trial_ends_at?: Date | null;
    two_factor_auth: boolean;
    approval_status: string;
    email_verified: boolean;
    hidden: boolean;
    rot_enabled: boolean;
}

export interface GetTenantIndexResponse {
    data: Array<TenantAdminInterface>;
}

export interface ShowTenantResponse {
    data: Tenant;
}
