import React from "react";
import { css } from "styled-components";
import { ThemeInterface, darkTheme, theme } from "../index";

export function AppCard() {
    return css`
        background-color: ${color("white")};
        border-radius: 0.25em;
        box-shadow: ${boxShadow("basic")("darker", 0.1)};
    `;
}

export function AppCardWithoutBorderRadius() {
    return css`
        background-color: ${color("white")};
        box-shadow: ${boxShadow("blurry")("darker", 0.1)};
    `;
}

export function TitleText() {
    return css`
        font-size: 1.25em;
        font-weight: 600;
        color: ${color("dark")};
    `;
}

export function flexRow() {
    return css`
        display: flex;
        flex-direction: row;
    `;
}

export function flexColumn() {
    return css`
        display: flex;
        flex-direction: column;
    `;
}

export function DividingBorder() {
    return css`
        border-bottom: ${borderThin("paleBlue")};
    `;
}

export function HideScrollBars() {
    return css`
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    `;
}

export function marginTextAreaLabelRaw(isSmartField: boolean) {
    const margin = theme.margin.textarea.label;
    return isSmartField ? em(margin["smartfield"]) : em(margin["normal"]);
}

export function changeColorWhenActive(colorWhenActive: string, colorWhenInactive: string) {
    return function (props: React.ComponentProps<any>) {
        if (props.active) {
            return props.theme.color.main[colorWhenActive];
        }

        return props.theme.color.main[colorWhenInactive];
    };
}

/** Takes in a string and returns a color value:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 * Only works in styled components (use colorRaw()() instead).
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 *
 * @param {string} colorValue - the color hex or rgb value
 * @param {number} opacity - the optional opacity value
 * @example
 * color('darker', .1)
 *     returns: rgba(52, 52, 52, 0.1)
 * color('darker')
 *      returns: #212121
 **/
export function color(colorValue: string, opacity?: number) {
    return function (props: React.ComponentProps<any>) {
        if (Object.keys(props?.theme).length) {
            if (opacity) {
                const color = props.theme.rgbacolor.main[colorValue];
                return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity ?? color.a ?? 1})`;
            }

            return props.theme.color.main[colorValue];
        } else {
            if (opacity) {
                const color = theme.rgbacolor.main[colorValue];
                return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity ?? color.a ?? 1})`;
            }

            return theme.color.main[colorValue];
        }
    };
}

/** Takes in a string and returns a color value:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 * Doesn't work in styled components (use color() instead).
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 *
 * @param {string} colorValue - the color hex or rgb value
 * @param {number} opacity - the optional opacity value
 * @example
 * colorRaw('darker', .1)()
 *     returns: rgba(52, 52, 52, 0.1)
 * colorRaw('darker')()
 *     returns: #212121
 */
export function colorRaw(colorValue: string, opacity?: number) {
    /**
     * @param {ThemeInterface} activeTheme - the theme (dark mode or light mode)
     */
    return function (activeTheme: ThemeInterface = darkTheme) {
        if (opacity) {
            const color = activeTheme.rgbacolor.main[colorValue];
            return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity ?? color.a ?? 1})`;
        }

        return activeTheme.color.main[colorValue];
    };
}

/** Takes in a string and returns a 'thin'-sized (in px) dashed border with a hex color:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.borderSize] for border sizes
 *
 * @param {string} borderColor - the color hex or rgb value
 * @param {number} borderOpacity - the optional opacity value
 * @example
 * borderThinDashed('darker', 1)
 *     returns: 1px dashed rgba(52, 52, 52, 1)
 */
export function borderThinDashed(borderColor: string, borderOpacity?: number) {
    return function (props: React.ComponentProps<any>) {
        if (Object.keys(props?.theme).length) {
            return `${px(props.theme.borderSize.px["thin"])} dashed ${color(borderColor, borderOpacity ?? undefined)(props)}`;
        }

        return `${px(theme.borderSize.px["thin"])} dashed ${color(borderColor, borderOpacity ?? undefined)(props)}`;
    };
}

/** Takes in a string and returns a 'thin'-sized (in px) solid border with a hex color:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.borderSize] for border sizes
 *
 * @param {string} borderColor - the color hex or rgb value
 * @param {number} borderOpacity - the optional opacity value
 * @example:
 * borderThin('darker', 1)
 *     returns: 1px solid rgba(52, 52, 52, 1)
 */
export function borderThin(borderColor: string, borderOpacity?: number) {
    return function (props: React.ComponentProps<any>) {
        if (Object.keys(props?.theme).length) {
            return `${px(props.theme.borderSize.px["thin"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
        }

        return `${px(theme.borderSize.px["thin"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
    };
}

/** Takes in a string and returns a 'normal'-sized (in px) solid border with a hex color:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.borderSize] for border sizes
 *
 * @param {string} borderColor - the color hex or rgb value
 * @param {number} borderOpacity - the optional opacity value
 * @example
 * border('darker', 1)
 *     returns: 2px solid rgba(52, 52, 52, 1)
 */
export function border(borderColor: string, borderOpacity?: number) {
    return function (props: React.ComponentProps<any>) {
        if (Object.keys(props?.theme).length) {
            return `${px(props.theme.borderSize.px["normal"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
        }

        return `${px(theme.borderSize.px["normal"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
    };
}

/** Takes in a string and returns a 'thick'-sized (in px) solid border with a hex color:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.borderSize] for border sizes
 *
 * @param {string} borderColor - the color hex or rgb value
 * @param {number} borderOpacity - the optional opacity value
 * @example
 * borderThick('darker', 1)
 *     returns: 3px solid rgba(52, 52, 52, 1)
 */
export function borderThick(borderColor: string, borderOpacity?: number) {
    return function (props: React.ComponentProps<any>) {
        if (Object.keys(props?.theme).length) {
            return `${px(props.theme.borderSize.px["thick"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
        }

        return `${px(theme.borderSize.px["thick"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
    };
}

/** Takes in a string and returns a 'normal'-sized (in em) solid border with a hex color:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.borderSize] for border sizes
 *
 * @param {string} borderColor - the color hex or rgb value
 * @param {number} borderOpacity - the optional opacity value
 * @example
 * borderEm('darker', 1)
 *     returns: 1em solid rgba(52, 52, 52, 1)
 */
export function borderEm(borderColor: string, borderOpacity?: number) {
    return function (props: React.ComponentProps<any>) {
        if (Object.keys(props?.theme).length) {
            return `${em(props.theme.borderSize.em["normal"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
        }

        return `${em(theme.borderSize.em["normal"])} solid ${color(borderColor, borderOpacity ?? undefined)(props)}`;
    };
}

/** Takes in a string and returns a 'thin'-sized (in em) solid border with a hex color.
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.borderSize] for border sizes
 *
 * @param {string} borderColor - the color hex or rgb value
 * @param {number} borderOpacity - the optional opacity value
 * @example
 * borderThinEm('darker', 1)
 *     returns: 0.5em solid rgba(52, 52, 52, 1)
 */
export function borderThinEm(borderColor: string, borderOpacity?: number) {
    return function (props: React.ComponentProps<any>) {
        if (Object.keys(props?.theme).length) {
            return `${em(props.theme.borderSize.em["thin"])} solid ${color(borderColor, borderOpacity)}`;
        }

        return `${em(theme.borderSize.em["thin"])} solid ${color(borderColor, borderOpacity)}`;
    };
}

/** This function has two parentheses: (boxShadowDiv()())
 * The first parentheses takes in a string and looks up the pixel values in [theme.boxShadow]
 * The second parentheses takes in a string and returns a color value (must use 2 params):
 *     It looks up an rgba color in [theme.rgba.color] and uses the second param for the division percentage
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.boxShadow] for box-shadow pixel values in styles/index.js
 *
 * @param {string} typeValue - the px values of the box-shadow are represented by this value
 * @example
 * ${boxShadowDiv('transHead')('black', 20)}
 *     returns:  0px 2px 4px -1px rgba(0 0 0 / 20%)
 */
export function boxShadowDiv(typeValue: string) {
    return function (colorValue: string, percValue: number) {
        return function (props: React.ComponentProps<any>) {
            let bSType = theme.boxShadow[typeValue];
            let bSColor = theme.rgbacolor.main[colorValue];

            if (Object.keys(props?.theme).length) {
                bSType = props.theme.boxShadow[typeValue];
                bSColor = props.theme.rgbacolor.main[colorValue];
            }

            return `${px(bSType.px1)} ${px(bSType.px2)} ${px(bSType.px3)} ${px(bSType.px4)} rgb(${bSColor.r} ${bSColor.g} ${bSColor.b} / ${percent(percValue)})`;
        };
    };
}

export function borderStyleHidden() {
    return function () {
        return css`
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom-style: hidden;
        `;
    };
}

/** This function has two parentheses: (boxShadowDiv()())
 * The first parentheses takes in a string and looks up the pixel values in [theme.boxShadow]
 * The second parentheses takes in a string and returns a color value:
 *     IF you don't add a second param: it looks up a hex color in [theme.color.main]
 *     IF you DO add a second param: looks up an rgba color in [theme.rgba.color]
 *         and uses the second param for the opacity
 *
 * check [theme.color.main] and [theme.rgbacolor.main] for color params in styles/index.js
 * check [theme.boxShadow] for box-shadow pixel values in styles/index.js
 *
 * @example
 * ${boxShadow('transHead')('black', 1)}
 *     returns:  0px 2px 4px -1px rgba(0, 0, 0, 1)
 */
export function boxShadow(typeValue: string) {
    return function (colorValue: string, boxOpacity?: number) {
        return function (props: React.ComponentProps<any>) {
            let bSType = theme.boxShadow[typeValue];
            if (Object.keys(props?.theme).length) {
                bSType = props.theme.boxShadow[typeValue];
            }

            return `${px(bSType.px1)} ${px(bSType.px2)} ${px(bSType.px3)} ${px(bSType.px4)} ${color(colorValue, boxOpacity ?? undefined)(props)}`;
        };
    };
}

export function laptop(value: string) {
    return css`
        @media (max-width: ${breakpoint("lg")}) {
            ${value}
        }
    `;
}

export function tablet(value: string) {
    return css`
        @media (max-width: ${breakpoint("md")}) {
            ${value}
        }
    `;
}

export function mobile(value: string) {
    return css`
        @media (max-width: ${breakpoint("sm")}) {
            ${value}
        }
    `;
}

export function pxToRem(x: number) {
    return `${rem(x / 16)}`;
}

export function getMediaSize(value: string) {
    switch (value) {
        case "laptop":
            return theme.mediaSize["lg"];
        case "tablet":
            return theme.mediaSize["md"];
        case "mobile":
            return theme.mediaSize["sm"];
        default:
            return theme.mediaSize["xl"];
    }
}

function breakpoint(value: string) {
    return px(theme.mediaSize[value]);
}

export function px(x: number | string) {
    return `${x}px`;
}

function em(x: number | string) {
    return `${x}em`;
}

function rem(x: number | string) {
    return `${x}rem`;
}

function percent(x: number | string) {
    return `${x}%`;
}
