import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../components/ui/dialog";
import React from "react";
import { Button } from "../../../../components/ui/button";

interface DeleteRecapField {
    onSubmit: () => void;
}

export default function DeleteRecapField({ onSubmit }: DeleteRecapField) {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="destructive">X</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="text-xl">Remove recap field</DialogTitle>
                    <DialogDescription>Do you want to remove this recap field?</DialogDescription>
                </DialogHeader>
                <DialogFooter className="mt-4">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button type="submit" onClick={onSubmit}>
                            Confirm
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
