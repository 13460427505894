import React, { Fragment, useEffect, useRef, useState } from "react";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";
import { Panel } from "../../../components/ui/panel";
import { Typography } from "../../../components/ui/typography";
import { z, ZodObject, ZodString } from "zod";
import { Button } from "../../../components/ui/button";
import {
    CalendarDays,
    Handshake,
    LoaderCircle,
    Pencil,
    Save,
    ScanEye,
    Settings2,
} from "lucide-react";
import { useAddRecapStructureMutation, useLazyGetRecapQuery } from "../../../api/tenant/recap";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { useGetRecapCategoriesQuery } from "../../../api/tenant/recap-categories";
import {
    fetchFieldValues,
    RecapFieldValueEntityInterface,
} from "../../../lib/stores/RecapFieldValuesSlice";
import { fetchVessels, setSelectedVessel } from "../../../lib/stores/RecapVesselsSlice";
import { RecapCategoryIcon } from "./recap-category-icon";
import Loading from "../../../components/global/loading";
import {
    fetchRecapStructure,
    RecapStructureItemInterface,
} from "../../../api/central/recap-structure";
import { VESSEL_CATEGORY_UUID, VESSEL_IMO_UUID, VESSEL_NAME_UUID } from "./recap-utils";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { useGetUploadedFilesQuery } from "../../../api/tenant/attachments";
import { UploadedFile } from "../../../types/uploads";
import { ActiveUsersInterface } from "../../../types/document-editor";
import { useAuth } from "../../../lib/providers/AuthProvider";
import { RecapFieldInterface, RecapInterface } from "../../../types/recap";
import CleanEmptyFields from "./clean-empty-fields";
import { usePageTitle } from "../../../lib/usePageTitle";
import { TenantUser } from "../../../types/auth";
import SearchableList from "../../../components/searchable-list/searchable-list";
import { RecapForm } from "./recap-form";
import DocumentViewer from "../../../components/froala-read-only/document-viewer";
import { fetchClauseValues } from "../../../lib/stores/RecapClauseValueSlice";
import { RecapClause } from "./recap-clauses";
import parse from "html-react-parser";
import { scrollToCenterInsideParent } from "../../../lib/utils";
import { toast } from "sonner";
import { Avatar, AvatarFallback } from "../../../components/ui/avatar";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../../../components/ui/hover-card";
import { formatDistance } from "date-fns";
import { setActiveCollaborator } from "../../../api/tenant/document-editor";
import { Badge } from "../../../components/ui/badge";
import { avatarColors, avatarInitials } from "../../../lib/helpers/avatar";
import PreviewTitle from "../../../components/ui/preview-title";
import ParseRecapFile from "./import/parse-recap-file";

export function RecapEditor() {
    const { setActivePage, documents, meta_data, permissions } = useCaseFiles();
    const dispatch = useAppDispatch();
    const recapIndex = documents.find((document) => document.template_type === "recap");
    const [getRecap, { data: recap, error: recapError, isLoading: recapIsLoading }] =
        useLazyGetRecapQuery();
    const { data: recapCategories, isLoading: recapCategoriesIsLoading } =
        useGetRecapCategoriesQuery();
    const [addRecapFields] = useAddRecapStructureMutation();
    const recapFieldValues = useAppSelector((state) => state.recapFieldValues.entities);
    const selectedVessel = useAppSelector((state) => state.recapVessels.selected);
    const [allRecapFields, setAllRecapFields]: [
        RecapStructureItemInterface[] | undefined,
        (value: RecapStructureItemInterface[] | undefined) => void,
    ] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const vessels = useAppSelector((state) => state.recapVessels.entities);
    const formRef = useRef<{
        updateValues: (values: { [key: string]: string }) => void;
        isDirty: () => boolean;
        submitForm: () => void;
    } | null>(null);
    const { case_file_id } = useCaseFiles();
    const { data: uploads, isLoading: uploadsLoading } = useGetUploadedFilesQuery(case_file_id);
    const [socketConnected, setSocketConnected] = useState<boolean>(false);
    const [activeCollaboratorId, setActiveCollaboratorId] = useState<string>("");
    const [activeUsers, setActiveUsers] = useState<Array<ActiveUsersInterface>>([]);
    const [isDirty, setIsDirty] = useState(false);
    const user = useAuth().state.user;
    const echo = window.Echo;
    const recapClauseValues = useAppSelector((state) => state.recapClauseValues.entities);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [switchingUser, setSwitchingUser] = useState<boolean>(false);
    const { state: userState } = useAuth();

    usePageTitle(`CP: ${meta_data?.reference} - Recap`);

    useEffect(() => {
        (async () => {
            await determineActiveUser();
        })();
    }, [activeUsers]);

    useEffect(() => {
        setActivePage("recap");
        if (window.innerWidth >= 1600) {
            setShowPreview(true);
        }

        return () => {
            echo.leaveAllChannels();
        };
    }, []);

    useEffect(() => {
        if (recapIndex?.id) {
            (async () => {
                try {
                    await getRecap({
                        id: recapIndex.id,
                        case_file_id: case_file_id,
                    });
                    await dispatch(
                        fetchFieldValues({
                            recapId: recapIndex.id,
                            case_file_id: case_file_id,
                        }),
                    );
                    await dispatch(
                        fetchVessels({
                            recapId: recapIndex.id,
                            case_file_id: case_file_id,
                        }),
                    );
                    await dispatch(
                        fetchClauseValues({
                            recap_document_id: recapIndex.id,
                            case_file_id: case_file_id,
                        }),
                    );
                    const { data: recapFields } = await fetchRecapStructure();
                    setAllRecapFields(recapFields);
                } finally {
                    setIsLoading(false);
                }
            })();
        } else {
            setIsLoading(false);
        }
    }, [recapIndex]);

    useEffect(() => {
        if (recap && user && !socketConnected) {
            setUpSocket(user, recap);
        }
    }, [recap, user]);

    useEffect(() => {
        if (activeCollaboratorId !== userState.user?.id) {
            setSwitchingUser(false);
        }
    }, [activeCollaboratorId]);

    function setUpSocket(user: TenantUser, recap: RecapInterface) {
        let socketChannel = `${user.tenant_id}.recaps.${recap.id}`;

        if (meta_data?.additional_data.socket_channel) {
            socketChannel =
                meta_data?.additional_data.socket_channel + "--supply-chain-editor." + recap.id;
        }

        echo.join(socketChannel)
            .here((users: Array<ActiveUsersInterface>) => setActiveUsers(users))
            .joining(userJoined)
            .leaving(userLeft)
            .listen(".access-granted", async (e: { collaborator_id: string }) => {
                await handleAccessGranted(e.collaborator_id);
            });

        setSocketConnected(true);
    }

    async function handleAccessGranted(collaborator_id: string) {
        if (recapIndex) {
            await fetchData(recapIndex.id);
        }
        setActiveCollaboratorId(collaborator_id);
    }

    async function fetchData(id: string) {
        await getRecap({
            id: id,
            case_file_id: case_file_id,
        });
        await dispatch(
            fetchVessels({
                recapId: id,
                case_file_id: case_file_id,
            }),
        );
        const recapValues = (await dispatch(
            fetchFieldValues({
                recapId: id,
                case_file_id: case_file_id,
            }),
        )) as { payload: RecapFieldValueEntityInterface[] };
        const values = Object.fromEntries(
            recapValues.payload.map((field) => [
                `${field.field_id}${field.recap_vessel_id ? `--${field.recap_vessel_id}` : ""}`,
                field.value ?? "",
            ]),
        );

        formRef.current?.updateValues(values);
    }

    function userJoined(user: ActiveUsersInterface) {
        if (activeUsers.filter((u) => u.id === user.id).length) {
            setActiveUsers(
                activeUsers
                    .map((u) => {
                        if (u.id === user.id) {
                            return { ...u, user_joined: user.user_joined };
                        } else {
                            return u;
                        }
                    })
                    .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
            );
        } else {
            setActiveUsers((prev) =>
                [...prev, user].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
            );
        }
    }

    function userLeft(user: ActiveUsersInterface) {
        setActiveUsers((prev) =>
            prev
                .filter((u) => u.id !== user.id)
                .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
        );
    }

    async function determineActiveUser() {
        if (activeUsers.length) {
            try {
                const prevActiveUserId = activeCollaboratorId;
                const activeUserId = activeUsers
                    .filter((x) => x.allowed_to_edit)
                    .sort((a, b) => a.user_joined - b.user_joined)[0]["id"];
                setActiveCollaboratorId(activeUserId);

                if (
                    prevActiveUserId !== activeUserId &&
                    activeUserId === user?.id &&
                    !recapIsLoading &&
                    !isLoading &&
                    recapIndex
                ) {
                    setIsLoading(true);
                    await fetchData(recapIndex.id);
                    setIsLoading(false);
                }
            } catch (e) {
                setActiveCollaboratorId("");
            }
        }
    }

    function isUserActive(): boolean {
        if (recap?.finalized) {
            return false;
        }
        if (!permissions?.edit_documents) {
            return false;
        }
        if (activeUsers.length <= 1) {
            return true;
        } else {
            return activeCollaboratorId === user?.id;
        }
    }

    function scrollToCategory(categoryName: string) {
        const section = document.getElementById(`section-${categoryName}`);

        section?.scrollIntoView({
            behavior: "auto",
            block: "start",
            inline: "nearest",
        });
    }

    function getDefaultValues() {
        const values: {
            [key: string]: string | Pick<RecapClause, "title" | "value">;
        } = {};
        recapFieldValues.forEach((field) => {
            values[field.field_id + (field.recap_vessel_id ? "--" + field.recap_vessel_id : "")] =
                field.value;
        });

        recapClauseValues.forEach((clause) => {
            values[clause.id] = {
                title: clause.title,
                value: clause.value,
            };
        });

        return values;
    }

    function getSchema() {
        const schema: {
            [key: string]: ZodString | ZodObject<{ title: ZodString; value: ZodString }>;
        } = {};

        getFields().forEach((field) => {
            if (field.categoryId === VESSEL_CATEGORY_UUID) {
                vessels.forEach((vessel) => {
                    schema[field.fieldId + "--" + vessel.id] = z.string();
                });
            } else {
                schema[field.fieldId] = z.string();
            }
        });

        if (recap && Object.keys(recap.structure).includes("recap-clauses")) {
            Object.values(recap?.structure["recap-clauses"].fields).forEach((clauseId) => {
                schema[clauseId] = z.object({
                    title: z.string(),
                    value: z.string(),
                    visible: z.boolean(),
                });
            });
        }

        return z.object(schema);
    }

    async function addAndSelectField(fieldId: string, categoryId: string) {
        if (!recap) {
            return;
        }

        try {
            await addRecapFields({
                id: recap.id,
                fields: [fieldId],
                case_file_id: case_file_id,
            }).unwrap();

            setTimeout(() => {
                selectField(fieldId, categoryId);
            }, 200);
        } catch (e) {
            toast.error("Something went wrong whilst adding recap field");
        }
    }

    function selectField(fieldId: string, categoryId: string) {
        if (categoryId === VESSEL_CATEGORY_UUID) {
            focusVesselField(fieldId, selectedVessel);
        } else {
            focusField(fieldId);
        }
    }

    function focusVesselField(fieldId: string, vesselId?: string) {
        if (!vesselId) {
            return;
        }

        const field = document.getElementById("field-" + fieldId + "--" + vesselId);
        if (field) {
            // Dirty hack to force ui to be drawn
            setTimeout(() => {
                if (vesselId !== selectedVessel) {
                    dispatch(setSelectedVessel(vesselId));
                }
                field.focus();
            }, 1);
        }
    }

    const vesselName = (vesselId: string, vesselIndex: number) => {
        const value = vesselFieldValue(vesselId, VESSEL_NAME_UUID);
        return value ? value : "Unnamed vessel" + (vessels.length > 1 ? ` ${vesselIndex + 1}` : "");
    };

    const vesselImo = (vesselId: string) => {
        const value = vesselFieldValue(vesselId, VESSEL_IMO_UUID);
        return value ? value : "<UNKNOWN>";
    };

    const vesselFieldValue = (vesselId: string, fieldId: string): string | undefined => {
        return recapFieldValues.find(
            (fieldValue) =>
                fieldValue.field_id === fieldId && fieldValue.recap_vessel_id === vesselId,
        )?.value;
    };

    function focusField(fieldId: string) {
        const field = document.getElementById("field-" + fieldId);

        if (field) {
            field.focus();
        }
    }

    function getFields() {
        if (!recap?.structure) {
            return [];
        }

        const fields: RecapFieldInterface[] = [];

        Object.entries(recap.structure)
            .filter((category) => category[0] !== "recap-clauses")
            .forEach((category) => {
                Object.entries(category[1].fields).forEach((field) => {
                    fields.push({
                        categoryId: category[0],
                        categoryName: category[1].name,
                        fieldId: field[0],
                        fieldName: field[1],
                    });
                });
            });

        return fields;
    }

    function getEmptyFields(): RecapFieldInterface[] {
        if (!recap?.structure) {
            return [];
        }

        const fields: RecapFieldInterface[] = [];

        Object.entries(recap.structure)
            .filter((category) => category[0] !== "recap-clauses")
            .forEach((category) => {
                Object.entries(category[1].fields).forEach((field) => {
                    if (
                        !recapFieldValues.find((val) => val.field_id === field[0]) ||
                        recapFieldValues.find((val) => val.field_id === field[0])?.value === ""
                    ) {
                        fields.push({
                            categoryId: category[0],
                            categoryName: category[1].name,
                            fieldId: field[0],
                            fieldName: field[1],
                        });
                    }
                });
            });

        return fields;
    }

    async function handleGrantAccess(userId: string) {
        if (!recap) {
            return;
        }
        setSwitchingUser(true);
        try {
            await setActiveCollaborator(
                recap.id,
                userId,
                "recaps",
                undefined,
                meta_data?.additional_data.socket_channel,
            );
        } catch {
            setSwitchingUser(false);
        }
    }

    function getDocumentName(): string {
        return (recap?.name !== "" ? recap?.name : meta_data?.document_name) ?? "Unnamed";
    }

    function categoryName(categoryId: string) {
        return (
            recapCategories?.find((recapCategory) => recapCategory.id === categoryId)?.name ??
            "Undefined"
        );
    }

    function categorySlug(categoryId: string) {
        return (
            recapCategories?.find((recapCategory) => recapCategory.id === categoryId)?.name ??
            "undefined"
        )
            .toLowerCase()
            .replaceAll(" ", "-");
    }

    function fieldValue(fieldId: string, vesselId?: string) {
        return (
            recapFieldValues?.find(
                (recapFieldsValue) =>
                    recapFieldsValue.field_id === fieldId &&
                    recapFieldsValue.recap_vessel_id === (vesselId ?? null),
            )?.value ?? ""
        );
    }

    function handleQ88(q88: UploadedFile, vesselId?: string) {
        if (recap && selectedVessel) {
            const usedVesselId = vesselId ?? selectedVessel;
            const newValues = q88.smart_fields?.filter((x) =>
                getFields().find(
                    (field) => field.fieldId === x.id && field.categoryId === VESSEL_CATEGORY_UUID,
                ),
            );
            if (!newValues) {
                return;
            }
            const values = Object.fromEntries(
                newValues.map((field) => [`${field.id}--${usedVesselId}`, field.value ?? ""]),
            );

            formRef.current?.updateValues(values);
        }
    }

    function clauseTitle(clauseId: string): string {
        return recapClauseValues.find((clause) => clause.id === clauseId)?.title ?? "";
    }

    function clauseValue(clauseId: string): string {
        const clause = recapClauseValues.find((clause) => clause.id === clauseId);

        return clause?.visible ? clause?.value ?? "" : "– Not applicable –";
    }

    function scrollToCategoryInPreview(categoryId: string) {
        const node = window.document.getElementById(`recap-preview-category-${categoryId}`);
        const parent = window.document.getElementById(`recap-preview`);

        if (node && parent) {
            scrollToCenterInsideParent(node, parent);
        }
    }

    function getFieldsNotInDocument(): RecapFieldInterface[] {
        return (
            allRecapFields
                ?.filter(
                    (field) =>
                        !getFields()
                            .map((field) => field.fieldId)
                            .includes(field.id),
                )
                .map((item) => ({
                    categoryId: item.category_id,
                    categoryName: categoryName(item.category_id),
                    fieldId: item.id,
                    fieldName: item.name,
                })) ?? []
        );
    }

    async function handleFieldSelectedFromSearch(
        field: Set<RecapFieldInterface | undefined> | undefined,
    ) {
        if (field) {
            if (
                getFields()
                    .map((field) => field.fieldId)
                    .includes(field.values().next().value.fieldId)
            ) {
                selectField(
                    field.values().next().value.fieldId,
                    field.values().next().value.categoryId,
                );
            } else {
                await addAndSelectField(
                    field.values().next().value.fieldId,
                    field.values().next().value.categoryId,
                );
            }
        }
    }

    return (
        <Fragment>
            <Loading
                loaded={!isLoading && !recapIsLoading && !recapCategoriesIsLoading}
                child={
                    recap !== undefined && !recapError ? (
                        <div className="flex flex-col !scroll-smooth">
                            <div className="flex pt-2 px-4 h-16 left-12 right-0 gap-4 items-center fixed bg-background top-28 z-10">
                                <div className={"flex flex-grow gap-4 items-center"}>
                                    <Typography
                                        className="pb-0 mb-0 leading-none"
                                        text={getDocumentName()}
                                        style="h2"
                                    />
                                    <Badge variant={recap.finalized ? "primary" : "pending"}>
                                        {recap.finalized ? "Finalized" : "Draft"}
                                    </Badge>
                                </div>
                                <div className="flex gap-x-2 justify-end items-center h-8">
                                    {activeUsers?.map((member, index) => (
                                        <div key={index} className={"relative"}>
                                            {activeCollaboratorId === member.id ? (
                                                <div
                                                    title={member.name + " is currently editing."}
                                                    className={
                                                        "absolute rounded-full h-3 flex items-center justify-center w-3 z-10 text-sky-50 bg-sky-600 outline outline-2 outline-background -right-1 -bottom-1"
                                                    }
                                                >
                                                    <Pencil width={6} />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <Avatar
                                                className={
                                                    "h-6 w-6 text-xs overflow-visible " +
                                                    (isUserActive() ? "cursor-pointer" : "")
                                                }
                                                key={index}
                                            >
                                                <HoverCard>
                                                    <HoverCardTrigger asChild>
                                                        <AvatarFallback
                                                            style={avatarColors(
                                                                member.id,
                                                                userState.user?.id === member.id,
                                                            )}
                                                            className={
                                                                "text-[10px] uppercase overflow-auto"
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    "w-full text-center overflow-hidden"
                                                                }
                                                            >
                                                                {avatarInitials(member.name)}
                                                            </div>
                                                        </AvatarFallback>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="w-48" align="end">
                                                        <div className="space-y-1">
                                                            <h4 className="text-sm font-semibold">
                                                                {member.name}
                                                            </h4>
                                                            <div className="flex items-center pt-2">
                                                                <CalendarDays className="mr-2 h-4 w-4 opacity-70" />{" "}
                                                                <span className="text-xs text-muted-foreground">
                                                                    Joined{" "}
                                                                    {formatDistance(
                                                                        new Date(
                                                                            member.user_joined *
                                                                                1000,
                                                                        ),
                                                                        new Date(),
                                                                        { addSuffix: true },
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {isUserActive() &&
                                                                member.id !==
                                                                    activeCollaboratorId &&
                                                                member.allowed_to_edit && (
                                                                    <Button
                                                                        size={"sm"}
                                                                        className={
                                                                            "w-full block !mt-3"
                                                                        }
                                                                        onClick={() =>
                                                                            handleGrantAccess(
                                                                                member.id,
                                                                            )
                                                                        }
                                                                        disabled={switchingUser}
                                                                    >
                                                                        Make editor
                                                                    </Button>
                                                                )}
                                                        </div>
                                                    </HoverCardContent>
                                                </HoverCard>
                                            </Avatar>
                                        </div>
                                    ))}
                                </div>
                                <SearchableList<RecapFieldInterface>
                                    groups={[
                                        {
                                            heading: "Fields in document",
                                            options: getFields(),
                                        },
                                        {
                                            heading: "Not in document",
                                            options: getFieldsNotInDocument(),
                                            className: "text-slate-500 hover:text-slate-950",
                                        },
                                    ]}
                                    onOptionSelected={handleFieldSelectedFromSearch}
                                    searchPlaceholder="Search for field"
                                    className="w-[200px] h-9 focus-within:outline border outline-2 outline-blue-500 text-sm"
                                    searchInputClassName="h-9"
                                    selectedValues={new Set(undefined)}
                                    labelFormatter={(field) => field.fieldName}
                                    popover={{
                                        closeOnSelect: true,
                                    }}
                                >
                                    {(props) => (
                                        <div className="flex items-start">
                                            <RecapCategoryIcon
                                                categoryId={props.option.value.categoryId}
                                                className={"w-4 h-4 shrink-0 mt-0.5 mr-3"}
                                            />
                                            <span>{props.option.value.fieldName}</span>
                                        </div>
                                    )}
                                </SearchableList>
                                {!uploadsLoading && uploads?.filter((x) => x.imo).length ? (
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button
                                                variant="outline"
                                                type="button"
                                                disabled={!isUserActive()}
                                            >
                                                <img
                                                    className="h-[16px] mr-2 w-4 stroke-current"
                                                    alt="q88"
                                                    src="/images/q88.svg"
                                                />
                                                Insert Q88
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent>
                                            {uploads
                                                ?.filter((x) => x.imo)
                                                .map((q88, index) => (
                                                    <DropdownMenuItem
                                                        key={index}
                                                        onClick={() => handleQ88(q88)}
                                                    >
                                                        {q88.document_name}
                                                    </DropdownMenuItem>
                                                ))}
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                ) : null}
                                <Button
                                    onClick={() => setShowPreview(!showPreview)}
                                    variant={showPreview ? "default" : "outline"}
                                    title={`${showPreview ? "Hide" : "Show"} preview`}
                                >
                                    <ScanEye className="h-4 w-4" />
                                </Button>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button type="button" variant="outline">
                                            <Settings2 height={16} />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuLabel>Recap options</DropdownMenuLabel>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuGroup>
                                            <DropdownMenuItem
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }}
                                            >
                                                <CleanEmptyFields
                                                    emptyFields={getEmptyFields()}
                                                    recapId={recapIndex?.id ?? ""}
                                                    isUserActive={isUserActive()}
                                                />
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                        <DropdownMenuGroup>
                                            <DropdownMenuItem
                                                onSelect={(event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }}
                                            >
                                                <ParseRecapFile
                                                    case_file_id={case_file_id}
                                                    recap={recap}
                                                    isUserActive={isUserActive()}
                                                    fetchData={fetchData}
                                                />
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                                <Button
                                    onClick={() => {
                                        formRef?.current?.submitForm();
                                    }}
                                    type={"button"}
                                    variant="default"
                                    disabled={!isUserActive() || !isDirty}
                                >
                                    {!isDirty ? (
                                        <Save height={16} className={"mr-2"} />
                                    ) : (
                                        <LoaderCircle height={16} className={"mr-2 animate-spin"} />
                                    )}
                                    Save
                                </Button>
                            </div>
                            {recap.structure ? (
                                <div
                                    className={`editor-grid ${showPreview ? "editor-grid-preview" : ""}`}
                                >
                                    <Panel className="sticky top-44 flex flex-col gap-4 shrink-0">
                                        <Typography text="Table of contents" style="h4" />
                                        <div className={"flex flex-col"}>
                                            {Object.entries(recap.structure)
                                                .filter(
                                                    (structure) => structure[0] !== "recap-clauses",
                                                )
                                                .map((category) => (
                                                    <Fragment key={category[0]}>
                                                        {category[0] === VESSEL_CATEGORY_UUID ? (
                                                            <Fragment>
                                                                {vessels.map((vessel, index) => (
                                                                    <a
                                                                        key={index}
                                                                        className={
                                                                            "py-2 border-b flex gap-4 items-start" +
                                                                            " last:border-b-0"
                                                                        }
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            scrollToCategory(
                                                                                categorySlug(
                                                                                    category[0],
                                                                                ),
                                                                            );
                                                                            dispatch(
                                                                                setSelectedVessel(
                                                                                    vessel.id,
                                                                                ),
                                                                            );
                                                                            scrollToCategoryInPreview(
                                                                                vessel.id,
                                                                            );
                                                                            scrollToCategoryInPreview(
                                                                                vessel.id,
                                                                            );
                                                                        }}
                                                                        href={`#section-${categorySlug(category[0])}`}
                                                                    >
                                                                        <RecapCategoryIcon
                                                                            categoryId={
                                                                                VESSEL_CATEGORY_UUID
                                                                            }
                                                                            className={
                                                                                "w-5 h-5 shrink-0 mt-0.5"
                                                                            }
                                                                        />
                                                                        {vesselName(
                                                                            vessel.id,
                                                                            index,
                                                                        )}
                                                                    </a>
                                                                ))}
                                                            </Fragment>
                                                        ) : (
                                                            <a
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    scrollToCategory(
                                                                        categorySlug(category[0]),
                                                                    );

                                                                    scrollToCategoryInPreview(
                                                                        category[0],
                                                                    );
                                                                }}
                                                                href={`#section-${categorySlug(category[0])}`}
                                                                className={
                                                                    "py-2 border-b flex gap-4 items-start last:border-b-0"
                                                                }
                                                            >
                                                                <RecapCategoryIcon
                                                                    categoryId={category[0]}
                                                                    className={
                                                                        "w-5 h-5 shrink-0 mt-0.5"
                                                                    }
                                                                />
                                                                {categoryName(category[0])}
                                                            </a>
                                                        )}
                                                    </Fragment>
                                                ))}
                                            <a
                                                className={
                                                    "py-2 border-b flex gap-4 items-start last:border-b-0"
                                                }
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    scrollToCategory("recap-clauses");
                                                    scrollToCategoryInPreview("recap-clauses");
                                                }}
                                                href={`#section-recap-clauses`}
                                            >
                                                <Handshake className="w-5 h-5 shrink-0 mt-0.5" />
                                                Additional terms
                                            </a>
                                        </div>
                                    </Panel>
                                    <div
                                        className={`sticky min-w-0 top-48 scroll-m-8 ${showPreview ? "hidden 2xl:block" : "block"}`}
                                    >
                                        <RecapForm
                                            ref={formRef}
                                            setIsDirty={setIsDirty}
                                            recap={recap}
                                            handleQ88={handleQ88}
                                            schema={getSchema()}
                                            defaultValues={getDefaultValues()}
                                            categoryName={categoryName}
                                            categorySlug={categorySlug}
                                            activeUsers={activeUsers}
                                            activeCollaboratorId={activeCollaboratorId}
                                            isUserActive={isUserActive()}
                                            showPreview={showPreview}
                                        />
                                    </div>
                                    <div
                                        className={`top-48 ${showPreview ? "sticky mt-16" : "hidden"}`}
                                        style={{ fontFamily: "Lora, serif" }}
                                    >
                                        <DocumentViewer zoom id="recap-preview">
                                            <Typography
                                                text={getDocumentName()}
                                                style={"h4"}
                                                className={
                                                    "mb-12 text-3xl font-normal text-center text-primary"
                                                }
                                            />
                                            {Object.entries(recap.structure).map((category) => (
                                                <div className="text-xs" key={category[0]}>
                                                    {category[0] === VESSEL_CATEGORY_UUID ? (
                                                        <Fragment>
                                                            {vessels.map((vessel, index) => (
                                                                <div
                                                                    className={"mb-10"}
                                                                    key={index}
                                                                >
                                                                    <PreviewTitle
                                                                        id={`recap-preview-category-${vessel.id}`}
                                                                    >
                                                                        <span>
                                                                            {categoryName(
                                                                                category[0],
                                                                            )}
                                                                        </span>
                                                                        <span
                                                                            className={
                                                                                "text-xs text-slate-500 pl-[12px]"
                                                                            }
                                                                        >
                                                                            {vesselName(
                                                                                vessel.id,
                                                                                index,
                                                                            )}{" "}
                                                                            - IMO:{" "}
                                                                            {vesselImo(vessel.id)}
                                                                        </span>
                                                                    </PreviewTitle>
                                                                    {Object.entries(
                                                                        category[1].fields,
                                                                    ).map((field, fieldIndex) => (
                                                                        <div
                                                                            className={`flex ${fieldIndex > 0 ? "" : "border-t"} border-b border-primary-50 gap-2 items-stretch`}
                                                                            key={field[0]}
                                                                            id={`recap-preview-vessel-${vessel.id}-field-${field[0]}`}
                                                                        >
                                                                            <div className="w-1/3 shrink-0 grow-0 opacity-80 bg-slate-100 p-1.5 ">
                                                                                {field[1]}
                                                                            </div>
                                                                            <div className="whitespace-pre-line p-1.5">
                                                                                {fieldValue(
                                                                                    field[0],
                                                                                    vessel.id,
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </Fragment>
                                                    ) : category[0] === "recap-clauses" ? (
                                                        <div
                                                            className={"mb-10"}
                                                            id={`recap-preview-category-recap-clauses`}
                                                        >
                                                            <Typography
                                                                text={"Additional terms"}
                                                                style={"h4"}
                                                                className={
                                                                    "mb-8 text-3xl font-normal text-center text-primary"
                                                                }
                                                            />
                                                            {Object.entries(category[1].fields)
                                                                .map((clause) => clause[1])
                                                                .map((clauseId, fieldIndex) => (
                                                                    <div key={fieldIndex}>
                                                                        <PreviewTitle
                                                                            id={`recap-preview-clause-${clauseId}`}
                                                                        >
                                                                            {clauseTitle(clauseId)}
                                                                        </PreviewTitle>
                                                                        <div
                                                                            className={
                                                                                "text-xs leading-relaxed mb-10 text-wrap [&_p]:my-[.5em]"
                                                                            }
                                                                        >
                                                                            {parse(
                                                                                clauseValue(
                                                                                    clauseId,
                                                                                ),
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    ) : (
                                                        <div className={"mb-10"}>
                                                            <PreviewTitle
                                                                id={`recap-preview-category-${category[0]}`}
                                                            >
                                                                {categoryName(category[0])}
                                                            </PreviewTitle>
                                                            {Object.entries(category[1].fields).map(
                                                                (field, fieldIndex) => (
                                                                    <div
                                                                        className={`flex ${fieldIndex > 0 ? "" : "border-t"} border-b border-primary-50 gap-2 items-stretch`}
                                                                        key={field[0]}
                                                                        id={`recap-preview-field-${field[0]}`}
                                                                    >
                                                                        <div className="w-1/3 shrink-0 grow-0 opacity-80 bg-slate-100 p-1.5 ">
                                                                            {field[1]}
                                                                        </div>
                                                                        <div className="whitespace-pre-line p-1.5">
                                                                            {fieldValue(field[0])}
                                                                        </div>
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </DocumentViewer>
                                    </div>
                                </div>
                            ) : (
                                <div>No structure</div>
                            )}
                        </div>
                    ) : (
                        <div className={"p-4"}>
                            <Panel>No recap found</Panel>
                        </div>
                    )
                }
            />
        </Fragment>
    );
}
