import React, { useState } from "react";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { columns } from "../../config/team";
import { DataTableToolbar } from "../../components/data-table/data-table-toolbar";
import DataTable from "../../components/data-table/data-table";
import { DataTableToolbarButton } from "../../types/table";
import { Tables } from "../../config/app";
import { TenantUser } from "../../types/auth";

function TeamTable({
    data,
    buttons,
}: {
    data: Array<TenantUser>;
    buttons: Array<DataTableToolbarButton>;
}) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({}),
        [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]),
        [sorting, setSorting] = useState<SortingState>([]),
        table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                columnVisibility,
                columnFilters,
            },
            initialState: {
                pagination: {
                    pageSize: 20,
                },
            },
            meta: {
                tableId: Tables.TEAM,
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
        });

    return (
        <div className="flex flex-col h-full">
            <DataTableToolbar
                table={table}
                searchableColumns={[]}
                buttons={buttons}
                title="Current members"
            />
            <div className="flex py-4 h-full">
                <DataTable columns={columns} table={table} onClick={() => void 0} />
            </div>
        </div>
    );
}

export default TeamTable;
