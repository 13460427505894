import axios from "axios";

export async function overviewStats(): Promise<any> {
    return await axios.get("/admin/api/v1/stats/overview").then((r) => r.data);
}

export async function tenantStats(tenant_id: string): Promise<any> {
    return await axios.get(`/admin/api/v1/stats/tenant/${tenant_id}`).then((r) => r.data);
}

export async function activityStats(page: number = 1): Promise<any> {
    return await axios.get(`/admin/api/v1/stats/activity?page=${page}`).then((r) => r.data);
}

export async function validateDocusignOAuth(code: string) {
    return await axios.get(`/admin/api/v1/docusign/${code}`).then((r) => r.data);
}

export async function documentPDFblob(tenant: string, document_id: string): Promise<Blob> {
    return await axios
        .post(
            "/admin/api/v1/getdocumentpdfblob",
            {
                tenant: tenant,
                document_id: document_id,
            },
            {
                responseType: "blob",
            },
        )
        .then((r) => r.data);
}
