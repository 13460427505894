import React, { useEffect, useReducer } from "react";
import {
    useLazyFetchLoggedInUserQuery,
    useLazySignOutUserQuery,
    useSignInUserMutation,
} from "../../api/tenant/authentication";
import Echo from "laravel-echo";
import { useLazyGetCsrfCookieQuery } from "../../api/cookieApi";
import axios from "axios";
import { toast } from "sonner";
import { useLazyGetColumnVisibilityQuery } from "../../api/tenant/settings";
import { AuthContextType, AuthProviderStateInterface, TenantUser, User } from "../../types/auth";

export const AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => {
    return React.useContext(AuthContext);
};

export async function attemptLoginByToken(token: string): Promise<TenantUser> {
    return await axios
        .get("/api/tenant/v1/authentication/validate-by-token", {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        })
        .then((res) => {
            return res.data;
        });
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [state, setState] = useReducer(
        (state: AuthProviderStateInterface, newState: Partial<AuthProviderStateInterface>) => ({
            ...state,
            ...newState,
        }),
        {
            loggedIn: false,
            loaded: false,
            socketConnected: false,
            column_visibility: [],
        },
    );
    const [getCsrfCookie] = useLazyGetCsrfCookieQuery();
    const [signInUser] = useSignInUserMutation();
    const [signOutUser] = useLazySignOutUserQuery();
    const [fetchLoggedInUser] = useLazyFetchLoggedInUserQuery();
    const [getColumnVisibility, { data: columns }] = useLazyGetColumnVisibilityQuery();

    useEffect(() => {
        if (columns) {
            setState({
                column_visibility: columns,
            });
        }
    }, [columns]);

    useEffect(() => {
        if (!state.loggedIn) {
            setTimeout(() => {
                checkLoginAfterRefresh();
            }, 1);
        }
    }, [state.loggedIn]);

    useEffect(() => {
        if (state.loaded && state.loggedIn && !state.socketConnected && state.user) {
            connectToSocket(state.user);
        }
    }, [state]);

    async function connectToSocket(user: TenantUser, token?: string) {
        await getCsrfCookie();

        try {
            window.Echo = new Echo({
                broadcaster: "pusher",
                key: process.env.PUSHER_APP_KEY,
                cluster: "eu",
                forceTLS: true,
                authEndpoint: `/${user.tenant_id}/api/tenant/v1/sockets/channels/auth`,
                encrypted: true,
                auth: {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                },
            });

            setState({
                socketConnected: true,
            });
        } catch (e) {
            // TODO lock app
        }
    }

    async function checkLoginAfterRefresh() {
        try {
            const loggedInUser = await fetchLoggedInUser().unwrap();
            const columns = await getColumnVisibility().unwrap();

            if (typeof loggedInUser !== "string") {
                setState({
                    user: loggedInUser,
                    loggedIn: true,
                    column_visibility: columns,
                });
            }
        } catch (e) {
            setState({
                loggedIn: false,
                socketConnected: false,
                user: undefined,
                column_visibility: [],
            });
        }

        setState({
            loaded: true,
        });
    }

    async function signIn(user: User): Promise<boolean | string> {
        try {
            await getCsrfCookie();
            const loginResponse = await signInUser(user).unwrap();
            const columns = await getColumnVisibility().unwrap();

            setState({
                user: loginResponse.user,
                loggedIn: true,
                column_visibility: columns,
            });

            return true;
        } catch (e) {
            return e.data.message ?? "Login failed";
        }
    }

    async function signOut(): Promise<boolean> {
        try {
            const res = await signOutUser().unwrap();
            setState({
                loggedIn: false,
                socketConnected: false,
                user: undefined,
            });

            document.cookie = "XSRF-TOKEN= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

            return res;
        } catch (e) {
            toast.error("Error while logging out");

            return false;
        }
    }

    async function signInByToken(token: string) {
        await getCsrfCookie();
        const user = await attemptLoginByToken(token);

        connectToSocket(user, token);

        return user;
    }

    const value = {
        signIn,
        state,
        signOut,
        signInByToken,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
