import { Typography } from "../../components/ui/typography";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import React, { useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../lib/providers/AuthProvider";
import ForgotPassword from "./forgot-password";
import { usePageTitle } from "../../lib/usePageTitle";
import { User } from "../../types/auth";

function Login() {
    const schema = z.object({
        username: z.string().min(2, {
            message: "Username must be at least 2 characters.",
        }),
        password: z.string().min(2, {
            message: "Password must be at least 2 characters.",
        }),
    });

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            username: "",
            password: "",
        },
    });

    const signIn = useAuth().signIn;

    const [errorMessage, setErrorMessage] = useState<string>();

    async function logIn(values: z.infer<typeof schema>) {
        const result = await signIn(values as User);

        if (typeof result === "string") {
            form.reset();
            setErrorMessage(result);
        }
    }

    usePageTitle("Login");

    return (
        <div className="bg-login h-screen bg-cover bg-center bg-no-repeat">
            <div className="h-screen w-screen bg-zinc-900 bg-opacity-45 flex align items-center justify-center">
                <div className="w-[520px] rounded shadow-xl bg-white p-8 flex flex-col">
                    <img
                        src="/images/logo.png"
                        alt="Ocean Recap"
                        className="h-24 mt-8 mb-8 self-center"
                    />
                    <div className="flex align items-center justify-cente">
                        <Typography text={"Welcome back"} style="h3" />
                    </div>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(logIn)} className="space-t-8 space-b-4">
                            <FormField
                                control={form.control}
                                name="username"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Username</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel className="pb-1">Password</FormLabel>
                                        <FormControl>
                                            <Input type="password" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {errorMessage && <Typography text={errorMessage} style="warning" />}

                            <div className="flex flex-col">
                                <Button type="submit" className="mt-8">
                                    Log in
                                </Button>
                            </div>
                        </form>
                        <ForgotPassword />
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Login;
