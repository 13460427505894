import React from "react";
import SmartFields from "./smart-fields";
import { Panel } from "../../../components/ui/panel";

function SidePanel() {
    return (
        <Panel
            id="editor-side-panel"
            className="xl:w-[300px] grow-0 shrink-0 absolute bottom-0 w-[1px] overflow-hidden -z-10 xl:static xl:bottom-auto xl:z-auto"
        >
            <div className="xl:bg-white ml-4 xl:ml-0 flex flex-1 overflow-y-auto h-full">
                <SmartFields />
            </div>
        </Panel>
    );
}

export default SidePanel;
