import React from "react";
import styled from "styled-components";

const LogoContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const LogoImage = styled.img`
    height: 56px;
`;

export const Logo = () => {
    return (
        <LogoContainer>
            <LogoImage src={"/images/logo_clean.svg"} alt="logo" />
        </LogoContainer>
    );
};
