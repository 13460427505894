import { SheetHeader } from "../ui/sheet";
import { Typography } from "../ui/typography";
import { Separator } from "../ui/separator";
import React, { Fragment } from "react";

function NotificationsHeader() {
    return (
        <Fragment>
            <SheetHeader>
                <div className="flex items-center">
                    <Typography text="Notifications" style="h2" className="pb-0" />
                </div>
            </SheetHeader>
            <Separator className="my-4" />
        </Fragment>
    );
}

export default NotificationsHeader;
