import { useEditDocumentMutation } from "../../../api/tenant/case-file";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import React, { Fragment, useState } from "react";
import { DialogFooter, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { TableActionProps } from "../../../types/table";
import { CaseFileDocumentIndex } from "../../../types/case-files";
import Loading from "../../../components/global/loading";
import { useCaseFiles } from "../../../lib/providers/CaseFilesProvider";

function EditDocument({ row, onClose }: TableActionProps<CaseFileDocumentIndex>) {
    const schema = z.object({
        document_name: z.string().min(2, {
            message: "Document name must be at least 2 characters.",
        }),
    });
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            document_name: row.document_name,
        },
    });
    const [editDocument] = useEditDocumentMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const { case_file_id } = useCaseFiles();

    async function handleSubmit(values: z.infer<typeof schema>) {
        setLoading(true);
        try {
            await editDocument({
                id: row.id,
                case_file_id: case_file_id,
                ...values,
            });

            onClose();
            toast.success("Document name updated");
        } catch (e) {
            toast.error("Something went wrong while updating document name");
        }
        setLoading(false);
    }

    return (
        <Loading
            loaded={!loading}
            child={
                <Fragment>
                    <DialogHeader>
                        <DialogTitle>Change name</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)}>
                            <FormField
                                control={form.control}
                                name="document_name"
                                render={({ field }) => (
                                    <FormItem className="py-2">
                                        <FormLabel>
                                            Document name{" "}
                                            <span className="text-sm text-muted-foreground">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            {form.formState.isValid && (
                                <DialogFooter className="mt-4">
                                    <Button type="submit">Update</Button>
                                </DialogFooter>
                            )}
                        </form>
                    </Form>
                </Fragment>
            }
        />
    );
}

export default EditDocument;
