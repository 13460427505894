import React, { Fragment } from "react";
import { Typography } from "./ui/typography";
import { Separator } from "./ui/separator";

type SimpleListProps = {
    title?: string;
    items: Array<{
        title: string;
        content: string;
    }>;
    onClick?: () => void;
};

function SimpleList({ title, items, onClick }: SimpleListProps) {
    return (
        <div className="grid gap-3">
            {title && <Typography text={title} style="medium" />}
            <ul className="grid gap-3">
                {items.map((item, index) => (
                    <Fragment key={index}>
                        {index !== 0 && index < items.length && <Separator />}
                        <li className="flex items-center justify-between" onSelect={onClick}>
                            <span className="text-muted-foreground">{item.title}:</span>
                            <span>{item.content}</span>
                        </li>
                    </Fragment>
                ))}
            </ul>
        </div>
    );
}

export default SimpleList;
