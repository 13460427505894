import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../components/ui/dialog";
import React, { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { Checkbox } from "../../../../components/ui/checkbox";
import { RecapSingleFieldInterface } from "../index.interface";

interface AddRecapFieldInterface {
    fields: Array<RecapSingleFieldInterface>;
    onSubmit: (selectedFields: Array<RecapSingleFieldInterface>) => void;
}

export default function AddRecapField({ fields, onSubmit }: AddRecapFieldInterface) {
    const [selectedFields, setSelectedFields] = useState<Array<RecapSingleFieldInterface>>([]);

    function handleOnValueChanged(field_id: string, checked: boolean) {
        if (checked) {
            setSelectedFields((prevFields: Array<RecapSingleFieldInterface>) => [
                ...prevFields,
                fields.filter((field: { id: string }) => field.id === field_id)[0],
            ]);
        } else {
            setSelectedFields((prevFields: Array<RecapSingleFieldInterface>) => [
                ...prevFields.filter((field: { id: string }) => field.id !== field_id),
            ]);
        }
    }

    function handleSubmit() {
        onSubmit(selectedFields);
        setSelectedFields([]);
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button>+</Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="text-xl">Add recap field</DialogTitle>
                    <DialogDescription>Select recap field and add to category</DialogDescription>
                </DialogHeader>
                <ul className="overflow-auto max-h-96">
                    {fields.map((field) => (
                        <li key={field.id}>
                            <Checkbox
                                id={`field[${field.id}]`}
                                value={field.id}
                                onCheckedChange={(checked: boolean) =>
                                    handleOnValueChanged(field.id, checked)
                                }
                            />{" "}
                            <label
                                htmlFor={`field[${field.id}]`}
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                {field.name}
                            </label>
                        </li>
                    ))}
                </ul>
                <DialogFooter className="mt-4">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button type="submit" onClick={handleSubmit}>
                            Add
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
