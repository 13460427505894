import React from "react";

type DocumentViewerProps = {
    children: React.ReactNode;
    id?: string;
    standardForm?: boolean;
    landscape?: boolean;
    mobileZoom?: boolean;
    zoom?: boolean;
};

function DocumentViewer({
    children,
    landscape,
    id,
    zoom,
    mobileZoom,
    standardForm,
}: DocumentViewerProps) {
    return (
        <div
            className={"bg-[#efefef] grow rounded-lg border border-gray-300"}
            suppressContentEditableWarning
        >
            <div className={"h-12 rounded-t-lg bg-white"}></div>
            <div
                style={{ maxHeight: "calc(100vh - 255px )" }}
                className="rounded-b-lg overflow-auto flex justify-center"
            >
                <div className={`p-4`}>
                    <div
                        style={{
                            minHeight: `${landscape ? 210 : 297}mm`,
                            width: `${landscape ? 297 : 210}mm`,
                        }}
                        className={`shadow bg-white ${standardForm ? "px-[12mm]" : "px-[15mm]"} ${standardForm ? "py-[12mm]" : "py-[30mm]"} m-4 overflow-x-hidden transform ${zoom ? "zoom-75" : ""} ${mobileZoom ? "2xl:zoom-100" : ""}`}
                        id={id ?? undefined}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentViewer;
