import React, { Fragment } from "react";
import { Typography } from "../../components/ui/typography";
import HouseFormsTable from "./house-forms-table";
import { useGetHouseFormsQuery } from "../../api/tenant/house-forms";
import Loading from "../../components/global/loading";
import { usePageTitle } from "../../lib/usePageTitle";
import CreateHouseForm from "./create-house-form";

function HouseForms() {
    const { data: houseForms, isLoading } = useGetHouseFormsQuery();

    usePageTitle("templates");

    return (
        <Loading
            loaded={!isLoading}
            child={
                <Fragment>
                    <div className="flex flex-col justify-between space-y-2 bg-foreground p-4 border border-solid border-slate-200">
                        <div className="flex items-center justify-between w-full">
                            <Typography text="Templates" style="h2" className="pb-0" />
                            <CreateHouseForm />
                        </div>
                    </div>
                    <div className=" w-full flex items-stretch h-full">
                        <div className="bg-foreground p-4 rounded-sm">
                            <HouseFormsTable data={houseForms?.data ?? []} />
                        </div>
                    </div>
                </Fragment>
            }
        />
    );
}

export default HouseForms;
